import { SelectorOption } from "../components/sub/component/Selector";

export enum Region {
  서울 = "서울",
  부산 = "부산",
  대구 = "대구",
  인천 = "인천",
  광주 = "광주",
  대전 = "대전",
  울산 = "울산",
  경기 = "경기",
  강원 = "강원",
  충북 = "충북",
  충남 = "충남",
  전북 = "전북",
  전남 = "전남",
  경북 = "경북",
  경남 = "경남",
  제주 = "제주",
  세종 = "세종",
}

export namespace Region {
  export const getOptionList = (): SelectorOption[] => {
    const list = Object.keys(Region).flatMap((key) => {
      const valueObj = Region[key as keyof typeof Region];

      if (typeof valueObj === "string") {
        return [
          {
            value: key,
            label: valueObj.toString(),
          },
        ];
      } else {
        return [];
      }
    });

    return list;
  };
}
