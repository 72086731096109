import { useEffect, useRef } from "react";

interface Args {
  callback: () => void;
  delay: number;
  isPaused: boolean;
}
export function useInterval({ callback, delay, isPaused }: Args) {
  const savedCallback = useRef<() => void>(callback);

  useEffect(() => {
    if (savedCallback.current) {
      savedCallback.current = callback;
    }
  });

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current && !isPaused) {
        savedCallback.current();
      }
    };

    const timerId = setInterval(tick, delay);
    return () => clearInterval(timerId);
    // eslint-disable-next-line
  }, [delay, isPaused, savedCallback.current]);
}
