import React from "react";
import style from "../../../assets/scss/common/modal/refundModal.module.scss";

interface RefundInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RefundModal: React.FC<RefundInfoModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  const handleRefundInquiry = () => {
    window.open("https://pf.kakao.com/_txlSZxj/chat", "_blank");
    onClose();
  };

  return (
    <div className={style.refundModalOverlay}>
      <div className={style.refundModal}>
        <div className={style.refundModalContent}>
          <h2 className={style.titleText}>
            <span className={style.boldText}>환불하기</span>
          </h2>
        </div>
        <div className={style.refundTextContainer}>
          해당 주문건에 대한 환불은
          <br />
          고객센터로 문의 바랍니다.
        </div>
        <div className={style.buttonContainer}>
          <button onClick={onClose} className={style.cancelButton}>
            취소
          </button>
          <button onClick={handleRefundInquiry} className={style.refundCloseButton}>
            환불 문의하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default RefundModal;
