import kpassLogo from "../../assets/images/logo/K-PASS.svg";
import hamburgerBtn from "../../assets/images/header/top_menu_hamburger_btn.svg";
import style from "../../assets/scss/common/header.module.scss";
import partnershipIcon from "../../assets/images/header/partnership_icon.svg";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DrawerMenu } from "./DrawerMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginBar } from "./LoginBar";
import {
  useAuth,
  useLanguage,
  useMainScrolling,
  useModal,
  usePage,
  useScrollingEvent,
  useUserAgent,
  useAwardBanner,
} from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useLocationHistory } from "../../hooks/location/useLocationHistory";
import { GoogleAnalytics } from "./GoogleAnalytics";
import { useUserInfoGetter } from "./hooks/useUserInfoGetter";

export enum MainArticle {
  NONE = "NONE",
  ARTICLE1 = "ARTICLE1",
  ARTICLE2 = "ARTICLE2",
  ARTICLE3 = "ARTICLE3",
  ARTICLE4 = "ARTICLE4",
}

export function Header() {
  const { t } = useTranslation(["newHeader"]);
  const navigate = useNavigate();
  const { isMobile } = useUserAgent();
  const { isWithAppPage, isPartnershipPage, isPartnerCompanyPage, isMainPage } = usePage();
  const [showDrawer, setShowDrawer] = useState(false);

  const { hideAwardBanner } = useAwardBanner();

  const isProdStatus = process.env.REACT_APP_BASE_URL === "https://service.feel-good.io/";
  const { removeUserInfo } = useUserInfoGetter();
  const {
    setHeaderHeight,
    scrollingSection,
    setScrollingSection,
    setSelectedSection,
    getArticleElementOffsetTop,
    article1Element,
    article2Element,
    article3Element,
    article4Element,
  } = useMainScrolling();
  const { openConfirmModal, closeModal } = useModal();
  const auth = useAuth();
  const { canLogin, language, isNotKorean, getLanguageStyle } = useLanguage();
  const { scrollY } = useScrollingEvent();

  const onClickHamburgerBtn = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setShowDrawer(true);
    },
    [setShowDrawer]
  );
  const closeDrawer = useCallback(() => setShowDrawer(false), [setShowDrawer]);
  const { pushHistory } = useLocationHistory({ callbackOnBack: () => closeDrawer() });
  const onClickLogo = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      window.scrollTo(0, 0);
      if (isMainPage) {
        window.location.reload();
      } else {
        navigate("/");
      }
    },
    [isMainPage, navigate]
  );

  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (showDrawer) {
      pushHistory();
    }
    // eslint-disable-next-line
  }, [showDrawer]);

  useEffect(() => {
    if (headerRef.current) {
      const headerRect = headerRef.current.getBoundingClientRect();
      setHeaderHeight(headerRect.height);
    }
    // eslint-disable-next-line
  }, [headerRef.current, setHeaderHeight, language, isWithAppPage, hideAwardBanner]);

  const onClickMainSection = (e: React.MouseEvent, menu: MainArticle) => {
    e.preventDefault();
    setSelectedSection(menu);
    const offsetTop = getArticleElementOffsetTop(menu);
    if (offsetTop !== null) {
      window.scrollBy({ top: offsetTop - scrollY, left: 0, behavior: "smooth" });
    }
  };

  const confirmAndSignOut = () => {
    openConfirmModal(
      {
        title: "로그아웃",
        desc: "로그아웃 하시겠어요?",
      },
      () =>
        auth.signOut(() => {
          closeModal();
          removeUserInfo();
          navigate("/");
        })
    );
  };

  const activeMenuBaseLine = useMemo(() => window.innerHeight / 3, []);

  useEffect(() => {}, [hideAwardBanner]);

  useEffect(() => {
    if (!isMainPage) {
      setScrollingSection(MainArticle.NONE);
      return;
    }

    const basedScrollY = scrollY + activeMenuBaseLine;

    if (article1Element.current && article2Element.current && article3Element.current && article4Element.current) {
      const article1OffsetTop = article1Element.current.offsetTop;
      const article2OffsetTop = article2Element.current.offsetTop;
      const article3OffsetTop = article3Element.current.offsetTop;
      const article4OffsetTop = article4Element.current.offsetTop;

      switch (true) {
        case basedScrollY < article1OffsetTop:
          setScrollingSection(MainArticle.NONE);
          break;
        case basedScrollY < article2OffsetTop:
          setScrollingSection(MainArticle.ARTICLE1);
          break;
        case basedScrollY < article3OffsetTop:
          setScrollingSection(MainArticle.ARTICLE2);
          break;
        case basedScrollY < article4OffsetTop:
          setScrollingSection(MainArticle.ARTICLE3);
          break;
        case article4OffsetTop < basedScrollY:
          setScrollingSection(MainArticle.ARTICLE4);
          break;
      }
    }
    // eslint-disable-next-line
  }, [isMainPage, scrollY, activeMenuBaseLine, article1Element, article2Element, article3Element, article4Element]);

  // 임시 추가
  const location = useLocation().pathname;

  return (
    <header
      className={`${style.header} ${!hideAwardBanner && style.headerBanner} ${isWithAppPage ? style.hide : ""} ${
        canLogin ? "" : style.noLoginBar
      }`}
      ref={headerRef}
    >
      {canLogin ? <LoginBar signOut={confirmAndSignOut} /> : <></>}
      <div className={style.headerBg}>
        <div className={style.logo}>
          <h1 className="blind">{t("heading1")}</h1>
          {location === "/withuser" ? (
            <img src={kpassLogo} alt={t("logoAlt")} />
          ) : (
            <Link to="/" onClick={onClickLogo}>
              <img src={kpassLogo} alt={t("logoAlt")} />
            </Link>
          )}
        </div>
        {location !== "/withuser" && (
          <div className={style.menuArea}>
            <ul
              className={`${style.mainTopMenu} ${isMainPage ? "" : style.hide} ${isNotKorean ? style.isNotKorean : ""}`}
            >
              <li
                className={`${style.menuItem} ${style.parentWish} ${getLanguageStyle(style)} ${
                  scrollingSection === MainArticle.ARTICLE1 ? style.active : ""
                }`}
              >
                <button className={style.anchor} onClick={(e) => onClickMainSection(e, MainArticle.ARTICLE1)}>
                  <span className={style.normalText}>{t("linkMedia")}</span>
                  <span className={`${style.activeText} ${isNotKorean ? style.notKO : ""}`}>{t("linkMedia")}</span>
                </button>
              </li>
              <li
                className={`${style.menuItem} ${style.aboutKpass} ${getLanguageStyle(style)} ${
                  scrollingSection === MainArticle.ARTICLE2 ? style.active : ""
                }`}
              >
                <button className={style.anchor} onClick={(e) => onClickMainSection(e, MainArticle.ARTICLE2)}>
                  <span className={style.normalText}>{t("linkKpass")}</span>
                  <span className={`${style.activeText} ${isNotKorean ? style.notKO : ""}`}>{t("linkKpass")}</span>
                </button>
              </li>
              <li
                className={`${style.menuItem} ${style.competitiveness} ${getLanguageStyle(style)} ${
                  scrollingSection === MainArticle.ARTICLE3 ? style.active : ""
                }`}
              >
                <button className={style.anchor} onClick={(e) => onClickMainSection(e, MainArticle.ARTICLE3)}>
                  <span className={style.normalText}>{t("linkCompetitiveness")}</span>
                  <span className={`${style.activeText} ${isNotKorean ? style.notKO : ""}`}>
                    {t("linkCompetitiveness")}
                  </span>
                </button>
              </li>
              <li
                className={`${style.menuItem} ${style.realReview} ${getLanguageStyle(style)} ${
                  scrollingSection === MainArticle.ARTICLE4 ? style.active : ""
                }`}
              >
                <button className={style.anchor} onClick={(e) => onClickMainSection(e, MainArticle.ARTICLE4)}>
                  <span className={style.normalText}>{t("linkRealReview")}</span>
                  <span className={`${style.activeText} ${isNotKorean ? style.notKO : ""}`}>{t("linkRealReview")}</span>
                </button>
              </li>
            </ul>
            {canLogin && (
              <div className={style.buttonWrapper}>
                {!isPartnerCompanyPage && (!isMobile || (isMobile && isPartnershipPage)) && (
                  <button className={style.partnerCompanyButton} onClick={() => navigate(`/partnerlist`)}>
                    <div className={style.icon}>
                      <div className={style.defaultIcon}></div>
                      <div className={style.hoverIcon}></div>
                    </div>
                    <span className={style.title}>협력사</span>
                  </button>
                )}
                {!isPartnershipPage && (
                  <button className={style.partnershipButton} onClick={() => navigate(`/partnership`)}>
                    <div className={style.hoverBg}></div>
                    <img src={partnershipIcon} alt={"partnership icon"} />
                    <span className={style.title}>제휴 문의</span>
                  </button>
                )}
              </div>
            )}
            <button className={style.hamburger} onClick={onClickHamburgerBtn}>
              <img src={hamburgerBtn} alt={t("hamburgerButtonAlt")} />
            </button>
          </div>
        )}
      </div>
      <DrawerMenu showDrawer={showDrawer} closeDrawer={closeDrawer} signOut={confirmAndSignOut} />
      {isProdStatus && <GoogleAnalytics />}
    </header>
  );
}
