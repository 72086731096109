import { MainApi } from "./MainApi";
import { UserProfile } from "../types/user";
import {
    ConsultantReviewPostDTO,
    ConsultationApplyDTO, ConsultationCancelDTO,
    ConsultationData,
    ConsultationPutDTO,
    ConsultationReservationPostDTO
} from "../types/consultation";

export class ConsultationApi {
    static consultationUrl = `${MainApi.urlPrefix}/user/counseling`;

    static getConsultationHistory = (profileIdx: number, pageable: { page: number; size: number }) => () =>
        MainApi.api.get<UserProfile>(
            `${ConsultationApi.consultationUrl}/test-history/${profileIdx}`,
            { params: { ...pageable } } // 쿼리 파라미터로 pageable 추가
        );

    static getCounselingResult = (counselingIdx: number) => () =>
        MainApi.api.get<ConsultationData>(`${ConsultationApi.consultationUrl}/${counselingIdx}`);

    // 예약 가능 테이블 조회 API
    static getAvailableReservation = (body: ConsultationReservationPostDTO) => () =>
        MainApi.api.post(`${ConsultationApi.consultationUrl}/reservation`,body);

    // 상담 신청 API
    static applyCounseling = (body: ConsultationApplyDTO) => () =>
        MainApi.api.post(`${ConsultationApi.consultationUrl}`,body);

    // 상담 취소 API
    static cancelCounseling = (body: ConsultationCancelDTO) => () =>
        MainApi.api.post(`${ConsultationApi.consultationUrl}/cancel`,body);

    // 상담 신청 수정 API
    static putCounseling = ({body, idx}: { body:ConsultationPutDTO, idx:number }) => () =>
        MainApi.api.put(`${ConsultationApi.consultationUrl}/${idx}`,body);

    // 배정 상담사 조회 API
    static getConsultantInfo = (counselingIdx:number) => () =>
        MainApi.api.get(`${ConsultationApi.consultationUrl}/consultant/${counselingIdx}`);

    // 리뷰 작성 API
    static postConsultationReview = ({body ,counselingIdx}: { body :ConsultantReviewPostDTO, counselingIdx:number }) => () =>
        MainApi.api.post(`${ConsultationApi.consultationUrl}/review/${counselingIdx}`, body);

    // 상담 이용권 갯수 조회 API
    static getCounselingVoucher = () => () =>
        MainApi.api.get(`${ConsultationApi.consultationUrl}/voucher`);
}
