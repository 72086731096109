import { useMainScrolling, useScrollingEvent } from "./hooks";
import { useEffect } from "react";

interface Args {
  refCurrent: HTMLElement | null;
  setShow: (_: boolean) => void;
}
export function useShowOrHide({ refCurrent, setShow }: Args) {
  const { showOrHide } = useMainScrolling();
  const { scrollY } = useScrollingEvent();

  useEffect(() => {
    showOrHide(refCurrent, setShow);
  }, [scrollY, showOrHide, refCurrent, setShow]);
}
