import { Article4Section1 } from "./article4/Article4Section1";
import React from "react";

export function MainArticle4() {
  return (
    <article>
      <Article4Section1 />
    </article>
  );
}
