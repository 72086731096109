import style from "./subtitleNumbering.module.scss";

interface Props {
  lightNumber: number;
  boldNumber: number;
  fontSize?: string;
}

export function SubtitleNumbering({ lightNumber, boldNumber, fontSize }: Props) {
  return (
    <span className={style.numbers} style={{ fontSize }}>
      <span className={style.lightNumber}>{lightNumber}</span>
      {boldNumber}
    </span>
  );
}
