import style from "./directionButton.module.scss";
import { useMemo } from "react";

interface Props {
  direction: "next" | "prev";
  width: number;
  onClickFunc: () => void;
  className?: string;
}
export function DirectionButton({ direction, width, onClickFunc, className }: Props) {
  const isNext = useMemo(() => direction === "next", [direction]);

  return (
    <button
      className={`${style.directionButton} ${isNext ? style.next : style.prev} ${className ?? ""}`}
      style={{ width: `${width}px`, height: `${width}px` }}
      onClick={onClickFunc}
    ></button>
  );
}
