import style from "../../../../assets/scss/common/modal/addConsultationModal.module.scss";
import React from "react";
import {ValidateStatus} from "../login/signup/hooks/useSignupValidator";

interface Args{
    inquiry: string;
    setInquiry: (_:string) => void;
    isInquiryValidate: ValidateStatus;
}
export function ReservationInquiry({inquiry, setInquiry, isInquiryValidate}:Args) {
    /**
     * 사전문의 내용 작성 ~ 영역
     */
    const handleInquiryChange = (event: any) => {
        setInquiry(event.target.value);
    };
    return (
        <div className={style.innerTextContainer}>
            <div className={style.infoContainer}>
                <span className={style.infoTitleText}> 사전문의 내용 작성</span>
                <span className={style.innerTextDesc}>
                상담사에게 전할 사전문의 내용을 작성해 주세요. <br/>
                상담 받고 싶은 내용을 상세히 작성해 주시면 상담에 큰 도움이 될 수 있습니다.
                <br/>
              </span>
                <div className={style.textareaInputContainer}>
                <textarea
                    placeholder="사전문의 2~1,000자리 입력"
                    className={style.textareaInput}
                    value={inquiry}
                    onChange={handleInquiryChange}
                />
                    {isInquiryValidate !== ValidateStatus.NONE &&(
                        <span className={style.warning}>문의는 2글자 이상 1000자 이하로 작성해주세요.</span>
                    )}
                </div>
            </div>
        </div>

    )
}