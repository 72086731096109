import {useAppDispatch} from "../../../hooks/hooks";
import {useCallback, useEffect, useState} from "react";
import {getProfileAuthTokenAsync} from "../../../store/profile/profileSlice";
import {VoucherData} from "../../../types/voucher";
interface Args{
    voucherData: VoucherData[];
    setVoucherData: (_: (prevVoucherData: VoucherData[]) => (VoucherData | {
        organVoucherCnt: number;
        organKpassVoucherCnt: number;
        organDcasVoucherCnt: number;
        voucherCnt: number
    })[]) => void
}
export function useProfileInfoGetter({voucherData, setVoucherData}:Args) {
    const dispatch = useAppDispatch();
    const [accessToken, setAccessToken] = useState('');


    const getAuthToken = useCallback(
        async (param: { userProfileIdx: number }) => {
          try {
            const response: any = await dispatch(getProfileAuthTokenAsync(param)).unwrap();

            if (response && typeof response === "object" && "accessToken" in response) {
              setAccessToken(response.accessToken);
              return response.accessToken;
            } else {
              console.warn("응답에 Access Token이 없습니다.");
            }
          } catch (error) {
            console.error("인증 토큰을 가져오는 중 오류 발생:", error);
          }
        },
        [dispatch]
    );

    const fetchVoucherData = useCallback(async () => {
      if (!accessToken) {
        console.warn("Access Token이 없습니다.");
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/kpass/game-content/profile/voucher`, {
          method: "GET",
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const response2 = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/dcas/game-content/profile/voucher`, {
          method: "GET",
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok || !response2.ok) {
          throw new Error(`Error: ${response.status || response2.status}`);
        }

        const data = await response.json();
        const data2 = await response2.json();

        // ORGAN_MANAGER의 KPASS와 DCAS 값을 추출
        const organKpass =
            data2.voucherInformation.find(
                (voucher: any) => voucher.whose === "ORGAN_MANAGER" && voucher.unitVoucherType === "KPASS"
            )?.quantity || 0;

        const organDcas =
            data2.voucherInformation.find(
                (voucher: any) => voucher.whose === "ORGAN_MANAGER" && voucher.unitVoucherType === "DCAS"
            )?.quantity || 0;

        setVoucherData(prevVoucherData => [
            ...prevVoucherData,  // 기존 배열의 모든 요소를 복사
            {
                voucherCnt: data.voucherCnt,
                organDcasVoucherCnt: organDcas,
                organKpassVoucherCnt: organKpass,
                organVoucherCnt: organKpass + organDcas, // ORGAN_MANAGER의 KPASS와 DCAS의 총합을 사용
            }
        ]);
      } catch (error) {
        console.error("Failed to fetch voucher data:", error);
      }
    }, [accessToken]);


    useEffect(() => {
        fetchVoucherData().then()
    }, [dispatch,fetchVoucherData, getAuthToken]);

    return{
        getAuthToken,
        voucherData,fetchVoucherData
    }
}