import style from "./mainArticle3.module.scss";
import disadvantageTitleIcon from "../../../assets/images/main/article3/section1/part2/disadvantage_title_icon.svg";
import advantageTitleIcon from "../../../assets/images/main/article3/section1/part2/advantage_title_icon.svg";
import advantageItem1 from "../../../assets/images/main/article3/section1/part2/ico_advantage1.svg";
import advantageItem2 from "../../../assets/images/main/article3/section1/part2/ico_advantage2.svg";
import advantageItem3 from "../../../assets/images/main/article3/section1/part2/ico_advantage3.svg";
import advantageItem4 from "../../../assets/images/main/article3/section1/part2/ico_advantage4.svg";
import advantageItem5 from "../../../assets/images/main/article3/section1/part2/ico_advantage5.svg";
import advantageItem6 from "../../../assets/images/main/article3/section1/part2/ico_advantage6.svg";
import advantageItem7 from "../../../assets/images/main/article3/section1/part2/ico_advantage7.svg";
import { useMemo, useRef, useState } from "react";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { useUserAgent } from "../../../hooks/hooks";
import { PeaceCharacter } from "./PeaceCharacter";
import { Trans, useTranslation } from "react-i18next";

export function Section1ProsAndCons() {
  const { t } = useTranslation(["competitiveness"]);
  const { isMobile } = useUserAgent();
  const contentRef = useRef<HTMLDivElement>(null);
  const disadvantageRef = useRef<HTMLDivElement>(null);
  const advantageRef = useRef<HTMLDivElement>(null);
  const [showContentInDesktop, setShowContentInDesktop] = useState(false);
  const [showDisadvantage, setShowDisadvantage] = useState(false);
  const [showAdvantage, setShowAdvantage] = useState(false);
  useShowOrHide({ refCurrent: contentRef.current, setShow: setShowContentInDesktop });
  useShowOrHide({ refCurrent: disadvantageRef.current, setShow: setShowDisadvantage });
  useShowOrHide({ refCurrent: advantageRef.current, setShow: setShowAdvantage });

  const showDisadvantageArea = useMemo(
    () => (!isMobile && showContentInDesktop) || (isMobile && showDisadvantage),
    [isMobile, showContentInDesktop, showDisadvantage]
  );
  const showAdvantageArea = useMemo(
    () => (!isMobile && showContentInDesktop) || (isMobile && showAdvantage),
    [isMobile, showContentInDesktop, showAdvantage]
  );

  return (
    <div className={style.prosAndCons}>
      <div className={style.hillBg}></div>
      <div className={style.contentWrapper}>
        <div className={style.content} ref={contentRef}>
          <div
            className={`${style.disadvantage} ${showDisadvantageArea ? style.show : style.hide}`}
            ref={disadvantageRef}
          >
            <div className={style.title}>
              <h3 className={"blind"}>{t("section1.disadvantage.heading3")}</h3>
              <img src={disadvantageTitleIcon} alt={t("section1.disadvantage.disadvantageImageAlt")} />
              <span>
                <Trans
                  t={t}
                  i18nKey={"section1.disadvantage.title"}
                  components={[<StrongTextStyle></StrongTextStyle>]}
                />
              </span>
            </div>
            <ul className={style.descriptions}>
              <li className={style.aDescription}>
                <h4 className={style.title}>{t("section1.disadvantage.reason1.title")}</h4>
                <span className={style.desc}>{t("section1.disadvantage.reason1.description")}</span>
              </li>
              <li className={style.aDescription}>
                <h4 className={style.title}>{t("section1.disadvantage.reason2.title")}</h4>
                <span className={style.desc}>{t("section1.disadvantage.reason2.description")}</span>
              </li>
              <li className={style.aDescription}>
                <h4 className={style.title}>{t("section1.disadvantage.reason3.title")}</h4>
                <span className={style.desc}>{t("section1.disadvantage.reason3.description")}</span>
              </li>
              <li className={style.aDescription}>
                <h4 className={style.title}>{t("section1.disadvantage.reason4.title")}</h4>
                <span className={style.desc}>{t("section1.disadvantage.reason4.description")}</span>
              </li>
              <li className={style.aDescription}>
                <h4 className={style.title}>{t("section1.disadvantage.reason5.title")}</h4>
                <span className={style.desc}>{t("section1.disadvantage.reason5.description")}</span>
              </li>
              <li className={style.aDescription}>
                <h4 className={style.title}>{t("section1.disadvantage.reason6.title")}</h4>
                <span className={style.desc}>{t("section1.disadvantage.reason6.description")}</span>
              </li>
              <li className={style.aDescription}>
                <h4 className={style.title}>{t("section1.disadvantage.reason7.title")}</h4>
                <span className={style.desc}>{t("section1.disadvantage.reason7.description")}</span>
              </li>
            </ul>
          </div>
          {isMobile && (
            <PeaceCharacter className={`${style.peace} ${showContentInDesktop ? style.show : style.hide}`} />
          )}
          <div className={`${style.advantage} ${showAdvantageArea ? style.show : style.hide}`} ref={advantageRef}>
            {!isMobile && (
              <PeaceCharacter className={`${style.peace} ${showContentInDesktop ? style.show : style.hide}`} />
            )}
            <div className={style.title}>
              <h3 className={"blind"}>{t("section1.advantage.heading3")}</h3>
              <img src={advantageTitleIcon} alt={t("section1.advantage.advantageImageAlt")} />
              <span>
                <Trans t={t} i18nKey={"section1.advantage.title"} components={[<StrongTextStyle></StrongTextStyle>]} />
              </span>
            </div>
            <ul className={style.descriptions}>
              <li className={style.aDescription}>
                <div className={style.icon}>
                  <img src={advantageItem1} alt={t("section1.advantage.reason1.imageAlt")} />
                </div>
                <div className={style.texts}>
                  <h4 className={style.title}>{t("section1.advantage.reason1.title")}</h4>
                  <span className={style.desc}>{t("section1.advantage.reason1.description")}</span>
                </div>
              </li>
              <li className={style.aDescription}>
                <div className={style.icon}>
                  <img src={advantageItem2} alt={t("section1.advantage.reason2.imageAlt")} />
                </div>
                <div className={style.texts}>
                  <h4 className={style.title}>{t("section1.advantage.reason2.title")}</h4>
                  <span className={style.desc}>{t("section1.advantage.reason2.description")}</span>
                </div>
              </li>
              <li className={style.aDescription}>
                <div className={style.icon}>
                  <img src={advantageItem3} alt={t("section1.advantage.reason3.imageAlt")} />
                </div>
                <div className={style.texts}>
                  <h4 className={style.title}>{t("section1.advantage.reason3.title")}</h4>
                  <span className={style.desc}>{t("section1.advantage.reason3.description")}</span>
                </div>
              </li>
              <li className={style.aDescription}>
                <div className={style.icon}>
                  <img src={advantageItem4} alt={t("section1.advantage.reason4.imageAlt")} />
                </div>
                <div className={style.texts}>
                  <h4 className={style.title}>{t("section1.advantage.reason4.title")}</h4>
                  <span className={style.desc}>{t("section1.advantage.reason4.description")}</span>
                </div>
              </li>
              <li className={style.aDescription}>
                <div className={style.icon}>
                  <img src={advantageItem5} alt={t("section1.advantage.reason5.imageAlt")} />
                </div>
                <div className={style.texts}>
                  <h4 className={style.title}>{t("section1.advantage.reason5.title")}</h4>
                  <span className={style.desc}>
                    {t("section1.advantage.reason5.description1")}
                    <br /> {t("section1.advantage.reason5.description2")}
                  </span>
                </div>
              </li>
              <li className={style.aDescription}>
                <div className={style.icon}>
                  <img src={advantageItem6} alt={t("section1.advantage.reason6.imageAlt")} />
                </div>
                <div className={style.texts}>
                  <h4 className={style.title}>{t("section1.advantage.reason6.title")}</h4>
                  <span className={style.desc}>{t("section1.advantage.reason6.description")}</span>
                </div>
              </li>
              <li className={style.aDescription}>
                <div className={style.icon}>
                  <img src={advantageItem7} alt={t("section1.advantage.reason7.imageAlt")} />
                </div>
                <div className={style.texts}>
                  <h4 className={style.title}>{t("section1.advantage.reason7.title")}</h4>
                  <span className={style.desc}>{t("section1.advantage.reason7.description")}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={style.ellipseBgWrapper}>
        <div className={style.ellipseBg}></div>
      </div>
    </div>
  );
}
