import { ScrollingEventContext } from "./ScrollingEventContext";
import { ChildrenProp } from "../../types/common";
import { useCallback, useEffect, useState } from "react";
import { throttle } from "underscore";

export function ScrollingEventProvider({ children }: ChildrenProp) {
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isStoppedScrolling, setIsStoppedScrolling] = useState(false);

  useEffect(() => {
    const defaultScrollListener = throttle(() => {
      setPrevScrollY(scrollY);
      setScrollY(window.scrollY);
    }, 20);

    // const defaultScrollListener = (e: Event) => {
    //   setScrollY(window.scrollY);
    // };

    window.addEventListener("scroll", defaultScrollListener);
    return () => {
      window.removeEventListener("scroll", defaultScrollListener);
    };
  }, [scrollY]);

  const stopScroll = useCallback(() => {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.style.overflowY = "hidden";
      setIsStoppedScrolling(true);
    }
  }, [setIsStoppedScrolling]);

  const stopScrollYAndFix = useCallback(
    (y: number) => {
      const htmlElement = document.querySelector("html");
      if (htmlElement) {
        htmlElement.style.overflowY = "hidden";
        window.scrollTo(0, y);
        setIsStoppedScrolling(true);
      }
    },
    [setIsStoppedScrolling]
  );

  const restartScroll = useCallback(() => {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.style.overflowY = "auto";
      setIsStoppedScrolling(false);
    }
  }, [setIsStoppedScrolling]);

  return (
    <ScrollingEventContext.Provider
      value={{
        prevScrollY,
        scrollY,
        stopScrollYAndFix,
        restartScroll,
        stopScroll,
        isStoppedScrolling,
      }}
    >
      {children}
    </ScrollingEventContext.Provider>
  );
}
