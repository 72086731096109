import style from "./review.module.scss";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { Pagination } from "../../common/pagination/Pagination";
import { useAppSelector } from "../../../hooks/hooks";
import { reviewsState, reviewTotalCountState } from "../../../store/review/reviewSlice";
import { AReviewInList } from "./list/AReviewInList";
import { ReviewListVO } from "../../../types/review";
import { Fragment, RefObject } from "react";
import { numberWithCommas } from "../../../util/commonFunctions";
import { useTranslation } from "react-i18next";
import { useLocationHistory } from "../../../hooks/location/useLocationHistory";

interface Props {
  reviewListRef: RefObject<HTMLElement>;
}

export function ReviewSection3({ reviewListRef }: Props) {
  const { t } = useTranslation(["subRealReview"]);
  const reviews = useAppSelector(reviewsState);
  const reviewTotalCount = useAppSelector(reviewTotalCountState);
  const { pushHistory } = useLocationHistory({});

  return (
    <section className={style.section3} ref={reviewListRef}>
      <span className={style.section3Count}>
        {t("section2.totalReview")}
        <StrongTextStyle>
          {numberWithCommas(reviewTotalCount ?? 0)} {t("count")}
        </StrongTextStyle>
      </span>
      <div className={style.section3List}>
        {reviews?.content.map((aReview: ReviewListVO, idx: number) => (
          <Fragment key={`review-${aReview.idx}`}>
            <AReviewInList aReview={aReview} />
            {idx < reviews.content.length - 1 ? <div className={style.section3ListDivider}></div> : <></>}
          </Fragment>
        ))}
      </div>
      <div className={style.section3Pagination}>
        {reviews ? (
          <Pagination goSpecificPage={(pageNum) => pushHistory(`?page=${pageNum}&showList=true`)} page={reviews} />
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}
