import { useCallback } from "react";
import { sendingSignInCodeAsnyc } from "../../../../../store/login/loginSlice";
import { ErrorType } from "../../../../../types/error";
import { useAppDispatch, useModal } from "../../../../../hooks/hooks";
import { RequestCodeType } from "../../../../../types/auth";

export function useCodeSender() {
  const dispatch = useAppDispatch();
  const { openAlertModal } = useModal();

  const sendCode = useCallback(
    async (email: string, type: RequestCodeType) => {
      try {
        return await dispatch(sendingSignInCodeAsnyc({ email: email.trim(), requestCodeType: type })).unwrap();
      } catch (error) {
        const errorType: ErrorType = JSON.parse((error as Error).message);
        switch (errorType?.errorCode?.httpCode) {
          case 462:
            openAlertModal({
              title: "안내",
              desc: "잠시 후 시도해 주세요.",
            });
            break;
          default:
            console.error(error);
            openAlertModal(null);
            break;
        }
        throw error;
      }
    },

    [dispatch, openAlertModal]
  );

  return {
    sendCode,
  };
}
