import { SliceState, Status } from "../../types/common";
import { CreateOrderParam, OrderedProduct, OrderDetail, AffiliateCodeCommissionCheckerParam } from "../../types/order";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { DiscountType } from "../../types/product";
import { addCases, executePromise } from "../../util/sliceUtil";
import { OrderApi } from "../../api/OrderApi";
import { ReadyToPayment } from "../../types/payment";

export interface OrderState extends SliceState {
  orderedProducts?: OrderedProduct[];
}

const initialState: OrderState = {
  status: Status.IDLE,
};

export const postOrderAsync = createAsyncThunk("order/postOrder", (orderParam: CreateOrderParam) =>
  executePromise<ReadyToPayment>(OrderApi.postOrder(orderParam))
);

export const getOrderDetailAsync = createAsyncThunk("order/getOrderDetail", (orderIdx: number) =>
  executePromise<OrderDetail>(OrderApi.getOrder(orderIdx))
);

export const getRefundOrderAsync = createAsyncThunk("order/getRefundOrder", (orderIdx: number) =>
  executePromise<OrderDetail>(OrderApi.getRefundOrder(orderIdx))
);

export const getAffiliateCodeAsync = createAsyncThunk("order/getAffiliateCode", (code: string) =>
  executePromise(OrderApi.getAffiliateCode(code))
);

export const checkAffiliateCodeCommissionAsync = createAsyncThunk(
  "order/checkAffiliateCodeCommission",
  (param: AffiliateCodeCommissionCheckerParam) => executePromise(OrderApi.checkAffiliateCodeCommission(param))
);

export const getOrdersAsync = createAsyncThunk("order/getOrders", async (orderIdx: number) =>
  executePromise(OrderApi.getOrders(orderIdx))
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderedProducts: (state, action: PayloadAction<OrderedProduct[]>) => {
      const arr = action.payload;
      state.orderedProducts = arr.map((productToOrder) => {
        const product = productToOrder.product;
        const quantity = productToOrder.quantity;
        const totalRegularPrice = product.regularPrice * quantity;

        let totalDiscountAmount = 0;
        switch (product.discountType) {
          case DiscountType.AMOUNT:
            totalDiscountAmount = product.discountValue * quantity;
            break;
          case DiscountType.PERCENT:
            totalDiscountAmount = (product.discountValue / 100) * product.regularPrice * quantity;
            break;
        }

        return {
          product,
          quantity,
          totalRegularPrice,
          totalDiscountAmount,
          totalActualAmount: totalRegularPrice - totalDiscountAmount,
        };
      });
    },
  },
  extraReducers: (builder) => {
    addCases(builder, postOrderAsync);
    addCases(builder, getOrderDetailAsync);
    addCases(builder, getAffiliateCodeAsync);
    addCases(builder, getOrdersAsync);
  },
});

export const { setOrderedProducts } = orderSlice.actions;

export const orderStatus = (state: RootState) => state.order.status;
export const orderedProducts = (state: RootState) => state.order.orderedProducts;

export default orderSlice.reducer;
