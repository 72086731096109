import styled from "styled-components";
import { ErrorMessageContainerStyle, ErrorMessageStyle, InputTextAreaStyle } from "./StyledComponents";
import { TextCounter } from "./TextCounter";
import { ChangeEvent, MutableRefObject, useCallback, useEffect, useState } from "react";
import { breakpoints } from "../../../../../../theme/theme";

const InputTextAreaContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;

  ${breakpoints.mobile} {
    gap: 6px;
  }
`;

interface Props {
  textRef: MutableRefObject<HTMLTextAreaElement | null>;
  defaultText?: string;
  placeholder: string;
  maxLength: number;
  onChange: (newText: string) => void;
  hasError: boolean;
  errorMessage: string;
}

export function InputTextArea({
  textRef,
  defaultText,
  placeholder,
  maxLength,
  onChange,
  hasError,
  errorMessage,
}: Props) {
  const [text, setText] = useState(defaultText ?? "");
  useEffect(() => setText(defaultText ?? ""), [defaultText, setText]);

  const onChangeText = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newText = e.target.value;
      if (newText.length > maxLength) return;
      setText(newText);
    },
    [setText, maxLength]
  );

  const onFocusOut = useCallback(() => onChange(text), [onChange, text]);
  return (
    <InputTextAreaContainerStyle>
      <InputTextAreaStyle
        ref={textRef}
        value={text}
        placeholder={placeholder}
        onChange={onChangeText}
        onBlur={onFocusOut}
      />
      <ErrorMessageContainerStyle>
        {hasError && <ErrorMessageStyle>{errorMessage}</ErrorMessageStyle>}
        <TextCounter currentCount={text.length} maxCount={maxLength} />
      </ErrorMessageContainerStyle>
    </InputTextAreaContainerStyle>
  );
}
