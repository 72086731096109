import style from "./eventRegistererModal.module.scss";
import { RefObject } from "react";
import { getOnlyNumberText } from "../../../../util/commonFunctions";

interface Props {
  phoneNumber: string;
  setPhoneNumber: (_: string) => void;
  phoneNumberRef: RefObject<HTMLInputElement>;
  invalidMessage?: string;
}
export function EventRegistererPhoneNumberField({
  phoneNumber,
  setPhoneNumber,
  phoneNumberRef,
  invalidMessage,
}: Props) {
  return (
    <div className={style.field}>
      <label className={style.label}>부모님 휴대폰 번호</label>
      <input
        className={`${style.inputText} ${phoneNumber ? style.inputted : ""}`}
        required={true}
        minLength={10}
        maxLength={11}
        type={"tel"}
        name={"phone"}
        placeholder={"‘-’ 없이 숫자만 입력해 주세요."}
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(getOnlyNumberText(e.target.value))}
        ref={phoneNumberRef}
      />
      {invalidMessage && <span className={style.invalidMessage}>{invalidMessage}</span>}
    </div>
  );
}
