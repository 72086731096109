import style from "../../../../assets/scss/sub/purchase/cashReceiptInfo.module.scss";

export function PaymentOnAccountNotice() {
  return (
    <ul className={style.noticeTexts}>
      <li>입금 완료 후 이용권이 지급됩니다.</li>
      <li>입금하기 전 받는 분의 이름이 "(주) 필굿" 인지 반드시 확인해 주시기 바랍니다.</li>
      <li>
        주문 후 7일 이내 입금 확인이 되지 않을 경우, 가상 계좌번호는 자동 소멸되며, 해당 주문 건도 자동 취소됩니다.
      </li>
      <li>정확한 금액을 입금해야 결제 완료 처리가 되오니 확인 후 입금 부탁드립니다.</li>
      <li>주문자와 다른 분이 입금하셔도 별도의 입금확인 요청을 하실 필요가 없습니다.</li>
      <li>가상 결제 후 10분 이내로 이용권이 지급되지 않은 경우 고객센터로 문의 바랍니다.</li>
    </ul>
  );
}
