import loginStyle from "../../../../../../assets/scss/common/modal/loginModal.module.scss";
import memberStyle from "../../../../../../assets/scss/sub/editMember.module.scss";
import React, {RefObject, useState} from "react";
import {Address} from "../../../../../../types/user";
import {Option} from "../../../../../../types/signup";
import {useDaumPostcodePopup} from "react-daum-postcode";
import {SignupForeignAddressField} from "./SignupForeignAddressField";
import {StyleRequestType} from "../../../../../../types/common";

interface Props{
    address: Address;
    setAddress: (_: Address) => void;
    showNation: boolean;
    setShowNation: (_: boolean) => void;
    styleRequestType:StyleRequestType;
    addressRef?: RefObject<HTMLDivElement>;
    addressDetailRef?: RefObject<HTMLInputElement>;
    foreignAddressDetailRef?: RefObject<HTMLInputElement>;
}
export function SignupAddressField(
    {address, setAddress, showNation, setShowNation,
        styleRequestType, addressRef, addressDetailRef, foreignAddressDetailRef
}:Props){
    const styles = styleRequestType === StyleRequestType.SIGN_UP ? loginStyle :  memberStyle;
    const [nation, setNation] = useState(styleRequestType === StyleRequestType.SIGN_UP ? "" : address.country);
    const nationOptions: Option[] = [
        { key: '', value: '국가를 선택해주세요.'},
        { key: 'KR', value: '대한민국'},
        { key: 'US', value: '미국'},
        { key: 'MN', value: '몽골'},
        { key: 'TW', value: '대만'},
        { key: 'VN', value: '베트남'},
    ];
    const handleOptionClick = (option: Option) => {
        setNation(option.key);
        setAddress({
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            country: option.key,
            postalCode: '',
        });
        setShowNation(false);
    };
    const nationality = address.country;

    const open = useDaumPostcodePopup();
    const onClickFindingAddress = () => {
        open({
            onComplete: (data) => {
                const { sido, sigungu, address, zonecode } = data;
                const addr1 = address.replace(sido, "").replace(sigungu, "").trim();
                setAddress({
                    address1: addr1,
                    address2: "",
                    city: sigungu,
                    state: sido,
                    postalCode: zonecode,
                    country:nation,
                })
                setAddress({
                    country:nation,
                    address1: addr1,
                    address2: "",
                    city: sigungu,
                    state: sido,
                    postalCode: zonecode,
                });
            },
        }).then();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setAddress({
            ...address,
            address2: value
        });
    };

    const addressByLanguage = () => {
        if(address.country === ''){
            <></>
        } else if (address.country === 'KR') {
            return (
                <>
                    <div className={styles.addressWrapper}>
                        <div className={styles.address} ref={addressRef}>
                            {address.city === '' ? (
                                <span className={styles.addressText}>주소검색</span>
                            ) : (
                                <span className={`${styles.addressText} ${styles.selected}`}>
                                    {address.state} {address.city} {address.address1}
                                </span>
                            )}
                        </div>
                        <div className={styles.addressSearchBtn} onClick={onClickFindingAddress}>
                            <span className={styles.addressSearchText}>주소찾기</span>
                        </div>
                    </div>
                    <div className={styles.inputWrapper}>
                        <input
                            ref={addressDetailRef}
                            type="name"
                            className={styles.addressInput}
                            value={address.address2 || ''}
                            onInvalid={(e) => e.preventDefault()}
                            onChange={handleInputChange}
                            placeholder={"상세주소를 입력해주세요."}
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <SignupForeignAddressField title={'State'} setValue={setAddress} address={address}
                                               addressRef={addressRef} foreignAddressRef={foreignAddressDetailRef}
                                               styleRequestType={styleRequestType}
                    />
                    <SignupForeignAddressField title={'City'} setValue={setAddress} address={address}
                                               addressRef={addressRef} foreignAddressRef={foreignAddressDetailRef}
                                               styleRequestType={styleRequestType}/>
                    <SignupForeignAddressField title={'Zip code'} setValue={setAddress} address={address}
                                               addressRef={addressRef} foreignAddressRef={foreignAddressDetailRef}
                                               styleRequestType={styleRequestType}/>
                    <SignupForeignAddressField title={'Address1'} setValue={setAddress} address={address}
                                               addressRef={addressRef} foreignAddressRef={foreignAddressDetailRef}
                                               styleRequestType={styleRequestType}/>
                    <SignupForeignAddressField title={'Address2'} setValue={setAddress} address={address}
                                               addressRef={addressRef} foreignAddressRef={foreignAddressDetailRef}
                                               styleRequestType={styleRequestType}/>
                </>
            );
        }
    };

    return (
        <div className={styles.selectWrapper}>
            <div className={styles.selectTitleWrapper}>
                <span className={styles.unHighLight}>[선택]</span>
                <span className={styles.titleText}>국가 및 주소</span>
            </div>

            <div className={styles.selectTextFieldWrapper} onClick={() => setShowNation(!showNation)}>
          <span className={`${styles.selectText} ${address.country === '' ? styles.unSelected : ''}`}>
            {nationOptions ? nationOptions.find(option => option.key === address.country)?.value : '국적을 선택해 주세요'}
          </span>
                <div className={styles.arrowImg}></div>
                {showNation && (
                    <div className={`${styles.optionStyle}  ${styles.nationStyle}`}>
                        {nationOptions.map((option) => (
                            <button
                                key={option.key}
                                className={`${styles.optionTextWrapper} ${option.key === nationality ? styles.selectedOption : ''}`}
                                onClick={() => handleOptionClick(option)}

                            >
                                <span
                                    className={`${styles.optionText} ${option.key === nationality ? styles.selectedOption : ''}`}>{option.value}</span>
                            </button>
                        ))}
                    </div>
                )}
            </div>
            {addressByLanguage()}
        </div>
    )
}