import style from "../../assets/scss/common/header.module.scss";
import closeBtn from "../../assets/images/drawer_menu/top_menu_drawer_close_btn.svg";
import rightArrow from "../../assets/images/drawer_menu/right_arrow.svg";
import rightArrowMobile from "../../assets/images/drawer_menu/right_arrow_mobile.svg";
import logoutIcon from "../../assets/images/drawer_menu/logout_icon.svg";
import downloadIcon from "../../assets/images/drawer_menu/drawer_download_icon.svg";
import downloadIconBold from "../../assets/images/drawer_menu/drawer_download_icon_bold.svg";
import voucherIcon from "../../assets/images/drawer_menu/drawer_voucher_icon.svg";
import voucherIconBold from "../../assets/images/drawer_menu/drawer_voucher_icon_bold.svg";
import partnershipIcon from "../../assets/images/drawer_menu/partnership_icon.svg";
import partnershipIconBold from "../../assets/images/drawer_menu/partnership_icon_bold.svg";
import partnerIcon from "../../assets/images/drawer_menu/partner_icon.svg";
import partnerIconBold from "../../assets/images/drawer_menu/partner_icon_bold.svg";
import searchIcon from "../../assets/images/drawer_menu/search-icon.svg";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAuth, useLanguage, useModal, useUserAgent } from "../../hooks/hooks";
import { ModalType } from "../../types/common";
import { userDetail } from "../../store/user/userSlice";
import { useTranslation } from "react-i18next";
import { useClickEvent } from "../../hooks/useClickEvent";
import { CLICK_TARGET_NAME } from "../../util/events";

interface Props {
  showDrawer: boolean;
  closeDrawer: VoidFunction;
  signOut: VoidFunction;
}

export function DrawerMenu({ showDrawer, closeDrawer, signOut }: Props) {
  const { t } = useTranslation(["drawerMenu"]);
  const { openModal, openConfirmModal } = useModal();
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const [activePath, setActivePath] = useState("home");
  const userInfo = useAppSelector(userDetail);
  const { canLogin, isNotKorean, isKorean } = useLanguage();
  const drawerRef = useRef<HTMLDivElement>(null);
  const { isTablet, isMobile } = useUserAgent();
  useClickEvent({ elementRefs: [drawerRef], doOnClickBg: closeDrawer });

  useEffect(() => {
    const pathArr = location.pathname.split("/");
    setActivePath(pathArr[1]);
  }, [location]);

  const onClickLink = () => {
    window.scrollTo(0, 0);
    closeDrawer();
  };

  const onClickLoginBtn = (e: React.MouseEvent) => {
    openModal(ModalType.LOGIN);
    e.stopPropagation();
  };

  const handleSimpleTestClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isTablet) {
      openConfirmModal(
        {
          title: "K-PASS 간이 검사 안내",
          desc: "K-PASS 간이 검사는 \n가로모드만 지원 합니다. \n가로모드 전환 후 확인을 눌러주세요.",
        },
        () => {
          window.open("https://kpass-simple.feel-good.io", "_blank", "noopener,noreferrer");
        }
      );
    } else if (isMobile) {
      openConfirmModal(
        {
          title: "K-PASS 간이 검사 안내",
          desc: "K-PASS 간이 검사는 \n가로모드만 지원 합니다. \n가로모드 전환 후 확인을 눌러주세요.",
        },
        () => {
          window.open("https://kpass-simple.feel-good.io", "_blank", "noopener,noreferrer");
        }
      );
    } else {
      window.open("https://kpass-simple.feel-good.io", "_blank", "noopener,noreferrer");
    }
  };

  const onClickLogoutBtn = () => signOut();
  const { isDesktop } = useUserAgent();

  const BeforeLoginNode = () => (
    <>
      {canLogin ? (
        <div className={style.beforeLogin}>
          <button className={style.loginBtn} onClick={onClickLoginBtn}>
            <span className={style.btnText}>K-PASS 로그인</span>
            <img src={isDesktop ? rightArrow : rightArrowMobile} alt={"right arrow"} />
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  const AfterLoginNode = () => (
    <div className={style.afterLogin}>
      <div className={style.welcome}>
        <div className={style.text}>
          <span className={style.accent}>{userInfo?.name} 님</span>&nbsp;<span>안녕하세요 :)</span>
        </div>
      </div>
      <p className={style.desc}>
        <Link
          onClick={onClickLink}
          className={`${style.mypageMenu} ${activePath === "mypage" ? style.active : ""}`}
          to="/mypage"
        >
          <span className={style.menuName} style={{ color: "#583BE2" }}>
            {"My Page 이동하기"}
          </span>
        </Link>
      </p>
    </div>
  );

  useEffect(() => {
    if (showDrawer) {
      document.querySelector("html")!.style.overflowY = "hidden";
    } else {
      document.querySelector("html")!.style.overflowY = "auto";
    }
  }, [showDrawer]);

  return (
    <div className={style.drawerArea}>
      <div className={`${style.drawerBg} ${showDrawer ? style.show : ""}`}></div>
      <div
        className={`${style.drawer} ${isNotKorean ? style.ru : ""} ${showDrawer ? style.show : ""}`}
        ref={drawerRef}
        data-name={CLICK_TARGET_NAME}
      >
        <button className={style.closeBtn} onClick={closeDrawer}>
          <img src={closeBtn} alt={t("closeButton")} />
        </button>

        {isLoggedIn ? <AfterLoginNode /> : <BeforeLoginNode />}

        <div className={style.navWrapper}>
          <nav className={style.nav}>
            <ul className={`${style.navList} ${isNotKorean ? style.ru : ""}`}>
              {isKorean && (
                <li className={`${style.menu}`}>
                  <Link
                    onClick={handleSimpleTestClick}
                    className={`${activePath === "" ? style.active : ""} ${style.noGap}`}
                    to="#"
                  >
                    <img src={searchIcon} className={style.searchIcon} alt={'searchIcon'}/>
                    <span className={style.simpleTestMenu}>{t("simpleTest")}</span>
                    <span className={style.freeProviding}>무료 제공!</span>
                  </Link>
                </li>
              )}

              <li className={style.menu}>
                <Link onClick={onClickLink} className={activePath === "" ? style.active : ""} to="/">
                  <span className={style.menuName}>{t("menuHome")}</span>
                  <span className={`${style.activeMenuName} ${isNotKorean ? style.notKO : ""}`}>{t("menuHome")}</span>
                </Link>
              </li>
              {isKorean && (
                <li className={style.menu}>
                  <Link
                    onClick={onClickLink}
                    className={activePath === "startguide" ? style.active : ""}
                    to="/startguide"
                  >
                    <span className={style.menuName} style={{ color: "#583BE2" }}>
                      {t("startGuide")}
                    </span>
                    <span
                      className={`${style.activeMenuName} ${isNotKorean ? style.notKO : ""}`}
                      style={{ color: "#583BE2" }}
                    >
                      {t("startGuide")}
                    </span>
                  </Link>
                </li>
              )}
              <li className={style.menu}>
                <Link
                  onClick={onClickLink}
                  className={activePath === "understanding" ? style.active : ""}
                  to="/understanding/kpass"
                >
                  <span className={style.menuName}>{t("understandingKpass")}</span>
                  <span className={`${style.activeMenuName} ${isNotKorean ? style.notKO : ""}`}>
                    {t("understandingKpass")}
                  </span>
                </Link>
              </li>
              <li className={style.menu}>
                <Link
                  onClick={onClickLink}
                  className={activePath === "purpose-and-characteristics" ? style.active : ""}
                  to="/purpose-and-characteristics"
                >
                  <span className={style.menuName}>{t("purposeAndCharacteristics")}</span>
                  <span className={`${style.activeMenuName} ${isNotKorean ? style.notKO : ""}`}>
                    {t("purposeAndCharacteristics")}
                  </span>
                </Link>
              </li>
              <li className={style.menu}>
                <Link
                  onClick={onClickLink}
                  className={activePath === "researcher" ? style.active : ""}
                  to="/researcher"
                >
                  <span className={style.menuName}>{t("researcher")}</span>
                  <span className={`${style.activeMenuName} ${isNotKorean ? style.notKO : ""}`}>{t("researcher")}</span>
                </Link>
              </li>
              <li className={style.menu}>
                <Link onClick={onClickLink} className={activePath === "review" ? style.active : ""} to="/review?page=0">
                  <span className={style.menuName}>{t("realReview")}</span>
                  <span className={`${style.activeMenuName} ${isNotKorean ? style.notKO : ""}`}>{t("realReview")}</span>
                </Link>
              </li>
              {canLogin ? (
                <>
                  <li className={style.menu}>
                    <Link
                      onClick={onClickLink}
                      className={`${style.purchasePage} ${style.withIcon} ${
                        activePath === "purchase-voucher" ? style.active : ""
                      }`}
                      to="/purchase-voucher"
                    >
                      <img className={style.normalIcon} src={voucherIcon} alt={"이용권"} />
                      <img className={style.activeIcon} src={voucherIconBold} alt={"이용권"} />
                      <span className={style.menuName}>검사이용권 구매</span>
                      <span className={style.activeMenuName}>검사이용권 구매</span>
                      <span className={style.discountBadge}>
                        {/* 여름 기한 한정 문구 변경임 */}
                        추가혜택 제공!
                        {/*여름방학 특가!*/}
                      </span>
                    </Link>
                  </li>
                  <li className={style.menu}>
                    <Link
                      onClick={onClickLink}
                      className={`${style.downloadPage} ${style.withIcon} ${
                        activePath === "download-test" ? style.active : ""
                      }`}
                      to="/download-test"
                    >
                      <img className={style.normalIcon} src={downloadIcon} alt={"검사 다운로드"} />
                      <img className={style.activeIcon} src={downloadIconBold} alt={"검사 다운로드"} />
                      <span className={style.menuName}>검사 다운로드</span>
                      <span className={style.activeMenuName}>검사 다운로드</span>
                    </Link>
                  </li>
                  <li className={style.menu}>
                    <Link
                      onClick={onClickLink}
                      className={`${style.partnership} ${style.withIcon} ${
                        activePath === "partnership" ? style.active : ""
                      }`}
                      to="/partnership"
                    >
                      <img className={style.normalIcon} src={partnershipIcon} alt={"제휴 문의"} />
                      <img className={style.activeIcon} src={partnershipIconBold} alt={"제휴 문의"} />
                      <span className={style.menuName}>제휴 문의</span>
                      <span className={style.activeMenuName}>제휴 문의</span>
                    </Link>
                  </li>
                  <li className={style.menu}>
                    <Link
                      onClick={onClickLink}
                      className={`${style.partnerlist} ${style.withIcon} ${
                        activePath === "partnerlist" ? style.active : ""
                      }`}
                      to="/partnerlist"
                    >
                      <img className={style.normalIcon} src={partnerIcon} alt={"협력사"} />
                      <img className={style.activeIcon} src={partnerIconBold} alt={"협력사"} />
                      <span className={style.menuName}>협력사</span>
                      <span className={style.activeMenuName}>협력사</span>
                    </Link>
                  </li>
                </>
              ) : (
                <></>
              )}
            </ul>
          </nav>
        </div>

        {isLoggedIn && (
          <button className={style.logoutBtn} onClick={onClickLogoutBtn}>
            <span className={style.text}>로그아웃</span>
            <img src={logoutIcon} alt={"로그아웃 아이콘"} />
          </button>
        )}
      </div>
    </div>
  );
}
