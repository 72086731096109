import React, {useEffect, useState} from "react";
import style from "../../../assets/scss/sub/consultantHistory.module.scss";
import AddConsultationModal from "../../common/modal/AddConsultationModal";
import ConfirmConsultantModal from "../../common/modal/ConfirmConsultantModal";

import LeftBtnIcon from "../../../assets/images/mypage/left_button_icon.svg";
import RightBtnIcon from "../../../assets/images/mypage/right_button_icon.svg";
import {ConsultationItem} from "../../../types/consultation";
import EditConsultationModal from "../../common/modal/EditConsultationModal";
import AboutConsultantModal from "../../common/modal/AboutConsultantModal";
import {useConsultationFunctions} from "./hooks/useConsultationFunctions";
import {ReviewConsultantModal} from "../../common/modal/ReviewConsultantModal";
import {useConsultationGetter} from "./hooks/useConsultationGetter";

interface ProfileInfo {
  idx: number;
  name: string;
  birthday: string;
  createAt: string;
  userCode: string;
  testCnt: number;
  isOwnerProfile: boolean;
}

interface ProfileInfoProps {
  profileIdx: number;
  profileInfo: ProfileInfo;
}

export function ConsultationHistory({profileIdx, profileInfo}: ProfileInfoProps) {
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isConsultantModal, setIsConsultantModal] = useState(false);
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [isCounselingReserve, setIsCounselingReserve] = useState(false);
  const { getStatusMessage, getButtonContent} = useConsultationFunctions();
  const { getTestHistory, consultationHistory } = useConsultationGetter();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedConsultation, setSelectedConsultation] = useState<ConsultationItem | null>(null);
  // 상담내역 모달창에 전달할 데이터 저장

  // 페이지네이션 범위를 계산하는 부분 추가
  const paginationRange = 5;
  const startPage = Math.max(0, Math.min(currentPage - Math.floor(paginationRange / 2), totalPages - paginationRange));
  const endPage = Math.min(totalPages, startPage + paginationRange);

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const toggleModal = (consultation: ConsultationItem | null) => {
    const status = consultation?.status;
    if (status === "NOT_ALLOTTED") {
      setIsEditModal((prev) => !prev);
    } else if(status === "FINISHED"){
      setIsReviewModal((prev) => !prev)
      setSelectedConsultation(consultation);
    }else {
      setIsConfirmModal((prev) => !prev);
    }
    setSelectedConsultation(consultation);
  };

  const removeToggle = () => {
    setSelectedConsultation(null)
    setIsEditModal(false);
    setIsReviewModal(false);
    setIsConsultantModal(false);
    setIsConfirmModal(false);
  }

  const toggleReserveModal = (consultation: ConsultationItem | null) => {
    setIsCounselingReserve((prev) => !prev);
    setSelectedConsultation(consultation);
  };

  const toggleConsultantModal = (consultation: ConsultationItem | null) => {
    setIsConsultantModal((prev) => !prev)
    setSelectedConsultation(consultation);
  }

  const onRefresh = () =>{
    getTestHistory({profileIdx:profileIdx, currentPage:currentPage}).then();
  }

  useEffect(() => {
    if(profileIdx === null) return;
    getTestHistory({profileIdx: profileIdx, currentPage: currentPage}).then(
        (result) => result && setTotalPages(Math.ceil(result.totalElements / 4))
    );
  }, [getTestHistory, currentPage, profileIdx]);

  return (
      <div className={style.container}>
        <div className={style.containerContentBox}>
          <h2 className={style.containerTitle}>
            상담내역&nbsp;
            <span className={style.profileCount}>({profileInfo?.name ? profileInfo?.name : "나"})</span>
          </h2>
        </div>
        <div>
          <div className={style.labelContainer}>
            <div className={style.firstLabel}>검사종류</div>
            <div className={style.secondLabel}>상담요청일</div>
            <div className={style.thirdLabel}>상세보기</div>
            <div className={style.fourthLabel}>상태</div>
          </div>
          {consultationHistory?.content && (
              <div className={style.resultInfoContainer}>
                {consultationHistory.content.map((item: any) => (
                    <div key={item.contentExecutedInfoIdx} className={style.resultInfoData}>
                      <div className={style.firstInfoData}>
                        {item.unitVoucherType === "KPASS" ? "K-PASS" : "D-CAS"}
                        <br/>
                        {`${item.testRound}회차` || "-"}
                      </div>
                      <div className={style.secondInfoData}>
                        {item.desiredDate ? item.desiredDate : "-"}
                        {item.desiredTime ? (
                            <>
                              <br/>
                              {item.desiredTime.slice(0, 5)}
                            </>
                        ) : (
                            ""
                        )}
                      </div>
                      <div className={style.thirdInfoData}>
                        {getButtonContent(item, toggleModal)}
                      </div>
                      <div
                          className={`${style.fourthInfoData} ${item.status ? style[item.status.toLowerCase()] : ""}`}>
                        {item?.status?.toLowerCase() === "allotted" ? (
                            <>
                                <span style={{cursor: "pointer"}}
                                      onClick={() => toggleConsultantModal(item)}
                                      className={`${style.allotted_consultant}`}>{item.consultantName}</span>
                              <br/>
                            </>
                        ) : null}
                        {!item.status ? (
                            <button className={style.resultButton}
                                    onClick={() => {
                                      setSelectedConsultation(item)
                                      setIsCounselingReserve(true)
                                    }}>
                              {getStatusMessage(item.status)}</button>
                        ) : (
                            <div>{getStatusMessage(item.status)}</div>
                        )}
                      </div>
                    </div>
                ))}
                {consultationHistory.content.length === 0 && <div className={style.noPaymentInfo}>검사 내역이 없습니다.</div>}
              </div>
          )}

          <div className={style.paginationContainer}>
            <div className={style.paginationDiv}>
              <img
                  src={LeftBtnIcon}
                  className={style.leftButtonImg}
                  onClick={() => handlePageChange(currentPage - 1)}
                  alt="previous"
              />
              {Array.from({length: endPage - startPage}, (_, i) => startPage + i).map((page) => (
                  <button
                      key={page}
                      className={`${style.paginationBtn} ${page === currentPage ? style.active : ""}`}
                      onClick={() => handlePageChange(page)}
                  >
                    {page + 1}
                  </button>
              ))}
              <img
                  src={RightBtnIcon}
                  className={style.rightButtonImg}
                  onClick={() => handlePageChange(currentPage + 1)}
                  alt="next"
              />
            </div>
          </div>
        </div>

        <EditConsultationModal
            isOpen={isEditModal}
            selectedConsultation={selectedConsultation}
            onRefresh={onRefresh}
            onClose={() => removeToggle()}/>

        <ConfirmConsultantModal
            isOpen={isConfirmModal}
            onClose={() => removeToggle()}
            selectedConsultation={selectedConsultation}
            profileName={profileInfo?.name}
        />

        <AddConsultationModal isOpen={isCounselingReserve}
                              selectedConsultation={selectedConsultation}
                              onRefresh={onRefresh}
                              onClose={() => toggleReserveModal(null)}
        />
        <AboutConsultantModal
            isOpen={isConsultantModal}
            selectedConsultation={selectedConsultation}
            onClose={() => removeToggle()}
        />
        <ReviewConsultantModal
            isOpen={isReviewModal}
            selectedConsultation={selectedConsultation}
            onClose={() => removeToggle()}
        />
      </div>
  );
}
