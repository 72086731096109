import { checkAffiliateCodeCommissionAsync } from "../../store/order/orderSlice";
import { useAppDispatch } from "../hooks";
import { useCallback } from "react";
import { AffiliateCodeCommissionCheckerParam } from "../../types/order";

export function useAffiliateCodeCommissionChecker() {
  const dispatch = useAppDispatch();

  const checkAffiliateCodeCommission = useCallback(
    async (param: AffiliateCodeCommissionCheckerParam) => {
      try {
        await dispatch(checkAffiliateCodeCommissionAsync(param)).unwrap();
      } catch (e) {
        console.log({ e });
        throw e;
      }
    },
    [dispatch]
  );

  return {
    checkAffiliateCodeCommission,
  };
}
