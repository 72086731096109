interface Props {
  totalCount: number;
  currentSlide: number;
  wrapperClassName?: string;
  activeBulletClassName: string;
}

export function Indicator({ totalCount, currentSlide, wrapperClassName, activeBulletClassName }: Props) {
  return (
    <ul className={wrapperClassName ?? ""}>
      {Array(totalCount)
        .fill(undefined)
        .map((_, index) => (
          <li key={`slide-${index}`} className={currentSlide === index + 1 ? activeBulletClassName : ""}></li>
        ))}
    </ul>
  );
}
