import image01 from "../../../assets/images/sub/review/profileCharacters/ico_max1.png";
import image02 from "../../../assets/images/sub/review/profileCharacters/ico_peace1.png";
import image03 from "../../../assets/images/sub/review/profileCharacters/ico_darkmong1.png";
import image04 from "../../../assets/images/sub/review/profileCharacters/ico_linkey1.png";
import image05 from "../../../assets/images/sub/review/profileCharacters/ico_max2.png";
import image06 from "../../../assets/images/sub/review/profileCharacters/ico_peace2.png";
import image07 from "../../../assets/images/sub/review/profileCharacters/ico_darkmong2.png";
import image08 from "../../../assets/images/sub/review/profileCharacters/ico_linkey2.png";
import image09 from "../../../assets/images/sub/review/profileCharacters/ico_max3.png";
import image10 from "../../../assets/images/sub/review/profileCharacters/ico_peace3.png";
import image11 from "../../../assets/images/sub/review/profileCharacters/ico_darkmong3.png";
import image12 from "../../../assets/images/sub/review/profileCharacters/ico_linkey3.png";
import { useMemo } from "react";

interface Props {
  reviewIdx: number;
}
export function ProfileImage({ reviewIdx }: Props) {
  const images = useMemo(
    () => [image01, image02, image03, image04, image05, image06, image07, image08, image09, image10, image11, image12],
    []
  );

  return (
    <>
      <img src={images[reviewIdx % images.length]} alt={"프로필 캐릭터"} />
    </>
  );
}
