import loginStyle from "../../../../../../assets/scss/common/modal/loginModal.module.scss";
import memberStyle from "../../../../../../assets/scss/sub/editMember.module.scss";
import React, {RefObject} from "react";
import {ValidateStatus} from "../hooks/useSignupValidator";
import {StyleRequestType} from "../../../../../../types/common";
interface Props{
    name: string;
    setName: (_: string) => void;
    nameValidate: ValidateStatus;
    setNameValidate: (_: ValidateStatus) => void;
    styleRequestType: StyleRequestType;
    nameRef?: RefObject<HTMLInputElement>;
}
export function SignupNameField({name, setName, nameValidate, setNameValidate, styleRequestType, nameRef}:Props){
    const styles = styleRequestType === StyleRequestType.SIGN_UP ? loginStyle :  memberStyle;
    const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNameValidate(ValidateStatus.NONE);
        setName(e.target.value.replace(/\s+/g, ''))
    };

    return (
        <div className={styles.selectWrapper}>
            <div className={styles.selectTitleWrapper}>
                <span className={styles.highLight}>[필수]</span>
                <span className={styles.titleText}>이름 (본명 권장)</span>
            </div>
            <div className={styles.selectTextFieldWrapper} >
                <input ref={nameRef}
                    maxLength={50}
                    type="name"
                    className={styles.selectText}
                    value={name}
                    onInvalid={(e) => e.preventDefault()}
                    // onChange={(e) => setName(e.target.value.toUpperCase())}
                    onChange={handleSetName}
                    placeholder={"이름을 입력해 주세요."}
                />
            </div>
            {nameValidate === ValidateStatus.UNFILLED &&
                (<span className={styles.validateText}>이름을 입력해주세요.</span>)
            }
            {nameValidate === ValidateStatus.LANGUAGE_MISMATCH &&
                (<span className={styles.validateText}>이름은 한글, 영어로 입력해 주세요.</span>)
            }
        </div>
    )
}