import {OrderDetail} from "../../../../types/order";
import {useEffect, useState} from "react";
import {PaymentWay} from "../../../../types/payment";

export function useOrderProductName(
    orderDetail: OrderDetail
): string {
    const [productName, setProductName] = useState("")

    useEffect(() => {
        setProductName(orderDetail.orderTitle)
    }, [orderDetail]);

    return productName;
}

export function useOrderPaymentType(
    orderDetail: OrderDetail
): string {
    const [paymentType, setPaymentType] = useState("")
    useEffect(() => {
        switch (orderDetail.paymentWay) {
            case PaymentWay.VBANK:
                setPaymentType("가상계좌");
                break;
            case PaymentWay.CARD:
                setPaymentType("카드");
                break;
        }
    }, [orderDetail])

    return paymentType
}