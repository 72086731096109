import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { Gender } from "../user";

export function useGenderType() {
  const { t } = useTranslation("subRealReview");

  const toStringFromGender = useCallback(
    (gender: Gender) => {
      switch (gender) {
        case Gender.FEMALE:
          return t("female");
        case Gender.MALE:
          return t("male");
        default:
          return "";
      }
    },
    [t]
  );

  return {
    toStringFromGender,
  };
}
