import style from "../../assets/scss/sub/partnership.module.scss";
import { FloatingBtn } from "../common/button/FloatingBtn";
import { useFooter, useUserAgent } from "../../hooks/hooks";
import { useEffect } from "react";
import { usePartnershipDetailImages } from "../../hooks/usePartnershipDetailImages";
import { FileCategory } from "../../types/file";

export function Partnership() {
  const { setShowFooter } = useFooter();
  const { detailImagesOnMobile, detailImagesOnDesktop } = usePartnershipDetailImages();
  const { isMobile } = useUserAgent();

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    // eslint-disable-next-line
  }, []);

  return (
    <main className={style.partnership}>
      <FloatingBtn />
      <div className={style.title}>
        <h2 className={style.text}>제휴 문의</h2>
      </div>
      <article className={style.article}>
        <div className={style.bgItem1}></div>
        <div className={style.bgItem2}></div>
        <div className={style.bgItem3}></div>
        <section className={style.imageSection}>
          {(isMobile ?  detailImagesOnMobile : detailImagesOnDesktop).map((detailFile, index) => {
            if (detailFile.type === FileCategory.IMAGE) {
              return <img key={`detail${index}`} src={detailFile.url} alt={"상세 이미지"} />;
            } else {
              return (
                <div className={style.videoWrapper} key={`detail${index}`}>
                  <video controls={true} autoPlay={true} muted={true} playsInline={true}>
                    <source src={detailFile.url} />
                  </video>
                </div>
              );
            }
          })}
        </section>
      </article>
    </main>
  );
}
