import { useAppDispatch, useAppSelector } from "../hooks";
import { useCallback, useEffect } from "react";
import { bestReviewsState, getBestReviewsAsync, setBestReviewsState } from "../../store/review/reviewSlice";
import { KPASSReviewListDTO } from "../../types/review";
import { useReviewListVOType } from "../../types/hooks/useReviewListVOType";

export function useBestReviewsGetter() {
  const dispatch = useAppDispatch();
  const bestReviews = useAppSelector(bestReviewsState);
  const { toReviewListVOFromDto } = useReviewListVOType();

  const getBestReviews = useCallback(async () => {
    const bestReviewDTOs = await dispatch(getBestReviewsAsync()).unwrap();
    const bestReviewVOs = bestReviewDTOs.map((dto: KPASSReviewListDTO) => toReviewListVOFromDto(dto));
    dispatch(setBestReviewsState(bestReviewVOs));
    return bestReviewVOs;
  }, [dispatch, toReviewListVOFromDto]);

  useEffect(() => {
    if (!bestReviews) {
      getBestReviews().then();
    }
    // eslint-disable-next-line
  }, []);

  return {
    getBestReviews,
    bestReviews,
  };
}
