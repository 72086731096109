import style from "./eventRegistererModal.module.scss";
import { RefObject } from "react";
import { getOnlyNumberText } from "../../../../util/commonFunctions";

interface Props {
  birthday: string;
  setBirthday: (_: string) => void;
  birthdayRef: RefObject<HTMLInputElement>;
  invalidMessage?: string;
}
export function EventRegistererBirthdayField({ birthday, setBirthday, birthdayRef, invalidMessage }: Props) {
  return (
    <div className={style.field}>
      <label className={style.label}>자녀의 생년월일</label>
      <input
        className={`${style.inputText} ${birthday ? style.inputted : ""}`}
        name={"birthday"}
        required={true}
        minLength={8}
        maxLength={8}
        type={"number"}
        placeholder={"8자리 생년월일을 입력해 주세요."}
        value={birthday}
        onChange={(e) => setBirthday(getOnlyNumberText(e.target.value))}
        ref={birthdayRef}
      />
      {invalidMessage && <span className={style.invalidMessage}>{invalidMessage}</span>}
    </div>
  );
}
