import style from "../../../assets/scss/sub/purchase/orderAndPayment.module.scss"
import {getPriceText} from "../../../util/commonFunctions";
import {CartedProduct} from "../../../types/cart";

interface Props {
  cartedProducts: CartedProduct[]
}

export default function CartProductItems({cartedProducts}: Props) {
  return <>
    <table className={`${style.items} ${style.desktop}`} cellSpacing="0" cellPadding="0">
      <thead className={style.tableHeader}>
      <tr className={style.headerRow}>
        <th className={style.headerCell} colSpan={2}>상품정보</th>
        <th className={style.headerCell}>수량</th>
        <th className={style.headerCell}>정가</th>
        <th className={style.headerCell}>할인</th>
        <th className={style.headerCell}>상품금액</th>
      </tr>
      </thead>
      <tbody className={style.tableBody}>
      {
        cartedProducts?.map((prd, idx) => {
          const product = prd.product;

          return <tr className={style.bodyRow} key={`product-info-${idx}`}>
            <td className={`${style.bodyCell} ${style.detailCell}`} colSpan={2}>
              <div className={style.detailArea}>
                <div className={style.thumbnail}>
                  <img src={product.thumbnail.uri} alt={"상품 이미지"}/>
                </div>
                <span className={style.text}>
                        {product.name}
                      </span>
              </div>
            </td>
            <td className={style.bodyCell}>{prd.quantity}개</td>
            <td className={style.bodyCell}>
              {getPriceText(prd.totalRegularPrice ?? 0)}원
            </td>
            <td className={`${style.bodyCell} ${style.priceAccent}`}>
              -{getPriceText(prd.totalDiscountAmount ?? 0)}원
            </td>
            <td className={style.bodyCell}>{getPriceText(prd.totalActualAmount ?? 0)}원</td>
          </tr>
        })
      }
      </tbody>
    </table>


    <ul className={`${style.items} ${style.mobile}`}>
      {
        cartedProducts?.map((prd, idx) => {
          const product = prd.product;

          return <li key={idx} className={style.item}>
            <div className={style.thumbnail}>
              <img src={product.thumbnail.uri} alt={"상품 이미지"}/>
            </div>

            <div className={style.productInfo}>
              <span className={style.productName}>{product.name}</span>
              <span className={style.quantity}>{prd.quantity}개</span>
              <span className={style.priceWrap}>
                <span className={style.sellingPrice}>{getPriceText(product.sellingPrice)}원</span>
                <span className={style.regularPrice}>{getPriceText(product.regularPrice)}원</span>
              </span>
            </div>
          </li>
        })
      }
    </ul>
  </>
}