import { useFooter, useMainScrolling, useUserAgent, useAwardBanner } from "../../hooks/hooks";
import React, { useEffect, useState } from "react";
import { MainArticle1 } from "./MainArticle1";
import { FloatingBtn } from "../common/button/FloatingBtn";
import { MainArticle2 } from "./MainArticle2";
import { MainArticle3 } from "./MainArticle3";
import { MainArticle4 } from "./MainArticle4";
import style from "./main.module.scss";
import { Popups } from "./popup/Popups";

export function Main() {
  const { headerHeight } = useMainScrolling();
  const { setShowFooter } = useFooter();
  const { isDesktop } = useUserAgent();
  const { hideAwardBanner } = useAwardBanner();

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    document.title = `${process.env.REACT_APP_TITLE}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("hide award banner >", hideAwardBanner);
  }, [hideAwardBanner]);

  return (
    <main
      className={`${style.main} ${!hideAwardBanner ? style.mainBanner : ""}`}
      style={{ marginTop: `${isDesktop ? headerHeight : -10}px`, letterSpacing: "-0.015em" }}
    >
      <Popups />
      <MainArticle1 />
      <MainArticle2 />
      <MainArticle3 />
      <MainArticle4 />
      <FloatingBtn />
    </main>
  );
}
