import style from "./domesticPartnerCompanyCards.module.scss";
import searchIcon from "../../../assets/images/sub/partner_company/search_icon.svg";
import closeIcon from "../../../assets/images/sub/partner_company/close_icon.svg";
import React, { useCallback, useRef, useState } from "react";
import { APartnerCompanyDomestic } from "./APartnerCompanyDomestic";
import { PartnerCompanyVO } from "../../../types/partnerCompany";

interface Props {
  partnerCompanies: PartnerCompanyVO[];
  search: (_: string) => void;
  selectPartnerCompany: (_: PartnerCompanyVO) => void;
  selectedPartner: PartnerCompanyVO | null;
}

export function DomesticPartnerCompanyCards({
  partnerCompanies,
  search,
  selectPartnerCompany,
  selectedPartner,
}: Props) {
  const [searchWord, setSearchWord] = useState("");
  const resultAreaRef = useRef<HTMLDivElement>(null);
  const searchWordInputRef = useRef<HTMLInputElement>(null);

  const onClickSearchButton = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      search(searchWord);
      searchWordInputRef.current?.blur();
    },
    [search, searchWord]
  );

  const onClickDeleteButton = useCallback(() => {
    setSearchWord("");
    searchWordInputRef.current?.focus();
  }, []);

  return (
    <div className={style.cards}>
      <form className={style.searchInput}>
        <input
          className={style.textInput}
          type={"text"}
          placeholder={"지역 또는 협력사명을 입력해 주세요."}
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
          ref={searchWordInputRef}
        />
        {searchWord ? (
          <button type={"button"} className={style.deleteButton} onClick={onClickDeleteButton}>
            <img src={closeIcon} alt={"삭제"} />
          </button>
        ) : null}
        <button className={style.searchButton} onClick={onClickSearchButton}>
          <img src={searchIcon} alt={"검색"} />
        </button>
      </form>
      <div className={style.searchResult} ref={resultAreaRef}>
        {partnerCompanies.length > 0 ? (
          partnerCompanies.map((vo, idx) => (
            <React.Fragment key={`domestic-partner-${vo.idx}`}>
              <APartnerCompanyDomestic
                isSelected={vo.idx === selectedPartner?.idx}
                partnerCompany={vo}
                selectPartnerCompany={selectPartnerCompany}
                parentElement={resultAreaRef.current}
              />
              {idx < partnerCompanies.length - 1 ? <hr className={style.divider} /> : null}
            </React.Fragment>
          ))
        ) : (
          <div className={style.noResult}>검색된 협력사가 없습니다.</div>
        )}
      </div>
    </div>
  );
}
