import style from "../koreanKpassStandardization.module.scss";
import { ArticleHeaderKO } from "./ArticleHeaderKO";
import { SubtitleNumbering } from "../../main/article3/SubtitleNumbering";
import table3 from "../../../assets/images/kpass_standardization/table3.svg";
import table4 from "../../../assets/images/kpass_standardization/table4.svg";
import table5 from "../../../assets/images/kpass_standardization/table5.svg";
import table6 from "../../../assets/images/kpass_standardization/table6.svg";
import table7 from "../../../assets/images/kpass_standardization/table7.svg";
import table8 from "../../../assets/images/kpass_standardization/table8.svg";
import table9 from "../../../assets/images/kpass_standardization/table9.svg";
import table10 from "../../../assets/images/kpass_standardization/table10.svg";
import picture1 from "../../../assets/images/kpass_standardization/picture1.svg";
import picture2 from "../../../assets/images/kpass_standardization/picture2.svg";
import React from "react";
import { ArticleFooterKO } from "./ArticleFooterKO";

export function ReliabilityAndValidityKO() {
  return (
    <article className={style.article}>
      <div className={style.contentHeaderWrapper}>
        <ArticleHeaderKO />
      </div>
      <section className={style.contentSection}>
        <div style={{ marginBottom: "280px" }}>
          <SubtitleNumbering lightNumber={0} boldNumber={4} fontSize={"85px"} />
          <h2 className={style.articleTitle}>
            한국판 K-PASS의
            <br /> 신뢰도 및 타당도
          </h2>
        </div>
        <div className={style.part}>
          <h3 className={style.partTitle}>
            신뢰도<span className={style.light}>(Reliability)</span>
          </h3>
          <p className={style.partSummary}>
            &nbsp;신뢰도란 한 검사가 측정하고자 하는 내용을 어느 정도 일관성(consistency) 있게 측정하고 있는지를
            말해주는 검사의 정확성을 뜻한다. 다시 말하면, 신뢰도는 한 검사가 재려고 하는 측정 대상을 어떻게 어느 정도
            정확하게 측정하느냐에 관심을 가지고, 주로 측정 과정에 작용하는 오차만을 문제 삼는 검사의 정확성을 가리킨다.
            <br />
            &nbsp;K-PASS와 같은 심리검사는 검사 사용자가 검사 결과를 정확하게 해석할 수 있도록 해주기 위해 충분히 만족할
            만한 수준의 신뢰도에 대한 자료를 제시할 수 있어야 하며 바람직한 신뢰도계수는 최소한 .80 이상이어야
            한다(Aiken, 1985; Helmstadter, 1964; Nunnally, 1978; Salvia & Ysseldyke, 1991). 한국판 K-PASS 검사에서는
            K-PASS의 네 가지 PASS 척도와 전체척도의 신뢰도를 각각 추정하였다.
          </p>
          <ul className={style.partContent}>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>1</div>
                <h4 className={style.title}>반분신뢰도(Split-Half Reliability)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;[표 3]은 K-PASS 하위척도의 문항이 난이도에 따라 오름차순으로 배열되어 있기 때문에 반분신뢰도를
                추정하기 위해 각 하위척도 별 문항을 홀수-문항 군과 짝수-문항 군으로 분류한 다음 4세~12세까지의 각
                연령집단별로 두 문항 군 간의 적률상관계수를 계산하였다. 그리고, Spearman-Brown 교정 공식을 적용하여
                반분신뢰도 계수를 추정하였으며 각 연령군별 평균 신뢰도는 Fisher의 Z 변환 절차에 따라 계산하였다.
                <div className={style.imageWrapper}>
                  <img src={table3} alt={"[표 3]  K-PASS 하위척도별 반분검사 신뢰도 계수"} style={{ width: "800px" }} />
                </div>
              </div>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>2</div>
                <h4 className={style.title}>재검사 신뢰도(Test-Retest Reliability)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;K-PASS의 재검사 신뢰도를 추정하기 위해 4세~12세 아동 270명을 대상으로 약 2주에서 4주간에 걸쳐(평균
                20일) K-PASS를 두 번 실시하였다. 재검사 신뢰도 추정을 위해 전체 연령집단을 학령전(4세~6세) 집단과 학령기
                집단(7세~12세)으로 나눈 다음 [표 4]와 같이 각 연령집단별로 재검사 신뢰도 계수를 추정하였다.
                <div className={style.imageWrapper}>
                  <img src={table4} alt={"[표 4]  검사-재검사 신뢰도 계수"} style={{ width: "800px" }} />
                </div>
              </div>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>3</div>
                <h4 className={style.title}>측정의 표준오차(SEM: Standard Error of Measurement)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;[표 5], [표 6]과 같이 측정의 표준오차는 각 하위척도의 표준점수의 표준편차를 바탕으로 산출하였다.
                이론적으로 SEM은 피검사자의 진점수의 오차 범위를 나타낸다. 우리는 피검사자의 진점수를 결코 정확히 알 수
                추정할 수 없기 때문에 검사를 통해 얻어진 관찰점수를 중심으로 SEM을 이용하여 진점수가 포함될 범위를
                설정할 수밖에 없다.
                <br />
                &nbsp;심리 측정에서 일반적으로 측정의 표준오차를 이용하여 표준점수를 중심으로 90% 신뢰구간 또는 95%
                신뢰구간에 따른 구간 값을 추정하고 있다. K-PASS 검사 결과 보고서에서는 95% 신뢰구간 값을 추정하여
                제공하고 있다.
                <div className={style.imageWrapper}>
                  <img
                    src={table5}
                    alt={"[표 5]  K-PASS 하위척도 및 전체척도의 측정의 표준오차"}
                    style={{ width: "800px" }}
                  />
                  <img src={table6} alt={"[표 6]  연령별 하위척도의 평균 및 표준편차"} style={{ width: "800px" }} />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={style.part}>
          <h3 className={style.partTitle}>
            타당도<span className={style.light}>(Validity)</span>
          </h3>
          <p className={style.partSummary}>
            &nbsp;심리검사에 있어서 타당도란 어떤 심리검사가 측정하려고 하는 심리적 특성을 측정할 수 있는 정도를 말한다.
            어떤 심리검사를 실시하여 얻어진 점수가 얼마나 일관성을 가질 수 있느냐도 중요하지만, 검사를 통해 얻어진
            점수를 해석하기 위해서는 주어진 심리검사를 통해 얻어진 점수가 도대체 무엇을 의미하는지를 알 수 있어야 한다.
            <br />
            &nbsp;검사 개발자는 자신이 개발한 심리검사를 통해 얻어진 점수가 왜 그러한 의미를 가진 점수로 해석될 수
            있는지 그 근거를 구체적인 타당도의 이름으로 밝혀야 한다. 따라서, 타당도란 주어진 심리검사가 어떤 목적으로
            얼마나 적절하게 사용될 수 있는 유용한 검사인지를 말해주는 정도라고 할 수 있다(AERA, APA, & NCME, 1985).
          </p>
          <ul className={style.partContent}>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>1</div>
                <h4 className={style.title}>내용 타당도(Content Validity)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;내용 타당도는 검사에 포함된 문항들이 검사가 측정하고자 하는 행동영역을 대표할 수 있는 정도를
                체계적으로 검토하여 얻어진다(Anastasi, 1988, p. 140). 내용 타당도는 검사를 어떤 하위검사로 구성할 것인지
                또는 각 하위검사에 어떤 문항을 개발하여 구성할 것이지를 검토할 때 기본적으로 고려되어야 한다.
                <br />
                &nbsp;K-PASS의 각 하위척도를 구성하고 있는 문항들은 PASS 이론에서 기술된 인지기능의 특성을 가장 잘
                효율적으로 반영할 수 있도록 하기 위해 문항분석과 실험적 결과를 동시에 고려하여 개발되었다.
              </div>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>2</div>
                <h4 className={style.title}>구성 타당도(Construct Validity)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;구성 타당도란 어떤 검사가 측정하려고 하는 이론적 구성개념 또는 특성을 실제로 어느 정도 측정하고
                있는가를 말하며 실제로 검사 점수에 내재된 의미의 분석을 가능케 해준다(Cronbach, 1970). 한국판
                K-PASS에서도 구성 타당도에 대한 증거를 여러 가지 방법으로 수집하여 발달적 변화, 내적 일관성, 요인분석,
                다른 검사와의 상관 등 네 가지 영역으로 분류하여 타당도의 증거를 추정하였다.
              </div>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>3</div>
                <h4 className={style.title}>발달적 변화(Developmental Difference)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;비록 연령의 증가에 따라 어떤 검사 점수가 증가하면 그 검사가 지능검사라고 보장할 수 있는 것은
                아니지만, 지능검사의 타당도를 검토하기 위해 사용되는 주요 준거 중의 하나가 바로 검사 점수의 연령에 따른
                차별적 변화이다(Anastasi, 1988, p. 153). 예컨대, 키와 신발의 크기도 연령과 함께 증가하지만 키와 신발의
                크기가 지능과 상관이 있다고 말할 수는 없다. 그러므로 연령과 확실히 상관을 가지고 있는 지능이나 성취검사
                또는 어떤 검사라도 그 검사의 구인 타당도에 대한 어떤 주장을 하기 위해서는 연령에 따른 유의한 차별적
                변화를 보여줄 수 있어야 한다. 물론 연령에 따른 검사 점수의 차별적 변화에 의한 증거로서, 검사의 타당도를
                주장하기 위해서는 다른 측면의 구인 타당도에 대한 증거가 함께 고려되어야 한다. [그림 1]과 [표 7]에는 각
                하위척도의 원점수의 평균과 표준편차가 연령별로 제시되어 있다. 연령에 따른 평균값의 변화를 보면 각
                하위척도에서 측정하는 능력이 연령과 더불어 증가함을 알 수 있다. 각 하위척도별 실시 대상 연령에서 모든
                하위척도의 평균 점수가 완만하게 증가되는 것으로 나타나 있다.
                <br />
                &nbsp;이것은 바로 K-PASS 하위척도의 원점수가 아동의 연령에 따라 차별적 변화를 보이고 있음을 지지해 준다.
                <div className={style.picture1AndTable7Wrapper}>
                  <img
                    className={style.picture1}
                    src={picture1}
                    alt={"[그림 1]  연령의 증가에 따른 각 하위척도 원점수의 발달적 변화"}
                  />
                  <img
                    className={style.table7}
                    src={table7}
                    alt={"[표 7]  K-PASS 하위척도별 원점수의 연령별 평균과 표준편차(N=2,600)"}
                  />
                </div>
              </div>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>4</div>
                <h4 className={style.title}>K-PASS 하위척도 및 전체척도 간의 상관</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;여러 종류의 점수가 제공되는 검사 도구의 동질성 또는 내적 일관성은 하위척도점수와 전체 검사 점수
                간의 상관 정도를 검토해 보면 된다. 물론 하위척도와 전체 검사 간의 상관이 검사 도구의 구인 타당도에 대한
                증거가 될 수 있지만, 이러한 내적 타당화를 통해 얻어진 타당도 증거는 외적 준거를 사용한 타당도 증거에
                의해 보완돼야 한다(Anastasi, 1988).
                <div className={style.imageWrapper}>
                  <img
                    src={table8}
                    alt={"[표 8]  K-PASS 하위척도 및 전체척도 간 상호상관"}
                    style={{ width: "800px" }}
                  />
                </div>
              </div>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>5</div>
                <h4 className={style.title}>확인적 요인분석(Confirmatory Factor Analysis)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;K-PASS는 PASS 이론에 따라 계획력, 주의력, 동시처리 그리고 순차처리의 네 가지 요인을 측정하기 위해
                개발되었기 때문에 요인분석을 통해 계획력, 주의력, 동시처리, 그리고 순차처리 인자가 내재되어 있음을
                경험적으로 증명해 주는 것은 대단히 중요하며, 반드시 모든 하위검사는 본래의 소속된 요인에 유의미한 높은
                요인 부하를 가지는 것으로 증명되어야 한다. 여러 가지 요인분석 기법을 적용하여 K-PASS의 요인 타당도의
                증거를 검토하였으며 요인분석 결과로부터 추출된 요인들은 이론적 유의미성과 경험적 지지를 동시에 고려하여
                그 타당성을 평가하였다. 확인적 요인분석을 통해 K-PASS 모델의 적합도를 평가하였다.
                <br />
                &nbsp;확인적 요인분석을 위해 전체 표준화 집단을 4세~6세 집단과 7세~12세 적용 연령집단으로 나눈 다음, 각
                연령집단별로 먼저 PASS 이론적 모델에 따라 2차-CFA 모델을 설정하고, AMOS 27.0 프로그램을 사용하여 확인적
                요인분석을 실시해 Chi-Square 값, GFI, CFI, SRMR, 그리고 RMSR 부합도 지수를 사용하여 모델의 적합도를
                평가하였다.
                <div className={style.imageWrapperInsideParagraph}>
                  <img src={picture2} alt={"[그림 2]  연령집단별 확인적 요인분석"} style={{ width: "960px" }} />
                  <img
                    className={style.table9}
                    src={table9}
                    alt={"[표 9]  K-PASS 대한 확인적 요인분석 결과의 부합도 평가 지수"}
                    style={{ width: "500px" }}
                  />
                </div>
                &nbsp;[표 9]에 제시된 바와 같이 각 연령별로 분석된 PASS 모델의 부합도 지수가 RMSR(&lt;1.0) 값을 포함한
                GFI(&gt;.90), CFI(&gt;.90), SRMR(&lt;.05) 모든 적합도 지수가 적합도 평가 기준을 양호하게 충족시키는
                것으로 나타났다. 그리고 [그림 2]에서 볼 수 있는 바와 같이, 각 하위척도의 모든 지표들이 해당 잠재 변인에
                .90 이상의 높은 부하량을 보이고 있고 네 가지 하위척도들이 2차 요인인 전체 척도 요인에 .64~.69(4세~6세)
                그리고 .57~.78(7세~12세)의 수렴적 타당도를 가지는 것으로 나타났다.
                <br />
                &nbsp;그리고 하위척도 간 상호상관계수를 추정한 결과 .34~.58(4세~6세)와 .32~.47(7세~12세)의 변별적
                타당도를 가지는 것으로 나타났다.
              </div>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>6</div>
                <h4 className={style.title}>준거 관련 타당도 (Criterion Related Validity)</h4>
              </div>
              <div className={style.partContentItemParagraph}>
                &nbsp;K-PASS의 공인 타당도에 대한 증거를 수집하기 위해 Luria의 신경심리학 이론에 바탕을 두고 개발된
                KABC-II와 K-PASS 간의 상관 정도를 알아보기 위해 7세~12세 (평균=8년) 정상 아동 40명을 대상으로 검사
                실시에 따른 차별 효과를 통제하기 위해 두 검사를 약 일주일간의 간격으로 역 균형 법에 따라 실시하였다.
                K-PASS와 KABC-II 하위척도 및 전체척도의 표준점수 간의 상관계수를 추정한 결과 [표 10]과 같다.
                <div className={style.imageWrapperInsideParagraph}>
                  <img
                    className={style.table10}
                    src={table10}
                    style={{ width: "800px" }}
                    alt={"[표 10]  정상 아동 집단에 있어서 K-PASS와 KABC-II 간의 상관, 평균, 그리고 표준편차(n=40)"}
                  />
                </div>
                &nbsp;위의 [표 10]에서 볼 수 있는 바와 같이, 평균 K-PASS 척도 점수는 98.6(주의력)~104.3(순차처리)의
                범위를 가지며 전체척도 표준점수의 평균은 101.3이다. 마찬가지로, KABC-II 하위척도의 표준점수는
                학습력=103.2, 순차처리=102.3, 동시처리=103.6 계획력=107.3, 지식=105.2, 그리고 전체 IQ=105.4로서 모두
                평균 범위에 속하는 것으로 나타났다.
                <br />
                &nbsp;특히, Luria 모델에 바탕을 두고 개발된 K-PASS 하위척도의 표준점수와 동일한 이론적 근거를 두고 있는
                KABC-II 하위척도의 표준점수 간에 순차처리=2.4, 동시처리=2.1, 계획력=3.1, 그리고 전체 지능=4.1 정도의
                작은 차이를 보이는 것으로 나타났다. 하위척도 간 상관계수를 살펴보면, K-PASS의 계획력은 KABC-II의 계획력
                하위척도와 가장 높은 상관(.69)을 가지는 것으로 나타났으며 다른 하위척도들 간의 상관도
                .36(순차처리)~.56(동시처리)의 범위를 가지는 것으로 나타났다.
                <br />
                &nbsp;K-PASS 동시처리척도는 K-ABC-II의 동시처리척도와 .73 정도의 높은 상관을 가지며 다른 하위척도들간의
                상관도 .42(순차처리)~.55(계획력)의 범위를 가지는 것으로 나타났다. K-PASS 주의력척도와 KABC-II 하위척도들
                간에는 .31(지식)~.43(순차처리) 정도의 상관을 가지는 것으로 나타났으며, K-PASS 순차처리척도는 KABC-II
                순차처리척도와 .80정도의 높은 상관을 보이고 그리고 KABC-II 다른 하위척도들 간에는 .34(계획력)~.55(지식)
                범위의 상관을 보여주고 있다.
                <br />
                &nbsp;마지막으로, K-PASS 전체척도 KABC-II 전체척도 점수간에는 .71 정도의 높은 상관을 나타내고 있다.
                이러한 결과는 K-PASS가 이론적으로 설정된 구인을 타당하게 측정하고 있음을 지지해 주고 있다.
              </div>
            </li>
          </ul>
        </div>
      </section>
      <div className={style.contentFooterWrapper}>
        <ArticleFooterKO />
      </div>
    </article>
  );
}
