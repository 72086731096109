import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector, useAuth } from "../../../hooks/hooks";
import { couponList, getMyCouponListAsync, setCoupons } from "../../../store/coupon/couponSlice";
import { Coupon, CouponHistoryListDTO, CouponHistoryStatus, CouponSummaryDTO, CouponType } from "../../../types/coupon";
import { Selector, SelectorOption } from "../component/Selector";
import { OrderedProduct } from "../../../types/order";

interface Props {
  products: OrderedProduct[];
  changeSelectedCoupon: (couponIdx: number | null, totalDiscountAmount: number) => void;
  actualAmount: number;
  selectedCouponIdx: number | null;
}

export function CouponSelector({ products, changeSelectedCoupon, actualAmount, selectedCouponIdx }: Props) {
  const dispatch = useAppDispatch();
  const coupons = useAppSelector(couponList);
  const { isLoggedIn } = useAuth();

  const getCoupons = useCallback(async () => {
    try {
      const result = await dispatch(
        getMyCouponListAsync({
          status: CouponHistoryStatus.BEFORE_USE,
          pageCondition: {
            page: 0,
            size: 100,
            sort: null,
          },
        })
      ).unwrap();

      dispatch(setCoupons(result.content.filter((c: Coupon) => c.type !== CouponType.GOODS)));
    } catch (e) {
      console.error(e);
    }
  }, [dispatch]);

  const usableCoupons = useMemo(
    () =>
      coupons?.flatMap((c: CouponHistoryListDTO) => {
        if (c.availableStartAt && new Date() < new Date(c.availableStartAt)) {
          return [];
        }
        if (c.availableEndAt && new Date(c.availableEndAt) < new Date()) {
          return [];
        }

        const thisCoupon: CouponSummaryDTO = c.coupon;
        switch (thisCoupon.type) {
          case CouponType.TOTAL_AMOUNT_DISCOUNT:
            if (thisCoupon.availableAboveAmount && actualAmount < thisCoupon.availableAboveAmount) {
              return [];
            } else {
              return [c];
            }
          case CouponType.PRODUCT_DISCOUNT:
            const targetProduct = products.find((product) => product.product.idx === thisCoupon.productIdx);
            if (targetProduct) {
              return [c];
            } else {
              return [];
            }
          default:
            return [];
        }
      }) ?? [],
    [coupons, actualAmount, products]
  );

  const couponOptions: SelectorOption[] = useMemo(
    () => [
      {
        value: "",
        label: "적용 안 함",
      },
      ...usableCoupons.flatMap((c) => {
        const couponProductIdx = c.coupon.productIdx;
        if (couponProductIdx) {
          if (products.some((prd) => prd.product.idx === couponProductIdx)) {
            return [
              {
                value: c.idx,
                label: c.coupon.name,
              },
            ];
          } else {
            return [];
          }
        } else {
          return [
            {
              value: c.idx,
              label: c.coupon.name,
            },
          ];
        }
      }),
    ],
    [usableCoupons, products]
  );

  const alternateOptionString: string | null = useMemo(() => {
    if (!coupons || coupons.length === 0) {
      return "보유한 쿠폰이 없어요.";
    }

    const productsCouponUsable = products.filter((p) => p.product.isCouponUsable);
    if (productsCouponUsable.length === 0) {
      return "사용가능한 쿠폰이 없어요.";
    }

    if (usableCoupons.length === 0) {
      return "사용가능한 쿠폰이 없어요.";
    }

    return null;
  }, [coupons, products, usableCoupons]);

  const changeCoupon = useCallback(
    (couponIdx: number | null) => {
      const selectedCoupon = usableCoupons?.find((coupon) => coupon.idx === couponIdx);

      if (selectedCoupon) {
        const coupon: CouponSummaryDTO = selectedCoupon.coupon;

        switch (coupon.type) {
          case CouponType.TOTAL_AMOUNT_DISCOUNT:
            if (coupon.percent) {
              const totalSellingAmount = products
                .filter((prd) => prd.product.isCouponUsable)
                .reduce((totalAmount, curPrd) => totalAmount + curPrd.product.sellingPrice * curPrd.quantity, 0);

              changeSelectedCoupon(selectedCoupon.idx, Math.round(totalSellingAmount * (coupon.percent / 100)));
              return;
            }

            if (coupon.point) {
              changeSelectedCoupon(selectedCoupon.idx, coupon.point);
              return;
            }
            break;
          case CouponType.PRODUCT_DISCOUNT:
            const discountablePrd = products.find((prd) => prd.product.idx === coupon.productIdx)?.product;

            if (discountablePrd && discountablePrd.isCouponUsable) {
              const sellingPrice = discountablePrd.sellingPrice;
              if (coupon.percent) {
                changeSelectedCoupon(selectedCoupon.idx, sellingPrice * (coupon.percent / 100));
                return;
              }

              if (coupon.point) {
                changeSelectedCoupon(selectedCoupon.idx, coupon.point);
                return;
              }
            }
            break;
          default:
            break;
        }
      }
    },
    [usableCoupons, changeSelectedCoupon, products]
  );

  useEffect(() => {
    if (isLoggedIn) {
      getCoupons().then();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    changeCoupon(selectedCouponIdx);
    // eslint-disable-next-line
  }, [selectedCouponIdx]);

  return (
    <Selector
      placeholderValue={"적용할 쿠폰을 선택해주세요."}
      selectedOptionValue={selectedCouponIdx}
      options={couponOptions}
      optionAlternateString={alternateOptionString}
      selectOption={(val: number | string) => {
        if (val === "") {
          changeSelectedCoupon(null, 0);
        } else {
          changeCoupon(val as number);
        }
      }}
    />
  );
}
