import styles from "../../../assets/scss/common/button/floatingBtn.module.scss";
import { TopButton } from "./TopButton";
import { BuyVoucherBtn } from "./BuyVoucherBtn";
import { useLanguage, usePage, useScrollingEvent } from "../../../hooks/hooks";
import { useMemo } from "react";

export function FloatingBtn() {
  const { canLogin, isNotKorean } = useLanguage();
  const { isPurchasePage, isOrderPage, isWithAppPage } = usePage();
  const { scrollY } = useScrollingEvent();

  const showBuyVoucherButton = useMemo(
    () => !(isPurchasePage || isOrderPage || isNotKorean || isWithAppPage),
    [isPurchasePage, isOrderPage, isNotKorean, isWithAppPage]
  );

  return (
    <div className={styles.floatingBtn}>
      <TopButton scrollY={scrollY} hideBelowButton={!showBuyVoucherButton} />
      {canLogin && showBuyVoucherButton && <BuyVoucherBtn />}
    </div>
  );
}
