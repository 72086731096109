import { useCallback } from "react";
import { Address } from "../types/user";

export function useAddress() {
  const getAddressLine1 = useCallback((address: Address) => {
    return [address.state, address.city, address.address1].filter((value) => Boolean(value)).join(" ");
  }, []);

  return {
    getAddressLine1,
  };
}
