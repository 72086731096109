import modalStyle from "../../../../assets/scss/common/modal/modal.module.scss";
import discountImage from "../../../../assets/images/modal/affiliate_code/discount.png";
import { AffiliateCodeVO, DiscountType } from "../../../../types/affiliateCode";
import { useCallback } from "react";
import { Modal } from "../Modal";
import { useModal, useTimeConverter, useUserAgent } from "../../../../hooks/hooks";
import { ModalType } from "../../../../types/common";
import style from "./affiliateCodeModal.module.scss";
import { StrongTextStyle } from "../../StyledCommonComponents";
import { numberWithCommas } from "../../../../util/commonFunctions";

interface Props {
  codeVO: AffiliateCodeVO | null;
}

export function AffiliateCodeModal({ codeVO }: Props) {
  const { isMobile } = useUserAgent();
  const { showModal, closeModal, openAlertModal } = useModal();
  const { timeConverter } = useTimeConverter();

  const onClickCopyButton = useCallback(() => {
    if (codeVO) {
      navigator.clipboard.writeText(codeVO?.code).then(() => {
        openAlertModal({
          title: "안내",
          desc: "제휴코드 번호가 복사되었습니다.",
        });
      });
    }
  }, [codeVO, openAlertModal]);

  return (
    <Modal
      showModal={showModal === ModalType.AFFILIATE_CODE}
      title={"제휴코드"}
      closeModalFunction={closeModal}
      modalBgStyle={modalStyle.mainColorBg}
      modalCloseButtonStyle={modalStyle.mainColorBg}
      modalOtherStyle={`${modalStyle.narrowPadding} ${style.wrapper}`}
    >
      <div className={style.codeWrapper}>
        <div className={style.imageWrapper}>
          <img src={discountImage} alt={"할인"} />
        </div>
        <div className={style.infoWrapper}>
          <p className={style.description}>
            검사이용권 구매 시{isMobile ? <br /> : " "}
            아래의 코드를 입력해 주세요.
          </p>
          <div className={style.codeInfo}>
            <span className={style.code}>{codeVO?.code}</span>
            <button className={style.copyButton} onClick={onClickCopyButton}>
              복사
            </button>
          </div>
          <div className={style.discountInfo}>
            <span className={style.yellow}>
              <StrongTextStyle>
                할인금액&nbsp;&nbsp;|&nbsp;&nbsp;
                {numberWithCommas(codeVO?.discountValue ?? 0)}
                {codeVO?.discountType === DiscountType.PERCENT ? "%" : "원"} 할인
              </StrongTextStyle>
            </span>
            <span>
              <StrongTextStyle>사용기한&nbsp;&nbsp;|&nbsp;&nbsp;</StrongTextStyle>
              {timeConverter.convertToLocalDate(codeVO?.availableStartAt)} ~{" "}
              {codeVO?.availableEndAt ? timeConverter.convertToLocalDate(codeVO.availableEndAt) : "상시사용"}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
