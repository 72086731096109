import style from "./review.module.scss";
import linkey from "../../../assets/images/sub/review/section2/linkey.png";
import { WhiteButton } from "../../common/button/WhiteButton";
import pencilIcon from "../../../assets/images/sub/review/section2/pencile_icon.svg";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import React from "react";
import { useReviewWriterOpener } from "../../common/modal/review/hooks/useReviewWriterOpener";
import { useLanguage, useUserAgent } from "../../../hooks/hooks";

export function ReviewSection2() {
  const { isMobile } = useUserAgent();
  const { openReviewWriter } = useReviewWriterOpener();
  const { isKorean } = useLanguage();

  return isKorean ? (
    <section className={style.section2Wrapper}>
      <div className={style.section2}>
        <div className={style.section2Part1}>
          <div className={style.section2Part1ImageWrapper}>
            <img className={style.linkeyImg} src={linkey} alt={"링키"} />
          </div>
          <span className={style.section2Part1Desc}>
            검사 후기를 남겨주세요!
            <br />
            <StrongTextStyle>
              여러분의 소중한 후기가
              {isMobile ?  <br /> : <> </>}
              많은 분들에게 큰 도움이 됩니다:)
            </StrongTextStyle>
          </span>
        </div>
        <div className={style.section2Part2}>
          <WhiteButton text={"후기 작성하기"} imageSrc={pencilIcon} onClickFunc={openReviewWriter} />
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
}
