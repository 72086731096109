import style from "../../assets/scss/sub/miniGame.module.scss";
import bubbleTailImg from "../../assets/images/mypage/bubble_tail_icon.svg";
import { useModal, useUserAgent } from "../../hooks/hooks";

export function MiniGame({ accessToken }: { accessToken: string }) {
  const { openConfirmModal, openAlertModal } = useModal();
  const { isTablet, isMobile } = useUserAgent();

  const homeBoundAnimals = () => {
    openAlertModal({
      title: "미니게임 안내",
      desc: "[집으로 가는 길] 미니게임은 곧 공개될 예정입니다.\n 감사합니다.",
    });
  };

  // 이미지를 클릭했을 때 동작하는 함수
  const handleImageClick = (gameType: string) => {
    let url = "";

    switch (gameType) {
      case "catchMonster":
        url = `https://catch-monster-dev.feel-good.io/?token=${accessToken}`;
        break;
      case "hideNSeek":
        url = `https://hide-seek-dev.feel-good.io/?token=${accessToken}`;
        break;
      case "soundCooking":
        url = `https://sound-cooking-dev.feel-good.io/?token=${accessToken}`;
        break;
      case "animalDetective":
        url = `https://animal-detective-dev.feel-good.io/?token=${accessToken}`;
        break;
      case "homeBoundAnimals":
        url = `https://home-bound-animals-dev.feel-good.io/?token=${accessToken}`;
        break;
      default:
        break;
    }

    if (isTablet) {
      openConfirmModal(
        {
          title: "미니게임 안내",
          desc: "미니게임은 태블릿에서 \n가로모드만 지원 합니다. \n가로모드 전환 후 확인을 눌러주세요.",
        },
        () => {
          window.open(url, "_blank");
        }
      );
    } else if (isMobile) {
      openConfirmModal(
        {
          title: "미니게임 안내",
          desc: "미니게임은 모바일에서 \n가로모드만 지원 합니다. \n가로모드 전환 후 확인을 눌러주세요.",
        },
        () => {
          window.open(url, "_blank");
        }
      );
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.containerContentBox}>
        <div className={style.containerTitle}>집중력 향상 미니게임</div>
        <div className={style.speechBubbleContainer}>
          <div className={style.gameSpeechBubble}>무료 제공!</div>
          <img src={bubbleTailImg} className={style.bubbleTailImg} />
        </div>
      </div>

      <div className={style.bannerContainer}>
        <img
          src="https://images.feel-good.io/k-pass/mypage/game1.png"
          onClick={() => handleImageClick("catchMonster")}
          className={style.catchMonsterBannerImage}
        />
        <img
          src="https://images.feel-good.io/k-pass/mypage/game2.png"
          onClick={() => handleImageClick("hideNSeek")}
          className={style.catchMonsterBannerImage}
        />
        <img
          src="https://images.feel-good.io/k-pass/mypage/game3.png"
          onClick={() => handleImageClick("soundCooking")}
          className={style.catchMonsterBannerImage}
        />
        <img
          src="https://images.feel-good.io/k-pass/mypage/game4.png"
          onClick={() => handleImageClick("animalDetective")}
          className={style.catchMonsterBannerImage}
        />
        <img
          src="https://images.feel-good.io/k-pass/mypage/game5.png"
          onClick={() => handleImageClick("homeBoundAnimals")}
          className={style.catchMonsterBannerImage}
        />
      </div>
    </div>
  );
}
