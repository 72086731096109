import style from "./mainArticle1.module.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLanguage, useMainScrolling, usePage, useUserAgent } from "../../../hooks/hooks";
import audience from "../../../assets/images/main/article1/press/audience.png";
import maxAndPiece from "../../../assets/images/main/article1/press/max_piece.png";
import audienceMobile from "../../../assets/images/main/article1/press/audience_mobile.png";
import playWhite from "../../../assets/images/main/article1/press/play_white.png";
import playRed from "../../../assets/images/main/article1/press/play_red.png";
import playActivated from "../../../assets/images/main/article1/press/activated.gif";
import linkey from "../../../assets/images/main/article1/press/linkey.png";
import max from "../../../assets/images/main/article1/press/max.png";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { Trans, useTranslation } from "react-i18next";

interface VideoData {
  url: string;
  urlNotKO?: string;
  thumbnail: string;
  textElement: JSX.Element;
}
export function Article1Press() {
  const { t } = useTranslation("mainSection1");
  const { isMobile, isTablet } = useUserAgent();
  const { isWithAppPage } = usePage();
  const { isNotKorean, isKorean } = useLanguage();
  const mainVideoWrapperRef = useRef<HTMLDivElement>(null);
  const mainVideoRef = useRef<HTMLVideoElement>(null);
  const audienceRef = useRef<HTMLDivElement>(null);
  const [selectedVideoIdx, setSelectedVideoIdx] = useState(0);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const [showMainVideo, setShowMainVideo] = useState(false);
  const [showMaxAndPiece, setShowMaxAndPiece] = useState(false);
  const [isFirstLoadOnMobile, setIsFirstLoadOnMobile] = useState(false);
  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false);
  const { article1Element } = useMainScrolling();

  useEffect(() => {
    if (isWithAppPage) {
      setIsFirstLoadOnMobile(true);
      return;
    }

    if (isMobile) {
      if (showMainVideo) {
        setIsFirstLoadOnMobile(false);
      } else {
        setIsFirstLoadOnMobile(true);
      }
    }
  }, [isWithAppPage, isMobile, showMainVideo]);

  const OpeningAngleBracket = useCallback(() => <>&lt;</>, []);
  const ClosingAngleBracket = useCallback(() => <>&gt;</>, []);

  const videos: VideoData[] = useMemo(
    () => [
      {
        url: `${process.env.PUBLIC_URL}assets/video/maekyung_tv_magazine_today.mp4`,
        urlNotKO: `${process.env.PUBLIC_URL}assets/video/maekyung_tv_magazine_today_not_ko.mp4`,
        thumbnail: `${process.env.PUBLIC_URL}assets/video/maekyung_tv_magazine_today_thumbnail.png`,
        textElement: (
          <Trans
            t={t}
            i18nKey={isTablet && isKorean ? "press.video2.descriptionTablet" : "press.video2.description"}
            components={[<StrongTextStyle />, <OpeningAngleBracket />, <ClosingAngleBracket />]}
          />
        ),
      },
      {
        url: `${process.env.PUBLIC_URL}assets/video/SBS_Biz_trend_special.mp4`,
        urlNotKO: `${process.env.PUBLIC_URL}assets/video/SBS_Biz_trend_special_not_ko.mp4`,
        thumbnail: `${process.env.PUBLIC_URL}assets/video/SBS_Biz_trend_special_thumbnail.png`,
        textElement: (
          <Trans
            t={t}
            i18nKey={isTablet && isKorean ? "press.video1.descriptionTablet" : "press.video1.description"}
            components={[<StrongTextStyle />, <OpeningAngleBracket />, <ClosingAngleBracket />]}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    [t, ClosingAngleBracket, OpeningAngleBracket]
  );

  useIntersectionObserver({
    targetRef: mainVideoWrapperRef,
    callbackOnIntersecting: () => {
      setShowMainVideo(true);
      setIsThumbnailLoaded(true);
    },
    callbackOnNotIntersecting: () => setShowMainVideo(false),
  });

  useIntersectionObserver({
    targetRef: audienceRef,
    callbackOnIntersecting: () => setShowMaxAndPiece(true),
    callbackOnNotIntersecting: () => setShowMaxAndPiece(false),
  });

  useEffect(() => {
    if (showMainVideo && mainVideoRef.current) {
      // 스크롤 시 비디오 재생되는 부분 주석처리
      // mainVideoRef.current.play().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMainVideo, mainVideoRef.current, selectedVideoIdx]);

  const selectedVideo = useMemo(() => videos[selectedVideoIdx], [videos, selectedVideoIdx]);
  const selectedVideoUrl = useMemo(() => {
    if (isNotKorean && selectedVideo.urlNotKO) return selectedVideo.urlNotKO;
    else return selectedVideo.url;
  }, [isNotKorean, selectedVideo]);

  return (
    <section className={style.pressSection} ref={article1Element}>
      <div className={`${style.maxAndPiece} ${showMaxAndPiece ? style.show : style.hide}`}>
        <img src={maxAndPiece} alt={"max and piece character"} />
      </div>
      <div className={style.audienceBgItem} ref={audienceRef}>
        <img src={!isMobile ? audience : audienceMobile} alt={"audience"} />
      </div>
      <div className={style.pressContent}>
        <div className={style.texts}>
          <h2 className={style.pressTitle}>
            <Trans t={t} i18nKey={"press.video1.title"} components={[<StrongTextStyle />]} />
          </h2>
          <p
            key={selectedVideoIdx}
            className={`${style.pressDescription} ${isFirstLoadOnMobile || showMainVideo ? style.show : ""}`}
          >
            {videos[selectedVideoIdx].textElement}
          </p>
        </div>
        <div className={style.videoWrapper}>
          <div className={`${style.linkeyWrapper} ${showMainVideo ? style.show : style.hide}`}>
            <img src={linkey} alt={"linkey"} />
          </div>
          <div className={style.mainVideo} ref={mainVideoWrapperRef}>
            <video
              playsInline={true}
              controls
              key={selectedVideoUrl}
              loop={videos.length === 1}
              onEnded={() => {
                if (videos.length > 1) setSelectedVideoIdx((selectedVideoIdx + 1) % videos.length);
                setIsVideoStarted(false);
              }}
              onPlay={() => {
                setIsVideoStarted(true);
              }}
              ref={mainVideoRef}
            >
              <source src={selectedVideoUrl} type="video/mp4"></source>
            </video>
          </div>
          {videos.length > 1 && (
            <div className={style.videoThumbnails}>
              {videos.map(({ thumbnail }, index) => (
                <button
                  key={`video-${index}`}
                  className={`${selectedVideoIdx === index ? style.active : ""} ${
                    showMainVideo || isThumbnailLoaded ? style.show : style.hide
                  }`}
                  onClick={() => {
                    setSelectedVideoIdx(index);
                    if (selectedVideoIdx !== index) {
                      setIsVideoStarted(false); // Reset the state to indicate the video is not started yet
                    }

                    // Play the video immediately after changing the index
                    if (mainVideoRef?.current) {
                      setTimeout(() => {
                        mainVideoRef?.current?.load(); // Ensure the video source is updated
                        mainVideoRef?.current?.play(); // Play the selected video immediately
                        setIsVideoStarted(true); // Mark the video as started
                      }, 0);
                    }
                  }}
                  style={{
                    backgroundImage: !isMobile ? "" : `url(${thumbnail})`,
                    transitionDelay: `${index * 0.1}s`,
                  }}
                >
                  <div className={style.buttonCover}>
                    <div className={style.default}></div>
                    <div className={style.hover}></div>
                  </div>
                  <div className={style.buttonIcon}>
                    <img className={style.default} src={playWhite} alt={"play button"} />
                    <img className={style.hover} src={playRed} alt={"play hover button"} />
                    <img className={style.active} src={playActivated} alt={"play activated button"} />
                  </div>
                  {!isMobile && <img className={style.thumbnailImage} src={thumbnail} alt={"thumbnail"} />}
                </button>
              ))}
              {videos.length > 1 && (
                <>
                  {Array(4 - videos.length)
                    .fill(undefined)
                    .map((_, index) => (
                      <div key={`comingSoon-${index}`} className={style.comingSoon}>
                        <span className={style.text}>
                          Coming
                          <br /> Soon..!
                        </span>
                        <img className={style.max} src={max} alt={"max"} />
                      </div>
                    ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
