interface AddedFile {
  uri: string;
  fileIdx: number;
}

enum FileClassification {
  KPASS_REVIEW_MAIN_IMAGE = "KPASS_REVIEW_MAIN_IMAGE",
  KPASS_REVIEW_MAIN_THUMBNAIL_IMAGE = "KPASS_REVIEW_MAIN_THUMBNAIL_IMAGE",
  KPASS_REVIEW_IMAGE = "KPASS_REVIEW_IMAGE",
}

interface FileResponse {
  idx: number;
  uri: string;
}

interface FileType {
  src: string;
  file: File;
}

export enum FileCategory {
  IMAGE,
  VIDEO,
}

export interface DetailFileType {
  type: FileCategory;
  url: string;
}

export function instanceOfAddedFile(object: any): object is FileType {
  return object !== undefined && "uri" in object && "fileIdx" in object;
}

export function instanceOfFileType(object: any): object is FileType {
  return object !== undefined && "src" in object && "file" in object;
}

export type { AddedFile, FileResponse, FileType };
export { FileClassification };
