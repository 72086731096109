import { MainApi } from "./MainApi";
import { CouponListSearchCondition } from "../types/coupon";

export class CouponApi {
  static baseUrl = `${MainApi.urlPrefix}/user/coupon`;

  static getCouponCount = () => () => MainApi.api.get(`${CouponApi.baseUrl}/count`);

  static getMyCouponList = (condition: CouponListSearchCondition) => () =>
    MainApi.api.get(`${CouponApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  // mypage 보유 쿠폰 api
  static organUrl = `${MainApi.urlPrefix}/organ-manager`;
  static getAllCoupon = () => () => MainApi.api.get(`${CouponApi.organUrl}/organ/count-all-organ-coupon`);

  // mypage 이용권 내역 kpass & dcas
  static getKpassCoupon = () => () => MainApi.api.get(`${CouponApi.organUrl}/voucher/count?type=KPASS`);
  static getDcasCoupon = () => () => MainApi.api.get(`${CouponApi.organUrl}/voucher/count?type=DCAS`);

  // mypage 누적검사 횟수
  static getAllTestCount = () => () => MainApi.api.get(`${CouponApi.organUrl}/organ/count-all-organ-test`);

  // mypage 쿠폰내역
  static getAllCouponList = (orderIdx: number) => () =>
    MainApi.api.get(`${CouponApi.baseUrl}?status=BEFORE_USE&page=${orderIdx}&size=4`);

  // mypage 보유쿠폰
  static getAllCouponCount = () => () => MainApi.api.get(`${CouponApi.baseUrl}?status=BEFORE_USE`);
}
