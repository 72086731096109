import style from "../../assets/scss/sub/circleTitle.module.scss";
import { useLanguage } from "../../hooks/hooks";

export function CircleTitle({ title }) {
  const { isNotKorean } = useLanguage();

  return (
    <div className={`${style.titleCircle} ${isNotKorean ? style.ru : ""}`}>
      <div className={style.innerCircle}></div>
      <span className={style.text}>{title}</span>
    </div>
  );
}
