import styles from "../../../assets/scss/common/modal/modal.module.scss";
import {useEffect, useRef} from "react";
import { CLICK_TARGET_NAME } from "../../../util/events";

interface Props {
  showModal: boolean;
  title: string;
  closeModalFunction: VoidFunction;
  children: JSX.Element;
  modalBgStyle: string;
  modalOtherStyle?: string;
  modalTitleStyle?: string;
  modalCloseButtonStyle?: string;
  isPathChanged?: number;
}

export function Modal({
  showModal,
  title,
  closeModalFunction,
  children,
  modalBgStyle,
  modalOtherStyle,
  modalTitleStyle,
  modalCloseButtonStyle,
  isPathChanged,
}: Props) {
  const modalRef = useRef<HTMLDivElement>(null);
  const onClickCloseBtn = () => closeModalFunction();
  // @ts-ignore

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [isPathChanged]);


  return (
    <div className={`${styles.backdrop} ${showModal ? "" : styles.hide}`}>
      <div
        className={`${styles.modal} ${modalBgStyle} ${modalOtherStyle ?? ""}`}
        data-name={CLICK_TARGET_NAME}
        ref={modalRef}
      >
        <div className={`${styles.title} ${modalTitleStyle ?? ""}`}>
          <span className={styles.loginSpan}>{title}</span>
        </div>
        <div
          className={`${styles.closeBtn} ${modalBgStyle} ${modalCloseButtonStyle ?? ""}`}
          onClick={onClickCloseBtn}
         />
        {children}
      </div>
    </div>
  );
}
