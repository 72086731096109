import styles from "../../assets/scss/sub/editMember.module.scss";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {SignupNationField} from "../common/modal/login/signup/component/SignupNationField";
import {ModalType, StyleRequestType} from "../../types/common";
import {SignupNameField} from "../common/modal/login/signup/component/SignupNameField";
import {SignupCellphoneField} from "../common/modal/login/signup/component/SignupCellphoneField";
import {Address, Gender, UserInfoUpdateDTO} from "../../types/user";
import {SignupBirthdayField} from "../common/modal/login/signup/component/SignupBirthdayField";
import {SignupGenderField} from "../common/modal/login/signup/component/SignupGenderField";
import {SignupAddressField} from "../common/modal/login/signup/component/SignupAddressField";
import {useFooter, useModal} from "../../hooks/hooks";
import {useUserInfoGetter} from "../common/hooks/useUserInfoGetter";
import {useCookie} from "../../hooks/auth/useCookie";
import {CookieKeys} from "../../hooks/auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import {useModifyMemberValidator} from "../common/modal/login/signup/hooks/useModifyMemberValidator";
import {useModifyMember} from "../common/modal/login/signup/hooks/useModifyMember";
import {convertDateFormat, convertToOriginalFormat} from "../../util/commonFunctions";
import {useProfileInfoGetter} from "../common/hooks/useProfileInfoGetter";
import {VoucherData} from "../../types/voucher";

export function EditMember() {
    const {setShowFooter} = useFooter();
    const {user} = useUserInfoGetter();
    const {getValueFromCookie} = useCookie();
    const {openAlertModal, openConfirmModal, openModal} = useModal();
    const navigate = useNavigate();
    const {modifyMember} = useModifyMember();
    const [voucherData, setVoucherData] = useState<VoucherData[]>([]);
    const {getAuthToken, fetchVoucherData} =
        useProfileInfoGetter({voucherData: voucherData, setVoucherData: setVoucherData});

    useEffect(() => {
        getProfileInfos();
    }, [user]);

    const getProfileInfos = useMemo(() => {
        return () => {
            if (user) {
                const uniqueProfiles = Array.from(new Set(user.profiles.map(profile => profile.idx)));

                return Promise.all(
                    uniqueProfiles.map(profileIdx => {
                        return getAuthToken({ userProfileIdx: profileIdx }).then(fetchVoucherData);
                    })
                )
                    .then(() => {
                        console.log("All tokens fetched and voucher data updated.");
                    })
                    .catch(error => {
                        console.error("An error occurred:", error);
                    });
            }
        };
    }, [user]);

    const [nationality, setNationality] = useState("KR");
    const [name, setName] = useState("");
    const [phoneCode, setPhoneCode] = useState("+82");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthday, setBirthday] = useState('');
    const [isAgreementEmailMarketingReceipt, setIsAgreementEmailMarketingReceipt] = useState(false);
    const [isAgreementSMSMarketingReceipt, setIsAgreementSMSMarketingReceipt] = useState(false);
    const [isAgreementPersonalInfoCollectionAndUsage, setIsAgreementPersonalInfoCollectionAndUsage] = useState(false);
    const [gender, setGender] = useState(Gender.NONE);
    const [address, setAddress] = useState<Address>({
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',

    });

    const nationalityRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const birthdayRef = useRef<HTMLInputElement>(null);
    const genderRef = useRef<HTMLInputElement>(null);
    const addressRef = useRef<HTMLDivElement>(null);
    const addressDetailRef = useRef<HTMLInputElement>(null);
    const foreignAddressDetailRef = useRef<HTMLInputElement>(null);


    // 렌더링 후 초기 정보 매핑
    useEffect(() => {
        if (user) {
            setNationality(user.nationality);
            setName(user.name);
            setPhoneCode(user.codePhone);
            setPhoneNumber(user.phoneNumber);
            setBirthday(convertDateFormat(user.birthday));
            setGender(user.gender);
            setAddress(user.address);
            setIsAgreementPersonalInfoCollectionAndUsage(user.isAgreementPersonalInfoCollectionAndUsage);
            setIsAgreementSMSMarketingReceipt(user.isAgreementSMSMarketingReceipt);
            setIsAgreementEmailMarketingReceipt(user.isAgreementEmailMarketingReceipt);
        }
    }, [user]);

    // 비로그인 접근 유저 처리
    useEffect(() => {
        setShowFooter(true);
        const accessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
        if (accessToken === undefined) {
            openAlertModal({
                title: "안내",
                desc:
                    "로그인 후 이용해주세요.",
                callback: () => navigate('/'),
            })
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [showNation, setShowNation] = useState(false);
    const [showAddressNation, setShowAddressNation] = useState(false);
    const [showCellphone, setShowCellphone] = useState(false);
    const styleRequestType = StyleRequestType.MEMBER

    // 유효성 검사
    const {
        validate,
        nationValidate, setNationValidate,
        nameValidate, setNameValidate,
        phoneCodeValidate, setPhoneCodeValidate,
        phoneNumberValidate, setPhoneNumberValidate,
        birthdayValidate, setBirthdayValidate,
        genderValidate, setGenderValidate,
    } = useModifyMemberValidator({
        address, phoneCode,
        nationField: {value: nationality, ref: nameRef},
        nameField: {value: name, ref: nameRef},
        phoneField: {value: phoneNumber, ref: phoneRef},
        birthdayField: {value: birthday, ref: birthdayRef},
        genderField: {value: gender, ref: genderRef},
        addressField: {value: address.city, ref: addressRef},

        // @ts-ignore
        addressDetailField: {value: address.address2, ref: addressDetailRef},
        foreignAddressDetailField: {value: address.address1, ref: addressDetailRef}
    });

    const selectAgreeWrappers = [
        {
            title: '[선택]',
            subTitle: '개인정보 수집 및 이용에 동의합니다.',
            isAgreed: isAgreementPersonalInfoCollectionAndUsage,
            setAgreement: setIsAgreementPersonalInfoCollectionAndUsage,
        },
        {
            title: '[선택]',
            subTitle: '이메일 : 이벤트 등 마케팅 수신에 동의합니다.',
            isAgreed: isAgreementEmailMarketingReceipt,
            setAgreement: setIsAgreementEmailMarketingReceipt,
        },
        {
            title: '[선택]',
            subTitle: '문자(SMS) : 이벤트 등 마케팅 수신에 동의합니다.',
            isAgreed: isAgreementSMSMarketingReceipt,
            setAgreement: setIsAgreementSMSMarketingReceipt,
        },
    ];

    const showAlert = (desc: string) => {
        openAlertModal({
            title: "회원탈퇴 안내",
            desc,
        });
    };

    // 서비스 탈퇴 버튼 클릭 시
    const onClickExitMember = () => {
        if (!voucherData || !user) {
            showAlert(`일시적인 오류가 발생했습니다.\n다시 시도해 주세요.`);
            return;
        }
        // 변수 초기화
        let isExitable = true;

        // 자녀를 포함한 프로필 중 소속이용권 있는지 체크
        for (let i = 0; i < voucherData.length; i++) {
            // @ts-ignore
            isExitable = (voucherData[i].organKpassVoucherCnt + voucherData[i].organDcasVoucherCnt) === 0;
            if (!isExitable) break;
        }

        if (voucherData.length < user.profiles.length) {
            showAlert(`잠시 후 다시 시도해 주세요.`);
            getProfileInfos();
            return;
        }

        if (isExitable) {
            openModal(ModalType.LEAVE);
        } else {
            showAlert(`소속이용권을 보유 중입니다.\n소속이용권을 보유 중인 계정은 탈퇴할 수 없습니다.\n소속관리자에게 문의해 주세요.`);
        }
    };

    // 수정하기 버튼 클릭 시
    const onClickMemberInfoEdit = () => {
        const result = validate();

        if (result) {
            const modifyProperties: UserInfoUpdateDTO = {
                name: name,
                birthday: convertToOriginalFormat(birthday),
                gender: gender,
                codePhone: phoneCode,
                phoneNumber: phoneNumber,
                address: address,
                nationality: nationality,
                isAgreementEmailMarketingReceipt: isAgreementEmailMarketingReceipt,
                isAgreementSMSMarketingReceipt: isAgreementSMSMarketingReceipt,
                isAgreementPersonalInfoCollectionAndUsage: isAgreementPersonalInfoCollectionAndUsage,
            };

            openConfirmModal(
                {
                    title: "회원정보 수정 안내",
                    desc: "회원정보를 정말 수정하시겠습니까?",
                },
                () => {
                    modifyMember(modifyProperties).then(r => {
                        openAlertModal({
                            title: "회원정보 수정 안내",
                            desc: "회원정보가 수정 되었습니다.",
                            callback: () => window.location.href = '/mypage'
                        })
                    })
                }
            );
        }
    }

    // 취소 버튼 클릭 시
    const onClickCancel = () => {
        openConfirmModal(
            {
                title: "회원정보 수정 안내",
                desc: "해당 페이지를 벗어날 경우\n" +
                    "수정한 회원정보가 모두 사라집니다.\n" +
                    "페이지를 정말 벗어나시겠습니까?",
            },
            () => {
                navigate("/mypage")
            }
        );
    }

    return (
        <>
            <div className={styles.mainWrapper}>
                {/* 타이틀 */}
                <div className={styles.titleWrapper}>
                    <span className={styles.titleText}>회원정보 수정</span>
                </div>

                {/* 회원 정보 수정 부분 */}
                <div className={styles.editMainWrapper}>
                    <div className={styles.editWrapper}>
                        <div className={styles.inputZone}>
                            <SignupNationField nationality={nationality} setNationality={setNationality}
                                               showNation={showNation} setShowNation={setShowNation}
                                               nationValidate={nationValidate} setNationValidate={setNationValidate}
                                               styleRequestType={styleRequestType}
                                               nationalityRef={nationalityRef}
                            />
                            <SignupNameField name={name} setName={setName}
                                             nameValidate={nameValidate} setNameValidate={setNameValidate}
                                             styleRequestType={styleRequestType}
                                             nameRef={nameRef}
                            />

                            <SignupCellphoneField phoneCode={phoneCode} setPhoneCode={setPhoneCode}
                                                  phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                                                  showCellphone={showCellphone} setShowCellphone={setShowCellphone}
                                                  phoneCodeValidate={phoneCodeValidate}
                                                  setPhoneCodeValidate={setPhoneCodeValidate}
                                                  phoneNumberValidate={phoneNumberValidate}
                                                  setPhoneNumberValidate={setPhoneNumberValidate}
                                                  styleRequestType={styleRequestType}
                                                  phoneRef={phoneRef}
                            />

                            <SignupBirthdayField birthday={birthday} setBirthday={setBirthday}
                                                 birthdayValidate={birthdayValidate}
                                                 setBirthdayValidate={setBirthdayValidate}
                                                 styleRequestType={styleRequestType}
                                                 birthdayRef={birthdayRef}
                            />
                            <SignupGenderField gender={gender} setGender={setGender}
                                               genderValidate={genderValidate} setGenderValidate={setGenderValidate}
                                               styleRequestType={styleRequestType}
                            />
                            <SignupAddressField address={address} setAddress={setAddress}
                                                showNation={showAddressNation} setShowNation={setShowAddressNation}
                                                styleRequestType={styleRequestType}
                                                addressRef={addressRef}
                                                addressDetailRef={addressDetailRef}
                                                foreignAddressDetailRef={foreignAddressDetailRef}
                            />

                            {/*  선택 동의 영역  */}
                            <div className={styles.selectAgreement}>
                                {selectAgreeWrappers.map((wrapper, index) => (
                                    <div className={styles.selectAgreeWrapper} key={index}
                                         onClick={() => wrapper.setAgreement(!wrapper.isAgreed)}>
                                        <div className={styles.selectAgreeTextWrapper}>
                                            <span className={styles.selectAgreeTitle}>{wrapper.title}</span>
                                            <span className={styles.selectAgreeSubTitle}>{wrapper.subTitle}</span>
                                        </div>
                                        <div
                                            className={`${styles.agreeCheckImg} ${wrapper.isAgreed ? styles.selected : ''}`}></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 서비스 탈퇴 버튼 */}
                    <div className={styles.exitWrapper}>
                        <button className={styles.exitButton} onClick={onClickExitMember}>
                            <span className={styles.text}>서비스 탈퇴</span>
                        </button>
                    </div>
                </div>

                {/* 뒤에 깔리는 이미지 */}
                <div className={styles.backgroundImg_1}/>
                <div className={styles.backgroundImg_2}/>


                {/*  수정 / 취소 버튼  */}
                <div className={styles.buttonWrapper}>
                    <button
                        className={styles.cancelButton}
                        onClick={onClickCancel}>
                        <span className={styles.text}>취소</span>
                    </button>
                    <button
                        className={styles.editButton}
                        onClick={onClickMemberInfoEdit}>
                        <span className={styles.text}>수정하기</span>
                    </button>
                </div>
            </div>
        </>
    )
}