import {ConsultationItem} from "../../../../types/consultation";
import style from "../../../../assets/scss/sub/consultantHistory.module.scss";
import statusStyle from "../../../../assets/scss/common/modal/addConsultationModal.module.scss";
import React from "react";

export function useConsultationFunctions() {
    const getStatusMessage = (status?: string) => {
        if (!status) return '상담신청';

        switch (status?.toLowerCase()) {
            case "refunded":
                return "환불처리";
            case "allotted":
                return "상담사 배정완료";
            case "finished":
                return "상담종료";
            case "not_allotted":
                return "상담사 미배정";
            default:
                return "싱담신청";
        }
    }

    const getConsultationStatus = (status?: string) =>{
        switch (status) {
            case "NOT_ALLOTTED":
                return <span className={statusStyle.innerTextDesc}>상담사 미배정</span>;
            case "ALLOTTED":
                return <span className={statusStyle.purpleText}>상담사 배정완료</span>;
            case "FINISHED":
                return <span className={statusStyle.redText}>상담종료</span>;
            case "REFUNDED":
                return <span className={statusStyle.redText}>환불 처리</span>;
            case "CANCELED":
                return <span className={statusStyle.redText}>취소 처리</span>;
            default:
                return <span>테스트</span>;
        }
    }

    const getButtonContent = (item:ConsultationItem, toggleModal:any) => {
        switch (item.status?.toLowerCase()) {
            case "finished":
                return (
                    <button
                        className={style.consultReviewBtn}
                        onClick={() => toggleModal(item)}
                    >
                        상담리뷰
                    </button>
                );
            case "not_allotted":
                return (
                    <button className={style.consultBtn} onClick={() => toggleModal(item)}>
                        상세보기
                    </button>
                )
            default:
                return <>-</>
        }
    };



    return {
        getButtonContent, getStatusMessage, getConsultationStatus
    }
}