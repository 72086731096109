import style from "./koreanKpassStandardization.module.scss";
import link from "../../assets/images/footer/link_orange_icon.svg";
import feelgoodWhiteLogo from "../../assets/images/footer/footer_feelgood_logo.svg";
import feelgoodLogo from "../../assets/images/logo/feelgoodLogoGray.svg";
export function StandardizationFooterKO() {
  return (
    <footer className={style.footerWrapper}>
      <div className={style.part1Wrapper}>
        <div className={style.part1}>
          <div className={style.feelgood}>
            <img src={feelgoodWhiteLogo} alt={"필굿 로고"} />
            <span>필굿은 어떤 회사인가요?</span>
          </div>
          <a className={style.link} href={"https://www.feel-good.io/"} target={"_blank"} rel="noreferrer">
            <img src={link} alt={"링크 아이콘"} />
            <span>기업 홈페이지 바로가기</span>
          </a>
        </div>
      </div>
      <div className={style.part2Wrapper}>
        <div className={style.part2}>
          <div className={style.companyInfo}>
            <div className={style.name}>(주)필굿</div>
            <p className={style.otherInfo}>
              대표{"  "}|{"  "}임유하{"        "}사업자 등록번호{"  "}|{"  "}397-81-01770{"        "}통신판매업
              {"  "}|{"  "}제2020-부산수영-0962호
              <br /> 문의{"  "}|{"  "}MAIL : contact@feel-good.io{"        "}전화{"  "}|{"  "}051-747-4050
              <br /> 부산광역시 남구 전포대로 133, 14층 102호(문현동)
              <br /> 경기도 성남시 분당구 정자로 2, 1층 106호(정자동)
            </p>
          </div>
          <div className={style.logo}>
            <img src={feelgoodLogo} alt={"필굿 로고"} />
          </div>
        </div>
      </div>
    </footer>
  );
}
