// profileSlice.ts
import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../../util/sliceUtil";
import { ProfileApi } from "../../api/ProfileApi";
import { RootState } from "../index";
import { NewUserProfile, UserProfile } from "../../types/user";

export interface ProfileState extends SliceState {
  profile?: UserProfile; // Optional: You may need this if you're storing the created profile in the state
}

const initialState: ProfileState = {
  status: Status.IDLE,
};

export const getProfilesAsync = createAsyncThunk("profiles/getProfiles", () =>
  executePromise(ProfileApi.getProfiles())
);

export const getProfileAsync = createAsyncThunk("profiles/getProfile", (profileIdx: number) =>
  executePromise(ProfileApi.getProfile(profileIdx))
);

export const getProfileTestHistoryAsync = createAsyncThunk(
  "profiles/getProfileHistory",
  ({ profileIdx }: { profileIdx: number }) => executePromise(ProfileApi.getProfileTestHistory(profileIdx))
);

export const getDcasProfileTestHistoryAsync = createAsyncThunk(
  "profiles/getDcasProfileHistory",
  ({ profileIdx }: { profileIdx: number }) => executePromise(ProfileApi.getDcasUserTestHistory(profileIdx))
);

export const createProfileAsync = createAsyncThunk("profiles/createProfile", (param: NewUserProfile) =>
  executePromise(ProfileApi.createProfile(param))
);

export const deleteProfileAsync = createAsyncThunk("profiles/deleteProfile", (profileIdx: number) =>
  executePromise(ProfileApi.deleteProfile(profileIdx))
);

export const editProfileAsync = createAsyncThunk(
  "profiles/editProfile",
  ({ profileIdx, param }: { profileIdx: number; param: NewUserProfile }) =>
    executePromise(ProfileApi.editProfile(profileIdx, param))
);

export const getProfileAuthTokenAsync = createAsyncThunk(
  "profiles/getProfileAuthToken",
  (param: { userProfileIdx: number }) => executePromise(ProfileApi.getProfileAuthToken(param))
);

export const getProfileOrganInfoAsync = createAsyncThunk("profiles/getProfileOrgainInfo", (profileIdx: number) =>
  executePromise(ProfileApi.getProfileOrganInfo(profileIdx))
);

export const getOrganTestResultAsync = createAsyncThunk(
  "profiles/getProfileOrganTestResult",
  async ({ name, code }: { name: string; code: string }) => {
    return await executePromise(ProfileApi.getOrganTestResult(name, code));
  }
);

export const addOrganTestResultAsync = createAsyncThunk(
  "profiles/addOrganTestResult",
  async ({ profileIdx, name, code }: { profileIdx: number; name: string; code: string }) => {
    return await executePromise(ProfileApi.addOrganTestResult(profileIdx, name, code));
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, getProfilesAsync);
    addCases(builder, getProfileAsync);
    addCases(builder, getProfileTestHistoryAsync);
    addCases(builder, createProfileAsync);
    addCases(builder, getProfileAuthTokenAsync);
    addCases(builder, getProfileOrganInfoAsync);
    addCases(builder, getOrganTestResultAsync);
    addCases(builder, addOrganTestResultAsync);
  },
});

export const profileStatusState = (state: RootState) => state.profile.status;

export default profileSlice.reducer;
