import { useAppDispatch } from "../hooks";
import { FileClassification } from "../../types/file";
import { uploadFilesAsync } from "../../store/file/fileSlice";

export function useFile() {
  const dispatch = useAppDispatch();

  const uploadImages = async (files: File[], fileClassification: FileClassification) => {
    return await dispatch(uploadFilesAsync({ files, classification: fileClassification })).unwrap();
  };

  return {
    uploadImages,
  };
}
