import loginStyle from "../../../../../../assets/scss/common/modal/loginModal.module.scss";
import memberStyle from "../../../../../../assets/scss/sub/editMember.module.scss";
import React, {RefObject, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {countryList, getCountriesAsync, setCountries} from "../../../../../../store/country/countrySlice";
import {Country} from "../../../../../../types/country";
import {filterAllowedCountries, handleOnlyNumberChange} from "../../../../../../util/commonFunctions";
import {ValidateStatus} from "../hooks/useSignupValidator";
import {StyleRequestType} from "../../../../../../types/common";

interface Props {
    phoneCode: string;
    setPhoneCode: (_: string) => void;
    phoneNumber: string;
    setPhoneNumber: (_: string) => void;
    showCellphone: boolean;
    setShowCellphone: (_: boolean) => void;
    phoneCodeValidate: ValidateStatus;
    setPhoneCodeValidate: (_: ValidateStatus) => void;
    phoneNumberValidate: ValidateStatus;
    setPhoneNumberValidate: (_: ValidateStatus) => void;
    styleRequestType: StyleRequestType,
    phoneRef?: RefObject<HTMLInputElement>;
}

export function SignupCellphoneField({
     phoneCode,
     setPhoneCode,
     phoneNumber,
     setPhoneNumber,
     showCellphone,
     setShowCellphone,
     phoneCodeValidate,
     setPhoneCodeValidate,
    phoneNumberValidate,
    setPhoneNumberValidate,
    styleRequestType,
    phoneRef
                                     }: Props) {

    const dispatch = useAppDispatch();
    const countries = useAppSelector(countryList);
    const styles = styleRequestType === StyleRequestType.SIGN_UP ? loginStyle :  memberStyle;

    const allowedCountryCodes = ['KR', 'US', 'MN', 'TW', 'VN'];
    const filteredOptions = filterAllowedCountries({countries, allowedKeys:allowedCountryCodes});

    // 현재 선택된 국가
    const selectedCountry = countries?.find(option => option.codePhone[0] === phoneCode);
    const isDefaultOption = !selectedCountry;

    // 국가정보 조회 함수
    useEffect(() => {
        if (!countries) {
            dispatch(getCountriesAsync())
                .unwrap()
                .then((countries) => {
                    dispatch(setCountries(countries));
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    // 휴대전화 국가 셀렉트 바 선택 함수
    const handleCellphoneClick = (option: Country) => {
        setPhoneCode(option.codePhone[0]);
        setShowCellphone(false);
        setPhoneCodeValidate(ValidateStatus.NONE);
    };

    const getValidationMessage = () => {
        // 국가 선택 케이스
        if (phoneCodeValidate === ValidateStatus.UNFILLED) {
            return <span className={styles.validateText}>국가를 선택해주세요.</span>;
        }

        // 휴대폰 번호 미작성 케이스
        if (phoneCodeValidate === ValidateStatus.NONE) {
            if (phoneNumberValidate === ValidateStatus.UNFILLED) {
                return <span className={styles.validateText}>휴대폰 번호를 입력해주세요.</span>;
            }

            // 국내 휴대폰 번호 길이 제한 케이스
            if (phoneCode === '+82') {
                if (phoneNumberValidate === ValidateStatus.BELOW_REQUIRED_LENGTH || phoneNumberValidate === ValidateStatus.OVER_REQUIRED_LENGTH) {
                    return <span className={styles.validateText}>휴대폰 번호는 11자리 숫자로 입력해 주세요.</span>;
                }
            // 해외 휴대폰 번호 길이 제한 케이스
            } else {
                if (phoneNumberValidate === ValidateStatus.BELOW_REQUIRED_LENGTH_FOREIGN) {
                    return <span className={styles.validateText}>휴대폰 번호는 5~15자리 숫자로 입력해 주세요.</span>;
                }
            }
        }

        return null;
    };

    return (
        <div className={styles.selectWrapper}>
            <div className={styles.selectTitleWrapper}>
                <span className={styles.highLight}>[필수]</span>
                <span className={styles.titleText}>휴대폰 번호</span>
            </div>
            <div className={styles.selectTextFieldWrapper} onClick={() => setShowCellphone(!showCellphone)}>
          <span className={`${styles.selectText} ${isDefaultOption ? styles.defaultText : ''}`}>
          {filteredOptions ? (
              selectedCountry ?
                  `(${selectedCountry.codePhone}) ${selectedCountry.nameKor}`
                  : '국가 선택'
          ) : 'Loading...'}
        </span>
                <div className={styles.arrowImg}></div>
                {filteredOptions && showCellphone && (
                    <div className={`${styles.optionStyle} ${styles.cellPhoneSelectStyle} `}>
                        {filteredOptions.map((option) => (
                            <button
                                key={option.nameKor}
                                className={`${styles.optionTextWrapper} ${option.codePhone[0] === phoneCode ? styles.selectedOption : ''}`}
                                onClick={() => handleCellphoneClick(option)}
                            >
                                <span
                                    className={`${styles.optionText} ${option.codePhone[0] === phoneCode ? styles.selectedOption : ''}`}>
                                    ({option.codePhone[0]}) {option.nameKor}
                                </span>
                            </button>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.selectTextFieldWrapper}>
                <input className={styles.selectText} ref={phoneRef}
                       maxLength={phoneCode === '+82' ? 11 : 15}
                       value={phoneNumber}
                       onChange={e => {
                           handleOnlyNumberChange(e, setPhoneNumber)
                           setPhoneNumberValidate(ValidateStatus.NONE)
                       }}
                       placeholder={"휴대폰 번호를 입력해 주세요."}/>
            </div>
            {getValidationMessage()}
            <span className={styles.cellPhoneInfo}>로그인 시 입력한 휴대폰 번호로 인증코드가 보내집니다.
                        <br/>정확한 번호를 입력해 주세요.
                    </span>
        </div>
    )
}