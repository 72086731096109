import style from "../koreanKpassStandardization.module.scss";
import { ArticleHeaderKO } from "./ArticleHeaderKO";
import { SubtitleNumbering } from "../../main/article3/SubtitleNumbering";
import React from "react";
import { ArticleFooterKO } from "./ArticleFooterKO";

export function References() {
  return (
    <article className={style.article}>
      <div className={style.contentHeaderWrapper}>
        <ArticleHeaderKO />
      </div>
      <section className={style.contentSection}>
        <div style={{ marginBottom: "70px" }}>
          <SubtitleNumbering lightNumber={0} boldNumber={5} fontSize={"85px"} />
          <h2 className={style.articleTitle}>참고 문헌</h2>
        </div>
        <div className={style.part}>
          <p className={style.partSummary}>
            문수백. (1997). 한국판 K-ABC 해석요강. 서울: 학지사.
            <br />
            Aiken, L. R. (1987). Assessment of Intellectual Functioning. Boston: Allyn & Bacon.
            <br />
            American Educational Research Association, American Psychological Association, & National Council on
            Measurement in Education. (1985). Standards for Educational and Psychological Testing. Washington, DC:
            American Psychological Association.
            <br />
            American Psychiatric Association (1994). Diagnostic and Statistical Manual of Mental Disorders (4th ed.).
            Washington, DC: Author.
            <br />
            Anastasi, A. (1988). Psychological Testing (6th ed.). New York: Macmillan.
            <br />
            Crocker, L., & Algina, J. (1986). Introduction to Classical and Modern Test Theory.
            <br />
            Das, J. P. (1970). Changes in Stroop Test Responses as a Fuction of Mental Age. British Journal of Social
            and Clinical Psychology, 9, 68-73.
            <br />
            Das, J. P. (1972). Patterns of Cognitive Ability in Nonretarded and Retarded Children. American Journal of
            Mental of Educational Psychology, 65, 103-108.
            <br />
            Das, J. P. (1980). Planning: Theoretical Considerations and Empirical Evidence. Psychological Research, 41,
            141-151.
            <br />
            Das, J. P. (1983). Process Training and Remediation of Reading Disability: Examples of Some Soviet Tasks.
            The Mental Retardation and Learning Disability Bulletin, 11, 32-41.
            <br />
            Das, J. P. (1984a). Aspect of planning. In J. R. Kirby (Ed.). Cognitive Strategies and Educational
            Performance (pp. 13-31). New York: Academic Press.
            <br />
            Das, J. P. (1984b). Cognitive Deficits in Mental Retardation: A Process Approach. In P. H. Brookes, R.
            Sperder, & C. McCauley (Eds.). Learning and Cognition in the Mentally Retarded (pp. 115-128). Hillsdale, NJ:
            Erlbaum.
            <br />
            Das, J. P. (1993). Difference in Cognitive Processes of Children with Reading Disabilities and Normal
            Readers. Developmental Disabilities Bulletin, 21, 46-62.
            <br />
            Das, J. P., Mishra, R. K. (1991). Relation between Memory Span, Naming Time, Speech Rate and Reading
            Competence. Journal of Experimental Education, 59, 129-139.
            <br /> Das, J. P., & Mishra, R. K. (1994). The Role of Speech Processes and Memory in Reading Disability.
            The Journal of General Psychology, 12, 131-146.
            <br /> Das, J. P., Carlson, J., Davidson, M. B., & Longe, K. (1997). PREP: PASS Remedial Program. Seattle,
            WA: Hogrefe.
            <br />
            Das, J. P., & Cummins, J. (1979). Academic Performance and Cognitive Processes in EMR Children. American
            Journal of Mental Deficiency, 83, 197-199.
            <br />
            Das, J. P., & Cummins, J. (1982). Language Processing and Reading Disability. Advances in Learning and
            Behavioral Disabilities, 1, 3-24.
            <br />
            Das, J. P., Kirby, J. R., & Jarman, R. F. (1975). Simultaneous and Successive Syntheses: An Alternative
            Model for Cognitive Abilities. Psychological Bulletin, 82, 87-103.
            <br />
            Das, J. P., Kirby, J. R., & Jarman, R. F. (1979). Simultaneous and Successive Cognitive Processes. New York:
            Academic Press.
            <br />
            Das, J. P., Mishra, R. K., & Kirby, J. R. (1994). Cognitive Patterns of Children with Dyslexia: A Comparison
            between Groups with High and Average Nonverbal Intelligence. Journal of Learning Disabilities, 27, 235-242,
            253.
            <br />
            Das, J. P., Mishra, R. K., & Pool, J. (1995). An Experiment on Cognitive Remediation of Word-Reading
            Difficulty. Journal of Learning Disabilities, 28, 66-79.
            <br />
            Das, J. P., Naglieri, J. A., & Kirby, J. R. (1994). Assessment of Cognitve Processes: The PAS Theory of
            Intelligence. Boston: Allyn & Bacon.
            <br />
            Guilford, J. P., & Fruchter, B. (1978). Fundamental Statistics in Psychology and Education (6th ed.). New
            York: McGraw-Hill.
            <br />
            Harman, H. H. (1976). Modern Factor Analysis (3rd ed., rev). Chicago: University of Chicago Press.
            <br />
            Luria, A. R.(1970). The Functional Organization of the Brain. Scientific American, 222, 66-78.
            <br />
            Luria, A. R.(1973). The Working Brain: An Introduction to Neuropsychology. New York: Basic Books.
            <br />
            Luria, A. R.(1976). Cognitive Development: Its Cultural and Social Foundations. Cambridge, MA: Harvard
            University Press.
            <br />
            Luria, A. R.(1980). Higher Cortical Functions in Man (2nd ed). New York: Basic Books.
            <br />
            Luria, A. R.(1982). Language and Cognition. New York: Wiley.
            <br />
            Luria, A. R., & Tsvetkova, L. S. (A. Mikheyev, & S. Mikeyev, Trans). (1990). The Neuropsychological Analysis
            of Problem Solving. Orlando, FL: Paul M. Deutsch Press.
            <br />
            Moffitt, T. E. (1993). The Neuropsychology of Conduct Disorder. Development and Psychopathology, 5, 135-151.
            <br />
            Moffitt, T. E., & Lynam, D. (1994). The Neuropsychology of Conduct Disorder and Delinquency: Implications
            for Understanding Antisocial Behavior. In D. C. Fowles, P. Sutker, & S. H. Goodman. (Eds.).
            <br />
            Progress in Experimental Personality and Psychopathology Research (pp. 233-262). New York: Springer.
            <br />
            Naglieri, J. A. (1985). Matrix Analogies Test-Expanded Form. San Antonio: The psychological Corporation.
            <br />
            Naglieri, J. A. (1989a). A Cognitive Processing Theory for the Measurement of Intelligence. Educational
            Psychologist, 24, 185-206.
            <br />
            Naglieri, J. A. (1989b). Planning, Attention, Simultaneous, and Successive Cognitive Processes: A Summary of
            Recent Studies with Exceptional Samples. The Mental Retardation and Learning Disability Bulletin, 17, 3-22.
            <br />
            Naglieri, J. A. (1992). Two Roads Diverged in the Wood: Choosing ‘g’ or PASS Cognitive Processes. In J.
            Carlson. (Ed.). Cognition and Educational Practice: An international Perspective (pp. 111-126). Greenwich,
            CTL: JAI Press.
            <br />
            Naglieri, J. A. (1993). Pairwise and Ipsative Comparisons of WISC-Ⅲ IQ and Index Score. Psychological
            Assessment, 5, 113-116.
            <br />
            Naglieri, J. A. (1996a). Cognitive Assessment: Nontraditional Intelligence Tests. In T. Fagan & P. Warden
            (Eds.). Historical Encyclopedia of School Psychology (pp. 69-70). Westport, CT: Greenwood Press.
            <br />
            Naglieri, J. A. (1996b). Naglieri Nonverbal Ability Test. San Antonio: The Psychological Corporation.
            <br />
            Naglieri, J. A. (1997). Planning, Attention, Simultaneous, and Successive Theory and the Cognitive
            Assessment System: A New Theory-based Measure of Intelligence. In D. P. Flanagan, J. L. Genshaft, & P. L.
            Harrison (Eds), Contemporary Intellectual Assessment: Theories, Tests, and Issues (pp. 247-267). New York:
            The Guilford Press.
            <br />
            Naglieri, J. A., & Das, J. P. (1987). Construct and Criterion Related Validity of Planning, Simultaneous,
            and Successive Cognitive Processing Tasks. Journal of Psychoeducational Assessment, 5, 353-363.
            <br />
            Naglieri, J. A., & Das, J. P. (1988). Planning-arousal-simultaneous-successive(PASS): A Model for
            Assessment. Journal of School Psychology, 26, 35-48.
            <br />
            Naglieri, J. A., & Das, J. P. (1990). Planning, Attention, Simultaneous, and Successive(PASS) Cognitive
            Processes as a Model for Intelligence. Journal of Psychoeducational Assessment, 8, 303-337.
            <br />
            Naglieri, J. A., & Das, J. P. (1995). A Reply to Kranzler and Weng’s Shooting in the Dark. Journal of School
            Psychology, 33, 159-167.
            <br />
            Naglieri, J. A., & Gottling, S. H. (1995). A Study of Planning and Mathematics Instruction for Students with
            Learning Disabilities. Psychological Reports, 76, 1343-1354.
            <br />
            Naglieri, J. A., & Gottling, S. H. (in press). Mathematics Instruction and PASS Cognitive Processes: An
            Intervention Study. Journal of Learning Disabilities, 30, 513-520.
            <br />
            Naglieri, J. A., & Reardon, S. (1993). Traditional IQ Is Irrelevant to Learning Disabilities - Intelligence
            Is Not. Journal of Learning Disabilities, 26, 127-133.
            <br />
            Naglieri, J. A., Braden, J., & Gottling, S. (1993). Confirmatory Factor Analysis of the Planning, Attention,
            Simultaneous, Successive(PASS) Cognitive Processing Model for a Kindergarten Sample. Journal of
            Psychoeducational Assessment, 11, 259-269.
            <br />
            Naglieri, J. A., Prewett, P., & Bardos, A. N. (1989). An Exploratory Study of Planning, Attention,
            Simultaneous, and Successive Cognitive Processes. Journal of School Psychology, 27, 347-364.
            <br />
            Naglieri, J. A., Welch, J. A., & Braden, J. (1994). Performance of Hearing-impaired Students on Planning,
            Attention, Simultaneous, and Successive(PASS) Cognitive Processing Tasks. Journal of School Psychology, 32,
            371-384.
            <br />
            Nunnally, J. C., & Bernstein, I. H. (1994). Psychometric Theory. New York: McGraw-Hill.
            <br />
            Reitan, R. M. (1955). The Relation of the Trail Making Test to Organic Brain Damage. Journal of Consulting
            Psychology, 19, 393-394. Neuropsychological Evaluation of Older Children. South Tucson, AZ: Neuropsychology
            Press.
            <br />
            Salvia, J., & Ysseldyke, J. (1991). Assessment in Special and Remedial Education(2nd ed). Boston: Houghton
            Mifflin.
            <br />
            Savage, R. C., & Wolcott, G. F. (1994). Educational Dimensions of Acquired Brain Injury. Austin, TX: Pro-Ed.
            <br />
            Solso, R. L., & Hoffman, C. A. (1991). Influence of Soviet Scholars. American Psychologist, 46, 251-253.
            <br />
            Weyandt, L. L., & Willis, W. G. (1994). Executive Functions in School Aged Children: Potential Efficacy of
            Tasks in Discriminating Clinical Groups. Developmental Neuropsychology, 10, 27-38.
          </p>
        </div>
      </section>
      <div className={style.contentFooterWrapper}>
        <ArticleFooterKO />
      </div>
    </article>
  );
}
