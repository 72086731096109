import { LanguageContext } from "./LanguageContext";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Language } from "../../types/language";
import { useAuth, useModal } from "../hooks";

export function LanguageProvider({ children }: { children: JSX.Element }) {
  const { i18n } = useTranslation();
  const { isLoggedIn, signOut } = useAuth();
  const { openConfirmModal, closeModal } = useModal();

  const getInitialLanguage = useCallback(() => {
    const cookies = document.cookie.split(";");
    const langCookie = cookies.find((cookie) => cookie.includes("lang"));
    const browserLang = window.navigator.language.substring(0, 2);
    let lang = langCookie ? langCookie.split("=")[1] : browserLang;

    const languageMap: Record<string, Language> = {
      ko: Language.KO,
      "ko-KR": Language.KO,
      en: Language.EN,
      ru: Language.RU,
    };

    return languageMap[lang] || Language.EN;
  }, []);

  const [language, setLanguage] = useState<Language>(getInitialLanguage());
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);

  const changeLanguage = useCallback(
    (lang: Language) => {
      setLanguage(lang);
      i18n.changeLanguage(lang).then();
      document.cookie = `lang=${lang};path=/`;
    },
    [i18n]
  );

  const handleLanguageChange = useCallback(
    (lang: Language) => {
      if (isLoggedIn) {
        openConfirmModal(
          {
            title: "안내",
            desc: "외국어 변경 시 로그인정보가 사라집니다.\n로그아웃 후 언어를 변경할까요?",
          },
          () => {
            signOut(() => {
              changeLanguage(lang);
              closeModal();
            });
          }
        );
      } else {
        changeLanguage(lang);
      }
    },
    [isLoggedIn, openConfirmModal, signOut, changeLanguage, closeModal]
  );

  useEffect(() => {
    const lang = getInitialLanguage();
    changeLanguage(lang);
    setIsLanguageLoaded(true);
  }, [getInitialLanguage, changeLanguage]);

  const getLanguageStyle = useCallback((style: Record<string, string>): string => style[language] ?? "", [language]);

  const contextValue = {
    canLogin: language === Language.KO,
    isKorean: language === Language.KO,
    isNotKorean: [Language.RU, Language.EN].includes(language),
    language,
    setLang: handleLanguageChange,
    getLanguageStyle,
    isLanguageLoaded,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {isLanguageLoaded ? children : <div>Loading...</div>}
    </LanguageContext.Provider>
  );
}
