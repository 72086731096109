import React, { useCallback, useEffect, useState } from "react";
import { LoginInfo, LoginResponse } from "../../types/auth";
import jwt_decode from "jwt-decode";
import { MainApi } from "../../api/MainApi";
import { useAppDispatch } from "../hooks";
import { getAccessTokenFromRefreshTokenAsync, signInAsnyc } from "../../store/login/loginSlice";
import { AuthContext } from "./AuthContext";
import { ErrorType } from "../../types/error";
import { useCookie } from "./useCookie";
import { useNavigate } from "react-router-dom";
import { useUserInfoGetter } from "../../components/common/hooks/useUserInfoGetter";

export enum CookieKeys {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  NOT_SHOW_POPUP_TODAY = "notShowPopupToday",
}

function getExpiresFromToken(token: string) {
  const { exp } = jwt_decode(token) as { exp: number };
  return new Date(exp * 1000).toUTCString();
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { getValueFromCookie, setCookie } = useCookie();
  const navigate = useNavigate();
  const { getUserInfo } = useUserInfoGetter();

  const onLoggedIn = useCallback(
    (accessToken: string, refreshToken: string) => {
      setIsLoggedIn(true);

      const accessTokenExpires = getExpiresFromToken(accessToken);
      const refreshTokenExpires = getExpiresFromToken(refreshToken);

      MainApi.getInstance().setToken(accessToken);

      setCookie(CookieKeys.ACCESS_TOKEN, accessToken, accessTokenExpires);
      setCookie(CookieKeys.REFRESH_TOKEN, refreshToken, refreshTokenExpires);
    },
    [setCookie]
  );

  const signIn = useCallback(
    async (loginInfo: LoginInfo, successCallback: VoidFunction, errorCallback: (e: ErrorType) => void) => {
      try {
        const result: LoginResponse = await dispatch(signInAsnyc(loginInfo)).unwrap();
        onLoggedIn(result.accessToken, result.refreshToken);
        getUserInfo().then();

        successCallback();
      } catch (e) {
        console.log({ e });
        const errorMessage = (e as { message: string }).message;
        const error: ErrorType = JSON.parse(errorMessage);
        errorCallback(error);
      }
    },
    [dispatch, onLoggedIn]
  );

  const signOut = useCallback(
    (callback: VoidFunction) => {
      setCookie(CookieKeys.ACCESS_TOKEN, "", new Date().toUTCString());
      setCookie(CookieKeys.REFRESH_TOKEN, "", new Date().toUTCString());
      setIsLoggedIn(false);
      callback();
    },
    [setCookie]
  );

  const setAccessToken = useCallback(
    (accessToken: string) => {
      const accessTokenExpires = getExpiresFromToken(accessToken);
      MainApi.getInstance().setToken(accessToken);
      setCookie(CookieKeys.ACCESS_TOKEN, accessToken, accessTokenExpires);
      setIsLoggedIn(true);
    },
    [setCookie]
  );

  const setRefreshToken = useCallback(
    (token: string) => {
      const expires = getExpiresFromToken(token);
      setCookie(CookieKeys.REFRESH_TOKEN, token, expires);
    },
    [setCookie]
  );

  const getAccessTokenFromRefreshToken = useCallback(
    async (token: string) => {
      try {
        const { accessToken, refreshToken } = await dispatch(getAccessTokenFromRefreshTokenAsync(token)).unwrap();
        if (accessToken) {
          setAccessToken(accessToken);
        }
        if (refreshToken) {
          setRefreshToken(refreshToken);
        }
      } catch (e) {
        throw e;
      }
    },
    [dispatch, setAccessToken, setRefreshToken]
  );

  const getAccessTokenFromRefreshTokenCookie = useCallback(async () => {
    try {
      const savedRefreshToken = getValueFromCookie(CookieKeys.REFRESH_TOKEN);
      if (savedRefreshToken) {
        await getAccessTokenFromRefreshToken(savedRefreshToken);
      } else {
        signOut(() => {
          navigate(`/`);
        });
      }
    } catch (e) {
      throw e;
    }
  }, [signOut, getAccessTokenFromRefreshToken, getValueFromCookie, navigate]);

  const getUserInfoOnLogin = useCallback(
    (callbackOnLogin: () => void, callbackOnError: () => void) => {
      getUserInfo()
        .then(() => {
          callbackOnLogin();
        })
        .catch((e: any) => {
          console.error(e);
          const errorMessage = (e as { message: string }).message;
          const error: ErrorType = JSON.parse(errorMessage);

          if (error.errorCode.httpCode === 401) {
            getAccessTokenFromRefreshTokenCookie()
              .then(() => {
                getUserInfoOnLogin(callbackOnLogin, callbackOnError);
              })
              .catch(() => {
                signOut(() => {
                  navigate(-1);
                });
              });
          } else {
            callbackOnError();
          }
        });
    },
    [getAccessTokenFromRefreshTokenCookie, getUserInfo, navigate, signOut]
  );

  const loginOnLoad = useCallback(
    ({
      callbackOnLogout,
      callbackOnLogin,
      callbackOnError,
    }: {
      callbackOnLogout: () => void;
      callbackOnLogin: () => void;
      callbackOnError: () => void;
    }) => {
      const savedAccessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
      const savedRefreshToken = getValueFromCookie(CookieKeys.REFRESH_TOKEN);

      if (!savedAccessToken) {
        if (!savedRefreshToken) {
          callbackOnLogout();
        } else {
          getAccessTokenFromRefreshToken(savedRefreshToken).then(() => {
            callbackOnLogin();
          });
        }
      } else {
        setAccessToken(savedAccessToken);
        getUserInfoOnLogin(callbackOnLogin, callbackOnError);
      }
    },
    [getAccessTokenFromRefreshToken, getUserInfoOnLogin, getValueFromCookie, setAccessToken]
  );

  useEffect(() => {
    loginOnLoad({ callbackOnLogin: () => {}, callbackOnError: () => {}, callbackOnLogout: () => {} });
    //   const savedAccessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
    //   const savedRefreshToken = getValueFromCookie(CookieKeys.REFRESH_TOKEN);
    //
    //   if (!savedAccessToken) {
    //     if (savedRefreshToken) {
    //       getAccessTokenFromRefreshToken(savedRefreshToken).then();
    //     }
    //   } else {
    //     setAccessToken(savedAccessToken);
    //   }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isLoggedIn,
        onLoggedIn,
        setAccessToken,
        getAccessTokenFromRefreshToken,
        getAccessTokenFromRefreshTokenCookie,
        loginOnLoad,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
