import style from "../../../../assets/scss/common/modal/addChildModal.module.scss";
import downIcon from "../../../../assets/images/modal/down_btn_icon.svg";
import CheckedIcon from "../../../../assets/images/modal/check_enabled.svg";
import CheckIcon from "../../../../assets/images/modal/check_disabled.svg";
import React, {useEffect} from "react";
import {Address, CountryFilterValue, CountryName, InitialProfileInfoDTO} from "../../../../types/user";
import {useDaumPostcodePopup} from "react-daum-postcode";
import {ForeignAddressForm} from "./ForeignAddressForm";
import {KoreaAddressForm} from "./KoreaAddressForm";
import {countryCodeMap, getCountryFilterValue} from "../../../../util/commonFunctions";

interface Args {
    profileInfo: InitialProfileInfoDTO;
    setProfileInfo: React.Dispatch<React.SetStateAction<InitialProfileInfoDTO>>;
    parentAddress?: Address;
    selectedCountry: CountryFilterValue;
    setSelectedCountry: React.Dispatch<React.SetStateAction<CountryFilterValue>>;
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ChildAddressField(
    {
        profileInfo, setProfileInfo, parentAddress, selectedCountry, setSelectedCountry,
        isChecked, setIsChecked
    }: Args
) {
    const open = useDaumPostcodePopup();
    const onClickFindingAddress = () => {
        open({
            onComplete: handleAddressSelect,
            autoClose: true,
        }).then();
    };

    const handleAddressSelect = (data: any) => {
        const {sido, sigungu, address, zonecode} = data;

        // address1은 sido와 sigungu를 제거한 나머지 부분
        const addr1 = address.replace(sido, "").replace(sigungu, "").trim();

        setProfileInfo((prev) => ({
            ...prev,
            address1: addr1, // 기본 주소 (시도, 시군구 제외)
            address2: "", // 시도와 시군구를 address2에 저장
            city: sigungu, // 시군구
            state: sido, // 시도
            postalCode: zonecode, // 우편번호
        }));
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            // 체크박스가 선택되었을 때, 부모 주소로 자동 채우기
            const countryCode = getCountryFilterValue(parentAddress?.country ?? "");
            setSelectedCountry(countryCode);

            setProfileInfo((prev) => ({
                ...prev,
                address1: parentAddress?.address1 || "",
                address2: parentAddress?.address2 || "",
                city: parentAddress?.city || "",
                state: parentAddress?.state || "",
                postalCode: parentAddress?.postalCode || "",
                country:countryCode,
            }));
        } else {
            // 체크박스가 선택 해제되었을 때, 주소를 빈 값으로 초기화
            setProfileInfo((prev) => ({
                ...prev,
                address1: "",
                address2: "",
                city: "",
                state: "",
                postalCode: "",
                country: selectedCountry
            }));
        }
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCountry(event.target.value as CountryFilterValue);

        const countryName = event.target.value as CountryName;
        const countryCode = countryCodeMap[countryName];
        setProfileInfo((prev) => ({
            ...prev,
            address1: "",
            address2: "",
            city: "",
            state: "",
            postalCode: "",
            country: countryCode
        }));
    };
    useEffect(() => {

    }, [profileInfo]);


    return (
        <div className={style.infoContainer}>
              <span className={style.infoText}>
                <span className={style.optionalText}>[선택]</span> 국가 및 주소
              </span>
            <div className={style.selectContainer}>
                <select className={`${style.inputField} ${selectedCountry === CountryFilterValue.NONE && style.lightColor}`}
                        value={selectedCountry} onChange={handleCountryChange}>

                    {Object.values(CountryFilterValue).map((country) => (
                        <option key={country} value={country}>
                            {country}
                        </option>
                    ))}
                </select>
                <img src={downIcon} alt="down icon" className={style.downIcon}/>
            </div>
            {selectedCountry === CountryFilterValue.NONE ? (
                <></>
            ) : selectedCountry === CountryFilterValue.KR ? (
                <KoreaAddressForm
                    profileInfo={profileInfo} setProfileInfo={setProfileInfo}
                    onClickFindingAddress={onClickFindingAddress}
                />
            ) : (
                <ForeignAddressForm profileInfo={profileInfo} setProfileInfo={setProfileInfo}/>
            )}
            <div className={style.checkboxContainer} onClick={handleCheckboxChange}>
                <img
                    src={isChecked ? CheckedIcon : CheckIcon}
                    alt={isChecked ? "Checked" : "Unchecked"}
                    className={style.checkboxIcon}
                />
                <label htmlFor="sameAsCountry" className={style.sameAsCountry}>
                    내 국가, 주소와 동일합니다.
                </label>
            </div>
        </div>
    )
}