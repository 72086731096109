import {MainApi} from "./MainApi";
import {UserInfoUpdateDTO, UserLeavePostDTO} from "../types/user";

export class UserApi {
  static baseUrl = `${MainApi.urlPrefix}/user`;

  static getDetailInfo = () => () => MainApi.api.get(`${UserApi.baseUrl}/detail-info`);

  static modifyMember = (condition: UserInfoUpdateDTO) => () =>
      MainApi.api.put(`${UserApi.baseUrl}/detail-info`, condition)

  static leaveMember = (condition: UserLeavePostDTO) =>() =>
      MainApi.api.post(`${UserApi.baseUrl}/leave-request`,condition);
}
