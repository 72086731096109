export enum Language {
  KO = "ko",
  EN = "en",
  RU = "ru",
}

export namespace Language {
  export function toString(lang: Language): string {
    switch (lang) {
      case Language.KO:
        return "한국어";
      case Language.EN:
        return "English";
      case Language.RU:
        return "Русский";
    }
  }
}
