import style from "../../assets/scss/common/footer.module.scss";
import logoGray from "../../assets/images/logo/feelgoodLogoGray.svg";
import linkIcon from "../../assets/images/footer/link_icon.svg";
import kpassLogoSecondary from "../../assets/images/footer/logo_secondary_white.svg";
import bannerChild from "../../assets/images/footer/banner_child.png";
import bannerChildMobile from "../../assets/images/footer/banner_child_mobile.png";
import { Trans, useTranslation } from "react-i18next";
import { useFooter, useLanguage, usePage, useUserAgent } from "../../hooks/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import React, { useCallback } from "react";
import { NavigationButton } from "./button/NavigationButton";
import { Language } from "../../types/language";
import { QnA } from "../sub/QnA";

export function Footer() {
  const { showFooter } = useFooter();
  const { t } = useTranslation(["newFooter"]);
  const navigate = useNavigate();

  const { isDesktop, isTablet } = useUserAgent();
  const { isKorean, language, setLang } = useLanguage();
  const { isOrderPage, isDownloadTestPage, isWithAppPage, isMyPage } = usePage();

  const onClickDownloadButton = () => navigate(`/download-test`);
  const Space = useCallback(() => <>{isTablet ? <br /> : <> </>}</>, [isTablet]);


  // 임시 추가
  const location = useLocation().pathname;

  return (
    <footer className={`${style.footer} ${showFooter ? style.show : ""}`}>
      <h1 className="blind">{t("footerHeading")}</h1>
      {!isOrderPage && !isDownloadTestPage && !isMyPage && location !== "/withuser" && (
        <div className={style.bannerWrapper}>
          <div className={style.banner}>
            <div className={style.textArea}>
              <p className={style.text}>
                {t("banner.text1")}
                <br />
                <span className={style.point}>
                  <Trans
                    t={t}
                    i18nKey={"banner.text2"}
                    components={[<span className={style.strongPoint} />, <Space />]}
                  />
                </span>
              </p>
              <NavigationButton buttonText={t("banner.download")} onClickEvent={onClickDownloadButton} />
            </div>
            <img className={style.image} src={isDesktop ? bannerChild : bannerChildMobile} alt={t("banner.imageAlt")} />
          </div>
        </div>
      )}
      <QnA />
      <div className={style.aboutFeelgood}>
        <div className={style.content}>
          <div className={style.logo}>
            <img src={kpassLogoSecondary} alt={"K-PASS Logo"} />
            <span className={style.text}>{t("snsNotice")}</span>
          </div>
          <div className={style.sns}>
            <a
              className={`${style.snsLink} ${style.naver}`}
              href={"https://blog.naver.com/k-pass_official"}
              target="_blank"
              rel="noreferrer"
            >
              <span>K-PASS 네이버 블로그</span>
            </a>
            <a
              className={`${style.snsLink} ${style.insta}`}
              href={"https://www.instagram.com/k_pass.official/"}
              target="_blank"
              rel="noreferrer"
            >
              <span>K-PASS instagram</span>
            </a>
            <a
              className={`${style.snsLink} ${style.facebook}`}
              href={"https://www.facebook.com/KPASS.official/"}
              target="_blank"
              rel="noreferrer"
            >
              <span>K-PASS facebook</span>
            </a>
            <a
              className={`${style.snsLink} ${style.youtube}`}
              href={"https://www.youtube.com/@FeelGood_official"}
              target="_blank"
              rel="noreferrer"
            >
              <span>K-PASS youtube</span>
            </a>
          </div>
        </div>
      </div>
      <div className={style.contentWrapper}>
        <div className={style.companyInfoAndLang}>
          {isDesktop ? (
            <></>
          ) : (
            <div className={style.companyLogo}>
              <a href="https://www.feel-good.io/" target="_blank" rel="noreferrer">
                <img src={logoGray} alt={t("logoAlt")} />
              </a>
            </div>
          )}
          <p className={style.companyInfo}>
            {isDesktop && isKorean ? (
              <>
                <span className={style.name}>{t("companyName")}</span>
                <br />
                <span className={style.others}>
                  {t("ceoLabel")}&nbsp;&nbsp;|&nbsp;&nbsp;{t("ceoName")}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {t("companyRegistrationNumberLabel")}
                  &nbsp;&nbsp;|&nbsp;&nbsp;397-81-01770&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {t("businessLabel")}&nbsp;&nbsp;|&nbsp;&nbsp;{t("businessNumber")}
                  <br />
                  {t("inquiryLabel")}&nbsp;&nbsp;|&nbsp;&nbsp;MAIL : contact@feel-good.io
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {"전화"}&nbsp;&nbsp;|&nbsp;&nbsp;051-747-4050
                  <br />
                  {t("busanAddress")}
                  <br />
                  {t("gyeonggiAddress")}
                </span>
              </>
            ) : (
              <>
                <span className={style.name}>{t("companyName")}</span>
                {isDesktop ? <br /> : <></>}
                <span className={style.others}>
                  {t("ceoLabel")}&nbsp;&nbsp;|&nbsp;&nbsp;{t("ceoName")}
                  {isTablet ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> : <br />}
                  {t("companyRegistrationNumberLabel")}
                  &nbsp;&nbsp;|&nbsp;&nbsp;397-81-01770
                  {isTablet ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> : <br />}
                  {t("businessLabel")}&nbsp;&nbsp;|&nbsp;&nbsp;{t("businessNumber")}
                  <br />
                  {t("inquiryLabel")}&nbsp;&nbsp;|&nbsp;&nbsp;MAIL : contact@feel-good.io
                  <br />
                  {isKorean ? `전화  |  051-747-4050` : null}
                  {isKorean ? <br /> : null}
                  {t("busanAddress")}
                  <br />
                  {t("gyeonggiAddress")}
                </span>
                {!isDesktop && (
                  <a className={style.link} href="https://www.feel-good.io/" target="_blank" rel="noreferrer">
                    <img className={style.icon} src={linkIcon} alt={t("blankLinkIconAlt")} />
                    <span>{t("companyHomepageLink")}</span>
                  </a>
                )}
              </>
            )}
          </p>
          {!isWithAppPage && (
            <div className={style.lang}>
              <label className={style.label}>Language</label>
              <select
                className={style.select}
                value={language}
                onChange={(e) => {
                  setLang(e.target.value as Language);
                  window.scrollTo(0, 0);
                }}
              >
                <option value={Language.KO}>{Language.toString(Language.KO)}</option>
                <option value={Language.EN}>{Language.toString(Language.EN)}</option>
                <option value={Language.RU}>{Language.toString(Language.RU)}</option>
              </select>
            </div>
          )}
        </div>
        {isDesktop ? (
          <div className={style.logoWrapper}>
            <a className={style.companyLogo} href="https://www.feel-good.io/" target="_blank" rel="noreferrer">
              <img src={logoGray} alt={t("logoAlt")} />
            </a>
            <a className={style.link} href="https://www.feel-good.io/" target="_blank" rel="noreferrer">
              <img className={style.icon} src={linkIcon} alt={t("blankLinkIconAlt")} />
              <span>{t("companyHomepageLink")}</span>
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </footer>
  );
}
