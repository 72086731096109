import styled from "styled-components";
import { KpassReviewInputTitleStyle } from "../common/StyledComponents";
import { PictureSample } from "./PictureSample";
import { ReviewImages } from "./ReviewImages";
import { breakpoints } from "../../../../../../theme/theme";
import { useTranslation } from "react-i18next";

const ReviewImagesStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageDescriptionStyle = styled.p`
  margin-top: 14px;
  color: ${(props) => props.theme.color.blueLight};
  font-size: 16px;
  line-height: 150%;

  ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

export function Pictures() {
  const { t } = useTranslation(["reviewModal"]);

  return (
    <ReviewImagesStyle>
      <KpassReviewInputTitleStyle>{t("picturesTitle")}</KpassReviewInputTitleStyle>
      <PictureSample />
      <ReviewImages />
      <ImageDescriptionStyle>{t("picturesFileType")}</ImageDescriptionStyle>
    </ReviewImagesStyle>
  );
}
