import style from "./effects.module.scss";
import effectImage from "../../../../assets/images/main/article2/section2/effects/main_point1.gif";
import { StrongTextStyle } from "../../../common/StyledCommonComponents";
import { useUserAgent } from "../../../../hooks/hooks";
import { Trans, useTranslation } from "react-i18next";
import { IsDesktopBr } from "../../../common/IsDesktopBr";
import { useDragEvent } from "../../../../hooks/useDragEvent";

export interface EffectProps {
  moveNext?: () => void;
  movePrev?: () => void;
}
export function Effect1({ moveNext, movePrev }: EffectProps) {
  const { t } = useTranslation(["kpass"]);

  const { isMobile } = useUserAgent();
  const { handleDragStart, handleDragEnd } = useDragEvent({
    moveRight: () => moveNext?.(),
    moveLeft: () => movePrev?.(),
  });

  return (
    <div className={style.wrapper}>
      <div className={style.image} draggable={true} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <img src={effectImage} alt={"section2.slider1.effectImageAlt"} draggable={"false"} />
      </div>
      {!isMobile ? <></> : <div className={style.space}></div>}
      <div className={style.text}>
        <p className={style.title}>{t("section2.slider1.title")}</p>
        <p className={style.desc}>
          <Trans
            t={t}
            i18nKey={"section2.slider1.description"}
            components={[<IsDesktopBr></IsDesktopBr>, <StrongTextStyle></StrongTextStyle>]}
          />
        </p>
      </div>
    </div>
  );
}
