import { useCallback, useMemo } from "react";

interface Args {
  map: any;
}

export function useLatitudeLongitudeGetter({ map }: Args) {
  const geocoder: kakao.maps.services.Geocoder | null = useMemo(() => {
    if (map) {
      return new kakao.maps.services.Geocoder();
    } else {
      return null;
    }
  }, [map]);

  const getMapPositionFromAddress = useCallback(
    (addressLine1: string, setPosition: (lat: string, lng: string) => void) => {
      if (geocoder) {
        geocoder.addressSearch(addressLine1, (result, status) => {
          if (status === kakao.maps.services.Status.OK) {
            setPosition(result[0].y, result[0].x);
          }
        });
      }
    },
    [geocoder]
  );

  return {
    getMapPositionFromAddress,
  };
}
