import style from "./eventRegistererModal.module.scss";
import { RefObject } from "react";
import { getOnlyKoreanEnglishAndNumber } from "../../../../util/commonFunctions";

interface Props {
  name: string;
  setName: (_: string) => void;
  nameRef: RefObject<HTMLInputElement>;
  invalidMessage?: string;
}
export function EventRegistererNameField({ name, setName, nameRef, invalidMessage }: Props) {
  return (
    <div className={style.field}>
      <label className={style.label}>자녀의 이름 또는 별명</label>
      <input
        className={`${style.inputText} ${name ? style.inputted : ""}`}
        name={"name"}
        required={true}
        minLength={2}
        maxLength={50}
        type={"text"}
        placeholder={"이름 또는 별명을 입력해 주세요."}
        value={name}
        onChange={(e) => setName(getOnlyKoreanEnglishAndNumber(e.target.value))}
        ref={nameRef}
      />
      {invalidMessage && <span className={style.invalidMessage}>{invalidMessage}</span>}
    </div>
  );
}
