import style from "./whiteButton.module.scss";
import React from "react";
interface Props {
  text: string;
  imageSrc: string;
  onClickFunc: (e: React.MouseEvent) => void;
}
export function WhiteButton({ text, imageSrc, onClickFunc }: Props) {
  return (
    <button className={style.whiteButton} onClick={onClickFunc}>
      <span className={style.whiteButtonText}>{text}</span>
      <img className={style.whiteButtonImg} src={imageSrc} alt={"아이콘"} />
    </button>
  );
}
