import googlePlayBadge from "../../../../assets/images/modal/app_download/google_play_badge.png";
import styled from "styled-components/macro";

const Link = styled.a`
  width: 169px;
  height: 50px;

  > img {
    width: 100%;
  }
`;

export function GooglePlayStoreBadge() {
  return (
    <Link href={"https://play.google.com/store/apps/details?id=io.feel_good.kpasswith"} target={"_blank"}>
      <img src={googlePlayBadge} alt={"구글 플레이 뱃지"} />
    </Link>
  );
}
