import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../../util/sliceUtil";
import { EventApi } from "../../api/EventApi";
import { EventTesterPostParam } from "../../types/event";
import { RootState } from "../index";

export interface EventState extends SliceState {}

const initialState: EventState = {
  status: Status.IDLE,
};

export const postEventTesterAsync = createAsyncThunk("event/postEventTester", (testerParam: EventTesterPostParam) =>
  executePromise(EventApi.postTester(testerParam))
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, postEventTesterAsync);
  },
});

export const eventStatus = (state: RootState) => state.event.status;
export default eventSlice.reducer;
