import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SliceState, Status} from "../../types/common";
import {Country} from "../../types/country";
import {RootState} from "../index";
import {CountryApi} from "../../api/CountryApi";

export interface CountryState extends SliceState {
  countries?: Country[];
}
const initialState: CountryState = {
  status: Status.IDLE,
};

export const getCountriesAsync = createAsyncThunk("country/getCountries", async () => await CountryApi.getCountries());

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export default countrySlice.reducer;

export const { setCountries } = countrySlice.actions;

export const countryList = (state: RootState) => state.country.countries;
