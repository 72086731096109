import { AddedFile } from "./file";

export enum PopupType {
  IMAGE = "IMAGE",
}

export enum PopupLinkOption {
  NEW_TAB = "NEW_TAB",
  CURRENT_TAB = "CURRENT_TAB",
}

export enum PopupStatus {
  UNEXPOSED = "UNEXPOSED",
  EXPOSED = "EXPOSED",
}

export enum WebsiteType {
  DCAS = "DCAS",
  KPASS = "KPASS",
}

export interface PopUpDTO {
  idx: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  availableStartAt: string;
  availableEndAt: string;
  type: PopupType;
  image: AddedFile;
  imageLink?: string;
  linkOption: PopupLinkOption;
  status: PopupStatus;
  websiteType: WebsiteType;
}

export interface PopupVO {
  idx: number;
  image: AddedFile;
  imageLink?: string;
  linkOption: PopupLinkOption;
  websiteType?: WebsiteType;
}
