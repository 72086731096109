import React, { useCallback, useEffect, useState } from "react";

interface Args {
  moveRight: (_: number) => void;
  moveLeft: (_: number) => void;
}
export function useDragEvent({ moveRight, moveLeft }: Args) {
  const [startX, setStartX] = useState(0);
  const [clonedElement, setClonedElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (clonedElement) {
      document.body.appendChild(clonedElement);

      return () => {
        document.body.removeChild(clonedElement);
      };
    }
  }, [clonedElement]);

  const handleDragStart = useCallback(
    (e: React.DragEvent) => {
      const clonedNode = (e.target as HTMLElement).cloneNode(true) as HTMLElement;
      clonedNode.style.width = "1px";
      clonedNode.style.height = "1px";
      // document.body.appendChild(clonedNode);
      setClonedElement(clonedNode);
      e.dataTransfer.setDragImage(clonedNode, 0, 0);
      setStartX(e.clientX);
    },
    [setStartX]
  );

  const handleDragEnd = useCallback(
    (e: React.DragEvent) => {
      const diffOnX = e.clientX - startX;
      if (Math.abs(diffOnX) > 50) {
        switch (true) {
          case 0 < diffOnX:
            moveLeft(diffOnX);
            break;
          case diffOnX < 0:
            moveRight(diffOnX);
            break;
        }
      }

      setStartX(0);
    },
    [startX, moveRight, moveLeft, setStartX]
  );

  return {
    handleDragStart,
    handleDragEnd,
  };
}
