import style from "../../assets/scss/sub/qna.module.scss";
import kakaotalkIcon from "../../assets/images/sub/purchase_voucher/kakaotalk_icon.svg";
import { useLanguage } from "../../hooks/hooks";

export function QnA() {
  const { isKorean } = useLanguage();

  const handleCopy = () => {
    const email = "contact@feel-good.io";
    navigator.clipboard
      .writeText(email)
      .then(() => alert("이메일이 복사되었습니다!"))
      .catch((err) => console.error("복사 실패:", err));
  };

  return (
    <>
      {isKorean && (
        <div className={style.qnaArea}>
          <span className={style.anyQuestionsText}>문의사항이 있으신가요?</span>
          <div className={style.kakaoQnaBtn}>
            <img
              className={style.icon}
              src={"https://organ-manager-dev.feel-good.io/static/media/mail_icon.05cae2f967926a848716166a1783eaf9.svg"}
              alt={"카카오톡 아이콘"}
            />
            <span className={style.text}>contact@feel-good.io</span>
          </div>
          <span className={style.copyBtnContainer} onClick={handleCopy}>
            Copy
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M8.54297 9.15249V7.11404C8.54297 6.37885 9.14448 5.77734 9.87966 5.77734H16.6634C17.3985 5.77734 18.0001 6.37885 18.0001 7.11404V13.8977C18.0001 14.6329 17.3985 15.2344 16.6634 15.2344H15.2933"
                stroke="#76718E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.9602 9.15234H7.17654C6.4383 9.15234 5.83984 9.7508 5.83984 10.489V17.2727C5.83984 18.011 6.4383 18.6094 7.17654 18.6094H13.9602C14.6985 18.6094 15.2969 18.011 15.2969 17.2727V10.489C15.2969 9.7508 14.6985 9.15234 13.9602 9.15234Z"
                stroke="#76718E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      )}
    </>
  );
}
