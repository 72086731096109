import styles from "../../../../../assets/scss/common/modal/loginModal.module.scss";
import React, {useEffect, useState} from "react";
import {SignupBirthdayField} from "./component/SignupBirthdayField";
import {SignupGenderField} from "./component/SignupGenderField";
import {SignupAddressField} from "./component/SignupAddressField";
import {Address, Gender} from "../../../../../types/user";
import {SignupStatus} from "../../SignupModal";
import {useSignupValidator} from "./hooks/useSignupValidator";
import {useUserAgent} from "../../../../../hooks/hooks";
import {StyleRequestType} from "../../../../../types/common";
interface Props {
    loginStep: SignupStatus;
    changeSignupStep: (_: SignupStatus) => void;
    birthday: string;
    setBirthday: (_: string) => void;
    gender: Gender;
    setGender: (_: Gender) => void;
    address: Address;
    setAddress: (_: Address) => void;
}

export function SignupInfoStep2({
    loginStep,
    changeSignupStep,
    birthday,
    setBirthday,
    gender,
    setGender,
    address,
    setAddress,
}: Props){

    const [isHovered, setIsHovered] = useState(false);
    const [showNation, setShowNation] = useState(false);
    const {isDesktop} = useUserAgent();
    const styleRequestType = StyleRequestType.SIGN_UP

    const {
        validate, birthdayValidate, setBirthdayValidate,
        genderValidate, setGenderValidate,
    } = useSignupValidator({
        loginStep, birthday:birthday, gender: gender, address: address
    })
    const onClickNextStep = () =>{
        const result = validate();
        if (result) {
            changeSignupStep(SignupStatus.Sign_UP_INFO_03);
        }
    }

    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const nationField = document.getElementById('nationField');

        if (showNation && nationField && !nationField.contains(target)) {
            setShowNation(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, [showNation]);

    return (
        <>
            <div className={styles.inputArea}>

                <div className={styles.desc}>
                    <span className={styles.accent}>추가 정보 입력(2)</span>
                    <br/>회원가입을 위해 아래 정보를 입력해 주세요.
                </div>


                {/* 생년월일 */}
                <SignupBirthdayField birthday={birthday} setBirthday={setBirthday}
                                     birthdayValidate={birthdayValidate} setBirthdayValidate={setBirthdayValidate}
                                     styleRequestType={styleRequestType}
                />

                {/* 성별 */}
                <SignupGenderField gender={gender} setGender={setGender}
                                   genderValidate={genderValidate} setGenderValidate={setGenderValidate}
                                   styleRequestType={styleRequestType}
                />

                {/* 주소 */}
                <div id={"nationField"}>
                    <SignupAddressField address={address} setAddress={setAddress}
                                        showNation={showNation} setShowNation={setShowNation}
                                        styleRequestType={styleRequestType}
                    />
                </div>


                <div className={`${styles.buttonsWrapper} ${isDesktop ? styles.withMarginTop : ''}`}>
                    <button type="button"
                            className={`${styles.signUpNextButton} ${isHovered ? styles.signUpNextHoverButton : ''}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={onClickNextStep}
                    >
                        다음
                    </button>
                </div>
            </div>
        </>
    )
}