import React, {useEffect} from "react";
import style from "../../../assets/scss/common/modal/addConsultationModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import {ConsultationItem} from "../../../types/consultation";
import {useConsultationGetter} from "../../sub/consultation/hooks/useConsultationGetter";
import {useConsultationFunctions} from "../../sub/consultation/hooks/useConsultationFunctions";

interface AddChildModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedConsultation: ConsultationItem | null;
    profileName: string | null;
}

const ConfirmConsultantModal: React.FC<AddChildModalProps> = (
    {isOpen, onClose, selectedConsultation, profileName,}) => {

    const {getConsultationInfo, consultationData} = useConsultationGetter();
    const {getConsultationStatus} = useConsultationFunctions();
    useEffect(() => {
        if (isOpen && selectedConsultation?.counselingIdx !== undefined) {
            getConsultationInfo(selectedConsultation.counselingIdx).then();
        }
        // eslint-disable-next-line
    }, [isOpen, getConsultationInfo]);

    // 조건부 렌더링
    if (!isOpen) {
        return null;
    }

    return (
        <div className={style.addChildModalOverlay}>
            <div className={style.addChildInfoModal}>
                <div className={style.closeBtnContainer}>
                    <img
                        src={CloseBtn}
                        alt="closeBtn"
                        className={style.closeBtn}
                        onClick={() => onClose()}
                    />
                </div>
                <div className={style.innerModalContainer}>
                    <h3 className={style.addChildInfoTitle}>상담 상세보기</h3>
                    <div className={style.addChildInfoDesc}>
                        <span className={style.boldDesc}>상담 정보를 확인해 주세요.</span>
                    </div>
                    <div className={style.innerTextContainer}>
                        <div className={style.infoContainer}>
                            <span className={style.infoTitleText}> 프로필(자녀) 정보 확인</span>
                            <span className={style.infoText}>이름</span>
                            <input
                                placeholder={profileName ? profileName : ""}
                                className={style.inputField}
                                value={profileName ? profileName : ""}
                                disabled
                            />
                            <span className={style.infoText}>검사종류</span>
                            <input
                                placeholder="K-PASS 1회차"
                                className={style.inputField}
                                value={`${selectedConsultation?.unitVoucherType === "KPASS" ? "K-PASS" : "D-CAS"} ${
                                    selectedConsultation?.testRound
                                }회차`}
                            />
                        </div>
                    </div>
                    <div className={style.innerTextContainer}>
                        <div className={style.infoContainer}>
                            <span className={style.infoTitleText}> 상담 정보 정보 확인</span>
                            <div className={style.consultInputContainer}>
                                <span className={style.consultInputTitle}>상담 희망 날짜</span>
                                <div className={style.customDateInput}>{selectedConsultation?.desiredDate}</div>
                            </div>
                            <div className={style.consultInputContainer}>
                                <span className={style.consultInputTitle}>상담 희망 시간</span>
                                <div
                                    className={style.customDateInput}>{selectedConsultation?.desiredTime?.slice(0, 5)}</div>
                            </div>
                        </div>
                    </div>
                    <div className={style.innerTextContainer}>
                        <div className={style.infoContainer}>
                            <span className={style.infoTitleText}> 사전문의 내용 작성</span>
                            <div className={style.previewContent}>{consultationData?.preQuestioning}</div>
                        </div>
                    </div>
                    <div className={style.innerTextContainer}>
                        <div className={style.infoContainer}>
                            <span className={style.infoTitleText}>상담신청 상태</span>
                            <div className={style.marginDiv}>
                                {getConsultationStatus(consultationData?.status)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmConsultantModal;
