import {RefObject, useCallback, useState} from "react";
import {Address, Gender} from "../../../../../../types/user";
import useValidateBirthday from "./useValidateBirthday";
export interface FieldValue<T, U> {
    value: T;
    ref: RefObject<U>;
}
export enum ValidateStatus {
    UNFILLED = "UNFILLED", // 작성이나 체크 되지 않은 상태
    UNFILLED_DETAIL = "UNFILLED_DETAIL", // 상세 정보가 작성이나 체크 되지 않은 상태
    BELOW_REQUIRED_LENGTH = "BELOW_REQUIRED_LENGTH", // 길이가 부족한 상태
    OVER_REQUIRED_LENGTH = "OVER_REQUIRED_LENGTH", // 길이를 초과한 상태
    BELOW_REQUIRED_LENGTH_FOREIGN = "BELOW_REQUIRED_LENGTH_FOREIGN", // 해외 기준 길이가 부족한 상태
    LANGUAGE_MISMATCH = "LANGUAGE_MISMATCH", // 다른 언어로 작성한 상태
    UNFILLED_FOREIGN = "UNFILLED_FOREIGN", // 해외 city, address 정보 미작성 상태
    INVALID_BIRTHDATE = "INVALID_BIRTHDATE", // 유효하지 않은 생년월일 상태
    NONE = "NONE",
}

interface Args {
    phoneCode: string;
    address: Address;
    nationField: FieldValue<string, HTMLInputElement>;
    nameField: FieldValue<string, HTMLInputElement>;
    phoneField: FieldValue<string, HTMLInputElement>;
    birthdayField: FieldValue<string, HTMLInputElement>;
    genderField: FieldValue<string, HTMLInputElement>;
    addressField: FieldValue<string, HTMLDivElement>;
    addressDetailField: FieldValue<string, HTMLInputElement>;
    foreignAddressDetailField: FieldValue<string, HTMLInputElement>;
}

export function useModifyMemberValidator({
    phoneCode,address,
    nationField, nameField, phoneField, birthdayField, genderField, addressField , addressDetailField, foreignAddressDetailField
                                         }: Args) {
    const [nationValidate, setNationValidate] = useState(ValidateStatus.NONE);
    const [nameValidate, setNameValidate] = useState(ValidateStatus.NONE);
    const [phoneCodeValidate, setPhoneCodeValidate] = useState(ValidateStatus.NONE);
    const [phoneNumberValidate, setPhoneNumberValidate] = useState(ValidateStatus.NONE);

    const [birthdayValidate, setBirthdayValidate] = useState(ValidateStatus.NONE);
    const [genderValidate, setGenderValidate] = useState(ValidateStatus.NONE);
    const [addressValidate, setAddressValidate] = useState(ValidateStatus.NONE);
    const [foreignAddressValidate, setForeignAddressValidate] = useState(ValidateStatus.NONE);
    const [foreignCityValidate, setForeignCityValidate] = useState(ValidateStatus.NONE);

    const [mandatoryAgreementValidate, setMandatoryAgreementValidate] = useState(ValidateStatus.NONE);
    // const regex = /^[a-zA-Z가-힣]*[aeiouAEIOUㄱ-ㅎㅏ-ㅣ]*$/;
    const regex = /^[a-zA-Z가-힣]+$/;

    const {validateBirthday} = useValidateBirthday();

    const focusAndScrollToField = (fieldRef: any) => {
        if (fieldRef.ref.current ) {
            fieldRef.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setTimeout(() =>{
                fieldRef.ref.current.focus();
            },500)
        }
    };

    const validate = useCallback(() => {
        let status = true;
        let isFocused = false;

        // 포커싱 함수
        const focusIfNeeded = (fieldRef: any) => {
            if (!isFocused) {
                focusAndScrollToField(fieldRef);
                isFocused = true;
            }
        };

        // 국가 미선택
        if (!nationField.value) {
            setNationValidate(ValidateStatus.UNFILLED)
            status = false;
        }

        // 이름 미작성
        if (!nameField.value) {
            setNameValidate(ValidateStatus.UNFILLED)
            focusIfNeeded(nationField)
            status = false;
        }

        // 이름에 한글 or 영어 아닌경우
        if (nameField.value && !regex.test(nameField.value)) {
            setNameValidate(ValidateStatus.LANGUAGE_MISMATCH)
            focusIfNeeded(nationField)
            status = false;
        }

        // 휴대폰 국가 미선택
        if (!phoneCode) {
            setPhoneCodeValidate(ValidateStatus.UNFILLED);
            status = false;
        }

        // 휴대폰 번호 미작성
        if (!phoneField.value && phoneCode) {
            setPhoneNumberValidate(ValidateStatus.UNFILLED);
            focusIfNeeded(phoneField)
            status = false;
        }

        // 휴대폰 번호 11자리 미만
        if (phoneField.value && phoneField.value.length < 11 && phoneCode && phoneCode === '+82') {
            setPhoneNumberValidate(ValidateStatus.BELOW_REQUIRED_LENGTH);
            focusIfNeeded(phoneField)
            status = false;
        }

        // 휴대폰 번호 11자리 초과
        if (phoneField.value && phoneField.value.length >= 12 && phoneCode && phoneCode === '+82') {
            setPhoneNumberValidate(ValidateStatus.OVER_REQUIRED_LENGTH);
            focusIfNeeded(phoneField)
            status = false;
        }

        // 국제 휴대폰 번호 5~15자리
        if (phoneField.value && (phoneField.value.length > 15 || phoneField.value.length < 5) && phoneCode !== '+82') {
            setPhoneNumberValidate(ValidateStatus.BELOW_REQUIRED_LENGTH_FOREIGN);
            focusIfNeeded(phoneField)
            status = false;
        }
        // 생년월일 미작성
        if (!birthdayField.value) {
            setBirthdayValidate(ValidateStatus.UNFILLED)
            focusIfNeeded(birthdayField)
            status = false;
        }

        // 생년월일 8자리 미만
        if (birthdayField.value && birthdayField.value.length < 8) {
            setBirthdayValidate(ValidateStatus.BELOW_REQUIRED_LENGTH)
            focusIfNeeded(birthdayField)
            status = false;
        }

        if (birthdayField.value && birthdayField.value.length >= 8) {
            const result = validateBirthday(birthdayField.value);
            if (!result) {
                setBirthdayValidate(ValidateStatus.INVALID_BIRTHDATE)
                focusIfNeeded(birthdayField)
                status = false;
            }

        }

        // 성별 미선택
        if (genderField.value === Gender.NONE) {
            setGenderValidate(ValidateStatus.UNFILLED);
            status = false;
        }

        return status;


        // eslint-disable-next-line
    }, [
        phoneCode,address,
        nationField, nameField, phoneField, birthdayField, genderField, addressField , addressDetailField
    ]);

    return {
        validate,
        nationValidate, setNationValidate,
        nameValidate, setNameValidate,
        phoneCodeValidate, setPhoneCodeValidate,
        phoneNumberValidate, setPhoneNumberValidate,
        birthdayValidate, setBirthdayValidate,
        genderValidate, setGenderValidate,
        addressValidate, setAddressValidate,
        foreignAddressValidate, setForeignAddressValidate,
        foreignCityValidate, setForeignCityValidate,
        mandatoryAgreementValidate, setMandatoryAgreementValidate
    }
}