import style from "./eventRegistererModal.module.scss";
import { RefObject } from "react";

interface Props {
  agreePersonalInfoUsage: boolean;
  setAgreePersonalInfoUsage: (_: boolean) => void;
  agreementRef: RefObject<HTMLDivElement>;
  invalidMessage?: string;
}
export function EventRegistererAgreementField({
  agreePersonalInfoUsage,
  setAgreePersonalInfoUsage,
  agreementRef,
  invalidMessage,
}: Props) {
  return (
    <div className={style.field} ref={agreementRef}>
      <label className={style.label}>개인정보 수집 및 이용 동의</label>
      <label className={`${style.checkboxLabel} ${agreePersonalInfoUsage ? style.checked : ""}`}>
        <input
          type={"checkbox"}
          checked={agreePersonalInfoUsage}
          onClick={() => setAgreePersonalInfoUsage(!agreePersonalInfoUsage)}
          readOnly={true}
        />
        개인정보 수집 및 이용 동의
      </label>
      {invalidMessage && <span className={style.invalidMessage}>{invalidMessage}</span>}
    </div>
  );
}
