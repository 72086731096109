import { useAppDispatch } from "../../../hooks/hooks";
import { useCallback } from "react";
import { getPartnerCompaniesAsync } from "../../../store/partner_company/partnerCompanySlice";
import { PageResponse } from "../../../types/page";
import { PartnerCompanyDTO, PartnerCompanySearchCondition, PartnerCompanyVO } from "../../../types/partnerCompany";
import { AffiliateCodeVO } from "../../../types/affiliateCode";

export function usePartnerCompaniesGetter() {
  const dispatch = useAppDispatch();

  const getPartnerCompanies = useCallback(
    async (condition: PartnerCompanySearchCondition): Promise<PartnerCompanyVO[]> => {
      try {
        const { content }: PageResponse<PartnerCompanyDTO> = await dispatch(
          getPartnerCompaniesAsync(condition)
        ).unwrap();

        return content.map((dto: PartnerCompanyDTO) => {
          const codeDTO = dto.affiliateCodeDTO;
          const codeVO: AffiliateCodeVO | null = codeDTO
            ? {
                idx: codeDTO.idx,
                code: codeDTO.code,
                status: codeDTO.status,
                availableStartAt: codeDTO.availableStartAt,
                availableEndAt: codeDTO.availableEndAt,
                discountType: codeDTO.discountType,
                discountValue: codeDTO.discountValue,
              }
            : null;

          return {
            idx: dto.idx,
            numericOrder: dto.numericOrder,
            name: dto.name,
            type: dto.type,
            logo: dto.logo.uri,
            address: dto.address,
            webSite: dto.webSite,
            codeVO: codeVO,
          } as PartnerCompanyVO;
        });
      } catch (e) {
        console.error(e);
        throw new Error("협력사 목록 조회 에러");
      }
    },
    [dispatch]
  );

  return {
    getPartnerCompanies,
  };
}
