import styled from "styled-components";
import { breakpoints } from "../../../../../theme/theme";
import { useUserAgent } from "../../../../../hooks/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetNextReviewGetter } from "../hooks/useGetNextReviewGetter";
import { useGetPrevReviewGetter } from "../hooks/useGetPrevReviewGetter";

const BottomButtonSectionStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;

  ${breakpoints.mobile} {
    gap: 15px;
  }
`;

const BottomButton = styled.button`
  border: 2px solid;
  padding: 20px 80px;
  border-radius: 100px;

  font-weight: bold;
  font-size: 20px;
  line-height: 150%;

  transition: all 0.5s ease-in-out;

  :hover {
    background-color: ${(props) => props.theme.color.mainColor};
    color: ${(props) => props.theme.color.white};
  }

  ${breakpoints.mobile} {
    font-size: 16px;
    padding: 16px 30px;

    :hover {
      background-color: white;
      color: ${(props) => props.theme.color.mainColor};
    }
  }
`;

const PreviousButton = styled(BottomButton)`
  border-color: ${(props) => props.theme.color.itemBgGray};
  color: ${(props) => props.theme.color.navyMedium};
`;

const NextButton = styled(BottomButton)`
  border-color: ${(props) => props.theme.color.blueLight};
  color: ${(props) => props.theme.color.kpBlue};
`;

interface Props {
  reviewIdx: number;
  isBestReview: boolean;
  reload: (reviewIdx: number) => void;
}

export function BottomButtonSection({ reviewIdx, isBestReview, reload }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const { isDesktop } = useUserAgent();
  const getPrevReview = useGetPrevReviewGetter();
  const getNextReview = useGetNextReviewGetter();

  const [prevReviewIdx, setPrevReviewIdx] = useState<number | undefined>();
  const [nextReviewIdx, setNextReviewIdx] = useState<number | undefined>();

  const initialize = useCallback(async () => {
    try {
      const prevReviewIdx = await getPrevReview(reviewIdx, isBestReview);
      setPrevReviewIdx(prevReviewIdx);
    } catch (_) {}

    try {
      const nextReviewIdx = await getNextReview(reviewIdx, isBestReview);
      setNextReviewIdx(nextReviewIdx);
    } catch (_) {}
  }, [reviewIdx, getPrevReview, getNextReview, isBestReview]);

  useEffect(() => {
    initialize().then();
  }, [initialize]);

  return (
    <BottomButtonSectionStyle>
      {prevReviewIdx && (
        <PreviousButton onClick={() => reload(prevReviewIdx)}>
          {isDesktop ? t("detailModalPrevButton") : t("detailModalPrevButtonMobile")}
        </PreviousButton>
      )}
      {nextReviewIdx && (
        <NextButton onClick={() => reload(nextReviewIdx)}>
          {isDesktop ? t("detailModalNextButton") : t("detailModalNextButtonMobile")}
        </NextButton>
      )}
    </BottomButtonSectionStyle>
  );
}
