import style from "./mainArticle3.module.scss";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { SubtitleNumbering } from "./SubtitleNumbering";
import { NavigationButton } from "../../common/button/NavigationButton";
import { useMemo, useRef, useState } from "react";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { useNavigate } from "react-router-dom";
import { useLanguage, useUserAgent } from "../../../hooks/hooks";
import { Trans, useTranslation } from "react-i18next";
import { IsDesktopBr } from "../../common/IsDesktopBr";
import {IsTabletBr} from "../../common/IsTabletBr";

export function Article3Section3() {
  const { t } = useTranslation(["competitiveness"]);
  const { isDesktop } = useUserAgent();
  const { isTablet } = useUserAgent();
  const navigate = useNavigate();
  const { getLanguageStyle } = useLanguage();
  const gridRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showGrid, setShowGrid] = useState(false);
  const [showButton, setShowButton] = useState(false);
  useShowOrHide({ refCurrent: gridRef.current, setShow: setShowGrid });
  useShowOrHide({ refCurrent: buttonRef.current, setShow: setShowButton });

  const showButtonArea = useMemo(
    () => (isDesktop && showGrid) || (!isDesktop && showButton),
    [isDesktop, showGrid, showButton]
  );
  const onClickReadMoreButton = () => navigate("/understanding/kpass");

  return (
    <section className={style.section3}>
      <div className={style.bgItemRightTop}></div>
      <div className={style.bgItemLeftBottom}></div>
      <div className={style.texts}>
        <div className={style.titleArea}>
          <SubtitleNumbering lightNumber={0} boldNumber={3} />
          <h2 className={`${style.title} ${getLanguageStyle(style)}`}>{t("section3.title")}</h2>
        </div>
        <p className={style.paragraphArea}>
          <Trans
            t={t}
            i18nKey="section3.description"
            components={isTablet ? [<IsTabletBr />, <StrongTextStyle></StrongTextStyle>] : [<IsDesktopBr />, <StrongTextStyle></StrongTextStyle>]}
          />
        </p>
      </div>
      <div className={style.aboutPass}>
        <ul className={`${style.passGrid} ${showGrid ? style.show : style.hide}`} ref={gridRef}>
          <li className={`${style.item} ${style.planning} ${showGrid ? style.show : style.hide}`}>
            <div className={style.mask}>
              <div className={style.maskDefault} />
              <div className={style.maskHover} />
            </div>
            <div className={style.content}>
              <span className={style.title}>{t("section3.aboutPass.item1.title")}</span>
              <p className={style.desc}>
                <StrongTextStyle className={style.small}>
                  {t("section3.aboutPass.item1.description.eng")}
                </StrongTextStyle>
                <span>{t("section3.aboutPass.item1.description.local")}</span>
              </p>
            </div>
          </li>
          <li className={`${style.item} ${style.attention} ${showGrid ? style.show : style.hide}`}>
            <div className={style.mask}>
              <div className={style.maskDefault} />
              <div className={style.maskHover} />
            </div>
            <div className={style.content}>
              <span className={style.title}>{t("section3.aboutPass.item2.title")}</span>
              <p className={style.desc}>
                <StrongTextStyle className={style.small}>
                  {t("section3.aboutPass.item2.description.eng")}
                </StrongTextStyle>
                <span>{t("section3.aboutPass.item2.description.local")}</span>
              </p>
            </div>
          </li>
          <li className={`${style.item} ${style.successiveProcessing} ${showGrid ? style.show : style.hide}`}>
            <div className={style.mask}>
              <div className={style.maskDefault} />
              <div className={style.maskHover} />
            </div>
            <div className={style.content}>
              <span className={style.title}>{t("section3.aboutPass.item3.title")}</span>
              <p className={style.desc}>
                <StrongTextStyle className={style.small}>
                  {t("section3.aboutPass.item3.description.eng")}
                </StrongTextStyle>
                <span>{t("section3.aboutPass.item3.description.local")}</span>
              </p>
            </div>
          </li>
          <li className={`${style.item} ${style.simultaneousProcessing} ${showGrid ? style.show : style.hide}`}>
            <div className={style.mask}>
              <div className={style.maskDefault} />
              <div className={style.maskHover} />
            </div>
            <div className={style.content}>
              <span className={style.title}>{t("section3.aboutPass.item4.title")}</span>
              <p className={style.desc}>
                <StrongTextStyle className={style.small}>
                  {t("section3.aboutPass.item4.description.eng")}
                </StrongTextStyle>
                <span>{t("section3.aboutPass.item4.description.local")}</span>
              </p>
            </div>
          </li>
        </ul>
        <div className={`${style.readMoreButton} ${showButtonArea ? style.show : style.hide}`} ref={buttonRef}>
          <NavigationButton buttonText={t("section3.showDetail")} onClickEvent={onClickReadMoreButton} />
        </div>
      </div>
    </section>
  );
}
