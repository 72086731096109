import modalStyle from "../../../assets/scss/common/modal/modal.module.scss";
import styles from "../../../assets/scss/common/modal/appDownloadModal.module.scss";
import qrCode from "../../../assets/images/modal/app_download/qr_code.png";
import { Modal } from "./Modal";
import { ModalType } from "../../../types/common";
import { useModal, useUserAgent } from "../../../hooks/hooks";
import { GooglePlayStoreBadge } from "./download_with_app/GooglePlayStoreBadge";
import { AppleAppStoreBadge } from "./download_with_app/AppleAppStoreBadge";

export function AppDownloadModal() {
  const { isDesktop, isMobile, isTablet } = useUserAgent();
  const { showModal, closeModal } = useModal();
  const onClickCloseBtn = () => closeModal();

  return (
    <Modal
      showModal={showModal === ModalType.APP_DOWNLOAD}
      title="K-PASS with 앱 다운로드"
      closeModalFunction={onClickCloseBtn}
      modalBgStyle={modalStyle.mainColorBg}
      modalCloseButtonStyle={modalStyle.mainColorBg}
      modalOtherStyle={`${modalStyle.appDownloadNormal} ${styles.wrapper}`}
    >
      <div className={styles.content}>
        <div className={styles.images}>
          <div className={styles.qrCode}>
            <img src={qrCode} alt={"QR 코드"} />
          </div>
          {(isMobile || isTablet) && (
            <div className={styles.badges}>
              <GooglePlayStoreBadge />
              <AppleAppStoreBadge />
            </div>
          )}
        </div>
        <div className={styles.textDesc}>
          <span className={styles.normal}>
            {isDesktop && (
              <>
                QR코드 스캔하고
                <br />
              </>
            )}
            K-PASS with 앱을 다운로드 받으세요!
          </span>
          <br />
          {(isMobile || isDesktop) && (
            <>
              <br />
            </>
          )}
          <span className={styles.accent}>
            K-PASS with 앱 회원가입 후
            {(isMobile || isDesktop) && (
              <>
                <br />
              </>
            )}{" "}
            검사이용권을 구매하실 수 있습니다.
          </span>
          {isDesktop && (
            <div className={styles.badges}>
              <GooglePlayStoreBadge />
              <AppleAppStoreBadge />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
