import { useUserAgent } from "../../hooks/hooks";

interface Props {
  isDesktopBr?: boolean;
}

export function IsDesktopBr({ isDesktopBr = true }: Props) {
  const { isDesktop } = useUserAgent();
  if (isDesktop && isDesktopBr) return <br />;
  if (!isDesktop && !isDesktopBr) return <br />;
  return <></>;
}
