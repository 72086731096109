import styled from "styled-components";
import { CheckboxStyle, ErrorMessageStyle, KpassReviewInputTitleStyle } from "../common/StyledComponents";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../../../../../hooks/hooks";
import { agreementState, setAgreement, validationErrorsState } from "../../../../../../store/review/reviewModalSlice";
import { useDispatch } from "react-redux";
import { ReviewValidationError } from "../../hooks/useReviewValidator";
import { breakpoints } from "../../../../../../theme/theme";
import { useTranslation } from "react-i18next";

const AgreementContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const AgreementBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;

  width: 100%;
  padding: 16px 25px;
  background-color: ${(props) => props.theme.color.deepMainColor};
  border-radius: 20px;
  cursor: pointer;

  &.checked {
    background-color: ${(props) => props.theme.color.kpDeepBlue};
  }

  ${breakpoints.mobile} {
    align-items: start;
    padding: 16px 16px;
    border-radius: 10px;
  }
`;

const AgreementBoxTextStyle = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${(props) => props.theme.color.kpBlueLight};
  font-size: 20px;
  line-height: 150%;

  span {
    margin-left: 10px;
  }

  a {
    color: ${(props) => props.theme.color.kpBlueLight};
    margin-left: 20px;
    font-size: 16px;
    line-height: 150%;
    font-weight: bold;
    text-decoration: underline;
  }

  &.checked {
    color: ${(props) => props.theme.color.white};

    a {
      color: ${(props) => props.theme.color.white};
    }
  }
  
  ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.24px;

    span b {
      font-weight: 700px;
    }
      
    span, a {
      margin-left: 0;
      }
    }

  ${breakpoints.mobile} {
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 6px;

    font-size: 16px;

    span b {
      font-weight: normal;
    }

    span,
    a {
      margin-left: 0;
    }
  }
`;

export function Agreement() {
  const { t } = useTranslation(["reviewModal"]);
  const dispatch = useDispatch();
  const agreement = useAppSelector(agreementState);
  const onClickAgreement = useCallback(() => dispatch(setAgreement(!agreement)), [agreement, dispatch]);
  const validationErrors = useAppSelector(validationErrorsState);
  const hasError = useMemo(
    () => validationErrors.some((it) => it === ReviewValidationError.AGREEMENT),
    [validationErrors]
  );

  return (
    <AgreementContainerStyle>
      <KpassReviewInputTitleStyle>{t("agreementTitle")}</KpassReviewInputTitleStyle>

      <AgreementBoxStyle onClick={onClickAgreement} className={`${agreement ? "checked" : ""}`}>
        <CheckboxStyle className={`${agreement ? "checked" : ""}`} />

        <AgreementBoxTextStyle className={`${agreement ? "checked" : ""}`}>
          <span>
            <b>[{t("required")}]</b> {t("agreementMessage")}
          </span>
          <a
            href="https://harmless-tune-3e0.notion.site/8867e10ff411429593f0ed7f7e914a1a"
            rel="noreferrer"
            onClick={(event) => {
              event.stopPropagation();
              return true;
            }}
            target="_blank"
          >
            {t("showDetail")}
          </a>
        </AgreementBoxTextStyle>
      </AgreementBoxStyle>

      {hasError && <ErrorMessageStyle>{t("agreementErrorMessage")}</ErrorMessageStyle>}
    </AgreementContainerStyle>
  );
}
