import styles from "../../../../../assets/scss/common/modal/signupModal.module.scss";
import {Timer} from "../Timer";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {getConfirmEmail} from "../../../../../store/singup/signupSlice";
import {ErrorType} from "../../../../../types/error";
import {useAppDispatch, useModal, useUserAgent} from "../../../../../hooks/hooks";
import {SignupStatus} from "../../SignupModal";
import {ModalType} from "../../../../../types/common";
import {getOnlyNumberText} from "../../../../../util/commonFunctions";

interface Props {
    email: string;
    changeSignupStep: (_: SignupStatus) => void;
    setTitle: (_: string) => void;
    setCode: (_: string) => void;
}

export function SignupStep({
   email,
   changeSignupStep,
   setTitle,
   setCode
}: Props){
    const LOGIN_BLOCK_SEC = 5 * 60;

    const codeFormRef = useRef<HTMLFormElement>(null);
    const [isHovered, setIsHovered] = useState(false);


    const [loginCode, setLoginCode] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [canLogin, setCanLogin] = useState(true);
    const dispatch = useAppDispatch();
    const { openModal } = useModal();
    // const onClickCloseBtn = () => closeModal();
    const {isMobile} = useUserAgent();

    useEffect(() => {
        setTitle("회원가입")
    });

    const onClickCancelBtn = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            setAlertMessage("");
            openModal(ModalType.LOGIN)
        },
        // eslint-disable-next-line
        [changeSignupStep]
    );

    const emailConfirm = useCallback(
        async (email: string, code : string) => {
            try {
                return await dispatch(getConfirmEmail({email, code})).unwrap();
            } catch (e) {
                console.error(e);
                const errorMessage = (e as { message: string }).message;
                const error: ErrorType = JSON.parse(errorMessage);
                console.log(errorMessage);
                console.log(error.errorCode.msg);
                const isLoginPossible = (error.data as { incorrect_count: string }).incorrect_count === '5'
                if (isLoginPossible) {
                    setAlertMessage("인증코드를 잘못 입력하셨습니다. (5/5회) \n아래 취소 버튼을 통해 다시 시도해 주세요.");
                    setCanLogin(false);
                    setTitle('로그인 & 회원가입');
                } else {
                    const incorrectCount = (error.data as { incorrect_count: string }).incorrect_count;
                    setAlertMessage(`인증코드를 잘못 입력하셨습니다. (${incorrectCount}/5회)`);
                }

                throw e;
            }
        },
        // eslint-disable-next-line
        [dispatch]
    );

    const onClickNextStep = useCallback(() => {
        if(loginCode !== '' &&  getOnlyNumberText(loginCode)){
            emailConfirm(email, loginCode).then((result) => {
                changeSignupStep(SignupStatus.Sign_UP_INFO_01);
                setCode(loginCode)
            }).catch((error) => {});
        } else if(!getOnlyNumberText(loginCode) && loginCode !== ''){
            setAlertMessage('잘못된 형식의 인증코드입니다. 다시 입력해 주세요!')
        }else if (loginCode === '') {
            setAlertMessage('인증코드를 입력해 주세요.')
        } else {
            setAlertMessage('다시 시도해 주세요.');
        }
        // eslint-disable-next-line
    }, [loginCode])


    const doAtTheEnd = useCallback(() => {
        setAlertMessage("입력시간이 초과된 코드입니다.\n아래 취소버튼을 통해 다시 시도해주세요.");
        setCanLogin(false);
    }, []);


    return(
        <div className={styles.authenticationContent}>
            <div className={styles.textDesc}>
                <div className={styles.desc}>
                    <span className={styles.accent}>{email}</span>
                    {isMobile ? (
                            <>
                                <br/>위 메일을 확인하여 인증코드를<br/>입력해주세요!
                            </>
                        ) :
                        <>
                            <br/>위 메일을 확인하여 인증코드를 입력해주세요!
                        </>
                    }
                </div>
                <form className={styles.emailForm} onSubmit={(e) => e.preventDefault()} ref={codeFormRef}>
                    <input
                        type="tel"
                        className={styles.input}
                        value={loginCode}
                        onInvalid={(e) => e.preventDefault()}
                        onChange={(e) => setLoginCode(e.target.value.toUpperCase())}
                        placeholder={"인증코드 6자리 입력"}
                    />
                    {canLogin ? (
                        <Timer initialMilliseconds={LOGIN_BLOCK_SEC * 1000} styleClassName={styles.timer}
                               doAtTheEnd={doAtTheEnd}
                        />
                    ) : (
                        <></>
                    )}
                    {alertMessage ? <span className={styles.invalidEmailAddr}>{alertMessage}</span> : <></>}
                    <div className={`${styles.buttonsWrapper} ${styles.withMarginTop}`}>
                        <button type="button" className={styles.outlineButton}
                                onClick={onClickCancelBtn}
                        >
                            취소
                        </button>
                        {canLogin ? (
                            <button type="button"
                                    className={`${styles.authenticationNextButton} ${isHovered ? styles.authenticationNextHoverButton : ''}`}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={onClickNextStep}
                            >
                                다음
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}