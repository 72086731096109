import style from "../../assets/scss/main_legacy/section1.module.scss";
import section1Bg from "../../assets/images/main_legacy/section1/section1Bg.jpg";
import kpassplay1Img from "../../assets/images/main_legacy/section1/kpassplay1.jpg";
import kpassplay2Img from "../../assets/images/main_legacy/section1/kpassplay2.jpg";
import kpassplay3Img from "../../assets/images/main_legacy/section1/kpassplay3.jpg";
import kpassplay4Img from "../../assets/images/main_legacy/section1/kpassplay4.jpg";
import { useContext, useEffect, useRef, useState } from "react";
import { MainLegacyScrollContext } from "./hooks/MainLegacyScrollContext";
import { ZoomImageModal } from "./ZoomImageModal";
import { ArticleTitle } from "../sub/ArticleTitle";
import { useTranslation } from "react-i18next";
import { ReadMoreBtn } from "./ReadMoreBtn";
import { GameImageSlider } from "./slider/GameImageSlider";
import { useLanguage, useUserAgent } from "../../hooks/hooks";

export function Section1({ menuName }) {
  const { isDesktop } = useUserAgent();
  const { t } = useTranslation(["mainSection1"]);
  const [isZoomModalShown, setIsZoomModalShown] = useState(false);
  const [modalImgSource, setModalImgSource] = useState("");
  const [modalImgAlt, setModalImgAlt] = useState("");
  const { isNotKorean } = useLanguage();

  const onClickGameImg = (imgSource, imgAlt) => {
    setModalImgSource(imgSource);
    setModalImgAlt(imgAlt);
    setIsZoomModalShown(true);
  };

  const [isShownPhrase1, setIsShownPhrase1] = useState(false);
  const [isShownPhrase2Desc, setIsShownPhrase2Desc] = useState(false);
  const [isShownPhrase3Text, setIsShownPhrase3Text] = useState(false);
  const { scrollY, selectedSection, setScrollingSection, showOrHide, isPassingHeaderBottom } =
    useContext(MainLegacyScrollContext);
  const phrase1Ref = useRef();
  const phrase2DescRef = useRef();
  const phrase3TextRef = useRef();
  const sectionRef = useRef();

  useEffect(() => {
    showOrHide(phrase1Ref.current, setIsShownPhrase1);
    showOrHide(phrase2DescRef.current, setIsShownPhrase2Desc);
    showOrHide(phrase3TextRef.current, setIsShownPhrase3Text);

    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (sectionRect && isPassingHeaderBottom(sectionRect.top, sectionRect.bottom)) {
      setScrollingSection(menuName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    if (menuName === selectedSection) {
      sectionRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionRef, selectedSection]);

  return (
    <section className={style.section1} ref={sectionRef}>
      <div className={style.bgItem2}></div>
      <article className={style.definition}>
        <div className={style.bgImage}>
          <img src={section1Bg} alt={t("bgItemImgAlt")} />
        </div>
        <h3>
          <ArticleTitle title={t("title")} className={style.title} />
        </h3>
        <div className={`${style.phrase1} ${isShownPhrase1 ? style.show : style.hide}`} ref={phrase1Ref}>
          <h4 className={style.text1}>{isDesktop ? t("kpass.summary") : t("kpass.summaryMobile")}</h4>
          <span className={style.text2}>{isDesktop ? t("kpass.descLine1") : t("kpass.descLine1Mobile")}</span>
        </div>
        <div className={style.phrase2}>
          <div className={`${style.desc} ${isShownPhrase2Desc ? style.show : style.hide}`} ref={phrase2DescRef}>
            <h4 className={style.title}>{t("analysisItems.heading")}</h4>
            <p className={style.description}>
              {isDesktop ? t("analysisItems.descLine1") : t("analysisItems.descLine1Mobile")}
            </p>
            <ReadMoreBtn link={"/understanding/kpass"} />
          </div>
          <ul className={style.descriptions}>
            <li className={style.descriptionP}>
              <div className={`${style.contentWrapper} ${isNotKorean ? style.ru : ""}`}>
                <div className={style.hoverBg}></div>
                <span className={style.subtitle}>P</span>
                <ul className={`${style.subtitleDesc} ${style.pa}`}>
                  <li className={style.enDesc}>{t("pass.planning.desc1")}</li>
                  <li>{t("pass.planning.desc2")}</li>
                </ul>
              </div>
            </li>
            <li className={style.descriptionA}>
              <div className={`${style.contentWrapper} ${isNotKorean ? style.ru : ""}`}>
                <div className={style.hoverBg}></div>
                <span className={style.subtitle}>A</span>
                <ul className={`${style.subtitleDesc} ${style.pa}`}>
                  <li className={style.enDesc}>{t("pass.attention.desc1")}</li>
                  <li>{t("pass.attention.desc2")}</li>
                </ul>
              </div>
            </li>
            <li className={style.descriptionS1}>
              <div className={`${style.contentWrapper} ${isNotKorean ? style.ru : ""}`}>
                <div className={style.hoverBg}></div>
                <span className={style.subtitle}>S</span>
                <ul className={style.subtitleDesc}>
                  <li className={style.enDesc}>{t("pass.sequential.desc1")}</li>
                  <li>{t("pass.sequential.desc2")}</li>
                </ul>
              </div>
            </li>
            <li className={style.descriptionS2}>
              <div className={`${style.contentWrapper} ${isNotKorean ? style.ru : ""}`}>
                <div className={style.hoverBg}></div>
                <span className={style.subtitle}>S</span>
                <ul className={style.subtitleDesc}>
                  <li className={style.enDesc}>{t("pass.simultaneous.desc1")}</li>
                  <li>{t("pass.simultaneous.desc2")}</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className={style.phrase3}>
          <div className={`${style.text} ${isShownPhrase3Text ? style.show : style.hide}`} ref={phrase3TextRef}>
            <h4 className={style.title}>{t("intelligenceTest.summary")}</h4>
            <span className={style.desc}>
              {isDesktop ? t("intelligenceTest.descLine1") : t("intelligenceTest.descLine1Mobile")}
            </span>
            <ReadMoreBtn link={"/purpose-and-characteristics"} />
          </div>
          {isDesktop ? (
            <div className={style.gameImages}>
              <div className={style.bgItemRightTop}></div>
              <div className={style.bgItemLeftBottom}></div>
              <div className={style.imgItem}>
                <button onClick={() => onClickGameImg(kpassplay1Img, t("gameImages.img1Alt"))}>
                  <img src={kpassplay1Img} alt={t("gameImages.img1Alt")} />
                </button>
              </div>
              <div className={style.imgItem}>
                <button onClick={() => onClickGameImg(kpassplay2Img, t("gameImages.img2Alt"))}>
                  <img src={kpassplay2Img} alt={t("gameImages.img2Alt")} />
                </button>
              </div>
              <div className={style.imgItem}>
                <button onClick={() => onClickGameImg(kpassplay3Img, t("gameImages.img3Alt"))}>
                  <img src={kpassplay3Img} alt={t("gameImages.img3Alt")} />
                </button>
              </div>
              <div className={style.imgItem}>
                <button onClick={() => onClickGameImg(kpassplay4Img, t("gameImages.img4Alt"))}>
                  <img src={kpassplay4Img} alt={t("gameImages.img4Alt")} />
                </button>
              </div>
            </div>
          ) : (
            <GameImageSlider />
          )}
        </div>
      </article>
      <ZoomImageModal
        closeModal={() => setIsZoomModalShown(false)}
        isModalShown={isZoomModalShown}
        imgSrc={modalImgSource}
        imgAlt={modalImgAlt}
      />
    </section>
  );
}
