import { ModalType } from "../../types/common";
import { useEffect, useMemo } from "react";
import { LoginModal } from "../../components/common/modal/LoginModal";
import { AppDownloadModal } from "../../components/common/modal/AppDownloadModal";
import { TermsModal } from "../../components/common/modal/TermsModal";
import { ReviewWriteModal } from "../../components/common/modal/review/ReviewWriteModal";
import { ReviewEditModal } from "../../components/common/modal/review/ReviewEditModal";
import { TermsCategory } from "../../types/terms";
import { useScrollingEvent } from "../hooks";
import { ReviewDetailModal } from "../../components/common/modal/review/ReviewDetailModal";
import { EventRegistererModal } from "../../components/common/modal/event_registerer/EventRegistererModal";
import { AffiliateCodeModal } from "../../components/common/modal/affiliate_code/AffiliateCodeModal";
import { AffiliateCodeVO } from "../../types/affiliateCode";
import {SignupModal} from "../../components/common/modal/SignupModal";
import {LeaveModal} from "../../components/common/modal/LeaveModal";

interface Props {
  modalType: ModalType;
  modalParam: {
    termsCategory: TermsCategory;
    reviewIdx?: number;
    isBestReview: boolean;
    affiliateCodeVO: AffiliateCodeVO | null;
  };
}
export function ModalRoutingComponent({
  modalType,
  modalParam: { termsCategory, reviewIdx, isBestReview, affiliateCodeVO },
}: Props) {
  const { restartScroll, stopScroll } = useScrollingEvent();
  const modalRouter = useMemo(
    () => ({
      [ModalType.LOGIN.toString()]: <LoginModal />,
      [ModalType.SIGN_UP.toString()]: <SignupModal />,
      [ModalType.LEAVE.toString()]: <LeaveModal />,
      [ModalType.APP_DOWNLOAD.toString()]: <AppDownloadModal />,
      [ModalType.TERMS.toString()]: <TermsModal category={termsCategory} />,
      [ModalType.REVIEW_WRITE.toString()]: <ReviewWriteModal />,
      [ModalType.REVIEW_EDIT.toString()]: <ReviewEditModal reviewIdx={reviewIdx} />,
      [ModalType.REVIEW_DETAIL.toString()]: <ReviewDetailModal reviewIdx={reviewIdx} isBestReview={isBestReview} />,
      [ModalType.EVENT_REGISTERER.toString()]: <EventRegistererModal />,
      [ModalType.AFFILIATE_CODE.toString()]: <AffiliateCodeModal codeVO={affiliateCodeVO} />,
    }),
    [termsCategory, reviewIdx, isBestReview, affiliateCodeVO]
  );

  useEffect(() => {
    if (modalType === ModalType.NONE) {
      restartScroll();
    } else {
      stopScroll();
    }
  }, [modalType, stopScroll, restartScroll]);

  return <>{modalRouter[modalType.toString()]}</>;
}
