import style from "../../assets/scss/sub/mainWrapper.module.scss";
import { useEffect, useState } from "react";
import { FloatingBtn } from "../common/button/FloatingBtn";
import { useFooter, useAwardBanner } from "../../hooks/hooks";

interface Props {
  showFooter: boolean;
  mainClassName: string;
  children: JSX.Element;
}

export function MainWrapper({ showFooter, mainClassName, children }: Props) {
  const { setShowFooter } = useFooter();
  const { hideAwardBanner } = useAwardBanner();

  useEffect(() => {
    setShowFooter(showFooter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [hideAwardBanner]);

  return (
    <main className={`${style.main} ${mainClassName} ${!hideAwardBanner ? style.bannerMain : ""}`}>
      <FloatingBtn />
      {children}
    </main>
  );
}
