import {SliderDirection, useSlider} from "../../../common/slider/useSlider";
import React, {useCallback, useEffect, useState} from "react";
import style from "../../../common/slider/slider.module.scss";
import effectSliderStyle from "./effectSlider.module.scss";
import {useUserAgent} from "../../../../hooks/hooks";

interface Props {
  slides: JSX.Element[];
  slideWidth: number;
  delay: number;
  shortcutButton: {
    texts: string[];
    defaultClassName: string;
    activeClassName: string;
    wrapperClassName: string;
  };
  slideWrapperClassName: string;
  showSlider: boolean;
}
export function EffectSlider({ shortcutButton, slides, slideWidth, delay, slideWrapperClassName, showSlider }: Props) {
  const { isMobile } = useUserAgent();
  const [effectCurrentSlideIdx, setEffectCurrentSlideIdx] = useState(0);
  const [moveNextAdditionalFunc, setMoveNextAdditionalFunc] = useState<() => void>(() => () => {});
  const [movePrevAdditionalFunc, setMovePrevAdditionalFunc] = useState<() => void>(() => () => {});
  const {
    moveSlideBySlideIdx,
    moveSlide,
    currentSlideIdx,
    transitionDelay,
    translateXValue,
    handleTouch,
    pauseAutoPlay,
    isTransitioning,
    getNextSlideIdx,
    getPrevSlideIdx,
  } = useSlider({
    slides,
    slideWidth,
    delay,
    blockSwipeOnTransitioning: true,
    additionalFunctions: {
      moveNext: moveNextAdditionalFunc,
      movePrev: movePrevAdditionalFunc,
    },
  });

  useEffect(() => {
    setEffectCurrentSlideIdx(currentSlideIdx);
  }, [currentSlideIdx, setEffectCurrentSlideIdx]);

  useEffect(() => {
    setMoveNextAdditionalFunc(() => () => {
      setEffectCurrentSlideIdx(getNextSlideIdx(currentSlideIdx));
    });
  }, [setMoveNextAdditionalFunc, setEffectCurrentSlideIdx, getNextSlideIdx, currentSlideIdx]);

  useEffect(() => {
    setMovePrevAdditionalFunc(() => () => {
      setEffectCurrentSlideIdx(getPrevSlideIdx(currentSlideIdx));
    });
  }, [setMovePrevAdditionalFunc, setEffectCurrentSlideIdx, getPrevSlideIdx, currentSlideIdx]);

  useEffect(() => {
    if (showSlider) {
      moveSlideBySlideIdx(0, false, false);
    }
  }, [showSlider, moveSlideBySlideIdx]);

  const onClickDirectionButton = useCallback(
    (direction: SliderDirection) => {
      if (!isTransitioning) {
        pauseAutoPlay();
        moveSlide(direction);
      }
    },
    [isTransitioning, pauseAutoPlay, moveSlide]
  );

  /* eslint-disable */
  const ASlideComponent = useCallback(
    ({ ASlide }: { ASlide: JSX.Element }) => {
      return (
        <div
          style={{
            width: `${slideWidth}px`,
          }}
        >
          <ASlide.type
            {...{
              movePrev: () => onClickDirectionButton(SliderDirection.PREV),
              moveNext: () => onClickDirectionButton(SliderDirection.NEXT),
            }}
          />
        </div>
      );
    },
    [slideWidth, onClickDirectionButton]
  );

  return (
    <>
      {!isMobile && (
        <div className={`${style.buttonWrapper} ${shortcutButton.wrapperClassName}`}>
          {shortcutButton.texts.map((buttonText, idx) => (
            <React.Fragment key={`effects-buttons-${idx}`}>
              <h3 className={"blind"}>{buttonText}</h3>
              <button
                className={`${shortcutButton.defaultClassName} ${
                  effectCurrentSlideIdx === idx ? shortcutButton.activeClassName : ""
                }`}
                onClick={() => {
                  if (!isTransitioning) {
                    pauseAutoPlay();
                    moveSlideBySlideIdx(idx, true, false);
                    setTimeout(() => {
                      moveSlideBySlideIdx(idx, false, false);
                    }, delay);
                  }
                }}
              >
                {buttonText}
              </button>
            </React.Fragment>
          ))}
        </div>
      )}
      <div
        style={{ width: "100%", height: "max-content" }}
        className={`${style.wrapper} ${effectSliderStyle.slideWrapper} ${slideWrapperClassName}`}
      >
        <button
          className={`${style.directionButton} ${style.prev} ${effectSliderStyle.prevButton}`}
          onClick={() => onClickDirectionButton(SliderDirection.PREV)}
          style={{ bottom: isMobile ? "-52px" : "" }}
        ></button>
        <div
          className={style.content}
          style={{
            width: `${slideWidth}px`,
            overflow: "hidden",
          }}
          onTouchStart={handleTouch.start}
          onTouchMove={handleTouch.move}
          onTouchEnd={handleTouch.end}
        >
          <div
            className={style.slides}
            style={{
              height: "max-content",
              alignItems: "flex-start",
              transform: `translateX(${translateXValue}px)`,
              transition: `all ${transitionDelay}ms`,
            }}
          >
            {slides.map((aSlide, idx) => (
              <ASlideComponent key={`effect-group0-${idx}`} ASlide={aSlide} />
            ))}
            {slides.map((aSlide, idx) => (
              <ASlideComponent key={`effect-group1-${idx}`} ASlide={aSlide} />
            ))}
            {slides.map((aSlide, idx) => (
              <ASlideComponent key={`effect-group2-${idx}`} ASlide={aSlide} />
            ))}
          </div>
        </div>
        <button
          className={`${style.directionButton} ${style.next} ${effectSliderStyle.nextButton}`}
          onClick={() => onClickDirectionButton(SliderDirection.NEXT)}
          style={{ bottom: isMobile ? "-52px" : ""}}
        ></button>
      </div>
    </>
  );
}
