import { useAppDispatch } from "../../../../../hooks/hooks";
import { useCallback } from "react";
import { getProfilesAsync } from "../../../../../store/profile/profileSlice";
import { getProfileReviewStatusesAsync } from "../../../../../store/review/reviewSlice";
import {
  setUserProfileWithReviewStatuses,
  setUserProfileWithReviewStatusForEdit,
} from "../../../../../store/review/reviewModalSlice";
import { useProfileGetter } from "../../../../../hooks/profile/useProfileGetter";

export function useProfileReviewGetter() {
  const dispatch = useAppDispatch();
  const { getProfile } = useProfileGetter();

  const getProfileReviews = useCallback(async () => {
    const profiles = await dispatch(getProfilesAsync()).unwrap();
    const profileWithReviewStatusesAsync = profiles.map(async (it) => {
      const statuses = await dispatch(getProfileReviewStatusesAsync(it.idx)).unwrap();

      return {
        profileIdx: it.idx,
        userProfile: it,
        reviewStatuses: statuses,
      };
    });
    const profileWithReviewStatuses = await Promise.all(profileWithReviewStatusesAsync);
    dispatch(setUserProfileWithReviewStatuses(profileWithReviewStatuses));
  }, [dispatch]);

  const getProfileReview = useCallback(
    async (profileIdx: number) => {
      const profile = await getProfile(profileIdx);
      const status = await dispatch(getProfileReviewStatusesAsync(profileIdx)).unwrap();
      dispatch(
        setUserProfileWithReviewStatusForEdit({
          profileIdx: profileIdx,
          userProfile: profile,
          reviewStatuses: status,
        })
      );
    },
    [getProfile, dispatch]
  );

  return {
    getProfileReview,
    getProfileReviews,
  };
}
