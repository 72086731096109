import { SearchCondition } from "./page";

interface CouponCount {
  count: number;
  result?: number;
  totalElements: number;
}

enum CouponType {
  TOTAL_AMOUNT_DISCOUNT = "TOTAL_AMOUNT_DISCOUNT",
  PRODUCT_DISCOUNT = "PRODUCT_DISCOUNT",
  GOODS = "GOODS",
}

enum CouponStatus {
  BEFORE_USE = "BEFORE_USE",
  USED = "USED",
  DELETED = "DELETED",
}

export enum CouponSummaryStatus {
  IS_ISSUING = "IS_ISSUING",
  STOP_ISSUING = "STOP_ISSUING",
  DELETED = "DELETED",
}

enum CouponHistoryStatus {
  BEFORE_USE = "BEFORE_USE",
  USED = "USED",
  DELETED = "DELETED",
}

enum AvailableDateType {
  DURATION = "DURATION",
  GRANTED_DATE = "GRANTED_DATE",
}

interface Coupon {
  idx: number;
  type: CouponType;
  name: string;
  status: CouponStatus;
  productIdx: number;
  availableDateType: AvailableDateType;
  availableStartAt: string;
  availableEndAt: string;
  availableDateAfterGranted: number;
  point: number;
  percent: number;
  availableAboveAmount: number;
}

export interface CouponSummaryDTO {
  idx: number;
  type: CouponType;
  status: CouponSummaryStatus;
  name: string;
  productIdx: number;
  availableDateType: AvailableDateType;
  availableStartAt: string;
  availableEndAt: string;
  availableDateAfterGranted: number;
  point: number;
  percent: number;
  availableAboveAmount: number;
}

interface CouponHistoryListDTO {
  idx: number;
  coupon: CouponSummaryDTO;
  status: CouponHistoryStatus;
  availableStartAt: string;
  availableEndAt: string;
}

interface CouponListSearchCondition extends SearchCondition {
  type?: CouponType;
  status: CouponHistoryStatus;
}

interface MyCoupon {
  idx: number;
  coupon: {
    idx: number;
    type: string;
    status: string;
    name: string;
    productIdx: number | null;
    availableDateType: string | null;
    availableStartAt: string | null;
    availableEndAt: string | null;
    availableDateAfterGranted: string | null;
    point: number | null;
    percent: number | null;
    availableAboveAmount: number | null;
  };
  status: string;
  availableStartAt: string;
  availableEndAt: string;
  orderIdx: number | null;
  ranking: number;
}

interface ApiResponse {
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
  numberOfElements: number;
  content: MyCoupon[];
  first: boolean;
  last: boolean;
  empty: boolean;
}

export type { CouponCount, CouponHistoryListDTO, CouponListSearchCondition, Coupon, MyCoupon, ApiResponse };
export { CouponType, CouponStatus, CouponHistoryStatus, AvailableDateType };
