import "./assets/scss/app.scss";
import { Outlet } from "react-router-dom";
import { Header } from "./components/common/Header";
import { Footer } from "./components/common/Footer";
import React, { useEffect } from "react";
import { CLICK_TARGET_NAME, publish } from "./util/events";
import { HooksWrapper } from "./hooks/HooksWrapper";
import {injectAnalytics} from "./util/commonFunctions";

function App() {
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    const clickEvent = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement) {
        publish("clickDocument", e.target.closest(`[data-name=${CLICK_TARGET_NAME}]`));
      }
    };

    window.addEventListener("click", clickEvent);
    injectAnalytics();

    return () => {
      window.removeEventListener("click", clickEvent);
    };
  }, []);

  return (
    <HooksWrapper>
      <div className="App">
        <>
          <Header />
          <Outlet />
          <Footer />
        </>
      </div>
    </HooksWrapper>
  );
}

export default App;
