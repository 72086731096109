import { ConfirmModalContent } from "../../../types/common";
import styles from "../../../assets/scss/common/modal/confirmAlertModal.module.scss";
import { ConfirmAlertModal } from "./ConfirmAlertModal";
import React, { useCallback } from "react";

interface Props {
  showConfirmModal: boolean;
  closeConfirmModal: (e: React.MouseEvent) => void;
  content: ConfirmModalContent;
}

export function ConfirmModal({ content, showConfirmModal, closeConfirmModal }: Props) {
  const Buttons = useCallback(
    () => (
      <>
        <button className={styles.cancelBtn} onClick={closeConfirmModal}>
          취소
        </button>
        <button
          className={styles.confirmBtn}
          onClick={(e) => {
            content.confirmFunc();
            closeConfirmModal(e);
          }}
        >
          확인
        </button>
      </>
    ),
    [content, closeConfirmModal]
  );

  return <ConfirmAlertModal showModal={showConfirmModal} content={content.content} buttons={<Buttons />} />;
}
