import style from "../koreanKpassStandardization.module.scss";
import { ArticleHeaderKO } from "./ArticleHeaderKO";
import { SubtitleNumbering } from "../../main/article3/SubtitleNumbering";
import table1 from "../../../assets/images/kpass_standardization/table1.svg";
import table2 from "../../../assets/images/kpass_standardization/table2.svg";
import React from "react";
import { ArticleFooterKO } from "./ArticleFooterKO";

export function StandardizationKO() {
  return (
    <article className={style.article}>
      <div className={style.contentHeaderWrapper}>
        <ArticleHeaderKO />
      </div>
      <section className={style.contentSection}>
        <div className={style.sectionTitleWrapperWideMarginBottom}>
          <SubtitleNumbering lightNumber={0} boldNumber={3} fontSize={"85px"} />
          <h2 className={style.articleTitle}>K-PASS의 표준화</h2>
        </div>
        <div className={style.part}>
          <h3 className={style.partTitle}>표준화 집단</h3>
          <div className={style.partSummary}>
            &nbsp;표준화를 위한 자료는 2023년 1월~2023년 6월에 걸쳐 유아원, 유치원, 초등학교에 재학 중인 2,700명의
            아동을 대상으로 전국적으로 수집되었다. 그리고, 수집된 자료는 2023년 7월~2023년 9월 걸쳐 분석한 다음 한국판
            K-PASS의 규준을 개발 완료하였다. 표준화를 위한 자료는 2022년 교육통계연감에 보고 된 아동의 연령, 성별,
            지역별 분포를 고려하여 각 연령별로 300명씩 표집 하여 전체 2700명으로 구성된 표준화 집단을 구성하였으며 인구
            비율에 따라 장애 아동 및 영재 아동도 각 연령별 표준화 집단에 포함시켰다.
            <br />
            &nbsp;한국판 K-PASS의 실시 대상 연령은 4세~12세이다. 따라서 표준화 집단은 4세~12세 11개월의 연령을 3개월
            단위로 나누어 모두 27개의 연령군으로 만든 다음 각 연령군별로 100명씩 표집하였다. 아래 [표 1]은 27개 연령군의
            표준화 집단을 9개 연령집단으로 요약하여 성별에 따라 정리하여 제시하고 있다. 그리고 [표 2]는 지역과 연령에
            따른 표준화 집단의 표집수를 정리하여 제시하고 있다.
            <div className={style.tableWrapper}>
              <img
                src={table1}
                className={style.table1}
                alt={"[표 1]  아동의 성별 및 연령에 따른 표준화 집단의 표집수"}
              />
              <img src={table2} className={style.table2} alt={"[표 2]  지역과 연령에 따른 표준화 집단의 표집수"} />
            </div>
          </div>
        </div>
      </section>
      <div className={style.contentFooterWrapper}>
        <ArticleFooterKO />
      </div>
    </article>
  );
}
