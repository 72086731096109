import {useAppDispatch} from "../../../hooks/hooks";
import {useCallback, useState} from "react";
import {getAllCouponCountAsync} from "../../../store/coupon/couponSlice";
import {getProfilesAsync} from "../../../store/profile/profileSlice";
import {UserProfile} from "../../../types/user";
import {getCounselingVoucherAsync} from "../../../store/consultation/consultationSlice";
import {VoucherCount} from "../../../types/voucher";
interface CouponApiResponse {
    totalPages: number;
    totalElements: number;
    number: number;
    size: number;
    numberOfElements: number;
    content: any[]; // 필요한 경우 정확한 타입으로 변경
    orders: {
        direction: string;
        property: string;
        ignoreCase: boolean;
        nullHandling: string;
        ascending: boolean;
        descending: boolean;
    }[];
    first: boolean;
    last: boolean;
    empty: boolean;
    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        };
        pageNumber: number;
        pageSize: number;
        offset: number;
        paged: boolean;
        unpaged: boolean;
    };
}

export function useTopBannerFunctions() {
    const dispatch = useAppDispatch();
    const [totalCouponCount, setTotalCouponCount] = useState(0);
    const [totalTestCount, setTotalTestCount] = useState(0);
    const [totalCounselingVoucherCount, setTotalCounselingVoucherCount] = useState(0);

    // 보유쿠폰수
    const getAllCouponCount = useCallback(async () => {
        try {
            const response = (await dispatch(getAllCouponCountAsync()).unwrap()) as CouponApiResponse;
            setTotalCouponCount(response.numberOfElements);
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    // 누적검사횟수
    const getProfileList = useCallback(async () => {
        try {
            const response = (await dispatch(getProfilesAsync()).unwrap()) as UserProfile[];
            // 모든 프로필의 testCnt를 더하는 코드
            const totalTestCnt = response.reduce((total, profile) => total + profile.testCnt, 0);
            setTotalTestCount(totalTestCnt);
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    // 상담 이용권 갯수
    const getCounselingVoucher = useCallback(async () => {
        try {
            const response:VoucherCount = (await dispatch(getCounselingVoucherAsync()).unwrap());
            setTotalCounselingVoucherCount(response.count);
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    return {
        getAllCouponCount, totalCouponCount,
        getProfileList, totalTestCount,
        getCounselingVoucher, totalCounselingVoucherCount
    }
}