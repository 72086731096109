import {createAsyncThunk} from "@reduxjs/toolkit";
import {ConsultationApi} from "../../api/ConsultationApi";
import {executePromise} from "../../util/sliceUtil";
import {
    ConsultantReviewPostDTO,
    ConsultationApplyDTO, ConsultationCancelDTO,
    ConsultationPutDTO,
    ConsultationReservationPostDTO
} from "../../types/consultation";

export const getConsultationHistory = createAsyncThunk(
    "profiles/getConsultants",
    async ({ profileIdx, pageable }: { profileIdx: number; pageable: { page: number; size: number } }) => {
        return executePromise(ConsultationApi.getConsultationHistory(profileIdx, pageable));
    }
);

export const getConsultationDetail = createAsyncThunk(
    "profiles/getConsultantDetail",
    async ({ consultantIdx }: { consultantIdx: number }) => {
        return executePromise(ConsultationApi.getCounselingResult(consultantIdx));
    }
);

// 예약 가능 테이블 조회 API
export const getAvailableReservationAsync = createAsyncThunk("consultation/getAvailableReservation",
    async (body: ConsultationReservationPostDTO) =>
        executePromise(ConsultationApi.getAvailableReservation(body))
);

// 상담 신청 API
export const applyCounselingAsync = createAsyncThunk("consultation/applyCounseling",
    async (body: ConsultationApplyDTO) =>
        executePromise(ConsultationApi.applyCounseling(body))
);

// 상담 취소 API
export const cancelCounselingAsync = createAsyncThunk("consultation/cancelCounseling",
    async (body: ConsultationCancelDTO) =>
        executePromise(ConsultationApi.cancelCounseling(body))
);

// 상담 수정 API
export const putCounselingAsync = createAsyncThunk("consultation/putCounseling",
    async ({body, idx}: { body:ConsultationPutDTO, idx:number }) =>
        executePromise(ConsultationApi.putCounseling({body, idx}))
);

// 배정 상담사 조회 API
export const getConsultantInfoAsync = createAsyncThunk("consultation/getConsultantInfo",
    async (counselingIdx:number) =>
        executePromise(ConsultationApi.getConsultantInfo(counselingIdx))
);

// 리뷰 작성 API
export const postConsultationReviewAsync = createAsyncThunk("consultation/postConsultationReview",
    async ({body ,counselingIdx}: { body :ConsultantReviewPostDTO, counselingIdx:number }) =>
        executePromise(ConsultationApi.postConsultationReview({body, counselingIdx}))
);

// 상담 이용권 갯수 조회 API
export const getCounselingVoucherAsync = createAsyncThunk("consultation/getCounselingVoucher",
    async () =>
        executePromise(ConsultationApi.getCounselingVoucher())
);
