import {SliceState, Status} from "../../types/common";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {addCases, executePromise} from "../../util/sliceUtil";
import {SignupInfo} from "../../types/auth";
import {AuthApi} from "../../api/AuthApi";
import {SignupApi} from "../../api/SignupApi";
import {LeavePostDTO, SignPostDTO} from "../../types/signup";

export interface SignupState extends SliceState {
}

const initialState: SignupState = {
    status: Status.IDLE,
};


// 회원가입 이메일 인증
export const getConfirmEmail = createAsyncThunk("signup/confirmEmail",
    (loginInfo: SignupInfo) =>
    executePromise(AuthApi.confirmEmail(loginInfo))
);

// 회원가입 API
export const signupMember = createAsyncThunk("signup/signups", (condition: SignPostDTO) =>
    executePromise(SignupApi.signupMember(condition))
);


// 탈퇴 API
export const leaveMember = createAsyncThunk("signup/signups", (condition: LeavePostDTO) =>
    executePromise(SignupApi.leaveMember(condition))
);


export const signupSlice = createSlice({
    name: "signup",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        addCases(builder, getConfirmEmail);
        addCases(builder, signupMember);
    },
});

export default signupSlice.reducer