import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import style from "../../assets/scss/sub/understanding.module.scss";
import { FloatingBtn } from "../common/button/FloatingBtn";
import { useTranslation } from "react-i18next";
import { useFooter } from "../../hooks/hooks";

export function Understanding() {
  const { t } = useTranslation(["subUnderstanding"]);
  const { setShowFooter } = useFooter();
  const location = useLocation();
  const [tabMenu, setTabMenu] = useState("");

  console.log('test')
  useEffect(() => {
    document.title = `${t("title")} | ${process.env.REACT_APP_TITLE}`;
  }, [t]);

  useEffect(() => {
    const pathname = location.pathname;
    const pathnameArr = pathname.split("/");
    const lastPath = pathnameArr[pathnameArr.length - 1];
    setTabMenu(lastPath);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={style.understanding}>
      <FloatingBtn />
      <div className={style.title}>
        <h2 className={style.text}>{t("title")}</h2>
      </div>
      <nav className={style.nav}>
        <ul className={style.list}>
          <li className={`${style.menuItem}`}>
            <Link className={`${tabMenu === "kpass" ? style.active : ""}`} to="/understanding/kpass">
              {t("understandingTab")}
            </Link>
          </li>
          <li className={`${style.menuItem}`}>
            <Link className={`${tabMenu === "history" ? style.active : ""}`} to="/understanding/history">
              {t("historyTab")}
            </Link>
          </li>
          <li className={`${style.menuItem}`}>
            <Link className={`${tabMenu === "academic-basis" ? style.active : ""}`} to="/understanding/academic-basis">
              {t("academicBasisTab")}
            </Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </main>
  );
}
