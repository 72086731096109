import maxCharacter from "../../../../assets/images/main/article4/section1/characters/ico_best_max.png";
import darkmongCharacter from "../../../../assets/images/main/article4/section1/characters/ico_best_darkmong.png";
import linkeyCharacter from "../../../../assets/images/main/article4/section1/characters/ico_best_linkey.png";
import peaceCharacter from "../../../../assets/images/main/article4/section1/characters/ico_best_peace.png";
import { useMemo } from "react";

export interface ReviewTheme {
  character: string;
  bgColor: string;
}
export function useBestReviewTheme() {
  const theme: ReviewTheme[] = useMemo(
    () => [
      {
        character: maxCharacter,
        bgColor: "#F1EFFF",
      },
      {
        character: darkmongCharacter,
        bgColor: "#FFF8EC",
      },
      {
        character: linkeyCharacter,
        bgColor: "#E8FBF4",
      },
      {
        character: peaceCharacter,
        bgColor: "#FFEFF3",
      },
    ],
    []
  );

  return theme;
}
