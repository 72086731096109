import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SliceState, Status} from "../../types/common";
import {RootState} from "../index";
import {Terms, TermsCategory} from "../../types/terms";
import {addCases, executePromise} from "../../util/sliceUtil";
import {TermsApi} from "../../api/TermsApi";

export interface TermsState extends SliceState {
  terms?: Terms;
}

const initialState: TermsState = {
  status: Status.IDLE
};

export const getTermsDetailAsync = createAsyncThunk(
  'terms/getTermsDetail',
  (category: TermsCategory) => executePromise(TermsApi.getTermByCategory(category))
);

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setTerms: (state, action: PayloadAction<Terms>) => {
      state.terms = action.payload;
    }
  },
  extraReducers: builder => {
    addCases(builder, getTermsDetailAsync);
  }
});

export const {
  setTerms
} = termsSlice.actions;

export const termsStatus = (state: RootState) => state.terms.status;
export const termsDetail = (state: RootState) => state.terms.terms;

export default termsSlice.reducer;