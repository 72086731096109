import { MainWrapper } from "./MainWrapper";
import style from "../../assets/scss/sub/downloadTest.module.scss";
import downloadIcon from "../../assets/images/header/download_icon.svg";
import appGuide1 from "../../assets/images/sub/download_test_guide/app_guide_1.png";
import appGuide2 from "../../assets/images/sub/download_test_guide/app_guide_2.png";
import appGuide3 from "../../assets/images/sub/download_test_guide/app_guide_3.png";
import appGuide4 from "../../assets/images/sub/download_test_guide/app_guide_4.png";
import windowGuide1 from "../../assets/images/sub/download_test_guide/window_guide_1.png";
import windowGuide2 from "../../assets/images/sub/download_test_guide/window_guide_2.png";
import windowGuide3 from "../../assets/images/sub/download_test_guide/window_guide_3.png";
import windowGuide4 from "../../assets/images/sub/download_test_guide/window_guide_4.png";
import windowGuide5 from "../../assets/images/sub/download_test_guide/window_guide_5.png";
import appStoreBadgeEn from "../../assets/images/sub/download_test_guide/apple_badge_desktop_en.png";
import appStoreBadgeEnMobile from "../../assets/images/sub/download_test_guide/apple_badge_mobile_en.png";
import appStoreBadge from "../../assets/images/sub/download_test_guide/app_store_badge.png";
import appStoreBadgeMobile from "../../assets/images/sub/download_test_guide/app_store_badge_mobile.png";
import googlePlayBadgeEn from "../../assets/images/sub/download_test_guide/google_badge_desktop_en.png";
import googlePlayBadgeEnMobile from "../../assets/images/sub/download_test_guide/google_badge_mobile_en.png";
import googlePlayBadge from "../../assets/images/sub/download_test_guide/google_play_badge.png";
import googlePlayBadgeMobile from "../../assets/images/sub/download_test_guide/google_play_badge_mobile.png";
import { ArticleTitle } from "./ArticleTitle";
import { useCallback, useEffect, useState } from "react";
import { GuideListItem } from "./download/GuideListItem";
import { useUserAgent, useModal, useLanguage } from "../../hooks/hooks";
import { Trans, useTranslation } from "react-i18next";
import { IsDesktopBr } from "../common/IsDesktopBr";
import { StrongTextStyle } from "../common/StyledCommonComponents";

enum OSType {
  WINDOW = "window",
  APP = "app",
}

export function DownloadTest() {
  const [osType, setOsType] = useState<OSType>(OSType.WINDOW);
  const { isDesktop } = useUserAgent();
  const { openAlertModal } = useModal();
  const onClickTabMenu = useCallback((type: OSType) => setOsType(type), []);
  const { isKorean } = useLanguage();
  const { t } = useTranslation("downloadTest");

  const googleImg = isDesktop ? isKorean ? googlePlayBadge : googlePlayBadgeEn : isKorean ? googlePlayBadgeMobile : googlePlayBadgeEnMobile;
  const appleImg = isDesktop ? isKorean ? appStoreBadge : appStoreBadgeEn : isKorean ? appStoreBadgeMobile : appStoreBadgeEnMobile;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `검사 다운로드 | ${process.env.REACT_APP_TITLE}`;
  }, []);

  const openPreparingAlert = useCallback(() => {
    if (!isDesktop) {
      openAlertModal({
        title: "안내",
        desc: t("secondExp.subDesc")
      });
    } else {
      window.location.href = "https://images.feel-good.io/setup/K-PASS.exe";
    }
  }, [isDesktop, openAlertModal, t]);

  // TODO: 영문 번역, 러시아 번역 update 필요
  const GuideNode = useCallback(() => {
    switch (osType) {
      case OSType.WINDOW:
        return (
          <div className={style.guideContent}>
            <button className={style.downloadBtn} onClick={openPreparingAlert}>
              <Trans t={t} i18nKey={"downloadforWindows"} components={[<IsDesktopBr />, <StrongTextStyle />]} />
              <img className={style.icon} src={downloadIcon} alt={"Window Download Link"} />
            </button>
            <ol className={style.orderedListGuide}>
              <GuideListItem
                number={1}
                normalText={t("firstExp.desc")}
                accentText={t("firstExp.subDesc")}
                imageUrl={windowGuide1}
              />
              <GuideListItem
                number={2}
                normalText={t("secondExp.desc")}
                accentText={t("secondExp.subDesc")}
                imageUrl={windowGuide2}
              />
              <GuideListItem number={3} normalText={t("thirdExp.desc")} accentText={""} imageUrl={windowGuide3} />
              <GuideListItem
                number={4}
                normalText={t("fourthExp.desc")}
                accentText={t("fourthExp.subDesc")}
                imageUrl={windowGuide4}
              />
              <GuideListItem
                number={5}
                normalText={t("fifthExp.desc")}
                accentText={t("fifthExp.subDesc")}
                imageUrl={windowGuide5}
              />
            </ol>
          </div>
        );
      case OSType.APP:
        return (
          <div className={style.guideContent}>
            <div className={style.googlePlayGuide}>
              <span className={style.text}>
                {t("storeText.desc1")}
                {isDesktop ? <> </> : <br />}
                <span className={style.accent}>{t("storeText.desc2")}</span>
              </span>
              <div className={style.badges}>
                <a
                  href={"https://play.google.com/store/apps/details?id=com.feelgood.kpass&hl=ko"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className={style.badgeImage}
                    src={googleImg}
                    alt={"구글 플레이스토어 뱃지"}
                  />
                </a>
                <a
                  href={"https://apps.apple.com/kr/app/k-pass-%EC%BC%80%EC%9D%B4%ED%8C%A8%EC%8A%A4/id6448768648"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className={style.badgeImage}
                    src={appleImg}
                    alt={"앱 스토어 뱃지"}
                  />
                </a>
              </div>
            </div>
            <ol className={style.orderedListGuide}>
              <GuideListItem
                number={1}
                normalText={t("firstAppExp.desc")}
                accentText={t("firstAppExp.subDesc")}
                imageUrl={appGuide1}
              />
              <GuideListItem number={2} normalText={t("secondAppExp.desc")} accentText={""} imageUrl={appGuide2} />
              <GuideListItem
                number={3}
                normalText={t("thirdAppExp.desc")}
                accentText={t("thirdAppExp.subDesc")}
                imageUrl={appGuide3}
              />
              <GuideListItem
                number={4}
                normalText={t("fourthAppExp.desc")}
                accentText={t("fourthAppExp.subDesc")}
                imageUrl={appGuide4}
              />
            </ol>
          </div>
        );
    }
    // eslint-disable-next-line
  }, [osType, isDesktop]);

  return (
    <>
      <MainWrapper showFooter={true} mainClassName={style.downloadTest}>
        <>
          <div className={style.bgItem1}></div>
          <div className={style.bgItem2}></div>
          <div className={style.title}>
            <ArticleTitle title={t('download')} className={""} />
          </div>
          <div className={style.tabMenu}>
            <button
              className={`${style.tab} ${osType === OSType.WINDOW ? style.active : ""}`}
              onClick={() => onClickTabMenu(OSType.WINDOW)}
            >
              {t("forWindows")}
            </button>
            <button
              className={`${style.tab} ${osType === OSType.APP ? style.active : ""}`}
              onClick={() => onClickTabMenu(OSType.APP)}
            >
              {t("forAndroidIos")}
            </button>
          </div>
          <GuideNode />
        </>
      </MainWrapper>
    </>
  );
}
