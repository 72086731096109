import React from "react";

export interface PageContextType {
  isMainPage: boolean;
  isWithAppPage: boolean;
  isPartnershipPage: boolean;
  isPurchasePage: boolean;
  isOrderPage: boolean;
  isPaymentResultPage: boolean;
  isDownloadTestPage: boolean;
  isPartnerCompanyPage: boolean;
  isMyPage: boolean;
}

export const PageContext = React.createContext<PageContextType>(null!);
