import {ConsultationReservation} from "../../../../types/consultation";
import React from "react";

interface Args {
    reservations: ConsultationReservation[],
    onTimeSelect: (time: string, idx: number) => void;
}

export function ReservationSelectTimeOption({reservations}: Args) {
    const now = new Date();
    const currentHour = now.getHours();
    const today = now.toISOString().split('T')[0]; // YYYY-MM-DD

    return (
        <>
            {reservations.map((data, key) => {
                // 예약 시간을 숫자로 변환 (10:00 -> 10)
                const reservationHour = parseInt(data.desiredTime.split(':')[0]);

                // 오늘 날짜인 경우에만 시간 체크, 미래 날짜는 모두 허용
                const isSameDate = data.desiredDate === today;
                const isPastTime = isSameDate && (reservationHour <= currentHour);

                // 이미 예약되었거나, 오늘이면서 현재 시간보다 이전인 경우 disabled
                const isDisabled = data.isReserved || isPastTime;

                return (
                    <option
                        key={key}
                        value={data.desiredTime}
                        data-idx={data.idx}
                        disabled={isDisabled}
                    >
                        {data.desiredTime} (잔여 {data.isReserved ? 0 : 1}석)
                    </option>
                );
            })}
        </>
    );
}