import style from "../../../../assets/scss/common/modal/leaveModal.module.scss";
import {useEffect, useState} from "react";

interface Args{
    setIsAllAgreementSelected: (_: boolean) => void;
    errorMessage: string;
    setErrorMessage: (_:string) => void;
}
export function LeaveAgreementField({setIsAllAgreementSelected, errorMessage, setErrorMessage}: Args) {

    const [voucherAgreement, setVoucherAgreement] = useState(false);
    const [affiliationAgreement, setAffiliationAgreement] = useState(false);
    const [sameAccountAgreement, setSameAccountAgreement] = useState(false);
    const [informationAgreement, setInformationAgreement] = useState(false);
    const [selectAll, setSelectAll] = useState(false);



    const selectAgreeWrappers = [
        {
            content: '이용권을 보유하셨다면 탈퇴 전 사용해주세요. 미사용 탈퇴 시 이용권도 삭제됩니다. 단, 구매일로부터 7일 이내의 이용권은 환불이 가능합니다.',
            isAgreed: voucherAgreement,
            setAgreement: setVoucherAgreement,
            isAllSelectButton: false,
        },
        {
            content: '소속이용권을 보유중이시라면 회원 탈퇴 시 자동으로 소속에서 퇴출되며, 소속이용권은 반환됩니다.',
            isAgreed: affiliationAgreement,
            setAgreement: setAffiliationAgreement,
            isAllSelectButton: false,
        },
        {
            content: '동일한 계정(이메일)으로는 재가입이 불가능 합니다.',
            isAgreed: sameAccountAgreement,
            setAgreement: setSameAccountAgreement,
            isAllSelectButton: false,
        },
        {
            content: '회원 탈퇴 시 모든 정보는 삭제됩니다. 삭제된 정보는 복구할 수 없으니 신중하게 결정해 주세요. 단, K-PASS 검사 및 D-CAS 검사 정보는 삭제되지 않으니 미리 확인해 주세요.',
            isAgreed: informationAgreement,
            setAgreement: setInformationAgreement,
            isAllSelectButton: false,
        },
        {
            content: '모두 확인하였으며, 이에 동의합니다.',
            isAgreed: selectAll,
            setAgreement: setSelectAll,
            isAllSelectButton: true,
        },
    ];

    useEffect(() => {
        setIsAllAgreementSelected(voucherAgreement && affiliationAgreement && sameAccountAgreement && informationAgreement)
        setSelectAll(voucherAgreement && affiliationAgreement && sameAccountAgreement && informationAgreement)
        setErrorMessage('')

        // eslint-disable-next-line
    }, [voucherAgreement, affiliationAgreement, sameAccountAgreement, informationAgreement]);

    const handleTotalAgreement = () => {
        if (voucherAgreement && affiliationAgreement && sameAccountAgreement && informationAgreement) {
            setVoucherAgreement(false)
            setAffiliationAgreement(false)
            setSameAccountAgreement(false)
            setInformationAgreement(false)
        } else {
            // 하나라도 false일 경우 모두 true로 설정
            setVoucherAgreement(true)
            setAffiliationAgreement(true)
            setSameAccountAgreement(true)
            setInformationAgreement(true)
        }
    }
    return(
        <>
            <div className={style.leaveAgreementWrapper}>
                <div className={style.leaveAgreementContentWrapper}>
                    {selectAgreeWrappers.map((wrapper, index) => (
                        <div className={style.agreementContent} key={index}
                             onClick={() => {
                                 wrapper.setAgreement(!wrapper.isAgreed);
                                 if (wrapper.isAllSelectButton) {
                                     handleTotalAgreement();
                                 }
                             }}>
                            <div className={`${style.agreementCheckImg} ${wrapper.isAgreed ? style.selected : ''}`}/>
                            <div className={style.agreementTextWrapper}>
                                <span className={`${style.agreementText} ${wrapper.isAllSelectButton ? style.bold : ''}`}>
                                    {wrapper.content}
                                </span>
                            </div>
                        </div>
                    ))}
                    {errorMessage && (
                        <div className={style.errorMessageWrapper}>
                            <span className={style.message}>{errorMessage}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}