import { useCallback, useMemo } from "react";
import { BrainType } from "../review";
import { useTranslation } from "react-i18next";

export function useBrainType() {
  const { t } = useTranslation("subRealReview");

  const router = useMemo(
    () => ({
      [BrainType.UNKNOWN.toString()]: "",
      [BrainType.SIMULTANEOUS.toString()]: t("brainType.simultaneous"),
      [BrainType.SUCCESSIVE.toString()]: t("brainType.successive"),
      [BrainType.BALANCED.toString()]: t("brainType.balanced"),
    }),
    [t]
  );

  const toStringFromBrainType = useCallback((brainType: BrainType) => router[brainType.toString()], [router]);

  return { toStringFromBrainType };
}
