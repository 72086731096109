import {ChildrenProp} from "../../types/common";
import {BannerContext} from "./BannerContext";
import {useEffect, useState} from "react";

export function BannerProvider({children}: ChildrenProp) {
    // localStorage에서 초기값 설정
    const getInitialHideState = () => {
        const storedValue = localStorage.getItem('hideAwardBanner');
        const expiry = localStorage.getItem('hideBannerExpiry');

        if (expiry && parseInt(expiry, 10) < Date.now()) {
            // 만료된 경우 초기화
            localStorage.removeItem('hideAwardBanner');
            localStorage.removeItem('hideBannerExpiry');
            return false;
        }

        return storedValue === 'true';
    };

    const [hideAwardBanner, setHideAwardBanner] = useState(getInitialHideState);

    // hideAwardBanner 상태가 변경될 때마다 localStorage 업데이트
    useEffect(() => {
        localStorage.setItem('hideAwardBanner', hideAwardBanner.toString());
    }, [hideAwardBanner]);

    const setBannerVisibilityWithExpiry = (value: boolean, expiryMs?: number) => {
        if (value && expiryMs) {
            const expiryTime = Date.now() + expiryMs;
            localStorage.setItem('hideBannerExpiry', expiryTime.toString());
        } else {
            localStorage.removeItem('hideBannerExpiry');
        }
        setHideAwardBanner(value);
    };

    return (
        <BannerContext.Provider
            value={{
                hideAwardBanner,
                setHideAwardBanner: setBannerVisibilityWithExpiry
            }}
        >
            {children}
        </BannerContext.Provider>
    );
}
