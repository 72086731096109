import styled from "styled-components";
import {ErrorMessageContainerStyle, ErrorMessageStyle, InputTextStyle} from "./StyledComponents";
import {ChangeEvent, MutableRefObject, useCallback, useEffect, useState} from "react";
import {breakpoints} from "../../../../../../theme/theme";
import {TextCounter} from "./TextCounter";

const InputTextContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
  
  ${breakpoints.tablet} {
    align-self: stretch;
    gap: 6px;
  }

  ${breakpoints.mobile} {
    gap: 6px;
  }
`;

interface Props {
  inputRef: MutableRefObject<HTMLInputElement | null>;
  defaultText?: string;
  placeholder: string;
  maxLength: number;
  onChangeText: (newText: string) => void;
  hasError: boolean;
  errorMessage: string;
}

export function InputText({
  inputRef,
  defaultText,
  placeholder,
  maxLength,
  onChangeText,
  hasError,
  errorMessage,
}: Props) {
  const [text, setText] = useState(defaultText ?? "");
  useEffect(() => setText(defaultText ?? ""), [defaultText, setText]);

  const onChangeInputText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newText = e.target.value;
      if (newText.length > maxLength) return;
      setText(newText);
    },
    [setText, maxLength]
  );

  const onFocusOut = useCallback(() => onChangeText(text), [onChangeText, text]);

  return (
    <InputTextContainerStyle>
      <InputTextStyle
        ref={inputRef}
        value={text}
        placeholder={placeholder}
        onChange={onChangeInputText}
        onBlur={onFocusOut}
      />
      <ErrorMessageContainerStyle>
        {hasError && <ErrorMessageStyle>{errorMessage}</ErrorMessageStyle>}
        <TextCounter currentCount={text.length} maxCount={maxLength} />
      </ErrorMessageContainerStyle>
    </InputTextContainerStyle>
  );
}
