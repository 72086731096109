import styled from "styled-components";
import { ErrorMessageStyle, KpassReviewInputTitleStyle } from "../common/StyledComponents";
import { Profile } from "./Profile";
import { useAppSelector } from "../../../../../../hooks/hooks";
import {
  ReviewModalMode,
  reviewModalModeState,
  userProfileWithReviewStatusesState,
  userProfileWithReviewStatusForEditState,
  validationErrorsState,
} from "../../../../../../store/review/reviewModalSlice";
import { MutableRefObject, useMemo } from "react";
import { breakpoints } from "../../../../../../theme/theme";
import { ReviewValidationError } from "../../hooks/useReviewValidator";
import { useTranslation } from "react-i18next";

const ProfilesStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ProfilesContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;

  ${breakpoints.tablet} {
    overflow-x: auto;
    gap: 10px;
    width: 640px;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${breakpoints.mobile} {
    overflow-x: auto;
    gap: 10px;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

interface Props {
  profileRef: MutableRefObject<HTMLDivElement | null>;
}

export function Profiles({ profileRef }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const reviewModalMode = useAppSelector(reviewModalModeState);
  const userProfileWithReviewStatuses = useAppSelector(userProfileWithReviewStatusesState);
  const userProfileWithReviewStatusForEdit = useAppSelector(userProfileWithReviewStatusForEditState);
  const validationErrors = useAppSelector(validationErrorsState);
  const hasError = useMemo(
    () => validationErrors.some((it) => it === ReviewValidationError.PROFILE),
    [validationErrors]
  );

  const userProfiles = useMemo(() => {
    if (reviewModalMode === ReviewModalMode.EDIT)
      return userProfileWithReviewStatusForEdit ? [userProfileWithReviewStatusForEdit] : [];
    else return userProfileWithReviewStatuses ? userProfileWithReviewStatuses : [];
  }, [reviewModalMode, userProfileWithReviewStatuses, userProfileWithReviewStatusForEdit]);
  return (
    <ProfilesStyle ref={profileRef}>
      <KpassReviewInputTitleStyle>{t("profileTitle")}</KpassReviewInputTitleStyle>
      <ProfilesContainerStyle>
        {userProfiles.map((it) => (
          <Profile userProfileWithReviewStatusVO={it} key={it.userProfile.idx} />
        ))}
      </ProfilesContainerStyle>

      {hasError && <ErrorMessageStyle>{t("profileSelectProfile")}</ErrorMessageStyle>}
    </ProfilesStyle>
  );
}
