enum TermsCategory {
  NONE = "",
  USER_SIGN_UP = "USER_SIGN_UP",
  PAYMENT_TERMS = "PAYMENT_TERMS",
}

interface Terms {
  idx: number;
  title: string;
  content: string;
  isAgreementRequired: boolean;
}

export { TermsCategory };
export type { Terms };
