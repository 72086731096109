import { useCallback } from "react";
import { ReviewStatus, UserProfileWithReviewStatusVO } from "../../../../../types/review";

export function useReviewStateHook() {
  const isWritable = useCallback((profileWithStatus: UserProfileWithReviewStatusVO): boolean => {
    return profileWithStatus.reviewStatuses.some((it) => it.status === ReviewStatus.UNWRITTEN);
  }, []);

  const hasNoTest = useCallback((profileWithStatus: UserProfileWithReviewStatusVO): boolean => {
    return profileWithStatus.reviewStatuses.length === 0;
  }, []);

  const isWrittenAllReview = useCallback((profileWithStatus: UserProfileWithReviewStatusVO): boolean => {
    return (
      profileWithStatus.userProfile.testCnt > 0 &&
      profileWithStatus.reviewStatuses.every((it) => it.status === ReviewStatus.WRITTEN)
    );
  }, []);

  const writableCount = useCallback((profileWithStatus: UserProfileWithReviewStatusVO): number => {
    return profileWithStatus.reviewStatuses.filter((it) => it.status === ReviewStatus.UNWRITTEN).length;
  }, []);

  return {
    isWritable,
    hasNoTest,
    isWrittenAllReview,
    writableCount,
  };
}
