import React from "react";

export enum UserDevice {
  MOBILE = "MOBILE",
  TABLET = "TABLET",
  DESKTOP = "DESKTOP",
}

export const UserAgentContext = React.createContext({
  isDesktop: true,
  isTablet: true,
  isMobile: true,
  device: UserDevice.DESKTOP,
});
