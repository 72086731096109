import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import ko from "./locales/ko";
import ru from "./locales/ru";
import { Language } from "../types/language";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      [Language.EN]: en,
      [Language.KO]: ko,
      [Language.RU]: ru,
    },
    fallbackLng: Language.KO,
    interpolation: {
      escapeValue: false,
    },
  })
  .then();
