import style from "../koreanKpassStandardization.module.scss";
import kpassSecondaryLogo from "../../../assets/images/logo/kpass_colored_secondary.svg";

export function ArticleHeaderKO() {
  return (
    <section className={style.articleHeader}>
      <span>한국판 K-PASS 표준화</span>
      <img className={style.logo} src={kpassSecondaryLogo} alt={"K-PASS Secondary Logo"} />
    </section>
  );
}
