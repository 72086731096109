import style from "../../assets/scss/main_legacy/outro.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { MainLegacyScrollContext } from "./hooks/MainLegacyScrollContext";
import { useTranslation } from "react-i18next";

export function Outro() {
  const { t } = useTranslation(["mainOutro"]);
  const [isShownSection, setIsShownSection] = useState(false);
  const { scrollY, setScrollingSection, showOrHide, isPassingHeaderBottom, setShowFooter } =
    useContext(MainLegacyScrollContext);
  const sectionRef = useRef();

  useEffect(() => {
    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (sectionRect && isPassingHeaderBottom(sectionRect.top, sectionRect.bottom)) {
      setScrollingSection(null);
    }

    showOrHide(sectionRef.current, setIsShownSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    if (isShownSection) {
      setTimeout(() => {
        setShowFooter(true);
      }, 2400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownSection]);

  return (
    <section className={style.outro} ref={sectionRef}>
      <h3 className="blind">Outro</h3>
      <div className={style.content}>
        <p className={`${style.phrase1} ${isShownSection ? style.show : style.hide}`}>
          {t("text1")}
          <br />
          {t("text2")}
        </p>
        <div className={style.phrase2AndLine}>
          <div className={`${style.line} ${isShownSection ? style.show : style.hide}`}></div>
          <p className={`${style.phrase2} ${isShownSection ? style.show : style.hide}`}>
            {t("text3")}
            <br />
            {t("text4")}
          </p>
        </div>
      </div>
    </section>
  );
}
