import { useCallback } from "react";
import { getProfileAsync } from "../../store/profile/profileSlice";
import { useAppDispatch } from "../hooks";
import { getProfileByReviewIdxAsync } from "../../store/review/reviewSlice";

export function useProfileGetter() {
  const dispatch = useAppDispatch();

  const getProfile = useCallback(
    async (profileIdx: number) => await dispatch(getProfileAsync(profileIdx)).unwrap(),
    [dispatch]
  );

  const getSecuredProfile = useCallback(
    async (reviewIdx: number) => await dispatch(getProfileByReviewIdxAsync(reviewIdx)).unwrap(),
    [dispatch]
  );

  return {
    getProfile,
    getSecuredProfile,
  };
}
