import { ProductSearchCondition } from "../types/page";
import { MainApi } from "./MainApi";
import {DisplayPosition} from "../types/product";

export class ProductApi {
  static baseUrl = `${MainApi.urlPrefix}/user/product`;

  static getTheLatestProduct = ({condition, displayPosition}:{
      condition: ProductSearchCondition,displayPosition:DisplayPosition}) => () =>
    MainApi.api.get(
      `${ProductApi.baseUrl}/the-latest${MainApi.toParamStringFromObject({
        ...condition,
        display_position: displayPosition,
      })}`
    );

  static getProductImages =  ({condition, displayPosition}:{
      condition: ProductSearchCondition,displayPosition:DisplayPosition}) => () =>
    MainApi.api.get(
      `${ProductApi.baseUrl}/${condition?.productIdx}/image${MainApi.toParamStringFromObject({
        ...condition,
        display_position: displayPosition,
      })}`
    );
}
