import { KPASSReviewVO, ReviewImageDTO } from "../../../../../types/review";
import styled from "styled-components";
import style from "../../review.module.scss";
import { EditableButtons } from "../../list/EditableButtons";
import React from "react";
import { fillZeroTo2Digit } from "../../../../../util/commonFunctions";
import { breakpoints } from "../../../../../theme/theme";
import { useUserAgent } from "../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";

const ReviewContentSectionStyle = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DateContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > span {
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    color: ${(props) => props.theme.color.subtextColor};
    
    ${breakpoints.tablet} {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        letter-spacing: -0.21px;
    }

    ${breakpoints.mobile} {
      font-size: 14px;
    }
  }
`;

const ReviewTitleStyle = styled.div`
  padding: 30px 40px;
  background-color: #fbfbff;

  font-weight: bold;
  font-size: 28px;
  line-height: 140%;
  border-radius: 20px;
  color: ${(props) => props.theme.color.kpNavy};
  
  ${breakpoints.tablet} {
    font-size: 20px;
    padding: 16px;
    border-radius: 10px;
  }

  ${breakpoints.mobile} {
    font-size: 20px;
    padding: 16px;
    border-radius: 10px;
  }
`;

const ReviewDescriptionStyle = styled.div`
  padding: 20px 40px;

  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: ${(props) => props.theme.color.subtextColor};
  white-space: pre-wrap;
  
  ${breakpoints.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.24px;
    padding: unset;
  }

  ${breakpoints.mobile} {
    font-size: 16px;
    padding: unset;
  }
`;

const ReviewImagesContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  > img {
    max-width: 50%;
  }
  
  ${breakpoints.tablet} {
    gap: 16px;

    > img {
      width: 300px;
      max-width: unset;
    }
  }

  ${breakpoints.mobile} {
    gap: 16px;

    > img {
      width: 100%;
      max-width: unset;
    }
  }
`;

interface Props {
  review: KPASSReviewVO;
  reviewImages: ReviewImageDTO[];
}

export function ReviewContentSection({ review, reviewImages }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const { isDesktop } = useUserAgent();
  const dateToString = (date: Date) =>
    `${date.getFullYear()}${t("year")} ${fillZeroTo2Digit(date.getMonth() + 1)}${t("month")} ${fillZeroTo2Digit(
      date.getDate()
    )}${t("day")}`;

  return (
    <ReviewContentSectionStyle>
      <DateContainerStyle>
        <span>{dateToString(new Date(review.writtenAt))}</span>

        <EditableButtons
          wrapperClassName={isDesktop ? style.editableButtons : style.editableButtonsMobile}
          editButtonClassName={style.purple}
          deleteButtonClassName={style.pink}
          userIdx={review.userIdx}
          reviewIdx={review.idx}
        />
      </DateContainerStyle>

      <ReviewTitleStyle>{review.oneLineReviewText}</ReviewTitleStyle>
      <ReviewDescriptionStyle>{review.reviewText}</ReviewDescriptionStyle>

      <ReviewImagesContainerStyle>
        {reviewImages.map((it) => (
          <img key={it.file.fileIdx} src={it.file.uri} alt="review" />
        ))}
      </ReviewImagesContainerStyle>
    </ReviewContentSectionStyle>
  );
}
