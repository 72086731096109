import style from "./mainArticle3.module.scss";
import graph1 from "../../../assets/images/main/article3/section1/part1/main_graph1.gif";
import graph1Eng from "../../../assets/images/main/article3/section1/part1/main_graph1_eng.gif";
import graph1MobileEng from "../../../assets/images/main/article3/section1/part1/main_graph1_mobile_eng.gif";
import graph2 from "../../../assets/images/main/article3/section1/part1/main_graph2.gif";
import graph2Eng from "../../../assets/images/main/article3/section1/part1/main_graph2_eng.gif";
import graph2Mobile from "../../../assets/images/main/article3/section1/part1/main_graph2_mobile.gif";
import graph2MobileEng from "../../../assets/images/main/article3/section1/part1/main_graph2_mobile_eng.gif";
import graph3 from "../../../assets/images/main/article3/section1/part1/korean_graph_desktop.gif";
import graph3Eng from "../../../assets/images/main/article3/section1/part1/english_graph_desktop.gif";
import graph3Mobile from "../../../assets/images/main/article3/section1/part1/korean_graph_mobile.gif";
import graph3MobileEng from "../../../assets/images/main/article3/section1/part1/english_graph_mobile.gif";
import checkbox from "../../../assets/images/main/article3/section1/part1/checkbox.svg";
import {ArticleTitle} from "../../sub/ArticleTitle";
import {SubtitleNumbering} from "./SubtitleNumbering";
import {Section1ProsAndCons} from "./Section1ProsAndCons";
import {useRef, useState} from "react";
import {useShowOrHide} from "../../../hooks/useShowOrHide";
import {useLanguage, useMainScrolling, useUserAgent} from "../../../hooks/hooks";
import {StrongTextStyle} from "../../common/StyledCommonComponents";
import {Trans, useTranslation} from "react-i18next";
import {IsDesktopBr} from "../../common/IsDesktopBr";
import {IsTabletBr} from "../../common/IsTabletBr";

export function Article3Section1() {
  const { t } = useTranslation(["competitiveness"]);
  const { getLanguageStyle, isKorean } = useLanguage();
  const gridRef = useRef<HTMLDivElement>(null);
  const chart1Ref = useRef<HTMLDivElement>(null);
  const chart2Ref = useRef<HTMLDivElement>(null);
  const chart3Ref = useRef<HTMLDivElement>(null);
  const [showGrid, setShowGrid] = useState(false);
  const [showChart1, setShowChart1] = useState(false);
  const [showChart2, setShowChart2] = useState(false);
  const [showChart3, setShowChart3] = useState(false);
  const { article3Element } = useMainScrolling();
  const { isTablet } = useUserAgent();
  const { isMobile } = useUserAgent();



  useShowOrHide({ refCurrent: gridRef.current, setShow: setShowGrid });
  useShowOrHide({ refCurrent: chart1Ref.current, setShow: setShowChart1 });
  useShowOrHide({ refCurrent: chart2Ref.current, setShow: setShowChart2 });
  useShowOrHide({ refCurrent: chart3Ref.current, setShow: setShowChart3 });

  return (
    <section className={style.section1} ref={article3Element}>
      <div className={style.titleWrapper}>
        <h2 className={"blind"}>{t("section1.title")}</h2>
        <ArticleTitle title={t("section1.title")} className={`${style.title} ${getLanguageStyle(style)}`} />
      </div>
      <div className={style.gridArea} ref={gridRef}>
        <div className={`${style.texts} ${getLanguageStyle(style)}`}>
          <SubtitleNumbering lightNumber={0} boldNumber={1} />
          <h3 className={style.title}>{t("section1.subtitle")}</h3>
          <p className={style.description}>
            <Trans
                t={t}
                i18nKey={"section1.description"}
                components={isTablet ? [<IsTabletBr />, <StrongTextStyle></StrongTextStyle>] : [<IsDesktopBr />, <StrongTextStyle></StrongTextStyle>]}
            />
          </p>
        </div>
        <div className={`${style.chart} ${showChart1 ? style.show : style.hide}`} ref={chart1Ref}>
          <h4 className={"blind"}>{t("section1.chart1.heading4")}</h4>

          <div className={style.title}>
            <img className={style.checkbox} src={checkbox} alt={t("section1.chart1.checkboxImageAlt")} />
            <span>{t("section1.chart1.title")}</span>
          </div>
          <div className={`${style.graphWrapper} ${style.graph1} ${showGrid ? style.show : style.hide}`}>
            {showGrid && (
              <img
                src={isKorean ? (isMobile ? graph1 : graph1) : isMobile ? graph1MobileEng : graph1Eng}
                alt={t("section1.chart1.graphImageAlt")}
              />
            )}
          </div>
        </div>
        <div className={`${style.chart} ${showChart2 ? style.show : style.hide}`} ref={chart2Ref}>
          <h4 className={"blind"}>{t("section1.chart2.heading4")}</h4>
          <div className={style.title}>
            <img className={style.checkbox} src={checkbox} alt={t("section1.chart2.checkboxImageAlt")} />
            <span>{t("section1.chart2.title")}</span>
          </div>
          <div className={`${style.graphWrapper} ${style.graph2} ${showGrid ? style.show : style.hide}`}>
            {showGrid && (
              <img
                className={`${style.graph2} ${showGrid ? style.show : style.hide}`}
                src={isKorean ? (isMobile ? graph2Mobile : graph2) : isMobile ? graph2MobileEng : graph2Eng}
                alt={t("section1.chart2.graphImageAlt")}
              />
            )}
          </div>
        </div>
        <div className={`${style.chart} ${showChart3 ? style.show : style.hide}`} ref={chart3Ref}>
          <h4 className={"blind"}>{t("section1.chart3.heading4")}</h4>
          <div className={style.title}>
            <img className={style.checkbox} src={checkbox} alt={t("section1.chart3.checkboxImageAlt")} />
            <span>{t("section1.chart3.title")}</span>
          </div>
          <div className={`${style.graphWrapper} ${style.graph3} ${showGrid ? style.show : style.hide}`}>
            {showGrid && (
              <img
                src={isKorean ? (isMobile ? graph3Mobile : graph3) : isMobile ? graph3MobileEng : graph3Eng}
                alt={t("section1.chart3.graphImageAlt")}
              />
            )}
          </div>
        </div>
      </div>
      <Section1ProsAndCons />
    </section>
  );
}
