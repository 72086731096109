import { LoaderContext } from "./LoaderContext";
import { ChildrenProp, Status } from "../../types/common";
import { useMemo, useState } from "react";
import { Loader } from "../../components/common/Loader";

export function LoaderProvider({ children }: ChildrenProp) {
  const [loaderStatus, setLoaderStatus] = useState<Status[]>([Status.IDLE]);

  const isLoading = useMemo(() => loaderStatus.some((status) => status === Status.LOADING), [loaderStatus]);

  return (
    <LoaderContext.Provider
      value={{
        setLoaderStatus,
        isLoading,
      }}
    >
      <>
        <Loader status={isLoading ? Status.LOADING : Status.IDLE} />
        {children}
      </>
    </LoaderContext.Provider>
  );
}
