import { useCallback } from "react";
import { CookieKeys } from "./AuthProvider";

export function useCookie() {
  const getValueFromCookie = useCallback((cookieKey: CookieKeys) => {
    const cookies = document.cookie.split(";");

    return cookies
      .find((c) => c.trim().startsWith(`${cookieKey}=`))
      ?.split("=")[1]
      .trim();
  }, []);

  const setCookie = useCallback((key: CookieKeys, value: string, expires?: string) => {
    document.cookie = `${key.toString()}=${value};path=/;${expires ? `expires=${expires};` : ""}`;
  }, []);

  return {
    getValueFromCookie,
    setCookie,
  };
}
