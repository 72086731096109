import {MainApi} from "./MainApi";
import {LeavePostDTO, SignPostDTO} from "../types/signup";
import {leaveMember} from "../store/singup/signupSlice";

export class SignupApi {
    static baseUrl = `${MainApi.urlPrefix}/auth/user`;

    static signupMember = (condition: SignPostDTO) =>() =>
        MainApi.api.post(`${SignupApi.baseUrl}/signup`,condition);


    static kpassBaseUrl = `${MainApi.urlPrefix}/user`;
    static leaveMember = (condition: LeavePostDTO) =>() =>
        MainApi.api.post(`${SignupApi.kpassBaseUrl}/leave-request`,condition);


}