import React, { useCallback, useState } from "react";
import style from "../../../assets/scss/common/modal/addCheckupInfoModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import { useModal, useAppDispatch, useTimeConverter } from "../../../hooks/hooks";
import { getOrganTestResultAsync, addOrganTestResultAsync } from "../../../store/profile/profileSlice";

interface AddCheckupInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  profileIdx: number;
}

const AddCheckupInfoModal: React.FC<AddCheckupInfoModalProps> = ({ isOpen, onClose, profileIdx }) => {
  const [name, setName] = useState("");
  const [loginCode, setLoginCode] = useState("");
  const [nameError, setNameError] = useState("");
  const [loginCodeError, setLoginCodeError] = useState("");
  const [organName, setOrganName] = useState("");
  const [testDate, setTestDate] = useState("");
  const [showData, setShowData] = useState(false);
  const [buttonText, setButtonText] = useState("소속 검사 조회");
  const [showErrorInfo, setShowErrorInfo] = useState(false);
  const [showAddErrorInfo, setShowAddErrorInfo] = useState(false);
  const { openConfirmModal, openAlertModal } = useModal();
  const { timeConverter } = useTimeConverter();

  const dispatch = useAppDispatch();

  const getOrganTestData = useCallback(
    async (name: string, code: string) => {
      try {
        setShowErrorInfo(false);
        const response = await dispatch(getOrganTestResultAsync({ name, code })).unwrap();
        console.log("get organ test data>", response);
        return response;
      } catch (error) {
        // 소속 검사 결과가 없습니다.
        setShowErrorInfo(true);
        console.log(error);
      }
    },
    [dispatch]
  );

  const addOrganTestData = useCallback(
    async (name: string, code: string) => {
      try {
        setShowErrorInfo(false);
        await dispatch(addOrganTestResultAsync({ profileIdx, name, code })).unwrap();

        openAlertModal({
          title: "소속 검사 결과 안내",
          desc: "소속 검사 결과가 추가 되었습니다.",
          callback: () => handleClose(),
        });

        return;
      } catch (error: any) {
        try {
          // JSON 파싱
          const parsedError = JSON.parse(error.message);

          // httpCode가 493인 경우 에러 메시지 표시
          if (parsedError?.errorCode?.httpCode === 493) {
            setShowAddErrorInfo(true); // 에러 메시지 표시
          }
        } catch (parseError) {
          console.error("Error parsing error message:", parseError);
        }
        console.log(error);
      }
    },
    [dispatch, profileIdx]
  );

  const validateName = (value: string) => {
    if (!value) {
      return "이름을 입력해 주세요.";
    } else if (!/^[가-힣a-zA-Z0-9]+$/.test(value)) {
      return "이름은 한글, 영어, 숫자로 입력해 주세요.";
    }
    return "";
  };

  const validateLoginCode = (value: string) => {
    if (!value) {
      return "로그인 코드를 입력해 주세요.";
    } else if (!/^[a-zA-Z0-9]{6}$/.test(value)) {
      return "로그인 코드는 6자리 영문, 숫자로 입력해 주세요.";
    }
    return "";
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    setNameError(validateName(value));
  };

  const handleLoginCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLoginCode(value);
    setLoginCodeError(validateLoginCode(value));
  };

  const handleCheckupFetch = async () => {
    // 입력 값 검증
    const nameError = validateName(name);
    const loginCodeError = validateLoginCode(loginCode);

    setNameError(nameError);
    setLoginCodeError(loginCodeError);

    if (nameError || loginCodeError) {
      return; // 유효하지 않으면 더 이상의 처리 중지
    }

    if (buttonText === "추가하기") {
      openConfirmModal(
        {
          title: "소속 검사 결과 안내",
          desc: "소속 검사 결과는 하나의 프로필만 연동 가능합니다.\n 조회한 소속 검사 결과를 추가하시겠습니까?",
        },
        async () => {
          try {
            await addOrganTestData(name, loginCode);
          } catch (error) {
            console.log("소속 검사 결과 추가 중 에러 발생:", error);
            setShowAddErrorInfo(true); // 에러 메시지 표시
          }
        }
      );
    } else {
      try {
        // getOrganTestData 함수 호출
        const response = await getOrganTestData(name, loginCode);

        if (response) {
          // 성공적으로 데이터를 받아온 경우 organName과 testDate 설정
          setOrganName(response.organName);
          setTestDate(timeConverter.convertToFormattedDate(response.testEndedAt.split("T")[0]));
          setShowData(true);
          setButtonText("추가하기");
          setShowErrorInfo(false); // 데이터가 성공적으로 받아지면 에러 표시 해제
        }
      } catch (error: any) {
        console.log(error);

        // 에러 처리: 존재하지 않는 데이터일 경우 404 메시지를 확인
        const errorMessage = JSON.parse(error.message);
        if (errorMessage.errorCode && errorMessage.errorCode.httpCode === 404) {
          setShowErrorInfo(true); // 에러 상태 업데이트
        } else {
          // 다른 에러 처리 로직 (필요에 따라 추가)
          console.log("다른 에러 발생:", error);
        }
      }
    }
  };

  const handleClose = () => {
    setName("");
    setLoginCode("");
    setNameError("");
    setLoginCodeError("");
    setOrganName("");
    setTestDate("");
    setShowData(false);
    setShowErrorInfo(false);
    setShowAddErrorInfo(false);
    setButtonText("소속 검사 조회");
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={style.addChildModalOverlay}>
      <div className={style.addChildInfoModal}>
        <div className={style.closeBtnContainer}>
          <img src={CloseBtn} alt="closeBtn" className={style.closeBtn} onClick={handleClose} />
        </div>
        <div className={style.innerModalContainer}>
          <h3 className={style.addChildInfoTitle}>소속 검사 결과 추가하기</h3>
          <div className={style.addChildInfoDesc}>
            <span className={style.boldDesc}>소속을 통해 전달받은 로그인 코드와 이름을 입력해 주세요.</span>
            <br />
            소속을 통해 진행한 검사 결과를 연동할 경우 사용자 정보(이름, 연령 등)는 소속에서 등록한 정보를 따라갑니다.
          </div>
          <div className={style.innerTextContainer}>
            <div className={style.infoContainer}>
              <span className={style.infoText}>
                <span className={style.requireText}>[필수]</span> 이름
              </span>
              <input
                name="name"
                value={name}
                onChange={handleNameChange}
                placeholder="이름을 입력해 주세요."
                className={style.inputField}
              />
              {nameError && <div className={style.errorText}>{nameError}</div>}
            </div>
            <div className={style.infoContainer}>
              <span className={style.infoText}>
                <span className={style.requireText}>[필수]</span> 로그인 코드
              </span>
              <input
                name="loginCode"
                value={loginCode}
                onChange={handleLoginCodeChange}
                placeholder="로그인 코드를 입력해 주세요."
                className={style.inputField}
              />
              {loginCodeError && <div className={style.errorText}>{loginCodeError}</div>}
            </div>
          </div>

          {showData && (
            <div className={style.innerTextContainer}>
              <div className={style.infoContainer}>
                <span className={style.infoText}>소속 이름</span>
                <input
                  name="organName"
                  value={organName}
                  readOnly
                  className={`${style.inputField} ${style.aboutOrganText}`}
                />
              </div>
              <div className={style.infoContainer}>
                <span className={style.infoText}>검사 완료일</span>
                <input
                  name="testDate"
                  value={testDate}
                  readOnly
                  className={`${style.inputField} ${style.aboutOrganText}`}
                />
              </div>
            </div>
          )}

          {showErrorInfo && (
            <div className={`${style.innerTextContainer} ${style.noTestInfo}`}>
              소속 검사 결과가 없습니다.
              <br />
              이름과 로그인 코드를 확인 후 다시 조회해 주세요.
            </div>
          )}

          {showAddErrorInfo && (
            <div className={`${style.innerTextContainer} ${style.noTestInfo} ${style.aboutOrganText}`}>
              이미 추가 된 검사 결과입니다.
            </div>
          )}

          <button className={style.submitBtn} onClick={handleCheckupFetch}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCheckupInfoModal;
