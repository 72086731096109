import style from "../../../../assets/scss/sub/purchase/paymentResult.module.scss";
import { MainWrapper } from "../../MainWrapper";
import { CompletePayment } from "./CompletePayment";
import { useCallback, useEffect, useState } from "react";
import { FailedPayment } from "./FailedPayment";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetailAsync } from "../../../../store/order/orderSlice";
import { useAppDispatch, useAuth, useModal } from "../../../../hooks/hooks";
import { OrderDetail, OrderStatus } from "../../../../types/order";
import { ErrorType } from "../../../../types/error";
import { CancelPayment } from "./CancelPayment";
import { PaymentActions } from "./PaymentActions";

export function PaymentResult() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openAlertModal } = useModal();
  const { orderIdx } = useParams<"orderIdx">();
  const [orderDetail, setOrderDetail] = useState<OrderDetail | null>(null);

  const { isLoggedIn } = useAuth();

  const getOrder = useCallback(async () => {
    try {
      const orderDetail = await dispatch(getOrderDetailAsync(Number(orderIdx))).unwrap();
      setOrderDetail(orderDetail);
    } catch (error) {
      const errorObj: ErrorType = JSON.parse((error as Error).message);
      if (errorObj.errorCode.httpCode === 500) {
        openAlertModal(null);
      } else {
        navigate("/");
      }
    }
  }, [dispatch, navigate, openAlertModal, orderIdx]);
  useEffect(() => {
    if (!isLoggedIn) return;
    getOrder().then();
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const ResultNode = useCallback(() => {
    switch (orderDetail?.status) {
      case OrderStatus.PAID:
        return <CompletePayment type={"payment"} orderDetail={orderDetail} />;
      case OrderStatus.AWAITING_PAYMENT:
        return <CancelPayment />;
      case OrderStatus.FAIL:
        return <FailedPayment orderDetail={orderDetail} />;
      case OrderStatus.AWAITING_DEPOSIT:
        return <CompletePayment type={"order"} orderDetail={orderDetail} />;
      default:
        return <></>;
    }
  }, [orderDetail]);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITLE}`;
    window.scroll({ top: 0 });
  }, []);

  return (
    <>
      <MainWrapper showFooter={true} mainClassName={style.paymentResult}>
        <>
          <div className={style.topBg}></div>
          <div className={style.bottomBg}></div>
          <div className={style.contentArea}>
            <div className={`${style.content}`}>
              <ResultNode />
            </div>
            {orderDetail && <PaymentActions orderDetail={orderDetail} />}
          </div>
        </>
      </MainWrapper>
    </>
  );
}
