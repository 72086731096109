import detailDesktop01 from "../assets/images/sub/partnership/desktop/1.png";
import detailDesktop02 from "../assets/images/sub/partnership/desktop/2.png";
import detailDesktop03 from "../assets/images/sub/partnership/desktop/3.jpg";
import detailDesktop05 from "../assets/images/sub/partnership/desktop/5.jpg";
import detailDesktop06 from "../assets/images/sub/partnership/desktop/6.jpg";
import detailDesktop08 from "../assets/images/sub/partnership/desktop/8.png";
import detailDesktop09 from "../assets/images/sub/partnership/desktop/9.gif";
import detailDesktop10 from "../assets/images/sub/partnership/desktop/10.gif";
import detailDesktop12 from "../assets/images/sub/partnership/desktop/12.jpg";
import detailDesktop13 from "../assets/images/sub/partnership/desktop/13.jpg";
import detailDesktop14 from "../assets/images/sub/partnership/desktop/14.jpg";
import detailDesktop15 from "../assets/images/sub/partnership/desktop/15.jpg";
import detailDesktop16 from "../assets/images/sub/partnership/desktop/16.png";

import detailMobile01 from "../assets/images/sub/partnership/mobile/1.png";
import detailMobile02 from "../assets/images/sub/partnership/mobile/2.png";
import detailMobile03 from "../assets/images/sub/partnership/mobile/3.png";
import detailMobile04 from "../assets/images/sub/partnership/mobile/4.jpg";
import detailMobile06 from "../assets/images/sub/partnership/mobile/6.jpg";
import detailMobile07 from "../assets/images/sub/partnership/mobile/7.png";
import detailMobile08 from "../assets/images/sub/partnership/mobile/8.png";
import detailMobile09_1 from "../assets/images/sub/partnership/mobile/9_1.png";
import detailMobile09_2 from "../assets/images/sub/partnership/mobile/9_2.jpg";
import detailMobile10 from "../assets/images/sub/partnership/mobile/10.gif";
import detailMobile12 from "../assets/images/sub/partnership/mobile/12.jpg";
import detailMobile13 from "../assets/images/sub/partnership/mobile/13.gif";
import detailMobile14 from "../assets/images/sub/partnership/mobile/14.jpg";
import detailMobile15 from "../assets/images/sub/partnership/mobile/15.jpg";
import detailMobile16 from "../assets/images/sub/partnership/mobile/16.jpg";
import detailMobile17 from "../assets/images/sub/partnership/mobile/17.png";
import detailMobile18 from "../assets/images/sub/partnership/mobile/18.jpg";
import detailMobile19 from "../assets/images/sub/partnership/mobile/19.png";
import { useMemo } from "react";
import { DetailFileType, FileCategory } from "../types/file";

export function usePartnershipDetailImages() {
  const detailImagesOnDesktop: DetailFileType[] = useMemo(
    () => [
      { type: FileCategory.IMAGE, url: detailDesktop01 },
      { type: FileCategory.IMAGE, url: detailDesktop02 },
      { type: FileCategory.IMAGE, url: detailDesktop03 },
      { type: FileCategory.VIDEO, url: `${process.env.PUBLIC_URL}assets/video/partnership_detail_film.mp4` },
      { type: FileCategory.IMAGE, url: detailDesktop05 },
      { type: FileCategory.IMAGE, url: detailDesktop06 },
      { type: FileCategory.IMAGE, url: detailDesktop08 },
      { type: FileCategory.IMAGE, url: detailDesktop09 },
      { type: FileCategory.IMAGE, url: detailDesktop10 },
      { type: FileCategory.IMAGE, url: detailDesktop12 },
      { type: FileCategory.IMAGE, url: detailDesktop13 },
      { type: FileCategory.IMAGE, url: detailDesktop14 },
      { type: FileCategory.IMAGE, url: detailDesktop15 },
      { type: FileCategory.IMAGE, url: detailDesktop16 },
    ],
    []
  );

  const detailImagesOnMobile: DetailFileType[] = useMemo(
    () => [
      { type: FileCategory.IMAGE, url: detailMobile01 },
      { type: FileCategory.IMAGE, url: detailMobile02 },
      { type: FileCategory.IMAGE, url: detailMobile03 },
      { type: FileCategory.IMAGE, url: detailMobile04 },
      { type: FileCategory.VIDEO, url: `${process.env.PUBLIC_URL}assets/video/partnership_detail_film.mp4` },
      { type: FileCategory.IMAGE, url: detailMobile06 },
      { type: FileCategory.IMAGE, url: detailMobile07 },
      { type: FileCategory.IMAGE, url: detailMobile08 },
      { type: FileCategory.IMAGE, url: detailMobile09_1 },
      { type: FileCategory.IMAGE, url: detailMobile09_2 },
      { type: FileCategory.IMAGE, url: detailMobile10 },
      { type: FileCategory.IMAGE, url: detailMobile12 },
      { type: FileCategory.IMAGE, url: detailMobile13 },
      { type: FileCategory.IMAGE, url: detailMobile14 },
      { type: FileCategory.IMAGE, url: detailMobile15 },
      { type: FileCategory.IMAGE, url: detailMobile16 },
      { type: FileCategory.IMAGE, url: detailMobile17 },
      { type: FileCategory.IMAGE, url: detailMobile18 },
      { type: FileCategory.IMAGE, url: detailMobile19 },
    ],
    []
  );

  return {
    detailImagesOnDesktop,
    detailImagesOnMobile,
  };
}
