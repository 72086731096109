import {useCallback, useState} from "react";
import {SignupStatus} from "../../../SignupModal";
import {Address, Gender} from "../../../../../../types/user";
import useValidateBirthday from "./useValidateBirthday";

export enum ValidateStatus{
    UNFILLED = "UNFILLED", // 작성이나 체크 되지 않은 상태
    UNFILLED_DETAIL = "UNFILLED_DETAIL", // 상세 정보가 작성이나 체크 되지 않은 상태
    BELOW_REQUIRED_LENGTH = "BELOW_REQUIRED_LENGTH", // 길이가 부족한 상태
    OVER_REQUIRED_LENGTH = "OVER_REQUIRED_LENGTH", // 길이를 초과한 상태
    BELOW_REQUIRED_LENGTH_FOREIGN = "BELOW_REQUIRED_LENGTH_FOREIGN", // 해외 기준 길이가 부족한 상태
    LANGUAGE_MISMATCH = "LANGUAGE_MISMATCH", // 다른 언어로 작성한 상태
    UNFILLED_FOREIGN = "UNFILLED_FOREIGN", // 해외 city, address 정보 미작성 상태
    INVALID_BIRTHDATE = "INVALID_BIRTHDATE", // 유효하지 않은 생년월일 상태

    NONE = "NONE",
}

interface Args{
    loginStep: SignupStatus;
    nationality?: string;
    name?: string;
    phoneCode?: string;
    phoneNumber?: string;
    birthday?: string;
    gender?: Gender;
    address?: Address;
    isAgreementAge?: boolean;
    isAgreementTerms?: boolean;
    isAgreementPrivacy?: boolean;
}
export function useSignupValidator({
  loginStep, nationality, name,phoneCode, phoneNumber,
  birthday, gender, address,
  isAgreementAge, isAgreementTerms, isAgreementPrivacy
                                        }:Args){
    const [nationValidate, setNationValidate] = useState(ValidateStatus.NONE);
    const [nameValidate, setNameValidate] = useState(ValidateStatus.NONE);
    const [phoneCodeValidate, setPhoneCodeValidate] = useState(ValidateStatus.NONE);
    const [phoneNumberValidate, setPhoneNumberValidate] = useState(ValidateStatus.NONE);

    const [birthdayValidate, setBirthdayValidate] = useState(ValidateStatus.NONE);
    const [genderValidate, setGenderValidate] = useState(ValidateStatus.NONE);

    const [mandatoryAgreementValidate, setMandatoryAgreementValidate] = useState(ValidateStatus.NONE);
    // const regex = /^[a-zA-Z가-힣]*[aeiouAEIOUㄱ-ㅎㅏ-ㅣ]*$/;
    const regex = /^[a-zA-Z가-힣]+$/;

    const {validateBirthday} = useValidateBirthday();



    const validate = useCallback(() => {

        let status = true;
        if (loginStep === SignupStatus.Sign_UP_INFO_01) {

            // 국가 미선택
            if (!nationality) {
                setNationValidate(ValidateStatus.UNFILLED)
                status =  false;
            }

            // 이름 미작성
            if (!name) {
                setNameValidate(ValidateStatus.UNFILLED)
                status = false;
            }

            // 이름에 한글 or 영어 아닌경우
            if (name && !regex.test(name)) {
                setNameValidate(ValidateStatus.LANGUAGE_MISMATCH)
                status = false;
            }

            // 휴대폰 국가 미선택
            if (!phoneCode) {
                setPhoneCodeValidate(ValidateStatus.UNFILLED);
                status = false;
            }

            // 휴대폰 번호 미작성
            if (!phoneNumber && phoneCode) {
                setPhoneNumberValidate(ValidateStatus.UNFILLED);
                status = false;
            }

            // 휴대폰 번호 11자리 미만
            if (phoneNumber && phoneNumber.length < 11 && phoneCode && phoneCode === '+82') {
                setPhoneNumberValidate(ValidateStatus.BELOW_REQUIRED_LENGTH);
                status = false;
            }

            if (phoneNumber && phoneNumber.length > 12 && phoneCode && phoneCode === '+82') {
                setPhoneNumberValidate(ValidateStatus.OVER_REQUIRED_LENGTH);
                status = false;
            }

            // 국제 휴대폰 번호 5~15자리
            if (phoneNumber && (phoneNumber.length > 15 ||  phoneNumber.length < 5) && phoneCode !== '+82') {
                setPhoneNumberValidate(ValidateStatus.BELOW_REQUIRED_LENGTH_FOREIGN);
                status = false;
            }
            return status;
        }

        if (loginStep === SignupStatus.Sign_UP_INFO_02) {
            // 생년월일 미작성
            if (!birthday) {
                setBirthdayValidate(ValidateStatus.UNFILLED)
                status = false;
            }

            // 생년월일 8자리 미만
            if (birthday && birthday.length<8) {
                setBirthdayValidate(ValidateStatus.BELOW_REQUIRED_LENGTH)
                status = false;
            }

            if (birthday && birthday.length >= 8) {
                const result = validateBirthday(birthday);
                if (!result) {
                    setBirthdayValidate(ValidateStatus.INVALID_BIRTHDATE)
                    status = false;
                }

            }

            // 성별 미선택
            if (gender === Gender.NONE) {
                setGenderValidate(ValidateStatus.UNFILLED);
                status = false;
            }

            return status;
        }

        if (loginStep === SignupStatus.Sign_UP_INFO_03) {
            if (!(isAgreementAge && isAgreementTerms && isAgreementPrivacy)) {
                setMandatoryAgreementValidate(ValidateStatus.UNFILLED)
                status = false;
            }

            return status;
        }

        return true;
        // eslint-disable-next-line
    }, [
        nationality, name, phoneCode, phoneNumber,
        birthday, gender, address,
        isAgreementAge, isAgreementTerms, isAgreementPrivacy
    ]);

    return {validate,
        nationValidate, setNationValidate,
        nameValidate, setNameValidate,
        phoneCodeValidate, setPhoneCodeValidate,
        phoneNumberValidate, setPhoneNumberValidate,
        birthdayValidate, setBirthdayValidate,
        genderValidate, setGenderValidate,
        mandatoryAgreementValidate, setMandatoryAgreementValidate
    }
}