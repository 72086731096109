import { useAppSelector } from "../../../../../hooks/hooks";
import { reviewImagesState } from "../../../../../store/review/reviewModalSlice";
import { useFile } from "../../../../../hooks/file/useFile";
import { AddedFile, FileClassification, FileType, instanceOfFileType } from "../../../../../types/file";
import { useCallback } from "react";
import { instanceOfReviewImageDTO, ReviewImageDTO } from "../../../../../types/review";

export function useReviewImages() {
  const reviewImages = useAppSelector(reviewImagesState);
  const { uploadImages } = useFile();

  const uploadReviewImages = useCallback(async (): Promise<AddedFile[]> => {
    const uploadedImages = reviewImages
      .slice(0, reviewImages.length)
      .filter(instanceOfReviewImageDTO)
      .map((it) => it as ReviewImageDTO)
      .map((it) => it.file);

    const attachedImages = reviewImages
      .slice(0, reviewImages.length)
      .filter(instanceOfFileType)
      .map((it) => it as FileType)
      .map((it) => it.file);

    if (attachedImages.length === 0) return [...uploadedImages];
    const uploadedAttachedImages = (await uploadImages(attachedImages, FileClassification.KPASS_REVIEW_IMAGE)).map(
      (it) => {
        return {
          uri: it.uri,
          fileIdx: it.idx,
        };
      }
    );
    return [...uploadedImages, ...uploadedAttachedImages];
  }, [uploadImages, reviewImages]);

  return {
    uploadReviewImages,
  };
}
