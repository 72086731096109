import { RefObject, useCallback, useMemo } from "react";
import { Gender } from "../../../../../types/user";

export interface FieldValue<T, U> {
  value: T;
  ref: RefObject<U>;
}
interface Args {
  nameField: FieldValue<string, HTMLInputElement>;
  birthdayField: FieldValue<string, HTMLInputElement>;
  genderField: FieldValue<Gender | "", HTMLDivElement>;
  regionField: FieldValue<string, HTMLDivElement>;
  phoneNumberField: FieldValue<string, HTMLInputElement>;
  emailField: FieldValue<string, HTMLInputElement>;
  agreementPersonalInfoUsageField: FieldValue<boolean, HTMLDivElement>;
}

export function useEventRegistererValidator({
  nameField,
  birthdayField,
  genderField,
  regionField,
  phoneNumberField,
  emailField,
  agreementPersonalInfoUsageField,
}: Args) {
  const invalidNameMessage = useMemo((): string => {
    const name = nameField.value;
    if (!name) {
      return "자녀의 이름 또는 별명을 입력해 주세요.";
    }

    if (name.length < 2) {
      return "최소 2글자 이상 입력해 주세요.";
    }

    return "";
  }, [nameField.value]);

  const invalidBirthdayMessage = useMemo((): string => {
    const birthday = birthdayField.value;
    if (!birthday) {
      return "자녀의 생년월일을 입력해 주세요.";
    }

    if (birthday.length < 8) {
      return "생년월일을 올바르게 입력해 주세요.";
    }

    const dashedDateString = `${birthday.substring(0, 4)}-${birthday.substring(4, 6)}-${birthday.substring(6, 8)}`;
    if (Number.isNaN(Date.parse(dashedDateString))) {
      return "생년월일을 올바르게 입력해 주세요.";
    }

    const birthdayDate = new Date(dashedDateString);
    if (birthdayDate < new Date("2011-01-01") || new Date("2019-12-31") < birthdayDate) {
      return "만 4세 ~ 12세의 아동만 신청 가능합니다.";
    }

    return "";
  }, [birthdayField.value]);

  const invalidGenderMessage = useMemo(() => {
    const gender = genderField.value;
    if (!gender) {
      return "자녀의 성별을 선택해 주세요.";
    }
    return "";
  }, [genderField.value]);

  const invalidRegionMessage = useMemo(() => {
    const region = regionField.value;
    if (!region) {
      return "거주 지역을 선택해 주세요.";
    }
    return "";
  }, [regionField.value]);

  const invalidPhoneNumberMessage = useMemo(() => {
    const phoneNumber = phoneNumberField.value;
    if (!phoneNumber) {
      return "부모님 휴대폰 번호를 입력해 주세요.";
    }
    if (phoneNumber.length < 10) {
      return "휴대폰 번호 10~11자리를 올바르게 입력해 주세요.";
    }
    return "";
  }, [phoneNumberField.value]);

  const invalidEmailMessage = useMemo(() => {
    const email = emailField.value;

    if (email) {
      if (email.length < 6) return "최소 6글자 이상 입력해 주세요.";

      const emailRegexp = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{1,10}$/g);
      if (!emailRegexp.test(email)) {
        return "잘못된 형식의 이메일 주소입니다.";
      }
    }

    return "";
  }, [emailField.value]);

  const invalidAgreementMessage = useMemo(() => {
    const isAgreed = agreementPersonalInfoUsageField.value;
    if (isAgreed === false) return "미동의 시 이벤트 참여가 불가능합니다.";

    return "";
  }, [agreementPersonalInfoUsageField.value]);

  const validate = useCallback(() => {
    if (invalidNameMessage) {
      nameField.ref.current?.focus();
      return false;
    }

    if (invalidBirthdayMessage) {
      birthdayField.ref.current?.focus();
      return false;
    }

    if (invalidGenderMessage) {
      genderField.ref.current?.scrollIntoView();
      return false;
    }

    if (invalidRegionMessage) {
      regionField.ref.current?.scrollIntoView();
      return false;
    }

    if (invalidPhoneNumberMessage) {
      phoneNumberField.ref.current?.focus();
      return false;
    }

    if (invalidEmailMessage) {
      emailField.ref.current?.focus();
      return false;
    }

    if (invalidAgreementMessage) {
      agreementPersonalInfoUsageField.ref.current?.scrollIntoView();
      return false;
    }

    return true;
  }, [
    invalidNameMessage,
    nameField.ref,
    invalidBirthdayMessage,
    birthdayField.ref,
    invalidGenderMessage,
    genderField.ref,
    invalidRegionMessage,
    regionField.ref,
    invalidPhoneNumberMessage,
    phoneNumberField.ref,
    invalidEmailMessage,
    emailField.ref,
    invalidAgreementMessage,
    agreementPersonalInfoUsageField.ref,
  ]);

  return {
    invalidNameMessage,
    invalidBirthdayMessage,
    invalidGenderMessage,
    invalidRegionMessage,
    invalidPhoneNumberMessage,
    invalidEmailMessage,
    invalidAgreementMessage,
    validate,
  };
}
