import { useCallback } from "react";

export function useErrorType() {
  const getErrorObject = useCallback((e: string) => {
    try {
      return JSON.parse(e);
    } catch (error) {
      console.error("parsing error : ", error);
      throw new Error("parsing error");
    }
  }, []);

  return {
    getErrorObject,
  };
}
