import { useCallback, useEffect, useMemo, useState } from "react";
import { SlideGap } from "./slider";
import { useTouchEvent } from "../../../hooks/useTouchEvent";
import { useInterval } from "../../../hooks/useInterval";

export enum SliderDirection {
  PREV = "PREV",
  NEXT = "NEXT",
}
interface Args {
  slides: JSX.Element[];
  slideWidth: number;
  slideGap?: SlideGap;
  delay: number;
  additionalFunctions?: {
    moveNext: () => void;
    movePrev: () => void;
  };
  blockSwipeOnTransitioning: boolean;
}

export const AUTO_PLAY_DELAY = 4000;

export function useSlider({
  slides,
  slideWidth,
  slideGap,
  delay,
  additionalFunctions,
  blockSwipeOnTransitioning,
}: Args) {
  const slidesLength = useMemo(() => slides.length, [slides]);
  const slideTotalWidth = useMemo(() => slideWidth + (slideGap?.totalGap ?? 0), [slideWidth, slideGap?.totalGap]);

  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(0);
  const [translateXValue, setTranslateXValue] = useState(slideTotalWidth * -1 * slidesLength);
  const [transitionDelay, setTransitionDelay] = useState(0);
  const [isAutoPlayPaused, setIsAutoPlayPaused] = useState(false);
  const [direction, setDirection] = useState(SliderDirection.NEXT);

  useEffect(() => {
    setTranslateXValue(slideTotalWidth * -1 * slidesLength);
  }, [slideTotalWidth, slidesLength]);

  const getNextSlideIdx = useCallback((currentIdx: number) => (currentIdx + 1) % slidesLength, [slidesLength]);
  const getPrevSlideIdx = useCallback(
    (currentIdx: number) => (currentIdx === 0 ? slidesLength - 1 : currentIdx - 1),
    [slidesLength]
  );

  const moveSlideBySlideIdx = useCallback(
    (slideIdx: number, hasTransitionDelay: boolean, hasTimeout: boolean) => {
      setTimeout(
        () => {
          setTransitionDelay(hasTransitionDelay ? delay : 0);
          setTranslateXValue(-1 * slideTotalWidth * slideIdx - slideTotalWidth * slidesLength);
          setCurrentSlideIdx(slideIdx);
        },
        hasTimeout ? delay : 0
      );
    },
    [slideTotalWidth, delay, slidesLength]
  );

  const showNextFrame = useCallback(() => {
    setTransitionDelay(delay);
    setTranslateXValue(translateXValue + slideTotalWidth * -1);
    moveSlideBySlideIdx(getNextSlideIdx(currentSlideIdx), false, true);
  }, [translateXValue, currentSlideIdx, moveSlideBySlideIdx, getNextSlideIdx, slideTotalWidth, delay]);

  const showPrevFrame = useCallback(() => {
    setTransitionDelay(delay);
    setTranslateXValue(translateXValue + slideTotalWidth);
    moveSlideBySlideIdx(getPrevSlideIdx(currentSlideIdx), false, true);
  }, [translateXValue, currentSlideIdx, moveSlideBySlideIdx, getPrevSlideIdx, slideTotalWidth, delay]);

  const moveSlide = useCallback(
    (direction: SliderDirection) => {
      setDirection(direction);
      switch (direction) {
        case SliderDirection.NEXT:
          additionalFunctions?.moveNext();
          showNextFrame();
          return;
        case SliderDirection.PREV:
          additionalFunctions?.movePrev();
          showPrevFrame();
          return;
      }
    },
    // eslint-disable-next-line
    [showNextFrame, showPrevFrame, additionalFunctions?.moveNext, additionalFunctions?.movePrev]
  );

  const pauseAutoPlay = useCallback(() => {
    setIsAutoPlayPaused(true);
    setTimeout(() => setIsAutoPlayPaused(false), AUTO_PLAY_DELAY);
  }, [setIsAutoPlayPaused]);

  const { handleTouchEndOnX, handleTouchStart, handleTouchMove } = useTouchEvent({
    moveRight: () => {
      if (!blockSwipeOnTransitioning || (blockSwipeOnTransitioning && !isTransitioning)) {
        pauseAutoPlay();
        moveSlide(SliderDirection.NEXT);
      }
    },
    moveLeft: () => {
      if (!blockSwipeOnTransitioning || (blockSwipeOnTransitioning && !isTransitioning)) {
        pauseAutoPlay();
        moveSlide(SliderDirection.PREV);
      }
    },
  });

  const isTransitioning = useMemo(() => transitionDelay > 0, [transitionDelay]);

  useInterval({
    callback: () => {
      moveSlide(SliderDirection.NEXT);
    },
    delay: AUTO_PLAY_DELAY,
    isPaused: isAutoPlayPaused,
  });

  useEffect(() => {
    if (slides.length === 1) {
      setIsAutoPlayPaused(true);
    }
  }, [slides]);

  return {
    moveSlide,
    currentSlideIdx,
    moveSlideBySlideIdx,
    translateXValue,
    transitionDelay,
    getNextSlideIdx,
    getPrevSlideIdx,
    handleTouch: {
      start: handleTouchStart,
      move: handleTouchMove,
      end: handleTouchEndOnX,
    },
    // handleDrag: {
    //   start: handleDragStart,
    //   end: handleDragEnd,
    // },
    pauseAutoPlay,
    isTransitioning,
    direction,
  };
}
