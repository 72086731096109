import { MainApi } from "./MainApi";
import { LoginInfo, RequestCodeDTO } from "../types/auth";

export class AuthApi {
  static baseUrl = `${MainApi.urlPrefix}/auth`;

  static isExistedUser = (email: string) => () =>
    MainApi.api.post(`${AuthApi.baseUrl}/user/sign-check`, { email: email });

  static requestCode = (dto: RequestCodeDTO) => () => MainApi.api.post(`${AuthApi.baseUrl}/user/request-code`, dto);

  static signIn =
    ({ email, code }: LoginInfo) =>
    () =>
      MainApi.api.post(`${AuthApi.baseUrl}/user/sign-in`, { email, code });

  static getAccessTokenFromRefreshToken = (refreshToken: string) => () => {
    const mainApi = MainApi.getInstance();
    mainApi.setToken(refreshToken);
    return mainApi.instance.get(`${AuthApi.baseUrl}/user/access-token`);
  };

  static getPhoneNumberByEmail = (email: string) => () =>
    MainApi.api.post(`${AuthApi.baseUrl}/user/phone-number-by-email`, { email });



  // 회원가입 이메일 인증
  static confirmEmail = ({ email, code }: LoginInfo) => () =>
      MainApi.api.post(`${AuthApi.baseUrl}/user/email-confirm`, { email, code });
}
