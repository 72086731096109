import styles from "../../../../assets/scss/common/modal/loginModal.module.scss";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {isExistedUserAsync} from "../../../../store/login/loginSlice";
import {useAppDispatch, useModal, useUserAgent} from "../../../../hooks/hooks";
import {LoginStep} from "../LoginModal";
import {Timer} from "./Timer";
import {useCodeSender} from "./hooks/useCodeSender";
import {RequestCodeType} from "../../../../types/auth";
import {ModalType} from "../../../../types/common";

interface Props {
  email: string;
  incorrectCodeCount: number;
  setIncorrectCodeCount: (_: number) => void;
  setEmail: (_: string) => void;
  changeLoginStep: (_: LoginStep) => void;
  isBackAfterSendingCode: boolean;
  setIsBackAfterSendingCode: (_: boolean) => void;
}

export function EmailStep({
  email,
  incorrectCodeCount,
  setIncorrectCodeCount,
  setEmail,
  changeLoginStep,
  isBackAfterSendingCode,
  setIsBackAfterSendingCode,
}: Props) {
  // const SEND_BLOCK_SEC = 2;
  const SEND_BLOCK_SEC = 30;
  const dispatch = useAppDispatch();
  const emailFormRef = useRef<HTMLFormElement>(null);
  const { openAlertModal, openModal, openConfirmModal, closeModal } = useModal();

  const [alertMessage, setAlertMessage] = useState("");
  const [canClickSendBtn, setCanClickSendBtn] = useState(true);

  const { sendCode } = useCodeSender();
  const isExistedUser = useCallback(
    async (emailAddr: string) => {
      try {
        const result = await dispatch(isExistedUserAsync(emailAddr)).unwrap();
        return result;
      } catch (error) {
        const errorObj: any = JSON.parse((error as Error).message);
        switch (errorObj?.errorCode?.httpCode) {
          case 477:
            setAlertMessage("해당 계정은 이미 탈퇴한 계정입니다.\n다른 이메일주소로 재시도해주세요.");
            break;
          case 472:
            setAlertMessage("현재 차단된 계정입니다.\n고객센터로 문의바랍니다.");
            break;
          default:
            openAlertModal(null);
        }

        throw error;
      }
    },
    [dispatch, openAlertModal]
  );

  const onClickSendingLoginCodeBtn = () => {
    if (canClickSendBtn) {
      const trimmedEmail = email.trim();
      if (trimmedEmail === "") {
        setAlertMessage("이메일을 입력해주세요.");
        return;
      }

      const isValidEmailForm = (emailFormRef.current as HTMLFormElement).reportValidity();
      if (!isValidEmailForm) {
        setAlertMessage("잘못된 형식의 이메일주소입니다. 다시 입력해주세요!");
        return;
      }
      isExistedUser(trimmedEmail).then(({result}: { result: boolean }) => {
        if (result) {
          changeLoginStep(LoginStep.AUTH_WAY);
        } else {
          openConfirmModal(
              {
                title: "회원가입 안내",
                desc: "해당 이메일은\n" +
                    "회원가입이 되어 있지 않습니다.\n" +
                    "회원가입을 진행할까요?",
              },
              () => {
                sendCode(email, RequestCodeType.EMAIL)
                    .then()
                    .catch();
                localStorage.setItem('email', email)
                closeModal();
                openModal(ModalType.SIGN_UP)
              }
          );
        }
      });

    }
  };

  useEffect(() => {
    if (!isBackAfterSendingCode) {
      return;
    }

    setCanClickSendBtn(false);

    if (incorrectCodeCount >= 5) {
      setAlertMessage("인증코드를 5회 잘못입력하셨습니다. 잠시후 시도해주세요.");
    } else {
      setAlertMessage("잠시후 시도해주세요.");
    }
  }, [isBackAfterSendingCode, incorrectCodeCount]);

  const doAtTheEndTimer = useCallback(() => {
    setCanClickSendBtn(true);
    setIncorrectCodeCount(0);
    setAlertMessage("");
    setIsBackAfterSendingCode(false);
  }, [setIncorrectCodeCount, setIsBackAfterSendingCode]);

  const {isDesktop, isTablet} = useUserAgent();

  const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value.replace(/\s/g, ""); // 모든 공백 제거
    setEmail(emailValue);
  };

  return (
    <div className={styles.textDesc}>
      <div className={styles.desc}>
        {isDesktop || isTablet ? (
          <>
            <span className={styles.accent}>로그인 또는 회원가입 할 이메일을 입력해 주세요!</span>
            <br />
            K-PASS와 D-CAS는 동일한 이메일 계정을 사용할 수 있습니다.
          </>
        ) : (
          <>
            <span className={styles.accent}>
              로그인 또는 회원가입 할 이메일을
              <br />
              입력해 주세요!
            </span>
            <br />
            K-PASS와 D-CAS는 동일한 이메일 계정을
            <br />  사용할 수 있습니다.
          </>
        )}
      </div>
      <form className={styles.emailForm} onSubmit={(e) => e.preventDefault()} ref={emailFormRef}>
        <input
          type="email"
          className={styles.input}
          value={email}
          name="email"
          onInvalid={(e) => e.preventDefault()}
          onChange={handleSetEmail}
          placeholder={"이메일 입력"}
        ></input>
        {canClickSendBtn ? (
          <></>
        ) : (
          <Timer
            initialMilliseconds={SEND_BLOCK_SEC * 1000}
            styleClassName={styles.timer}
            doAtTheEnd={doAtTheEndTimer}
          />
        )}
        {alertMessage ? <span className={styles.invalidEmailAddr}>{alertMessage}</span> : <></>}
        <button className={styles.button} onClick={onClickSendingLoginCodeBtn}>
          다음
        </button>
      </form>
    </div>
  );
}
