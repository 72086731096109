import modalStyle from "../../../assets/scss/common/modal/modal.module.scss";
import style from "../../../assets/scss/common/modal/termsModal.module.scss";
import { Modal } from "./Modal";
import { useCallback, useEffect, useState } from "react";
import { ModalType } from "../../../types/common";
import { Terms, TermsCategory } from "../../../types/terms";
import { getTermsDetailAsync, termsStatus } from "../../../store/terms/termsSlice";
import { useAppDispatch, useAppSelector, useLoader, useModal } from "../../../hooks/hooks";

interface Props {
  category: TermsCategory;
}

export function TermsModal({ category }: Props) {
  const status = useAppSelector(termsStatus);
  const dispatch = useAppDispatch();
  const { showModal, closeModal, openAlertModal } = useModal();
  const { setLoaderStatus } = useLoader();
  const [terms, setTerms] = useState<Terms | null>(null);

  const getTermsDetail = useCallback(async () => {
    try {
      const termsDetail: Terms = await dispatch(getTermsDetailAsync(category)).unwrap();
      setTerms(termsDetail);
    } catch (error) {
      console.error(error);
      openAlertModal(null);
    }
  }, [dispatch, openAlertModal, category]);

  useEffect(() => {
    setLoaderStatus([status]);
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    if (showModal === ModalType.TERMS) {
      if (category) {
        getTermsDetail().then();
      }
    }
    // eslint-disable-next-line
  }, [showModal, category]);

  return (
    <Modal
      showModal={showModal === ModalType.TERMS}
      title={terms?.title ?? ""}
      closeModalFunction={() => closeModal()}
      modalBgStyle={modalStyle.whiteBg}
      modalCloseButtonStyle={modalStyle.whiteBg}
      modalOtherStyle={style.termsModal}
    >
      <div className={style.termsContent} dangerouslySetInnerHTML={{ __html: terms?.content ?? "" }}></div>
    </Modal>
  );
}
