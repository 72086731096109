import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { getTestHistoryCountAsync } from "../../../../store/test_history/testHistorySlice";

export function useTestHistoryCountGetter() {
  const dispatch = useAppDispatch();

  const getTestHistoryCount = useCallback(async () => {
    try {
      const { result }: { result: number } = await dispatch(getTestHistoryCountAsync()).unwrap();
      return result;
    } catch (e) {
      console.error(e);
      throw new Error("getTestHistoryCount 에러");
    }
  }, [dispatch]);

  return {
    getTestHistoryCount,
  };
}
