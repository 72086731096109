import style from "./review.module.scss";
import { ReviewSlider } from "../../common/slider/ReviewSlider";
import { useModal, useUserAgent } from "../../../hooks/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import { Trans, useTranslation } from "react-i18next";
import { useTestHistoryCountGetter } from "./hooks/useTestHistoryCountGetter";

export function ReviewSection1() {
  const { t } = useTranslation(["subRealReview"]);
  const sliderRef = useRef<HTMLDivElement>(null);
  const { isDesktop } = useUserAgent();
  const { isTablet } = useUserAgent();
  const { openReviewDetailModal } = useModal();
  const { getTestHistoryCount } = useTestHistoryCountGetter();
  const [testHistoryCount, setTestHistoryCount] = useState<number>(0);

  useEffect(() => {
    getTestHistoryCount().then((count) => setTestHistoryCount(count));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sliderRect = useMemo(
    () => sliderRef.current?.getBoundingClientRect(),
    // eslint-disable-next-line
    [sliderRef.current]
  );
  const sliderWidth = useMemo(() => sliderRect?.width ?? 0, [sliderRect]);
  const sliderHeight = useMemo(() => sliderRect?.height ?? 0, [sliderRect]);

  const CountComponent = useCallback(
    () => (
      <span className={style.section1Part1Text1Line1}>
        <span className={style.point}>
          <CountUp end={testHistoryCount} duration={1} />
          {t("countOfPerson")}
        </span>
      </span>
    ),
    [t, testHistoryCount]
  );

  return (
    <section className={style.section1}>
      <div className={style.section1Part1}>
        <p className={style.section1Part1Text1}>
          <Trans
            t={t}
            i18nKey={"section1.text1LineEdit"}
            components={[
              <CountComponent />,
              <span className={style.section1Part1Text1Line1} />,
              <span className={style.section1Part1Text1Line2} />,
            ]}
          />
        </p>
        <p className={style.section1Part1Text2}>
          <Trans t={t} i18nKey={"section1.text2"} />
        </p>
      </div>
      <div className={style.section1Part2} ref={sliderRef}>
        <ReviewSlider
          isArticleSlider={false}
          isSubpageSlider={true}
          hasBulletIndicator={true}
          slideWidth={isDesktop ? 592 : isTablet ? 300 : sliderWidth}
          slideHeight={isDesktop ? 840 : sliderHeight}
          delay={800}
          slideGap={{ totalGap: isDesktop ? 160 : isTablet ? 102 :  14, mainWidth: isDesktop ? 592 : isTablet ? 300 :  sliderWidth }}
          slideClassName={{
            wrapper: style.sliderWrapper,
            notCurrentSlide: style.notCurrentSlide,
            currentSlide: style.currentSlide,
            show: style.show,
            hide: style.hide,
          }}
          onClickSlide={(reviewIdx: number) => openReviewDetailModal(reviewIdx, true)}
        />
      </div>
    </section>
  );
}
