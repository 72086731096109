import React, {useCallback, useEffect, useState} from "react";
import style from "../../../assets/scss/common/modal/addChildModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import {useAppDispatch, useModal, useUserAgent} from "../../../hooks/hooks";
import useValidation from "../../../hooks/useValidation";
import {createProfileAsync} from "../../../store/profile/profileSlice";
import {
  Address,
  CountryCode,
  CountryFilterValue,
  CountryName,
  InitialProfileInfoDTO,
  NewUserProfile,
  ProfileErrorDTO
} from "../../../types/user";
import {ChildNameField} from "./mypage/ChildNameField";
import {ChildAgeField} from "./mypage/ChildAgeField";
import {ChildGenderField} from "./mypage/ChildGenderField";
import {countryCodeMap} from "../../../util/commonFunctions";
import {ChildAddressField} from "./mypage/ChildAddressField";

interface AddChildModalProps {
  isOpen: boolean;
  onClose: () => void;
  onProfileAdded: () => void; // 프로필 추가 콜백
  parentAddress?: Address; // New prop to receive the parent's address
}

const AddChildModal: React.FC<AddChildModalProps> = ({ isOpen, onClose, onProfileAdded, parentAddress }) => {
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryFilterValue>(CountryFilterValue.NONE);
  const { isMobile } = useUserAgent();

  const initialProfileInfo = {
    name: "",
    birthday: "",
    gender: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  };

  const [profileInfo, setProfileInfo] = useState<InitialProfileInfoDTO>(initialProfileInfo);
  const { validateName, validateBirthday, validateGender } =
    useValidation();
  const [errors, setErrors] = useState<ProfileErrorDTO>({
    name: "",
    birthday: "",
    gender: "",
  });

  const { openConfirmModal, openAlertModal } = useModal();

  const getCountryCode = (selectedCountry: CountryName): CountryCode => {
    return countryCodeMap[selectedCountry];
  };

  const validateProfileInfo = (profile: InitialProfileInfoDTO) => {
    const validationErrors = {
      name: validateName(profile.name),
      birthday: validateBirthday(profile.birthday),
      gender: validateGender(profile.gender),
    };
    return validationErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validateProfileInfo(profileInfo);
    setErrors(validationErrors);

    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    createProfile({
      name: profileInfo.name,
      birthday: `${profileInfo.birthday.slice(0, 4)}-${profileInfo.birthday.slice(4, 6)}-${profileInfo.birthday.slice(
        6
      )}`,
      gender: profileInfo.gender,
      address: {
        address1: profileInfo.address1,
        address2: profileInfo.address2,
        address3: profileInfo.address3,
        city: profileInfo.city,
        state: profileInfo.state,
        country: getCountryCode(selectedCountry),
        postalCode: profileInfo.postalCode,
      },
    })
      .then(onProfileAdded)
      .then(onClose);

    openAlertModal({
      title: "프로필(자녀) 추가 안내",
      desc: "프로필이 추가 되었습니다.",
    });
  };

  const createProfile = useCallback(
    async (profileInfo: NewUserProfile) => {
      try {
        const response = await dispatch(createProfileAsync(profileInfo)).unwrap();
        console.log("create profile!", response, profileInfo);
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isOpen) {
      setProfileInfo(initialProfileInfo);
      setErrors({
        name: "",
        birthday: "",
        gender: "",
      });
      setSelectedCountry(CountryFilterValue.NONE);
      setIsChecked(false);
    }
    // eslint-disable-next-line
  }, [isOpen, parentAddress]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={style.addChildModalOverlay}>
      <div className={style.addChildInfoModal}>
        <div className={style.closeBtnContainer}>
          <img
            src={CloseBtn}
            alt="closeBtn"
            className={style.closeBtn}
            onClick={() => {
              openConfirmModal(
                {
                  title: "프로필(자녀) 추가 안내",
                  desc: "해당 창을 닫을 경우\n입력한 프로필(자녀) 정보가 모두 사라집니다.\n창을 정말 닫으시겠습니까?",
                },
                () => {
                  onClose();
                }
              );
            }}
          />
        </div>
        <div className={style.innerModalContainer}>
          <h3 className={style.addChildInfoTitle}>프로필(자녀) 추가</h3>
          <div className={style.addChildInfoDesc}>
            <span className={style.boldDesc}>
              검사 진행을 위한 필수 정보이므로 본명 및 실제 정보 입력을 권장합니다.
            </span>
            <br />
            검사 후 정보를 수정해도 결과지의 정보는 변경되지 않으니
            {isMobile ? " " : <br />}
            검사 전에 정보를 명확히 입력해 주세요!
          </div>
          <div className={style.innerTextContainer}>
            <ChildNameField profileInfo={profileInfo} setProfileInfo={setProfileInfo} errors={errors}/>

            <ChildAgeField profileInfo={profileInfo} setProfileInfo={setProfileInfo} errors={errors}/>

            <ChildGenderField
                profileInfo={profileInfo} setProfileInfo={setProfileInfo}
                errors={errors} setErrors={setErrors}/>

            <ChildAddressField profileInfo={profileInfo} setProfileInfo={setProfileInfo}
                               parentAddress={parentAddress}
                               selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
                               isChecked={isChecked} setIsChecked={setIsChecked}
            />

          </div>
          <button className={style.submitBtn} onClick={handleSubmit}>
            등록하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddChildModal;
