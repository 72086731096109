import React, {useCallback, useEffect, useState} from "react";
import style from "../../../assets/scss/common/modal/addChildModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import {useAppDispatch, useModal} from "../../../hooks/hooks";
import useValidation from "../../../hooks/useValidation";
import {deleteProfileAsync, editProfileAsync} from "../../../store/profile/profileSlice";
import {
    Address,
    CountryCode,
    CountryFilterValue,
    CountryName,
    InitialProfileInfoDTO,
    NewUserProfile
} from "../../../types/user";
import {ChildNameField} from "./mypage/ChildNameField";
import {ChildAgeField} from "./mypage/ChildAgeField";
import {ChildGenderField} from "./mypage/ChildGenderField";
import {countryCodeMap, getCountryFilterValue} from "../../../util/commonFunctions";
import {ChildAddressField} from "./mypage/ChildAddressField";


interface ProfileInfo {
    name?: string;
    birthday?: string;
    gender?: string;
    address?: Address;
    idx?: number;
}

interface EditChildModalProps {
    isOpen: boolean;
    onClose: () => void;
    profileInfo: ProfileInfo | null;
    onProfileAdded: () => void; // 프로필 추가 콜백
    parentAddress?: Address;
    editChildModalData: {
        testCntExists: boolean;
        vouchersAvailable: boolean;
    };
}

const formatBirthday = (birthday: string) => {
    // Convert from 'YYYY-MM-DD' to 'YYYYMMDD'
    return birthday.replace(/-/g, "");
};

const EditChildModal: React.FC<EditChildModalProps> = (
    {
        isOpen,
        onClose,
        profileInfo,
        onProfileAdded,
        parentAddress,
        editChildModalData,
    }
) => {
    const [isChecked, setIsChecked] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<CountryFilterValue>(CountryFilterValue.NONE);
    const [tempProfileInfo, setTempProfileInfo] = useState<InitialProfileInfoDTO>({
        name: profileInfo?.name || "",
        birthday: profileInfo?.birthday ? formatBirthday(profileInfo.birthday) : "",
        gender: profileInfo?.gender || "",
        address1: profileInfo?.address?.address1 || "",
        address2: profileInfo?.address?.address2 || "",
        city: profileInfo?.address?.city || "",
        state: profileInfo?.address?.state || "",
        address3: profileInfo?.address?.address3 || "",
        country: profileInfo?.address?.country || "",
        postalCode: profileInfo?.address?.postalCode || "",
    });

    const resetProfileInfo = () => {
        setTempProfileInfo({
            name: profileInfo?.name || "",
            birthday: profileInfo?.birthday ? formatBirthday(profileInfo.birthday) : "",
            gender: profileInfo?.gender || "",
            address1: profileInfo?.address?.address1 || "",
            address2: profileInfo?.address?.address2 || "",
            address3: profileInfo?.address?.address3 || "",
            city: profileInfo?.address?.city || "",
            state: profileInfo?.address?.state || "",
            country: selectedCountry,
            postalCode: profileInfo?.address?.postalCode || "",
        });
        const countryName = profileInfo?.address === undefined
            ? CountryFilterValue.NONE
            : getCountryFilterValue(profileInfo.address.country)

        setSelectedCountry(countryName);
        setIsChecked(false); // Reset the checkbox
    };

    const getCountryCode = (selectedCountry: CountryName): CountryCode => {
        return countryCodeMap[selectedCountry];
    };

    useEffect(() => {
        if (profileInfo) {
            // Update tempProfileInfo and selectedCountry
            resetProfileInfo()
        }
        // eslint-disable-next-line
    }, [profileInfo]);

    const {validateName, validateBirthday, validateGender} =
        useValidation();
    const [errors, setErrors] = useState({
        name: "",
        birthday: "",
        gender: "",
    });

    const {openConfirmModal, openAlertModal} = useModal();
    const dispatch = useAppDispatch();

    const validateProfileInfo = (profile: typeof tempProfileInfo) => {
        return {
            name: validateName(profile.name),
            birthday: validateBirthday(profile.birthday),
            gender: validateGender(profile.gender),
        };
    };

    const handleSubmit = () => {
        const validationErrors = validateProfileInfo(tempProfileInfo);
        setErrors(validationErrors);

        if (Object.values(validationErrors).some((error) => error)) {
            return;
        }
        // 프로필 수정
        const data = {
            name: tempProfileInfo.name,
            birthday: `${tempProfileInfo.birthday.slice(0, 4)}-${tempProfileInfo.birthday.slice(
                4,
                6
            )}-${tempProfileInfo.birthday.slice(6)}`,
            gender: tempProfileInfo.gender,
            address: {
                address1: tempProfileInfo.address1,
                address2: tempProfileInfo.address2,
                city: tempProfileInfo.city,
                state: tempProfileInfo.state,
                country: getCountryCode(selectedCountry),
                postalCode: tempProfileInfo.postalCode,
            },
        };

        if (profileInfo?.idx) editProfile(profileInfo?.idx, data).then(onProfileAdded).then(onClose);

        openAlertModal({
            title: "프로필(자녀) 수정 안내",
            desc: "프로필이 수정 되었습니다.",
        });
    };

    const deleteProfile = useCallback(
        async (profileIdx: number) => {
            try {
                const response = await dispatch(deleteProfileAsync(profileIdx)).unwrap();
                console.log("delete profile!", response);
            } catch (error) {
                console.log(error);
            }
        },
        [dispatch]
    );

    const editProfile = useCallback(
        async (profileIdx: number, param: NewUserProfile) => {
            try {
                const response = await dispatch(editProfileAsync({profileIdx, param})).unwrap();
                console.log("Profile edited successfully!", response);
            } catch (error) {
                console.log(error);
            }
        },
        [dispatch]
    );

    const handleRemove = () => {
        // 소속이용권을 보유중인 프로필을 삭제 로직 -> 삭제 불가능
        if (editChildModalData?.vouchersAvailable) {
            openAlertModal({
                title: "프로필(자녀) 삭제 안내",
                desc: "소속이용권이 부여된 프로필 입니다.\n소속이용권을 보유중인 프로필은 삭제할 수 없습니다.\n소속관리자에게 문의해 주세요.",
            });
        } else {
            // 검사 내역이 있는 프로필 삭제 로직 -> 추가 컨펌창
            if (editChildModalData?.testCntExists) {
                openConfirmModal(
                    {
                        title: "프로필(자녀) 삭제 안내",
                        desc: "검사 내역이 있는 프로필 입니다.\n프로필 삭제 시 모든 정보는 삭제됩니다.\n프로필을 정말 삭제하시겠습니까?",
                    },
                    () => {
                        if (profileInfo?.idx) deleteProfile(profileInfo?.idx).then(onProfileAdded).then(onClose);
                        openAlertModal({
                            title: "프로필(자녀) 삭제 안내",
                            desc: "프로필이 삭제 되었습니다.",
                        });
                    }
                );
            } else {
                openConfirmModal(
                    {
                        title: "프로필(자녀) 삭제 안내",
                        desc: "정말 프로필을 삭제 하시겠습니까?\n프로필 삭제 시 모든 정보는 삭제되며,\n삭제된 정보는 복구할 수 없습니다.",
                    },
                    () => {
                        if (profileInfo?.idx) deleteProfile(profileInfo?.idx).then(onProfileAdded).then(onClose);
                        openAlertModal({
                            title: "프로필(자녀) 삭제 안내",
                            desc: "프로필이 삭제 되었습니다.",
                        });
                    }
                );
            }
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={style.addChildModalOverlay}>
            <div className={style.addChildInfoModal}>
                <div className={style.closeBtnContainer}>
                    <img
                        src={CloseBtn}
                        alt="closeBtn"
                        className={style.closeBtn}
                        onClick={() => {
                            openConfirmModal(
                                {
                                    title: "프로필(자녀) 수정 안내",
                                    desc: "해당 창을 닫을 경우\n입력한 프로필(자녀) 정보가 모두 사라집니다.\n창을 정말 닫으시겠습니까?",
                                },
                                () => {
                                    resetProfileInfo();
                                    onClose();
                                }
                            );
                        }}
                    />
                </div>
                <div className={style.innerModalContainer}>
                    <h3 className={style.addChildInfoTitle}>프로필(자녀) 수정</h3>
                    <div className={style.addChildInfoDesc}>
                    <span className={style.boldDesc}>
                      검사 진행을 위한 필수 정보이므로 본명 및 실제 정보 입력을 권장합니다.
                    </span>
                        <br/>
                        검사 후 정보를 수정해도 결과지의 정보는 변경되지 않으니
                        <br/>
                        검사 전에 정보를 명확히 입력해 주세요!
                    </div>
                    <div className={style.innerTextContainer} style={{marginBottom: "20px"}}>
                        <ChildNameField profileInfo={tempProfileInfo} setProfileInfo={setTempProfileInfo}
                                        errors={errors}/>

                        <ChildAgeField profileInfo={tempProfileInfo} setProfileInfo={setTempProfileInfo}
                                       errors={errors}/>

                        <ChildGenderField
                            profileInfo={tempProfileInfo} setProfileInfo={setTempProfileInfo}
                            errors={errors} setErrors={setErrors}/>

                        <ChildAddressField profileInfo={tempProfileInfo} setProfileInfo={setTempProfileInfo}
                                           parentAddress={parentAddress}
                                           selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
                                           isChecked={isChecked} setIsChecked={setIsChecked}
                        />
                    </div>
                    <div className={style.profileContainer}>
                        <button className={style.removeProfileBtn} onClick={handleRemove}>
                            프로필 삭제
                        </button>
                    </div>
                    <button className={style.submitBtn} onClick={handleSubmit}>
                        수정하기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditChildModal;
