import styled from "styled-components";
import { KpassReviewInputTitleStyle } from "../common/StyledComponents";
import { InputTextArea } from "../common/InputTextArea";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooks";
import {
  descriptionState,
  setDescription,
  validationErrorsState,
} from "../../../../../../store/review/reviewModalSlice";
import { MutableRefObject, useCallback, useMemo } from "react";
import { ReviewValidationError } from "../../hooks/useReviewValidator";
import { useTranslation } from "react-i18next";

const ReviewDescriptionStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

interface Props {
  textRef: MutableRefObject<HTMLTextAreaElement | null>;
}

export function Description({ textRef }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const dispatch = useAppDispatch();
  const defaultDescription = useAppSelector(descriptionState);
  const onChangeDescription = useCallback(
    (newDescription: string) => dispatch(setDescription(newDescription)),
    [dispatch]
  );
  const validationErrors = useAppSelector(validationErrorsState);
  const hasError = useMemo(
    () => validationErrors.some((it) => it === ReviewValidationError.DESCRIPTION),
    [validationErrors]
  );

  return (
    <ReviewDescriptionStyle>
      <KpassReviewInputTitleStyle>{t("descriptionTitle")}</KpassReviewInputTitleStyle>

      <InputTextArea
        textRef={textRef}
        defaultText={defaultDescription}
        placeholder={t("descriptionPlaceholder")}
        maxLength={2000}
        onChange={onChangeDescription}
        hasError={hasError}
        errorMessage={t("descriptionErrorMessage")}
      />
    </ReviewDescriptionStyle>
  );
}
