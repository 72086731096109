import style from "../../../../assets/scss/sub/purchase/paymentResult.module.scss";
import {PaymentDetail} from "./PaymentDetail";
import {OrderDetail} from "../../../../types/order";
import {useOrderPaymentType, useOrderProductName} from "./PaymentHook";

interface Props {
  orderDetail: OrderDetail;
}

export function FailedPayment({ orderDetail }: Props) {
  const productName = useOrderProductName(orderDetail);
  const paymentType = useOrderPaymentType(orderDetail);

  return <div className={style.failed}>
    <h3 className={style.title}>결제에 실패하였습니다.</h3>
    <p className={style.description}>재결제를 원하시면 ‘재시도’ 버튼을 눌러주세요.</p>
    <PaymentDetail result={{
      type: 'payment',
      productName: productName,
      paymentWay: paymentType,
      amount: orderDetail.payAmount,
      failedReason: orderDetail.failReason,
      vbankName: orderDetail?.vbankName ?? "",
      vbankAccountNumber: orderDetail?.vbankAccountNumber ?? ""
    }}/>
  </div>;
}