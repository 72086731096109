// src/AffiliationInfoModal.tsx
import React from "react";
import style from "../../../assets/scss/common/modal/affiliationInfoModal.module.scss";

interface AffiliationInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  affiliationInfo: string;
  name: string;
}

const AffiliationInfoModal: React.FC<AffiliationInfoModalProps> = ({ isOpen, onClose, name, affiliationInfo }) => {
  if (!isOpen) {
    return null;
  }

  const handleCopyInfo = () => {
    navigator.clipboard.writeText(affiliationInfo);
    alert("회원코드가 복사되었습니다.");
  };

  return (
    <div className={style.affiliationInfoModalOverlay}>
      <div className={style.affiliationInfoModal}>
        <div className={style.affiliationInfoModalContent}>
          <h2 className={style.titleText}>
            <span className={style.boldText}>
              {/* {affiliationInfo} */}
              {name}
            </span>
            님 회원코드
          </h2>
          <button onClick={handleCopyInfo} className={style.copyInfoButton}>
            <span className={style.affiliationCode}>{affiliationInfo}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none" className={style.copyIcon}>
              <path
                d="M11.9844 12.167V10.1044C11.9844 9.36058 12.593 8.75195 13.3369 8.75195H20.2008C20.9447 8.75195 21.5533 9.36058 21.5533 10.1044V16.9684C21.5533 17.7122 20.9447 18.3209 20.2008 18.3209H18.8145"
                stroke="#757575"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.4645 12.168H10.6005C9.85358 12.168 9.24805 12.7735 9.24805 13.5205V20.3844C9.24805 21.1313 9.85358 21.7369 10.6005 21.7369H17.4645C18.2114 21.7369 18.817 21.1313 18.817 20.3844V13.5205C18.817 12.7735 18.2114 12.168 17.4645 12.168Z"
                stroke="#757575"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <ul className={style.affiliationDescription}>
            <li className={style.affiliationFirstDescriptionText} style={{ listStyle: "disc" }}>
              회원코드를 소속관리자에게 전달하면 소속단체에 가입할 수 있습니다.
            </li>
            <li className={style.affiliationSecondDescriptionText} style={{ listStyle: "disc" }}>
              소속관리자에게 회원코드 전달 시, 회원 정보 및 검사 결과 제공에 동의한 것으로 간주합니다. <br />
              (정보 제공 항목 : 이름/연락처/나이/성별/지역/검사 내역)
            </li>
          </ul>
        </div>
        <button onClick={onClose} className={style.affiliationInfoCloseButton}>
          닫기
        </button>
      </div>
    </div>
  );
};

export default AffiliationInfoModal;
