import { useUserAgent } from "../../hooks/hooks";

interface Props {
  isTabletBr?: boolean;
}

export function IsTabletBr({ isTabletBr = true }: Props) {
  const { isTablet } = useUserAgent();
  if (isTablet && isTabletBr) return <br />;
  if (!isTablet && !isTabletBr) return <br />;
  return <></>;
}
