import {useCallback} from "react";
import {UserLeavePostDTO} from "../../../../../types/user";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {leaveMember} from "../../../../../store/user/userSlice";

export function useLeaveMember(){
    const dispatch = useAppDispatch();
    const leaveRequestMember = useCallback(
        async (leave: UserLeavePostDTO) => {
            const request: UserLeavePostDTO = {
                reason: leave.reason
            };

            try {
                console.log('leave start')
                await dispatch(leaveMember(request)).unwrap();
                // onLoggedIn(result.accessToken, result.refreshToken);
                console.log('leave successful');
            } catch (error) {
                console.error('Failed to signup:', error);
                throw error;
            }
        },
        [dispatch]
    )
    return{leaveRequestMember}
}