import {useAppDispatch} from "../../../../hooks/hooks";
import {useCallback, useState} from "react";
import {
    applyCounselingAsync, cancelCounselingAsync,
    getAvailableReservationAsync,
    getConsultantInfoAsync,
    getConsultationDetail,
    getConsultationHistory,
    postConsultationReviewAsync,
    putCounselingAsync
} from "../../../../store/consultation/consultationSlice";
import {
    ConsultantInfoDTO,
    ConsultantReviewPostDTO,
    ConsultationApplyDTO, ConsultationCancelDTO,
    ConsultationData,
    ConsultationPutDTO,
    ConsultationReservation,
    ConsultationReservationPostDTO
} from "../../../../types/consultation";
import {UserProfile} from "../../../../types/user";
import {ErrorType} from "../../../../types/error";

export function useConsultationGetter() {
    const dispatch = useAppDispatch();
    const [reservationList, setReservationList] = useState<ConsultationReservation[]>([])
    const [consultationData, setConsultationData] = useState<ConsultationData>();
    const [consultantInfo, setConsultantInfo] = useState<ConsultantInfoDTO>();
    const [consultationHistory, setConsultationHistory] = useState<UserProfile>();

    // 예약 가능 상담 테이블 조회 API
    const getAvailableReservation = useCallback(async (body:ConsultationReservationPostDTO) => {
        try {
            const result:ConsultationReservation[] = await dispatch(getAvailableReservationAsync(body)).unwrap();
            setReservationList(result)
            // return result;
        } catch (e) {
            throw e; // new Error((e as { message: string }).message);
        }
    }, [dispatch]);

    // 상담 신청 API
    const applyCounseling = useCallback(async (body:ConsultationApplyDTO) => {
        try {
            const result = await dispatch(applyCounselingAsync(body)).unwrap();
            console.log(result)
            // return result;
        } catch (e) {
            const errorMessage = (e as { message: string }).message;
            const error: ErrorType = JSON.parse(errorMessage);
            console.log(error)
            throw new Error(error.errorCode.msg);
        }
    }, [dispatch]);

    // 상담 취소 API
    const cancelCounseling = useCallback(async (body:ConsultationCancelDTO) => {
        try {
            const result = await dispatch(cancelCounselingAsync(body)).unwrap();
            console.log(result)
            // return result;
        } catch (e) {
            console.log('error : ',e)
            throw e; // new Error((e as { message: string }).message);
        }
    }, [dispatch]);

    // 상담 내역 단건 조회 API
    const getConsultationInfo = useCallback(
        async (consultantIdx: number) => {
            try {
                const response: ConsultationData = await dispatch(
                    getConsultationDetail({ consultantIdx: consultantIdx })
                ).unwrap();
                setConsultationData(response);
            } catch (error) {
                console.error(error);
            }
        },
        [dispatch]
    );

    // 상담 수정 API
    const updateCounseling = useCallback(async (
        {body, idx}: { body:ConsultationPutDTO, idx:number }
    ) => {
        try {
            const result = await dispatch(putCounselingAsync({body, idx})).unwrap();
            console.log(result)
        } catch (e) {
            throw e; // new Error((e as { message: string }).message);
        }
    }, [dispatch]);

    // 배정 상담사 조회 API
    const getConsultantInfo = useCallback(async (counselingIdx:number) => {
        try {
            const result:ConsultantInfoDTO = await dispatch(getConsultantInfoAsync(counselingIdx)).unwrap();
            setConsultantInfo(result)
        } catch (e) {
            throw e; // new Error((e as { message: string }).message);
        }
    }, [dispatch]);

    // 상담 리뷰 등록 API
    const postConsultationReview = useCallback(async (
        {body ,counselingIdx}: { body :ConsultantReviewPostDTO, counselingIdx:number }) => {
        try {
            return await dispatch(postConsultationReviewAsync({counselingIdx, body})).unwrap();
        } catch (e) {
            throw e; // new Error((e as { message: string }).message);
        }
    }, [dispatch]);

    // 마이페이지 상담 내역 조회
    const getTestHistory = useCallback(async (
        {profileIdx, currentPage}:{profileIdx:number, currentPage:number}
    ) => {
        try {
            const response = await dispatch(
                getConsultationHistory({profileIdx: profileIdx, pageable: {page: currentPage, size: 4}})
            ).unwrap();

            // 여기 페이지네이션 하는것부터
            setConsultationHistory(response);
            return response;
        } catch (error) {
            console.error(error);
        }
        // eslint-disable-next-line
    }, [dispatch]);

    return {
        getAvailableReservation, reservationList,
        applyCounseling, cancelCounseling,
        getConsultationInfo, consultationData,
        updateCounseling,
        getConsultantInfo, consultantInfo,
        postConsultationReview,
        getTestHistory, consultationHistory
    }
}