import style from "../../../assets/scss/sub/purchase/orderAndPayment.module.scss";
import supportIcon from "../../../assets/images/support_icon.svg";
import closeIcon from "../../../assets/images/close_icon.svg";
import bubbleTail from "../../../assets/images/bubble_tail.svg";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useClickEvent } from "../../../hooks/useClickEvent";
import { CLICK_TARGET_NAME } from "../../../util/events";

interface Props {
  title: string;
  content: string[];
  supportLeft: number;
}

export function SupportButton({ title, content, supportLeft }: Props) {
  const supportButtonRef = useRef<HTMLButtonElement>(null);
  const supportAreaRef = useRef<HTMLDivElement>(null);
  const [showSupportBubble, setShowSupportBubble] = useState(false);

  const bubbleTailTranslateX = useMemo(
    () => Math.abs(supportLeft) + (supportButtonRef.current?.getBoundingClientRect().width ?? 0) / 2 - 7,
    [supportLeft, supportButtonRef]
  );

  useClickEvent({ elementRefs: [supportButtonRef, supportAreaRef], doOnClickBg: () => setShowSupportBubble(false) });

  const onClickSupportButton = useCallback((showBubble: boolean) => {
    setShowSupportBubble(!showBubble);
  }, []);

  return (
    <div className={style.supportWrapper}>
      <button
        data-name={CLICK_TARGET_NAME}
        className={style.supportButton}
        onClick={() => onClickSupportButton(showSupportBubble)}
        ref={supportButtonRef}
      >
        <img src={supportIcon} alt={"support icon"} />
      </button>
      {showSupportBubble && (
        <div
          data-name={CLICK_TARGET_NAME}
          className={style.supportArea}
          style={{
            left: `${supportLeft}px`,
          }}
          ref={supportAreaRef}
        >
          <div
            className={style.bubbleTail}
            style={{
              transform: `translateX(${bubbleTailTranslateX}px)`,
            }}
          >
            <img className={style.bubbleTailImage} src={bubbleTail} alt={"말풍선 꼬리"} />
          </div>
          <div className={style.supportContent}>
            <div className={style.titleArea}>
              <span className={style.supportTitle}>{title}</span>
              <button className={style.closeButton} onClick={() => setShowSupportBubble(false)}>
                <img src={closeIcon} alt={"close icon"} />
              </button>
            </div>
            <ul className={style.contentList}>
              {content.map((c, index) => (
                <li className={style.item} key={`support-${index}`}>
                  {c}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
