import style from "../../../../assets/scss/sub/purchase/paymentResult.module.scss";
import friendsImg from "../../../../assets/images/sub/purchase_voucher/pay/happy_friends.png";
import { PaymentDetail } from "./PaymentDetail";
import { OrderDetail } from "../../../../types/order";
import { useOrderPaymentType, useOrderProductName } from "./PaymentHook";

interface Props {
  type: "payment" | "order";
  orderDetail: OrderDetail;
}

export function CompletePayment({ type, orderDetail }: Props) {
  const productName = useOrderProductName(orderDetail);
  const paymentType = useOrderPaymentType(orderDetail);

  return (
    <div className={style.success}>
      <div className={style.bgItem}>
        <img src={friendsImg} alt={"주문 완료 배경 이미지"} />
      </div>
      <div className={style.description}>
        <h3 className={style.title}>{type === "payment" ? "결제가 완료되었습니다." : "주문이 완료되었습니다."}</h3>
        <p className={style.descText}>
          {type === "payment" ? (
            <>
              <span className={style.accent}>My Page 에서 프로필(자녀) 등록 후,</span>
              <br />
              PC 또는 태블릿 환경에서 K-PASS 검사 프로그램을 다운받아 검사가 가능합니다.
            </>
          ) : (
            <span className={style.accent}>주문 완료일 기준 7일 이내에 아래 주문금액을 입금해주세요.</span>
          )}
        </p>
      </div>
      <PaymentDetail
        result={{
          type: type,
          productName: productName,
          paymentWay: paymentType,
          amount: orderDetail?.payAmount ?? 0,
          vbankName: orderDetail?.vbankName ?? "",
          vbankAccountNumber: orderDetail?.vbankAccountNumber ?? "",
        }}
      />
      <div className={style.descEtc}>
        <span className={style.text}>* 결제내역은 [ My Page ] 에서 확인하실 수 있습니다.</span>
      </div>
    </div>
  );
}
