import { useMemo } from "react";
import { TimeConverter } from "../../util/timeConverter";
import { TimeConverterContext } from "./TimeConverterContext";
import { useLanguage } from "../hooks";

export function TimeConverterProvider({ children }: { children: JSX.Element }) {
  const { language } = useLanguage();

  const timeConverter = useMemo(() => {
    let instance = TimeConverter.getInstance();
    instance.setLocale(language);
    return instance;
  }, [language]);

  return <TimeConverterContext.Provider value={{ timeConverter }}>{children}</TimeConverterContext.Provider>;
}
