import { useMemo } from "react";
import { useCookie } from "./auth/useCookie";
import { CookieKeys } from "./auth/AuthProvider";
import jwt_decode from "jwt-decode";

export function useSignedInUserIdx(): number | null {
  const { getValueFromCookie } = useCookie();

  return useMemo(() => {
    let accessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
    if (typeof accessToken == "undefined") return null;

    const { userIdx } = jwt_decode(accessToken) as { userIdx: number };
    return Number(userIdx);
  }, [getValueFromCookie]);
}
