import { useCallback, useMemo } from "react";
import { OrderDirection, ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../types/page";
import { KPASSReviewListDTO, KPASSReviewVO, ReviewImageDTO } from "../../../../types/review";
import {
  getNextReviewAsync,
  getPrevReviewAsync,
  getReviewAsync,
  getReviewImagesAsync,
  getReviewsAsync,
  reviewsState,
  reviewTotalCountState,
  setReviewsState,
} from "../../../../store/review/reviewSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useKpassReviewVOType } from "../../../../types/hooks/useKpassReviewVOType";
import { useReviewListVOType } from "../../../../types/hooks/useReviewListVOType";

export function useReviewsGetter() {
  const dispatch = useAppDispatch();
  const reviews = useAppSelector(reviewsState);
  const reviewTotalCount = useAppSelector(reviewTotalCountState);
  const { toKpassReviewVOFromDTO } = useKpassReviewVOType();
  const { toReviewListVOPageableListFromDto } = useReviewListVOType();

  const pageCondition = useMemo(
    () => ({
      page: reviews?.number ?? 0,
      size: reviews?.size ?? 10,
      sort: reviews?.orders
        ? ordersToSortArr(reviews.orders)
        : [{ columnName: "writtenAt", direction: OrderDirection.DESC }],
    }),
    [reviews]
  );

  const getReviews = useCallback(
    async ({ page, size, sort }: PageSearchCondition) => {
      const param: PageSearchCondition = {
        page: page ?? pageCondition.page,
        size: size ?? pageCondition.size,
        sort: sort ?? pageCondition.sort,
      };

      const result: PageResponse<KPASSReviewListDTO> = await dispatch(getReviewsAsync(param)).unwrap();
      dispatch(setReviewsState(toReviewListVOPageableListFromDto(result)));
    },
    [dispatch, pageCondition.page, pageCondition.size, pageCondition.sort, toReviewListVOPageableListFromDto]
  );

  const getReview = useCallback(
    async (reviewIdx: number): Promise<KPASSReviewVO> => {
      const dto = await dispatch(getReviewAsync(reviewIdx)).unwrap();
      return toKpassReviewVOFromDTO(dto);
    },
    [dispatch, toKpassReviewVOFromDTO]
  );

  const getPrevReview = useCallback(
    async (reviewIdx: number): Promise<KPASSReviewVO | undefined> => {
      try {
        const dto = await dispatch(getPrevReviewAsync(reviewIdx)).unwrap();
        return toKpassReviewVOFromDTO(dto);
      } catch (_) {
        return undefined;
      }
    },
    [dispatch, toKpassReviewVOFromDTO]
  );

  const getNextReview = useCallback(
    async (reviewIdx: number): Promise<KPASSReviewVO | undefined> => {
      try {
        const dto = await dispatch(getNextReviewAsync(reviewIdx)).unwrap();
        return toKpassReviewVOFromDTO(dto);
      } catch (_) {
        return undefined;
      }
    },
    [dispatch, toKpassReviewVOFromDTO]
  );

  const getReviewImages = useCallback(
    async (reviewIdx: number): Promise<ReviewImageDTO[]> => {
      return dispatch(getReviewImagesAsync(reviewIdx)).unwrap();
    },
    [dispatch]
  );

  return {
    getReview,
    getPrevReview,
    getNextReview,
    getReviews,
    reviews,
    getReviewImages,
    reviewTotalCount,
  };
}
