import { PaymentWay, ReadyToPayment } from "../../../../types/payment";
import { useEffect, useRef, useState } from "react";
import { postOrderAsync } from "../../../../store/order/orderSlice";
import { Price } from "../../../../types/cart";
import { useAppDispatch, useModal } from "../../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { CreateOrderParam } from "../../../../types/order";

declare global {
  interface Window {
    nicepaySubmit: any;
    nicepayClose: any;
    goPay: any;
  }
}

interface PaymentGatewayProps {
  price: Price;
  cartIdxes: number[];
  isClickedPayButton: Boolean;
  resetClickedPayButton: (_: boolean) => void;
  paymentWay: PaymentWay | null;
  couponHistoryIdx: number | null;
  affiliateCodeIdx: number | null;
}

export function PaymentGateway(props: PaymentGatewayProps) {
  const {
    price,
    cartIdxes,
    isClickedPayButton,
    resetClickedPayButton,
    paymentWay,
    couponHistoryIdx,
    affiliateCodeIdx,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openAlertModal } = useModal();
  useEffect(() => {
    if (isClickedPayButton) pay().then();
    resetClickedPayButton(false);
    // eslint-disable-next-line
  }, [isClickedPayButton]);
  const form = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    let script = document.createElement("script");
    script.src = "https://web.nicepay.co.kr/v3/webstd/js/nicepay-3.0.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const [readyToPayment, setReadyToPayment] = useState<ReadyToPayment | null>(null);

  async function pay() {
    const param: CreateOrderParam = {
      cartIdxes: cartIdxes,
      couponHistoryIdx: couponHistoryIdx,
      affiliateCodeIdx: affiliateCodeIdx ?? undefined,
      regularPriceAmount: price.regularAmount,
      productDiscountAmount: price.productDiscountAmount,
      couponDiscountAmount: price.couponDiscountAmount,
      affiliateCodeDiscountAmount: price.affiliateCodeDiscountAmount,
      payAmount: price.actualAmount,
      paymentWay: paymentWay!,
    };

    try {
      await dispatch(postOrderAsync(param))
        .unwrap()
        .then((it) => {
          if (price.actualAmount > 0) {
            setReadyToPayment(it);
          } else {
            navigate(`/payment-result/${it.orderIdx}`);
          }
        });
    } catch (e) {
      console.error(e);
      openAlertModal({
        title: "에러",
        desc: "주문 중 예상치 못한 에러가 발생합니다. ",
      });
    }
  }

  window.nicepaySubmit = function () {
    form.current?.submit();
  };

  window.nicepayClose = function () {
    if (!readyToPayment) return;
    navigate(`/payment-result/${readyToPayment.orderIdx}`);
  };

  const isMobile = function () {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  const payStart = function () {
    if (form.current == null) return;
    if (isMobile()) {
      form.current.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
      form.current.acceptCharset = "euc-kr";
      form.current.submit();
    } else {
      window.goPay(form.current);
    }
  };

  useEffect(() => {
    if (readyToPayment == null) return;
    payStart();
    // eslint-disable-next-line
  }, [readyToPayment]);

  return (
    <div>
      {readyToPayment && (
        <form style={{ display: "none" }} name="payForm" method="post" ref={form} action={readyToPayment.returnUrl}>
          <input type="text" name="PayMethod" defaultValue={readyToPayment.payMethod} />
          <input type="text" name="GoodsName" defaultValue={readyToPayment.goodsName} />
          <input type="text" name="Amt" defaultValue={readyToPayment.amt} />
          <input type="text" name="MID" defaultValue={readyToPayment.mid} />
          <input type="text" name="Moid" defaultValue={readyToPayment.orderIdx} />
          <input type="text" name="BuyerName" defaultValue={readyToPayment.buyerName} />
          <input type="text" name="BuyerEmail" defaultValue={readyToPayment.buyerEmail} />
          <input type="text" name="BuyerTel" defaultValue={readyToPayment.buyerTel} />
          <input type="text" name="ReturnURL" defaultValue={readyToPayment.returnUrl} />
          <input type="text" name="VbankExpDate" defaultValue={readyToPayment.vBankExpDate} />
          <input type="hidden" name="CharSet" defaultValue="utf-8" />
          <input type="hidden" name="EdiDate" defaultValue={readyToPayment.ediDate} />
          <input type="hidden" name="SignData" defaultValue={readyToPayment.signData} />
        </form>
      )}
    </div>
  );
}
