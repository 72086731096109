import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartedProduct, CartResponse, CreateCartParam } from "../../types/cart";
import { addCases, executePromise } from "../../util/sliceUtil";
import { CartApi } from "../../api/CartApi";
import { RootState } from "../index";

export interface CartState extends SliceState {
  cartedProducts?: CartedProduct[];
}

const initialState: CartState = {
  status: Status.IDLE,
};

export const createCartAsync = createAsyncThunk("carts/createCart", (createCartParam: CreateCartParam[]) =>
  executePromise<CartResponse[]>(CartApi.createCart(createCartParam))
);

export const getCartsAsync = createAsyncThunk("carts/getCarts", (cartIdxes: number[]) =>
  executePromise<CartResponse[]>(CartApi.getCarts(cartIdxes))
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartedProducts: (state, action: PayloadAction<CartedProduct[]>) => {
      state.cartedProducts = action.payload.map((cartedProduct) => {
        const { product, quantity } = cartedProduct;
        const totalRegularPrice = product.regularPrice * quantity;

        let totalDiscountAmount = (product.regularPrice - product.sellingPrice) * quantity;
        return {
          product,
          quantity,
          totalRegularPrice,
          totalDiscountAmount,
          totalActualAmount: totalRegularPrice - totalDiscountAmount,
        };
      });
    },
  },
  extraReducers: (builder) => {
    addCases(builder, createCartAsync);
    addCases(builder, getCartsAsync);
  },
});

export const cartedProducts = (state: RootState) => state.cart.cartedProducts;

export const { setCartedProducts } = cartSlice.actions;

export default cartSlice.reducer;
