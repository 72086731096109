import style from "../../../assets/scss/common/modal/leaveModal.module.scss";
import {useModal} from "../../../hooks/hooks";
import {LeaveAgreementField} from "./leave/LeaveAgreementField";
import {LeaveHeaderField} from "./leave/LeaveHeaderField";
import {useState} from "react";
import {LeaveReasonField} from "./leave/LeaveReasonField";
import {LeaveButtonField} from "./leave/LeaveButtonField";
import {LeaveReason} from "../../../types/user";

export enum LeaveStep {
    STEP1 = "STEP1",
    STEP2 = "STEP2",
}

export function LeaveModal() {
    const {closeModal} = useModal();
    const [isAllAgreementSelected, setIsAllAgreementSelected] = useState(false);
    const [currentLeaveStep, setCurrentLeaveStep] = useState(LeaveStep.STEP1);
    const [radioOption, setRadioOption] = useState(LeaveReason.NONE)
    const [radioTextReason, setRadioTextReason] = useState("")

    const [errorMessage, setErrorMessage] = useState("");

    return (
        <>
            <div className={style.backdrop}>
                <div className={style.leaveMainWrapper}>
                    {/* 헤더 바 영역 */}
                    <div className={style.leaveHeaderWrapper}>
                        <div className={style.leaveHeaderLogo}/>
                        <div className={style.leaveCloseIcon} onClick={closeModal}></div>
                    </div>

                    {/*  메인 바디 영역  */}
                    {currentLeaveStep === LeaveStep.STEP1 && (
                        <>
                            <div className={style.leaveBodyWrapper}>
                                {/* 헤더 ~~서비스 탈퇴 안내~~ */}
                                <LeaveHeaderField title={"서비스 탈퇴 안내"} subTitle={"아래 내용을 꼭 확인해주세요."}/>

                                {/* 탈퇴 동의 */}
                                <LeaveAgreementField setIsAllAgreementSelected={setIsAllAgreementSelected}
                                                     errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>

                            </div>
                            {/*  취소 / 다음 버튼  */}
                            <LeaveButtonField isAllAgreementSelected={isAllAgreementSelected} confirmStatus={"다음"}
                                              currentLeaveStep={currentLeaveStep}
                                              setCurrentLeaveStep={setCurrentLeaveStep}
                                              radioOption={radioOption}
                                              radioTextReason={radioTextReason}
                                              setErrorMessage={setErrorMessage}/>
                        </>
                    )}
                    {currentLeaveStep === LeaveStep.STEP2 && (
                        <>
                            <div className={style.leaveBodyWrapper}>
                                <LeaveHeaderField title={"이용중 불편함이 있었나요?"} subTitle={"탈퇴 사유를 선택해주세요."}/>
                                <LeaveReasonField radioOption={radioOption} setRadioOption={setRadioOption}
                                                  radioTextReason={radioTextReason}
                                                  setRadioTextReason={setRadioTextReason}
                                                  errorMessage={errorMessage}
                                                  setErrorMessage={setErrorMessage}/>
                            </div>
                            <LeaveButtonField isAllAgreementSelected={isAllAgreementSelected}
                                              confirmStatus={"탈퇴 완료"}
                                              currentLeaveStep={currentLeaveStep}
                                              setCurrentLeaveStep={setCurrentLeaveStep}
                                              radioOption={radioOption}
                                              radioTextReason={radioTextReason}
                                              setErrorMessage={setErrorMessage}/>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}