import style from "./mainArticle2.module.scss";
import { ArticleTitle } from "../../sub/ArticleTitle";
import { Effect1 } from "./effects/Effect1";
import { Effect2 } from "./effects/Effect2";
import { Effect3 } from "./effects/Effect3";
import { Effect4 } from "./effects/Effect4";
import { Effect5 } from "./effects/Effect5";
import effectStyle from "./effects/effects.module.scss";
import React, { useMemo, useRef, useState } from "react";
import { EffectSlider } from "./effects/EffectSlider";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { useLanguage, useUserAgent } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";

export function Article2Section2() {
  const { t } = useTranslation(["kpass"]);
  const { isMobile } = useUserAgent();
  const sliderRef = useRef<HTMLDivElement>(null);
  const [showSlider, setShowSlider] = useState(false);
  const { language } = useLanguage();

  useShowOrHide({ refCurrent: sliderRef.current, setShow: setShowSlider });
  const sliderRect = useMemo(
    () => sliderRef.current?.getBoundingClientRect(),
    // eslint-disable-next-line
    [sliderRef.current]
  );
  const sliderWidth = useMemo(() => sliderRect?.width ?? 0, [sliderRect]);

  return (
    <section className={style.section2}>
      <h2 className={"blind"}>{t("section2.heading2")}</h2>
      <div className={style.titleWrapper}>
        <ArticleTitle title={t("section2.title")} className={style.title} />
      </div>
      <div className={style.slider} ref={sliderRef}>
        <EffectSlider
          slides={[<Effect1 />, <Effect2 />, <Effect3 />, <Effect4 />, <Effect5 />]}
          slideWidth={!isMobile ? 905 : sliderWidth}
          delay={800}
          shortcutButton={{
            texts: [
              t("section2.sliderTitle1"),
              t("section2.sliderTitle2"),
              t("section2.sliderTitle3"),
              t("section2.sliderTitle4"),
              t("section2.sliderTitle5"),
            ],
            defaultClassName: `${effectStyle.effectButton} ${language === "ru" ? effectStyle.ru : ""}`,
            activeClassName: effectStyle.active,
            wrapperClassName: `${style.shortcutButtonWrapper} ${showSlider ? style.show : style.hide}`,
          }}
          slideWrapperClassName={`${style.sliderWrapper} ${showSlider ? style.show : style.hide}`}
          showSlider={showSlider}
        />
      </div>
    </section>
  );
}
