import styles from "../../../../../assets/scss/common/modal/loginModal.module.scss";
import React, {useState} from "react";
import {SignupStatus} from "../../SignupModal";
import {useModal, useUserAgent} from "../../../../../hooks/hooks";

interface Props {
    changeSignupStep: (_: SignupStatus) => void;
}

export function SignupInfoStep4({
                                    changeSignupStep,
                                }: Props) {

    const {closeModal} = useModal();

    const onClickPurchase = () => {
        closeModal()
        window.location.href = '/purchase-voucher';
        // navigate('/purchase-voucher')
    }

    const onClickInfo = () => {
        closeModal()
        window.location.href = '/mypage';
    }
    const [isInfoHover, setIsInfoHover] = useState(false);
    const [isPurchaseHover, setIsPurchaseHover] = useState(false);
    const {isMobile} = useUserAgent();


    return (
        <>
            <div className={styles.inputArea}>

                <div className={styles.desc}>
                <span className={styles.accent}>화원가입이 완료 되었습니다.</span>
                    {isMobile ? (
                        <>
                            <br/>이용권 구매 후 즉시<br />검사를 시작하실 수 있습니다.
                        </>
                    ) : (
                        <>
                            <br/>이용권 구매 후 즉시 검사를 시작하실 수 있습니다.
                        </>
                    )}
                </div>
                <div className={styles.maxImg}></div>


                <div className={`${styles.signUpCompleteButtonsWrapper} ${isMobile ? '' : styles.withMarginTop}`}>
                    <button type="button"
                            className={`${styles.signUpNextButton} ${isPurchaseHover ? styles.signUpNextHoverButton : ''}`}
                            onMouseEnter={() => setIsPurchaseHover(true)}
                            onMouseLeave={() => setIsPurchaseHover(false)}
                            onClick={onClickPurchase}
                    >
                        이용권 구매 바로가기
                        <div className={`${styles.submitIcon} ${isPurchaseHover ? styles.submitIconHovered : ''}`}/>
                    </button>
                    <button type="button"
                            className={`${styles.signUpNextButton} ${isInfoHover ? styles.signUpNextHoverButton : ''}`}
                            onMouseEnter={() => setIsInfoHover(true)}
                            onMouseLeave={() => setIsInfoHover(false)}
                            onClick={onClickInfo}
                    >
                        My Page 바로가기
                        <div className={`${styles.submitIcon} ${isInfoHover ? styles.submitIconHovered : ''}`} />
                    </button>
                </div>
            </div>
        </>
    )
}