import style from "./koreanKpassStandardization.module.scss";
import kpassWhiteLogo from "../../assets/images/logo/kpass_white_logo.svg";
import printIcon from "../../assets/images/kpass_standardization/print_icon.svg";
import closeIcon from "../../assets/images/kpass_standardization/close_icon.svg";
import { useCallback } from "react";

export function StandardizationHeaderKO() {
  const onClickPrintButton = useCallback(() => {
    window.print();
  }, []);

  const onClickCloseButton = useCallback(() => {
    window.close();
  }, []);

  return (
    <header className={style.headerWrapper}>
      <div className={style.header}>
        <div className={style.logo}>
          <img src={kpassWhiteLogo} alt={"K-PASS white logo"} />
          한국판 K-PASS 표준화
        </div>
        <div className={style.buttons}>
          <button onClick={onClickPrintButton}>
            <img src={printIcon} alt={"print"} />
          </button>
          <button onClick={onClickCloseButton}>
            <img src={closeIcon} alt={"close"} />
          </button>
        </div>
      </div>
    </header>
  );
}
