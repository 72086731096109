import style from "../../../assets/scss/common/modal/addConsultationModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import React, {useEffect, useState} from "react";
import {ConsultantReviewPostDTO, ConsultationItem} from "../../../types/consultation";
import {useConsultationGetter} from "../../sub/consultation/hooks/useConsultationGetter";
import {useConsultationFunctions} from "../../sub/consultation/hooks/useConsultationFunctions";
import fullStar from "../../../assets/images/mypage/fullstar.svg";
import emptyStar from "../../../assets/images/mypage/emptystar.svg";
import {ValidateStatus} from "./login/signup/hooks/useSignupValidator";
import {useModal} from "../../../hooks/hooks";

interface Args {
    isOpen: boolean;
    onClose: () => void;
    selectedConsultation: ConsultationItem | null;
}

export function ReviewConsultantModal(
    {isOpen, onClose, selectedConsultation}: Args) {

    const {getConsultationInfo, consultationData, postConsultationReview} = useConsultationGetter();
    const {openConfirmModal, openAlertModal} = useModal();
    const {getConsultationStatus} = useConsultationFunctions();
    const [review, setReview] = useState(""); // 상담 리뷰 상태 관리
    const [rating, setRating] = useState(0); // 별점 상태 관리 (0 ~ 5)
    const [reviewValidator, setReviewValidator] = useState<ValidateStatus>(ValidateStatus.NONE)
    const [reviewRateValidator, setReviewRateValidator] = useState<ValidateStatus>(ValidateStatus.NONE)

    // 별점 클릭 핸들러
    const handleStarClick = (index: number) => {
        setRating(index + 1); // 클릭된 별 인덱스에 1을 더해서 설정
        setReviewRateValidator(ValidateStatus.NONE)
    };

    useEffect(() => {
        if (isOpen && selectedConsultation?.counselingIdx !== undefined) {
            getConsultationInfo(selectedConsultation.counselingIdx).then();
        }
        // eslint-disable-next-line
    }, [isOpen, getConsultationInfo]);

    useEffect(() => {
        if (consultationData) {
            setReview(consultationData.review ?? '');
            setRating(consultationData.reviewCount ?? 0)
        }
        // eslint-disable-next-line
    }, [consultationData]);

    if (!isOpen) {
        return null;
    }

    const handleReviewSubmit = () => {
        // 유효성 검증
        if (!selectedConsultation) return false;
        if (rating === 0) {
            setReviewRateValidator(ValidateStatus.UNFILLED)
            return false;
        }
        if (review.length < 2 || review.length > 1000) {
            setReviewValidator(ValidateStatus.BELOW_REQUIRED_LENGTH)
            return false;
        }
        const body: ConsultantReviewPostDTO = {
            review: review,
            reviewStar: rating
        };
        openConfirmModal(
            {
                title: "상담 리뷰 등록 안내",
                desc: "상담리뷰를 등록 하시겠습니까?\n" +
                    "상담리뷰 등록 후에는 수정이 어렵습니다.",
            }, () => {
                postConsultationReview({body: body, counselingIdx: selectedConsultation.counselingIdx})
                    .then(() => {
                        openAlertModal({
                            title: "상담 리뷰 등록 안내",
                            desc: "상담리뷰가 등록 되었습니다.",
                            callback: () => onClose(),
                        })
                    })

            });
    }

    return (
        <div className={style.addChildModalOverlay}>
            <div className={style.addChildInfoModal}>
                <div className={style.closeBtnContainer}>
                    <img
                        src={CloseBtn}
                        alt="closeBtn"
                        className={style.closeBtn}
                        onClick={() => onClose()}
                    />
                </div>
                {selectedConsultation && consultationData && (
                    <div className={style.innerModalContainer}>
                        <h3 className={style.addChildInfoTitle}>상담 상세보기</h3>
                        <div className={style.addChildInfoDesc}>
                            <span className={style.boldDesc}>상담 정보를 확인해 주세요.</span>
                        </div>
                        <div className={style.innerTextContainer}>
                            <div className={style.infoContainer}>
                                <span className={style.infoTitleText}> 프로필(자녀) 정보 확인</span>
                                <span className={style.infoText}>이름</span>
                                <input
                                    placeholder={selectedConsultation.userName ? selectedConsultation.userName : ""}
                                    className={style.inputField}
                                    value={selectedConsultation.userName ? selectedConsultation.userName : ""}
                                    disabled
                                />
                                <span className={style.infoText}>검사종류</span>
                                <input
                                    placeholder="K-PASS 1회차"
                                    className={style.inputField}
                                    value={`${selectedConsultation.unitVoucherType === "KPASS" ? "K-PASS" : "D-CAS"} ${
                                        selectedConsultation.testRound
                                    }회차`}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className={style.innerTextContainer}>
                            <div className={style.infoContainer}>
                                <span className={style.infoTitleText}> 상담 정보 정보 확인</span>
                                <div className={style.consultInputContainer}>
                                    <span className={style.consultInputTitle}>상담 희망 날짜</span>
                                    <div className={style.customDateInput}>{selectedConsultation.desiredDate}</div>
                                </div>
                                <div className={style.consultInputContainer}>
                                    <span className={style.consultInputTitle}>상담 희망 시간</span>
                                    <div
                                        className={style.customDateInput}>{selectedConsultation.desiredTime?.slice(0, 5)}</div>
                                </div>
                            </div>
                        </div>
                        <div className={style.innerTextContainer}>
                            <div className={style.infoContainer}>
                                <span className={style.infoTitleText}> 사전문의 내용 작성</span>
                                <div className={style.previewContent}>{consultationData.preQuestioning}</div>
                            </div>
                        </div>
                        <div className={style.innerTextContainer}>
                            <div className={style.infoContainer}>
                                <span className={style.infoTitleText}>상담신청 상태</span>
                                <div className={style.marginDiv}>
                                    {getConsultationStatus(consultationData.status)}
                                </div>
                            </div>
                        </div>
                        <div className={style.innerTextContainer}>
                            <div className={style.infoContainer}>
                                <span className={style.infoTitleText}>상담 리뷰</span>
                                <div className={style.innerTextContainerDesc}>
                                    검사 결과 상담은 어떠셨나요?
                                    <br/>
                                    별점과 함께 소중한 리뷰를 작성해 주세요.
                                </div>
                                <div className={style.starContainer}>
                                    {Array.from({length: 5}, (_, index) => (
                                        <img
                                            key={index}
                                            src={index < rating ? fullStar : emptyStar}
                                            alt={`${index + 1} star`}
                                            onClick={() => handleStarClick(index)} // 별 클릭 이벤트 연결
                                            className={`${style.star} ${consultationData.reviewCount != null ? style.disabled : style.clickable}`}
                                        />
                                    ))}
                                    {reviewRateValidator === ValidateStatus.UNFILLED && (
                                        <p className={style.warningText}>별점을 선택해주세요.</p>
                                    )}
                                </div>
                            </div>
                            <div className={style.textareaInputContainer}>
                          <textarea
                              readOnly={consultationData.reviewCount !== null}
                              placeholder="상담리뷰 2~1,000자리 입력"
                              className={style.textareaInput}
                              value={review}
                              onChange={(e) => {
                                  setReview(e.target.value)
                                  setReviewValidator(ValidateStatus.NONE)
                              }}
                          />
                            </div>
                            {reviewValidator === ValidateStatus.BELOW_REQUIRED_LENGTH && (
                                <p className={style.warningText}>리뷰는 2자리 이상 1000자리 이하로 입력해주세요.</p>
                            )}
                        </div>
                        {consultationData?.review === null && consultationData.reviewCount === null && (
                            <button className={style.submitBtn} onClick={handleReviewSubmit}>
                                리뷰 등록하기
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}