import style from "../../../../assets/scss/common/modal/addChildModal.module.scss";
import React from "react";
import {InitialProfileInfoDTO, ProfileErrorDTO} from "../../../../types/user";

interface Args{
    profileInfo: InitialProfileInfoDTO;
    setProfileInfo: React.Dispatch<React.SetStateAction<InitialProfileInfoDTO>>;
    errors: ProfileErrorDTO;
}
export function ChildAgeField({profileInfo, setProfileInfo, errors}:Args) {
    return (
        <div className={style.infoContainer}>
              <span className={style.infoText}>
                <span className={style.requireText}>[필수]</span> 생년월일 8자리
              </span>
            <input
                name="birthday"
                value={profileInfo.birthday}
                onChange={(e) => setProfileInfo({...profileInfo, birthday: e.target.value})}
                placeholder="예) 20210101"
                className={style.inputField}
            />
            {errors.birthday && <div className={style.errorText}>{errors.birthday}</div>}
        </div>
    )
}