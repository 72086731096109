import style from "../../assets/scss/sub/understandingAcademicBasis.module.scss";
import basisPic from "../../assets/images/sub/understanding/academic_basis/basis_pic.png";
import basisPicEn from "../../assets/images/sub/understanding/academic_basis/basis_pic_en.png";
import passP from "../../assets/images/sub/understanding/academic_basis/P.svg";
import passA from "../../assets/images/sub/understanding/academic_basis/A.svg";
import passS from "../../assets/images/sub/understanding/academic_basis/S.svg";
import planningPic from "../../assets/images/sub/understanding/academic_basis/planning_pic.png";
import planningPicMobile from "../../assets/images/sub/understanding/academic_basis/planning_pic_mobile.png";
import attentionPic from "../../assets/images/sub/understanding/academic_basis/attention_pic.png";
import attentionPicMobile from "../../assets/images/sub/understanding/academic_basis/attention_pic_mobile.png";
import sequentialProcessingPic from "../../assets/images/sub/understanding/academic_basis/sequential_processing_pic.png";
import sequentialProcessingPicMobile from "../../assets/images/sub/understanding/academic_basis/sequential_processing_pic_mobile.png";
import simultaneousProcessingPic from "../../assets/images/sub/understanding/academic_basis/simultaneous_processing_pic.png";
import simultaneousProcessingPicMobile from "../../assets/images/sub/understanding/academic_basis/simultaneous_processing_pic_mobile.png";
import lastChildrenPic from "../../assets/images/sub/understanding/academic_basis/last_children_pic.jpg";
import lastChildrenPicMobile from "../../assets/images/sub/understanding/academic_basis/last_children_pic_mobile.jpg";
import { ArticleTitle } from "./ArticleTitle";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage, useUserAgent } from "../../hooks/hooks";
import { StandardizationEvidenceButton } from "../StandardizationEvidenceButton";

export function UnderstandingAcademicBasis() {
  const { t } = useTranslation(["subUnderstandingAcademicBasis"]);
  const { isDesktop } = useUserAgent();
  const { isMobile } = useUserAgent();
  const { isNotKorean, isKorean } = useLanguage();

  return (
    <section className={style.basis}>
      <h3 className="blind">{t("title")}</h3>
      <article className={style.article1}>
        <h4>
          <ArticleTitle title={t("articleTitle")} className={style.title} />
        </h4>
        <div className={style.basisImage}>
          <img src={isKorean ? basisPic : basisPicEn} alt={t("basisPicAlt")} />
        </div>
        <div className={style.desc}>
          <p className={style.text1}>{t("summaryText1")}</p>
          <p className={style.text2}>{t("descText1")}</p>
        </div>
        {isKorean && (
          <div className={style.standardizationButton}>
            <StandardizationEvidenceButton />
          </div>
        )}
        <div className={style.passImages}>
          <h4 className="blind">{t("passTheory")}</h4>
          <ul className={style.imageList}>
            <li className={style.itemLine}>
              <div className={`${style.image} ${style.imagePA}`}>
                <img className={style.titleImg} src={passP} alt={t("pImageAlt")} />
                <img
                  className={style.exampleImg}
                  src={isDesktop ? planningPic : planningPicMobile}
                  alt={t("planningImageAlt")}
                />
              </div>
              <div className={style.descWrapper}>
                <h5 className={style.desc}>
                  <span className={style.subtitle}>Planning</span>
                  <span className={style.title}>{t("planning")}</span>
                </h5>
              </div>
            </li>
            <li className={style.itemLine}>
              <div className={`${style.image} ${style.imagePA}`}>
                <img className={style.titleImg} src={passA} alt={t("aImageAlt")} />
                <img
                  className={style.exampleImg}
                  src={isDesktop ? attentionPic : attentionPicMobile}
                  alt={t("attentionImageAlt")}
                />
              </div>
              <div className={style.descWrapper}>
                <h5 className={style.desc}>
                  <span className={style.subtitle}>Attention</span>
                  <span className={style.title}>{t("attention")}</span>
                </h5>
              </div>
            </li>
            <li className={style.itemLine}>
              <div className={`${style.image} ${style.imageS}`}>
                <img className={style.titleImg} src={passS} alt={t("sImageAlt1")} />
                <img
                  className={style.exampleImg}
                  src={isDesktop ? sequentialProcessingPic : sequentialProcessingPicMobile}
                  alt={t("sequentialProcessingImageAlt")}
                />
              </div>
              <div className={style.descWrapper}>
                <h5 className={style.desc}>
                  <span className={style.subtitle}>Successive Processing</span>
                  <span className={`${style.title} ${isNotKorean ? style.ru : ""}`}>{t("sequentialProcessing")}</span>
                </h5>
                <p className={style.phrase}>
                  {t("sequentialProcessingDesc1Text1")}
                  <br />
                  {t("sequentialProcessingDesc1Text2")}
                  <br />
                  <br />
                  <span className={style.accent}>{t("sequentialProcessingDesc2Title")}</span>
                  <br />
                  <span className={style.en}>{t("sequentialProcessingDesc2Text1")}</span>
                  <br />
                  <span className={style.en}>{t("sequentialProcessingDesc2Text2")}</span>
                  <br />
                  <span className={style.underline}>
                    {t("sequentialProcessingDesc2Text3")}{" "}
                    <span className={style.en}>{t("sequentialProcessingDesc2Text4")}</span>
                  </span>
                </p>
              </div>
            </li>
            <li className={style.itemLine}>
              <div className={`${style.image} ${style.imageS}`}>
                <img className={style.titleImg} src={passS} alt={t("sImageAlt2")} />
                <img
                  className={style.exampleImg}
                  src={isDesktop ? simultaneousProcessingPic : simultaneousProcessingPicMobile}
                  alt={t("simultaneousProcessingImageAlt")}
                />
              </div>
              <div className={style.descWrapper}>
                <h5 className={style.desc}>
                  <span className={style.subtitle}>Simultaneous Processing</span>
                  <span className={`${style.title} `}>{t("simultaneousProcessing")}</span>
                </h5>
                <p className={style.phrase}>
                  {t("simultaneousProcessingDesc1Text1")}
                  <br />
                  {t("simultaneousProcessingDesc1Text2")}
                  <br />
                  <br />
                  <span className={style.accent}>{t("simultaneousProcessingDesc2Title")}</span>
                  <br />
                  <span className={style.en}>{t("simultaneousProcessingDesc2Text1")}</span>
                  <br />
                  <span className={style.en}>{t("simultaneousProcessingDesc2Text2")}</span>
                  <br />
                  <span className={style.underline}>
                    {t("simultaneousProcessingDesc2Text3")}{" "}
                    <span className={style.en}>{t("simultaneousProcessingDesc2Text4")}</span>
                  </span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </article>
      <article className={style.article2}>
        {isMobile ? (
          <div className={style.imageArea}>
            <img src={lastChildrenPicMobile} alt={t("childrenPicAlt")} />
          </div>
        ) : (
          <></>
        )}
        <div className={style.text}>
          <p className={`${style.phrase1} ${isNotKorean ? style.ru : ""}`}>
            <Trans t={t} i18nKey={"article2TitleText1"} components={[<span className={style.accent} />]} />
          </p>
          <p className={style.phrase2}>{t("article2SummaryText1")}</p>
          <p className={style.phrase3}>
            {t("article2DescText1")}
            <br />
            <br />
            {t("article2DescText4")}
          </p>
        </div>
        {isMobile ? (
          <></>
        ) : (
          <div className={style.image}>
            <img src={lastChildrenPic} alt={t("childrenPicAlt")} />
          </div>
        )}
      </article>
    </section>
  );
}
