import styled from "styled-components";
import { numberWithCommas } from "../../../../../../util/commonFunctions";
import { breakpoints } from "../../../../../../theme/theme";

const TextCounterStyle = styled.span`
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => props.theme.color.blueLight};
  
  ${breakpoints.tablet} {
    font-size: 14px;
  }

  ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

interface Props {
  currentCount: number;
  maxCount: number;
}

export function TextCounter({ currentCount, maxCount }: Props) {
  return (
    <TextCounterStyle>
      {numberWithCommas(currentCount)} / {numberWithCommas(maxCount)}
    </TextCounterStyle>
  );
}
