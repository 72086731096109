import style from "../../../assets/scss/main_legacy/section1.module.scss";
import sliderStyle from "./gameImageSlider.module.scss";
import kpassplay1Img from "../../../assets/images/main_legacy/section1/kpassplay1.jpg";
import kpassplay2Img from "../../../assets/images/main_legacy/section1/kpassplay2.jpg";
import kpassplay3Img from "../../../assets/images/main_legacy/section1/kpassplay3.jpg";
import kpassplay4Img from "../../../assets/images/main_legacy/section1/kpassplay4.jpg";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Indicator } from "./Indicator";
import { useTouchEvent } from "../../../hooks/useTouchEvent";

export function GameImageSlider() {
  const { t } = useTranslation(["mainSection1"]);

  const gameImageSliderRef = useRef<HTMLDivElement>(null);
  const [slideNum, setSlideNum] = useState(1);

  const moveSliderRight = () => {
    const nextSlideNum = slideNum + 1;
    setSlideNum(nextSlideNum > 4 ? 4 : nextSlideNum);
  };
  const moveSliderLeft = () => {
    const nextSlideNum = slideNum - 1;
    setSlideNum(nextSlideNum < 1 ? 1 : nextSlideNum);
  };
  const { handleTouchMove, handleTouchEndOnX, handleTouchStart } = useTouchEvent({
    moveLeft: moveSliderLeft,
    moveRight: moveSliderRight,
  });

  useEffect(() => {
    gameImageSliderRef?.current?.style.setProperty("transform", `translateX(${(slideNum - 1) * -100}vw)`);
  }, [slideNum]);

  return (
    <div
      style={{
        transform: "translateX(0)",
      }}
    >
      <Indicator
        totalCount={4}
        wrapperClassName={sliderStyle.sliderIndicator}
        currentSlide={slideNum}
        activeBulletClassName={sliderStyle.active}
      />
      <div
        className={style.gameImagesSlider}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEndOnX}
        ref={gameImageSliderRef}
        style={{ transition: "all 0.4s ease-in-out" }}
      >
        <img src={kpassplay1Img} alt={t("gameImages.img1Alt")} />
        <img src={kpassplay2Img} alt={t("gameImages.img2Alt")} />
        <img src={kpassplay3Img} alt={t("gameImages.img3Alt")} />
        <img src={kpassplay4Img} alt={t("gameImages.img4Alt")} />
      </div>
    </div>
  );
}
