import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../../util/sliceUtil";
import { TestHistoryApi } from "../../api/TestHistoryApi";
import { RootState } from "../index";

export interface TestHistoryState extends SliceState {}

const initialState: TestHistoryState = {
  status: Status.IDLE,
};

export const getTestHistoryCountAsync = createAsyncThunk("testHistory/getTestHistoryCount", () =>
  executePromise(TestHistoryApi.getTestHistoryCount())
);

export const testHistorySlice = createSlice({
  name: "testHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, getTestHistoryCountAsync);
  },
});

export const testHistoryStatusState = (state: RootState) => state.testHistory.status;

export default testHistorySlice.reducer;
