import { Modal } from "../Modal";
import { useAppSelector, useLoader, useModal } from "../../../../hooks/hooks";
import { ModalType } from "../../../../types/common";
import style from "./eventRegistererModal.module.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Gender } from "../../../../types/user";
import { EventRegistererNameField } from "./EventRegistererNameField";
import { EventRegistererBirthdayField } from "./EventRegistererBirthdayField";
import { EventRegistererGenderField } from "./EventRegistererGenderField";
import { EventRegistererRegionField } from "./EventRegistererRegionField";
import { EventRegistererPhoneNumberField } from "./EventRegistererPhoneNumberField";
import { EventRegistererEmailField } from "./EventRegistererEmailField";
import { EventRegistererAgreementField } from "./EventRegistererAgreementField";
import { EventRegistererNotice } from "./EventRegistererNotice";
import { EventRegistererNoticeDetail } from "./EventRegistererNoticeDetail";
import { useEventRegisterer } from "./hooks/useEventRegisterer";
import { eventStatus } from "../../../../store/event/eventSlice";
import { useEventRegistererValidator } from "./hooks/useEventRegistererValidator";

export function EventRegistererModal() {
  const { setLoaderStatus } = useLoader();
  const status = useAppSelector(eventStatus);
  useEffect(() => {
    setLoaderStatus([status]);
    // eslint-disable-next-line
  }, [status]);

  const { showModal, closeModal, openConfirmModal } = useModal();

  const [name, setName] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [gender, setGender] = useState<Gender | "">("");
  const [region, setRegion] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [agreePersonalInfoUsage, setAgreePersonalInfoUsage] = useState<boolean>(false);
  const [showNoticeDetail, setShowNoticeDetail] = useState(false);
  const [isValidatorChecked, setIsValidatorChecked] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const birthdayRef = useRef<HTMLInputElement>(null);
  const genderRef = useRef<HTMLDivElement>(null);
  const regionRef = useRef<HTMLDivElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const agreementRef = useRef<HTMLDivElement>(null);

  const {
    validate,
    invalidNameMessage,
    invalidBirthdayMessage,
    invalidGenderMessage,
    invalidRegionMessage,
    invalidPhoneNumberMessage,
    invalidEmailMessage,
    invalidAgreementMessage,
  } = useEventRegistererValidator({
    nameField: { value: name, ref: nameRef },
    birthdayField: { value: birthday, ref: birthdayRef },
    genderField: { value: gender, ref: genderRef },
    regionField: { value: region, ref: regionRef },
    phoneNumberField: { value: phoneNumber, ref: phoneNumberRef },
    emailField: { value: email, ref: emailRef },
    agreementPersonalInfoUsageField: { value: agreePersonalInfoUsage, ref: agreementRef },
  });

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  const { registerEventTester } = useEventRegisterer();

  const onClickRegisterButton = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setIsValidatorChecked(true);
      if (!validate()) {
        return;
      }

      openConfirmModal(
        {
          title: "신청 완료하기",
          desc: "이벤트 신청을 완료하시겠어요?",
        },
        () =>
          registerEventTester({
            name: name.trim(),
            birthday: `${birthday.substring(0, 4)}-${birthday.substring(4, 6)}-${birthday.substring(6, 8)}`,
            gender: gender === "" ? Gender.MALE : gender,
            region,
            phoneNumber,
            codePhone: "+82",
            email: email.trim(),
          }).then(() => {
            closeModal();
          })
      );
    },
    [
      name,
      birthday,
      gender,
      region,
      phoneNumber,
      email,
      validate,
      setIsValidatorChecked,
      openConfirmModal,
      registerEventTester,
      closeModal,
    ]
  );

  return (
    <Modal
      showModal={showModal === ModalType.EVENT_REGISTERER}
      title={"이벤트 신청하기"}
      closeModalFunction={() => closeModal()}
      modalBgStyle={style.modalWrapper}
      modalCloseButtonStyle={style.modalCloseButton}
      modalTitleStyle={style.modalTitle}
    >
      <div className={style.modalContent}>
        <form className={style.form} onSubmit={(e) => e.preventDefault()}>
          <EventRegistererNameField
            name={name}
            setName={setName}
            nameRef={nameRef}
            invalidMessage={isValidatorChecked ? invalidNameMessage : undefined}
          />
          <EventRegistererBirthdayField
            birthday={birthday}
            setBirthday={setBirthday}
            birthdayRef={birthdayRef}
            invalidMessage={isValidatorChecked ? invalidBirthdayMessage : undefined}
          />
          <EventRegistererGenderField
            gender={gender}
            setGender={setGender}
            genderRef={genderRef}
            invalidMessage={isValidatorChecked ? invalidGenderMessage : undefined}
          />
          <EventRegistererRegionField
            region={region}
            setRegion={setRegion}
            regionRef={regionRef}
            invalidMessage={isValidatorChecked ? invalidRegionMessage : undefined}
          />
          <EventRegistererPhoneNumberField
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberRef={phoneNumberRef}
            invalidMessage={isValidatorChecked ? invalidPhoneNumberMessage : undefined}
          />
          <EventRegistererEmailField
            email={email}
            setEmail={setEmail}
            emailRef={emailRef}
            invalidMessage={isValidatorChecked ? invalidEmailMessage : undefined}
          />
          <EventRegistererAgreementField
            agreePersonalInfoUsage={agreePersonalInfoUsage}
            setAgreePersonalInfoUsage={setAgreePersonalInfoUsage}
            agreementRef={agreementRef}
            invalidMessage={isValidatorChecked ? invalidAgreementMessage : undefined}
          />
          <EventRegistererNotice />
          <EventRegistererNoticeDetail showNoticeDetail={showNoticeDetail} setShowNoticeDetail={setShowNoticeDetail} />
        </form>
        <button className={style.button} onClick={onClickRegisterButton}>
          신청하기
        </button>
      </div>
    </Modal>
  );
}
