import { ErrorType } from "./error";

interface AuthContextType {
  signIn: (user: LoginInfo, successCallback: VoidFunction, errorCallback: (_: ErrorType) => void) => void;
  signOut: (callback: VoidFunction) => void;
  onLoggedIn: (accessToken: string, refreshToken: string) => void;
  isLoggedIn: boolean;
  getAccessTokenFromRefreshToken: (_: string) => Promise<void>;
  getAccessTokenFromRefreshTokenCookie: () => Promise<void>;
  setAccessToken: (_: string) => void;
  loginOnLoad: (args: {
    callbackOnLogout: () => void;
    callbackOnLogin: () => void;
    callbackOnError: () => void;
  }) => void;
}

interface LoginInfo {
  email: string;
  code: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
}

export interface PhoneNumberGetterResponseDTO {
  codePhone: string;
  phoneNumber: string;
}

export enum RequestCodeType {
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export interface RequestCodeDTO {
  email: string;
  requestCodeType: RequestCodeType;
}

interface SignupInfo {
  email: string;
  code: string;
}

export type { AuthContextType, LoginInfo, LoginResponse, SignupInfo };
