import {useCallback} from "react";
import {UserDetail, UserResponse} from "../../../types/user";
import {getUserDetailAsync, setUser, userDetail} from "../../../store/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";

export function useUserInfoGetter() {
  const user: UserDetail | undefined = useAppSelector(userDetail);
  const dispatch = useAppDispatch();

  const getUserInfo = useCallback(async () => {
    try {
      const result: UserResponse = await dispatch(getUserDetailAsync()).unwrap();
      result.user.profiles = result.userProfile;
      dispatch(setUser(result.user));
    } catch (e) {
      throw e; // new Error((e as { message: string }).message);
    }
  }, [dispatch]);

  const removeUserInfo = useCallback(() =>{
    dispatch(setUser({} as UserDetail));
  },[dispatch])

  return {
    getUserInfo,
    user, removeUserInfo
  };
}
