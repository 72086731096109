enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

interface Orders {
  direction: OrderDirection;
  property: string;
  ignoreCase: boolean;
  nullHandling: "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";
  ascending: boolean;
  descending: boolean;
}

interface Pageable {
  offset: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

interface PageResponse<T> {
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
  numberOfElements: number;
  first: boolean;
  last: boolean;
  empty: boolean;
  orders: Orders[];
  pageable: Pageable;
  content: Array<T>;
}

interface Sort {
  columnName: string;
  direction: OrderDirection;
}

interface PageSearchCondition {
  page?: number;
  size?: number;
  websiteType?: string;
  language?: "ko" | "en" | "ru" | "zh";
  sort?: Sort[] | null;
}

interface ProductSearchCondition {
  language?: "ko" | "en" | "ru" | "zh";
  productIdx?: number;
}

interface SearchCondition {
  pageCondition: PageSearchCondition;
}

export const ordersToSortArr = (orders: Orders[] | undefined): Sort[] =>
  orders?.map((o) => ({ columnName: o.property, direction: o.direction })) ?? [];

export type { Orders, Pageable, PageResponse, Sort, PageSearchCondition, SearchCondition, ProductSearchCondition };
export { OrderDirection };
