import style from "../../../../assets/scss/common/modal/addChildModal.module.scss";
import React from "react";
import {InitialProfileInfoDTO, ProfileErrorDTO} from "../../../../types/user";

interface Args{
    profileInfo: InitialProfileInfoDTO;
    setProfileInfo: React.Dispatch<React.SetStateAction<InitialProfileInfoDTO>>;
    errors: ProfileErrorDTO;
}
export function ChildNameField({profileInfo, setProfileInfo, errors}:Args) {
    return (
        <div className={style.infoContainer}>
              <span className={style.infoText}>
                <span className={style.requireText}>[필수]</span> 이름 (본명 권장)
              </span>
            <input
                name="name"
                value={profileInfo.name}
                onChange={(e) => setProfileInfo({...profileInfo, name: e.target.value})}
                placeholder="이름을 입력해 주세요"
                className={style.inputField}
            />
            {errors.name && <div className={style.errorText}>{errors.name}</div>}
        </div>
    )
}