import { useEffect } from "react";
import style from "../../assets/scss/sub/researcher.module.scss";
import childrenPic from "../../assets/images/sub/researcher/children_pic.png";
import { FloatingBtn } from "../common/button/FloatingBtn";
import { useTranslation } from "react-i18next";
import { useFooter } from "../../hooks/hooks";

export function Researcher() {
  const { t } = useTranslation(["subResearcher"]);
  const { setShowFooter } = useFooter();

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    document.title = `${t("title")} | ${process.env.REACT_APP_TITLE}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={style.main}>
      <FloatingBtn />
      <div className={style.title}>
        <h2 className={style.text}>{t("title")}</h2>
      </div>
      <section className={style.researcher}>
        <div className={style.area1}>
          <h3 className={style.title}>
            <span className={style.institute}>{t("institute")}</span>
            <span className={style.researcher}>{t("researcher")}</span>
          </h3>
          <p className={`${style.phrase} ${style.phrase1}`}>{t("desc1")}</p>
          <p className={`${style.phrase} ${style.phrase2}`}>{t("desc2")}</p>
          <p className={style.phrase3}>{t("desc3")}</p>
        </div>
        <div className={style.area2}>
          <div className={style.childrenPic}>
            <img src={childrenPic} alt={t("imageAlt")} />
          </div>
          <p className={style.phrase1}>{t("article2Desc1")}</p>
          <p className={style.phrase2}>{t("article2Desc2")}</p>
        </div>
      </section>
    </main>
  );
}
