import { BannerContext } from './banner/BannerContext';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../store";
import { useContext } from "react";
import { AuthContext } from "./auth/AuthContext";
import { LanguageContext } from "./language/LanguageContext";
import { ModalContext } from "./modal/ModalContext";
import { LoaderContext } from "./loader/LoaderContext";
import { FooterContext } from "./footer/FooterContext";
import { MainScrollingContext } from "./main_scrolling/MainScrollingContext";
import { PageContext } from "./location/PageContext";
import { UserAgentContext } from "./user_agent/UserAgentContext";
import { ScrollingEventContext } from "./scroll_event/ScrollingEventContext";
import { TimeConverterContext } from "./timeconverter/TimeConverterContext";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAuth = () => useContext(AuthContext);
export const useLanguage = () => useContext(LanguageContext);
export const useTimeConverter = () => useContext(TimeConverterContext);
export const useModal = () => useContext(ModalContext);
export const useLoader = () => useContext(LoaderContext);
export const useFooter = () => useContext(FooterContext);
export const useAwardBanner = () => useContext(BannerContext);
export const useMainScrolling = () => useContext(MainScrollingContext);
export const usePage = () => useContext(PageContext);
export const useUserAgent = () => useContext(UserAgentContext);
export const useScrollingEvent = () => useContext(ScrollingEventContext);
