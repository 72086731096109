import { useCallback } from "react";
import { KPASSReviewDTO, KPASSReviewVO } from "../review";

export function useKpassReviewVOType() {
  const toKpassReviewVOFromDTO = useCallback((dto: KPASSReviewDTO): KPASSReviewVO => dto as KPASSReviewVO, []);

  return {
    toKpassReviewVOFromDTO,
  };
}
