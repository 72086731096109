import style from "../../../assets/scss/sub/purchase/purchaseVoucher.module.scss";
import voucherIcon from "../../../assets/images/sub/purchase_voucher/voucher_icon.svg";
import plusBtnIcon from "../../../assets/images/sub/purchase_voucher/quantity_plus_btn.svg";
import minusBtnIcon from "../../../assets/images/sub/purchase_voucher/quantity_minus_btn.svg";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ArticleTitle } from "../ArticleTitle";
import { MainWrapper } from "../MainWrapper";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useAuth,
  useLoader,
  useModal,
  useUserAgent,
  useLanguage,
} from "../../../hooks/hooks";
import {
  getProductImagesAsync,
  getTheLatestProductDetailAsync,
  productDetail,
  productStatus,
  setProduct,
} from "../../../store/product/productSlice";
import {
  DiscountType, DisplayPosition,
  ProductDetailImageDTO,
  ProductFile,
  ProductFileType,
  ProductImage,
} from "../../../types/product";
import { ModalType } from "../../../types/common";
import { orderStatus } from "../../../store/order/orderSlice";
import { createCartAsync } from "../../../store/cart/cartSlice";
import { CartResponse } from "../../../types/cart";

interface Price {
  regularPrice: number;
  sellingPrice: number;
}

export function PurchaseVoucher({type}: { type: string }) {
  const statusProduct = useAppSelector(productStatus);
  const statusOrder = useAppSelector(orderStatus);
  const productDetailInfo = useAppSelector(productDetail);
  const dispatch = useAppDispatch();
  const { language } = useLanguage();

  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { openAlertModal, openModal } = useModal();
  const { setLoaderStatus } = useLoader();
  const { isMobile } = useUserAgent();

  const purchaseType = type === 'voucher' ? DisplayPosition.USER_WEB_KPASS : DisplayPosition.COUNSELOR_VOUCHER;
  const [quantity, setQuantity] = useState(1);

  const [productImages, setProductImages] = useState<ProductFile[]>([]);
  const [productImagesMobile, setProductImagesMobile] = useState<ProductFile[]>([]);

  const getSortedImageUrls = useCallback(
    (images: ProductImage[]) =>
      images
        .sort((a, b) => a.orderNum - b.orderNum)
        .map((img) => {
          const url = img.file.uri;
          return {
            type: ProductFileType.getFileTypeFromUrl(url),
            url,
          };
        }),
    []
  );

  const getProductDetail = useCallback(async () => {
    try {
      const result = await dispatch(getTheLatestProductDetailAsync({
          condition: {language},
          displayPosition: purchaseType
      })).unwrap();
      const {desktopImages, mobileImages}: ProductDetailImageDTO = await dispatch(getProductImagesAsync({
              condition: {productIdx: result.idx, language},
              displayPosition: purchaseType
          })
      ).unwrap();

      setProductImages(getSortedImageUrls(desktopImages));
      setProductImagesMobile(getSortedImageUrls(mobileImages));

      dispatch(setProduct(result));
    } catch (error) {
      console.error(error);
      openAlertModal(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAlertModal, language]);

  const getDiscountText = useCallback(() => {
    switch (productDetailInfo?.discountType) {
      case DiscountType.AMOUNT:
        return `${Math.floor(productDetailInfo?.discountValue)}원`;
      case DiscountType.PERCENT:
        return `${Math.floor(productDetailInfo?.discountValue)}%`;
      default:
        return "";
    }
  }, [productDetailInfo]);

  const onClickPurchaseBtn = async (e: React.MouseEvent) => {
    if (isLoggedIn) {
      if (productDetailInfo) {
        try {
          const carts: CartResponse[] = await dispatch(
            createCartAsync([
              {
                productIdx: productDetailInfo.idx,
                quantity: quantity,
              },
            ])
          ).unwrap();

          const cartIdxes = carts.map((it) => it.idx);
          navigate(`/order?cart-idxes=${cartIdxes}`);
        } catch (error) {
          console.error(error);
          openAlertModal(null);
        }
      }
    } else {
      openModal(ModalType.LOGIN);
      e.stopPropagation();
    }
  };

  const onClickAddQtyBtn = () => {
    setQuantity(quantity + 1);
  };

  const onClickSubtractQtyBtn = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const totalPrice: Price = useMemo(() => {
    if (productDetailInfo) {
      return {
        regularPrice: productDetailInfo.regularPrice * quantity,
        sellingPrice: productDetailInfo.sellingPrice * quantity,
      };
    } else {
      return {
        regularPrice: 0,
        sellingPrice: 0,
      };
    }
  }, [quantity, productDetailInfo]);

  useEffect(() => {
    document.title = `검사이용권 구매 | ${process.env.REACT_APP_TITLE}`;
    window.scroll({ top: 0 });
    getProductDetail().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    setLoaderStatus([statusOrder]);
  }, [setLoaderStatus, statusOrder]);

  useEffect(() => {
    setLoaderStatus([statusProduct]);
  }, [setLoaderStatus, statusProduct]);

  const getDetailImageAndVideoComponents = useCallback(
    (files: ProductFile[]) =>
      files.map((file, index) => (
        <Fragment key={`detail-image-${index}`}>
          {file.type === ProductFileType.IMAGE && <img src={file.url} alt={"상품 상세 이미지"} />}
          {file.type === ProductFileType.VIDEO && (
            <div className={style.videoWrapper}>
              <video controls={true} autoPlay={true} muted={true} playsInline={true}>
                <source src={file.url} />
              </video>
            </div>
          )}
        </Fragment>
      )),
    []
  );

  const hasDiscountValue = useMemo(
    () => Boolean(productDetailInfo?.discountValue && productDetailInfo.discountValue > 0),
    [productDetailInfo]
  );

  return (
    <MainWrapper showFooter={true} mainClassName={`${style.main}`}>
      <>
        <div className={style.title}>
          <div className={style.bgItem1}></div>
          <ArticleTitle title="검사이용권 구매" className={style.textArea} />
        </div>
        <div className={style.productDetail}>
          <div className={style.thumbnail}>
            {productDetailInfo?.thumbnail.uri && (
              <img src={productDetailInfo?.thumbnail.uri} alt={"상품 썸네일 이미지"} />
            )}
          </div>
          <div className={style.desc}>
            <span className={style.title}>{productDetailInfo?.name}</span>
            <ul className={style.descList}>
              {productDetailInfo?.description?.map((desc, idx) => (
                <li key={`normal-desc-${idx}`}>{desc}</li>
              ))}
              {productDetailInfo?.specialDescription?.map((desc, idx) => (
                <li key={`special-desc-${idx}`} className={style.accent}>
                  {desc}
                </li>
              ))}
            </ul>
            <div className={style.dashLine}></div>
            <div className={style.quantityAndPrice}>
              <div className={style.quantity}>
                <button className={style.qtyBtn}>
                  <img src={minusBtnIcon} alt={"수량 차감 아이콘"} onClick={onClickSubtractQtyBtn} />
                </button>
                <span className={style.number}>{quantity}</span>
                <button className={style.qtyBtn}>
                  <img src={plusBtnIcon} alt={"수량 추가 아이콘"} onClick={onClickAddQtyBtn} />
                </button>
              </div>
              {hasDiscountValue && (
                <div className={style.regularPrice}>{totalPrice.regularPrice.toLocaleString()}원</div>
              )}
              <div className={`${style.discountPrice} ${hasDiscountValue ? style.positionBottomRow : ""}`}>
                {hasDiscountValue && (
                  <>
                    <img className={style.icon} src={voucherIcon} alt="상품 아이콘" />
                    <span className={style.badge}>{getDiscountText()}할인!</span>
                  </>
                )}
                <span className={style.price}>{totalPrice.sellingPrice.toLocaleString()}원</span>
              </div>
            </div>
            <button className={style.purchaseBtn} onClick={onClickPurchaseBtn}>
              구매하기
            </button>
          </div>
        </div>
        <div className={style.imageArea}>
          <div className={style.bgItem1}></div>
          <div className={style.bgItem2}></div>
          <div className={style.bgItem3}></div>
          {isMobile ? (
            <>{getDetailImageAndVideoComponents(productImagesMobile)}</>
          ) : (
            <>{getDetailImageAndVideoComponents(productImages)}</>
          )}
        </div>
      </>
    </MainWrapper>
  );
}
