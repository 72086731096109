import { HttpClient } from "./HttpClient";
import { SearchCondition } from "../types/page";
import { camelToSnakeCase } from "../util/commonFunctions";

export class MainApi extends HttpClient {
  private static classInstance?: MainApi;

  public static api = MainApi.getInstance().instance;
  public static urlPrefix = "/api/v1/kpass";

  private constructor() {
    super(process.env.REACT_APP_BASE_URL as string);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new MainApi();
    }

    return this.classInstance;
  }

  public setToken(token: string) {
    this.instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  public static toParamStringFromObject<T extends object>(param: T) {
    const keyArr = Object.keys(param);
    if (keyArr.length > 0) {
      const paramStr = keyArr.reduce((acc, k) => {
        const value = param[k as keyof T];

        if (typeof value === "function") return acc;
        else {
          if (k === "pageCondition") {
            const pageParam = (param as SearchCondition)[k];
            if (pageParam?.page) {
              acc += `&page=${pageParam.page}`;
            } else {
              acc += `&page=0`;
            }

            if (pageParam?.size) {
              acc += `&size=${pageParam.size}`;
            } else {
              acc += `&size=10`;
            }

            if (pageParam?.sort) {
              const sortString = pageParam.sort.map((s) => `&sort=${s.columnName},${s.direction}`).join("");
              acc += sortString;
            } else {
              acc += `&sort=createAt,desc`;
            }
            return acc;
          } else {
            if (k === "websiteType") {
              return acc + `&${k}=${encodeURIComponent(String(value))}`;
            } else if (k && value !== undefined && value !== null && (value as unknown as string) !== "") {
              return acc + `&${camelToSnakeCase(k)}=${encodeURIComponent(String(value))}`;
            } else {
              return acc;
            }
          }
        }
      }, "");

      return `?${paramStr.substring(1)}`;
    }

    return "";
  }
}
