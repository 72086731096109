import style from "../koreanKpassStandardization.module.scss";
import { ArticleHeaderKO } from "./ArticleHeaderKO";
import React from "react";
import { ArticleFooterKO } from "./ArticleFooterKO";
import { SubtitleNumbering } from "../../main/article3/SubtitleNumbering";
import firstFunctionalUnit from "../../../assets/images/kpass_standardization/first_functional_unit.svg";
import secondFunctionalUnit from "../../../assets/images/kpass_standardization/second_functional_unit.svg";
import thirdFunctionalUnit from "../../../assets/images/kpass_standardization/third_functional_unit.svg";
import unitedUnit from "../../../assets/images/kpass_standardization/united_unit.svg";

export function TheoreticalBackgroundKO() {
  return (
    <article className={style.article}>
      <div className={style.contentHeaderWrapper}>
        <ArticleHeaderKO />
      </div>
      <section className={style.contentSection}>
        <div className={style.sectionTitleWrapper}>
          <SubtitleNumbering lightNumber={0} boldNumber={1} fontSize={"85px"} />
          <h2 className={style.articleTitle}>
            한국판 K-PASS의
            <br />
            이론적 배경
          </h2>
        </div>
        <div className={style.part}>
          <h3 className={style.partTitle}>인지과정에 관한 PASS 이론</h3>
          <p className={style.partSummary}>
            &nbsp;PASS 이론은 이론 심리학과 응용 심리학의 합병 결과로 나타났으며, Das, Naglieri, 및 Kirby(1994)에 의해
            가장 최근에 요약된 것이다. Das와 그의 동료들은, 우리가 지금 PASS라고 부르는 인지적 관점에서 지능이
            재해석되어야 한다고 주장하면서, 미국, 영국, 및 캐나다의 심리학 정기간행물에 가장 많이 인용되는 소련의 학자
            Luria(1996, 1970, 1973, 1976, 1980, 1982)와 지능 분야 간의 연결을 시도하였다(Solso & Hoffman, 1991). 이들은
            PASS 과정들이 인간의 인지기능에 있어서의 본질적인 요소라고 주장하였다.
            <br />
            &nbsp;PASS 이론은 지능을 인지과정(cognitive process)으로 재개념화하는 관점을 제공하고 있다. 이 이론은 인간의
            인지기능이 개인의 지식 기반(knowledge base)을 반영하고, 또 이를 변경시키는 네 개의 주요 활동, 즉 계획기능,
            주의집중, 동시처리, 순차처리에 기초를 두고 있다고 상정하고 있다(Das, 등, 1994).
            <br />
            &nbsp;이 이론에 의하면 인간의 인지 기능은 네 개의 요소, 즉 인지적 통제, 과정 및 지식의 활용, 의도성, 및
            원하는 목표를 성취하기 위한 자기조절, 등을 제공하는 계획 과정, 시간에 따라 초점 있고 선택적인 인지 활동을
            제공하는 주의집중 과정, 정보를 처리하는 두 가지 형식인, 동시적 처리 및 순차적 처리 과정을 포함한다.
          </p>
          <ul className={style.partContent}>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>1</div>
                <h4 className={style.title}>순차처리(Successive Processing)</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;순차적 처리는 개인이 자극을 특정한 일련의 순서로 통합하는 정신 과정이며, 이 순서는 사슬과 같은
                연쇄적의 형태를 갖는다. 연속적 처리는 사물들이 서로 엄격히 정해진 순서를 따라야 하는 경우에
                필요하다(Luria, 1966).
                <br />
                &nbsp;순차적 처리의 두드러진 특징은 각 요소가 바로 선행하는 요소에만 관련되고, 이러한 자극들이 상호
                관련되지 않는다는 점이다.
                <br />
                &nbsp;순차적 처리는 순서적이고 구문론적 요소가 강하다. 순차적 처리의 순서적 측면은 사물을 계열적으로
                지각하는 것과 소리나 동작을 순서대로 형성하는 것을 포함한다. 순차적 처리를 포함하고 있는 과제의 예는,
                구어를 순차적으로 조직하는 것과 분리된 소리나 운동 충동을 연속적인 계열로 종합하는 것이다(Luria &
                Tsvetkova, 1990).
                <br />
                &nbsp;순차적 처리의 구문론적 측면은 담화 언어의 의미를 이해하게 해주는 바, 이는 특히 전체 담화의
                개별적인 요소가 항상 특정한 연속적인 계열로 조직된 것처럼 작용할 때 그러하다(Luria, 1966).
                <br />
                &nbsp;다시 말하면, 담화의 순차적인 제시가 의미를 이끌어 낸다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>2</div>
                <h4 className={style.title}>동시처리(Simultaneous Processing)</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;동시처리는 흩어져 있는 자극들을 하나의 전체 또는 집단으로 통합하는 정신 과정이다(Luria, 1970).
                동시적 처리의 본질은 개인이 자극의 요소들을 지각적 또는 개념적으로 하나의 전체로 관련지우기 위해
                사용되는 정보처리 기능이다. 동시적 처리는 공간적 그리고 논리-문법적 요소가 강하다. 동시적 처리의 공간적
                측면은 자극을 집단으로 지각하는 것과 복잡한 시각적 심상을 내면적으로 형성하는 것을 포함한다.
                <br />
                &nbsp;동시적 처리의 논리-문법적 요소는 단어 관계의 이해, 전치사, 어형 변화, 등을 통해서 단어를
                아이디어로 통합하여 의미를 획득할 수 있도록 하는 것이다.
                <br />
                &nbsp;따라서, 동시적 처리는 비언어적-공간적 활동뿐만 아니라 언어적-문법적 활동도 포함하고 있다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>3</div>
                <h4 className={style.title}>계획기능(Planning)</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;계획기능은 개인이 문제 해결에 대하여 결정하고, 선택하고, 적용하고, 평가하는 정신 과정이다. 계획
                과정은 문제 해결의 방법이나 해법이 분명하지 않은 문제들을 해결하는 수단을 제공한다. 이는 단순한 과제나
                복잡한 과제에 모두 적용되고, 주의집중, 동시처리, 순차처리를 포함하기도 한다.
                <br />
                &nbsp;K-PASS의 계획기능 검사에서 성공하려면, 아동이 행위에 관한 계획을 고안하고, 방법의 가치를 평가하고,
                그의 효과를 추적하고, 과제가 새로운 변화를 요구할 경우 그에 따라 기존의 계획을 수정하거나 바꾸고,
                성급하게 행동하려는 충동을 통제할 필요가 있다. 계획기능은 교실에서나 일상생활에서 문제 해결을 위한 어떤
                방법이 필요한 모든 활동에 필수적인 것이다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>4</div>
                <h4 className={style.title}>주의집중(Attention)</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;주의집중은 개인이 특정한 자극에 선택적으로 주의를 집중하고, 자주 발생하는 다른 자극에 대한 반응을
                억제하는 정신 과정이다. K-PASS에 포함되어 있는 주의집중 하위검사는 Luria(1973)가 언급한 대로 높은 수준의
                복잡한 주의집중 형태로 묘사될 수 있다.
                <br />
                &nbsp;K-PASS의 주의집중 과제는 학습을 위해 필요한 자극에 선택적으로 주의를 두는 능력을 측정하고, 선택된
                자극에 학습을 위해 필요한 시간동안 주의력을 유지하는 능력을 측정하며, 그리고 방해 자극에 대한 반응을
                억제할 수 있는 능력을 측정하는 과제들로 구성되어 있다.
              </p>
            </li>
          </ul>
        </div>
        <div className={style.part}>
          <h3 className={style.partTitle}>Luria의 신경심리학 이론과 K-PASS</h3>
          <div className={style.partSummary}>
            &nbsp;신경심리학자로서 Luria(1970)의 목표는 복잡한 인간행동의 과정, 특히 문제-해결 능력과 정보를 받아들이고
            통합하는 고차적 수준의 처리 과정과 관련되어 있는 뇌 시스템 및 뇌기능의 지도를 그려내는 것이었다. 그는
            대뇌피질 기능 지도를 자세하게 나타내려 하기보다는 뇌의 기본적 기능을 이해하기 위해 세 가지의 구성요소들을
            제시하였다.
            <br />
            <br />
            &nbsp;첫 번째 요소는 각성과 주의력을 담당하는 영역 1(Block I), 두 번째 요소는 정보를 분석하고 정보화하고
            저장하기 위해 사용되는 감각적 요소를 담당하는 영역 2(Block II), 세 번째 요소는 행동을 프로그래밍하고 계획을
            세우기 위해 집행기능을 적용하는 영역 3(Block III)이다.
            <div className={style.functionalUnitImages}>
              <img src={firstFunctionalUnit} alt={"First Functional Unit"} />
              <img src={secondFunctionalUnit} alt={"Second Functional Unit"} />
              <img src={thirdFunctionalUnit} alt={"Third Functional Unit"} />
            </div>
            &nbsp;영역 1의 기능은 망상활성계와 일치하고 영역 3의 기능은 전두엽의 앞쪽 부분과 관련되어 있다. “두 부분
            모두 뇌기능의 전체적인 효율성과 관계되기 때문에 영역 3의 기능은 영역 1의 기능과 매우 밀접하게 관련되어
            있다.”(Reitan, 1988, p. 335).
            <br />
            &nbsp;Luria는 정보를 받아들이고 저장하는 기능은 후두엽, 두정엽, 그리고 중심열 뒷부분의 측두엽에 위치하고
            있는 주로 시각, 청각, 감각, 근육운동지각 등 감각정보를 부호화하는 것에 특정화된 영역 2의 기능과 관련되어
            있다고 보았다. Luria(1966)는 영역 2 내에서 “대뇌피질의 통합적 활동을 두 가지 기본적인 형태”로 구분하고
            순차적과 동시적으로 명명하였다. Luria가 세 가지 영역을 뇌 기능의 양상으로 나누어 구분했으나 그가 가장
            중요하게 강조한 것은 이 세 가지 영역들이 복잡한 행동을 지원할 수 있도록 하나의 기능적 시스템으로 통합되는
            것이었다. 영역 2의 역할은 “세 개의 영역을 연결하는 것과 다양한 감각을 통해 정보를 받아들이고 효과적으로
            통합하는 것이다.”(Reitan, 1988, p. 333). Naglieri(1999) 역시, 세 가지 영역과 관련된 과정은 “개인의 축적된
            지식과 서로 의존하여 활성화되고 상호작용하는 과정을 포함한다.”라고 강조했다(p. 21).
            <br />
            <br />
            &nbsp;많은 실증적 연구에서도 Luria(1966, 1970, 1973, 1980)의 세 가지 기능단위의 임상적 보고를 지지하는
            결과들이 보고되고 있다(예를 들면, Das, Kirby, & Jarman, 1979; Das, Naglieri, & Kirby, 1994; Naglieri, 1999;
            Naglieri & Das, 1997). 그러나, 학자들은 Luria의 이론을 일반적인 인지와 지능을 측정하는데 적용시키려면 각
            영역을 구체적으로 측정하기보다는 영역들 간의 통합에 중점을 두는 것이 중요하다고 보고 있다. 실제로 Naglieri와
            Das, 그리고 그의 동료들 또한 어떤 복잡한 행동도 이 세 가지 영역의 통합을 이해하는 것이 필수적이라고
            강조한다.
            <br />
            &nbsp;주의력, 집중력, “에너지 수준 조절과 대뇌피질의 상태”(Reitan, 1988, p. 333)를 포함하는 영역 1의 각성
            기능은 본질적으로 학자들이 말하는 높은 수준의 복잡한 지적 행동에는 해당되지 않지만 인지적 과제를 풀어야 하는
            검사를 성공적으로 수행하기 위한 핵심 기능이다. 이와 유사하게, Luria(1966)는 연속적이고 동시적인 과정을 거쳐
            들어오는 자극을 저장하고 분석하는 영역 2의 기능을 문제 해결 기능이 아닌 “부호화”기능으로 정의한다.
            <br />
            &nbsp;Luria는 영역 2에서 관장되는 두 가지 부호화 기능 간의 구분에 대한 관심뿐만 아니라 입력되는 자극들을
            통합하고 그리고 영역 3과의 연합을 형성하는 기능을 강조하고 있다.
            <div className={style.imageWrapperInsideParagraph}>
              <img src={unitedUnit} alt={"united unit"} style={{ width: "800px" }} />
            </div>
            &nbsp;Luria(1970)에 의하면, “모든 복잡한 형태의 행동은 뇌의 서로 다른 영역에 위치한 여러 가지 능력들의 공동
            조작에 의해 결정된다.”(p. 68). 새로운 내용을 효율적으로 학습할 때, 이와 같은 공동 조작은 특히 학습능력을
            결정한다.
            <br />
            &nbsp;K-PASS의 과제는 복잡한 시각적, 청각적 자극을 동시적으로 부호화하고 저장(영역 2) 하는 동안, 그리고
            새로운 내용을 효율적으로 학습하기 위해 전략을 생성(영역 3) 하는 동안 영역 1의 선택적 주의가 요구된다.
            <br />
            &nbsp;마찬가지로, 계획력 척도의 하위검사들은 피검사자가 가설을 생성하고 결정하고 효율적으로 작업기억을
            적용하고 자기-모니터링 행동을 할 수 있도록 하는 영역 1의 강한 주의력 기술이 요구된다.
          </div>
        </div>
      </section>
      <div className={style.contentFooterWrapper}>
        <ArticleFooterKO />
      </div>
    </article>
  );
}
