import { MainApi } from "./MainApi";
import { UserProfile, NewUserProfile, userProfileIdx } from "../types/user";

export class ProfileApi {
  static baseUrl = `${MainApi.urlPrefix}/user`;
  static profileAuthUrl = `${MainApi.urlPrefix}/game-content/user/sign-in`;

  static getProfiles = () => () => MainApi.api.get<UserProfile[]>(`${ProfileApi.baseUrl}/profile`);
  static getProfile = (profileIdx: number) => () =>
    MainApi.api.get<UserProfile>(`${ProfileApi.baseUrl}/profile/${profileIdx}`);

  // user/profile/test-history/33266?page=0&size=3
  static getProfileTestHistory = (profileIdx: number) => () =>
    MainApi.api.get<UserProfile>(`${ProfileApi.baseUrl}/profile/test-history/${profileIdx}`);

  // /api/v1/dcas/user/profile/test-history/
  static getDcasUserTestHistory = (profileIdx: number) => () =>
    MainApi.api.get(`api/v1/dcas/user/profile/test-history/${profileIdx}`);

  static createProfile = (param: NewUserProfile) => () => MainApi.api.post(`${ProfileApi.baseUrl}/profile`, param);

  static deleteProfile = (profileIdx: number) => () =>
    MainApi.api.delete(`${ProfileApi.baseUrl}/profile/${profileIdx}`);

  static editProfile = (profileIdx: number, param: NewUserProfile) => () =>
    MainApi.api.post(`${ProfileApi.baseUrl}/profile/update/${profileIdx}`, param);

  static getProfileAuthToken = (param: userProfileIdx) => () => MainApi.api.post(`${ProfileApi.profileAuthUrl}`, param);

  static getProfileOrganInfo = (profileIdx: number) => () =>
    MainApi.api.get(`${ProfileApi.baseUrl}/profile/organ/${profileIdx}`);

  static getOrganTestResult = (name: string, code: string) => () =>
    MainApi.api.post(`${ProfileApi.baseUrl}/profile/organ-test-result`, {
      targetName: name,
      targetOrganSignInCode: code,
    });

  static addOrganTestResult = (profileIdx:number, name: string, code: string) => () => 
  MainApi.api.post(`${ProfileApi.baseUrl}/profile/connect/organ-test-result`, {
    userProfileIdx: profileIdx,
    targetName: name,
    targetOrganSignInCode: code,
  })
}
