import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../../util/sliceUtil";
import { PopupApi } from "../../api/PopupApi";
import { RootState } from "../index";
import { PageSearchCondition } from "../../types/page";

export interface PopupState extends SliceState {}

const initialState: PopupState = {
  status: Status.IDLE,
};

export const getPopupsAsync = createAsyncThunk("popup/getPopups", (condition: PageSearchCondition) =>
  executePromise(PopupApi.getPopups(condition))
);

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, getPopupsAsync);
  },
});

export const popupStatusState = (state: RootState) => state.popup.status;

export default popupSlice.reducer;
