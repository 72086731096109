import React, { useEffect, useCallback, useState } from "react";
import style from "../../assets/scss/sub/paymentHistory.module.scss";
import LeftBtnIcon from "../../assets/images/mypage/left_button_icon.svg";
import RightBtnIcon from "../../assets/images/mypage/right_button_icon.svg";
import { useAppDispatch, useTimeConverter } from "../../hooks/hooks";
import { getOrdersAsync, getRefundOrderAsync } from "../../store/order/orderSlice";
import { useNavigate } from "react-router-dom";
import { PaymentInfoModal } from "../common/modal/PaymentInfoModal";
import { useUserAgent } from "../../hooks/hooks";

export function PaymentHistory() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { timeConverter } = useTimeConverter();
  const onClickVoucher = () => navigate(`/purchase-voucher`);
  const [orders, setOrders] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const { isMobile } = useUserAgent();

  const getOrdersList = useCallback(
    async (page: number) => {
      try {
        const response: any = await dispatch(getOrdersAsync(page)).unwrap();
        const { content, totalPages, totalElements }: any = response || {};

        if (content) {
          setOrders(content);
          setTotalPages(totalPages > 0 ? totalPages : 1);
          setTotalOrders(totalElements);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const renderOrderStatus = (status: string) => {
    if (isMobile && status === "AWAITING_DEPOSIT") {
      return (
        <>
          입금
          <br />
          확인중
        </>
      );
    }

    switch (status) {
      case "PAID":
        return "결제완료";
      case "CANCELLED":
        return "환불완료";
      case "OUTSTANDING_PAYMENT":
        return "주문취소";
      case "AWAITING_PAYMENT":
        return "결제대기중";
      case "AWAITING_DEPOSIT":
        return "입금확인중";
      case "PARTIALLY_CANCELLED":
        return "부분취소";
      default:
        return "-";
    }
  };

  const getRefundOrder = useCallback(
    async (orderIdx: number) => {
      try {
        const response: any = await dispatch(getRefundOrderAsync(orderIdx)).unwrap();

        if (response) {
          setSelectedOrder((prevOrder: any) => ({
            ...prevOrder,
            refundPrice: response[0].refundPrice,
            refundAt: timeConverter.convertToFormattedHHMMSS(
              timeConverter.convertToLocalDate(response[0].createAt).split(" ")[0]
            ),
          }));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, timeConverter]
  );

  useEffect(() => {
    getOrdersList(currentPage);
  }, [getOrdersList, currentPage]);

  useEffect(() => {
    if (isModalOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [isModalOpen]);

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handleOpenModal = (order: any) => {
    setSelectedOrder(order);
    if (order.status === "CANCELLED") getRefundOrder(order.idx);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  // 페이지네이션 범위를 계산하는 부분 추가
  const paginationRange = 5;
  const startPage = Math.max(0, Math.min(currentPage - Math.floor(paginationRange / 2), totalPages - paginationRange));
  const endPage = Math.min(totalPages, startPage + paginationRange);

  return (
    <div className={style.container}>
      <div className={style.containerContentBox}>
        <h2 className={style.containerTitle}>
          결제내역&nbsp;<span className={style.profileCount}>({totalOrders}건)</span>
        </h2>
      </div>
      <div>
        <div className={style.labelContainer}>
          <div className={style.firstLabel}>주문일시</div>
          <div className={style.secondLabel}>상품명</div>
          <div className={style.thirdLabel}>주문개수</div>
          <div className={style.fourthLabel}>주문상태</div>
          <div className={style.fifthLabel}>상세보기</div>
        </div>
        <div className={style.resultInfoContainer}>
          {orders.length > 0 ? (
            orders.map((order) => (
              <div key={order.idx} className={style.resultInfoData}>
                <div className={style.firstInfoData}>
                  {timeConverter.convertToFormattedDate(timeConverter.convertToLocalDate(order.createAt))}
                  <br /> {timeConverter.convertToLocalDate(order.createAt).split(" ")[1]}
                </div>
                <div className={style.secondInfoData}>{order.orderTitle}</div>
                <div className={style.thirdInfoData}>{order.orderQuantity}개</div>
                <div
                  className={`${style.fourthInfoData} 
                  ${
                    order.status === "PAID"
                      ? style.paid
                      : order.status === "CANCELLED"
                      ? style.cancel
                      : order.status === "OUTSTANDING_PAYMENT"
                      ? style.outstanding
                      : order.status === "AWAITING_PAYMENT"
                      ? style.awaitingPayment
                      : order.status === "AWAITING_DEPOSIT"
                      ? style.awaitingDeposit
                      : order.status === "PARTIALLY_CANCELLED"
                      ? style.partiallyCancelled
                      : style.defaultStatus
                  }`}
                >
                  {renderOrderStatus(order.status)}
                </div>
                <div className={style.fifthInfoData}>
                  <button className={style.guideButton} onClick={() => handleOpenModal(order)}>
                    {isMobile ? "상세" : "상세보기"}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className={style.noPaymentInfo}>
              결제 내역이 없습니다.
              <br />
              이용권을 구매하고 검사를 진행해 보세요!
              <br />
              <button className={style.buyVoucherButton} onClick={onClickVoucher}>
                이용권 구매하기
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4986 14.2679L15.5 9.63393L11.4986 5L10.628 5.96365L13.2012 8.94359L5.74933 8.94359L5.74933 10.3198L13.2051 10.3198L10.628 13.3042L11.4986 14.2679Z"
                    fill="#583BE2"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
        <div className={style.paginationContainer}>
          <div className={style.paginationDiv}>
            <img
              src={LeftBtnIcon}
              className={style.leftButtonImg}
              onClick={() => handlePageChange(currentPage - 1)}
              alt="previous"
            />
            {Array.from({ length: endPage - startPage }, (_, i) => startPage + i).map((page) => (
              <button
                key={page}
                className={`${style.paginationBtn} ${page === currentPage ? style.active : ""}`}
                onClick={() => handlePageChange(page)}
              >
                {page + 1}
              </button>
            ))}
            <img
              src={RightBtnIcon}
              className={style.rightButtonImg}
              onClick={() => handlePageChange(currentPage + 1)}
              alt="next"
            />
          </div>
        </div>
      </div>
      {isModalOpen && <PaymentInfoModal order={selectedOrder} onClose={handleCloseModal} />}
    </div>
  );
}
