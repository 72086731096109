import { useCallback } from "react";
import { getTesterInfoByReviewIdxAsync } from "../../../../../store/review/reviewSlice";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { TesterInfo } from "../../../../../types/user";

export function useTesterInfoGetter() {
  const dispatch = useAppDispatch();

  const getTesterInfoByReviewIdx = useCallback(
    async (reviewIdx: number): Promise<TesterInfo> => await dispatch(getTesterInfoByReviewIdxAsync(reviewIdx)).unwrap(),
    [dispatch]
  );

  return {
    getTesterInfoByReviewIdx,
  };
}
