import style from "../koreanKpassStandardization.module.scss";
import { ArticleHeaderKO } from "./ArticleHeaderKO";
import { SubtitleNumbering } from "../../main/article3/SubtitleNumbering";
import React from "react";
import { ArticleFooterKO } from "./ArticleFooterKO";

export function UtilizationAndApplicationKO() {
  return (
    <article className={style.article}>
      <div className={style.contentHeaderWrapper}>
        <ArticleHeaderKO />
      </div>
      <section className={style.contentSection}>
        <div className={style.sectionTitleWrapperWideMarginBottom}>
          <SubtitleNumbering lightNumber={0} boldNumber={2} fontSize={"85px"} />
          <h2 className={style.articleTitle}>K-PASS의 활용과 적용</h2>
        </div>
        <div className={style.part}>
          <p className={style.partSummary}>
            &nbsp;K-PASS는 개인의 능력과 인지기능의 수준을 알아보고자 할 때 활용되도록 개발되었고, 검사자는 (1) 아동
            개인 내의 4가지 처리 수준 간 상대적 차이(강점과 약점), (2) 4가지 처리능력별 또래들과의 상대적 차이, (3)
            K-PASS 척도 점수와 학업성취도 간의 관련성, (4) 이러한 정보가 아동에 대하여 지니는 함축성에 관한 정보를 얻을
            수 있다.
            <br />
            &nbsp;K-PASS의 활용 범위에는 학습의 강점과 약점을 진단하기, 학습장애 · 주의력 결핍 · 정신지체 · 영재성 등을
            분류하기, 그리고 특정한 교육적 처치, 교육, 치료 프로그램의 적절성 고려 등이 포함된다.
          </p>
          <ul className={style.partContent}>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>1</div>
                <h4 className={style.title}>학업성취의 예측</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;K-PASS는 아동들의 학업성취를 예측하는 기능을 지니도록 개발되었다. 개별적인 PASS 척도는 특정한
                학업수행 영역에서 성공 및 실패를 예측하는 데 적합하다. 전체 척도의 표준점수는 특히 학업성취의 가장 좋은
                전반적 예언 요인이 되고, 반면에 개별적인 PASS 척도의 표준점수는 특정한 학업수행 영역과 관련된다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>2</div>
                <h4 className={style.title}>주의력 결핍 / 과잉행동 장애를 지닌 개인의 평가</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;K-PASS는 주의력 결핍 / 과잉행동 장애를 지니고 있는 아동들의 인지 처리적 특징을 평가하는 데에
                적절하다.
                <br />
                &nbsp;이러한 진단이 고려되고 있을 때, 주의집중과 계획기능이 특히 중요하다. 주의집중 척도는 검사자로
                하여금, 개인이 얼마나 선택적으로 주의집중을 잘 하고, 부적합한 자극 중에서 적합한 자극을 얼마나 잘
                찾아내며, 혼란 자극에 대한 반응을 얼마나 잘 금지시키는지를 평가할 수 있게 해 준다.
                <br />
                &nbsp;계획기능 척도는 검사자로 하여금, 인지적 활동을 조직하고 자기-조정을 연출하는 아동의 능력을
                평가하게 해 준다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>3</div>
                <h4 className={style.title}>학습장애를 지닌 아동의 평가</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;K-PASS는 학습문제에 내재되어 있는 인지과정을 사정하는 데 적합하다. 예를 들면, 이는 읽기 해부호화에
                관련되는 연속적 처리를 밝히려는 데에 사용될 수 있다. 순차적 처리 점수 및 동시적 처리 점수가 읽기 이해에
                관련될 수 있다. 또한, 수학 계산 곤란과 관련되는 계획기능 상의 문제가 밝혀질 수 있다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>4</div>
                <h4 className={style.title}>정신지체를 지닌 아동의 평가</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;K-PASS가 어떤 개인이 정신지체를 지니고 있는지의 여부를 결정하는 데는 두 가지 방식이 있다.
                <br />
                &nbsp;첫째, 이는 획득된 지식을 최소한도로 요구하는 검사이기 때문에 어떤 아동이라도 지식의 부족으로
                인해서 이 검사에서 실패하지는 않을 것이다.
                <br />
                &nbsp;둘째, 이는 폭넓은 인지과정 범위에 대한 측정치를 제공하기 때문에 변별적 진단을 가능케 해준다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>5</div>
                <h4 className={style.title}>외상적 뇌 상해를 지닌 아동의 평가</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;네 개의 K-PASS 척도는 외상적 뇌 상해에 민감하게 반응하는 폭넓은 인지과정 스펙트럼을 측정하는
                기회를 제공한다. 외상적 뇌 상해를 지닌 아동들에게는 조직화, 충동 통제, 주의집중, 문제 해결, 및
                계획기능이 특히 문제가 되는데(Savage & Wolcott, 1994), K-PASS는 이러한 인지기능을 포함하고 있다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>6</div>
                <h4 className={style.title}>심한 정서장애를 지닌 아동의 평가</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;K-PASS는 심한 정서장애를 지닌 개인을 평가하고자 할 때 도움을 주는 정보를 제공한다. 이러한 아동들이
                지니고 있는 행동 통제 상의 곤란, 대인관계 문제, 충동성 등(Moffitt, 1993; Moffitt & Lynam, 1994)은
                K-PASS의 계획기능 척도의 낮은 점수와 연관이 있을 것이다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>7</div>
                <h4 className={style.title}>영재성을 지닌 아동의 평가</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;K-PASS의 네 가지 PASS 척도는 영재성을 평가하는 데 필요한 인지과정을 측정하는 기회를 제공한다.
                K-PASS는 측정된 인지 기능의 폭넓은 범위를 제공하고, 전통적인 검사로는 측정되지 않는 인지 영역에서
                영재성을 지닌 개인을 찾아내기도 한다. 측정된 인지 기능의 폭넓은 범위는 전통적인 검사에 의해서 찾아낼 수
                있는 영재 아동의 다양성보다 훨씬 큰 다양성을 찾아낼 수도 있다.
              </p>
            </li>
            <li className={style.partContentItem}>
              <div className={style.partContentItemTitleWrapper}>
                <div className={style.numbering}>8</div>
                <h4 className={style.title}>계획하기에 문제가 있는 아동의 평가</h4>
              </div>
              <p className={style.partContentItemParagraph}>
                &nbsp;K-PASS는 개인의 계획 및 조직화, 때로는 집행기능이라 불리는 것(Weyandt & Willis, 1994)의 수준을
                평가하기 위한 체계적이고 구조화된 수단을 제공한다. 계획기능 검사 결과로부터 아동의 활동의 조직, 적절한
                전략의 활용, 충동성, 조정 및 행동의 평가에 대한 정보를 파악할 수 있다.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <div className={style.contentFooterWrapper}>
        <ArticleFooterKO />
      </div>
    </article>
  );
}
