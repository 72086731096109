import style from "./aPartnerCompanyOverseas.module.scss";

interface Props {
  logo: string;
  name: string;
}
export function APartnerCompanyOverseas({ logo, name }: Props) {
  return (
    <li className={style.aPartner}>
      <div className={style.logo} style={{ backgroundImage: `url(${logo})` }}></div>
      <span className={style.name}>{name}</span>
    </li>
  );
}
