import { Intro } from "./Intro";
import { Section1 } from "./Section1";
import { Outro } from "./Outro";
import { Section2 } from "./Section2";
import { Section3 } from "./Section3";
import { Section4 } from "./Section4";
import { useCallback, useEffect } from "react";
import { MainLegacyScrollContext } from "./hooks/MainLegacyScrollContext";
import style from "../../assets/scss/main_legacy/main.module.scss";
import { isOnScreen, isPassingHeaderBottom, showArea } from "../../util/commonFunctions";
import { FloatingBtn } from "../common/button/FloatingBtn";
import { useTranslation } from "react-i18next";
import { useFooter, useMainScrolling, useScrollingEvent } from "../../hooks/hooks";

export function MainLegacy() {
  const { setShowFooter } = useFooter();
  const { selectedSection, scrollingSection, setScrollingSection, headerHeight } = useMainScrolling();
  const { t } = useTranslation(["mainMain"]);

  const showOrHideFunc = useCallback(() => showArea(headerHeight), [headerHeight]);
  const isOnScreenFunc = useCallback(() => isOnScreen(headerHeight), [headerHeight]);
  const isPassingHeaderBottomFunc = useCallback(() => isPassingHeaderBottom(headerHeight), [headerHeight]);
  const { scrollY } = useScrollingEvent();

  useEffect(() => {
    setShowFooter(false);
    document.title = process.env.REACT_APP_TITLE;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={style.main}>
      <div className={style.headerBgArea}>
        <div className={style.bgItem1}></div>
      </div>
      <h2 className="blind">{t("content")}</h2>
      <FloatingBtn />
      <MainLegacyScrollContext.Provider
        value={{
          scrollY,
          headerHeight,
          selectedSection,
          scrollingSection,
          setScrollingSection,
          showOrHide: showOrHideFunc(),
          isOnScreen: isOnScreenFunc(),
          isPassingHeaderBottom: isPassingHeaderBottomFunc(),
          setShowFooter,
        }}
      >
        <Intro />
        <Section1 menuName="SECTION1" />
        <Section2 menuName="SECTION2" />
        <Section3 menuName="SECTION3" />
        <Section4 menuName="SECTION4" />
        <Outro />
      </MainLegacyScrollContext.Provider>
    </main>
  );
}
