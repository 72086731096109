import style from "../../../assets/scss/common/button/topButton.module.scss";
import topIcon from "../../../assets/images/top_icon.svg";
import topIconMobile from "../../../assets/images/top_icon_mobile.svg";
import React, { useCallback } from "react";
import { useUserAgent } from "../../../hooks/hooks";

interface Props {
  scrollY: number;
  hideBelowButton: boolean;
  additionalClassName?: string;
}

export function TopButton({ scrollY, hideBelowButton, additionalClassName }: Props) {
  const { isDesktop, isTablet } = useUserAgent();
  const onClickTopBtn = useCallback(() => {
    window.scrollBy({
      left: 0,
      top: -window.scrollY,
      behavior: "smooth",
    });
  }, []);

  return (
    <button
      className={`${style.topButton} ${scrollY > 0 ? style.show : ""} ${additionalClassName ?? ""}`}
      onClick={onClickTopBtn}
      style={{ bottom: isDesktop ? "" : hideBelowButton ? "30px" : isTablet ? "160px" : "108px" }}
    >
      <img src={isDesktop ? topIcon : topIconMobile} alt="위로 이동 아이콘" />
    </button>
  );
}
