import {MainApi} from "./MainApi";

export class VoucherApi {

  static baseUrl = `${MainApi.urlPrefix}/user/voucher`;

  static getUserVoucherCount = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count`);

  static getUserVoucherCountKpass = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count?type=KPASS`);

  static getUserVoucherCountDcas = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count?type=DCAS`);

  static getOrganVoucherCount = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count-from-organ`);
}