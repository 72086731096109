import { useCallback } from "react";
import { useAppSelector } from "../../../../../hooks/hooks";
import { bestReviewsState } from "../../../../../store/review/reviewSlice";
import { useReviewsGetter } from "../../hooks/useReviewsGetter";

export function useGetPrevReviewGetter() {
  const bestReviews = useAppSelector(bestReviewsState);
  const { getPrevReview } = useReviewsGetter();

  const getPrevBestReviewIdx = useCallback(
    (currentBestReviewIdx: number): number | undefined => {
      if (bestReviews === undefined) return undefined;
      const idx = bestReviews.findIndex((it) => it.idx === currentBestReviewIdx);
      if (idx === -1) return undefined;
      if (idx === 0) return undefined;
      return bestReviews![idx - 1].idx;
    },
    [bestReviews]
  );

  return useCallback(
    async (reviewIdx: number, isBestReview: boolean): Promise<number | undefined> => {
      if (isBestReview) {
        return getPrevBestReviewIdx(reviewIdx);
      }
      const prevReview = await getPrevReview(reviewIdx);
      return prevReview?.idx;
    },
    [getPrevBestReviewIdx, getPrevReview]
  );
}
