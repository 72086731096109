import style from "../../assets/scss/main_legacy/section3.module.scss";
import pic1 from "../../assets/images/main_legacy/section3/pic1.jpg";
import pic1Mobile from "../../assets/images/main_legacy/section3/pic1_mobile.jpg";
import researcherImage from "../../assets/images/main_legacy/section3/researcher.png";
import comingSoonLeftPic from "../../assets/images/main_legacy/section3/coming_soon_left_pic.png";
import comingSoonLeftPicMobile from "../../assets/images/main_legacy/section3/coming_soon_left_pic_mobile.png";
import comingSoonRightPic from "../../assets/images/main_legacy/section3/coming_soon_right_pic.png";
import comingSoonRightPicMobile from "../../assets/images/main_legacy/section3/coming_soon_right_pic_mobile.png";
import { useContext, useEffect, useRef, useState } from "react";
import { MainLegacyScrollContext } from "./hooks/MainLegacyScrollContext";
import { useTranslation } from "react-i18next";
import { ReadMoreBtn } from "./ReadMoreBtn";
import { useLanguage, useUserAgent } from "../../hooks/hooks";

export function Section3({ menuName }) {
  const { t } = useTranslation(["mainSection3"]);
  const [isShownSlogan, setIsShownSlogan] = useState(false);
  const [isShownResearcherDesc, setIsShownResearcherDesc] = useState(false);
  const [isShownIntroduction2, setIsShownIntroduction2] = useState(false);
  const [isShownComingSoon, setIsShownComingSoon] = useState(false);
  const { scrollY, selectedSection, setScrollingSection, showOrHide, isPassingHeaderBottom } =
    useContext(MainLegacyScrollContext);
  const sloganRef = useRef();
  const researcherDescRef = useRef();
  const introduction2Ref = useRef();
  const sectionRef = useRef();
  const comingSoonRef = useRef();

  useEffect(() => {
    showOrHide(sloganRef.current, setIsShownSlogan);
    showOrHide(researcherDescRef.current, setIsShownResearcherDesc);
    showOrHide(introduction2Ref.current, setIsShownIntroduction2);
    showOrHide(comingSoonRef.current, setIsShownComingSoon);

    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (sectionRect && isPassingHeaderBottom(sectionRect.top, sectionRect.bottom)) {
      setScrollingSection(menuName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    if (menuName === selectedSection) {
      sectionRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionRef, selectedSection]);

  const { isDesktop } = useUserAgent();
  const { isNotKorean } = useLanguage();

  return (
    <section className={style.section3} ref={sectionRef}>
      <h3 className="blind">{t("heading")}</h3>
      <article className={style.researchBackground}>
        <div className={style.textContent}>
          <h4
            className={`${style.slogan} ${isShownSlogan ? style.show : style.hide} ${isNotKorean ? style.ru : ""}`}
            ref={sloganRef}
          >
            {t("slogan.line1")}
          </h4>
          <p className={style.text1}>{t("summary.line1")}</p>
          {isDesktop ? (
            <></>
          ) : (
            <div className={style.imgContent}>
              <img src={pic1Mobile} alt={t("childrenImgAlt")} />
              <div className={style.bgCircle} />
            </div>
          )}
          <p className={style.text2}>
            {t("paragraph1.line1")}
            <br />
            <br />
            {t("paragraph2.line1")}
          </p>
        </div>
        {isDesktop ? (
          <div className={style.imgContent}>
            <img src={pic1} alt={t("paragraph2.childrenImgAlt")} />
            <div className={style.bgCircle}></div>
          </div>
        ) : (
          <></>
        )}
      </article>
      <article className={style.aboutResearcher}>
        <h4 className="blind">{t("research.heading")}</h4>
        <div className={style.introduction1}>
          <div className={style.pictureContent}>
            <div className={style.bgItem}></div>
            <img src={researcherImage} alt={t("research.introduction.imgAlt")} />
          </div>
          <div className={style.textContent}>
            <p
              className={`${style.researcherDesc} ${isShownResearcherDesc ? style.show : style.hide}`}
              ref={researcherDescRef}
            >
              <span className={style.text1}>{t("research.introduction.institute")}</span>
              <span className={style.text2}>{t("research.introduction.text1")}</span>
              <span className={style.text3}>{t("research.introduction.text2")}</span>
            </p>
            <ul className={style.researcherHistory}>
              <li>{t("research.history.item2")}</li>
              <li>{t("research.history.item3")}</li>
              <li>{t("research.history.item4")}</li>
              <li>{t("research.history.item5")}</li>
              <li>{t("research.history.item6")}</li>
              <li>{t("research.history.item7")}</li>
              <li>{t("research.history.item9")}</li>
              <li>{t("research.history.item10")}</li>
            </ul>
            {isDesktop ? <ReadMoreBtn link={"/researcher"} /> : <></>}
          </div>
        </div>
        <div
          className={`${style.introduction2} ${isShownIntroduction2 ? style.show : style.hide}`}
          ref={introduction2Ref}
        >
          <div className={`${style.box} ${style.standard}`}>
            <h5 className={style.title}>{t("research.standardization.title")}</h5>
            <ul className={style.contentList}>
              <li>{t("research.standardization.item1")}</li>
              <li>{t("research.standardization.item2")}</li>
              <li>{t("research.standardization.item3")}</li>
              <li>{t("research.standardization.item4")}</li>
              <li>
                {t("research.standardization.item5")}
                <ul>
                  <li>{t("research.standardization.item5_1")}</li>
                </ul>
              </li>
              <li>{t("research.standardization.item6")}</li>
            </ul>
          </div>
          <div className={`${style.box} ${style.books}`}>
            <h5 className={style.title}>{t("research.books.title")}</h5>
            <ul className={style.contentList}>
              <li>{t("research.books.item1")}</li>
              <li>{t("research.books.item2")}</li>
              <li>{t("research.books.item3")}</li>
              <li>{t("research.books.item4")}</li>
              <li>{t("research.books.item5.line1")}</li>
              <li>{t("research.books.item6.line1")}</li>
              <li>{t("research.books.item7")}</li>
            </ul>
          </div>
        </div>
        {isDesktop ? <></> : <ReadMoreBtn link={"/researcher"} />}
      </article>
      <article className={`${style.comingSoon} ${isShownComingSoon ? style.show : style.hide}`} ref={comingSoonRef}>
        <div className={style.leftBgItem}>
          <img src={isDesktop ? comingSoonLeftPic : comingSoonLeftPicMobile} alt={t("comingSoon.pictureAlt1")} />
        </div>
        <div className={style.rightBgItem}>
          <img src={isDesktop ? comingSoonRightPic : comingSoonRightPicMobile} alt={t("comingSoon.pictureAlt2")} />
        </div>
        <span className={style.month}>{t("comingSoon.releaseDate")}</span>
        <p className={style.desc}>
          {isDesktop ? <>{t("comingSoon.releaseDesc")}</> : <>{t("comingSoon.releaseDescMobile")}</>}
        </p>
      </article>
    </section>
  );
}
