import {BrainType, KPASSReviewVO, ReviewImageDTO} from "../../../../types/review";
import {Gender, SecuredUserProfile, TesterInfo} from "../../../../types/user";
import styled from "styled-components";
import {BottomButtonSection} from "./components/BottomButtonSection";
import {breakpoints} from "../../../../theme/theme";
import {useCallback, useEffect, useRef, useState} from "react";
import {ReviewWebContentSection} from "./components/ReviewWebContentSection";
import {ProfileImage} from "../ProfileImage";
import {useBrainType} from "../../../../types/hooks/useBrainType";
import {useTranslation} from "react-i18next";
import reviewCheck from "../../../../assets/images/main/review_check.png"
import {useNavigate} from "react-router-dom";
import {useFooter} from "../../../../hooks/hooks";
import {useLocationHistory} from "../../../../hooks/location/useLocationHistory";
import queryString from "query-string";
import {useReviewsGetter} from "../hooks/useReviewsGetter";


const ReviewWebWrapper = styled.div`
  width: 1380px;
  justify-content: center;
  margin: 0 auto;
  gap:50px;
  
  ${breakpoints.tablet} {
    display: flex;
    width: 708px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  ${breakpoints.mobile} {
  
    // display: flex;
    width: 340px;
    margin:0 auto;
    padding:0;
  }
`;

const ReviewWebTitleWrapper = styled.div`
  display: flex;
  width: 1200px;
  min-height:60px;
  padding-right: 80px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom:60px;
      
  ${breakpoints.tablet} {
    width:100%;
    display: flex;
    padding-right: 20px;
    align-self: stretch;
    margin-bottom:0;
  }
  
`

const ReviewWebTitleBox = styled.button`
  width:278px;
  display: flex;
  padding: 20px 50px 20px 30px;
  align-items: center;
  gap: 20px;
  border-radius: 0px 100px 100px 0px;
  background: var(--K-PASS-KPnavy-500_main, #383649);
  
  ${breakpoints.tablet} {
    width:160px;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
  }
  
`

const ReviewWebTitleImageBox = styled.img`
  width:13px;
  height:21px;
  fill: var(--GrayScale-white, #FFF);
  
  ${breakpoints.tablet} {
      width:9px;
      height:15px;
      fill: var(--GrayScale-white, #FFF);
  }
`

const ReviewWebTitleText = styled.div`
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.36px;
  color: var(--GrayScale-white, #FFF);
  
  ${breakpoints.tablet} {
    color: var(--GrayScale-white, #FFF);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.8px
  }
`

const ReviewProfileWrapper = styled.div`
margin-left : 170px;
display: inline-flex;
align-items: center;
gap: 30px;


  ${breakpoints.tablet} {
    margin: 0;
    display: flex;
    gap: 16px;
    align-self: stretch;
    
    
    padding: 0px 40px;
  }
  
  ${breakpoints.tablet} {
    margin: 0;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    
    
    padding: 0px 20px;
  }
`
const ReviewProfileThumbnail = styled.div`
  width: 130px;
  height: 130px;
  > img {
    width: 100%;
    height: 100%;
  }
  
  ${breakpoints.tablet} {
    width: 60px;
    height: 60px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
`

const ReviewProfileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  
  ${breakpoints.tablet} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: stretch;
    gap: 10px;
    flex: 1 0 0;
  }
  
  ${breakpoints.mobile} {
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ProfileNameStyle = styled.span`
  font-style: normal;
  font-size: 32px;
  line-height: 140%;
  font-weight: normal;

  b {
    font-weight: bold;
    color: ${(props) => props.theme.color.kpNavy};
  }

  ${breakpoints.tablet} {
    min-width:75px;
    font-size: 24px;
    letter-spacing: -0.36px;
  }

  ${breakpoints.mobile} {
    font-size: 24px;
  }
`;

const ProfileExtraInformationStyle = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  flex-wrap: wrap;
  word-break: break-all;
  color: ${(props) => props.theme.color.kpNavy};
  
  ${breakpoints.mobile} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;
  }

`

const ProfileExtraInformationSeparatorStyle = styled.span`
  color: #c4c4c4;
  margin: 0 5px;
`;

const HrStyle = styled.hr`
  border: none;
  border-bottom: 1px solid #e1e1e1;
  margin: 40px auto;
  width: 1040px;
  
  ${breakpoints.tablet} {
    width:628px;
    margin:0
  }
  
  ${breakpoints.mobile} {
    width:300px;
    margin:0
  }
`;

interface Props {
  review: KPASSReviewVO;
  reviewImages: ReviewImageDTO[];
  profile: SecuredUserProfile;
  testerInfo: TesterInfo;
  isBestReview: boolean;
  reload: (reviewIdx: number) => void;
}

export function ReviewWebDetail({ review, reviewImages, profile, testerInfo, isBestReview, reload }: Props) {
  const reviewDetailBodyRef = useRef<HTMLDivElement>(null);
  const { toStringFromBrainType } = useBrainType();
  const { t } = useTranslation(["reviewModal"]);
  const navigate = useNavigate();
  const { setShowFooter } = useFooter();
  const reviewListRef = useRef<HTMLElement>(null);
  const { getReviews } = useReviewsGetter();
  const [pageNum, setPageNum] = useState<number | null>(null);


  const getPageNumFromUrl = useCallback(() => {
    const { page } = queryString.parse(window.location.search);
    if (page) {
      return Number(page as string);
    } else {
      return 0;
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    document.title = `리얼 후기 | ${process.env.REACT_APP_TITLE}`;

    setPageNum(getPageNumFromUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPageNumFromUrl]);

  const getPage = useCallback(() => {
    const nextPage = getPageNumFromUrl();
    if (!Number.isNaN(pageNum) && !Number.isNaN(nextPage) && pageNum !== nextPage) {
      setPageNum(nextPage);
      reviewListRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line
  }, [getPageNumFromUrl, pageNum, setPageNum, reviewListRef.current]);

  useLocationHistory({
    callbackOnBack: () => getPage(),
    callbackOnNext: () => getPage(),
  });

  useEffect(() => {
    if (pageNum !== null) {
      getReviews({
        page: pageNum,
      }).then();
    }
    // eslint-disable-next-line
  }, [pageNum]);

  const onReload = useCallback(
    (reviewIdx: number) => {
      window.location.href = '/review/'+reviewIdx
      // window.scrollTo(0, 543);
      // reload(reviewIdx);
    },
      // eslint-disable-next-line
    [reviewDetailBodyRef, reload]
  );

  const handleReviewPage = () =>{
    navigate("/review");
  }


  return (
  <ReviewWebWrapper>
    <ReviewWebTitleWrapper>
      <ReviewWebTitleBox onClick={handleReviewPage}>
        <ReviewWebTitleImageBox src={reviewCheck} alt={reviewCheck}/>
        {/*<img src={reviewCheck} alt={reviewCheck}/>*/}
        <ReviewWebTitleText>
          {t("detailModalTitle")}
        </ReviewWebTitleText>
      </ReviewWebTitleBox>
    </ReviewWebTitleWrapper>

    <ReviewProfileWrapper>
      <ReviewProfileThumbnail>
        <ProfileImage reviewIdx={review.idx}  />
      </ReviewProfileThumbnail>
      <ReviewProfileTextWrapper>
        <ProfileNameStyle>
          <b>{profile.name}</b>
          {t("detailModalSir")}
        </ProfileNameStyle>
        <ProfileExtraInformationStyle>
          {testerInfo.region}
          <ProfileExtraInformationSeparatorStyle>|</ProfileExtraInformationSeparatorStyle>
          {testerInfo.age}
          {t("detailModalAge")}
          <ProfileExtraInformationSeparatorStyle>|</ProfileExtraInformationSeparatorStyle>
          {testerInfo.gender === Gender.MALE ? t("detailModalMale") : t("detailModalFemale")}
          {testerInfo.brainType !== BrainType.UNKNOWN ? (
              <>
                <ProfileExtraInformationSeparatorStyle>|</ProfileExtraInformationSeparatorStyle>
                {toStringFromBrainType(testerInfo.brainType)}
              </>
          ) : (
              <></>
          )}
        </ProfileExtraInformationStyle>

      </ReviewProfileTextWrapper>
    </ReviewProfileWrapper>
    <HrStyle />


    <ReviewWebContentSection review={review} reviewImages={reviewImages} />
    <HrStyle />
    <BottomButtonSection reviewIdx={review.idx} isBestReview={isBestReview} reload={onReload} />
    {/*<div style={{marginBottom:'100px'}} />*/}

  </ReviewWebWrapper>
  );
}
