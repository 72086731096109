import style from "../review.module.scss";
import {ProfileImage} from "../ProfileImage";
import {StrongTextStyle} from "../../../common/StyledCommonComponents";
import {useModal, useTimeConverter, useUserAgent} from "../../../../hooks/hooks";
import {ReviewListVO} from "../../../../types/review";
import {Gender} from "../../../../types/user";
import {EditableButtons} from "./EditableButtons";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Thumbnail} from "./Thumbnail";
import {useShowOrHide} from "../../../../hooks/useShowOrHide";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import {useBrainType} from "../../../../types/hooks/useBrainType";

interface Props {
  aReview: ReviewListVO;
}
export function AReviewInList({ aReview }: Props) {
  const { t } = useTranslation(["subRealReview"]);
  const { isDesktop } = useUserAgent();
  const { isMobile } = useUserAgent();
  const aReviewRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { openReviewDetailModal } = useModal();
  const mobileTitleRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { timeConverter } = useTimeConverter();

  useEffect(() => {
    const { showList } = queryString.parse(location.search);
    if (showList) {
      setIsLoaded(true);
    }
  }, [location.search]);

  useShowOrHide({ refCurrent: aReviewRef.current, setShow });
  const { toStringFromBrainType } = useBrainType();

  useEffect(() => {
    if (!isLoaded && show) {
      setIsLoaded(true);
    }
  }, [isLoaded, show]);

  const openDetail = useCallback(
    (e: React.MouseEvent, reviewIdx: number) => {
      openReviewDetailModal(reviewIdx, false);
      e.stopPropagation();
    },
    [openReviewDetailModal]
  );

  const mobileContentLineClamp = useMemo(() => {
    if (mobileTitleRef.current) {
      const { height } = mobileTitleRef.current.getBoundingClientRect();
      if (height > 24) {
        return 1;
      }
    }

    return 2;
    // eslint-disable-next-line
  }, [mobileTitleRef.current]);

  return (
    <div
      className={`${style.section3ListItem} ${isLoaded ? style.show : show ? style.show : style.hide}`}
      onClick={(e) => {
        if (isMobile) openDetail(e, aReview.idx);
      }}
      ref={aReviewRef}
    >
      <div
        className={style.itemTextArea}
        onClick={(e) => {
          if (!isMobile) openDetail(e, aReview.idx);
        }}
      >
        <div className={style.writerInfo}>
          <div className={style.profileImage}>
            <ProfileImage reviewIdx={aReview.idx} />
          </div>
          <div className={style.profileInfo}>
            <span className={style.name}>
              <StrongTextStyle>{aReview.name}</StrongTextStyle>
              {t("sir")}
            </span>
            <div className={style.additionalInfo}>
              <span className={style.exceptName}>{aReview.region}</span>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <span className={style.exceptName}>
                {aReview.age}
                {t("age")}
              </span>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <span className={style.exceptName}>{aReview.gender === Gender.MALE ? t("male") : t("female")}</span>
              {aReview.brainType ? (
                <>
                  <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  <span className={style.exceptName}>{toStringFromBrainType(aReview.brainType)}</span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {!isDesktop ? (
            <EditableButtons
              wrapperClassName={style.editableButtonsMobile}
              editButtonClassName={style.purple}
              deleteButtonClassName={style.pink}
              userIdx={aReview.userIdx}
              reviewIdx={aReview.idx}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={style.contentInfo}>
          {isDesktop ? (
            <>
              <div className={style.contentInfoTitle}>{aReview.oneLineReviewText}</div>
              <div className={style.contentText}>{aReview.reviewText}</div>
              <div className={style.contentInfoWrittenAt}>{timeConverter.convertToLocalDate(aReview.writtenAt)}</div>
            </>
          ) : (
            <>
              <div className={style.contentInfoWrapper}>
                <div className={style.contentInfoTitle} ref={mobileTitleRef}>
                  {aReview.oneLineReviewText}
                </div>
                <div className={style.contentText} style={{ WebkitLineClamp: mobileContentLineClamp }}>
                  {aReview.reviewText}
                </div>
              </div>
              {aReview.thumbnailUrl ? <Thumbnail className={style.image} imageSource={aReview.thumbnailUrl} /> : <></>}
            </>
          )}
        </div>
        {isDesktop ? (
          <></>
        ) : (
          <div className={style.contentInfoWrittenAt}>{timeConverter.convertToLocalDate(aReview.writtenAt)}</div>
        )}
      </div>
      {isDesktop ? (
        <div className={style.itemButtonAndImageArea}>
          <EditableButtons
            wrapperClassName={style.editableButtons}
            editButtonClassName={style.purple}
            deleteButtonClassName={style.pink}
            userIdx={aReview.userIdx}
            reviewIdx={aReview.idx}
          />
          <div onClick={(e) => !isMobile && openDetail(e, aReview.idx)}>
            <Thumbnail className={style.image} imageSource={aReview.thumbnailUrl} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
