import { Article3Section1 } from "./article3/Article3Section1";
import React from "react";
import { Article3Section2 } from "./article3/Article3Section2";
import style from "./article3/mainArticle3.module.scss";
import { Article3Section3 } from "./article3/Article3Section3";

export function MainArticle3() {
  return (
    <article className={style.article}>
      <Article3Section1 />
      <Article3Section2 />
      <Article3Section3 />
    </article>
  );
}
