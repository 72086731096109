import { useAffiliateCodeGetter } from "./useAffiliateCodeGetter";
import { useCallback } from "react";
import { AffiliateCodeVO, CodeStatus } from "../../types/affiliateCode";
import { useModal } from "../hooks";
import moment from "moment-timezone";
import { useErrorType } from "../useErrorType";
import { useAffiliateCodeCommissionChecker } from "./useAffiliateCodeCommissionChecker";

interface Args {
  setCodeErrorMessage: (_: string) => void;
}
export function useAvailableAffiliateCodeGetter({ setCodeErrorMessage }: Args) {
  const { getAffiliateCode } = useAffiliateCodeGetter();
  const { checkAffiliateCodeCommission } = useAffiliateCodeCommissionChecker();
  const { getErrorObject } = useErrorType();
  const { openAlertModal } = useModal();

  const isUnavailable = useCallback((codeVO: AffiliateCodeVO) => {
    if (codeVO.status === CodeStatus.UNAVAILABLE || codeVO.status === CodeStatus.DELETED) return true;

    if (moment.utc(codeVO.availableStartAt).isAfter(moment())) return true;
    if (moment.utc(codeVO.availableEndAt).isBefore(moment())) return true;

    return false;
  }, []);

  const getAvailableAffiliateCode = useCallback(
    async (code: string, cartIdxes: number[]): Promise<AffiliateCodeVO | null> => {
      try {
        const codeVO: AffiliateCodeVO = await getAffiliateCode(code);
        if (isUnavailable(codeVO)) {
          setCodeErrorMessage("현재 사용 불가능한 코드입니다.");
          return null;
        }

        await checkAffiliateCodeCommission({
          cartIdxes,
          affiliateCodeIdx: codeVO.idx,
        });

        return codeVO;
      } catch (e) {
        console.error("getAvailableAffiliateCode occurs an error - ", e);
        const errorType = getErrorObject((e as { message: string }).message);
        const errorCode = errorType?.errorCode?.httpCode;

        switch (errorCode) {
          case 404:
            setCodeErrorMessage("잘못된 코드입니다.");
            break;
          case 496:
            setCodeErrorMessage("사용할 수 없는 코드입니다.\n소속 관리자에게 문의해 주세요.");
            break;
          default:
            openAlertModal(null);
            break;
        }

        return null;
      }
    },
    [getAffiliateCode, isUnavailable, setCodeErrorMessage, getErrorObject, openAlertModal, checkAffiliateCodeCommission]
  );

  return {
    getAvailableAffiliateCode,
  };
}
