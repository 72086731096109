import { useEffect, useState } from "react";
import { ChildrenProp } from "../../types/common";
import { UserAgentContext, UserDevice } from "./UserAgentContext";

const TABLET_MIN_WIDTH = 768;
const DESKTOP_MIN_WIDTH = 1380;

export function UserAgentProvider({ children }: ChildrenProp) {
  const [device, setDevice] = useState<UserDevice>(() => {
    const innerWidth = window.innerWidth;
    if (innerWidth >= DESKTOP_MIN_WIDTH) return UserDevice.DESKTOP;
    if (innerWidth >= TABLET_MIN_WIDTH) return UserDevice.TABLET;
    return UserDevice.MOBILE;
  });

  const updateDeviceType = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth >= DESKTOP_MIN_WIDTH && device !== UserDevice.DESKTOP) {
      setDevice(UserDevice.DESKTOP);
    } else if (innerWidth >= TABLET_MIN_WIDTH && innerWidth < DESKTOP_MIN_WIDTH && device !== UserDevice.TABLET) {
      setDevice(UserDevice.TABLET);
    } else if (innerWidth < TABLET_MIN_WIDTH && device !== UserDevice.MOBILE) {
      setDevice(UserDevice.MOBILE);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDeviceType);

    return () => window.removeEventListener("resize", updateDeviceType);
    // eslint-disable-next-line
  }, [device]);

  const isDesktop = device === UserDevice.DESKTOP;
  const isTablet = device === UserDevice.TABLET;
  const isMobile = device === UserDevice.MOBILE;

  return (
    <UserAgentContext.Provider value={{ isDesktop, isTablet, isMobile, device }}>{children}</UserAgentContext.Provider>
  );
}
