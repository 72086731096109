import loginStyle from "../../../../../../assets/scss/common/modal/loginModal.module.scss";
import memberStyle from "../../../../../../assets/scss/sub/editMember.module.scss";
import React, {RefObject} from "react";
import {Option} from "../../../../../../types/signup";
import {ValidateStatus} from "../hooks/useSignupValidator";
import {StyleRequestType} from "../../../../../../types/common";

interface Props {
    nationality: string;
    setNationality: (_: string) => void;
    showNation: boolean;
    setShowNation: (_: boolean) => void;
    nationValidate: ValidateStatus;
    setNationValidate: (_: ValidateStatus) => void;
    styleRequestType: StyleRequestType;
    nationalityRef?: RefObject<HTMLDivElement>;
}

const nationOptions: Option[] = [
    { key: '', value: '국적 선택'},
    { key: 'KR', value: '대한민국'},
    { key: 'US', value: '미국'},
    { key: 'MN', value: '몽골'},
    { key: 'TW', value: '대만'},
    { key: 'VN', value: '베트남'},
];

export function SignupNationField({nationality, setNationality, showNation, setShowNation, nationValidate, setNationValidate, styleRequestType, nationalityRef}: Props){

    const selectedOption = nationOptions.find(option => option.key === nationality);
    const isDefaultOption = !selectedOption || selectedOption.key === '';
    const styles = styleRequestType === StyleRequestType.SIGN_UP ? loginStyle :  memberStyle;

    const handleOptionClick = (option: Option) => {
        setNationality(option.key);
        setShowNation(false);
        setNationValidate(ValidateStatus.NONE)
    };

    return (
        <div className={styles.selectWrapper}>
            <div className={styles.selectTitleWrapper}>
                <span className={styles.highLight}>[필수]</span>
                <span className={styles.titleText}>국적</span>
            </div>

            <div className={styles.selectTextFieldWrapper} onClick={() => setShowNation(!showNation)}>
                <span className={`${styles.selectText} ${isDefaultOption ? styles.defaultText : ''}`}>
                  {selectedOption?.value}
                </span>
                <div className={styles.arrowImg}></div>
                {showNation && (
                    <div className={styles.optionStyle}>
                        {nationOptions.filter(option => option.key !== '').map((option) => (
                            <button
                                key={option.key}
                                className={`${styles.optionTextWrapper} ${option.key === nationality ? styles.selectedOption : ''}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                <span ref={nationalityRef}
                                    className={`${styles.optionText} ${option.key === nationality ? styles.selectedOption : ''}`}>{option.value}</span>
                            </button>
                        ))}
                    </div>
                )}
            </div>
            {nationValidate === ValidateStatus.UNFILLED &&
                (<span className={styles.validateText}>국적을 선택해 주세요.</span>)
            }
        </div>
    )
}