import style from "../../../../assets/scss/common/modal/leaveModal.module.scss";
import {useModal} from "../../../../hooks/hooks";
import {LeaveStep} from "../LeaveModal";
import {LeaveReason, UserLeavePostDTO} from "../../../../types/user";
import {useCookies} from "react-cookie";
import {useLeaveMember} from "./hooks/useLeaveMember";

interface Args{
    confirmStatus: string;
    isAllAgreementSelected: boolean;
    currentLeaveStep: LeaveStep;
    setCurrentLeaveStep: (_:LeaveStep) => void;
    radioOption: LeaveReason;
    radioTextReason: string;
    setErrorMessage: (_: string) => void;
}

export function LeaveButtonField(
    {confirmStatus, isAllAgreementSelected, currentLeaveStep, setCurrentLeaveStep,
     radioOption, radioTextReason, setErrorMessage}: Args){
    const {closeModal, openAlertModal, openConfirmModal} = useModal();
    const {leaveRequestMember} = useLeaveMember();
    const [, , removeCookie] = useCookies(['accessToken', 'refreshToken']);


    // 탈퇴 완료 요청할 수 있는 상태들
    const radioNoneStatus = radioOption !== LeaveReason.NONE;
    const radioEtcStatus = (radioOption === LeaveReason.ETC && radioTextReason !== '') || radioOption !== LeaveReason.ETC;

    const isHoverActiveStatus = (currentLeaveStep === LeaveStep.STEP1 && isAllAgreementSelected) || (radioNoneStatus && radioEtcStatus)

    const onClickStepChange = (currentStep: LeaveStep) => {
        if (currentStep === LeaveStep.STEP1) {
            if (isAllAgreementSelected) {
                setErrorMessage('')
                setCurrentLeaveStep(LeaveStep.STEP2);
            } else {
                setErrorMessage('모든 항목을 확인해 주세요.')
            }
        }

        if(currentStep === LeaveStep.STEP2){
            if (radioNoneStatus && radioEtcStatus) {
                handleLeave();
            } else if(!radioNoneStatus){
                setErrorMessage('탈퇴 사유를 선택해 주세요.')
            }
        }

    }

    const handleLeave = () => {

        const leaveRequest: UserLeavePostDTO = {
            reason: radioOption === LeaveReason.ETC ? radioTextReason : radioOption
        }
        const confirmLeaveModal = () => {
            openConfirmModal(
                {
                    title: "회원탈퇴 안내",
                    desc: "정말 회원탈퇴를 하시겠습니까?\n" +
                        "회원 탈퇴 시 모든 정보는 삭제되며,\n" +
                        "삭제된 정보는 복구할 수 없습니다",
                },
                () => {
                    leaveRequestMember(leaveRequest).then(r => console.log(leaveRequest))
                    removeCookie('accessToken', { path: '/' });
                    removeCookie("refreshToken", {path: '/'})
                    closeModal();
                    openAlertModal(
                        {
                            title: "회원탈퇴 안내",
                            desc: "회원탈퇴가 완료 되었습니다.",
                            callback: () => window.location.href = "/"
                        },
                    )
                }
            );
        }

        confirmLeaveModal()
    }
    return(
        <>
            <div className={style.leaveButtonWrapper}>
                <div className={style.buttonWrapper}>
                    <button className={style.cancelButton} onClick={closeModal}>
                        취소
                    </button>
                    <button
                        className={`${style.confirmButton} ${isHoverActiveStatus ? style.selected :  ''}`}
                        onClick={() => onClickStepChange(currentLeaveStep)}>
                        {confirmStatus}
                    </button>
                </div>
            </div>
        </>
    )
}