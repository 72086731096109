import style from "./eventRegistererModal.module.scss";
import arrowDown from "../../../../assets/images/modal/event_registerer/ico_arrow_down.svg";

interface Props {
  showNoticeDetail: boolean;
  setShowNoticeDetail: (_: boolean) => void;
}
export function EventRegistererNoticeDetail({ showNoticeDetail, setShowNoticeDetail }: Props) {
  return (
    <div className={style.detailNoticeWrapper}>
      <button
        className={style.readDetailButton}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowNoticeDetail(!showNoticeDetail);
        }}
      >
        내용 자세히보기
        <img className={showNoticeDetail ? style.show : ""} src={arrowDown} alt={"아래 화살표"} />
      </button>
      {showNoticeDetail && (
        <ol className={style.detailNotice}>
          <li>
            수집하는 개인정보 항목
            <ul className={style.detailList}>
              <li>이름, 성별, 생년월일, 거주 지역, 휴대폰 번호, E-mail 주소</li>
            </ul>
          </li>
          <li>
            개인정보의 수집 및 이용목적
            <ul className={style.detailList}>
              <li>K-PASS 및 K-PASS with 앱의 서비스 개선 및 보완사항, 효과성 측정, 데이터 취합 등</li>
            </ul>
          </li>
          <li>
            개인정보의 보유 및 이용 기간
            <ul className={style.detailList}>
              <li>
                이벤트 종료 후 1년까지, 또는 자신의 개인정보제공 동의를 철회한 때, 단, 관련 법령의 규정에 의하여
                개인정보를 보유할 필요가 있는 경우, 해당 법령에서 정한 기간까지
              </li>
            </ul>
          </li>
          <li>
            동의거부관리
            <ul className={style.detailList}>
              <li>
                귀하는 이에 대한 동의를 거부할 수 있으며, 단, 비동의 시 무료체험 이벤트에 참여가 불가능할 수 있음을
                알려드립니다.
              </li>
            </ul>
          </li>
        </ol>
      )}
    </div>
  );
}
