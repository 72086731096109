import style from "./eventRegistererModal.module.scss";

export function EventRegistererNotice() {
  return (
    <p className={style.notice}>
      (주) 필굿은 [개인정보보호법]에 의거하여 아래와 같이 개인정보를 수집하고 있습니다.
      <br />
      내용을 읽어보시고 동의 여부를 결정해 주시기 바랍니다.
      <br />
      <br />
      * 개인정보대상자가 동의한 내용 외에 다른 목적으로 활용하지 않습니다.
      <br />* 만 14세 미만의 아동의 경우 반드시 법정대리인의 동의가 필요합니다.
    </p>
  );
}
