import React, { useCallback, useEffect, useState } from "react";
import style from "../../assets/scss/sub/checkupInfo.module.scss";
import addIcon from "../../assets/images/button/icon/addIcon.svg";
import LeftBtnIcon from "../../assets/images/mypage/left_button_icon.svg";
import RightBtnIcon from "../../assets/images/mypage/right_button_icon.svg";
import {
  getDcasProfileTestHistoryAsync,
  getProfileAuthTokenAsync,
  getProfileTestHistoryAsync,
} from "../../store/profile/profileSlice";
import { useAppDispatch, useTimeConverter } from "../../hooks/hooks";
import AddCheckupInfoModal from "../common/modal/AddCheckupInfoModal";
import { TestResultFormType } from "../../types/user";

interface ProfileInfo {
  idx: number;
  name: string;
  birthday: string;
  createAt: string;
  userCode: string;
  testCnt: number;
  isOwnerProfile: boolean;
}

interface ProfileInfoProps {
  profileIdx: number;
  profileInfo: ProfileInfo;
}

export function CheckupInfo({ profileIdx, profileInfo }: ProfileInfoProps) {
  const dispatch = useAppDispatch();
  const { timeConverter } = useTimeConverter();
  const [pageIdx, setPageIdx] = useState(0);
  const [profileData, setProfileData] = useState<any[]>([]);
  const [paginatedData, setPaginatedData] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isCheckupInfoModalOpen, setIsCheckupInfoModalOpen] = useState(false);
  const itemsPerPage = 4;

  const getProfileInfo = useCallback(
    async ({ profileIdx }: { profileIdx: number }) => {
      try {
        // 새로운 데이터를 가져오기 전에 profileData 상태를 초기화합니다.
        setProfileData([]); // profileIdx가 변경될 때마다 데이터를 초기화합니다.

        const response = await dispatch(getProfileTestHistoryAsync({ profileIdx })).unwrap();
        const response2 = await dispatch(getDcasProfileTestHistoryAsync({ profileIdx })).unwrap();

        const combinedData = [...(response?.content ?? []), ...(response2?.content ?? [])].sort(
          (a, b) => new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime()
        );

        setProfileData(combinedData);
        setPageIdx(0);
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const getAuthToken = useCallback(
    async (param: { userProfileIdx: number }) => {
      try {
        const response: any = await dispatch(getProfileAuthTokenAsync(param)).unwrap();

        if (response && typeof response === "object" && "accessToken" in response) {
          setAccessToken(response.accessToken);
        } else {
          console.warn("응답에 Access Token이 없습니다.");
        }
      } catch (error) {
        console.error("인증 토큰을 가져오는 중 오류 발생:", error);
      }
    },
    [dispatch]
  );

  const toggleAddCheckupInfoModal = () => {
    setIsCheckupInfoModalOpen((prev) => !prev);
  };

  useEffect(() => {
    if (profileIdx) getProfileInfo({ profileIdx });
  }, [profileIdx, getProfileInfo, isCheckupInfoModalOpen]);

  useEffect(() => {
    if (profileIdx) getAuthToken({ userProfileIdx: profileIdx });
  }, [getAuthToken, profileIdx]);

  useEffect(() => {
    if (accessToken) {
      // Access Token이 설정된 이후에 필요한 작업을 수행할 수 있습니다.
    }
  }, [accessToken]);

  useEffect(() => {
    if (isCheckupInfoModalOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [isCheckupInfoModalOpen]);

  useEffect(() => {
    const startIdx = pageIdx * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    setPaginatedData(profileData.slice(startIdx, endIdx));
  }, [profileData, pageIdx]);

  const totalPages = Math.max(Math.ceil(profileData.length / itemsPerPage), 1); // 최소 1페이지

  const handlePageChange = (newPageIdx: number) => {
    if (newPageIdx >= 0 && newPageIdx < totalPages) {
      setPageIdx(newPageIdx);
    }
  };

  const handleOpenTestResult = (
    executedInfoIdx: number,
    unitVoucherType: "KPASS" | "DCAS",
    kpassResultForm: TestResultFormType,
    dcasResltForm: TestResultFormType
  ) => {
    if (!accessToken) {
      console.warn("Access Token이 없습니다. 새 창을 열 수 없습니다.");
      return;
    }

    const getUrl = (type: "KPASS" | "DCAS", form: TestResultFormType) => {
      const baseUrl = `${process.env.REACT_APP_BASE_URL}api/v1/${type.toLowerCase()}`;
      const path =
        form === TestResultFormType.SUMMARY
          ? `/organ-manager/test-result/summary/${executedInfoIdx}/${accessToken}`
          : `/profile/test-result/${executedInfoIdx}/${accessToken}`;

      return baseUrl + path;
    };

    const returnUrl = unitVoucherType === "KPASS" ? getUrl("KPASS", kpassResultForm) : getUrl("DCAS", dcasResltForm);

    window.open(returnUrl, "_blank");
  };

  const handleOpenGuide = (executedInfoIdx: number, unitVoucherType: "KPASS" | "DCAS") => {
    if (accessToken) {
      const url = `${process.env.REACT_APP_BASE_URL}api/v1/kpass/profile/test-result/${executedInfoIdx}/recommend-job?token=${accessToken}`;
      const DcasUrl = `${process.env.REACT_APP_BASE_URL}api/v1/dcas/profile/test-result/${executedInfoIdx}/recommend-job?token=${accessToken}`;
      if (unitVoucherType === "KPASS") window.open(url, "_blank");
      else window.open(DcasUrl, "_blank");
    } else {
      console.warn("Access Token이 없습니다. 가이드 페이지를 열 수 없습니다.");
    }
  };

  // 약식결과지인 경우 FALSE, 아닌 경우 TRUE를 반환하는 함수
  const handleGuideVisible = (
    unitVoucherType: "KPASS" | "DCAS",
    kpassResultType: TestResultFormType,
    dcasResultType: TestResultFormType
  ) => {
    if (unitVoucherType === "KPASS") {
      return kpassResultType !== TestResultFormType.SUMMARY;
    } else {
      return dcasResultType !== TestResultFormType.SUMMARY;
    }
  };

  // 페이지네이션 범위를 계산하는 부분 추가
  const paginationRange = 5;
  const startPage = Math.max(0, Math.min(pageIdx - Math.floor(paginationRange / 2), totalPages - paginationRange));
  const endPage = Math.min(totalPages, startPage + paginationRange);

  return (
    <div className={style.container}>
      <div className={style.containerContentBox}>
        <h2 className={style.containerTitle}>
          검사정보&nbsp; <span className={style.profileCount}>({profileInfo?.name ? profileInfo?.name : "나"}</span>
          <span className={style.profileCount}>)</span>
        </h2>
        <button
          className={`${style.addProfileButton} ${style.addCheckupInfoButton}`}
          onClick={toggleAddCheckupInfoModal}
        >
          <img src={addIcon} className={style.addIcon} alt="icon" width={26} height={26} />
          소속 검사 결과 추가하기
        </button>
      </div>
      <div>
        <div className={style.labelContainer}>
          <div className={style.firstLabel}>검사종류</div>
          <div className={style.secondLabel}>두뇌유형</div>
          <div className={style.thirdLabel}>검사완료일</div>
          <div className={style.fourthLabel}>결과지</div>
        </div>
        <div className={style.resultInfoContainer}>
          {paginatedData.length > 0 ? (
            paginatedData.map((item) => (
              <div key={item.contentExecutedInfoIdx} className={style.resultInfoData}>
                <div className={style.firstInfoData}>
                  {item.unitVoucherType === "KPASS" ? "K-PASS" : "D-CAS"}
                  <br />
                  {item.isLinkedOrganTest ? "소속검사" : !item.customerType ? item.testRound + "회차" : ""}
                </div>
                <div className={style.secondInfoData}>
                  {item.brainType === "BALANCED"
                    ? "좌우밸런스형"
                    : item.brainType === "SIMULTANEOUS"
                    ? "우뇌우세형"
                    : item.brainType === "SUCCESSIVE"
                    ? "좌뇌우세형"
                    : "-"}
                </div>
                <div className={style.thirdInfoData}>
                  {timeConverter.convertToFormattedDate(timeConverter.convertToLocalDate(item.endedAt).split(" ")[0])}
                </div>
                <div className={style.fourthInfoData}>
                  <button
                    className={style.resultButton}
                    onClick={() =>
                      handleOpenTestResult(
                        item.contentExecutedInfoIdx,
                        item.unitVoucherType,
                        item.kpassResultForm,
                        item.dcasResultForm
                      )
                    }
                  >
                    검사내역
                  </button>
                  {handleGuideVisible(item.unitVoucherType, item.kpassResultForm, item.dcasResultForm) && (
                    <button
                      className={style.guideButton}
                      onClick={() => handleOpenGuide(item.contentExecutedInfoIdx, item.unitVoucherType)}
                    >
                      가이드
                    </button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className={style.noPaymentInfo}>검사 내역이 없습니다.</div>
          )}
        </div>
        <div className={style.paginationContainer}>
          <div className={style.paginationDiv}>
            <img
              src={LeftBtnIcon}
              className={style.leftButtonImg}
              onClick={() => handlePageChange(pageIdx - 1)}
              alt="previous"
            />
            {Array.from({ length: endPage - startPage }, (_, i) => startPage + i).map((page) => (
              <button
                key={page}
                className={`${style.paginationBtn} ${page === pageIdx ? style.active : ""}`}
                onClick={() => handlePageChange(page)}
              >
                {page + 1}
              </button>
            ))}
            <img
              src={RightBtnIcon}
              className={style.rightButtonImg}
              onClick={() => handlePageChange(pageIdx + 1)}
              alt="next"
            />
          </div>
        </div>
      </div>
      {profileIdx !== null && (
        <AddCheckupInfoModal
          isOpen={isCheckupInfoModalOpen}
          onClose={toggleAddCheckupInfoModal}
          profileIdx={profileIdx}
        />
      )}
    </div>
  );
}
