import style from "../../assets/scss/main_legacy/zoomImageModal.module.scss";
import closeBtn from "../../assets/images/main_legacy/close_modal.svg";
import { useTranslation } from "react-i18next";

export function ZoomImageModal({ imgSrc, imgAlt, isModalShown, closeModal }) {
  const { t } = useTranslation(["mainMain"]);

  const onClickBg = (e) => {
    const imgNode = e.target.closest("img");
    if (imgNode === null) {
      closeModal();
    }
  };

  return (
    <div className={`${style.zoomImageModal} ${isModalShown ? style.show : ""}`} onClick={onClickBg}>
      <div className={style.content}>
        <button className={style.closeBtn} onClick={() => closeModal()}>
          <img src={closeBtn} alt={t("modalCloseIconAlt")} />
        </button>
        <img src={imgSrc} alt={imgAlt} />
      </div>
    </div>
  );
}
