import openingDoubleQuote from "../../assets/images/main_legacy/section4/openingDoubleQuote.svg";
import closingDoubleQuote from "../../assets/images/main_legacy/section4/closingDoubleQuote.svg";
import style from "../../assets/scss/main_legacy/section4.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { MainLegacyScrollContext } from "./hooks/MainLegacyScrollContext";
import { ArticleTitle } from "../sub/ArticleTitle";
import { useTranslation } from "react-i18next";
import { useLanguage, useUserAgent } from "../../hooks/hooks";

export function Section4({ menuName }) {
  const { t } = useTranslation(["mainSection4"]);
  const [isShownContent2Phrase1, setIsShownContent2Phrase1] = useState(false);
  const [videoStart, setVideoStart] = useState(false);
  const { scrollY, selectedSection, setScrollingSection, showOrHide, isOnScreen, isPassingHeaderBottom } =
    useContext(MainLegacyScrollContext);
  const content2Phrase1Ref = useRef();
  const videoRef = useRef();
  const sectionRef = useRef();
  const { isNotKorean } = useLanguage();

  useEffect(() => {
    showOrHide(content2Phrase1Ref.current, setIsShownContent2Phrase1);

    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (sectionRect && isPassingHeaderBottom(sectionRect.top, sectionRect.bottom)) {
      setScrollingSection(menuName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    if (!videoStart) {
      const videoRect = videoRef.current?.getBoundingClientRect();
      if (videoRect && isOnScreen(videoRect.top, videoRect.bottom)) {
        videoRef.current?.play();
        setVideoStart(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY, videoRef, videoStart]);

  useEffect(() => {
    if (menuName === selectedSection) {
      sectionRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionRef, selectedSection]);

  const { isDesktop } = useUserAgent();

  return (
    <section className={style.section4} ref={sectionRef}>
      {isDesktop ? <div className={style.bgItem1}></div> : <></>}
      <div className={style.bgItem2}></div>
      <div className={style.content1Wrapper}>
        <div className={style.content1}>
          <h3 className={style.titleHeading}>
            <ArticleTitle title={t("title")} className={`${style.title} ${isNotKorean ? style.ru : ""}`} />
          </h3>
          <div className={style.video}>
            {isDesktop ? <></> : <div className={style.bgItem1}></div>}
            <video controls muted ref={videoRef}>
              <source src="/assets/video/kpass_animation.mp4" type="video/mp4">
                {/*Sorry, your browser doesn't support embedded videos.*/}
              </source>
            </video>
          </div>
          <div className={style.phrase1}>
            <p className={style.quote}>
              <span className={style.text1}>{t("quote1.text")}</span>
              <span className={style.text2}>- {t("quote1.writer")}</span>
            </p>
            <p className={style.desc}>
              {t("quote1.desc.line1")}
              <br />
              {t("quote1.desc.line2")}
            </p>
          </div>
          <div className={style.phrase2}>
            <p className={style.quote}>
              <span className={style.text1}>{t("quote2.text.line1")}</span>
              <span className={style.text2}>- {t("quote2.writer")}</span>
            </p>
            <p className={style.desc}>{t("quote2.desc")}</p>
          </div>
        </div>
      </div>
      <div className={style.content2}>
        <p className={`${style.phrase1} ${isShownContent2Phrase1 ? style.show : style.hide}`} ref={content2Phrase1Ref}>
          <img src={openingDoubleQuote} alt={t("openingQuotationMarkAlt")} />
          <span className={style.text}>{t("summary")}</span>
          <img src={closingDoubleQuote} alt={t("closingQuotationMarkAlt")} />
        </p>
        <p className={style.phrase2}>
          {t("desc.line1")}
          <br />
          {t("desc.lien2")}
        </p>
      </div>
    </section>
  );
}
