import style from "./eventRegistererModal.module.scss";
import { Gender } from "../../../../types/user";
import { RefObject } from "react";

interface Props {
  gender: Gender | "";
  setGender: (_: Gender | "") => void;
  genderRef: RefObject<HTMLDivElement>;
  invalidMessage?: string;
}
export function EventRegistererGenderField({ gender, setGender, genderRef, invalidMessage }: Props) {
  return (
    <div className={style.field} ref={genderRef}>
      <label className={style.label}>자녀의 성별</label>
      <div className={style.genderWrapper}>
        <label className={`${style.checkboxLabel} ${gender === Gender.MALE ? style.checked : ""}`}>
          <input
            type={"checkbox"}
            checked={gender === Gender.MALE}
            onClick={() => setGender(Gender.MALE)}
            readOnly={true}
          />
          남자
        </label>
        <label className={`${style.checkboxLabel} ${gender === Gender.FEMALE ? style.checked : ""}`}>
          <input
            type={"checkbox"}
            checked={gender === Gender.FEMALE}
            onClick={() => setGender(Gender.FEMALE)}
            readOnly={true}
          />
          여자
        </label>
      </div>
      {invalidMessage && <span className={style.invalidMessage}>{invalidMessage}</span>}
    </div>
  );
}
