import {InitialProfileInfoDTO} from "../../../../types/user";
import style from "../../../../assets/scss/common/modal/addChildModal.module.scss";
import React from "react";

interface Args{
    profileInfo: InitialProfileInfoDTO;
    setProfileInfo: React.Dispatch<React.SetStateAction<InitialProfileInfoDTO>>;
    onClickFindingAddress: () => void;
}
export function KoreaAddressForm({profileInfo, setProfileInfo, onClickFindingAddress}:Args) {

    return (
        <>
            <div className={style.addressContainer}>
                <input
                    disabled={true}
                    name="address1"
                    value={
                        profileInfo.address1
                            ? profileInfo.state + " " + profileInfo.city + " " + profileInfo.address1
                            : ""
                    }
                    onChange={(e) => setProfileInfo({...profileInfo, address1: e.target.value})}
                    placeholder="주소 검색"
                    className={style.inputField}
                />
                <button className={style.addressBtn} onClick={onClickFindingAddress}>
                    주소찾기
                </button>
            </div>
            <input
                name="address2"
                value={profileInfo.address2}
                onChange={(e) => setProfileInfo({...profileInfo, address2: e.target.value})}
                placeholder="상세주소를 입력해 주세요"
                className={style.inputField}
                style={{marginTop: "10px"}}
            />
        </>
    )
}