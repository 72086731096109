import { useCallback } from "react";
import { useAppSelector } from "../../../../../hooks/hooks";
import { bestReviewsState } from "../../../../../store/review/reviewSlice";
import { useReviewsGetter } from "../../hooks/useReviewsGetter";

export function useGetNextReviewGetter() {
  const bestReviews = useAppSelector(bestReviewsState);
  const { getNextReview } = useReviewsGetter();

  const getNextBestReviewIdx = useCallback(
    (currentBestReviewIdx: number): number | undefined => {
      if (bestReviews === undefined) return undefined;
      const idx = bestReviews.findIndex((it) => it.idx === currentBestReviewIdx);
      if (idx === -1) return undefined;
      if (idx === bestReviews.length - 1) return undefined;
      return bestReviews![idx + 1].idx;
    },
    [bestReviews]
  );

  return useCallback(
    async (reviewIdx: number, isBestReview: boolean): Promise<number | undefined> => {
      if (isBestReview) {
        return getNextBestReviewIdx(reviewIdx);
      }
      const prevReview = await getNextReview(reviewIdx);
      return prevReview?.idx;
    },
    [getNextBestReviewIdx, getNextReview]
  );
}
