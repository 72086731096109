import {InitialProfileInfoDTO} from "../../../../types/user";
import {AddressInput} from "./AddressInput";
import React from "react";

interface Args{
    profileInfo: InitialProfileInfoDTO;
    setProfileInfo: React.Dispatch<React.SetStateAction<InitialProfileInfoDTO>>;
}
export function ForeignAddressForm({profileInfo, setProfileInfo}:Args) {

    return (
        <>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <AddressInput
                label="State"
                name="state"
                value={profileInfo.state}
                onChange={(e) => setProfileInfo({...profileInfo, state: e.target.value})}
                placeholder="State"
            />
            <AddressInput
                label="City"
                name="city"
                value={profileInfo.city}
                onChange={(e) => setProfileInfo({...profileInfo, city: e.target.value})}
                placeholder="City"
            />
            <AddressInput
                label="Zip code"
                name="postalCode"
                value={profileInfo.postalCode}
                onChange={(e) => setProfileInfo({...profileInfo, postalCode: e.target.value})}
                placeholder="Zip code"
            />
            <AddressInput
                label="Address 1"
                name="address1"
                value={profileInfo.address1}
                onChange={(e) => setProfileInfo({...profileInfo, address1: e.target.value})}
                placeholder="Address 1"
            />
            <AddressInput
                label="Address 2"
                name="address2"
                value={profileInfo.address2}
                onChange={(e) => setProfileInfo({...profileInfo, address2: e.target.value})}
                placeholder="Address 2"
            />
        </>
    )
}