import style from "../../../../assets/scss/common/modal/loginModal.module.scss";
import { useEffect, useState } from "react";

interface Props {
  initialMilliseconds: number;
  styleClassName: string;
  doAtTheEnd: () => void;
}

export function Timer({ initialMilliseconds, styleClassName, doAtTheEnd }: Props) {
  const fillDigit = (n: number) => n.toString().padStart(2, "0");
  const getMinAndSec = (ms: number) => {
    const minSecVal = Math.floor(ms / 1000);
    const min = Math.floor(minSecVal / 60);
    const sec = minSecVal - min * 60;

    return { min, sec };
  };
  const initialMinAndSec = getMinAndSec(initialMilliseconds);

  const [minAfterSendCode, setMinAfterSendCode] = useState(initialMinAndSec.min);
  const [secAfterSendCode, setSecAfterSendCode] = useState(initialMinAndSec.sec);
  const [totalMilliseconds, setTotalMilliseconds] = useState(initialMilliseconds);
  const [timerId, setTimerId] = useState<NodeJS.Timer>();

  useEffect(() => {
    let total = initialMilliseconds;

    let timer = setInterval(() => {
      total = total - 1000;
      setTotalMilliseconds(total);

      if (total >= 0) {
        const { min, sec } = getMinAndSec(total);

        setMinAfterSendCode(min);
        setSecAfterSendCode(sec);

        if (min === 0 && sec === 0) {
          doAtTheEnd();
        }
      }
    }, 1000);
    setTimerId(timer);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (totalMilliseconds < 0) {
      clearInterval(timerId);
    }
  }, [totalMilliseconds, timerId]);

  return (
    <span className={`${style.timerTime} ${styleClassName}`}>
      {fillDigit(minAfterSendCode)}:{fillDigit(secAfterSendCode)}
    </span>
  );
}
