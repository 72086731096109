import { useEffect } from "react";
import style from "../../assets/scss/sub/purposeAndCharacteristics.module.scss";
import { ArticleTitle } from "./ArticleTitle";
import purposePic from "../../assets/images/sub/purpose_and_characteristics/purpose_exmaple_pic.png";
import purposePicMobile from "../../assets/images/sub/purpose_and_characteristics/purpose_exmaple_pic_mobile.png";
import evaluationItem1 from "../../assets/images/sub/purpose_and_characteristics/item1.svg";
import evaluationItem2 from "../../assets/images/sub/purpose_and_characteristics/item2.svg";
import evaluationItem3 from "../../assets/images/sub/purpose_and_characteristics/item3.svg";
import evaluationItem4 from "../../assets/images/sub/purpose_and_characteristics/item4.svg";
import evaluationItem5 from "../../assets/images/sub/purpose_and_characteristics/item5.svg";
import evaluationItem6 from "../../assets/images/sub/purpose_and_characteristics/item6.svg";
import evaluationItem7 from "../../assets/images/sub/purpose_and_characteristics/item7.svg";
import evaluationItem8 from "../../assets/images/sub/purpose_and_characteristics/item8.svg";

import evaluationTabletItem1 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_1.svg";
import evaluationTabletItem2 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_2.svg";
import evaluationTabletItem3 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_3.svg";
import evaluationTabletItem4 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_4.svg";
import evaluationTabletItem5 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_5.svg";
import evaluationTabletItem6 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_6.svg";
import evaluationTabletItem7 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_7.svg";
import evaluationTabletItem8 from "../../assets/images/sub/purpose_and_characteristics/item_tablet_8.svg";
import { FloatingBtn } from "../common/button/FloatingBtn";
import { Trans, useTranslation } from "react-i18next";
import { useFooter, useLanguage, useUserAgent } from "../../hooks/hooks";

export function PurposeAndCharacteristics() {
  const { t } = useTranslation(["subPurposeAndCharacteristics"]);
  const { setShowFooter } = useFooter();
  const { isDesktop } = useUserAgent();
  const { isTablet } = useUserAgent();
  const { isNotKorean } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    document.title = `${t("title")} | ${process.env.REACT_APP_TITLE}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={style.main}>
      <FloatingBtn />
      <div className={style.title}>
        <h2 className={style.text}>{t("title")}</h2>
      </div>
      <section className={style.purpose}>
        <div className={style.part1}>
          <h3>
            <ArticleTitle title={t("article1Title")} className={`${style.title} ${isNotKorean ? style.ru : ""}`} />
          </h3>
          <p className={`${style.desc1} ${isNotKorean ? style.ru : ""}`}>{t("desc1Text1")}</p>
          <div className={style.image}>
            <img src={isDesktop ? purposePic : purposePicMobile} alt="" />
          </div>
        </div>
        <div className={style.part2}>
          <p className={`${style.desc2} ${isNotKorean ? style.ru : ""}`}>
            <Trans t={t} i18nKey={"desc2Text1"} components={[<span className={style.accent} />]} />
          </p>
        </div>
      </section>
      <section className={style.characteristics}>
        {isDesktop ? (
          <>
            <div className={style.bgItem1}></div>
            <div className={style.bgItem2}></div>
            <div className={style.bgItem3}></div>
          </>
        ) : (
          <></>
        )}
        <h3>
          <ArticleTitle title={t("article2Title")} className={`${style.title} ${isNotKorean ? style.ru : ""}`} />
        </h3>
        <article className={`${style.article} ${style.weakness}`}>
          <div className={style.image}></div>
          <div className={style.text}>
            <h4 className={style.title}>{t("weaknessTitle")}</h4>
            <ul className={style.list}>
              <li>{t("weaknessDesc1")}</li>
              <li>{t("weaknessDesc2")}</li>
              <li>{t("weaknessDesc3")}</li>
              <li>{t("weaknessDesc4")}</li>
              <li>{t("weaknessDesc5")}</li>
            </ul>
          </div>
        </article>
        <article className={`${style.article} ${style.kpass}`}>
          <div className={style.image}></div>
          <div className={style.text}>
            <h4 className={style.title}>{t("digitalTestTitle")}</h4>
            <ul className={style.list}>
              <li>{t("digitalTestDesc1")}</li>
              <li>{t("digitalTestDesc2")}</li>
              <li>{t("digitalTestDesc3")}</li>
              <li>{t("digitalTestDesc4")}</li>
              <li>{t("digitalTestDesc5")}</li>
              <li>{t("digitalTestDesc6")}</li>
              <li>{t("digitalTestDesc7")}</li>
            </ul>
          </div>
        </article>
      </section>
      <section className={style.evaluationItems}>
        <ArticleTitle title={t("testSectionTitle")} className={`${style.title} ${isNotKorean ? style.ru : ""}`} />
        <div className={style.contentWrapper}>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image1}`}>
              <span className={style.indexNumber}>1</span>
              <img src={isTablet ? evaluationTabletItem1 : evaluationItem1} alt={t("testSection1ImageAlt")} />
            </div>
            <p className={style.text}>
              {t("testSection1Text1")}
              <br />
              <span className={style.lightText}>{t("testSection1Text2")}</span>
            </p>
          </div>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image2}`}>
              <span className={style.indexNumber}>2</span>
              <img src={isTablet ? evaluationTabletItem2 : evaluationItem2} alt={t("testSection2ImageAlt")} />
            </div>
            <p className={style.text}>{t("testSection2Text")}</p>
          </div>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image3}`}>
              <span className={style.indexNumber}>3</span>
              <img src={isTablet ? evaluationTabletItem3 : evaluationItem3} alt={t("testSection3ImageAlt")} />
            </div>
            <p className={style.text}>{t("testSection3Text")}</p>
          </div>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image4}`}>
              <span className={style.indexNumber}>4</span>
              <img src={isTablet ? evaluationTabletItem4 : evaluationItem4} alt={t("testSection4ImageAlt")} />
            </div>
            <p className={style.text}>{t("testSection4Text")}</p>
          </div>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image5}`}>
              <span className={style.indexNumber}>5</span>
              <img src={isTablet ? evaluationTabletItem5 : evaluationItem5} alt={t("testSection5ImageAlt")} />
            </div>
            <p className={style.text}>{t("testSection5Text")}</p>
          </div>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image6}`}>
              <span className={style.indexNumber}>6</span>
              <img src={isTablet ? evaluationTabletItem6 : evaluationItem6} alt={t("testSection6ImageAlt")} />
            </div>
            <p className={style.text}>{t("testSection6Text")}</p>
          </div>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image7}`}>
              <span className={style.indexNumber}>7</span>
              <img src={isTablet ? evaluationTabletItem7 : evaluationItem7} alt={t("testSection7ImageAlt")} />
            </div>
            <p className={style.text}>{t("testSection7Text")}</p>
          </div>
          <div className={style.contentItem}>
            <div className={`${style.image} ${style.image8}`}>
              <span className={style.indexNumber}>8</span>
              <img src={isTablet ? evaluationTabletItem8 : evaluationItem8} alt={t("testSection8ImageAlt")} />
            </div>
            <p className={style.text}>{t("testSection8Text")}</p>
          </div>
        </div>
      </section>
    </main>
  );
}
