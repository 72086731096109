import styles from "../../../assets/scss/common/modal/confirmAlertModal.module.scss";
import { ModalContent } from "../../../types/common";

interface Props {
  showModal: boolean;
  content: ModalContent;
  buttons: JSX.Element;
}

export function ConfirmAlertModal({ showModal, content, buttons }: Props) {
  return (
    <div className={`${styles.backdrop} ${showModal ? "" : styles.hide}`} onClick={(e) => e.stopPropagation()}>
      <div className={styles.modalScreen}>
        <div className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.title}>{content.title}</div>
            <div className={styles.desc}>{content.desc}</div>
          </div>
          <div className={styles.buttons}>{buttons}</div>
        </div>
      </div>
    </div>
  );
}
