import footer from "./footer.json";
import newFooter from "./newFooter.json";
import mainOutro from "./main/outro.json";
import mainIntro from "./main/intro.json";
import mainSection1 from "./main/section1.json";
import mainSection2 from "./main/section2.json";
import mainSection3 from "./main/section3.json";
import mainSection4 from "./main/section4.json";
import mainMain from "./main/main.json";
import kpass from "./new_main/kpass.json";
import competitiveness from "./new_main/competitiveness.json";
import realReview from "./new_main/realReview.json";
import subResearcher from "./sub/researcher.json";
import subPurposeAndCharacteristics from "./sub/purposeAndCharacteristics.json";
import subUnderstanding from "./sub/understanding.json";
import subUnderstandingKpass from "./sub/understandingKpass.json";
import subUnderstandingHistory from "./sub/understandingHistory.json";
import subUnderstandingAcademicBasis from "./sub/understandingAcademicBasis.json";
import drawerMenu from "./drawerMenu.json";
import header from "./header.json";
import newHeader from "./newHeader.json";
import parentsWish from "./new_main/parentsWish.json";
import reviewModal from "./modal/reviewModal.json";
import subRealReview from "./sub/realReview.json";
import downloadTest from "./downloadTest.json";
import popup from "./main/popup.json";

const indexObject = {
  header,
  newHeader,
  footer,
  newFooter,
  mainMain,
  parentsWish,
  kpass,
  competitiveness,
  realReview,
  mainOutro,
  mainIntro,
  mainSection1,
  mainSection2,
  mainSection3,
  mainSection4,
  subResearcher,
  subPurposeAndCharacteristics,
  subUnderstanding,
  subUnderstandingKpass,
  subUnderstandingHistory,
  subUnderstandingAcademicBasis,
  drawerMenu,
  reviewModal,
  subRealReview,
  downloadTest,
  popup,
};
export default indexObject;
