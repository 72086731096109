import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/scss/reset.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { MainLegacy } from "./components/main_legacy/MainLegacy";
import { Understanding } from "./components/sub/Understanding";
import { PurposeAndCharacteristics } from "./components/sub/PurposeAndCharacteristics";
import { Researcher } from "./components/sub/Researcher";
import "./lang";
import { UnderstandingKpass } from "./components/sub/UnderstandingKpass";
import { UnderstandingHistory } from "./components/sub/UnderstandingHistory";
import { UnderstandingAcademicBasis } from "./components/sub/UnderstandingAcademicBasis";
import { Provider } from "react-redux";
import { store } from "./store";
import { PurchaseVoucher } from "./components/sub/purchase/PurchaseVoucher";
import { DownloadTest } from "./components/sub/DownloadTest";
import { OrderAndPayment } from "./components/sub/payment/OrderAndPayment";
import { PaymentResult } from "./components/sub/payment/result/PaymentResult";
import { Main } from "./components/main/Main";
import { ReviewMain } from "./components/sub/review/ReviewMain";
import { KpassWithQr } from "./components/sub/qr/KpassWithQr";
import { RequireAuth } from "./components/auth/RequireAuth";
import { SignInFromKPass } from "./components/sub/sign_in/SignInFromKpass";
import { KoreanKpassStandardization } from "./components/kpass_standardization/KoreanKpassStandardization";
import { ScrollingEventProvider } from "./hooks/scroll_event/ScrollingEventProvider";
import { Partnership } from "./components/sub/Partnership";
import { PartnerList } from "./components/sub/partner/PartnerList";
import { ReviewWebDetailWrapper } from "./components/sub/review/ReviewWebDetailWrapper";
import { StartGuide } from "./components/sub/StartGuide";
import {LeaveUser} from "./components/common/modal/login/signup/LeaveUser";
import {EditMember} from "./components/sub/EditMember";
import { MyPage } from "./components/sub/MyPage";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="" element={<Main />} />
            <Route path={"with-app"} element={<Main />} />
            <Route path="main" element={<MainLegacy />} />
            <Route path="understanding" element={<Understanding />}>
              <Route path="kpass" element={<UnderstandingKpass />} />
              <Route path="history" element={<UnderstandingHistory />} />
              <Route path="academic-basis" element={<UnderstandingAcademicBasis />} />
            </Route>
            <Route path="purpose-and-characteristics" element={<PurposeAndCharacteristics />} />
            <Route path="researcher" element={<Researcher />} />
            <Route path="purchase-voucher" element={<PurchaseVoucher type={"voucher"} />} />
            <Route path="purchase-counselor" element={<PurchaseVoucher type={"counselor"} />} />
            <Route path="download-test" element={<DownloadTest />} />
            <Route path="startguide" element={<StartGuide />} />
            <Route path="mypage/user" element={<EditMember />} />
            <Route path="mypage" element={<MyPage />} />
            <Route
              path="order"
              element={
                <RequireAuth>
                  <OrderAndPayment />
                </RequireAuth>
              }
            />
            <Route
              path="payment-result/:orderIdx"
              element={
                <RequireAuth>
                  <PaymentResult />
                </RequireAuth>
              }
            />
            <Route path="review" element={<ReviewMain />} />
            <Route path="review/:id" element={<ReviewWebDetailWrapper />} />
            <Route path="partnership" element={<Partnership />} />
            <Route path="partnerlist" element={<PartnerList />} />
            <Route path="sign-in-from-kpass" element={<SignInFromKPass />} />
            <Route path="withuser" element={<LeaveUser />} />
          </Route>
          <Route path="kpass-with-qr" element={<KpassWithQr />} />
          <Route
            path="korean-version-kpass-standardization"
            element={
              <ScrollingEventProvider>
                <KoreanKpassStandardization />
              </ScrollingEventProvider>
            }
          />
          <Route path="/event/*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
