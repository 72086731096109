import style from "../../../assets/scss/sub/purchase/selector.module.scss";
import selectorArrow from "../../../assets/images/sub/purchase_voucher/selector_arrow.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { CLICK_TARGET_NAME, subscribe, unsubscribe } from "../../../util/events";

export interface SelectorOption {
  value: number | string;
  label: string;
  isLight?: boolean;
}

interface Props {
  placeholderValue: string;
  selectedOptionValue: number | null;
  options: SelectorOption[];
  optionAlternateString: string | null;
  selectOption: (_: number) => void;
}

export function Selector({
  placeholderValue,
  selectedOptionValue,
  options,
  optionAlternateString,
  selectOption,
}: Props) {
  const selectorRef = useRef<HTMLDivElement>(null);
  const [showOption, setShowOption] = useState(false);
  const onClickSelector = (showOptionVal: boolean) => setShowOption(!showOptionVal);
  const onClickOption = (optionVal: number) => selectOption(optionVal);

  const selectedOption = useMemo(
    () => options.find((o) => o.value === selectedOptionValue),
    [options, selectedOptionValue]
  );

  useEffect(() => {
    const clickDocumentListener = (e: { detail: any }) => {
      if (e.detail !== selectorRef.current) {
        setShowOption(false);
      }
    };
    subscribe("clickDocument", clickDocumentListener);

    return () => {
      unsubscribe("clickDocument", clickDocumentListener);
    };
  }, []);

  return (
    <div
      data-name={CLICK_TARGET_NAME}
      className={`${style.selector} ${selectedOptionValue ? style.selected : ""}`}
      onClick={() => onClickSelector(showOption)}
      ref={selectorRef}
    >
      <span className={style.text}>
        {selectedOptionValue ? (
          selectedOption?.label ?? <span className={style.placeholder}>{placeholderValue}</span>
        ) : (
          <span className={style.placeholder}>{placeholderValue}</span>
        )}
      </span>
      <img className={style.arrow} src={selectorArrow} alt={"Selector 선택 이미지"} />
      {showOption ? (
        <ul className={`${style.options} ${optionAlternateString ? style.optionMessage : ""}`}>
          {optionAlternateString ??
            options.map((o) => (
              <li key={`select-option${o.value}`} value={o.value} onClick={() => onClickOption(o.value as number)}>
                {o.label}
              </li>
            ))}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
}
