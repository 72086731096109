import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceState, Status } from "../../types/common";
import { LoginInfo, PhoneNumberGetterResponseDTO, RequestCodeDTO } from "../../types/auth";
import { RootState } from "../index";
import { AuthApi } from "../../api/AuthApi";
import { addCases, executePromise } from "../../util/sliceUtil";

export interface LoginState extends SliceState {}

const initialState: LoginState = {
  status: Status.IDLE,
};

export const isExistedUserAsync = createAsyncThunk("login/isExistedUser", (email: string) =>
  executePromise(AuthApi.isExistedUser(email))
);

export const sendingSignInCodeAsnyc = createAsyncThunk("login/sendingSignInCode", (dto: RequestCodeDTO) =>
  executePromise(AuthApi.requestCode(dto))
);

export const signInAsnyc = createAsyncThunk("login/signIn", (loginInfo: LoginInfo) =>
  executePromise(AuthApi.signIn(loginInfo))
);

export const getAccessTokenFromRefreshTokenAsync = createAsyncThunk(
  "login/getAccessTokenFromRefreshToken",
  (refreshToken: string) => executePromise(AuthApi.getAccessTokenFromRefreshToken(refreshToken))
);

export const getPhoneNumberByEmailAsync = createAsyncThunk("login/getPhoneNumberByEmail", (email: string) =>
  executePromise<PhoneNumberGetterResponseDTO>(AuthApi.getPhoneNumberByEmail(email))
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, isExistedUserAsync);
    addCases(builder, sendingSignInCodeAsnyc);
    addCases(builder, signInAsnyc);
    addCases(builder, getPhoneNumberByEmailAsync);
  },
});

export default loginSlice.reducer;

export const loginStatus = (state: RootState) => state.login.status;
