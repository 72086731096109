import style from "./domesticPartnerCompanyCards.module.scss";
import { useUserAgent } from "../../../hooks/hooks";
import { PartnerCompanyVO } from "../../../types/partnerCompany";
import { APartnerCompanyDomesticButtons } from "./APartnerCompanyDomesticButtons";
import { useAddress } from "../../../hooks/useAddress";
import { useCallback, useEffect, useRef } from "react";

interface Props {
  isSelected: boolean;
  partnerCompany: PartnerCompanyVO;
  selectPartnerCompany: (_: PartnerCompanyVO) => void;
  parentElement: HTMLDivElement | null;
}

export function APartnerCompanyDomestic({ isSelected, partnerCompany, selectPartnerCompany, parentElement }: Props) {
  const { isDesktop } = useUserAgent();
  const { getAddressLine1 } = useAddress();
  const cardElementRef = useRef<HTMLDivElement | null>(null);

  const onClickPartnerCompany = useCallback(() => {
    selectPartnerCompany(partnerCompany);
  }, [partnerCompany, selectPartnerCompany]);

  useEffect(() => {
    if (isSelected && parentElement && cardElementRef.current) {
      const { y: parentY } = parentElement.getBoundingClientRect();
      const { y: childY } = cardElementRef.current.getBoundingClientRect();

      parentElement.scrollTo({
        top: parentElement.scrollTop + childY - parentY,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  return (
    <div
      className={`${style.aCard} ${isSelected ? style.selected : ""}`}
      onClick={onClickPartnerCompany}
      ref={cardElementRef}
    >
      <div className={style.logoArea} style={{ backgroundImage: `url("${partnerCompany.logo}")` }}></div>
      <div className={style.infoArea}>
        <div className={style.name}>{partnerCompany.name}</div>
        <div className={style.address}>
          <span className={style.postalCode}>{partnerCompany.address.postalCode}</span>
          <div className={style.addressTexts}>
            <span>{getAddressLine1(partnerCompany.address)}</span>
            <span>{partnerCompany.address.address2 ?? ""}</span>
          </div>
        </div>
        {isDesktop && <APartnerCompanyDomesticButtons partnerCompany={partnerCompany} />}
      </div>
      {!isDesktop && <APartnerCompanyDomesticButtons partnerCompany={partnerCompany} />}
    </div>
  );
}
