import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SliceState, Status} from "../../types/common";
import {RootState} from "../index";
import {addCases, executePromise} from "../../util/sliceUtil";
import {UserApi} from "../../api/UserApi";
import {UserDetail, UserInfoUpdateDTO, UserLeavePostDTO} from "../../types/user";

export interface UserState extends SliceState {
  user?: UserDetail
}

const initialState: UserState = {
  status: Status.IDLE
};

export const getUserDetailAsync = createAsyncThunk(
  'user/getUserDetail',
  () => executePromise(UserApi.getDetailInfo())
);

export const modifyMemberAsync = createAsyncThunk(
    'user/modifyMember',
    (condition: UserInfoUpdateDTO) => executePromise(UserApi.modifyMember(condition))
)

// 탈퇴 API
export const leaveMember = createAsyncThunk("signup/leaveMember", (condition: UserLeavePostDTO) =>
    executePromise(UserApi.leaveMember(condition))
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserDetail>) => {
      state.user = action.payload;
    }
  },
  extraReducers: builder => {
    addCases(builder, getUserDetailAsync);
  }
});

export const {
  setUser
} = userSlice.actions;

export const userStatus = (state: RootState) => state.user.status;
export const userDetail = (state: RootState) => state.user.user;

export default userSlice.reducer;