import modalStyle from "../../../assets/scss/common/modal/modal.module.scss";
import styles from "../../../assets/scss/common/modal/loginModal.module.scss";
import {Modal} from "./Modal";
import {useCallback, useEffect, useState} from "react";
import {useAppSelector, useLoader, useModal} from "../../../hooks/hooks";
import {loginStatus} from "../../../store/login/loginSlice";
import {ModalType} from "../../../types/common";
import {EmailStep} from "./login/EmailStep";
import {CodeStep} from "./login/CodeStep";
import {AuthWayChoiceStep} from "./login/AuthWayChoiceStep";

export enum LoginStep {
  EMAIL = "EMAIL",
  AUTH_WAY = "AUTH_WAY",
  EMAIL_CODE = "EMAIL_CODE",
  PHONE_NUMBER_CODE = "PHONE_NUMBER_CODE",
}

export function LoginModal() {
  const status = useAppSelector(loginStatus);
  const { showModal, closeModal } = useModal();
  const { setLoaderStatus } = useLoader();

  const [loginStep, setLoginStep] = useState<LoginStep>(LoginStep.EMAIL);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [incorrectCodeCount, setIncorrectCodeCount] = useState(0);
  const [isBackAfterSendingCode, setIsBackAfterSendingCode] = useState(false);
  const [title, setTitle] = useState("로그인 & 회원가입")

  const onClickCloseBtn = () => closeModal();

  const changeLoginStep = useCallback((step: LoginStep) => setLoginStep(step), []);

  useEffect(() =>{
    setTitle("로그인 & 회원가입")
  },[])

  useEffect(() => {
    setLoaderStatus([status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (loginStep === LoginStep.EMAIL) {
      setEmail("");
    }
  }, [loginStep]);

  // const { isDesktop, isTablet } = useUserAgent();

  return (
    <Modal
      showModal={showModal.valueOf() === ModalType.LOGIN.valueOf()}
      title={title}
      closeModalFunction={onClickCloseBtn}
      modalBgStyle={modalStyle.mainColorBg}
      modalCloseButtonStyle={modalStyle.mainColorBg}
      modalOtherStyle={`${modalStyle.loginModal} ${styles.wrapper}`}
    >
      <div className={styles.content}>
        {loginStep === LoginStep.EMAIL && (
          <EmailStep
            changeLoginStep={changeLoginStep}
            incorrectCodeCount={incorrectCodeCount}
            setIncorrectCodeCount={setIncorrectCodeCount}
            email={email}
            setEmail={setEmail}
            isBackAfterSendingCode={isBackAfterSendingCode}
            setIsBackAfterSendingCode={setIsBackAfterSendingCode}
          />
        )}
        {loginStep === LoginStep.AUTH_WAY && (
          <AuthWayChoiceStep
            email={email}
            changeLoginStep={changeLoginStep}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            setTitle={setTitle}
          />
        )}
        {(loginStep === LoginStep.EMAIL_CODE || loginStep === LoginStep.PHONE_NUMBER_CODE) && (
          <CodeStep
            changeLoginStep={changeLoginStep}
            setIncorrectCodeCount={setIncorrectCodeCount}
            email={email}
            step={loginStep}
            phoneNumber={phoneNumber}
            setIsBackAfterSendingCode={setIsBackAfterSendingCode}
          />
        )}
      </div>
    </Modal>
  );
}
