function subscribe(eventName: string, listener: (_: any) => void) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: string, listener: (_: any) => void) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: string, data?: any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

const CLICK_TARGET_NAME = "ClickedTarget";

export { publish, subscribe, unsubscribe, CLICK_TARGET_NAME };
