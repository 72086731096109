import styled from "styled-components";
import kpassWithIcon from "../../../assets/images/kpass_with_icon.png";
import { useEffect } from "react";

const KpassWithQrStyle = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
    height: 50px;
  }
`;

declare global {
  interface Window {
    opera: any;
    MSStream: any;
  }
}

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function KpassWithQr() {
  useEffect(() => {
    let redirectUrl;
    let mobileSystem = getMobileOperatingSystem();
    if (mobileSystem === "iOS") {
      redirectUrl = "https://apps.apple.com/kr/app/id6444020031";
    } else if (mobileSystem === "Android") {
      redirectUrl =
        "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=io.feel_good.kpasswith";
    } else {
      redirectUrl = "https://play.google.com/store/apps/details?id=io.feel_good.kpasswith";
    }

    window.location.href = redirectUrl;
  }, []);
  return (
    <KpassWithQrStyle>
      <img src={kpassWithIcon} alt="kpass-with" />
    </KpassWithQrStyle>
  );
}
