import { MainWrapper } from "./MainWrapper";
import style from "../../assets/scss/sub/startGuide.module.scss";
import { useState } from "react";

export function StartGuide() {
  const [memberType, setMemberType] = useState("personal");

  const setPersonalMember = () => {
    setMemberType("personal");
  };

  const setGroupMember = () => {
    setMemberType("group");
  };

  return (
    <MainWrapper showFooter={true}>
      <>
        <div className={style.bgContainer}>
          <img src="https://images.feel-good.io/inspection_method/top_banner_background.png" className={style.topBg}
          alt={"top-banner-background"}/>
          <div className={style.textContainer}>
            <div className={style.topBgSubText}>내 아이의 미래를 바꾸는</div>
            <div className={style.topBgMainText}>K-PASS! 어떻게 시작 할까요?</div>
            <img src="https://images.feel-good.io/inspection_method/top_main_image.png" className={style.mainImage}
            alt={"top-main-image"}/>
          </div>
        </div>
        <div className={style.btnContainer}>
          <button
            className={`${style.indiBtn} ${memberType === "personal" ? style.isActive : ""} `}
            onClick={setPersonalMember}
          >
            개인 회원 안내
          </button>
          <button
            className={`${style.memberBtn} ${memberType === "group" ? style.isActive : ""} `}
            onClick={setGroupMember}
          >
            소속 회원 안내
          </button>
        </div>

        {memberType === "personal" ? (
          <>
            {/* 개인 회원 안내 */}
            <div className={style.whiteBg}>
              {/* 01 회원가입 */}
              <div className={style.processTextContainer}>
                  <div className={style.signupImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>1</span>
                    </p>
                    <p className={style.stepTitle}>회원가입</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>[ K-PASS 홈페이지 ] 에서 회원가입을 진행해주세요.</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* 02 자녀등록 */}
              <div className={`${style.processTextContainer} ${style.processTopMargin}`}>
                <div className={style.mypageImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>2</span>
                    </p>
                    <p className={style.stepTitle}>자녀등록</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        [ K-PASS 홈페이지 ] - [ My Page ] 에서 검사를 진행할 프로필(자녀)을 등록해 주세요.
                      </li>
                      <li className={style.listSubItem}>[ 관리중인 프로필 ] 목록에 본인 프로필은 자동으로 생성되어 있습니다.</li>
                      <li className={style.listSubItem}>검사를 진행할 추가 프로필(자녀)을 등록해 주세요.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.blueBg} ${style.processTopMargin}`}>
              {/* 03 이용권 구매 */}
              <div className={`${style.processTextContainer}`}>
                <div className={style.voucherPurchaseImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>3</span>
                    </p>
                    <p className={style.stepTitle}>이용권 구매</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        [ K-PASS 홈페이지 ] 에서 이용권을 구매해 주세요.
                      </li>
                      <li className={style.listSubItem}>
                        경로 : [ K-PASS 홈페이지 ] - [ 검사 이용권 구매 ]
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* 04 다운로드 */}
              <div className={`${style.processTextContainer} ${style.processTopMargin}`}>
                <div className={style.downloadImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>4</span>
                    </p>
                    <p className={style.stepTitle}>다운로드</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        PC : [ K-PASS 홈페이지 ] 에서 [ K-PASS ] 를 다운로드 후 실행해 주세요!
                      </li>
                      <li className={style.listItem}>
                        태블릿 : 앱스토어 또는 구글 플레이에서 [ K-PASS ] 혹은 [ 케이패스 ] 검색해 주세요!
                      </li>
                      <li className={style.listSubItem}>모바일에서는 불가능합니다.</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* 05 로그인 */}
              <div
                className={`${style.processTextContainer} ${style.processTopMargin}`}
                style={{ marginBottom: "100px" }}
              >
                <div className={style.loginImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>5</span>
                    </p>
                    <p className={style.stepTitle}>로그인</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        [ K-PASS 홈페이지 ] 에서 가입한 메일 주소로 로그인 후 휴대폰 또는 이메일로 로그인 인증코드를 받아 주세요.
                      </li>
                      <li className={style.listItem}> 인증코드를 입력하면 로그인이 완료됩니다.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.whiteBg}>
              <div className={`${style.processTextContainer}`}>
                <div className={style.gameImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>6</span>
                    </p>
                    <p className={style.stepTitle}>검사진행</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>검사를 진행 할 자녀 프로필을 선택하여 검사를 진행해주세요.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={`${style.processTextContainer} ${style.processTopMargin} ${style.processBottomMargin}`}>
                <div className={style.resultImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>7</span>
                    </p>
                    <p className={style.stepTitle}>결과보기</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>검사 완료후 [ 검사결과보기 ]를 눌러 검사결과를 확인하세요.</li>
                      <li className={style.listSubItem}>
                          게임종료 후 [ K-PASS 홈페이지 ] - [ My Page ] 에서도 검사 결과를 확인하실 수 있습니다.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* 소속 회원 안내 */}
            <div className={style.whiteBg}>
              {/* 01 코드 받기 */}
              <div className={style.processTextContainer}>
                <div className={style.organCodeImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>1</span>
                    </p>
                    <p className={style.stepTitle}>코드 받기</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        소속 관리자를 통해 소속에 등록된 6자리 [ 로그인코드 ]와 [ 이름 ]을 확인해 주세요.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.blueBg} ${style.processTopMargin}`}>
              {/* 02 다운로드 */}
              <div className={`${style.processTextContainer} ${style.processTopMargin}`}>
                <div className={style.organDownloadImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>2</span>
                    </p>
                    <p className={style.stepTitle}>다운로드</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        PC : [ K-PASS 홈페이지 ] 에서 [ K-PASS ] 를 다운로드 후 실행해 주세요!
                      </li>
                      <li className={style.listItem}>
                        태블릿 : 앱스토어 또는 구글 플레이에서 [ K-PASS ] 혹은 [ 케이패스 ] 검색해 주세요!{" "}
                      </li>
                      <li className={style.listSubItem}>모바일에서는 불가능합니다.</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* 03 로그인 */}
              <div className={`${style.processTextContainer}`}>
                <div className={style.organLoginImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>3</span>
                    </p>
                    <p className={style.stepTitle}>로그인</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        [ K-PASS ] 실행 후 소속 관리자에게 전달받은 [ 로그인코드 ]와 [ 이름 ]을 입력하여 로그인해 주세요.
                      </li>
                      <li className={style.listSubItem}>로그인코드를 모를 경우 소속 관리자에게 문의해 주세요.</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* 04 검사진행 */}
              <div className={`${style.processTextContainer} ${style.processBottomMargin2}`}>
                <div className={style.organProcessingImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>4</span>
                    </p>
                    <p className={style.stepTitle}>검사진행</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>
                        자신의 프로필과 보유한 소속이용권 수량을 확인 후 검사를 진행합니다.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.whiteBg}`}>
              {/* 05 결과보기 */}
              <div className={`${style.processTextContainer}`} style={{marginBottom: "100px"}}>
                <div className={style.organResultImg}/>
                <div className={style.processStep}>
                  <div className={style.processHeader}>
                    <p className={style.stepNumber}>
                      <span className={style.firstLetter}>0</span>
                      <span>5</span>
                    </p>
                    <p className={style.stepTitle}>결과보기</p>
                  </div>
                  <div className={style.verticalBar}></div>
                  <div className={style.ulContainer}>
                    <ul className={style.stepDescription}>
                      <li className={style.listItem}>검사 완료 후 [ 검사결과보기 ]를 눌러 검사결과를 확인하세요.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </MainWrapper>
  );
}
