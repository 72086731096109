import { Modal } from "../Modal";
import { ModalType } from "../../../../types/common";
import { useAppSelector, useLoader, useModal } from "../../../../hooks/hooks";
import modalStyle from "../../../../assets/scss/common/modal/modal.module.scss";
import { ReviewDetail } from "../../../sub/review/detail/ReviewDetail";
import { useCallback, useEffect, useState } from "react";
import { useReviewsGetter } from "../../../sub/review/hooks/useReviewsGetter";
import { KPASSReviewVO, ReviewImageDTO } from "../../../../types/review";
import { SecuredUserProfile, TesterInfo } from "../../../../types/user";
import { useProfileGetter } from "../../../../hooks/profile/useProfileGetter";
import { useTesterInfoGetter } from "./hooks/useTesterInfoGetter";
import { useTranslation } from "react-i18next";
import { reviewStatusState } from "../../../../store/review/reviewSlice";

interface Props {
  reviewIdx?: number;
  isBestReview: boolean;
}

export function ReviewDetailModal({ reviewIdx, isBestReview }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const { getReview, getReviewImages } = useReviewsGetter();
  const { getSecuredProfile } = useProfileGetter();
  const { getTesterInfoByReviewIdx } = useTesterInfoGetter();
  const { showModal, closeModal, openAlertModal } = useModal();
  const [reviewVO, setReviewVO] = useState<KPASSReviewVO | undefined>();
  const [reviewImages, setReviewImages] = useState<ReviewImageDTO[] | undefined>();
  const [profile, setProfile] = useState<SecuredUserProfile | undefined>();
  const [testerInfo, setTesterInfo] = useState<TesterInfo | undefined>();
  const reviewState = useAppSelector(reviewStatusState);
  const { setLoaderStatus } = useLoader();

  useEffect(() => {
    setLoaderStatus([reviewState]);
    // eslint-disable-next-line
  }, [reviewState]);

  const loadReview = useCallback(
    async (reviewIdx: number) => {
      try {
        const reviewVO = await getReview(reviewIdx);
        const reviewImages = await getReviewImages(reviewIdx);
        const profile = await getSecuredProfile(reviewIdx);
        const testerInfo = await getTesterInfoByReviewIdx(reviewIdx);

        setReviewVO(reviewVO);
        setReviewImages(reviewImages);
        setProfile(profile);
        setTesterInfo(testerInfo);
      } catch (e) {
        console.error(e);
        openAlertModal({
          title: t("errorModalTitle"),
          desc: t("loadReviewError"),
        });
        closeModal();
      }
    },
    [
      getReview,
      getReviewImages,
      setReviewVO,
      setReviewImages,
      openAlertModal,
      getSecuredProfile,
      setProfile,
      closeModal,
      getTesterInfoByReviewIdx,
      t,
    ]
  );

  useEffect(() => {
    if (reviewIdx === undefined) {
      openAlertModal({
        title: t("errorModalTitle"),
        desc: t("badAccess"),
      });
      closeModal();
      return;
    }

    loadReview(reviewIdx).then();
    // eslint-disable-next-line
  }, [loadReview, reviewIdx]);

  return (
    <Modal
      showModal={showModal.valueOf() === ModalType.REVIEW_DETAIL.valueOf()}
      title={t("detailModalTitle")}
      closeModalFunction={() => closeModal()}
      modalBgStyle={modalStyle.whiteBg}
      modalOtherStyle={modalStyle.reviewDetailModal}
    >
      <>
        {reviewVO && reviewImages && profile && testerInfo && (
          <ReviewDetail
            review={reviewVO}
            reviewImages={reviewImages}
            profile={profile}
            testerInfo={testerInfo}
            isBestReview={isBestReview}
            reload={loadReview}
          />
        )}
      </>
    </Modal>
  );
}
