import style from "../../../assets/scss/sub/partnership.module.scss";
import spaceImage from "../../../assets/images/sub/partner_company/space.png";
import leftBgItem from "../../../assets/images/sub/partner_company/left_pattern.png";
import rightBgItem from "../../../assets/images/sub/partner_company/right_pattern.png";
import {ArticleTitle} from "../ArticleTitle";
import {StrongTextStyle} from "../../common/StyledCommonComponents";
import {APartnerCompanyOverseas} from "./APartnerCompanyOverseas";
import townImage from "../../../assets/images/sub/partner_company/town.png";
import townImageMobile from "../../../assets/images/sub/partner_company/town_mobile.png";
import React from "react";
import {useUserAgent} from "../../../hooks/hooks";
import {PartnerCompanyVO} from "../../../types/partnerCompany";

interface Props {
  partnerCompanies: PartnerCompanyVO[];
}
export function OverseasPartnerList({ partnerCompanies }: Props) {
  const { isMobile } = useUserAgent();

  return (
    <article className={`${style.article} ${style.white}`}>
      <div className={style.bgItemSpace}>
        <img src={isMobile ? spaceImage : spaceImage} alt={"우주"} />
      </div>
      <div className={style.bgItemLeft}>
        <img src={leftBgItem} alt={"왼쪽 배경"} />
      </div>
      <div className={style.bgItemRight}>
        <img src={rightBgItem} alt={"오른쪽 배경"} />
      </div>
      <section className={style.section}>
        <ArticleTitle title={"해외 협력사"} className={style.sectionTitle} />
        <p className={style.description}>
          <StrongTextStyle>필굿은 글로벌 교육의 허브로 나아가고 있습니다.</StrongTextStyle>
          <br />
          국내 뿐 아니라 해외 아이들을 위해 협력하여 K-PASS 검사를 시행 중입니다.
        </p>
        <ul className={style.overseasPartnerWrapper}>
          {partnerCompanies.map((vo) => (
            <APartnerCompanyOverseas key={vo.idx} logo={vo.logo} name={vo.name} />
          ))}
        </ul>
      </section>
      <img className={style.townImage} src={isMobile ? townImageMobile : townImage} alt={"마을"} />
    </article>
  );
}
