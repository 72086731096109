import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../../util/sliceUtil";
import { ReviewApi } from "../../api/ReviewApi";
import { PageResponse, PageSearchCondition } from "../../types/page";
import { RootState } from "../index";
import { ReviewListVO, ReviewPostDTO, ReviewPutDTO } from "../../types/review";

export interface ReviewState extends SliceState {
  reviewTotalCount?: number;
  reviews?: PageResponse<ReviewListVO>;
  bestReviews?: ReviewListVO[];
}

const initialState: ReviewState = {
  status: Status.IDLE,
};

export const getReviewsAsync = createAsyncThunk("review/getReviews", (condition: PageSearchCondition) =>
  executePromise(ReviewApi.getReviews(condition))
);

export const getReviewAsync = createAsyncThunk("review/getReview", (reviewIdx: number) =>
  executePromise(ReviewApi.getReview(reviewIdx))
);

export const getPrevReviewAsync = createAsyncThunk("review/getPrevReview", (reviewIdx: number) =>
  executePromise(ReviewApi.getPrevReview(reviewIdx))
);

export const getNextReviewAsync = createAsyncThunk("review/getNextReview", (reviewIdx: number) =>
  executePromise(ReviewApi.getNextReview(reviewIdx))
);

export const getReviewImagesAsync = createAsyncThunk("review/getReviewImages", (reviewIdx: number) =>
  executePromise(ReviewApi.getReviewImages(reviewIdx))
);

export const getProfileReviewStatusesAsync = createAsyncThunk("review/getProfileReviewStatuses", (profileIdx: number) =>
  executePromise(ReviewApi.getProfileReviewStatuses(profileIdx))
);

export const createReviewAsync = createAsyncThunk("review/createReview", (reviewPostDTO: ReviewPostDTO) =>
  executePromise(ReviewApi.createReview(reviewPostDTO))
);

export const updateReviewAsync = createAsyncThunk(
  "review/updateReview",
  ({ reviewIdx, reviewPutDTO }: { reviewIdx: number; reviewPutDTO: ReviewPutDTO }) =>
    executePromise(ReviewApi.updateReview(reviewIdx, reviewPutDTO))
);

export const deleteReviewAsync = createAsyncThunk("review/deleteReview", (reviewIdx: number) =>
  executePromise(ReviewApi.deleteReview(reviewIdx))
);

export const getBestReviewsAsync = createAsyncThunk("review/getBestReviews", () =>
  executePromise(ReviewApi.getBestReviews())
);

export const getProfileByReviewIdxAsync = createAsyncThunk("review/getProfileByReviewIdx", (reviewIdx: number) =>
  executePromise(ReviewApi.getProfileByReviewIdx(reviewIdx))
);

export const getTesterInfoByReviewIdxAsync = createAsyncThunk("review/getTesterInfoByReviewIdx", (reviewIdx: number) =>
  executePromise(ReviewApi.getTesterInfoByReviewIdx(reviewIdx))
);

export const getReviewCreateStatusAsync = createAsyncThunk("review/getReviewCreateStatus", () =>
  executePromise(ReviewApi.getReviewCreateStatus())
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setReviewsState: (state, action: PayloadAction<PageResponse<ReviewListVO>>) => {
      const dto = action.payload;
      state.reviewTotalCount = dto.totalElements;
      state.reviews = dto;
    },
    setBestReviewsState: (state, action: PayloadAction<ReviewListVO[]>) => {
      state.bestReviews = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getReviewsAsync);
    addCases(builder, getReviewAsync);
    addCases(builder, getReviewImagesAsync);
    addCases(builder, getProfileReviewStatusesAsync);
    addCases(builder, getBestReviewsAsync);
    addCases(builder, createReviewAsync);
    addCases(builder, updateReviewAsync);
    addCases(builder, getPrevReviewAsync);
    addCases(builder, getNextReviewAsync);
    addCases(builder, getProfileByReviewIdxAsync);
    addCases(builder, getTesterInfoByReviewIdxAsync);
    addCases(builder, getReviewCreateStatusAsync);
  },
});

export const { setReviewsState, setBestReviewsState } = reviewSlice.actions;

export const reviewsState = (state: RootState) => state.review.reviews;
export const reviewTotalCountState = (state: RootState) => state.review.reviewTotalCount;
export const reviewStatusState = (state: RootState) => state.review.status;
export const bestReviewsState = (state: RootState) => state.review.bestReviews;
export default reviewSlice.reducer;
