import { Modal } from "../Modal";
import { ModalType } from "../../../../types/common";
import { useAppSelector, useLoader, useModal } from "../../../../hooks/hooks";
import modalStyle from "../../../../assets/scss/common/modal/modal.module.scss";
import { ReviewBodyStyle, ReviewPolicyStyle, SaveButtonStyle } from "./components/common/StyledComponents";
import { Profiles } from "./components/profiles/Profiles";
import { Title } from "./components/title/Title";
import { Description } from "./components/description/Description";
import { Pictures } from "./components/pictures/Pictures";
import { useProfileReviewGetter } from "./hooks/useProfileReviewGetter";
import { useCallback, useEffect, useRef } from "react";
import { profileStatusState } from "../../../../store/profile/profileSlice";
import { reviewStatusState } from "../../../../store/review/reviewSlice";
import { ReviewValidationError, useReviewValidator } from "./hooks/useReviewValidator";
import { useReviewImages } from "./hooks/useReviewImages";
import { useReviewCreator } from "./hooks/useReviewCreator";
import { hasAnyInputState, reset } from "../../../../store/review/reviewModalSlice";
import { useDispatch } from "react-redux";
import { fileStatus } from "../../../../store/file/fileSlice";
import { Agreement } from "./components/agreement/Agreement";
import { useTranslation } from "react-i18next";
import { useReviewsGetter } from "../../../sub/review/hooks/useReviewsGetter";

export function ReviewWriteModal() {
  const { t } = useTranslation(["reviewModal"]);
  const dispatch = useDispatch();
  const { showModal, closeModal } = useModal();
  const { getProfileReviews } = useProfileReviewGetter();
  const profileState = useAppSelector(profileStatusState);
  const reviewState = useAppSelector(reviewStatusState);
  const fileState = useAppSelector(fileStatus);
  const hasAnyInput = useAppSelector(hasAnyInputState);
  const { createReview } = useReviewCreator();
  const { validate } = useReviewValidator();
  const { uploadReviewImages } = useReviewImages();
  const { getReviews } = useReviewsGetter();
  const { setLoaderStatus } = useLoader();
  const { openAlertModal, openConfirmModal } = useModal();

  const profileRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    getProfileReviews().then();
  }, [getProfileReviews]);

  useEffect(() => {
    setLoaderStatus([profileState, reviewState, fileState]);
  }, [profileState, reviewState, setLoaderStatus, fileState]);

  const validateAndScrollTo = useCallback((): boolean => {
    const errors = validate();
    if (errors.some((it) => it === ReviewValidationError.PROFILE)) profileRef.current?.scrollIntoView();
    else if (errors.some((it) => it === ReviewValidationError.TITLE)) titleRef.current?.focus();
    else if (errors.some((it) => it === ReviewValidationError.DESCRIPTION)) descriptionRef.current?.focus();
    return errors.length === 0;
  }, [validate, profileRef, titleRef, descriptionRef]);

  const closeReviewWriteModal = useCallback(() => {
    dispatch(reset());
    closeModal();
  }, [dispatch, closeModal]);

  const onClickCreateReview = useCallback(async () => {
    if (!validateAndScrollTo()) return;
    try {
      const reviewImages = await uploadReviewImages();
      await createReview(reviewImages);
      await getReviews({});

      openAlertModal({
        title: t("writeCompleteModalTitle"),
        desc: t("writeCompleteModalMessage"),
        callback: () => closeReviewWriteModal(),
      });
    } catch (e) {
      console.error(e);
      openAlertModal({
        title: t("errorModalTitle"),
        desc: t("errorModalMessage"),
        callback: () => closeReviewWriteModal(),
      });
    }
  }, [validateAndScrollTo, uploadReviewImages, createReview, closeReviewWriteModal, openAlertModal, t, getReviews]);

  const onClickCloseModalButton = useCallback(() => {
    if (!hasAnyInput) {
      closeReviewWriteModal();
      return;
    }

    openConfirmModal(
      {
        title: t("stopWriteReviewModalTitle"),
        desc: t("stopWriteReviewModalMessage"),
      },
      () => closeReviewWriteModal()
    );
  }, [hasAnyInput, closeReviewWriteModal, openConfirmModal, t]);

  return (
    <Modal
      showModal={showModal.valueOf() === ModalType.REVIEW_WRITE.valueOf()}
      title={t("writeModalTitle")}
      closeModalFunction={onClickCloseModalButton}
      modalBgStyle={modalStyle.mainColorBg}
      modalOtherStyle={modalStyle.reviewModal}
    >
      <ReviewBodyStyle>
        <Profiles profileRef={profileRef} />
        <Title inputRef={titleRef} />
        <Description textRef={descriptionRef} />
        <Pictures />
        <Agreement />
        <SaveButtonStyle onClick={onClickCreateReview}>{t("saveButton")}</SaveButtonStyle>
        <ReviewPolicyStyle>
          <span>* {t("warningMessage")}</span>
        </ReviewPolicyStyle>
      </ReviewBodyStyle>
    </Modal>
  );
}
