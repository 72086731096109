import React, { useCallback, useEffect, useState } from "react";
import style from "../../../assets/scss/sub/partnership.module.scss";
import { FloatingBtn } from "../../common/button/FloatingBtn";
import { useAppSelector, useFooter, useLoader, useModal } from "../../../hooks/hooks";
import { usePartnerCompaniesGetter } from "./usePartnerCompaniesGetter";
import { PartnerCompanyType, PartnerCompanyVO } from "../../../types/partnerCompany";
import { partnerCompanyStatusState } from "../../../store/partner_company/partnerCompanySlice";
import { DomesticPartnerList } from "./DomesticPartnerList";
import { OverseasPartnerList } from "./OverseasPartnerList";
import { useKakaoLoader } from "react-kakao-maps-sdk";
import { Status } from "../../../types/common";

const DEFAULT_PAGE_SIZE = 1000;
export function PartnerList() {
  const { setShowFooter } = useFooter();
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    // eslint-disable-next-line
  }, []);

  const [loading] = useKakaoLoader({
    appkey: "5b9631a350cba2089f76a62735051f9b",
    libraries: ["services"],
  });

  const partnerCompanyStatus = useAppSelector(partnerCompanyStatusState);
  const { setLoaderStatus } = useLoader();
  useEffect(() => {
    const mapLoadingStatus: Status = loading ? Status.LOADING : Status.IDLE;
    setLoaderStatus([partnerCompanyStatus, mapLoadingStatus]);
  }, [loading, partnerCompanyStatus, setLoaderStatus]);

  const [domesticPartnerCompanies, setDomesticPartnerCompanies] = useState<PartnerCompanyVO[] | null>(null);
  const [overseasPartnerCompanies, setOverseasPartnerCompanies] = useState<PartnerCompanyVO[] | null>(null);
  const { getPartnerCompanies } = usePartnerCompaniesGetter();
  const { openAlertModal } = useModal();

  const sortNumericOrderDesc = useCallback(
    (companies: PartnerCompanyVO[]) => companies.sort((a, b) => b.numericOrder - a.numericOrder),
    []
  );

  useEffect(() => {
    getPartnerCompanies({
      pageCondition: {
        size: DEFAULT_PAGE_SIZE,
      },
    })
      .then((companies: PartnerCompanyVO[]) => {
        const voList = companies.filter((vo: PartnerCompanyVO) => vo.type === PartnerCompanyType.DOMESTIC);
        setDomesticPartnerCompanies(sortNumericOrderDesc(voList));
        setOverseasPartnerCompanies(
          sortNumericOrderDesc(companies.filter((vo) => vo.type === PartnerCompanyType.OVERSEAS))
        );
      })
      .catch(() => {
        openAlertModal(null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchDomestic = useCallback(
    (searchWord: string) => {
      getPartnerCompanies({
        type: PartnerCompanyType.DOMESTIC,
        address: searchWord.trim(),
        pageCondition: {
          size: DEFAULT_PAGE_SIZE,
        },
      }).then((companies: PartnerCompanyVO[]) => {
        setDomesticPartnerCompanies(sortNumericOrderDesc(companies));
      });
    },
    [getPartnerCompanies, sortNumericOrderDesc]
  );

  return (
    <main className={`${style.partnership} ${style.companyList}`}>
      <FloatingBtn />
      <div className={style.title}>
        <h2 className={style.text}>협력사</h2>
      </div>
      <DomesticPartnerList partnerCompanies={domesticPartnerCompanies ?? []} search={searchDomestic} />
      <OverseasPartnerList partnerCompanies={overseasPartnerCompanies ?? []} />
    </main>
  );
}
