import style from "../../assets/scss/main_legacy/readMoreBtn.module.scss";
import linkArrow from "../../assets/images/main_legacy/link_arrow.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function ReadMoreBtn({ link }) {
  const { t } = useTranslation(["mainMain"]);

  return (
    <Link
      to={link}
      className={style.btn}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <span className={style.text}>{t("readMore")}</span>
      <img className={style.arrow} src={linkArrow} alt={t("readMoreIconAlt")} />
    </Link>
  );
}
