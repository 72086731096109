import React, {useEffect} from "react";
import style from "../../../assets/scss/common/modal/aboutConsultantModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import {ConsultationItem} from "../../../types/consultation";
import {useConsultationGetter} from "../../sub/consultation/hooks/useConsultationGetter";

interface AddChildModalProps {
  isOpen: boolean;
  selectedConsultation: ConsultationItem | null;
  onClose: () => void;
}

const AboutConsultantModal: React.FC<AddChildModalProps> = (
    {isOpen, onClose, selectedConsultation}) => {
  const {getConsultantInfo, consultantInfo} = useConsultationGetter();

  useEffect(() => {
    if(!selectedConsultation?.counselingIdx ) return;
    isOpen && getConsultantInfo(selectedConsultation?.counselingIdx).then();
    // eslint-disable-next-line
  }, [selectedConsultation]);

  if (!isOpen) {
    return null;
  }

  return (
      <div className={style.addChildModalOverlay}>
        <div className={style.addChildInfoModal}>
          <div className={style.closeBtnContainer}>
            <img
                src={CloseBtn}
                alt="closeBtn"
                className={style.closeBtn}
                onClick={() => onClose()}
            />
          </div>
          {consultantInfo && (
              <div className={style.innerModalContainer}>
                <h3 className={style.addChildInfoTitle}>상담사 정보</h3>
                <div className={style.innerTextContainer}>
                  <div className={style.infoContainer}>
                    <span className={style.infoTitleText}>상담사 기본 정보</span>
                    {/* 이미지 */}
                    <div className={style.imgContainer}>
                      <div className={style.imgBorderContainer}>
                        <img
                            className={style.consultantImg}
                            alt={"consultant_img"}
                            src={consultantInfo.image.uri}
                        />
                      </div>
                    </div>
                    <span className={style.infoText}>이름</span>
                    <span className={style.inputField} >{consultantInfo.consultantName}</span>
                    <span className={style.infoText}>이메일</span>
                    <span className={style.inputField}>{consultantInfo.email}</span>
                    <span className={style.infoText}>휴대전화</span>
                    <span className={style.inputField}>{consultantInfo.phoneNumber}</span>
                  </div>
                </div>
                <div className={style.innerTextContainer}>
                  <div className={style.infoContainer}>
                    <span className={style.infoTitleText}>상담사 약력</span>
                    <div className={style.aboutConsultantContainer}>
                      {consultantInfo.briefHistory}
                    </div>
                  </div>
                </div>
              </div>
          )}
        </div>
      </div>
  );
};

export default AboutConsultantModal;
