import style from "../../../../assets/scss/common/modal/addConsultationModal.module.scss";
import React from "react";

interface Args {
    childName: string;
    testInfo: string;
}

export function ReservationProfileInfo({childName, testInfo}: Args) {
    /**
     * 프로필(자녀) 정보 확인 영역
     */
    return (
        <div className={style.innerTextContainer}>
            <div className={style.infoContainer}>
                <span className={style.infoTitleText}> 프로필(자녀) 정보 확인</span>
                <span className={style.infoText}>이름</span>
                <input
                    placeholder="아이이름1"
                    className={style.inputField}
                    value={childName}
                    disabled={true}
                />
                <span className={style.infoText}>검사종류</span>
                <input
                    placeholder="K-PASS 1회차"
                    className={style.inputField}
                    value={testInfo}
                    disabled={true}
                />
            </div>
        </div>

    )
}