import {useCallback} from "react";
import {useAppDispatch} from "../../../../../../hooks/hooks";
import {LoginResponse} from "../../../../../../types/auth";
import {UserInfoUpdateDTO} from "../../../../../../types/user";
import {modifyMemberAsync} from "../../../../../../store/user/userSlice";

export function useModifyMember() {
    const dispatch = useAppDispatch();

    const modifyMember = useCallback(
        async (modify: UserInfoUpdateDTO) => {
            const request: UserInfoUpdateDTO = {
                name: modify.name,
                gender: modify.gender,
                birthday: modify.birthday,
                phoneNumber: modify.phoneNumber,
                codePhone: modify.codePhone,
                address: modify.address,
                nationality: modify.nationality,
                isAgreementEmailMarketingReceipt: modify.isAgreementEmailMarketingReceipt,
                isAgreementSMSMarketingReceipt: modify.isAgreementSMSMarketingReceipt,
                isAgreementPersonalInfoCollectionAndUsage: modify.isAgreementPersonalInfoCollectionAndUsage,
            };

            try {
                const result: LoginResponse= await dispatch(modifyMemberAsync(request)).unwrap();
                console.log("result : ", result)
                console.log('modify successful');
            } catch (error) {
                console.error('Failed to signup:', error);
                throw error;
            }
        },
        [dispatch]
    );

    return { modifyMember };
}