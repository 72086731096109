import style from "../../../assets/scss/sub/downloadTest.module.scss";

interface Props {
  number: number;
  normalText: string;
  accentText: string;
  imageUrl: string;
}

export function GuideListItem({ number, normalText, accentText, imageUrl }: Props) {
  return (
    <li className={style.guide}>
      <div className={style.textArea}>
        <div className={style.number}>{number}</div>
        <div className={style.descText}>
          <span className={style.text}>{normalText}</span>
          {accentText ? <span className={style.accent}>{accentText}</span> : <></>}
        </div>
      </div>
      <div className={style.descImage}>{imageUrl ? <img src={imageUrl} alt={"설명 관련 이미지"} /> : <></>}</div>
    </li>
  );
}
