import style from "./slider.module.scss";
import React from "react";

interface Props {
  slidersLength: number;
  currentSlideIdx: number;
  className?: string;
}

export function SliderBullets({ slidersLength, currentSlideIdx, className }: Props) {
  return (
    <div className={`${style.bullets} ${className ?? ""}`}>
      {Array.from({ length: slidersLength }, (_, idx) => (
        <button
          key={`bullet-button-${idx}`}
          className={currentSlideIdx === idx ? style.active : style.default}
        ></button>
      ))}
    </div>
  );
}
