import styles from "../../../../assets/scss/common/modal/loginModal.module.scss";
import React, { useCallback, useRef, useState } from "react";
import { ErrorType } from "../../../../types/error";
import {useAuth, useModal, useUserAgent} from "../../../../hooks/hooks";
import { LoginStep } from "../LoginModal";
import { Timer } from "./Timer";

interface Props {
  step: LoginStep;
  email: string;
  phoneNumber: string;
  changeLoginStep: (_: LoginStep) => void;
  setIncorrectCodeCount: (_: number) => void;
  setIsBackAfterSendingCode: (_: boolean) => void;
}

export function CodeStep({
  step,
  email,
  phoneNumber,
  changeLoginStep,
  setIncorrectCodeCount,
  setIsBackAfterSendingCode,
}: Props) {
  const LOGIN_TRY_COUNT = 5;
  const LOGIN_BLOCK_SEC = 5 * 60;
  const codeFormRef = useRef<HTMLFormElement>(null);
  const { signIn } = useAuth();
  const { closeModal, openAlertModal } = useModal();

  const [loginCode, setLoginCode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [canLogin, setCanLogin] = useState(true);
  const {isMobile} = useUserAgent();

  const login = useCallback(
    async (emailAddr: string, code: string) => {
      signIn(
        { email: emailAddr, code },
        () => {
          closeModal();
          changeLoginStep(LoginStep.EMAIL);
        },
        (e: ErrorType) => {
          const serverErrorCode = e.errorCode.httpCode;

          switch (serverErrorCode) {
            case 461:
              setAlertMessage("이미 로그인에 사용된 코드입니다.\n아래 취소버튼을 통해 다시 시도해주세요.");
              setCanLogin(false);
              break;
            case 462:
              openAlertModal({
                title: "안내",
                desc: "잠시 후 시도해 주세요.",
              });
              break;
            case 463:
              setAlertMessage("입력시간이 초과된 코드입니다.\n아래 취소버튼을 통해 다시 시도해주세요.");
              setCanLogin(false);
              break;
            case 464:
              setIncorrectCodeCount(LOGIN_TRY_COUNT);
              setAlertMessage("인증 횟수가 초과된 코드입니다. 뒤로가기를 통해 다시 시도해주세요.");
              break;
            case 465:
              const incorrectCount = e?.data?.incorrect_count ?? 0;
              setIncorrectCodeCount(incorrectCount);

              let message = `인증코드를 잘못입력하셨습니다. (${
                incorrectCount ? `${incorrectCount}/${LOGIN_TRY_COUNT}회)` : ""
              }`;
              if (incorrectCount >= LOGIN_TRY_COUNT) {
                message += `\n아래 취소버튼을 통해 다시 시도해주세요.`;
                setCanLogin(false);
              }

              setAlertMessage(message);
              break;
            default:
              openAlertModal(null);
              break;
          }
        }
      );
    },
    [changeLoginStep, closeModal, openAlertModal, setIncorrectCodeCount, signIn]
  );

  const onClickLoginBtn = () => {
    const trimmedCode = loginCode.trim();
    if (trimmedCode === "") {
      setAlertMessage("인증코드를 입력해주세요.");
      return;
    }

    const codeRegExp = /^[0-9|a-z|A-Z]{6}$/;
    if (!codeRegExp.test(trimmedCode)) {
      setAlertMessage("잘못된 인증코드입니다. 다시 입력해주세요!");
      return;
    }

    login(email.trim(), trimmedCode).then();
  };

  const onClickCancelBtn = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setAlertMessage("");
      changeLoginStep(LoginStep.EMAIL);
      setIsBackAfterSendingCode(true);
    },
    [changeLoginStep, setIsBackAfterSendingCode]
  );

  const doAtTheEnd = useCallback(() => {
    setAlertMessage("입력시간이 초과된 코드입니다.\n아래 취소버튼을 통해 다시 시도해주세요.");
    setCanLogin(false);
  }, []);

  return (
    <div className={styles.textDesc}>
      <div className={styles.desc}>
        {step === LoginStep.EMAIL_CODE && (
          <>
            <span className={styles.accent}>{email}</span>
            <br />위 메일을 확인하여 인증코드를 입력해주세요!
          </>
        )}
        {step === LoginStep.PHONE_NUMBER_CODE && (
          <>
            <span className={styles.accent}>{phoneNumber}</span>
            <br />
            위 번호로 받으신 인증코드를 입력해주세요!
            <br/>
            {isMobile ? (
                <>
                  인증 코드를 받지 못한 경우<br/>스팸 메시지를 확인해주세요.
                </>

                ) :
                <>
                  인증 코드를 받지 못한 경우 스팸 메시지를 확인해주세요.
                </>
            }

          </>
        )}
      </div>
      <form className={styles.emailForm} onSubmit={(e) => e.preventDefault()} ref={codeFormRef}>
        <input
          type="tel"
          className={styles.input}
          value={loginCode}
          onInvalid={(e) => e.preventDefault()}
          onChange={(e) => setLoginCode(e.target.value.toUpperCase())}
          placeholder={"인증코드 6자리 입력"}
        />
        {canLogin ? (
          <Timer initialMilliseconds={LOGIN_BLOCK_SEC * 1000} styleClassName={styles.timer} doAtTheEnd={doAtTheEnd} />
        ) : (
          <></>
        )}
        {alertMessage ? <span className={styles.invalidEmailAddr}>{alertMessage}</span> : <></>}
        <div className={`${styles.buttonsWrapper} ${styles.withMarginTop}`}>
          <button type="button" className={styles.outlineButton} onClick={onClickCancelBtn}>
            취소
          </button>
          {canLogin ? (
            <button type="button" className={styles.primaryButton} onClick={onClickLoginBtn}>
              로그인
            </button>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
}
