import style from "./effects.module.scss";
import { StrongTextStyle } from "../../../common/StyledCommonComponents";
import effectImage from "../../../../assets/images/main/article2/section2/effects/main_point2.gif";
import { useUserAgent } from "../../../../hooks/hooks";
import { Trans, useTranslation } from "react-i18next";
import { IsDesktopBr } from "../../../common/IsDesktopBr";
import { EffectProps } from "./Effect1";
import { useDragEvent } from "../../../../hooks/useDragEvent";
export function Effect2({ moveNext, movePrev }: EffectProps) {
  const { t } = useTranslation(["kpass"]);
  const { isDesktop } = useUserAgent();
  const { handleDragStart, handleDragEnd } = useDragEvent({
    moveRight: () => moveNext?.(),
    moveLeft: () => movePrev?.(),
  });

  return (
    <div className={style.wrapper}>
      <div className={style.image} draggable={true} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <img src={effectImage} alt={t("section2.slider2.effectImageAlt")} draggable={"false"} />
      </div>
      {isDesktop ? <></> : <div className={style.space}></div>}
      <div className={style.text}>
        <p className={style.title}>{t("section2.slider2.title")}</p>
        <p className={style.desc}>
          <Trans
            t={t}
            i18nKey={"section2.slider2.description"}
            components={[<IsDesktopBr></IsDesktopBr>, <StrongTextStyle></StrongTextStyle>]}
          />
        </p>
      </div>
    </div>
  );
}
