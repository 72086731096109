import { ChildrenProp, ConfirmModalContent, ModalContent, ModalType } from "../../types/common";
import { ModalContext } from "./ModalContext";
import { useCallback, useMemo, useState } from "react";
import { TermsCategory } from "../../types/terms";
import { ConfirmModal } from "../../components/common/modal/ConfirmModal";
import { AlertModal } from "../../components/common/modal/AlertModal";
import { ModalRoutingComponent } from "./ModalRoutingComponent";
import { useLocationHistory } from "../location/useLocationHistory";
import { AffiliateCodeVO } from "../../types/affiliateCode";

export function ModalProvider({ children }: ChildrenProp) {
  const initialModalContent: ModalContent = useMemo(
    () => ({
      title: "안내",
      desc: "잠시 후 다시 시도해주세요.",
    }),
    []
  );
  const [showModal, setShowModal] = useState<ModalType>(ModalType.NONE);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alertModalContent, setAlertModalContent] = useState<ModalContent>(initialModalContent);
  const [termsModalCategory, setTermsModalCategory] = useState<TermsCategory>(TermsCategory.NONE);
  const [reviewIdx, setReviewIdx] = useState<number>();
  const [isBestReview, setIsBestReview] = useState<boolean>(false);
  const [confirmModalContent, setConfirmModalContent] = useState<ConfirmModalContent>({
    content: initialModalContent,
    confirmFunc: () => {},
  });
  const [affiliateCodeVO, setAffiliateCodeVO] = useState<AffiliateCodeVO | null>(null);

  const openConfirmModal = useCallback((content: ModalContent, confirmFunc: VoidFunction) => {
    setConfirmModalContent({ content, confirmFunc });
    setShowConfirmModal(true);
  }, []);

  const openAlertModal = useCallback((content: ModalContent | null) => {
    setAlertModalContent(
      content ?? {
        title: "안내",
        desc: "일시적으로 서버에서 에러가 발생했습니다.\n잠시 후 다시 시도해주세요.",
      }
    );
    setShowAlertModal(true);
  }, []);

  const { pushHistory } = useLocationHistory({ callbackOnBack: () => closeModal() });

  const openModal = useCallback(
    (type: ModalType) => {
      pushHistory();
      setShowModal(type);
    },
    [setShowModal, pushHistory]
  );
  const openTermsModal = (category: TermsCategory) => {
    setTermsModalCategory(category);
    openModal(ModalType.TERMS);
  };

  const openReviewEditModal = (reviewIdx: number) => {
    setReviewIdx(reviewIdx);
    openModal(ModalType.REVIEW_EDIT);
  };

  const openReviewDetailModal = (reviewIdx: number, isBestReview: boolean) => {
    setReviewIdx(reviewIdx);
    setIsBestReview(isBestReview);
    openModal(ModalType.REVIEW_DETAIL);
  };

  const openAffiliateCodeModal = useCallback(
    (codeVO: AffiliateCodeVO) => {
      openModal(ModalType.AFFILIATE_CODE);
      setAffiliateCodeVO(codeVO);
    },
    [openModal]
  );

  const closeModal = useCallback(() => setShowModal(ModalType.NONE), [setShowModal]);

  return (
    <ModalContext.Provider
      value={{
        showModal,
        openModal,
        closeModal,
        openConfirmModal,
        openAlertModal,
        openTermsModal,
        openReviewEditModal,
        openReviewDetailModal,
        openAffiliateCodeModal,
      }}
    >
      <>
        <ModalRoutingComponent
          modalType={showModal}
          modalParam={{
            termsCategory: termsModalCategory,
            reviewIdx,
            isBestReview,
            affiliateCodeVO,
          }}
        />
        <ConfirmModal
          content={confirmModalContent}
          showConfirmModal={showConfirmModal}
          closeConfirmModal={(e) => {
            setShowConfirmModal(false);
            e.stopPropagation();
          }}
        />
        <AlertModal
          content={alertModalContent}
          showAlertModal={showAlertModal}
          closeAlertModal={(e) => {
            setShowAlertModal(false);
            e.stopPropagation();
          }}
        />
        {children}
      </>
    </ModalContext.Provider>
  );
}
