import appStoreBadge from "../../../../assets/images/modal/app_download/app_store_badge.png";
import styled from "styled-components";

const Link = styled.a`
  width: 166px;
  height: 50px;

  > img {
    width: 100%;
  }
`;

export function AppleAppStoreBadge() {
  return (
    <Link href={"https://apps.apple.com/app/id6444020031"} target={"_blank"}>
      <img src={appStoreBadge} alt={"앱 스토어 뱃지"} />
    </Link>
  );
}
