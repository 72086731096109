import { ModalContent } from "../../../types/common";
import { ConfirmAlertModal } from "./ConfirmAlertModal";
import styles from "../../../assets/scss/common/modal/confirmAlertModal.module.scss";
import React, { useCallback } from "react";

interface Props {
  content: ModalContent;
  showAlertModal: boolean;
  closeAlertModal: (e: React.MouseEvent) => void;
}

export function AlertModal({ content, showAlertModal, closeAlertModal }: Props) {
  const Buttons = useCallback(
    () => (
      <button
        className={styles.alertBtn}
        onClick={(e: React.MouseEvent) => {
          closeAlertModal(e);
          content.callback?.();
        }}
      >
        확인
      </button>
    ),
    [closeAlertModal, content]
  );

  return <ConfirmAlertModal showModal={showAlertModal} content={content} buttons={<Buttons />} />;
}
