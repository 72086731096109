// src/hooks/useValidation.ts

// 프로필 정보 인터페이스 정의
interface ProfileInfo {
  city: string;
  name: string;
  birthday: string;
  gender: string;
  address1: string;
  address2: string;
}

// 오류 메시지 인터페이스 정의
interface Errors {
  name: string;
  birthday: string;
  gender: string;
  address1: string;
  address2: string;
  city: string;
}

const useValidation = () => {
  // 각 필드에 대한 오류 메시지를 반환합니다.
  const validateName = (name: string) => {
    if (!name) {
      return "이름을 입력해 주세요.";
    }
    if (!/^[a-zA-Z가-힣]+$/.test(name)) {
      return "이름은 한글, 영어로 입력해 주세요.";
    }
    return "";
  };

  const validateBirthday = (birthday: string) => {
    if (!birthday) {
      return "생년월일을 입력해 주세요.";
    }
    if (!/^\d{8}$/.test(birthday)) {
      return "생년월일은 8자리 숫자로 입력해 주세요.";
    }
    return "";
  };

  const validateGender = (gender: string) => {
    if (!gender) {
      return "성별을 선택해 주세요.";
    }
    return "";
  };

  // 전체 프로필 정보 유효성 검사
  const validateProfileInfo = (profileInfo: ProfileInfo) => {
    const nameError = validateName(profileInfo.name);
    const birthdayError = validateBirthday(profileInfo.birthday);
    const genderError = validateGender(profileInfo.gender);

    return {
      name: nameError,
      birthday: birthdayError,
      gender: genderError,
    };
  };

  return {
    validateProfileInfo,
    validateName,
    validateBirthday,
    validateGender,
  };
};

export default useValidation;
