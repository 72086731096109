enum PaymentWay {
  CARD = 'CARD',
  VBANK = 'VBANK'
}

enum CashReceiptCategory {
  PRIVATE = 'PRIVATE',
  BUSINESS = 'BUSINESS',
  NONE = 'NONE'
}

interface ReadyToPayment {
  payMethod: PaymentWay,
  goodsName: string,
  amt: number,
  mid: string,
  buyerName: string,
  buyerEmail: string,
  buyerTel: string,
  returnUrl: string,
  vBankExpDate: string,
  ediDate: string,
  signData: string,
  orderIdx: number,
}

export { PaymentWay, CashReceiptCategory };
export type { ReadyToPayment }