import styles from "../../../../../assets/scss/common/modal/leaveUser.module.scss";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAuth, useFooter, useModal} from "../../../../../hooks/hooks";
import {RequestCodeType} from "../../../../../types/auth";
import {isExistedUserAsync} from "../../../../../store/login/loginSlice";
import {useCodeSender} from "../hooks/useCodeSender";
import {AuthWay} from "../../../../../types/user";
import sendIcon from "../../../../../assets/images/modal/login/send_icon.svg";
import {usePhoneNumberByEmailGetter} from "../hooks/usePhoneNumberByEmailGetter";
import {ErrorType} from "../../../../../types/error";
import {useSignupMember} from "./hooks/useSignupMember";
import {LeavePostDTO} from "../../../../../types/signup";
import {useCookies} from 'react-cookie';

enum ExitStatus {
    STEP1 = "STEP1",
    STEP2 = "STEP2",
    STEP3 = "STEP3",
    STEP5 = "STEP5",
    STEP6 = "STEP6",
}

enum ExitReason {
    REMOVE = "기록 삭제 목적",
    INCONVENIENT = "서비스 이용 불편",
    USE_OTHER_SITE = "다른 사이트 이용",
    LOW_USE = "사용빈도가 낮음",
    HUGE_PROGRAM = "프로그램 용량이 큼",
    MANY_ERRORS = "프로그램 오류가 많음",
    CONTENTS = "콘텐츠 불만",
    PERSONAL_INFORMATION = "개인정보 유출 우려",
    SERVICE = "고객응대 불만",
    ETC = "ETC",
    NONE = ""
}


export function LeaveUser() {

    const [email, setEmail] = useState("")
    const [step, setStep] = useState(ExitStatus.STEP1)
    const [voucherAgreement, setVoucherAgreement] = useState(false);
    const [affiliationAgreement, setAffiliationAgreement] = useState(false);
    const [sameAccountAgreement, setSameAccountAgreement] = useState(false);
    const [informationAgreement, setInformationAgreement] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [isAllAgreementSelected, setIsAllAgreementSelected] = useState(false);
    const [radioOption, setRadioOption] = useState(ExitReason.NONE)
    const [radioTextReason, setRadioTextReason] = useState("")
    const {openAlertModal, openConfirmModal, closeModal} = useModal();
    const [, , removeCookie] = useCookies(['accessToken', 'refreshToken']);
    const { setShowFooter } = useFooter();


    const [step1Valid, setStep1Valid] = useState("");
    const [step2Valid, setStep2Valid] = useState("");
    const [step3Valid, setStep3Valid] = useState("");
    const [step5Valid, setStep5Valid] = useState(true);
    const [step6Valid, setStep6Valid] = useState(true);


    /**
     * step1
     *
     */
    const [canClickSendBtn,] = useState(true);
    const dispatch = useAppDispatch();
    const {sendCode} = useCodeSender();

    const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = e.target.value.replace(/\s/g, ""); // 모든 공백 제거
        setStep1Valid("")
        setEmail(emailValue);
    };


    useEffect(() => {
        setShowFooter(true)
        if (step === ExitStatus.STEP1) {
            console.log('go to step1')
            removeCookie("accessToken",)
            removeCookie("refreshToken",)
            setStep1Valid("");
            setStep2Valid("");
            setStep3Valid("");
            setStep5Valid(true);
            setStep6Valid(true);
            setLoginCode("");
            setVoucherAgreement(false);
            setAffiliationAgreement(false);
            setSameAccountAgreement(false);
            setInformationAgreement(false);
            setSelectAll(false);

        }
// eslint-disable-next-line
    }, [step]);

    const isExistedUser = useCallback(
        async (emailAddr: string) => {
            try {
                return await dispatch(isExistedUserAsync(emailAddr)).unwrap();
            } catch (error) {
                const errorObj: any = JSON.parse((error as Error).message);
                switch (errorObj?.errorCode?.httpCode) {
                    case 477:
                        setStep1Valid("해당 계정은 이미 탈퇴한 계정입니다.\n다른 이메일주소로 재시도해주세요.");
                        break;
                    case 472:
                        setStep1Valid("현재 차단된 계정입니다.\n고객센터로 문의바랍니다.");
                        break;
                    default:
                        setStep1Valid("");
                }

                throw error;
            }
        },
        // eslint-disable-next-line
        [dispatch, openAlertModal]
    );
    const onClickSendingLoginCodeBtn = () => {
        if (canClickSendBtn) {
            const trimmedEmail = email.trim();
            if (trimmedEmail === "") {
                setStep1Valid("이메일을 입력해주세요.");
                return;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+[^\s@]+$/;

            const isValidEmailForm = emailRegex.test(email);
            if (!isValidEmailForm) {
                setStep1Valid("잘못된 형식의 이메일주소입니다. \n다시 입력해주세요!");
                return;
            }

            isExistedUser(trimmedEmail).then(({result}: { result: boolean }) => {
                if (result) {
                    getPhoneNumberByEmailGetter(email)
                        .then((phoneNumber) => setPhoneNumber(phoneNumber))
                        .catch((e) => {
                            console.error(e);
                            openAlertModal(null);
                            setAuthWay(AuthWay.PHONE);
                            handleNextStep(ExitStatus.STEP3);
                        });

                    setStep(ExitStatus.STEP2)
                } else {
                    setStep1Valid("존재하지 않는 이메일입니다. \n다시 입력해주세요.")
                }
            });
        }
    };

    /**
     * step2
     *
     */
    const [authWay, setAuthWay] = useState(AuthWay.NONE);
    const [phoneNumber, setPhoneNumber] = useState("");
    const {getPhoneNumberByEmailGetter} = usePhoneNumberByEmailGetter();
    const {leaveRequestMember} = useSignupMember();


    const onClickAuthWayButton = useCallback((toAuthWay: AuthWay) => {
        setAuthWay(toAuthWay);
        setStep2Valid("")
        // setErrorMessage("");
    }, []);

    const handleNextStep = (steps: ExitStatus) => {
        if (step === ExitStatus.STEP5) {
            if (isAllAgreementSelected) {
                setStep(steps);
            } else {
                setStep5Valid(false);
            }
        } else if (step === ExitStatus.STEP6) {
            if (radioOption === ExitReason.NONE) {
                setStep6Valid(false);
            } else {
                handleExit()
            }

        } else {
            setStep(steps);

        }
    }

    const handleFirstStep = () => {
        removeCookie("accessToken",)
        removeCookie("refreshToken",)
        setStep1Valid("");
        setStep2Valid("");
        setStep3Valid("");
        setStep5Valid(true);
        setStep6Valid(true);
        setStep(ExitStatus.STEP1)
        window.location.reload()
    }

    const handleExit = () => {

        const leaveRequest: LeavePostDTO = {
            reason: radioOption === ExitReason.ETC ? radioTextReason : radioOption
        }
        const confirmExitModal = () => {
            openConfirmModal(
                {
                    title: "회원탈퇴 안내",
                    desc: "정말 회원탈퇴를 하시겠습니까?\n" +
                        "회원 탈퇴 시 모든 정보는 삭제되며,\n" +
                        "삭제된 정보는 복구할 수 없습니다",
                },
                () => {
                    leaveRequestMember(leaveRequest).then(r => console.log(leaveRequest))
                    removeCookie("accessToken",)
                    removeCookie("refreshToken",)
                    closeModal();
                    openAlertModal(
                        {
                            title: "회원탈퇴 안내",
                            desc: "회원탈퇴가 완료 되었습니다.",
                        },
                    )
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            );
        }

        confirmExitModal()
    }

    const onClickSendCodeButton = useCallback(() => {
        if (authWay === AuthWay.NONE) {
            setStep2Valid("인증 방식을 선택해주세요.");
            return;
        }

        sendCode(email, authWay === AuthWay.EMAIL ? RequestCodeType.EMAIL : RequestCodeType.PHONE_NUMBER)
            .then(() => {
                console.log('send and step3')
                setAuthWay(authWay === AuthWay.EMAIL ? AuthWay.EMAIL : AuthWay.PHONE);
                handleNextStep(ExitStatus.STEP3);
            })
            .catch();
        // eslint-disable-next-line
    }, [authWay, email, sendCode]);



    /**
     * step3
     */
    const [loginCode, setLoginCode] = useState("");
    const [canLogin, setCanLogin] = useState(true);
    const codeFormRef = useRef<HTMLFormElement>(null);
    const LOGIN_TRY_COUNT = 5;
    const {signIn} = useAuth();
    const login = useCallback(
        async (emailAddr: string, code: string) => {
            signIn(
                {email: emailAddr, code},
                () => {
                    closeModal();
                    handleNextStep(ExitStatus.STEP5)
                },
                (e: ErrorType) => {
                    const serverErrorCode = e.errorCode.httpCode;

                    switch (serverErrorCode) {
                        case 461:
                            setStep3Valid("이미 로그인에 사용된 코드입니다.\n아래 취소버튼을 통해 다시 시도해주세요.");
                            setCanLogin(false);
                            break;
                        case 462:
                            openAlertModal({
                                title: "안내",
                                desc: "잠시 후 시도해 주세요.",
                            });
                            break;
                        case 463:
                            setStep3Valid("입력시간이 초과된 코드입니다.\n아래 취소버튼을 통해 다시 시도해주세요.");
                            break;
                        case 464:
                            setStep3Valid("인증 횟수가 초과된 코드입니다. 뒤로가기를 통해 다시 시도해주세요.");
                            break;
                        case 465:
                            const incorrectCount = e?.data?.incorrect_count ?? 0;

                            let message = `인증코드를 잘못입력하셨습니다. (${
                                incorrectCount ? `${incorrectCount}/${LOGIN_TRY_COUNT}회)` : ""
                            }`;
                            if (incorrectCount >= LOGIN_TRY_COUNT) {
                                message += `\n아래 취소버튼을 통해 다시 시도해주세요.`;
                                setCanLogin(false);
                            }

                            setStep3Valid(message);
                            break;
                        default:
                            openAlertModal(null);
                            break;
                    }
                }
            );
        },
        // eslint-disable-next-line
        [closeModal, openAlertModal, signIn]
    );

    const onClickLoginBtn = () => {
        const trimmedCode = loginCode.trim();
        if (trimmedCode === "") {
            setStep3Valid("인증코드를 입력해주세요.");
            return;
        }

        const codeRegExp = /^[0-9|a-z|A-Z]{6}$/;
        if (!codeRegExp.test(trimmedCode)) {
            setStep3Valid("잘못된 인증코드입니다. 다시 입력해주세요!");
            return;
        }

        login(email.trim(), trimmedCode).then();
    };


    /**
     * step 5
     */

    const selectAgreeWrappers = [
        {
            content: '이용권을 보유하셨다면 탈퇴 전 사용해주세요. 미사용 탈퇴 시 이용권도 삭제됩니다. 단, 구매일로부터 7일 이내의 이용권은 환불이 가능합니다.',
            isAgreed: voucherAgreement,
            setAgreement: setVoucherAgreement,
            isAllSelectButton: false,
        },
        {
            content: '소속이용권을 보유중이시라면 회원 탈퇴 시 자동으로 소속에서 퇴출되며, 소속이용권은 반환됩니다.',
            isAgreed: affiliationAgreement,
            setAgreement: setAffiliationAgreement,
            isAllSelectButton: false,
        },
        {
            content: '동일한 계정(이메일)으로는 재가입이 불가능 합니다.',
            isAgreed: sameAccountAgreement,
            setAgreement: setSameAccountAgreement,
            isAllSelectButton: false,
        },
        {
            content: '회원 탈퇴 시 모든 정보는 삭제됩니다. 삭제된 정보는 복구할 수 없으니 신중하게 결정해 주세요. 단, K-PASS 검사 및 D-CAS 검사 정보는 삭제되지 않으니 미리 확인해 주세요.',
            isAgreed: informationAgreement,
            setAgreement: setInformationAgreement,
            isAllSelectButton: false,
        },
        {
            content: '모두 확인하였으며, 이에 동의합니다.',
            isAgreed: selectAll,
            setAgreement: setSelectAll,
            isAllSelectButton: true,
        },
    ];

    useEffect(() => {
        setIsAllAgreementSelected(voucherAgreement && affiliationAgreement && sameAccountAgreement && informationAgreement)
        setSelectAll(voucherAgreement && affiliationAgreement && sameAccountAgreement && informationAgreement)
        setStep5Valid(true);
        // setMandatoryAgreementValidate(ValidateStatus.NONE)
        // eslint-disable-next-line
    }, [voucherAgreement, affiliationAgreement, sameAccountAgreement, informationAgreement]);

    const handleTotalAgreement = () => {
        if (voucherAgreement && affiliationAgreement && sameAccountAgreement && informationAgreement) {
            setVoucherAgreement(false)
            setAffiliationAgreement(false)
            setSameAccountAgreement(false)
            setInformationAgreement(false)
        } else {
            // 하나라도 false일 경우 모두 true로 설정
            setVoucherAgreement(true)
            setAffiliationAgreement(true)
            setSameAccountAgreement(true)
            setInformationAgreement(true)
        }
    }

    // useEffect(() => {
    //
    //     if (voucherAgreement && affiliationAgreement && sameAccountAgreement && informationAgreement) {
    //         setIsAllAgreementSelected(true);
    //     } else {
    //         setIsAllAgreementSelected(false);
    //     }
    //
    // }, [voucherAgreement, affiliationAgreement, sameAccountAgreement, informationAgreement, selectAll]);

    /**
     * step 6
     */

    const reasonRadioWrappers = [
        {
            content: '기록 삭제 목적',
            option: ExitReason.REMOVE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '서비스 이용 불편',
            option: ExitReason.INCONVENIENT,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '다른 사이트 이용',
            option: ExitReason.USE_OTHER_SITE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '사용빈도가 낮음',
            option: ExitReason.LOW_USE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '프로그램 용량이 큼',
            option: ExitReason.HUGE_PROGRAM,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '프로그램 오류가 많음',
            option: ExitReason.MANY_ERRORS,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '콘텐츠 불만',
            option: ExitReason.CONTENTS,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '개인정보 유출 우려',
            option: ExitReason.PERSONAL_INFORMATION,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '고객응대 불만',
            option: ExitReason.SERVICE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '기타(직접 입력)',
            option: ExitReason.ETC,
            setRadioOption: setRadioOption,
            isEtcStatus: true,
        },
    ];

    useEffect(() => {
        setStep6Valid(true);
    }, [radioOption]);

    return (
        <>

            <div className={styles.mainWrapper}>
                <div className={styles.titleBoxWrapper}>
                    <div className={styles.titleText}>
                        K-PASS with 회원 탈퇴
                    </div>
                </div>


                {step === ExitStatus.STEP1 && (
                    <div className={styles.modalStyle}>
                        <div className={styles.modalWrapper}>
                            <div className={styles.modalContent}>
                                <div className={styles.contentType}>
                                    <div className={styles.contentTitle}>
                                        회원 탈퇴 할 이메일을 입력해주세요!
                                    </div>
                                    <div className={styles.inputErrorWrapper}>
                                        <div className={styles.inputWrapper}>
                                            <input
                                                type="email"
                                                className={styles.contentInput}
                                                value={email}
                                                name="email"
                                                onInvalid={(e) => e.preventDefault()}
                                                onChange={handleSetEmail}
                                                placeholder={"이메일 입력"}
                                            />
                                            {/*<input*/}
                                            {/*    placeholder={"이메일 입력"}*/}
                                            {/*/>*/}
                                        </div>
                                        <div className={styles.errorMessageWrapper}>
                                            <span className={styles.errorMessage}>
                                                {step1Valid}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.buttonsWrapper}>
                                    <button className={styles.nextButton}
                                        // onClick={() => handleNextStep(ExitStatus.STEP2)}
                                            onClick={onClickSendingLoginCodeBtn}
                                    >
                                        <span className={styles.nextButtonText}>다음</span>

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {step === ExitStatus.STEP2 && (
                    <div className={styles.authWayUpperWrapper}>
                        <div className={styles.authWayWrapper}>
                            <span className={styles.desc}>인증 방식을 선택해 주세요.</span>
                            <div className={styles.waysWrapper}>
                                <button
                                    className={`${styles.aWay} ${authWay === AuthWay.PHONE ? styles.active : ""}`}
                                    onClick={() => onClickAuthWayButton(AuthWay.PHONE)}
                                >
                                    <div className={styles.checkbox}></div>
                                    <span className={styles.label}>
            <span>휴대폰 인증&nbsp;</span>
                                        <span className={styles.light}>({phoneNumber})</span>
          </span>
                                </button>
                                <button
                                    className={`${styles.aWay} ${authWay === AuthWay.EMAIL ? styles.active : ""}`}
                                    onClick={() => onClickAuthWayButton(AuthWay.EMAIL)}
                                >
                                    <div className={styles.checkbox}></div>
                                    <span className={styles.label}>
            <span>이메일 인증&nbsp;</span>
            <span className={styles.light}>({email})</span>
          </span>
                                </button>
                                {step2Valid && (
                                    <div className={styles.errorMessageWrapper}>
                                                <span className={styles.errorMessage}>
                                                    {step2Valid}
                                                </span>
                                    </div>
                                )}
                            </div>

                            <div className={styles.buttonsWrapper}>
                                <button className={styles.cancelButton} onClick={handleFirstStep}>
                                    <span className={styles.buttonText}>취소</span>
                                </button>
                                <button className={styles.nextButton} onClick={onClickSendCodeButton}>
                                    <span className={styles.buttonText}>인증코드 보내기 </span>
                                    <img className={styles.icon} src={sendIcon} alt={"보내기"}/>
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {step === ExitStatus.STEP3 && (
                    <div className={styles.modalStyle}>
                        <div className={styles.modalWrapper}>
                            <div className={styles.authCodeWrapper}>
                                <div className={styles.textDesc}>
                                    <div className={styles.desc}>
                                        {authWay === AuthWay.EMAIL && (
                                            <>
                                                <span className={styles.accent}>{email}</span>
                                                <br/>위 메일을 확인하여 인증코드를 입력해주세요!
                                            </>
                                        )}
                                        {authWay === AuthWay.PHONE && (
                                            <>
                                                <span className={styles.accent}>{phoneNumber}</span>
                                                <br/>위 번호로 받으신 인증코드를 입력해주세요!<br/>
                                                (<>인증 코드를 받지 못한 경우<br/>스팸 메시지를 확인해주세요.</>
                                                )
                                            </>
                                        )}
                                    </div>
                                    <form className={styles.emailForm} onSubmit={(e) => e.preventDefault()}
                                          ref={codeFormRef}>
                                        <input
                                            type="tel"
                                            className={styles.input}
                                            value={loginCode}
                                            onInvalid={(e) => e.preventDefault()}
                                            onChange={(e) => {
                                                setLoginCode(e.target.value.toUpperCase())
                                                setStep3Valid("")
                                            }}
                                            placeholder={"인증코드 6자리 입력"}
                                        />
                                        {/*{canLogin ? (*/}
                                        {/*    <Timer initialMilliseconds={LOGIN_BLOCK_SEC * 1000} styleClassName={styles.timer}*/}
                                        {/*           doAtTheEnd={doAtTheEnd}/>*/}
                                        {/*) : (*/}
                                        {/*    <></>*/}
                                        {/*)}*/}
                                        {step3Valid && (
                                            <div className={styles.errorMessageWrapper}>
                                                <span className={styles.errorMessage}>
                                                    {step3Valid}
                                                </span>
                                            </div>
                                        )}
                                    </form>
                                    <div className={styles.buttonsWrapper}>
                                        <button className={styles.cancelButton} onClick={handleFirstStep}>
                                            <span className={styles.buttonText}>취소</span>
                                        </button>
                                        {canLogin && (
                                            <button className={styles.nextButton}
                                                    onClick={onClickLoginBtn}>
                                                <span className={styles.buttonText}>로그인</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {step === ExitStatus.STEP5 && (
                    <div className={styles.exitModalStyle}>
                        <div className={styles.informationWrapper}>
                            <span className={styles.informationTitle}>
                                서비스 탈퇴 안내
                            </span>

                            <div className={styles.informationSub}>
                                <span className={styles.informationSubText}>아래 내용을 꼭 확인해주세요.</span>

                            </div>

                        </div>

                        <div className={styles.agreementUpperWrapper}>
                            {selectAgreeWrappers.map((wrapper, index) => (
                                <div className={styles.agreementWrapper} key={index}
                                     onClick={() => {
                                         wrapper.setAgreement(!wrapper.isAgreed);
                                         if (wrapper.isAllSelectButton) {
                                             handleTotalAgreement();
                                         }
                                     }}>
                                    <div className={styles.agreementContentWrapper}>
                                        <div
                                            className={`${styles.agreeCheckImg} ${wrapper.isAgreed ? styles.selected : ''}`}/>
                                        <div className={styles.agreementTextWrapper}>
                                        <span
                                            className={`${styles.agreementText} ${wrapper.isAllSelectButton ? styles.all : ''}`}>
                                            {wrapper.content}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!step5Valid && (
                                <div className={styles.errorMessageWrapper}>
                                    <span className={styles.errorMessage}>
                                        모든 항목을 확인해 주세요.
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className={styles.nextCancelButtonWrapper}>
                            <button className={styles.cancelButton} onClick={handleFirstStep}>
                                <span className={styles.buttonText}>취소</span>
                            </button>

                            <button className={styles.nextButton} onClick={() => handleNextStep(ExitStatus.STEP6)}>
                                <span className={styles.buttonText}>다음</span>
                            </button>
                        </div>

                    </div>
                )}


                {step === ExitStatus.STEP6 && (
                    <div className={styles.exitModalStyle}>
                        <div className={styles.informationWrapper}>
                            <span className={styles.informationTitle}>
                                이용중 불편함이 있었나요?
                            </span>

                            <div className={styles.informationSub}>
                                <span className={styles.informationSubText}>탈퇴 사유를 선택해주세요.</span>
                            </div>

                        </div>

                        <div className={styles.radioUpperWrapper}>
                            {reasonRadioWrappers.map((wrapper, index) => (
                                <div className={styles.radioWrapper} key={index}
                                     onClick={() => setRadioOption(wrapper.option)}>
                                    <div className={styles.radioContentWrapper}>
                                        <input
                                            type="radio"
                                            id={`radio${index}`} // 고유한 id를 위해 index 사용
                                            name="agree"
                                            value={wrapper.option}
                                            checked={wrapper.option === radioOption}
                                            className={styles.radioButton}
                                            readOnly
                                        />
                                        <label htmlFor={`radio${index}`}
                                               className={wrapper.isEtcStatus ? styles.selected : ''}></label>
                                        <div className={styles.agreementTextWrapper}>
              <span className={`${styles.agreementText} ${wrapper.isEtcStatus ? styles.all : ''}`}>
                {wrapper.content}
              </span>
                                        </div>
                                    </div>
                                    {wrapper.option === ExitReason.ETC && radioOption === ExitReason.ETC && (
                                        <div className={styles.inputWrapper}>

                                            <input
                                                type="radioTextReason"
                                                className={styles.contentInput}
                                                value={radioTextReason}
                                                name="radioTextReason"
                                                onChange={(e => setRadioTextReason(e.target.value))}
                                                placeholder={"탈퇴 사유를 입력해주세요."}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                            {!step6Valid && (
                                <div className={styles.errorMessageWrapper}>
                                    <span className={styles.errorMessage}>
                                        탈퇴 사유를 선택해 주세요.
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className={styles.nextCancelButtonWrapper}>
                            <button className={styles.cancelButton} onClick={handleFirstStep}>
                                <span className={styles.buttonText}>취소</span>
                            </button>

                            <button
                                className={`${styles.exitButton} ${radioOption === ExitReason.NONE ? '' : styles.selected}`}
                                onClick={() => handleNextStep(ExitStatus.STEP6)}>
                                <span
                                    className={`${styles.buttonText} ${radioOption === ExitReason.NONE ? '' : styles.selected}`}>탈퇴 완료</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}