import {MainApi} from "./MainApi";
import {CreateCartParam} from "../types/cart";

export class CartApi {

  static baseUrl = `${MainApi.urlPrefix}/user/carts`;

  static createCart = (cartParams: CreateCartParam[]) => () =>
    MainApi.api.post(`${CartApi.baseUrl}`, cartParams);

  static getCarts = (cartIdxes: number[]) => () =>
      MainApi.api.get(`${CartApi.baseUrl}?cart-idxes=${cartIdxes}`)
}