import { useFooter } from "../../../hooks/hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FloatingBtn } from "../../common/button/FloatingBtn";
import style from "./review.module.scss";
import { LightTextStyle } from "../../common/StyledCommonComponents";
import { ReviewSection1 } from "./ReviewSection1";
import { ReviewSection2 } from "./ReviewSection2";
import { ReviewSection3 } from "./ReviewSection3";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { useReviewsGetter } from "./hooks/useReviewsGetter";
import { Trans, useTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocationHistory } from "../../../hooks/location/useLocationHistory";

export function ReviewMain() {
  const { t } = useTranslation(["subRealReview"]);
  const { setShowFooter } = useFooter();
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const reviewListRef = useRef<HTMLElement>(null);
  const [showMainTitle, setShowMainTitle] = useState(false);
  useShowOrHide({ refCurrent: mainTitleRef.current, setShow: setShowMainTitle });
  const { getReviews } = useReviewsGetter();
  const [pageNum, setPageNum] = useState<number | null>(null);

  const getPageNumFromUrl = useCallback(() => {
    const { page } = queryString.parse(window.location.search);
    if (page) {
      return Number(page as string);
    } else {
      return 0;
    }
  }, []);
  const getPage = useCallback(() => {
    const nextPage = getPageNumFromUrl();
    if (!Number.isNaN(pageNum) && !Number.isNaN(nextPage) && pageNum !== nextPage) {
      setPageNum(nextPage);
      reviewListRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line
  }, [getPageNumFromUrl, pageNum, setPageNum, reviewListRef.current]);

  useLocationHistory({
    callbackOnBack: () => getPage(),
    callbackOnNext: () => getPage(),
  });

  useEffect(() => {
    if (pageNum !== null) {
      getReviews({
        page: pageNum,
      }).then();
    }
    // eslint-disable-next-line
  }, [pageNum]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowFooter(true);
    document.title = `리얼 후기 | ${process.env.REACT_APP_TITLE}`;

    setPageNum(getPageNumFromUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPageNumFromUrl]);

  return (
    <main className={style.main}>
      <FloatingBtn />

      <div className={style.titleWrapper}>
        <div className={style.titleEffect}></div>
        <div className={style.title}>
          <h2 className={`${style.text} ${showMainTitle ? style.show : style.hide}`} ref={mainTitleRef}>
            <Trans t={t} i18nKey={"title"} components={[<LightTextStyle></LightTextStyle>]} />
          </h2>
        </div>
      </div>
      <ReviewSection1 />
      <ReviewSection2 />
      <ReviewSection3 reviewListRef={reviewListRef} />
    </main>
  );
}
