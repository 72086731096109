import queryString from "query-string";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useAuth, useModal} from "../../../hooks/hooks";

export function SignInFromKPass() {
  const { accessToken, refreshToken } = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const { onLoggedIn } = useAuth();
  const {openAlertModal} = useModal()


  useEffect(() => {
    if (typeof accessToken != "string" || typeof refreshToken != "string") {
      openAlertModal({
        title: '안내',
        desc:'일시적인 문제가 발생했습니다.\n다시 시도해주세요.'
      })
      navigate("/");
    } else {
      onLoggedIn(accessToken, refreshToken);
      navigate("/purchase-voucher");
    }
    // eslint-disable-next-line
  }, [navigate, onLoggedIn]);

  return <></>;
}
