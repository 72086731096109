import { ArticleTitle } from "./ArticleTitle";
import style from "../../assets/scss/sub/understandingHistory.module.scss";
import historyPic from "../../assets/images/sub/understanding/history/history_pic.png";
import examplePic from "../../assets/images/sub/understanding/history/example_pic.png";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage, useUserAgent } from "../../hooks/hooks";

export function UnderstandingHistory() {
  const { t } = useTranslation(["subUnderstandingHistory"]);
  const { isDesktop } = useUserAgent();
  const { isTablet } = useUserAgent();
  const { isNotKorean } = useLanguage();

  return (
    <section className={style.history}>
      <article className={style.article1}>
        {isDesktop ? <></> : <div className={style.bottomWave}></div>}
        <h3 className="blind">{t("title")}</h3>
        <h4>
          <ArticleTitle title={t("articleTitle")} className={`${style.title} ${isNotKorean ? style.ru : ""}`} />
        </h4>
        <p className={style.desc1}>
          <Trans t={t} i18nKey={"articleDesc1"} components={[<span className={style.accent} />]} />
        </p>
        <div className={style.chart}>
          <img src={historyPic} alt={t("historyPicAlt")} />
        </div>
        <p className={style.desc2}>{t("desc2text1")}</p>
      </article>
      <article className={style.article2}>
        {isTablet ? (
        <div className={style.articleArea}>
          <p className={style.desc}>
            <Trans t={t} i18nKey={"article2text1"} components={[<span className={style.accent} />]} />
          </p>
          <div className={style.image}>
            <img src={examplePic} alt={t("examplePictureAlt")} />
          </div>
        </div>
        ) : (
            <>
              <p className={style.desc}>
                <Trans t={t} i18nKey={"article2text1"} components={[<span className={style.accent} />]} />
              </p>
              <div className={style.image}>
                <img src={examplePic} alt={t("examplePictureAlt")} />
              </div>
            </>
        )}
      </article>
    </section>
  );
}
