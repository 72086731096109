import style from "./pagination.module.scss";
import { DirectionButton } from "../button/DirectionButton";
import { PageResponse } from "../../../types/page";

interface Props {
  page: PageResponse<any>;
  goSpecificPage: (pageNum: number) => void;
}
export function Pagination({ page, goSpecificPage }: Props) {
  const pageNumberCount = 5;
  const currentPage = page.number + 1;
  const pageNumberQuotient = Math.floor(page.number / pageNumberCount);
  const pageNumberStart = pageNumberQuotient * pageNumberCount + 1;
  const totalPageQuotient = Math.floor((page.totalPages - 1) / pageNumberCount);

  const hasPrevPageNumbers = pageNumberQuotient > 0;
  const hasNextPageNumbers = totalPageQuotient > pageNumberQuotient;

  const getPageNumberNodes = (pageNumberStart: number, currentPage: number) => {
    return Array(pageNumberCount)
      .fill(undefined)
      .map((_, idx) => {
        const thisPageNumber = pageNumberStart + idx;
        if (thisPageNumber > page.totalPages) {
          return null;
        } else {
          return (
            <li key={`pageNum-${idx}`} className={style.paginationItem}>
              <button
                className={`${style.paginationButton} ${currentPage === thisPageNumber ? style.selected : ""}`}
                onClick={() => {
                  goSpecificPage(thisPageNumber - 1);
                }}
              >
                {thisPageNumber}
              </button>
            </li>
          );
        }
      });
  };

  return (
    <div className={style.pagination}>
      {hasPrevPageNumbers && (
        <DirectionButton
          direction={"prev"}
          width={50}
          onClickFunc={() => goSpecificPage(pageNumberQuotient * pageNumberCount - 1)}
        />
      )}
      <ul className={style.paginationList}>{getPageNumberNodes(pageNumberStart, currentPage)}</ul>
      {hasNextPageNumbers && (
        <DirectionButton
          direction={"next"}
          width={50}
          onClickFunc={() => goSpecificPage((pageNumberQuotient + 1) * pageNumberCount)}
        />
      )}
    </div>
  );
}
