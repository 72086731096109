import style from "../../../../assets/scss/common/modal/leaveModal.module.scss";
import {useEffect} from "react";
import {LeaveReason} from "../../../../types/user";

interface Args{
    radioOption: LeaveReason;
    setRadioOption: (_: LeaveReason) => void
    radioTextReason: string;
    setRadioTextReason: (_: string) => void
    errorMessage: string;
    setErrorMessage: (_:string) =>void;
}

export function LeaveReasonField(
    {radioOption, setRadioOption, radioTextReason, setRadioTextReason,
    errorMessage, setErrorMessage}: Args) {
    useEffect(() => {
        setRadioTextReason('')
        setErrorMessage('')
        // eslint-disable-next-line
    }, [radioOption]);

    const reasonRadioWrappers = [
        {
            content: '기록 삭제 목적',
            option: LeaveReason.REMOVE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '서비스 이용 불편',
            option: LeaveReason.INCONVENIENT,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '다른 사이트 이용',
            option: LeaveReason.USE_OTHER_SITE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '사용빈도가 낮음',
            option: LeaveReason.LOW_USE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '프로그램 용량이 큼',
            option: LeaveReason.HUGE_PROGRAM,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '프로그램 오류가 많음',
            option: LeaveReason.MANY_ERRORS,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '콘텐츠 불만',
            option: LeaveReason.CONTENTS,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '개인정보 유출 우려',
            option: LeaveReason.PERSONAL_INFORMATION,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '고객응대 불만',
            option: LeaveReason.SERVICE,
            setRadioOption: setRadioOption,
            isEtcStatus: false,
        },
        {
            content: '기타(직접 입력)',
            option: LeaveReason.ETC,
            setRadioOption: setRadioOption,
            isEtcStatus: true,
        },
    ];


    return (
        <>
            <div className={style.leaveAgreementWrapper}>
                <div className={style.leaveAgreementContentWrapper}>
                    {reasonRadioWrappers.map((wrapper, index) => (
                        <>
                            <div className={style.agreementContent} key={index}
                                 onClick={() => {
                                     wrapper.setRadioOption(wrapper.option);
                                 }}>
                                <input
                                    type="radio"
                                    id={`radio${index}`} // 고유한 id를 위해 index 사용
                                    name="agree"
                                    value={wrapper.option}
                                    checked={wrapper.option === radioOption}
                                    className={style.radioButton}
                                    readOnly
                                />
                                <div className={style.agreementTextWrapper}>
                                        <span className={style.agreementText}>
                                            {wrapper.content}
                                        </span>
                                </div>
                            </div>
                            {wrapper.option === LeaveReason.ETC && radioOption === LeaveReason.ETC && (
                                <div className={style.inputWrapper}>
                                    <input
                                        type="radioTextReason"
                                        className={style.contentInput}
                                        value={radioTextReason}
                                        name="radioTextReason"
                                        onChange={(e => setRadioTextReason(e.target.value))}
                                        placeholder={"탈퇴 사유를 입력해주세요."}
                                    />
                                    <div className={style.inputInline}></div>
                                </div>
                            )}
                        </>
                    ))}
                    {errorMessage && (
                        <div className={style.errorMessageWrapper}>
                            <span className={style.message}>{errorMessage}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}