import style from "../../../../assets/scss/sub/purchase/paymentResult.module.scss";
import copyIcon from "../../../../assets/images/sub/purchase_voucher/pay/copy_btn.svg";
import { useCallback, useMemo } from "react";
import { PaymentOnAccountNotice } from "./PaymentOnAccountNotice";

export interface PaymentResult {
  type: "order" | "payment";
  productName: string;
  paymentWay: string;
  amount: number;
  failedReason?: string;
  vbankName: string;
  vbankAccountNumber: string;
}

interface Props {
  result: PaymentResult;
}

export function PaymentDetail({ result }: Props) {
  const accountNumber = useMemo(() => `${result.vbankName} ${result.vbankAccountNumber}`, [result]);

  const onClickCopyAccountInfo = useCallback(async () => {
    const copyTarget = accountNumber.replace(/-/g, "");
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(copyTarget).then(() => alert("복사 되었습니다"));
    } else {
      return document.execCommand("copy", true, copyTarget);
    }
  }, [accountNumber]);

  const successTypeName = useMemo(() => {
    if (result.type === "order") return "주문";
    else return "결제";
  }, [result.type]);

  return (
    <div className={style.payDetail}>
      <dl className={style.infoTable}>
        <dt>{successTypeName} 상품</dt>
        <dd>{result.productName}</dd>
        <dt>{successTypeName} 수단</dt>
        <dd>{result.paymentWay}</dd>
        {result.type === "order" ? (
          <>
            <dt className={style.accent}>주문 금액</dt>
            <dd>{result.amount.toLocaleString("ko-KR")}원</dd>
            <dt className={style.accent}>입금 계좌</dt>
            <dd className={style.accountInfo}>
              {accountNumber}
              <button className={style.copyBtn} onClick={onClickCopyAccountInfo} title="복사하기">
                <img src={copyIcon} alt={"복사 아이콘 이미지"} />
              </button>
            </dd>
          </>
        ) : (
          <>
            <dt>결제 금액</dt>
            <dd>{result.amount.toLocaleString("ko-KR")}원</dd>
          </>
        )}
        {result.failedReason ? (
          <>
            <dt>실패 사유</dt>
            <dd>{result.failedReason}</dd>
          </>
        ) : (
          <></>
        )}
      </dl>
      {result.type === "order" ? (
        <>
          <div className={style.dashDivider}></div>
          <div className={style.notice}>
            <PaymentOnAccountNotice />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
//
