import { useAppDispatch, useModal } from "../../../../../hooks/hooks";
import { useCallback } from "react";
import { postEventTesterAsync } from "../../../../../store/event/eventSlice";
import { EventTesterPostParam } from "../../../../../types/event";

export function useEventRegisterer() {
  const dispatch = useAppDispatch();
  const { openAlertModal } = useModal();

  const registerEventTester = useCallback(
    async (param: EventTesterPostParam) => {
      try {
        await dispatch(postEventTesterAsync(param)).unwrap();
        openAlertModal({
          title: "신청 완료",
          desc: "이벤트 신청이 완료되었습니다.\n문자를 받지 못한 경우 고객센터로 문의해 주세요.",
        });
      } catch (e) {
        openAlertModal(null);
      }
    },
    [dispatch, openAlertModal]
  );

  return {
    registerEventTester,
  };
}
