import { useAppSelector } from "../../../../../hooks/hooks";
import {
  agreementState,
  descriptionState,
  selectedProfileIdxState,
  setValidationErrors,
  titleState,
} from "../../../../../store/review/reviewModalSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export enum ReviewValidationError {
  PROFILE,
  TITLE,
  DESCRIPTION,
  AGREEMENT,
}

export function useReviewValidator() {
  const selectedProfileIdx = useAppSelector(selectedProfileIdxState);
  const title = useAppSelector(titleState);
  const description = useAppSelector(descriptionState);
  const agreement = useAppSelector(agreementState);
  const dispatch = useDispatch();

  const validate = useCallback((): ReviewValidationError[] => {
    const validationErrors: ReviewValidationError[] = [];
    if (selectedProfileIdx === undefined) {
      validationErrors.push(ReviewValidationError.PROFILE);
    }
    if (title.length === 0) {
      validationErrors.push(ReviewValidationError.TITLE);
    }
    if (description.length === 0) {
      validationErrors.push(ReviewValidationError.DESCRIPTION);
    }
    if (!agreement) {
      validationErrors.push(ReviewValidationError.AGREEMENT);
    }

    dispatch(setValidationErrors(validationErrors));
    return validationErrors;
  }, [selectedProfileIdx, title, description, agreement, dispatch]);

  return {
    validate,
  };
}
