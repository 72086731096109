import style from "../../assets/scss/main_legacy/intro.module.scss";
import scrollIcon from "../../assets/images/main_legacy/intro/scroll.gif";
import { useContext, useEffect, useRef } from "react";
import { MainLegacyScrollContext } from "./hooks/MainLegacyScrollContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hooks/hooks";

export function Intro() {
  const { t } = useTranslation(["mainIntro"]);
  const [isShown, setIsShown] = useState(false);
  const { scrollY, setScrollingSection, showOrHide } = useContext(MainLegacyScrollContext);
  const sectionRef = useRef();
  const { canLogin } = useLanguage();

  useEffect(() => {
    showOrHide(sectionRef.current, setIsShown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    if (isShown) {
      setScrollingSection(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  return (
    <section className={`${style.intro} ${canLogin ? "" : style.noLoginBar}`} ref={sectionRef}>
      <h3 className="blind">{t("introHeading")}</h3>
      <div className={style.contentWrapper}>
        <div className={style.phrase1}>
          <div className={`${style.text1} ${isShown ? style.show : style.hide}`}>“ {t("smallText1")} ”</div>
          <div className={`${style.text2} ${isShown ? style.show : style.hide}`}>“ {t("smallText2")} ”</div>
        </div>
        <div className={style.phrase2}>
          <span className={`${style.text} ${isShown ? style.show : style.hide}`}>
            {t("bigText1")}
            <br />
            {t("bigText2")}
          </span>
        </div>
        <div className={style.lineAndPhrase3}>
          <div className={`${style.line} ${isShown ? style.show : style.hide}`}></div>
          <div className={style.phrase3}>
            <span className={`${style.text} ${isShown ? style.show : style.hide}`}>
              {t("really1")}
              <br />
              {t("really2")}
            </span>
          </div>
        </div>
      </div>
      <div className={`${style.scrollIcon} ${isShown ? style.show : style.hide}`}>
        <img src={scrollIcon} alt={t("scrollIcon")} />
      </div>
    </section>
  );
}
