import style from "./koreanKpassStandardization.module.scss";
import { StandardizationCoverKO } from "./cover/StandardizationCoverKO";
import { StandardizationHeaderKO } from "./StandardizationHeaderKO";
import React, { useEffect, useState } from "react";
import { TheoreticalBackgroundKO } from "./page/TheoreticalBackgroundKO";
import { UtilizationAndApplicationKO } from "./page/UtilizationAndApplicationKO";
import { StandardizationKO } from "./page/StandardizationKO";
import { ReliabilityAndValidityKO } from "./page/ReliabilityAndValidityKO";
import { TopButton } from "../common/button/TopButton";
import { useScrollingEvent } from "../../hooks/hooks";
import { StandardizationFooterKO } from "./StandardizationFooterKO";
import { ArticleHeaderKO } from "./page/ArticleHeaderKO";
import { ArticleFooterKO } from "./page/ArticleFooterKO";
import { References } from "./page/References";

export function KoreanKpassStandardization() {
  const { scrollY } = useScrollingEvent();
  const [isMetaTagChanged, setIsMetaTagChanged] = useState(false);

  useEffect(() => {
    const viewportMetaTag = document.querySelector("[name=viewport]");
    const prevValue = viewportMetaTag?.getAttribute("content");

    viewportMetaTag?.setAttribute("content", "width=1200px");
    setIsMetaTagChanged(true);
    document.title = "한국판 K-PASS 표준화  |  K-PASS";

    return () => {
      viewportMetaTag?.setAttribute("content", prevValue ?? "");
      setIsMetaTagChanged(false);
      document.title = process.env.REACT_APP_TITLE ?? "K-PASS";
    };
  }, []);

  return (
    <>
      {isMetaTagChanged && (
        <div className={style.wrapper}>
          <StandardizationHeaderKO />
          <main className={style.main}>
            <StandardizationCoverKO />

            <div className={style.fixedHeader}>
              <ArticleHeaderKO />
            </div>

            <div className={style.fixedFooter}>
              <ArticleFooterKO />
            </div>

            <table className={style.articleTable}>
              <thead className={style.printHeader}>
                <tr>
                  <td>
                    <div className={style.pageHeaderSpace}></div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={style.tableTd}>
                    <TheoreticalBackgroundKO />
                    <UtilizationAndApplicationKO />
                    <StandardizationKO />
                    <ReliabilityAndValidityKO />
                    <References />
                  </td>
                </tr>
              </tbody>
              <tfoot className={style.printFooter}>
                <tr>
                  <td>
                    <div className={style.pageFooterSpace}></div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </main>
          <StandardizationFooterKO />
          <TopButton scrollY={scrollY} hideBelowButton={true} additionalClassName={style.topButton} />
        </div>
      )}
    </>
  );
}
