import style from "../../../../assets/scss/sub/purchase/paymentResult.module.scss";
import friendImg from "../../../../assets/images/sub/purchase_voucher/pay/sad_friends.png";

export function CancelPayment() {
  return <>
    <div className={style.cancelled}>
      <h3 className={style.title}>결제가 취소되었습니다.</h3>
      <p className={style.description}>재결제를 원하시면 ‘재시도’ 버튼을 눌러주세요.</p>
      <div className={style.imgArea}>
        <img src={friendImg} alt={"결제 취소 배경 이미지"}/>
      </div>
    </div>
  </>
  ;
}