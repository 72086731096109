import { useCallback } from "react";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { getPhoneNumberByEmailAsync } from "../../../../../store/login/loginSlice";
import { PhoneNumberGetterResponseDTO } from "../../../../../types/auth";

export function usePhoneNumberByEmailGetter() {
  const dispatch = useAppDispatch();

  const getPhoneNumberByEmailGetter = useCallback(
    async (email: string) => {
      try {
        const result: PhoneNumberGetterResponseDTO = await dispatch(getPhoneNumberByEmailAsync(email)).unwrap();
        return result.phoneNumber;
      } catch (e) {
        throw e;
      }
    },
    [dispatch]
  );

  return {
    getPhoneNumberByEmailGetter,
  };
}
