import { MainApi } from "./MainApi";
import { PageSearchCondition } from "../types/page";
import {
  KPASSReviewDTO,
  ReviewCreateStatusDTO,
  ReviewImageDTO,
  ReviewPostDTO,
  ReviewPutDTO,
  ReviewStatusDTO,
} from "../types/review";
import { SecuredUserProfile, TesterInfo } from "../types/user";

export class ReviewApi {
  static baseUrl = `${MainApi.urlPrefix}/user`;

  static getReviews = (condition: PageSearchCondition) => () =>
    MainApi.api.get(
      `${ReviewApi.baseUrl}/kpass-reviews?status=WRITTEN&${MainApi.toParamStringFromObject({
        pageCondition: condition,
      }).substring(1)}`
    );

  static getReview = (reviewIdx: number) => () =>
    MainApi.api.get<KPASSReviewDTO>(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}`);

  static getPrevReview = (reviewIdx: number) => () =>
    MainApi.api.get<KPASSReviewDTO>(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}/next`);

  static getNextReview = (reviewIdx: number) => () =>
    MainApi.api.get<KPASSReviewDTO>(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}/previous`);

  static getReviewImages = (reviewIdx: number) => () =>
    MainApi.api.get<ReviewImageDTO[]>(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}/images`);

  static getProfileReviewStatuses = (profileIdx: number) => () =>
    MainApi.api.get<ReviewStatusDTO[]>(`${ReviewApi.baseUrl}/profile/${profileIdx}/kpass-reviews`);

  static createReview = (reviewPostDTO: ReviewPostDTO) => () =>
    MainApi.api.post(`${ReviewApi.baseUrl}/kpass-reviews`, reviewPostDTO);

  static updateReview = (reviewIdx: number, reviewPutDTO: ReviewPutDTO) => () =>
    MainApi.api.put(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}`, reviewPutDTO);

  static deleteReview = (reviewIdx: number) => () =>
    MainApi.api.delete(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}`);

  static getBestReviews = () => () => MainApi.api.get(`${ReviewApi.baseUrl}/kpass-best-reviews`);

  static getProfileByReviewIdx = (reviewIdx: number) => () =>
    MainApi.api.get<SecuredUserProfile>(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}/profile`);

  static getTesterInfoByReviewIdx = (reviewIdx: number) => () =>
    MainApi.api.get<TesterInfo>(`${ReviewApi.baseUrl}/kpass-reviews/${reviewIdx}/tester-info`);

  static getReviewCreateStatus = () => () =>
    MainApi.api.get<ReviewCreateStatusDTO>(`${ReviewApi.baseUrl}/kpass-reviews/creatable-status`);
}
