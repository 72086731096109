import style from "../koreanKpassStandardization.module.scss";
import kpassWhiteKoreanLogo from "../../../assets/images/logo/kpass_white_korean_logo.svg";
import { CoverHeaderKO } from "./CoverHeaderKO";
import { CoverFooterKO } from "./CoverFooterKO";

export function StandardizationCoverKO() {
  return (
    <article className={`${style.article} ${style.cover}`}>
      <div className={style.bgItemTopRight}></div>
      <div className={style.bgItemLeftBottom}></div>
      <CoverHeaderKO />
      <section className={style.coverMain}>
        <img className={style.coverLogo} src={kpassWhiteKoreanLogo} alt={"K-PASS 한국어 로고"} />
        <h1 className={style.coverTitle}>
          한국판 K-PASS
          <br /> 표준화
        </h1>
        <dl className={style.writer}>
          <dt>저자</dt>
          <dd>문수백 Ph.D 연구 (주)필굿 한국 심리검사 표준화 연구소 소장</dd>
        </dl>
      </section>
      <CoverFooterKO />
    </article>
  );
}
