import style from "../koreanKpassStandardization.module.scss";
import feelgoodWhiteLogo from "../../../assets/images/logo/feelgood_white_logo.svg";

export function CoverFooterKO() {
  return (
    <section className={style.articleFooter}>
      <span>Copyright 2023. FEELGOOD. All rights reserved.</span>
      <img className={style.logo} src={feelgoodWhiteLogo} alt={"FeelGood White Logo"} />
    </section>
  );
}
