import { AddedFile } from "../file";
import { Sort } from "../page";

export enum BannerType {
  KOREAN = "KOREAN",
  ENGLISH = "ENGLISH",
  RUSSIA = "RUSSIAN",
  TAIWAN = "TAIWAN",
}

export enum BannerLocation {
  MAIN = "MAIN",
}

export enum LinkOption {
  NEW_TAB = "NEW_TAB",
  CURRENT_TAB = "CURRENT_TAB",
}
export enum BannerStatus {
  UNEXPOSED = "UNEXPOSED",
  EXPOSED = "EXPOSED",
}

export enum WebsiteType {
    DCAS = "DCAS",
    KPASS = "KPASS",
}

export interface BannerDTO {
    idx: number;
    name: string;
    createdAt: string;
    type: BannerType;
    websiteType: WebsiteType;
    bannerLocation: BannerLocation;
    image: AddedFile;
    imageLink: string;
    description1: string;
    description2: string;
    buttonDescription: string;
    buttonLink: string;
    linkOption: LinkOption;
    availableStartAt: string;
    availableEndAt?: string;
    status: BannerStatus;
}

export interface BannerVO {
  idx: number;
  name: string;
  image: AddedFile;
  imageLink: string;
  description1: string;
  description2: string;
  buttonDescription: string;
  buttonLink: string;
  linkOption: LinkOption;
}

interface PageSearchTypeCondition {
    page?: number;
    size?: number;
    type?: BannerType;
    websiteType?: WebsiteType;
    sort?: Sort[] | null;
}

export type { PageSearchTypeCondition };
