import styles from "../../../../../assets/scss/common/modal/loginModal.module.scss";
import React, {useEffect, useState} from "react";
import {SignupStatus} from "../../SignupModal";
import {Address, Gender} from "../../../../../types/user";
import {SignPostDTO} from "../../../../../types/signup";
import {useSignupValidator, ValidateStatus} from "./hooks/useSignupValidator";
import {useSignupMember} from "./hooks/useSignupMember";
import {useModal, useUserAgent} from "../../../../../hooks/hooks";

interface Props {
    loginStep: SignupStatus;
    changeSignupStep: (_: SignupStatus) => void;

    isAgreementPersonalInfoCollectionAndUsage: boolean;
    isAgreementEmailMarketingReceipt: boolean;
    isAgreementSMSMarketingReceipt: boolean;
    setIsAgreementPersonalInfoCollectionAndUsage: (_: boolean) => void;
    setIsAgreementEmailMarketingReceipt: (_: boolean) => void;
    setIsAgreementSMSMarketingReceipt: (_: boolean) => void;

    email: string;
    name: string;
    phoneCode: string;
    phoneNumber: string;
    birthday: string;
    gender: Gender;
    address: Address;
    code: string;
    nationality: string;
}

export function SignupInfoStep3({
    loginStep,
    changeSignupStep,
    isAgreementPersonalInfoCollectionAndUsage,
    isAgreementEmailMarketingReceipt,
    isAgreementSMSMarketingReceipt,
    setIsAgreementPersonalInfoCollectionAndUsage,
    setIsAgreementEmailMarketingReceipt,
    setIsAgreementSMSMarketingReceipt,
    email,name,phoneCode,phoneNumber,birthday,gender,address,code,nationality
                                }: Props) {

    const [isHovered, setIsHovered] = useState(false);
    const {openAlertModal}= useModal();
    const {isDesktop} = useUserAgent();

    const [isAgreementAge, setAgreementAge] = useState(false);
    const [isAgreementTerms, setAgreementTerms] = useState(false);
    const [isAgreementPrivacy, setAgreementPrivacy] = useState(false);
    const [isTotalSelected, setIsTotalSelected] = useState(false);
    const {validate, mandatoryAgreementValidate, setMandatoryAgreementValidate} =
        useSignupValidator({
        loginStep, isAgreementAge: isAgreementAge, isAgreementTerms:isAgreementTerms, isAgreementPrivacy:isAgreementPrivacy
    });

    const TermsAndPrivacyLinks = [
        'https://service.feel-good.io/terms?terms_category=TERMS_OF_SERVICE',
        'https://service.feel-good.io/terms?terms_category=PRIVACY_POLICY'
    ];

    const {signups} = useSignupMember();

    function convertToLocalDateFormat(dateString: string) {
        // "18852211" -> "1885-22-11"
        if (dateString.length !== 8) {
            throw new Error("Invalid date string format");
        }
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        return `${year}-${month}-${day}`;
    }

    // 회원가입 요청
    const onClickNextStep = () => {
        const result = validate();

        if (result) {
            const signupProperties : SignPostDTO ={
                email: email,
                codePhone: phoneCode,
                phoneNumber: phoneNumber,
                birthday: convertToLocalDateFormat(birthday),
                name: name,
                gender: gender,
                address: address,
                code: code,
                nationality: nationality,
                isAgreementEmailMarketingReceipt: isAgreementEmailMarketingReceipt,
                isAgreementSMSMarketingReceipt: isAgreementSMSMarketingReceipt,
                isAgreementPersonalInfoCollectionAndUsage: isAgreementPersonalInfoCollectionAndUsage,
            };
            signups(signupProperties).then(
                r => {
                    changeSignupStep(SignupStatus.Sign_UP_INFO_04)
                }
            ).catch(error => {
                openAlertModal({
                    title: '안내',
                    desc: '일시적인 문제가 발생했습니다.\n다시 시도해주세요.'
                });
                console.error("에러가 발생했습니다:", error);
            });
        }
    }

    useEffect(() => {
        setIsTotalSelected(isAgreementAge && isAgreementTerms && isAgreementPrivacy &&
            isAgreementPersonalInfoCollectionAndUsage && isAgreementEmailMarketingReceipt && isAgreementSMSMarketingReceipt)
        setMandatoryAgreementValidate(ValidateStatus.NONE)
    // eslint-disable-next-line
    }, [isAgreementAge, isAgreementTerms, isAgreementPrivacy,
        isAgreementPersonalInfoCollectionAndUsage, isAgreementEmailMarketingReceipt, isAgreementSMSMarketingReceipt]);

    // 전체 선택 함수
    const handleTotalAgreement = () => {
        // 전체가 true일 경우 모두 false로 설정
        if (isAgreementAge && isAgreementTerms && isAgreementPrivacy &&
            isAgreementPersonalInfoCollectionAndUsage && isAgreementEmailMarketingReceipt && isAgreementSMSMarketingReceipt) {
            setAgreementAge(false);
            setAgreementTerms(false);
            setAgreementPrivacy(false);
            setIsAgreementPersonalInfoCollectionAndUsage(false);
            setIsAgreementEmailMarketingReceipt(false);
            setIsAgreementSMSMarketingReceipt(false);
        } else {
        // 하나라도 false일 경우 모두 true로 설정
            setAgreementAge(true);
            setAgreementTerms(true);
            setAgreementPrivacy(true);
            setIsAgreementPersonalInfoCollectionAndUsage(true);
            setIsAgreementEmailMarketingReceipt(true);
            setIsAgreementSMSMarketingReceipt(true);
        }
    }

    const selectMandatoryAgreeWrappers = [
        {
            title: '[필수]',
            subTitle: '만 14세 이상입니다.',
            isAgreed: isAgreementAge,
            setAgreement: setAgreementAge,
            info: 'age'
        },
        {
            title: '[필수]',
            subTitle: '이용약관에 동의합니다.',
            isAgreed: isAgreementTerms,
            setAgreement: setAgreementTerms,
            detail: '자세히보기',
            info: 'terms'
        },
        {
            title: '[필수]',
            subTitle: '개인정보 처리방침에 동의합니다.',
            isAgreed: isAgreementPrivacy,
            setAgreement: setAgreementPrivacy,
            detail: '자세히보기',
            info: 'privacy'
        },
    ];

    const selectAgreeWrappers = [
        {
            title: '[선택]',
            subTitle: '개인정보 수집 및 이용에 동의합니다.',
            isAgreed: isAgreementPersonalInfoCollectionAndUsage,
            setAgreement: setIsAgreementPersonalInfoCollectionAndUsage,
        },
        {
            title: '[선택]',
            subTitle: '이메일 : 이벤트 등 마케팅 수신에 동의합니다.',
            isAgreed: isAgreementEmailMarketingReceipt,
            setAgreement: setIsAgreementEmailMarketingReceipt,
        },
        {
            title: '[선택]',
            subTitle: '문자(SMS) : 이벤트 등 마케팅 수신에 동의합니다.',
            isAgreed: isAgreementSMSMarketingReceipt,
            setAgreement: setIsAgreementSMSMarketingReceipt,
        },
    ];

    const showAgreementDetail = (agreementType: string) => {
        const urlIndex = agreementType === 'terms' ? 0 : 1;
        window.open(TermsAndPrivacyLinks[urlIndex]);
    };

    return (
        <>
            <div className={styles.agreementWrapper}>

                <div className={styles.desc}>
                    <span className={styles.accent}>약관동의</span>
                    <br/>회원가입을 위해 아래 약관에 동의해 주세요.
                </div>


                {/*  전체 동의 영역  */}
                <div className={styles.agreementArea}>
                    <div className={styles.totalAgreeWrapper}
                         onClick={handleTotalAgreement}>
                        <div className={styles.totalAgreeTextWrapper}>
                            <span className={styles.totalAgreeTitle}>전체 동의</span>
                            <span className={styles.totalAgreeSubTitle}>아래 약관에 모두 동의합니다.</span>
                        </div>
                        <div
                            className={`${styles.agreeCheckImg} ${isTotalSelected ? styles.selected : ''}`}
                        ></div>
                    </div>

                    {/*  선택 동의 영역  */}
                    <div className={styles.selectAgreement}>

                        {selectMandatoryAgreeWrappers.map((wrapper, index) => (
                            <div className={styles.selectAgreeWrapper} key={index}
                                 onClick={() => wrapper.setAgreement(!wrapper.isAgreed)}>
                                <div className={styles.selectAgreeTextWrapper}>
                                        <span
                                            className={`${styles.selectAgreeTitle} ${styles.necessary}`}>{wrapper.title}</span>
                                    <span className={styles.selectAgreeSubTitle}>{wrapper.subTitle}</span>
                                    {wrapper.detail && (
                                        <button className={styles.selectMandatoryInfoWrapper}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // 동의 내역 변경 이벤트 전파 중지
                                                    showAgreementDetail(wrapper.info);
                                                }}>
                                            <span className={styles.selectMandatoryText}>
                                                {wrapper.detail}
                                            </span>
                                            <div className={styles.selectMandatoryImg}></div>

                                        </button>

                                    )}
                                </div>
                                <div
                                    className={`${styles.agreeCheckImg} ${wrapper.isAgreed ? styles.selected : ''}`}

                                ></div>
                            </div>
                        ))}

                        {selectAgreeWrappers.map((wrapper, index) => (
                            <div className={styles.selectAgreeWrapper} key={index}
                                 onClick={() => wrapper.setAgreement(!wrapper.isAgreed)}>
                                <div className={styles.selectAgreeTextWrapper}>
                                    <span className={styles.selectAgreeTitle}>{wrapper.title}</span>
                                    <span className={styles.selectAgreeSubTitle}>{wrapper.subTitle}</span>
                                </div>
                                <div className={`${styles.agreeCheckImg} ${wrapper.isAgreed ? styles.selected : ''}`}></div>
                            </div>
                        ))}
                    </div>
                </div>

                {mandatoryAgreementValidate === ValidateStatus.UNFILLED &&
                    (<span className={styles.validateText}>필수 약관에 동의해 주세요.</span>)
                }

                <div className={`${styles.buttonsWrapper} ${isDesktop ? styles.withMarginTop : ''}`}>
                    <button type="button"
                            className={`${styles.signUpNextButton} ${isHovered ? styles.signUpNextHoverButton : ''}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={onClickNextStep}
                    >
                        다음
                    </button>
                </div>
            </div>
        </>
    )
}