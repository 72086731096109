import styles from "../../../../assets/scss/common/modal/loginModal.module.scss";
import sendIcon from "../../../../assets/images/modal/login/send_icon.svg";
import { useModal, useUserAgent } from "../../../../hooks/hooks";
import { useCallback, useEffect, useState } from "react";
import { LoginStep } from "../LoginModal";
import { usePhoneNumberByEmailGetter } from "./hooks/usePhoneNumberByEmailGetter";
import { useCodeSender } from "./hooks/useCodeSender";
import { RequestCodeType } from "../../../../types/auth";
import {AuthWay} from "../../../../types/user";

interface Props {
  changeLoginStep: (_: LoginStep) => void;
  email: string;
  setPhoneNumber: (_: string) => void;
  phoneNumber: string;
  setTitle: (_: string) => void;
}

export function AuthWayChoiceStep({
    changeLoginStep, email, setPhoneNumber, phoneNumber, setTitle
}: Props) {
  const { isDesktop, isTablet } = useUserAgent();
  const { getPhoneNumberByEmailGetter } = usePhoneNumberByEmailGetter();
  const { openAlertModal } = useModal();
  const [authWay, setAuthWay] = useState(AuthWay.NONE);
  const [errorMessage, setErrorMessage] = useState("");
  const { sendCode } = useCodeSender();

  useEffect(() =>{
    setTitle("로그인")
  })

  const onClickCancelButton = useCallback(() => {
    setTitle("로그인 & 회원가입")
    changeLoginStep(LoginStep.EMAIL);
    // eslint-disable-next-line
  }, [changeLoginStep]);

  const onClickSendCodeButton = useCallback(() => {
    if (authWay === AuthWay.NONE) {
      setErrorMessage("인증 방식을 선택해주세요.");
      return;
    }

    sendCode(email, authWay === AuthWay.EMAIL ? RequestCodeType.EMAIL : RequestCodeType.PHONE_NUMBER)
      .then(() => changeLoginStep(authWay === AuthWay.EMAIL ? LoginStep.EMAIL_CODE : LoginStep.PHONE_NUMBER_CODE))
      .catch();
  }, [authWay, email, changeLoginStep, sendCode]);

  const onClickAuthWayButton = useCallback((toAuthWay: AuthWay) => {
    setAuthWay(toAuthWay);
    setErrorMessage("");
  }, []);

  useEffect(() => {
    setPhoneNumber("");
    setAuthWay(AuthWay.NONE);

    if (email) {
      getPhoneNumberByEmailGetter(email)
        .then((phoneNumber) => setPhoneNumber(phoneNumber))
        .catch((e) => {
          console.error(e);
          openAlertModal(null);
          changeLoginStep(LoginStep.EMAIL);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <div className={styles.authWayWrapper}>
      <div className={styles.desc}>
        <span className={styles.accent}>로그인 인증 방식을 선택해주세요.</span>
        <br />
        등록된 번호가 다를 경우 이메일 인증을 해주시고,
        {isDesktop || isTablet ? <br /> : " "}
        로그인 후 My Page 메뉴에서 회원 정보를 수정해 주세요.
      </div>
      <div className={styles.waysWrapper}>
        <button
          className={`${styles.aWay} ${authWay === AuthWay.PHONE ? styles.active : ""}`}
          onClick={() => onClickAuthWayButton(AuthWay.PHONE)}
        >
          <div className={styles.checkbox}></div>
          <span className={styles.label}>
            <span>휴대폰 인증&nbsp;</span>
            <span className={styles.light}>({phoneNumber})</span>
          </span>
        </button>
        <button
          className={`${styles.aWay} ${authWay === AuthWay.EMAIL ? styles.active : ""}`}
          onClick={() => onClickAuthWayButton(AuthWay.EMAIL)}
        >
          <div className={styles.checkbox}></div>
          <span className={styles.label}>
            <span>이메일 인증&nbsp;</span>
            <span className={styles.light}>({email})</span>
          </span>
        </button>
        {errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : <></>}
      </div>

      <div className={styles.buttonsWrapper}>
        <button className={styles.outlineButton} onClick={onClickCancelButton}>
          취소
        </button>
        <button className={styles.primaryButton} onClick={onClickSendCodeButton}>
          인증코드 보내기
          <img className={styles.icon} src={sendIcon} alt={"보내기"} />
        </button>
      </div>
    </div>
  );
}
