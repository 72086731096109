import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SliderDirection, useSlider } from "./useSlider";
import { SlideGap } from "./slider";
import style from "./slider.module.scss";
import { useUserAgent } from "../../../hooks/hooks";
import { SliderBullets } from "./SliderBullets";
import { useBestReviewsGetter } from "../../../hooks/review/useBestReviewsGetter";
import { BestReviewPreview } from "../../main/article4/review/BestReviewPreview";
import { useBestReviewTheme } from "../../main/article4/review/useBestReviewTheme";
import { ASlideOfReview } from "./ASlideOfReview";

interface Props {
  slideWidth: number;
  slideHeight: number;
  delay: number;
  slideGap: SlideGap;
  slideClassName: {
    currentSlide: string;
    notCurrentSlide?: string;
    show: string;
    hide: string;
    wrapper: string;
  };
  hasBulletIndicator: boolean;
  isSubpageSlider: boolean;
  onClickSlide: (reviewIdx: number) => void;
  isArticleSlider: boolean;
}
export function ReviewSlider({
  slideWidth,
  slideHeight,
  delay,
  slideGap,
  slideClassName,
  hasBulletIndicator,
  isSubpageSlider,
  onClickSlide,
  isArticleSlider,
}: Props) {
  const { isMobile } = useUserAgent();
  const { bestReviews } = useBestReviewsGetter();
  const theme = useBestReviewTheme();
  const bestReviewSlides: JSX.Element[] = useMemo(() => {
    if (bestReviews) {
      return bestReviews.map((review, idx) => (
        <BestReviewPreview
          theme={theme[idx % theme.length]}
          bestReview={review}
          onClickReview={() => onClickSlide(review.idx)}
        />
      ));
    } else {
      return [<></>];
    }
  }, [bestReviews, theme, onClickSlide]);
  const [reviewCurrentSlideIdx, setReviewCurrentSlideIdx] = useState(0);
  const [moveNextAdditionalFunc, setMoveNextAdditionalFunc] = useState<() => void>(() => () => {});
  const [movePrevAdditionalFunc, setMovePrevAdditionalFunc] = useState<() => void>(() => () => {});
  const {
    moveSlide,
    currentSlideIdx,
    transitionDelay,
    translateXValue,
    getNextSlideIdx,
    getPrevSlideIdx,
    handleTouch,
    pauseAutoPlay,
    isTransitioning,
  } = useSlider({
    slides: bestReviewSlides,
    slideGap,
    slideWidth,
    delay,
    additionalFunctions: {
      moveNext: moveNextAdditionalFunc,
      movePrev: movePrevAdditionalFunc,
    },
    blockSwipeOnTransitioning: true,
  });

  useEffect(() => {
    setReviewCurrentSlideIdx(currentSlideIdx);
  }, [currentSlideIdx, setReviewCurrentSlideIdx]);

  useEffect(() => {
    setMoveNextAdditionalFunc(() => () => {
      setReviewCurrentSlideIdx(getNextSlideIdx(currentSlideIdx));
    });
  }, [getNextSlideIdx, currentSlideIdx, setReviewCurrentSlideIdx, setMoveNextAdditionalFunc]);

  useEffect(() => {
    setMovePrevAdditionalFunc(() => () => {
      setReviewCurrentSlideIdx(getPrevSlideIdx(currentSlideIdx));
    });
  }, [getPrevSlideIdx, currentSlideIdx, setReviewCurrentSlideIdx, setMovePrevAdditionalFunc]);

  const buttonXPosition = useMemo(
    () => (isMobile ?  `${slideWidth / 2 - 20 - 52}px` : "auto"),
    [isMobile, slideWidth]
  );

  const onClickDirectionButton = useCallback(
    (direction: SliderDirection) => {
      if (!isTransitioning) {
        pauseAutoPlay();
        moveSlide(direction);
      }
    },
    [isTransitioning, pauseAutoPlay, moveSlide]
  );

  return (
    <div
      style={{ width: slideGap.mainWidth }}
      className={`${style.wrapper} ${slideClassName.wrapper ?? ""} ${style.bulletsWrapper}`}
    >
      {isSubpageSlider && <div className={style.leftCover}></div>}
      <button
        data-direction={"prev"}
        className={`${style.directionButton} ${style.prev}`}
        style={{ left: buttonXPosition }}
        onClick={() => onClickDirectionButton(SliderDirection.PREV)}
      ></button>
      <div
        className={isArticleSlider ? style.content : style.slideContent}
        style={{
          width: `${slideWidth}px`,
          height: `${slideHeight}px`,
          overflow: "visible",
        }}
        onTouchStart={handleTouch.start}
        onTouchMove={handleTouch.move}
        onTouchEnd={handleTouch.end}
      >
        <div
          className={style.slides}
          style={{
            transform: `translateX(${translateXValue}px)`,
            transition: `transform ${transitionDelay}ms`,
            gap: `${slideGap.totalGap}px`,
          }}
        >
          {bestReviewSlides.map((aSlide, idx) => (
            <ASlideOfReview
              key={`best-review0-${idx}`}
              aSlide={aSlide}
              slideWidth={slideWidth}
              slideHeight={slideHeight}
              className={{
                current: slideClassName.currentSlide,
                notCurrent: slideClassName.notCurrentSlide ?? "",
                show: slideClassName.show,
              }}
              currentSlideIdx={reviewCurrentSlideIdx}
              aSlideIdx={idx}
            />
          ))}
          {bestReviewSlides.map((aSlide, idx) => (
            <ASlideOfReview
              key={`best-review1-${idx}`}
              aSlide={aSlide}
              slideWidth={slideWidth}
              slideHeight={slideHeight}
              className={{
                current: slideClassName.currentSlide,
                notCurrent: slideClassName.notCurrentSlide ?? "",
                show: slideClassName.show,
              }}
              currentSlideIdx={reviewCurrentSlideIdx}
              aSlideIdx={idx}
            />
          ))}
          {bestReviewSlides.map((aSlide, idx) => (
            <ASlideOfReview
              key={`best-review2-${idx}`}
              aSlide={aSlide}
              slideWidth={slideWidth}
              slideHeight={slideHeight}
              className={{
                current: slideClassName.currentSlide,
                notCurrent: slideClassName.notCurrentSlide ?? "",
                show: slideClassName.show,
              }}
              currentSlideIdx={reviewCurrentSlideIdx}
              aSlideIdx={idx}
            />
          ))}
        </div>
      </div>
      <button
        data-direction={"next"}
        className={`${style.directionButton} ${style.next}`}
        style={{ right: buttonXPosition }}
        onClick={() => onClickDirectionButton(SliderDirection.NEXT)}
      ></button>
      {isMobile && hasBulletIndicator && (
        <SliderBullets
          slidersLength={bestReviewSlides.length}
          currentSlideIdx={currentSlideIdx}
          className={style.bottomNegative56}
        />
      )}
    </div>
  );
}
