import { useAppDispatch } from "../hooks";
import { useCallback } from "react";
import { getPopupsAsync } from "../../store/popup/popupSlice";
import { PopUpDTO, PopupVO, WebsiteType } from "../../types/popup";
import { PageResponse } from "../../types/page";
import { useLanguage } from "../hooks";

export function usePopupsGetter() {
  const dispatch = useAppDispatch();
  const { language } = useLanguage();

  const getPopups = useCallback(async (): Promise<PopupVO[]> => {
    try {
      const result: PageResponse<PopUpDTO> = await dispatch(
          getPopupsAsync({
            size: 1000,
            page: 0,
            language: language,
            websiteType: WebsiteType.KPASS
          })
      ).unwrap();
      return result.content.map((dto) => dto as PopupVO);
    } catch (e) {
      console.error(e);
      throw new Error("팝업 목록 조회 에러");
    }
  }, [dispatch, language]);

  return {
    getPopups,
  };
}
