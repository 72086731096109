import { AddedFile } from "./file";
import { Address } from "./user";
import { AffiliateCodeDTO, AffiliateCodeVO } from "./affiliateCode";
import { SearchCondition } from "./page";

export enum PartnerCompanyType {
  DOMESTIC = "DOMESTIC",
  OVERSEAS = "OVERSEAS",
}

export enum PartnerCompanyStatus {
  EXPOSED = "EXPOSED",
  UNEXPOSED = "UNEXPOSED",
}

export interface PartnerCompanyDTO {
  idx: number;
  createdAt: string;
  updatedAt: string;
  numericOrder: number;
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  logo: AddedFile;
  address: Address;
  webSite: string;
  affiliateCodeDTO?: AffiliateCodeDTO;
}

export interface PartnerCompanyVO {
  idx: number;
  numericOrder: number;
  name: string;
  type: PartnerCompanyType;
  logo: string;
  address: Address;
  webSite?: string;
  codeVO: AffiliateCodeVO | null;
  addressLine1?: string;
  mapLongitude?: string;
  mapLatitude?: string;
}

export interface PartnerCompanySearchCondition extends SearchCondition {
  type?: PartnerCompanyType;
  address?: string;
}
