import style from "./mainArticle3.module.scss";
import { SubtitleNumbering } from "./SubtitleNumbering";
import expertImage from "../../../assets/images/main/article3/section2/img_expert.png";
import bachelorCapIcon from "../../../assets/images/main/article3/section2/ico_bachelor_cap.svg";
import bookIcon from "../../../assets/images/main/article3/section2/ico_book.svg";
import { useMemo, useRef, useState } from "react";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { useUserAgent } from "../../../hooks/hooks";
import { Trans, useTranslation } from "react-i18next";
import { IsDesktopBr } from "../../common/IsDesktopBr";
import {IsTabletBr} from "../../common/IsTabletBr";

export function Article3Section2() {
  const { t } = useTranslation(["competitiveness"]);
  const { isDesktop } = useUserAgent();
  const historyRef = useRef<HTMLDivElement>(null);
  const standardRef = useRef<HTMLDivElement>(null);
  const [showHistory, setShowHistory] = useState(false);
  const [showStandard, setShowStandard] = useState(false);

  const showStandardArea = useMemo(
    () => (isDesktop && showHistory) || (!isDesktop && showStandard),
    [isDesktop, showHistory, showStandard]
  );

  useShowOrHide({ refCurrent: historyRef.current, setShow: setShowHistory });
  useShowOrHide({ refCurrent: standardRef.current, setShow: setShowStandard });

  return (
    <section className={style.section2}>
      <div className={style.description}>
        <div className={style.texts}>
          <SubtitleNumbering lightNumber={0} boldNumber={2} />
          <h2 className={style.title}>{t("section2.heading2")}</h2>
          <p className={style.desc}>
            <Trans
              t={t}
              i18nKey="section2.description"
              components={[<IsDesktopBr></IsDesktopBr>,
                <StrongTextStyle></StrongTextStyle>,
                <IsTabletBr></IsTabletBr>]}
            />
          </p>
        </div>
        <div className={style.pictureWrapper}>
          <div className={style.picture}> </div>
        </div>
      </div>
      <div className={style.historyWrapper}>
        <img className={style.bgItem} src={expertImage} alt={t("section2.history1.backgroundImageAlt")} />
        <div className={style.history} ref={historyRef}>
          <div className={`${style.panel} ${style.left} ${showHistory ? style.show : style.hide}`}>
            <h3 className={"blind"}>{t("section2.history1.heading3")}</h3>
            <div className={style.title}>
              <img src={bachelorCapIcon} alt={t("section2.history1.capIconAlt")} />
              {t("section2.history1.title")}
            </div>
            <ul className={style.list}>
              <li>{t("section2.history1.description1")}</li>
              <li>{t("section2.history1.description2")}</li>
              <li>{t("section2.history1.description3")}</li>
              <li>{t("section2.history1.description4")}</li>
              {/*<li>{t("section2.history1.description5")}</li>*/}
              {/*<li>{t("section2.history1.description6")}</li>*/}
              <li>{t("section2.history1.description7")}</li>
              <li>{t("section2.history1.description8")}</li>
            </ul>
          </div>
          <div
            className={`${style.panel} ${style.right} ${showStandardArea ? style.show : style.hide}`}
            ref={standardRef}
          >
            <h3 className={"blind"}>{t("section2.history2.title")}</h3>
            <div className={style.title}>
              <img src={bookIcon} alt={t("section2.history2.bookIconAlt")} />
              {t("section2.history2.title")}
            </div>
            <ul className={style.list}>
              <li>
                <StrongTextStyle>{t("section2.history2.bookTitle1")}</StrongTextStyle>
                <span className={style.light}>{t("section2.history2.bookPublishYear1")}</span>
              </li>
              <li>
                <StrongTextStyle>{t("section2.history2.bookTitle2")}</StrongTextStyle>
                <span className={style.light}>{t("section2.history2.bookPublishYear2")}</span>
              </li>
              <li>
                <StrongTextStyle>{t("section2.history2.bookTitle3")}</StrongTextStyle>
                <span className={style.light}>{t("section2.history2.bookPublishYear3")}</span>
              </li>
              <li>
                <StrongTextStyle>{t("section2.history2.bookTitle4")}</StrongTextStyle>
                <span className={style.light}>{t("section2.history2.bookPublishYear4")}</span>
              </li>
              <li>
                <StrongTextStyle>{t("section2.history2.bookTitle5")}</StrongTextStyle>
                <span className={style.light}>{t("section2.history2.bookPublishYear5")}</span>
                <br />
                <span>{t("section2.history2.bookDescription5")}</span>
              </li>
              <li>
                <StrongTextStyle>{t("section2.history2.bookTitle6")}</StrongTextStyle>
                <span className={style.light}>{t("section2.history2.bookPublishYear6")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
