import { ChildrenProp } from "../../types/common";
import React, { useCallback, useEffect, useState } from "react";
import { PageContext } from "./PageContext";
import { useLocation } from "react-router-dom";
import { Transition } from "history";
import { useBlocker } from "./useBlocker";

declare global {
  interface Window {
    openUrl: any;
    dataLayer: Record<string, any>[];
    gtag: (...args: any[]) => void;
  }
}

export function PageProvider({ children }: ChildrenProp) {
  const [isMainPage, setIsMainPage] = useState(false);
  const [isWithAppPage, setIsWithAppPage] = useState(false);
  const [isPartnershipPage, setIsPartnershipPage] = useState(false);
  const [isPurchasePage, setIsPurchasePage] = useState(false);
  const [isOrderPage, setIsOrderPage] = useState(false);
  const [isPaymentResultPage, setIsPaymentResultPage] = useState(false);
  const [isDownloadTestPage, setIsDownloadTestPage] = useState(false);
  const [isPartnerCompanyPage, setIsPartnerCompanyPage] = useState(false);
  const [isMyPage, setIsMyPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.trim().split("/")[1];

    setIsMainPage(pathname === "" || pathname === "main");
    setIsPurchasePage(pathname === "purchase-voucher");
    setIsPartnershipPage(pathname === "partnership");
    setIsWithAppPage(pathname === "with-app");
    setIsOrderPage(pathname === "order");
    setIsPaymentResultPage(pathname === "payment-result");
    setIsDownloadTestPage(pathname === "download-test");
    setIsPartnerCompanyPage(pathname === "partnerlist");
    setIsMyPage(pathname === "mypage");

  }, [location.pathname]);

  const blocker = useCallback((tx: Transition) => {
    window.openUrl?.postMessage(tx.location.pathname);
  }, []);
  useBlocker(blocker, isWithAppPage);

  return (
    <PageContext.Provider
      value={{
        isMainPage,
        isWithAppPage,
        isPartnershipPage,
        isPurchasePage,
        isOrderPage,
        isPaymentResultPage,
        isDownloadTestPage,
        isPartnerCompanyPage,
        isMyPage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}
