import React, {useEffect, useState} from "react";
import style from "../../../assets/scss/common/modal/addConsultationModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import {useModal} from "../../../hooks/hooks";
import {ConsultationApplyDTO, ConsultationItem, ConsultationReservation} from "../../../types/consultation";
import {ReservationTitle} from "./consultation/ReservationTitle";
import {ReservationProfileInfo} from "./consultation/ReservationProfileInfo";
import {ReservationDateInput} from "./consultation/ReservationDateInput";
import {ReservationInquiry} from "./consultation/ReservationInquiry";
import {useConsultationReservationValidator} from "../hooks/useConsultationReservationValidator";
import {useConsultationGetter} from "../../sub/consultation/hooks/useConsultationGetter";

interface AddChildModalProps {
  isOpen: boolean;
  onRefresh: (_: void) => void
  selectedConsultation: ConsultationItem | null;
  onClose: () => void;
}

const AddConsultationModal: React.FC<AddChildModalProps> = (
    { isOpen, onClose, selectedConsultation, onRefresh }
) => {

  const { openConfirmModal, openAlertModal } = useModal();
  const [selectedDate, setSelectedDate] = useState("연도-월-일");
  const [selectedTime, setSelectedTime] = useState("");
  const [reservationIdx, setReservationIdx] = useState(0);
  const [childName, setChildName] = useState("");
  const [testInfo, setTestInfo] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [availableTimes, setAvailableTimes] = useState<ConsultationReservation[]>([]); // 잔여석 데이터

  const {applyCounseling} = useConsultationGetter();

  const {isInquiryValidate, validate} = useConsultationReservationValidator(
      {desiredTime: selectedTime, desiredDate:selectedDate, inquiry:inquiry}
  )

  useEffect(() => {
    setSelectedDate("연도-월-일")
    setSelectedTime("")
    setReservationIdx(0)
    setChildName("")
    setTestInfo("0")
    setInquiry("")
    setAvailableTimes([])
  }, [isOpen]);

  useEffect(() => {
    if (selectedConsultation) {
      const { userName, testRound, unitVoucherType } = selectedConsultation;

      if (userName && testRound && unitVoucherType) {
        setChildName(userName);
        setTestInfo(`${unitVoucherType} ${testRound}회차`)
      }
    }
  }, [selectedConsultation]);

  if (!isOpen) {
    return null;
  }

  const handleReservationConsultation = () =>{
    const validator = validate();
    if (!validator) return alert('상담 예약 작성폼을 다시 확인해주세요.');
    if (selectedConsultation === null) return;

    openConfirmModal(
        {
          title: "상담 신청 안내",
          desc: "상담을 신청 하시겠습니까?\n상담 신청시 상담이용권이 1매 차감됩니다.",
        },
        () => {
          const body:ConsultationApplyDTO = {
            userProfileIdx: selectedConsultation.profileIdx,
            counselingReservationIdx: reservationIdx,
            contentExecutedInfoIdx: selectedConsultation.contentExecutedInfoIdx,
            preQuestioning:inquiry
          }
          applyCounseling(body).then(() =>{
            openAlertModal({
              title: "상담 신청 안내",
              desc: "상담 신청이 완료 되었습니다.",
            })
            onClose();
            onRefresh();
          })
              .catch((error) => {
                openConfirmModal(
                    {
                      title: "상담 신청 안내",
                      desc: "상담이용권이 부족합니다.\n상담이용권 구매 페이지로 이동하시겠습니까?",
                    },
                    () => {
                      window.open(`/purchase-counselor`, "_blank", "noopener,noreferrer");
                    }
                );
              })
        }
    );
  }

  return (
      <div className={style.addChildModalOverlay}>
        <div className={style.addChildInfoModal}>
          <div className={style.closeBtnContainer}>
            <img
                src={CloseBtn}
                alt="closeBtn"
                className={style.closeBtn}
                onClick={() => {
                  openConfirmModal(
                      {
                        title: "상담 신청 안내",
                        desc: "해당 창을 닫을 경우\n입력한 상담 신청 정보가 모두 사라집니다.\n창을 정말 닫으시겠습니까?",
                      },
                      () => {
                        onClose();
                        setAvailableTimes([])
                        setSelectedDate("연도-월-일");
                        setSelectedTime("");
                      }
                  );
                }}
            />
          </div>
          <div className={style.innerModalContainer}>
            <ReservationTitle subText={"상담 신청시 상담이용권이 1매 차감됩니다."} />
            <ReservationProfileInfo childName={childName} testInfo={testInfo} />
            <ReservationDateInput
                selectedDate={selectedDate} setSelectedDate={setSelectedDate}
                selectedTime={selectedTime} setSelectedTime={setSelectedTime}
                availableTimes={availableTimes} setAvailableTimes={setAvailableTimes}
                setReservationIdx={setReservationIdx}
            />
            <ReservationInquiry inquiry={inquiry} setInquiry={setInquiry} isInquiryValidate={isInquiryValidate}/>
            <button
                className={style.submitBtn}
                onClick={handleReservationConsultation}
                // disabled={!isFormValid()}
            >
              상담 신청하기
            </button>
          </div>
        </div>
      </div>
  );
};

export default AddConsultationModal;
