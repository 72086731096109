import styled from "styled-components";
import { KpassReviewInputTitleStyle } from "../common/StyledComponents";
import { InputText } from "../common/InputText";
import { setTitle, titleState, validationErrorsState } from "../../../../../../store/review/reviewModalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooks";
import { MutableRefObject, useCallback, useMemo } from "react";
import { ReviewValidationError } from "../../hooks/useReviewValidator";
import { useTranslation } from "react-i18next";
import {breakpoints} from "../../../../../../theme/theme";

const ReviewTitleStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  
  ${breakpoints.tablet} {
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
  }
`;

interface Props {
  inputRef: MutableRefObject<HTMLInputElement | null>;
}

export function Title({ inputRef }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const dispatch = useAppDispatch();
  const defaultTitle = useAppSelector(titleState);
  const onChangeTitle = useCallback((newTitle: string) => dispatch(setTitle(newTitle)), [dispatch]);
  const validationErrors = useAppSelector(validationErrorsState);
  const hasError = useMemo(() => validationErrors.some((it) => it === ReviewValidationError.TITLE), [validationErrors]);

  return (
    <ReviewTitleStyle>
      <KpassReviewInputTitleStyle>{t("titleTitle")}</KpassReviewInputTitleStyle>
      <InputText
        inputRef={inputRef}
        defaultText={defaultTitle}
        placeholder={t("titlePlaceholder")}
        maxLength={50}
        onChangeText={onChangeTitle}
        hasError={hasError}
        errorMessage={t("titleErrorMessage")}
      />
    </ReviewTitleStyle>
  );
}
