import React, { RefObject } from "react";
import { MainArticle } from "../../components/common/Header";

export enum SlidingPartPassingStatus {
  IS_BEFORE_PASSING = "IS_BEFORE_PASSING",
  IS_WAITING_PASSING = "IS_WAITING_PASSING",
  IS_PASSING = "IS_PASSING",
  IS_PASSING_COMPLETED = "IS_PASSING_COMPLETED",
  IS_AFTER_PASSING = "IS_AFTER_PASSING",
}
export interface ScrollingContextType {
  scrollingSection: MainArticle | null;
  setScrollingSection: (_: MainArticle | null) => void;
  selectedSection: MainArticle | null;
  setSelectedSection: (_: MainArticle | null) => void;
  headerHeight: number;
  setHeaderHeight: (_: number) => void;
  slidingPartPassingStatus: SlidingPartPassingStatus;
  setSlidingPartPassingStatus: (_: SlidingPartPassingStatus) => void;
  article1Section1Height: number;
  setArticle1Section1Height: (_: number) => void;
  showOrHide: (refCurrent: any, setIsShown: any) => void;
  article1Section1BottomSpace: number;
  setArticle1Section1BottomSpace: (_: number) => void;
  getArticleElement: (_: MainArticle) => HTMLElement | null;
  getArticleElementOffsetTop: (_: MainArticle) => number | null;
  article1Element: RefObject<HTMLElement>;
  article2Element: RefObject<HTMLElement>;
  article3Element: RefObject<HTMLElement>;
  article4Element: RefObject<HTMLElement>;
}
export const MainScrollingContext = React.createContext<ScrollingContextType>(null!);
