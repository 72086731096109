import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CouponApi } from "../../api/CouponApi";
import { RootState } from "../index";
import { CouponHistoryListDTO, CouponListSearchCondition } from "../../types/coupon";
import { executePromise } from "../../util/sliceUtil";

export interface CouponState extends SliceState {
  coupons?: CouponHistoryListDTO[];
  userCouponCount: number;
}

const initialState: CouponState = {
  status: Status.IDLE,
  userCouponCount: 0,
};

// mypage 보유쿠폰
export const getTotalCouponAsync = createAsyncThunk("coupon/getOrganCouponCount", () =>
  executePromise(CouponApi.getAllCoupon())
);

// mypage 이용권 내역 kpass & dcas
export const getKpassCouponAsync = createAsyncThunk("coupon/getKpassCouponCount", () =>
  executePromise(CouponApi.getKpassCoupon())
);

export const getDcasCouponAsync = createAsyncThunk("coupon/getDcasCouponCount", () =>
  executePromise(CouponApi.getDcasCoupon())
);

// mypage 누적 검사 횟수
export const getAllTestCountAsync = createAsyncThunk("coupon/allTestCount", () =>
  executePromise(CouponApi.getAllTestCount())
);

// mypage 보유쿠폰
export const getAllCouponCountAsync = createAsyncThunk("coupon/allCoupon", () =>
  executePromise(CouponApi.getAllCouponCount())
);

export const getUserCouponCountAsync = createAsyncThunk("coupon/getUserCouponCount", () =>
  executePromise(CouponApi.getAllCouponCount())
);

export const getMyCouponListAsync = createAsyncThunk("coupon/getMyCouponList", (condition: CouponListSearchCondition) =>
  executePromise(CouponApi.getMyCouponList(condition))
);

export const getAllCouponAsync = createAsyncThunk("coupon/getCouponHistory", (index: number) =>
  executePromise(CouponApi.getAllCouponList(index))
);

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCoupons: (state, action: PayloadAction<CouponHistoryListDTO[]>) => {
      state.coupons = action.payload;
    },
    setUserCouponCount: (state, action: PayloadAction<number>) => {
      state.userCouponCount = action.payload;
    },
  },
});

export const { setCoupons, setUserCouponCount } = couponSlice.actions;

export const couponStatus = (state: RootState) => state.coupon.status;
export const couponList = (state: RootState) => state.coupon.coupons;
export const userCouponCountState = (state: RootState) => state.coupon.userCouponCount;

export default couponSlice.reducer;
