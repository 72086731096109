import { BrainType } from "./review";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NONE = "NONE",
}

export enum TestResultFormType {
  /* 각각 정식, 약식, 추가 결과지 */
  FORMAL = "FORMAL",
  SUMMARY = "SUMMARY",
  ADDITIONAL = "ADDITIONAL",
}

interface Address {
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state?: string;
  country: string;
  postalCode?: string;
}

interface UserDetail {
  idx: number;
  email: string;
  name: string;
  birthday: string;
  gender: Gender;
  phoneNumber: string;
  codePhone: string;
  address: Address;
  isAgreementEmailMarketingReceipt: boolean;
  isAgreementSMSMarketingReceipt: boolean;
  isAgreementPersonalInfoCollectionAndUsage: boolean;
  nationality: string;
  profiles: UserProfile[];
}

interface UserProfile {
  totalElements: number;
  gender: any;
  address: any;
  totalPages?: number;
  content?: Array<string>;
  userCode: any;
  birthday: any;
  idx: number;
  userIdx: number;
  currentTestType: string;
  name: string;
  isDeleted: boolean;
  isOwnerProfile: boolean;
  testCnt: number;
  createAt: string;

  kpassResultForm: TestResultFormType;
  dcasResultForm: TestResultFormType;
}

interface SecuredUserProfile {
  name: string;
  region: string;
  gender?: Gender;
  age: number;
}

interface TesterInfo {
  region: string;
  age: number;
  gender: Gender;
  brainType: BrainType;
}

interface UserResponse {
  user: UserDetail;
  userProfile: UserProfile[];
}

enum AuthWay {
  NONE = "NONE",
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

interface UserInfoUpdateDTO {
  name: string;
  birthday: string;
  gender: Gender;
  phoneNumber: string;
  codePhone: string;
  address: Address;
  nationality: string;
  isAgreementEmailMarketingReceipt: boolean;
  isAgreementSMSMarketingReceipt: boolean;
  isAgreementPersonalInfoCollectionAndUsage: boolean;
}

interface UserLeavePostDTO {
  reason: string;
}

export enum LeaveReason {
  REMOVE = "기록 삭제 목적",
  INCONVENIENT = "서비스 이용 불편",
  USE_OTHER_SITE = "다른 사이트 이용",
  LOW_USE = "사용빈도가 낮음",
  HUGE_PROGRAM = "프로그램 용량이 큼",
  MANY_ERRORS = "프로그램 오류가 많음",
  CONTENTS = "콘텐츠 불만",
  PERSONAL_INFORMATION = "개인정보 유출 우려",
  SERVICE = "고객응대 불만",
  ETC = "ETC",
  NONE = "",
}

export { AuthWay };

interface Profile {
  idx: number;
  name: string;
  birthday: string;
  createAt: string;
  userCode: string;
  testCnt: number;
  isOwnerProfile: boolean;
}

interface NewUserProfile {
  name: string;
  birthday: string;
  gender: "MALE" | "FEMALE" | string;
  address: {
    address1: string;
    address2: string;
    address3?: string;
    city: string;
    state: string;
    country: "" | "KR" | "US" | "MN" | "TW" | "VN";
    postalCode: string;
  };
}

interface userProfileIdx {
  userProfileIdx: number;
}

// 마이페이지에서 사용
interface InitialProfileInfoDTO {
  name: string;
  birthday: string;
  gender: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface TempProfileInfo {
  name: string;
  birthday: string;
  gender: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

interface ProfileErrorDTO{
  name: string;
  birthday: string;
  gender: string;
}

enum CountryFilterValue{
  NONE = "국가를 선택해주세요.",
  KR = "대한민국",
  US = "미국",
  MN = "몽골",
  TW = "대만",
  VN = "베트남"
}

type CountryName = "국가를 선택해주세요." | "대한민국" | "미국" | "몽골" | "대만" | "베트남";
type CountryCode = "" | "KR" | "US" | "MN" | "TW" | "VN";
// ㅡㅡㅡㅡㅡㅡㅡ

export type {
  Address,
  UserDetail,
  UserProfile,
  UserResponse,
  SecuredUserProfile,
  TesterInfo,
  Profile,
  NewUserProfile,
  userProfileIdx,
  UserInfoUpdateDTO,
  UserLeavePostDTO,
  InitialProfileInfoDTO,
  ProfileErrorDTO,
  TempProfileInfo,
  CountryName,
  CountryCode
};

export {
  CountryFilterValue
}
