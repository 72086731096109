import style from "../../../assets/scss/sub/purchase/orderAndPayment.module.scss";
import { SupportButton } from "./SupportButton";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AffiliateCodeVO } from "../../../types/affiliateCode";
import { useAvailableAffiliateCodeGetter } from "../../../hooks/order/useAvailableAffiliateCodeGetter";

interface Props {
  setAffiliateCode: (_: AffiliateCodeVO | null) => void;
  discountCode: string;
  setDiscountCode: (_: string) => void;
  cartIdxes: number[];
}

enum Step {
  STEP_1 = "STEP_1",
  STEP_2 = "STEP_2",
}

export function CodeDiscountPanel({ setAffiliateCode, discountCode, setDiscountCode, cartIdxes }: Props) {
  const codeDiscountTitleRef = useRef<HTMLSpanElement>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [step, setStep] = useState(Step.STEP_1);
  const { getAvailableAffiliateCode } = useAvailableAffiliateCodeGetter({
    setCodeErrorMessage: setInvalidMessage,
  });

  const onClickApplyButton = useCallback(() => {
    if (discountCode.trim()) {
      setInvalidMessage("");
      getAvailableAffiliateCode(discountCode, cartIdxes).then((result: AffiliateCodeVO | null) => {
        if (result) {
          setAffiliateCode(result);

          setStep(Step.STEP_2);
        }
      });
    } else {
      codeInputRef.current?.focus();
    }
  }, [setAffiliateCode, getAvailableAffiliateCode, discountCode, codeInputRef, cartIdxes]);

  const onClickCancelButton = useCallback(() => {
    setAffiliateCode(null);
    setDiscountCode("");
    setStep(Step.STEP_1);
  }, [setDiscountCode, setAffiliateCode]);

  const isCompleted = useMemo(() => step === Step.STEP_2, [step]);

  useEffect(() => {
    if (!discountCode) {
      setStep(Step.STEP_1);
      setInvalidMessage("");
    }
  }, [discountCode]);

  return (
    <div className={`${style.supportPanel} ${style.codePanel}`}>
      <div className={style.titleBar}>
        <h3 className={style.sectionTitle}>
          <span ref={codeDiscountTitleRef}>코드 할인</span>
          <SupportButton
            title={"코드 이용 안내"}
            content={["쿠폰과 할인 코드는 중복 적용이 불가능합니다."]}
            supportLeft={-(codeDiscountTitleRef.current?.getBoundingClientRect().width ?? 0)}
          />
        </h3>
      </div>
      <div className={style.inputArea}>
        <input
          ref={codeInputRef}
          type={"text"}
          placeholder={"코드를 입력해 주세요."}
          className={`${style.codeInput} ${discountCode ? style.hasValue : ""} ${invalidMessage ? style.invalid : ""}`}
          value={discountCode}
          disabled={isCompleted}
          onChange={(e) => {
            setDiscountCode(e.target.value.toUpperCase());
            setInvalidMessage("");
          }}
        />
        {step === Step.STEP_1 && (
          <button className={style.applyButton} onClick={onClickApplyButton}>
            적용
          </button>
        )}
        {isCompleted && (
          <button className={style.cancelButton} onClick={onClickCancelButton}>
            취소
          </button>
        )}
        {invalidMessage ? <span className={style.invalidMessage}>{invalidMessage}</span> : <></>}
        {isCompleted ? <span className={style.completedMessage}>코드 할인이 적용되었습니다.</span> : <></>}
        <div className={style.inputWrapper}></div>
      </div>
    </div>
  );
}
