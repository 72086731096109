import variables from "../assets/scss/variables.module.scss";

export const theme = {
  color: {
    mainColor: variables.mainColor,
    deepMainColor: variables.deepMainColor,
    white: variables.white,
    blueLight: variables.blueLight,
    kpBlueLight: variables.kpBlueLight,
    lightMainColor: variables.lightMainColor,
    lightHeavyMainColor: variables.lightHeavyMainColor,
    brightHeavyPurple: variables.brightHeavyPurple,
    g4c6c6c6: variables.g4c6c6c6,
    g5dedede: variables.g5dedede,
    g2757575: variables.g2757575,
    kpNavy: variables.KPNavy,
    kpNavyLight: variables.kpNavyLight,
    kpPink: variables.kpPink,
    kpPinkHeavy: variables.kpPinkHeavy,
    fontBodyText: variables.fontBodyText,
    fontLight: variables.fontLight,
    fontHeavy: variables.fontHeavy,
    fontHeavyLight: variables.fontHeavyLight,
    fontModalAccent: variables.fontModalAccent,
    fontGray: variables.fontGray,
    subNavBg: variables.subNavBg,
    subNavText: variables.subNavText,
    footerFontColor: variables.footerFontColor,
    simDescColor: variables.simDescColor,
    navyMedium: variables.navyMedium,
    navyLight: variables.navyLight,
    hotpink: variables.hotpink,
    lightGray: variables.lightGray,
    kpBlue: variables.kpBlue,
    kpDeepBlue: variables.kpDeepBlue,
    subtextColor: variables.subtextColor,
    itemBgGray: variables.itemBgGray,
    c9B: variables.c9B,
    cFFEB38: variables.cFFEB38,
  },
  zIndex: {
    content: variables.zIndexContent,
    onContent: variables.zIndexOnContent,
    zIndexBgItem: variables.zIndexBgItem,
  },
};

export const breakpoints = {
  mobile: `@media screen and (max-width: ${variables.breakpointMobile})`,
  desktop: `@media screen and (max-width: ${variables.breakpointDesktop})`,
  tablet: `@media screen and (max-width: ${parseInt(variables.breakpointDesktop.replace("px", "")) - 1 + "px"})`,
  miniMobile: `@media screen and  (max-width: ${variables.breakpointMobileGalaxyFold})`,
};
