import { useCallback, useEffect, useState } from "react";
import style from "../../assets/scss/sub/profileInfo.module.scss";
import addIcon from "../../assets/images/button/icon/addIcon.svg";
import profileIcon from "../../assets/images/mypage/profile_icon.svg";
import settingLightIcon from "../../assets/images/mypage/setting_light_icon.svg";
import settingDarkIcon from "../../assets/images/mypage/setting_dark_icon.svg";
import questionIcon from "../../assets/images/mypage/question_icon.svg";
import { useAppDispatch, useTimeConverter } from "../../hooks/hooks";
import { getProfilesAsync, getProfileOrganInfoAsync, getProfileAuthTokenAsync } from "../../store/profile/profileSlice";
import {UserProfile as ImportedUserProfile, Profile, Address} from "../../types/user";
import AffiliationInfoModal from "../common/modal/AffiliationInfoModal";
import AddChildModal from "../common/modal/AddChildModal";
import EditChildModal from "../common/modal/EditChildModal";
import OrganInfoModal from "../common/modal/OrganInfoModal";

interface ProfileInfoProps {
  setProfileIdx: (idx: number) => void;
  setProfileInfo: (profile: ExtendedProfile) => void;
  setTotalVoucherData: (profile: object) => void;

  profileInfo: ExtendedProfile | null;
}

interface OrganInfo {
  organSignInCode: string;
  organName: string;
  organPhoneNumber: string;
}

interface ExtendedProfile extends Profile {
  address: Address;
  accessToken?: string;
}

interface VoucherData {
  voucherCnt: number;
  organVoucherCnt: number;
  organKpassVoucherCnt?: number;
  organDcasVoucherCnt?: number;
}

export function ProfileInfo({ setProfileIdx, setProfileInfo, profileInfo, setTotalVoucherData }: ProfileInfoProps) {
  const dispatch = useAppDispatch();
  const { timeConverter } = useTimeConverter();
  const [profiles, setProfiles] = useState<ExtendedProfile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddChildModalOpen, setIsAddChildModalOpen] = useState(false);
  const [isEditChildModalOpen, setIsEditChildModalOpen] = useState(false);
  const [profileOrganInfo, setProfileOrganInfo] = useState<{
    [key: number]: OrganInfo;
  }>({});
  const [isOrganInfoModalOpen, setIsOrganInfoModalOpen] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [voucherData, setVoucherData] = useState<VoucherData>({
    voucherCnt: 0,
    organVoucherCnt: 0,
    organKpassVoucherCnt: 0,
    organDcasVoucherCnt: 0,
  });

  const [editChildModalData, setEditChildModalData] = useState({
    testCntExists: false,
    vouchersAvailable: false,
  });

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const toggleEditChildModal = () => {
    setIsEditChildModalOpen((prev) => !prev);
  };

  const toggleAddChildModal = () => {
    setIsAddChildModalOpen((prev) => !prev);
  };

  const toggleOrganInfoModal = () => {
    setIsOrganInfoModalOpen((prev) => !prev);
  };

  const handleEditChildClick = () => {
    const testCntExists = (profileInfo?.testCnt ?? 0) >= 1;

    const vouchersAvailable = !!(
      (voucherData.organKpassVoucherCnt || 0) > 0 || (voucherData.organDcasVoucherCnt || 0) > 0
    );

    // Modal을 열 때 추가적인 데이터를 전달
    setEditChildModalData({
      testCntExists,
      vouchersAvailable,
    });
  };

  useEffect(() => {
    if (isModalOpen || isAddChildModalOpen || isEditChildModalOpen || isOrganInfoModalOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [isModalOpen, isAddChildModalOpen, isEditChildModalOpen, isOrganInfoModalOpen]);

  const getProfileList = useCallback(async () => {
    try {
      const response = (await dispatch(getProfilesAsync()).unwrap()) as ImportedUserProfile[];

      // 기본 프로필 데이터를 생성
      const profileData: ExtendedProfile[] = response.map((profile) => ({
        idx: profile.idx,
        name: profile.name,
        birthday: profile.birthday,
        createAt: profile.createAt,
        userCode: profile.userCode,
        testCnt: profile.testCnt,
        isOwnerProfile: profile.isOwnerProfile,
        address: profile.address,
        gender: profile.gender,
        accessToken: undefined, // 초기 accessToken 값은 null로 설정
      }));

      setProfiles(profileData);

      // 각 프로필에 대해 accessToken을 가져와 추가
      const profileDataWithTokens = await Promise.all(
        profileData.map(async (profile) => {
          if (profile.idx) {
            try {
              const response: any = await getAuthToken({ userProfileIdx: profile.idx });
              const accessToken = response || null;
              return { ...profile, accessToken };
            } catch (error) {
              console.error(`Failed to fetch accessToken for profile ${profile.idx}:`, error);
              return profile; // 실패한 경우 토큰 없이 반환
            }
          } else {
            return profile; // profile.idx가 없는 경우 그냥 반환
          }
        })
      );

      // 토큰이 포함된 프로필 데이터를 설정
      setProfiles(profileDataWithTokens);

      if (profileDataWithTokens.length > 0) {
        setProfileIdx(profileDataWithTokens[0].idx);
        setProfileInfo(profileDataWithTokens[0]);

        profileDataWithTokens.forEach((profile) => {
          getProfileOrganInfo(profile.idx);
        });
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [dispatch, setProfileIdx, setProfileInfo]);

  // organSignInCode가 있는 것 중 가장 뒤에 있는 개체를 찾고, 없으면 전체 배열에서 마지막 개체 반환
  const getLastOrganWithSignInCodeOrLast = (organArray: any[]) => {
    // organSignInCode가 있는 항목만 필터링
    const filtered = organArray.filter((organ) => organ?.organSignInCode !== null);

    // 필터링한 결과 중 마지막 항목, 없으면 전체 배열의 마지막 항목 반환
    return filtered.length > 0 ? filtered[filtered.length - 1] : organArray[organArray.length - 1];
  };

  const getProfileOrganInfo = useCallback(
    async (userProfileIdx: number) => {
      try {
        const response: any = await dispatch(getProfileOrganInfoAsync(userProfileIdx)).unwrap();

        const organDTO = getLastOrganWithSignInCodeOrLast(response.organDTO);
        // response.organDTO?.[response.organDTO.length - 1];
        const organName = organDTO?.organName || "없음";
        const organSignInCode = organDTO?.organSignInCode || "없음";
        const organPhoneNumber = organDTO?.organPhoneNumber || "없음";

        setProfileOrganInfo((prevState) => ({
          ...prevState,
          [userProfileIdx]: {
            organSignInCode,
            organName,
            organPhoneNumber,
          },
        }));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const getAuthToken = useCallback(
    async (param: { userProfileIdx: number }) => {
      try {
        const response: any = await dispatch(getProfileAuthTokenAsync(param)).unwrap();

        if (response && typeof response === "object" && "accessToken" in response) {
          setAccessToken(response.accessToken);
          return response.accessToken;
        } else {
          console.warn("응답에 Access Token이 없습니다.");
        }
      } catch (error) {
        console.error("인증 토큰을 가져오는 중 오류 발생:", error);
      }
    },
    [dispatch]
  );

  const fetchVoucherData = useCallback(async () => {
    if (!accessToken) {
      console.warn("Access Token이 없습니다.");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/kpass/game-content/profile/voucher`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const response2 = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/dcas/game-content/profile/voucher`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok || !response2.ok) {
        throw new Error(`Error: ${response.status || response2.status}`);
      }

      const data = await response.json();
      const data2 = await response2.json();

      // ORGAN_MANAGER의 KPASS와 DCAS 값을 추출
      const organKpass =
        data2.voucherInformation.find(
          (voucher: any) => voucher.whose === "ORGAN_MANAGER" && voucher.unitVoucherType === "KPASS"
        )?.quantity || 0;

      const organDcas =
        data2.voucherInformation.find(
          (voucher: any) => voucher.whose === "ORGAN_MANAGER" && voucher.unitVoucherType === "DCAS"
        )?.quantity || 0;

      setVoucherData({
        voucherCnt: data.voucherCnt,
        organDcasVoucherCnt: organDcas,
        organKpassVoucherCnt: organKpass,
        organVoucherCnt: organKpass + organDcas, // ORGAN_MANAGER의 KPASS와 DCAS의 총합을 사용
      });
    } catch (error) {
      console.error("Failed to fetch voucher data:", error);
    }
  }, [accessToken]);

  const fetchAndSumVouchers = useCallback(async (profiles: ExtendedProfile[]) => {
    let totalKpass = 0;
    let totalDcas = 0;
    let kpassVoucher = 0;
    let dcasVoucher = 0;

    for (let index = 0; index < profiles.length; index++) {
      const profile = profiles[index];

      try {
        const accessToken = profile.accessToken;

        if (accessToken) {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/dcas/game-content/profile/voucher`, {
            method: "GET",
            headers: {
              Accept: "*/*",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = await response.json();

          kpassVoucher = data.voucherInformation[0].quantity;
          dcasVoucher = data.voucherInformation[1].quantity;

          // KPASS 이용권 합산
          totalKpass += data.voucherInformation.reduce((sum: number, voucher: any) => {
            if (voucher.whose === "ORGAN_MANAGER" && voucher.unitVoucherType === "KPASS") {
              return sum + voucher.quantity;
            }
            return sum;
          }, 0);

          totalDcas += data.voucherInformation.reduce((sum: number, voucher: any) => {
            if (voucher.whose === "ORGAN_MANAGER" && voucher.unitVoucherType === "DCAS") {
              return sum + voucher.quantity;
            }
            return sum;
          }, 0);
        }
      } catch (error) {
        console.error(`Failed to fetch voucher data for profile ${profile.idx}:`, error);
      }
    }

    setTotalVoucherData({
      organDcasVoucherCnt: totalDcas + dcasVoucher,
      organKpassVoucherCnt: totalKpass + kpassVoucher,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (profiles.length > 0) {
      fetchAndSumVouchers(profiles);
    }
  }, [profiles, fetchAndSumVouchers]);

  const handleProfileClick = (profile: ExtendedProfile) => {
    setProfileIdx(profile.idx);
    setProfileInfo(profile);
    getAuthToken({ userProfileIdx: profile.idx }).then(() => {
      handleEditChildClick(); // 인증 토큰을 가져온 후에 모달 데이터 설정
    });
  };

  useEffect(() => {
    getProfileList().then();
  }, [getProfileList]);

  useEffect(() => {
    if (accessToken) {
      fetchVoucherData();
    }
  }, [accessToken, fetchVoucherData]);

  // editCildModalData 갱신이 잘 안되는 부분 해결을 위한 useEffect
  useEffect(() => {
    if (profileInfo) {
      handleEditChildClick();
    }
    // eslint-disable-next-line
  }, [profileInfo, voucherData]);
  const handleProfileAdded = () => {
    getProfileList().then();
  };

  return (
    <div className={style.container}>
      <div className={style.containerContentBox}>
        <h2 className={style.containerTitle}>
          관리중인 프로필&nbsp;<span className={style.profileCount}>({profiles.length}개)</span>
        </h2>
        <button className={`${style.addProfileButton}`} onClick={toggleAddChildModal}>
          <img
            src={addIcon}
            alt="icon"
            width={26}
            height={26}
            style={{ pointerEvents: "none" }}
            className={style.addIcon}
          />
          프로필(자녀) 추가하기
        </button>
      </div>
      <div className={style.profiles}>
        {profiles.map((profile) => (
          <div
            key={profile.idx}
            className={`${style.profile} ${profileInfo?.idx === profile.idx ? "" : style.lightMode}`}
            onClick={() => {
              setProfileIdx(profile.idx);
              setProfileInfo(profile);
              handleProfileClick(profile);
            }}
          >
            {/* 내 프로필이 아닐 경우 세팅 아이콘 노출 */}
            {!profile.isOwnerProfile && (
              <img
                className={style.settingIcon}
                src={profileInfo?.idx === profile.idx ? settingDarkIcon : settingLightIcon}
                alt="Settings"
                onClick={toggleEditChildModal}
              />
            )}
            <div className={style.profileIcon}>
              <img className={style.icon} src={profileIcon} alt={"profileIcon"}/>
              <div>
                <span className={style.profileName}>{profile.isOwnerProfile ? "(나" : `(${profile.name}`}</span>
                <span className={style.profileName}>)</span>
              </div>
            </div>
            <div className={style.profileContainer}>
              <p className={style.profileInfo}>
                나이 <span className={style.profileDetailInfo}>만 {calculateAge(profile.birthday)}세</span>
              </p>
              <div className={style.profileInfo}>
                <span>현재소속</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className={style.profileDetailInfo}>{profileOrganInfo[profile.idx]?.organName || "없음"}</span>
                  {profileOrganInfo[profile.idx]?.organName !== "없음" && (
                    <img
                      src={questionIcon}
                      alt="icon"
                      width={20}
                      height={20}
                      className={style.questionIcon}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={toggleOrganInfoModal}
                    />
                  )}
                </div>
              </div>

              <p className={style.profileInfo}>
                검사횟수 <span className={style.profileDetailInfo}>{profile.testCnt}회</span>
              </p>
              <p className={style.profileInfo}>
                회원코드
                <button
                  className={style.profileInfoBtn}
                  onClick={(e) => {
                    setProfileIdx(profile.idx);
                    setProfileInfo(profile);
                    e.stopPropagation();
                    toggleModal();
                  }}
                >
                  확인하기
                </button>
              </p>
              <p className={style.profileInfo}>
                등록일
                <span className={style.profileDetailInfo}>
                  {timeConverter.convertToFormattedDate(timeConverter.convertToLocalDate(profile.createAt))}
                </span>
              </p>
            </div>
          </div>
        ))}
        <div className={style.addProfile}>
          <button className={`${style.addProfileButton} ${style.addProfileButtonBottom}`} onClick={toggleAddChildModal}>
            <img src={addIcon} alt="icon" width={26} height={26} style={{ pointerEvents: "none" }} />
            프로필(자녀) 추가하기
          </button>
        </div>
      </div>
      {/* 소속정보 모달 */}
      <OrganInfoModal
        isOpen={isOrganInfoModalOpen}
        onClose={toggleOrganInfoModal}
        name={profileInfo?.name || ""}
        organSignInCode={profileOrganInfo[profileInfo?.idx || 0]?.organSignInCode || ""}
        organName={profileOrganInfo[profileInfo?.idx || 0]?.organName || ""}
        organPhoneNumber={profileOrganInfo[profileInfo?.idx || 0]?.organPhoneNumber || ""}
        organVoucherCnt={voucherData?.organVoucherCnt || 0}
        organKpassVoucherCnt={voucherData?.organKpassVoucherCnt || 0}
        organDcasVoucherCnt={voucherData?.organDcasVoucherCnt || 0}
      />
      {/* 회원코드 모달 - 확인하기 클릭 시 노출*/}
      <AffiliationInfoModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        name={profileInfo?.name || ""}
        affiliationInfo={profileInfo?.userCode || ""}
      />
      {/* 프로필(자녀) 추가하기 모달 */}
      <AddChildModal
        isOpen={isAddChildModalOpen}
        onClose={toggleAddChildModal}
        onProfileAdded={handleProfileAdded}
        parentAddress={profiles.length > 0 ? profiles[0].address : undefined}
      />
      {/* 프로필(자녀) 수정하기 모달 */}
      <EditChildModal
        isOpen={isEditChildModalOpen}
        onClose={toggleEditChildModal}
        profileInfo={profileInfo}
        onProfileAdded={handleProfileAdded}
        parentAddress={profiles.length > 0 ? profiles[0].address : undefined}
        editChildModalData={editChildModalData}
      />
    </div>
  );
}

function calculateAge(birthday: string): number {
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
