import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../../util/sliceUtil";
import { PartnerCompanyApi } from "../../api/PartnerCompanyApi";
import { RootState } from "../index";
import { PartnerCompanySearchCondition } from "../../types/partnerCompany";

export interface PartnerCompanyState extends SliceState {}

const initialState: PartnerCompanyState = {
  status: Status.IDLE,
};

export const getPartnerCompaniesAsync = createAsyncThunk(
  "partnerCompany/getPartnerCompanies",
  (condition: PartnerCompanySearchCondition) => executePromise(PartnerCompanyApi.getPartnerCompanies(condition))
);

export const partnerCompanySlice = createSlice({
  name: "partnerCompany",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, getPartnerCompaniesAsync);
  },
});

export const partnerCompanyStatusState = (state: RootState) => state.partnerCompany.status;

export default partnerCompanySlice.reducer;
