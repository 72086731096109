import style from "../../../../assets/scss/common/modal/addConsultationModal.module.scss";
import React from "react";

interface Args{
    subText?: string;
}
export function ReservationTitle({subText}:Args) {
    /**
     * 상담 신청하기 ~ 상담 정보를 확인해주세요.
     */
    return (
        <>
            <h3 className={style.addChildInfoTitle}>상담 신청하기</h3>
            <div className={style.addChildInfoDesc}>
                <span className={style.boldDesc}>상담 정보를 확인해 주세요.</span>
                <br/>
                {subText}
            </div>
        </>
    )
}