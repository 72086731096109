import style from "./domesticPartnerCompanyCards.module.scss";
import { PartnerCompanyVO } from "../../../types/partnerCompany";
import { useCallback, useMemo } from "react";
import { AffiliateCodeVO, CodeStatus } from "../../../types/affiliateCode";
import { useModal, useTimeConverter } from "../../../hooks/hooks";

interface Props {
  partnerCompany: PartnerCompanyVO;
}
export function APartnerCompanyDomesticButtons({ partnerCompany }: Props) {
  const { openAffiliateCodeModal } = useModal();
  const { timeConverter } = useTimeConverter();

  const onClickHomepageButton = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  const onClickAffiliateCodeButton = useCallback(
    (codeVO: AffiliateCodeVO) => {
      openAffiliateCodeModal(codeVO);
    },
    [openAffiliateCodeModal]
  );

  const hasAvailableCode = useMemo(() => {
    const code = partnerCompany.codeVO;
    const isValidDate = code && (code?.availableEndAt === null || timeConverter.isAfterNow(code.availableEndAt));
    return code && code.status === CodeStatus.AVAILABLE && isValidDate;
  }, [partnerCompany.codeVO, timeConverter]);

  return (
    <div className={style.buttonArea}>
      {partnerCompany.webSite ? (
        <button
          className={`${style.button} ${style.homepage}`}
          onClick={(e) => {
            e.stopPropagation();
            onClickHomepageButton(partnerCompany.webSite!);
          }}
        >
          홈페이지
        </button>
      ) : null}
      {hasAvailableCode && (
        <button
          className={`${style.button} ${style.code}`}
          onClick={(e) => {
            e.stopPropagation();
            onClickAffiliateCodeButton(partnerCompany.codeVO!);
          }}
        >
          제휴 할인 코드
        </button>
      )}
    </div>
  );
}
