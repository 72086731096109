import { useAppDispatch } from "../hooks";
import { useCallback } from "react";
import { getAffiliateCodeAsync } from "../../store/order/orderSlice";
import { AffiliateCodeDTO, AffiliateCodeVO } from "../../types/affiliateCode";

export function useAffiliateCodeGetter() {
  const dispatch = useAppDispatch();

  const getAffiliateCode = useCallback(
    async (code: string): Promise<AffiliateCodeVO> => {
      const result: AffiliateCodeDTO = await dispatch(getAffiliateCodeAsync(code.trim())).unwrap();
      return {
        ...result,
      } as AffiliateCodeVO;
    },
    [dispatch]
  );

  return {
    getAffiliateCode,
  };
}
