import styled from "styled-components";
import { breakpoints } from "../../../../../../theme/theme";
import checkboxUnCheckedImage from "../../../../../../assets/images/kpass_review/checkbox_unchecked.svg";
import checkboxDisabledImage from "../../../../../../assets/images/kpass_review/checkbox_disabled.svg";
import checkboxCheckedImage from "../../../../../../assets/images/kpass_review/checkbox_checked.svg";

export const ReviewBodyStyle = styled.div`
  width: 1200px;
  height: 100%;
  overflow-y: auto;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  
  ${breakpoints.tablet} {
    width: 100%;
    height : auto;
    padding: 30px 40px;
    gap: 40px;
  }

  ${breakpoints.mobile} {
    width: 100%;
    padding: 0 20px;
    gap: 40px;
  }
`;

export const KpassReviewInputTitleStyle = styled.span`
  color: ${(props) => props.theme.color.white};
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  
  ${breakpoints.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.24px;
  }

  ${breakpoints.mobile} {
    font-size: 16px;
  }
`;

export const InputTextStyle = styled.input.attrs({ type: "text" })`
  width: 100%;
  height: 62px;
  background-color: ${(props) => props.theme.color.deepMainColor};
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 0 30px;

  font-size: 20px;
  line-height: 150%;
  color: ${(props) => props.theme.color.white};

  ::placeholder {
    color: ${(props) => props.theme.color.c9B};
  }
  :focus,
  :not(:placeholder-shown) {
    background-color: ${(props) => props.theme.color.kpDeepBlue};
  }
  ${breakpoints.tablet} {
    padding: 0 16px;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
  }
  
  ${breakpoints.mobile} {
    padding: 0 16px;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
  }
`;

export const InputTextAreaStyle = styled.textarea`
  width: 100%;
  height: 336px;
  background-color: ${(props) => props.theme.color.deepMainColor};
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 16px 30px;

  font-size: 20px;
  line-height: 150%;
  color: ${(props) => props.theme.color.white};

  resize: none;

  ::placeholder {
    color: ${(props) => props.theme.color.c9B};
  }

  :focus,
  :not(:placeholder-shown) {
    background-color: ${(props) => props.theme.color.kpDeepBlue};
  }
  
  ${breakpoints.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.24px;
  }

  ${breakpoints.mobile} {
    font-size: 16px;
    padding: 12px 16px;
    border-radius: 10px;
  }
`;

export const SaveButtonStyle = styled.button`
  width: 422px;
  height: 88px;
  align-self: center;

  background-color: ${(props) => props.theme.color.kpNavy};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  color: ${(props) => props.theme.color.white};
  font-size: 24px;
  font-weight: bold;
  flex-shrink: 0;
  
  ${breakpoints.tablet} {
    width: 300px;
    margin: 20px 0;
    font-size: 20px;
  }

  ${breakpoints.mobile} {
    width: 100%;
    height: 68px;
    font-size: 20px;
  }
`;

export const ReviewPolicyStyle = styled.p`
  align-self: center;

  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.color.cFFEB38};
  
  ${breakpoints.tablet} {
    width : 260px;
    font-size: 14px;
    text-align: center;
  }
  
  ${breakpoints.mobile} {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
`;

export const ErrorMessageContainerStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  span:only-child {
    margin-left: auto;
  }
`;

export const ErrorMessageStyle = styled.span`
  color: #ffe606;
  font-size: 16px;
  line-height: 150%;
`;

export const CheckboxStyle = styled.div`
  display: inline-block;
  width: 34px;
  height: 34px;
  background-image: url(${checkboxUnCheckedImage});
  background-size: 34px 34px;

  &.disabled {
    background-image: url(${checkboxDisabledImage});
  }

  &.checked {
    background-image: url(${checkboxCheckedImage});
  }

  flex-shrink: 0;
  
  ${breakpoints.tablet} {
    width: 24px;
    height: 24px;

    background-size: 24px 24px;
  }

  ${breakpoints.mobile} {
    width: 24px;
    height: 24px;

    background-size: 24px 24px;
  }
`;
