enum Status {
  IDLE = "idle",
  LOADING = "loading",
  FAILED = "failed",
}

enum ModalType {
  NONE = "NONE",
  LOGIN = "LOGIN",
  SIGN_UP = "SIGN_UP",
  LEAVE = "LEAVE",
  APP_DOWNLOAD = "APP_DOWNLOAD",
  TERMS = "TERMS",
  REVIEW_WRITE = "REVIEW_WRITE",
  REVIEW_EDIT = "REVIEW_EDIT",
  REVIEW_DETAIL = "REVIEW_DETAIL",
  EVENT_REGISTERER = "EVENT_REGISTERER",
  AFFILIATE_CODE = "AFFILIATE_CODE",
}

interface SliceState {
  status: Status;
}

interface ModalContent {
  title: string;
  desc: string;
  callback?: () => void;
}

interface ConfirmModalContent {
  content: ModalContent;
  confirmFunc: VoidFunction;
}

export interface ChildrenProp {
  children: JSX.Element;
}

enum StyleRequestType{
  MEMBER= "MEMBER",
  SIGN_UP= "SIGN_UP"
}

export type { SliceState, ModalContent, ConfirmModalContent };
export { Status, ModalType, StyleRequestType };
