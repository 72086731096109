import style from "../../../assets/scss/sub/purchase/orderAndPayment.module.scss";
import { MainWrapper } from "../MainWrapper";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ArticleTitle } from "../ArticleTitle";
import { CouponSelector } from "./CouponSelector";
import { TermsCategory } from "../../../types/terms";
import { PaymentWay } from "../../../types/payment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useAuth, useLoader, useModal, useUserAgent } from "../../../hooks/hooks";
import { orderStatus } from "../../../store/order/orderSlice";
import { userCouponCountState } from "../../../store/coupon/couponSlice";
import { cartedProducts, getCartsAsync, setCartedProducts } from "../../../store/cart/cartSlice";
import { CartedProduct, Price } from "../../../types/cart";
import { PaymentGateway } from "./pg/PaymentGateway";
import { getPriceText } from "../../../util/commonFunctions";
import CartProductItems from "./CartProductItems";
import linkIcon from "../../../assets/images/sub/purchase_voucher/link_icon.svg";
import { SupportButton } from "./SupportButton";
import { CodeDiscountPanel } from "./CodeDiscountPanel";
import { AffiliateCodeVO, DiscountType } from "../../../types/affiliateCode";

export function OrderAndPayment() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const cartedProductList = useAppSelector(cartedProducts);
  const status = useAppSelector(orderStatus);
  const userCouponCount = useAppSelector(userCouponCountState);
  const { openAlertModal } = useModal();
  const { setLoaderStatus } = useLoader();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const couponDiscountTitleRef = useRef<HTMLSpanElement>(null);
  const { isMobile } = useUserAgent();

  const [isAgreed, setIsAgreed] = useState(false);
  const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
  const [selectedCouponIdx, setSelectedCouponIdx] = useState<number | null>(null);
  const [paymentWay, setPaymentWay] = useState<PaymentWay | null>(null);
  const [isClickedPayButton, setIsClickedPayButton] = useState(false);

  const [affiliateCode, setAffiliateCode] = useState<AffiliateCodeVO | null>(null);
  const [enteredCode, setEnteredCode] = useState("");
  const [affiliateCodeDiscountAmount, setAffiliateCodeDiscountAmount] = useState(0);

  const onClickAgreeBtn = useCallback((val: boolean) => setIsAgreed(!val), []);
  const onClickTermsLink = useCallback((e: React.MouseEvent) => {
    window.open(`${process.env.REACT_APP_BASE_URL}terms?terms_category=${TermsCategory.PAYMENT_TERMS}`, "_blank");
    e.stopPropagation();
  }, []);
  const onClickPaymentWayBtn = useCallback((p: PaymentWay) => setPaymentWay(p), []);
  const cartIdxes = getCartIdxesFromQueryParam();

  function getCartIdxesFromQueryParam(): number[] {
    const cartIdxes =
      searchParams
        .get("cart-idxes")
        ?.split(",")
        ?.map((it) => parseInt(it)) ?? [];
    if (cartIdxes.length === 0) {
      openAlertModal({
        title: "안내",
        desc: "잘못된 접근입니다.",
      });
      navigate(`/purchase-voucher`);
      return [];
    }

    return cartIdxes;
  }

  const onClickPayBtn = useCallback(() => {
    if (!isAgreed) {
      openAlertModal({
        title: "안내",
        desc: "주문 확인 후 약관동의 항목에 체크해주세요.",
      });
      return;
    }

    if (!paymentWay) {
      openAlertModal({
        title: "안내",
        desc: "결제수단을 선택해 주세요.",
      });

      return;
    }

    setIsClickedPayButton(true);
  }, [isAgreed, paymentWay, openAlertModal]);

  const totalPrice: Price = useMemo(() => {
    if (cartedProductList) {
      return cartedProductList.reduce(
        (acc: Price, curPrd) => {
          const thisRegularAmount = acc.regularAmount + (curPrd?.totalRegularPrice ?? 0);
          const thisProductDiscountAmount = acc.productDiscountAmount + (curPrd?.totalDiscountAmount ?? 0);
          const thisProductAmount = thisRegularAmount - thisProductDiscountAmount;
          const actualAmount =
            acc.actualAmount + (curPrd?.totalActualAmount ?? 0) - couponDiscountAmount - affiliateCodeDiscountAmount;

          return {
            regularAmount: thisRegularAmount,
            productDiscountAmount: thisProductDiscountAmount,
            productDiscountAmountShown: thisProductDiscountAmount,
            couponDiscountAmount: couponDiscountAmount,
            couponDiscountAmountShown:
              thisProductAmount < couponDiscountAmount ? thisProductAmount : couponDiscountAmount,
            affiliateCodeDiscountAmount: affiliateCodeDiscountAmount,
            affiliateCodeDiscountAmountShown:
              thisProductAmount < affiliateCodeDiscountAmount ? thisProductAmount : affiliateCodeDiscountAmount,
            actualAmount: actualAmount,
            actualAmountShown: actualAmount > 0 ? actualAmount : 0,
          };
        },
        {
          regularAmount: 0,
          couponDiscountAmount: couponDiscountAmount,
          couponDiscountAmountShown: couponDiscountAmount,
          affiliateCodeDiscountAmount: affiliateCodeDiscountAmount,
          affiliateCodeDiscountAmountShown: affiliateCodeDiscountAmount,
          productDiscountAmount: 0,
          productDiscountAmountShown: 0,
          actualAmount: 0,
          actualAmountShown: 0,
        }
      );
    } else {
      return {
        regularAmount: 0,
        productDiscountAmount: 0,
        productDiscountAmountShown: 0,
        couponDiscountAmount: couponDiscountAmount,
        couponDiscountAmountShown: couponDiscountAmount,
        affiliateCodeDiscountAmount: affiliateCodeDiscountAmount,
        affiliateCodeDiscountAmountShown: affiliateCodeDiscountAmount,
        actualAmount: 0,
        actualAmountShown: 0,
      };
    }
  }, [cartedProductList, couponDiscountAmount, affiliateCodeDiscountAmount]);

  const getCarts = useCallback(async () => {
    try {
      const cartedProducts: CartedProduct[] = await dispatch(getCartsAsync(cartIdxes)).unwrap();
      dispatch(setCartedProducts(cartedProducts));
    } catch (error) {
      console.error(error);
      openAlertModal(null);
    }
  }, [dispatch, openAlertModal, cartIdxes]);

  useEffect(() => {
    document.title = `검사이용권 구매 | ${process.env.REACT_APP_TITLE}`;
    window.scroll({ top: 0 });

    if (isLoggedIn) {
      getCarts().then();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    setLoaderStatus([status]);
    // eslint-disable-next-line
  }, [status]);

  const resetCoupon = useCallback(() => {
    setCouponDiscountAmount(0);
    setSelectedCouponIdx(null);
  }, []);

  const resetSalesCodeDiscount = useCallback(() => {
    setAffiliateCode(null);
    setEnteredCode("");
  }, []);

  const totalSellingPrice = useMemo(
    () =>
      cartedProductList?.map((it) => it.product.sellingPrice * it.quantity).reduce((total, it) => total + it, 0) ?? 0,
    [cartedProductList]
  );
  const setAffiliateCodeAndResetCoupon = useCallback(
    (codeVO: AffiliateCodeVO | null) => {
      if (codeVO) {
        resetCoupon();
      }
      setAffiliateCode(codeVO);
    },
    [resetCoupon]
  );

  useEffect(() => {
    if (affiliateCode) {
      switch (affiliateCode.discountType) {
        case DiscountType.AMOUNT:
          setAffiliateCodeDiscountAmount(affiliateCode.discountValue);
          break;
        case DiscountType.PERCENT:
          setAffiliateCodeDiscountAmount(Math.round(totalSellingPrice * (affiliateCode.discountValue / 100)));
          break;
        default:
          setAffiliateCodeDiscountAmount(0);
          break;
      }
    } else {
      setAffiliateCodeDiscountAmount(0);
    }
  }, [affiliateCode, totalSellingPrice]);

  const totalDiscountAmount = useMemo(
    () =>
      totalPrice.productDiscountAmountShown +
      totalPrice.couponDiscountAmountShown +
      totalPrice.affiliateCodeDiscountAmountShown,
    [
      totalPrice.productDiscountAmountShown,
      totalPrice.couponDiscountAmountShown,
      totalPrice.affiliateCodeDiscountAmountShown,
    ]
  );

  return (
    <>
      <MainWrapper showFooter={true} mainClassName={style.orderAndPayment}>
        <>
          <div className={style.title}>
            <ArticleTitle title="주문/결제" className={style.textArea} />
          </div>
          <div className={style.orderAndPaymentWrapper}>
            <div className={style.orderItemList}>
              <h3 className={style.sectionTitle}>주문상품</h3>
              <CartProductItems cartedProducts={cartedProductList ?? []} />
            </div>
            <div className={style.supportPanel}>
              <div className={style.titleBar}>
                <h3 className={style.sectionTitle}>
                  <span ref={couponDiscountTitleRef}>쿠폰 할인</span>
                  <SupportButton
                    title={"쿠폰 이용 안내"}
                    content={[
                      "각 쿠폰은 1회 사용 가능하며, 주문 건 당 한 장만 결제 적용 가능합니다.",
                      "유효기간이 만료된 쿠폰은 사용이 불가능합니다.\n쿠폰의 유효기간을 꼭 확인해 주세요.",
                      "쿠폰을 이용하여 구매한 상품을 환불할 경우,\n이용권 구매 전 쿠폰 상태로 반환됩니다.",
                      "반환될 때의 유효기간은 최초 지급된 쿠폰의 유효 기간과 동일합니다.\n단, 환불 시점 유효기간이 경과한 쿠폰은 사용할 수 없습니다.",
                      "쿠폰과 할인 코드는 중복 적용이 불가능합니다.",
                    ]}
                    supportLeft={-(couponDiscountTitleRef.current?.getBoundingClientRect().width ?? 0)}
                  />
                </h3>
                <span className={style.couponCount}>
                  <span>보유쿠폰 </span>
                  <span className={style.count}>{userCouponCount}장</span>
                </span>
              </div>
              <div className={style.selectorArea}>
                <CouponSelector
                  actualAmount={totalPrice.actualAmount}
                  products={cartedProductList ?? []}
                  changeSelectedCoupon={(couponIdx: number | null, totalDiscountAmount: number) => {
                    if (couponIdx) {
                      resetSalesCodeDiscount();
                    }
                    setSelectedCouponIdx(couponIdx);
                    setCouponDiscountAmount(totalDiscountAmount);
                  }}
                  selectedCouponIdx={selectedCouponIdx}
                />
              </div>
            </div>
            {isMobile && (
              <CodeDiscountPanel
                discountCode={enteredCode}
                setDiscountCode={setEnteredCode}
                setAffiliateCode={setAffiliateCodeAndResetCoupon}
                cartIdxes={cartIdxes}
              />
            )}
            <div className={style.paymentWay}>
              <h3 className={style.sectionTitle}>결제수단</h3>
              <ul className={style.ways}>
                <li
                  className={`${style.payItem} ${paymentWay === PaymentWay.CARD ? style.selected : ""}`}
                  onClick={() => onClickPaymentWayBtn(PaymentWay.CARD)}
                >
                  카드결제
                </li>
                <li
                  className={`${style.payItem} ${paymentWay === PaymentWay.VBANK ? style.selected : ""}`}
                  onClick={() => onClickPaymentWayBtn(PaymentWay.VBANK)}
                >
                  가상계좌
                </li>
              </ul>
            </div>
            {!isMobile && (
              <CodeDiscountPanel
                discountCode={enteredCode}
                setDiscountCode={setEnteredCode}
                setAffiliateCode={setAffiliateCodeAndResetCoupon}
                cartIdxes={cartIdxes}
              />
            )}
            <div className={style.orderedAmountInfo}>
              <h3 className={style.sectionTitle}>주문정보</h3>
              <div className={style.amountDetail}>
                <ul className={style.amountList}>
                  <li className={style.amountItem}>
                    <span>총 상품 금액</span>
                    <span>{getPriceText(totalPrice.regularAmount)}원</span>
                  </li>
                  {totalDiscountAmount > 0 && (
                    <li className={`${style.amountItem} ${style.accent}`}>
                      <span>할인금액</span>
                      <span>-{getPriceText(totalDiscountAmount)}원</span>
                    </li>
                  )}
                  {totalPrice.productDiscountAmount > 0 && (
                    <li className={style.amountItem}>
                      <span>ㄴ 상품 할인</span>
                      <span>-{getPriceText(totalPrice.productDiscountAmountShown)}원</span>
                    </li>
                  )}
                  {selectedCouponIdx ? (
                    <li className={style.amountItem}>
                      <span>ㄴ 쿠폰 할인</span>
                      <span>-{getPriceText(totalPrice.couponDiscountAmountShown)}원</span>
                    </li>
                  ) : (
                    <></>
                  )}
                  {affiliateCode ? (
                    <li className={style.amountItem}>
                      <span>ㄴ 코드 할인</span>
                      <span>-{getPriceText(totalPrice.affiliateCodeDiscountAmountShown)}원</span>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
                <div className={style.totalAmount}>
                  <span>총 결제금액</span>
                  <span>{getPriceText(totalPrice.actualAmountShown)}원</span>
                </div>
              </div>
            </div>
          </div>

          <div className={`${style.agreement} ${style.desktop}`}>
            <button
              className={`${style.checkBtn} ${isAgreed ? style.checked : ""}`}
              onClick={() => onClickAgreeBtn(isAgreed)}
            />
            <span className={style.text}>위 주문내용 확인 및 결제약관, 결제 진행에 동의합니다.</span>
            <span className={style.link} onClick={onClickTermsLink}>
              자세히보기
            </span>
          </div>

          <div className={`${style.agreement} ${style.mobile}`}>
            <div className={style.checkBtnWrap}>
              <button
                className={`${style.checkBtn} ${isAgreed ? style.checked : ""}`}
                onClick={() => onClickAgreeBtn(isAgreed)}
              />
            </div>
            <div className={style.textWrap}>
              <span className={style.text}>위 주문내용 확인 및 결제약관, 결제 진행에 동의합니다.</span>
              <div className={style.linkWrap}>
                <span className={style.link} onClick={onClickTermsLink}>
                  자세히보기
                </span>
                <img src={linkIcon} alt={"자세히 보기 아이콘"} />
              </div>
            </div>
          </div>

          <button className={style.payButton} onClick={onClickPayBtn}>
            결제하기
          </button>
        </>
      </MainWrapper>
      {
        <PaymentGateway
          isClickedPayButton={isClickedPayButton}
          resetClickedPayButton={() => setIsClickedPayButton(false)}
          paymentWay={paymentWay}
          cartIdxes={cartIdxes}
          price={totalPrice}
          couponHistoryIdx={selectedCouponIdx}
          affiliateCodeIdx={affiliateCode?.idx ?? null}
        />
      }
    </>
  );
}
