import style from "../../../../assets/scss/common/modal/leaveModal.module.scss";
interface Args{
    title: string;
    subTitle: string;
}
export function LeaveHeaderField({title, subTitle}: Args){
    return(
        <>
            <div className={style.leaveBodyTitleWrapper}>
                <span className={style.leaveBodyTitle}>{title}</span>
                <div className={style.leaveBodySubTitleWrapper}>
                    <span className={style.leaveBodySubtitle}>{subTitle}</span>
                </div>
            </div>
        </>
    )
}