export class FileInput {
  private fileInput: HTMLInputElement;

  public constructor(doWithFiles: (files: FileList) => void, multiple: boolean, acceptType: FileAcceptType) {
    this.fileInput = document.createElement("input");
    this.fileInput.type = "file";
    this.fileInput.accept = acceptType;
    this.fileInput.multiple = multiple;
    this.fileInput.onchange = async () => {
      if (this.fileInput.files) {
        doWithFiles(this.fileInput.files);
      }
    };
  }

  public click() {
    this.fileInput.click();
  }
}

export enum FileAcceptType {
  JPG_PNG_GIF = ".jpg, .jpeg, .png, .gif",
}
