import {Country} from "../types/country";
import {CountryCode, CountryFilterValue, CountryName} from "../types/user";

const height = window.innerHeight;

export const isOnScreen = (headerHeight: number) => (rectTop: number, rectBottom: number) => {
  const baseHeight = height * 0.9;
  if (headerHeight <= rectTop && rectTop < baseHeight) return true;
  if (headerHeight <= rectBottom && rectBottom <= baseHeight) return true;
  return rectTop <= headerHeight && baseHeight <= rectBottom;
};

export const isOnScreenAxisX = (left: number, right: number) => (rectLeft: number, rectRight: number) => {
  if (left <= rectLeft && rectLeft <= right) return true;
  if (left <= rectRight && rectRight <= right) return true;
  return rectLeft <= left && right <= rectRight;
};

export const isPassingHeaderBottom = (headerHeight: number) => (rectTop: number, rectBottom: number) => {
  return rectTop < headerHeight && headerHeight < rectBottom;
};

export const showArea = (headerHeight: number) => (refCurrent: HTMLElement, setIsShown: (_: boolean) => void) => {
  if (refCurrent) {
    const rect = refCurrent.getBoundingClientRect();
    if (isOnScreen(headerHeight)(rect.top, rect.bottom)) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  }
};

export const getPriceText = (price: number) => Math.floor(price).toLocaleString();

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const numberWithCommas = (x: number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const fillZeroTo2Digit = (n: number) => n.toString().padStart(2, "0");

export const getOnlyNumberText = (s: string) => s.replace(/[^0-9]/g, "");
export const getOnlyKoreanEnglishAndNumber = (s: string) => s.replace(/[^0-9a-zA-Zㄱ-ㅎ가-힣]/g, "");

export const handleOnlyNumberChange = (e:any, setValue: (_:string) =>void) => {
  const input = e.target.value.toUpperCase();
  const filteredInput = input.replace(/[^\d]/g, '');

  setValue(filteredInput);
};

// 1995-12-25 -> 19951225로 바꿔주는 함수
export const convertDateFormat = (dateString: string) =>{
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  if (!datePattern.test(dateString)) {
    throw new Error('data type errors');
  }
  return dateString.replace(/-/g, '');
}

// 19951225 -> 1995-12-25로 바꿔주는 함수
export const convertToOriginalFormat = (dateString: string) => {
  const datePattern = /^\d{8}$/;
  if (!datePattern.test(dateString)) {
    throw new Error('data type errors');
  }

  // 문자열 분할 및 하이픈 추가
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${year}-${month}-${day}`;
}

// 국가 선택지 중 필터 처리 함수
export const filterAllowedCountries = (
    {countries, allowedKeys}:{countries?:Country[], allowedKeys:string[]}
) => {
  return countries?.filter(option => allowedKeys.includes(option.codeISOAlpha2));
};

export const countryCodeMap: Record<CountryName, CountryCode> = {
  "국가를 선택해주세요.": "",
  대한민국: "KR",
  미국: "US",
  몽골: "MN",
  대만: "TW",
  베트남: "VN",
};


export const getCountryFilterValue = (code: string): CountryFilterValue => {
  switch(code) {
    case "KR": return CountryFilterValue.KR;
    case "US": return CountryFilterValue.US;
    case "MN": return CountryFilterValue.MN;
    case "TW": return CountryFilterValue.TW;
    case "VN": return CountryFilterValue.VN;
    default: return CountryFilterValue.NONE;
  }
};

export const injectAnalytics = () => {
  if (process.env.REACT_APP_MODE === "운영") {
    console.log('logging')
    // 네이버 로그 분석
    const naverScript = document.createElement('script');
    naverScript.src = '//wcs.naver.net/wcslog.js';
    document.head.appendChild(naverScript);

    naverScript.onload = () => {
      const naverTrackingScript = document.createElement('script');
      naverTrackingScript.innerHTML = `
        if (!wcs_add) var wcs_add={};
        wcs_add["wa"] = "s_282cfc2b7159";
        if (!_nasa) var _nasa={};
        if(window.wcs){
          wcs.inflow();
          wcs_do();
        }
      `;
      document.head.appendChild(naverTrackingScript);
    };

    // Google Analytics
    const gaScript = document.createElement('script');
    gaScript.async = true;
    gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-WJ2BNFM66N';
    document.head.appendChild(gaScript);

    const gaConfigScript = document.createElement('script');
    gaConfigScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-WJ2BNFM66N');
    `;
    document.head.appendChild(gaConfigScript);
  }
};