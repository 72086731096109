import style from "./popups.module.scss";
import closeIcon from "../../../assets/images/main/popup/close_icon.svg";
import popupCloseIcon from "../../../assets/images/main/popup/popup_close_today_icon.png";
import popupCloseIconMobile from "../../../assets/images/main/popup/popup_close_today_icon_mobile.png";
import { useCallback, useEffect, useState } from "react";
import { fillZeroTo2Digit } from "../../../util/commonFunctions";
import { useCookie } from "../../../hooks/auth/useCookie";
import { CookieKeys } from "../../../hooks/auth/AuthProvider";
import { useLanguage, useScrollingEvent, useUserAgent } from "../../../hooks/hooks";
import { PopupVO } from "../../../types/popup";
import { usePopupsGetter } from "../../../hooks/popup/usePopupsGetter";
import { PopupSlider } from "./PopupSlider";
import {useTranslation} from "react-i18next";

export function Popups() {
  const { t } = useTranslation(["popup"]);
  const { language, isLanguageLoaded } = useLanguage();
  const { isDesktop } = useUserAgent();
  const { getValueFromCookie, setCookie } = useCookie();
  const { stopScroll, restartScroll } = useScrollingEvent();
  const { getPopups } = usePopupsGetter();

  const [showPopups, setShowPopups] = useState(false);
  const [popups, setPopups] = useState<PopupVO[] | null>(null);
  const hidePopup = getValueFromCookie(CookieKeys.NOT_SHOW_POPUP_TODAY);

  useEffect(() => {
    if (!isLanguageLoaded) return;

    const hidePopup = getValueFromCookie(CookieKeys.NOT_SHOW_POPUP_TODAY) === "true";

    if (hidePopup) {
      setShowPopups(false);
      return;
    }

    getPopups()
      .then((result) => {
        if (result.length > 0) {
          setShowPopups(true);
          setPopups(result);
        } else {
          setShowPopups(false);
          setPopups(null);
        }
      })
      .catch(() => setShowPopups(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidePopup, language, isLanguageLoaded]);

  useEffect(() => {
    if (showPopups) {
      stopScroll();
    } else {
      restartScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopups]);

  const onClickNotShowTodayButton = useCallback(() => {
    setShowPopups(false);
    const now = new Date();
    const expires = new Date(
      `${now.getFullYear()}-${fillZeroTo2Digit(now.getMonth() + 1)}-${fillZeroTo2Digit(now.getDate())}T23:59:59`
    ).toUTCString();

    setCookie(CookieKeys.NOT_SHOW_POPUP_TODAY, Boolean(true).toString(), expires);
  }, [setCookie]);
  
  useEffect(() => {
    // console.log({ isKorean, showPopups });
  }, [language, showPopups]);
  return (
    <>
      {!!showPopups ? (
        <div className={style.popupWrapper}>
          <div className={style.popup}>
            <button className={style.closeButton} onClick={() => setShowPopups(false)}>
              <div className={style.area}>
                <div className={style.defaultBg}></div>
                <div className={style.hoverBg}></div>
                <img src={closeIcon} alt={"닫기"} />
              </div>
            </button>
            {popups && <PopupSlider popups={popups} />}
            <button className={style.closeTodayButton} onClick={onClickNotShowTodayButton}>
              <div className={style.icon}>
                <img src={isDesktop ? popupCloseIcon : popupCloseIconMobile} alt={"닫기"} />
              </div>
              <span className={style.text}>{t("notToday")}</span>
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}
