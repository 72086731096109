import { useCallback, useContext, useEffect } from "react";
import { NavigationType, UNSAFE_NavigationContext } from "react-router-dom";
import { History, Update } from "history";

interface Args {
  callbackOnBack?: (...args: any) => void;
  callbackOnNext?: (...args: any) => void;
}

export function useLocationHistory({ callbackOnBack, callbackOnNext }: Args) {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    const listener = ({ location, action }: Update) => {
      window.history.scrollRestoration = "manual";
      switch (action) {
        case NavigationType.Pop:
          callbackOnBack?.({ location, action });
          break;
        case NavigationType.Push:
          callbackOnNext?.({ location, action });
          break;
      }
    };

    return navigator.listen(listener);
  }, [callbackOnBack, callbackOnNext, navigator]);

  const pushHistory = useCallback(
    (param?: string) => {
      navigator.push(`${window.location.pathname}${param ?? window.location.search}`);
    },
    [navigator]
  );

  return { pushHistory };
}
