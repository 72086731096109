import { Modal } from "../Modal";
import { ModalType } from "../../../../types/common";
import { useAppSelector, useLoader, useModal } from "../../../../hooks/hooks";
import modalStyle from "../../../../assets/scss/common/modal/modal.module.scss";
import { ReviewBodyStyle, ReviewPolicyStyle, SaveButtonStyle } from "./components/common/StyledComponents";
import { Profiles } from "./components/profiles/Profiles";
import { Title } from "./components/title/Title";
import { Description } from "./components/description/Description";
import { Pictures } from "./components/pictures/Pictures";
import { useProfileReviewGetter } from "./hooks/useProfileReviewGetter";
import { useCallback, useEffect, useRef } from "react";
import { profileStatusState } from "../../../../store/profile/profileSlice";
import { reviewStatusState } from "../../../../store/review/reviewSlice";
import { ReviewValidationError, useReviewValidator } from "./hooks/useReviewValidator";
import { useReviewImages } from "./hooks/useReviewImages";
import {
  reset,
  ReviewModalMode,
  setAgreement,
  setReviewForEdit,
  setReviewImages,
  setReviewModalMode,
} from "../../../../store/review/reviewModalSlice";
import { useDispatch } from "react-redux";
import { useReviewsGetter } from "../../../sub/review/hooks/useReviewsGetter";
import { useReviewUpdater } from "./hooks/useReviewUpdater";
import { useTranslation } from "react-i18next";
import { Agreement } from "./components/agreement/Agreement";

interface Props {
  reviewIdx: number | undefined;
}

export function ReviewEditModal({ reviewIdx }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const dispatch = useDispatch();
  const { showModal, closeModal } = useModal();
  const { getProfileReview } = useProfileReviewGetter();
  const profileState = useAppSelector(profileStatusState);
  const reviewState = useAppSelector(reviewStatusState);
  const { updateReview } = useReviewUpdater();
  const { validate } = useReviewValidator();
  const { uploadReviewImages } = useReviewImages();
  const { getReview, getReviews, getReviewImages } = useReviewsGetter();
  const { setLoaderStatus } = useLoader();
  const { openAlertModal, openConfirmModal } = useModal();

  const profileRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const initialize = useCallback(async () => {
    if (reviewIdx === undefined) {
      openAlertModal({
        title: t("errorModalTitle"),
        desc: t("badAccess"),
      });
      closeModal();
      return;
    }

    try {
      const review = await getReview(reviewIdx);
      dispatch(setReviewForEdit(review));
      await getProfileReview(review.profileIdx);
      const reviewImages = await getReviewImages(reviewIdx);
      dispatch(setReviewImages(reviewImages));
      dispatch(setReviewModalMode(ReviewModalMode.EDIT));
      dispatch(setAgreement(true));
    } catch (e) {
      console.error(e);
      openAlertModal({
        title: t("errorModalTitle"),
        desc: t("loadReviewError"),
      });
      closeModal();
    }
  }, [closeModal, openAlertModal, reviewIdx, getProfileReview, dispatch, getReview, getReviewImages, t]);

  useEffect(() => {
    initialize().then();
  }, [initialize]);

  useEffect(() => {
    setLoaderStatus([profileState, reviewState]);
  }, [profileState, reviewState, setLoaderStatus]);

  const validateAndScrollTo = useCallback((): boolean => {
    const errors = validate();
    if (errors.some((it) => it === ReviewValidationError.TITLE)) titleRef.current?.focus();
    else if (errors.some((it) => it === ReviewValidationError.DESCRIPTION)) descriptionRef.current?.focus();

    return errors.length === 0;
  }, [validate, titleRef, descriptionRef]);

  const closeReviewEditModal = useCallback(() => {
    dispatch(reset());
    closeModal();
  }, [dispatch, closeModal]);

  const onClickEditReview = useCallback(async () => {
    if (!validateAndScrollTo()) return;
    if (reviewIdx === undefined) return;
    try {
      const reviewImages = await uploadReviewImages();
      await updateReview(reviewIdx, reviewImages);
      await getReviews({});

      openAlertModal({
        title: t("editCompleteModalTitle"),
        desc: t("editCompleteModalMessage"),
        callback: () => closeReviewEditModal(),
      });
    } catch (e) {
      console.error(e);
      openAlertModal({
        title: t("errorModalTitle"),
        desc: t("editModalErrorMessage"),
        callback: () => closeReviewEditModal(),
      });
    }
  }, [
    reviewIdx,
    validateAndScrollTo,
    uploadReviewImages,
    updateReview,
    closeReviewEditModal,
    openAlertModal,
    getReviews,
    t,
  ]);

  const onClickCloseModalButton = useCallback(() => {
    openConfirmModal(
      {
        title: t("stopEditReviewModalTitle"),
        desc: t("stopEditReviewModalMessage"),
      },
      () => closeReviewEditModal()
    );
  }, [closeReviewEditModal, openConfirmModal, t]);

  return (
    <Modal
      showModal={showModal.valueOf() === ModalType.REVIEW_EDIT.valueOf()}
      title={t("editModalTitle")}
      closeModalFunction={onClickCloseModalButton}
      modalBgStyle={modalStyle.mainColorBg}
      modalOtherStyle={modalStyle.reviewModal}
    >
      <ReviewBodyStyle>
        <Profiles profileRef={profileRef} />
        <Title inputRef={titleRef} />
        <Description textRef={descriptionRef} />
        <Pictures />
        <Agreement />
        <SaveButtonStyle onClick={onClickEditReview}>{t("saveButton")}</SaveButtonStyle>
        <ReviewPolicyStyle>
          <span>* {t("warningMessage")}</span>
        </ReviewPolicyStyle>
      </ReviewBodyStyle>
    </Modal>
  );
}
