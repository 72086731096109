import standardizationPDFImage from "../assets/images/standardization_pdf.png";
import style from "./standardizationEvidenceButton.module.scss";
import { useUserAgent } from "../hooks/hooks";

export function StandardizationEvidenceButton() {
  const { isDesktop } = useUserAgent();
  const { isMobile } = useUserAgent();

  return (
    <a
      className={style.standardizationPDFButton}
      href={"/korean-version-kpass-standardization"}
      target={"_blank"}
      rel="noreferrer"
    >
      <img className={style.pdfImage} src={standardizationPDFImage} alt={"standardization pdf"} />
      <div className={style.hiddenBg}></div>
      <span className={style.buttonTitle}>
        <span className={style.strong}>한국판 K-PASS 표준화</span>
        {isDesktop ? <></> : <br />}
      </span>
      {!isMobile && <div className={style.downloadText}>자세히보기</div>}
    </a>
  );
}
