import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SliderDirection, useSlider } from "../../common/slider/useSlider";
import { PopupVO } from "../../../types/popup";
import style from "../../common/slider/slider.module.scss";
import popupStyle from "./popups.module.scss";
import { APopup } from "./APopup";

interface Props {
  popups: PopupVO[];
}
export function PopupSlider({ popups }: Props) {
  const [popupCurrentSlideIdx, setPopupCurrentSlideIdx] = useState(0);
  const [moveNextAdditionalFunc, setMoveNextAdditionalFunc] = useState<() => void>(() => () => {});
  const [movePrevAdditionalFunc, setMovePrevAdditionalFunc] = useState<() => void>(() => () => {});

  const contentRef = useRef<HTMLDivElement>(null);
  const delay = useMemo(() => 800, []);
  const slideWidth = useMemo(() => {
    if (contentRef.current) {
      const { width } = contentRef.current.getBoundingClientRect();
      return width;
    }

    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]);

  const {
    moveSlideBySlideIdx,
    moveSlide,
    currentSlideIdx,
    transitionDelay,
    translateXValue,
    handleTouch,
    pauseAutoPlay,
    isTransitioning,
    getNextSlideIdx,
    getPrevSlideIdx,
  } = useSlider({
    slides: popups.map((p) => <div key={`popup-${p.idx}`}></div>),
    slideWidth,
    delay,
    blockSwipeOnTransitioning: true,
    additionalFunctions: {
      moveNext: moveNextAdditionalFunc,
      movePrev: movePrevAdditionalFunc,
    },
  });

  useEffect(() => {
    setPopupCurrentSlideIdx(currentSlideIdx);
  }, [currentSlideIdx]);

  useEffect(() => {
    setMoveNextAdditionalFunc(() => () => {
      setPopupCurrentSlideIdx(getNextSlideIdx(currentSlideIdx));
    });
  }, [setMoveNextAdditionalFunc, setPopupCurrentSlideIdx, getNextSlideIdx, currentSlideIdx]);

  useEffect(() => {
    setMovePrevAdditionalFunc(() => () => {
      setPopupCurrentSlideIdx(getPrevSlideIdx(currentSlideIdx));
    });
  }, [setMovePrevAdditionalFunc, setPopupCurrentSlideIdx, getPrevSlideIdx, currentSlideIdx]);

  useEffect(() => {
    moveSlideBySlideIdx(0, false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDirectionButton = useCallback(
    (direction: SliderDirection) => {
      if (!isTransitioning) {
        pauseAutoPlay();
        moveSlide(direction);
      }
    },
    [isTransitioning, pauseAutoPlay, moveSlide]
  );

  return (
    <div className={popupStyle.popupContent} ref={contentRef}>
      {popups.length > 1 && (
        <div className={popupStyle.bullets}>
          {popups.map((p, idx) => (
            <button
              key={`popup-bullets-${p.idx}`}
              className={`${popupStyle.bulletButton} ${idx === popupCurrentSlideIdx ? popupStyle.selected : ""}`}
              onClick={() => {
                if (!isTransitioning) {
                  pauseAutoPlay();
                  moveSlideBySlideIdx(idx, true, false);
                  setTimeout(() => {
                    moveSlideBySlideIdx(idx, false, false);
                  }, delay);
                }
              }}
            />
          ))}
        </div>
      )}
      <div style={{ width: "100%", height: "max-content" }} className={`${style.wrapper}`}>
        <div
          className={style.content}
          style={{
            width: `${slideWidth}px`,
            overflow: "hidden",
          }}
          onTouchStart={handleTouch.start}
          onTouchMove={handleTouch.move}
          onTouchEnd={handleTouch.end}
        >
          <div
            className={style.slides}
            style={{
              height: "100%", //"max-content",
              alignItems: "flex-start",
              transform: `translateX(${translateXValue}px)`,
              transition: `all ${transitionDelay}ms`,
            }}
          >
            {popups.map((p, idx) => (
              <div
                key={`popup-group0-${idx}`}
                style={{
                  width: `${slideWidth}px`,
                }}
              >
                <APopup
                  popupVO={p}
                  moveNext={() => onClickDirectionButton(SliderDirection.NEXT)}
                  movePrev={() => onClickDirectionButton(SliderDirection.PREV)}
                />
              </div>
            ))}
            {popups.map((p, idx) => (
              <div
                key={`popup-group1-${idx}`}
                style={{
                  width: `${slideWidth}px`,
                }}
              >
                <APopup
                  popupVO={p}
                  moveNext={() => onClickDirectionButton(SliderDirection.NEXT)}
                  movePrev={() => onClickDirectionButton(SliderDirection.PREV)}
                />
              </div>
            ))}
            {popups.map((p, idx) => (
              <div
                key={`popup-group2-${idx}`}
                style={{
                  width: `${slideWidth}px`,
                }}
              >
                <APopup
                  popupVO={p}
                  moveNext={() => onClickDirectionButton(SliderDirection.NEXT)}
                  movePrev={() => onClickDirectionButton(SliderDirection.PREV)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
