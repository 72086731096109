import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { deleteReviewAsync } from "../../../../store/review/reviewSlice";

export function useReviewDeleter() {
  const dispatch = useAppDispatch();

  const deleteReview = useCallback(
    async (reviewIdx: number) => {
      await dispatch(deleteReviewAsync(reviewIdx)).unwrap();
    },
    [dispatch]
  );

  return {
    deleteReview,
  };
}
