import style from "../../assets/scss/common/header.module.scss";

interface Props {
  couponCount: number;
  organVoucherCount: number;
  userVoucherCount: number;
  organKpassVoucherCount: number;
}

export function CouponVoucherStatus({
  couponCount,
  organVoucherCount,
  userVoucherCount,
  organKpassVoucherCount,
}: Props) {
  return (
    <ul className={style.vouchers}>
      <li className={style.anItem}>
        <span className={style.label}>보유쿠폰</span>
        <span className={style.content}>{couponCount ?? 0} 장</span>
      </li>
      <li className={style.anItem}>
        {/* K icon */}
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.533 10C20.533 4.48112 16.0519 0 10.533 0C5.01414 0 0.53302 4.48112 0.53302 10C0.53302 15.5189 5.01414 20 10.533 20H20.533V10ZM8.8383 12.6293L10.1647 11.0999L12.8891 14.8154H15.6015L11.5748 9.48666L15.3984 5.07812H12.7336L8.8383 9.66639V5.07812H6.66341V14.8154H8.8383V12.6293Z"
            fill="white"
          />
        </svg>
        <span className={style.label}>소속이용권</span>
        <span className={style.content}>{organKpassVoucherCount ?? 0} 매</span>
      </li>
      <li className={style.anItem}>
        {/* K icon */}
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.533 10C20.533 4.48112 16.0519 0 10.533 0C5.01414 0 0.53302 4.48112 0.53302 10C0.53302 15.5189 5.01414 20 10.533 20H20.533V10ZM8.8383 12.6293L10.1647 11.0999L12.8891 14.8154H15.6015L11.5748 9.48666L15.3984 5.07812H12.7336L8.8383 9.66639V5.07812H6.66341V14.8154H8.8383V12.6293Z"
            fill="white"
          />
        </svg>
        <span className={style.label}>검사이용권</span>
        <span className={style.content}>{userVoucherCount ?? 0} 매</span>
      </li>
    </ul>
  );
}
