import style from "./mainArticle1.module.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLanguage, useMainScrolling, usePage, useUserAgent } from "../../../hooks/hooks";
import { BannerSlider } from "./banner/BannerSlider";
import { useBannerGetter } from "../../../hooks/banner/useBannerGetter";
import { Banners } from "../banner/Banners";
import { BannerVO } from "../../../types/hooks/banner";

const WINDOW_WIDTH = window.innerWidth;
const BOTTOM_ELLIPSE_RATIO_DESKTOP = 0.0721;
const BOTTOM_ELLIPSE_RATIO_MOBILE = 0.082;

export function Article1Section1() {
  const { isDesktop, isTablet, isMobile } = useUserAgent();
  const { language } = useLanguage();
  const bottomRef = useRef<HTMLDivElement>(null);
  const section1Ref = useRef<HTMLElement>(null);
  const [sliderWrapperElement, setSliderWrapperElement] = useState<HTMLDivElement | null>(null);
  const { setArticle1Section1BottomSpace } = useMainScrolling();
  const { isKorean } = useLanguage();
  const { isWithAppPage } = usePage();

  const { getBanners } = useBannerGetter();
  const [banners, setBanners] = useState<BannerVO[] | null>(null);

  useEffect(() => {
    if (bottomRef.current) {
      const bottomSpaceHeight = WINDOW_WIDTH * (isDesktop ? BOTTOM_ELLIPSE_RATIO_DESKTOP : BOTTOM_ELLIPSE_RATIO_MOBILE);
      setArticle1Section1BottomSpace(bottomSpaceHeight);
    }

    // 추가
    getBanners().then((result) => {
      if (result.length > 0) {
        setBanners(result);
      } else {
        setBanners(null);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomRef.current, setArticle1Section1BottomSpace, isDesktop, isTablet, isMobile, language]);

  const sliderRect = useMemo(() => sliderWrapperElement?.getBoundingClientRect(), [sliderWrapperElement]);
  const sliderWidth = useMemo(() => sliderRect?.width ?? 0, [sliderRect]);

  const bannerSlideWidth = useMemo(
    () => (isDesktop ? 1040 : isTablet ? 700 : 400),
      // eslint-disable-next-line
    [isDesktop, isTablet, isMobile, sliderWidth]
  );

  const isKoreanAndNotAppPage = useMemo(() => isKorean && !isWithAppPage, [isKorean, isWithAppPage]);

  return (
    <section className={`${style.section1} ${isWithAppPage ? style.withApp : ""}`} ref={section1Ref}>
      <div className={style.bgItemTopRight} />
      <div className={style.slider} ref={(el) => setSliderWrapperElement(el)}>
        {sliderWrapperElement && banners && (
          <BannerSlider
            slides={banners.map((banner, idx) => (
              <Banners
                key={idx}
                banners={banner}
                wrapperRefCurrent={sliderWrapperElement}
                isKoreanAndNotAppPage={isKoreanAndNotAppPage}
              />
            ))}
            slideWidth={bannerSlideWidth}
            slideGap={{
              totalGap: isDesktop ? 0 : 30,
              mainWidth: bannerSlideWidth,
            }}
          />
        )}
      </div>
      <div className={style.bottomEllipse} ref={bottomRef}>
        <div className={style.ellipseBgItem1} />
      </div>
    </section>
  );
}
