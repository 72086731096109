import React, { useMemo, useRef } from "react";

interface Props {
  aSlide: JSX.Element;
  aSlideIdx: number;
  currentSlideIdx: number;
  slideWidth: number;
  slideHeight: number;
  className: {
    current: string;
    notCurrent: string;
    show: string;
  };
}
export function ASlideOfReview({ aSlide, aSlideIdx, currentSlideIdx, slideWidth, slideHeight, className }: Props) {
  const aSlideRef = useRef<HTMLDivElement>(null);

  const isCurrentSlide = useMemo(() => currentSlideIdx === aSlideIdx, [currentSlideIdx, aSlideIdx]);

  const slideClassName = useMemo(() => {
    return `${isCurrentSlide ? className.current : className.notCurrent}
      ${className.show}`;
  }, [isCurrentSlide, className]);

  return (
    <div
      className={slideClassName}
      style={{
        width: `${slideWidth}px`,
        height: `${slideHeight}px`,
        cursor: "pointer",
      }}
      ref={aSlideRef}
    >
      {aSlide}
    </div>
  );
}
