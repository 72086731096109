import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../../util/sliceUtil";
import { ProductApi } from "../../api/ProductApi";
import { RootState } from "../index";
import { DisplayPosition, ProductWOItems } from "../../types/product";
import { ProductSearchCondition } from '../../types/page';

export interface ProductState extends SliceState {
  product?: ProductWOItems;
}

const initialState: ProductState = {
  status: Status.IDLE,
};

export const getTheLatestProductDetailAsync = createAsyncThunk(
  "product/getTheLatestProductDetail",
  ({condition, displayPosition}: { condition: ProductSearchCondition, displayPosition: DisplayPosition }) =>
      executePromise(ProductApi.getTheLatestProduct({condition, displayPosition}))
);

export const getProductImagesAsync = createAsyncThunk(
  "product/getProductImages",
  ({condition, displayPosition}: { condition: ProductSearchCondition, displayPosition: DisplayPosition }) =>
      executePromise(ProductApi.getProductImages({condition,displayPosition}))
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action: PayloadAction<ProductWOItems>) => {
      state.product = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getTheLatestProductDetailAsync);
  },
});

export const { setProduct } = productSlice.actions;

export const productStatus = (state: RootState) => state.product.status;
export const productDetail = (state: RootState) => state.product.product;

export default productSlice.reducer;
