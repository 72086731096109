import loginStyle from "../../../../../../assets/scss/common/modal/loginModal.module.scss";
import memberStyle from "../../../../../../assets/scss/sub/editMember.module.scss";
import React, {RefObject} from "react";
import {handleOnlyNumberChange} from "../../../../../../util/commonFunctions";
import {ValidateStatus} from "../hooks/useSignupValidator";
import {StyleRequestType} from "../../../../../../types/common";

interface Props{
    birthday: string;
    setBirthday: (_: string) => void;
    birthdayValidate: ValidateStatus;
    setBirthdayValidate: (_: ValidateStatus) => void;
    styleRequestType: StyleRequestType;
    birthdayRef?: RefObject<HTMLInputElement>;
}
export function SignupBirthdayField({birthday, setBirthday, birthdayValidate, setBirthdayValidate, styleRequestType, birthdayRef}:Props){
    const styles = styleRequestType === StyleRequestType.SIGN_UP ? loginStyle :  memberStyle;
    return (
        <div className={styles.selectWrapper}>
            <div className={styles.selectTitleWrapper}>
                <span className={styles.highLight}>[필수]</span>
                <span className={styles.titleText}>생년월일 (8자리)</span>
            </div>
            <div className={styles.selectTextFieldWrapper}>
                <input
                    ref={birthdayRef}
                    maxLength={8}
                    type="tel"
                    className={styles.selectText}
                    value={birthday}
                    onInvalid={(e) => e.preventDefault()}
                    onChange={(e) => {
                        handleOnlyNumberChange(e, setBirthday)
                        setBirthdayValidate(ValidateStatus.NONE)
                    }}
                    placeholder={"YYYYMMDD"}
                />
            </div>
            {birthdayValidate === ValidateStatus.UNFILLED &&
                (<span className={styles.validateText}>생년월일을 입력해 주세요.</span>)
            }
            {birthdayValidate === ValidateStatus.BELOW_REQUIRED_LENGTH &&
                (<span className={styles.validateText}>생년월일은 8자리 숫자로 입력해 주세요.</span>)
            }
            {birthdayValidate === ValidateStatus.INVALID_BIRTHDATE &&
                (<span className={styles.validateText}>유효한 생년월일이 아닙니다.</span>)
            }
        </div>
    )
}