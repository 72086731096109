import {useCallback, useState} from "react";
import {LeavePostDTO, SignPostDTO} from "../../../../../../types/signup";
import {leaveMember, signupMember} from "../../../../../../store/singup/signupSlice";
import {useAppDispatch} from "../../../../../../hooks/hooks";
import {LoginResponse} from "../../../../../../types/auth";
import {MainApi} from "../../../../../../api/MainApi";
import {CookieKeys} from "../../../../../../hooks/auth/AuthProvider";
import {useCookie} from "../../../../../../hooks/auth/useCookie";
import jwt_decode from "jwt-decode";

function getExpiresFromToken(token: string) {
    const { exp } = jwt_decode(token) as { exp: number };
    return new Date(exp * 1000).toUTCString();
}

export function useSignupMember() {
    const dispatch = useAppDispatch();
    const [, setIsLoggedIn] = useState<boolean>(false);
    const { setCookie } = useCookie();

    const onLoggedIn = useCallback(
        (accessToken: string, refreshToken: string) => {
            setIsLoggedIn(true);

            const accessTokenExpires = getExpiresFromToken(accessToken);
            const refreshTokenExpires = getExpiresFromToken(refreshToken);

            MainApi.getInstance().setToken(accessToken);

            setCookie(CookieKeys.ACCESS_TOKEN, accessToken, accessTokenExpires);
            setCookie(CookieKeys.REFRESH_TOKEN, refreshToken, refreshTokenExpires);
        },
        [setCookie]
    );

    const signups = useCallback(
        async (signup: SignPostDTO) => {
            const request: SignPostDTO = {
                email: signup.email,
                codePhone: signup.codePhone,
                phoneNumber: signup.phoneNumber,
                birthday: signup.birthday,
                name: signup.name,
                gender: signup.gender,
                address: signup.address,
                code: signup.code,
                nationality: signup.nationality,
                isAgreementEmailMarketingReceipt: signup.isAgreementEmailMarketingReceipt,
                isAgreementSMSMarketingReceipt: signup.isAgreementSMSMarketingReceipt,
                isAgreementPersonalInfoCollectionAndUsage: signup.isAgreementPersonalInfoCollectionAndUsage,
            };

            try {
                const result: LoginResponse= await dispatch(signupMember(request)).unwrap();
                onLoggedIn(result.accessToken, result.refreshToken);
                console.log('Signup successful');
            } catch (error) {
                console.error('Failed to signup:', error);
                throw error;
            }
        },
        [dispatch, onLoggedIn]
    );

    const leaveRequestMember = useCallback(
        async (leave: LeavePostDTO) => {
            const request: LeavePostDTO = {
                reason: leave.reason
            };

            try {
                console.log('leave start')
                await dispatch(leaveMember(request)).unwrap();
                // onLoggedIn(result.accessToken, result.refreshToken);
                console.log('leave successful');
            } catch (error) {
                console.error('Failed to signup:', error);
                throw error;
            }
        },
        [dispatch, onLoggedIn]
    )

    return { signups, leaveRequestMember };
}