import styled from "styled-components";
import variables from "../../../../assets/scss/variables.module.scss";
import { OrderDetail, OrderStatus } from "../../../../types/order";
import { useNavigate } from "react-router-dom";
import {breakpoints} from "../../../../theme/theme";

export const ActionButtonsWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 20px;
  
  ${breakpoints.tablet} {
    gap: 10px;
    margin-top: 50px;
  }
`;

export const PrimaryButton = styled.button`
  background-color: ${variables.mainColor};
  width: 300px;
  height: 84px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  color: white;
  ${breakpoints.tablet} {
      width : 180px;
      height: 66px;
      
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.24px;
  }
  
  @media (max-width: ${variables.breakpointTablet}) {
    height: 66px;
    font-size: 16px;
  }

  @media (max-width: ${variables.breakpointMobile}) {
    height: 66px;
    font-size: 16px;
  }
`;

export const OutlinePrimaryButton = styled(PrimaryButton)`
  border: 2px solid ${variables.mainColor};
  background-color: white;
  color: ${variables.mainColor};

  @media (max-width: ${variables.breakpointMobile}) {
    border-width: 1px;
  }
`;

export const LargePrimaryButton = styled(PrimaryButton)`
  width: 480px;
  
  ${breakpoints.tablet} {
    width: 400px;
  }
`;

interface Props {
  orderDetail: OrderDetail;
}

export function PaymentActions({ orderDetail }: Props) {
  const navigate = useNavigate();

  return (
    <ActionButtonsWrapper>
      {orderDetail.status === OrderStatus.AWAITING_PAYMENT && (
        <LargePrimaryButton onClick={() => navigate(-1)}>결제 재시도</LargePrimaryButton>
      )}
      {orderDetail.status === OrderStatus.FAIL && (
        <LargePrimaryButton onClick={() => navigate(`/purchase-voucher`)}>결제 재시도</LargePrimaryButton>
      )}
      {(orderDetail.status === OrderStatus.PAID || orderDetail.status === OrderStatus.AWAITING_DEPOSIT) && (
        <>
          <OutlinePrimaryButton
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/mypage");
            }}
          >
            My Page 이동
          </OutlinePrimaryButton>
          <PrimaryButton
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/download-test");
            }}
          >
            검사 다운로드
          </PrimaryButton>
        </>
      )}
    </ActionButtonsWrapper>
  );
}
