import { RefObject, useEffect } from "react";
import { subscribe, unsubscribe } from "../util/events";

interface Args {
  elementRefs: RefObject<any>[];
  doOnClickBg: () => void;
}
export function useClickEvent({ elementRefs, doOnClickBg }: Args) {
  useEffect(() => {
    const clickDocumentListener = (e: { detail: any }) => {
      if (elementRefs.every((ref) => ref.current !== e.detail)) {
        doOnClickBg();
      }
    };
    subscribe("clickDocument", clickDocumentListener);

    return () => {
      unsubscribe("clickDocument", clickDocumentListener);
    };
    // eslint-disable-next-line
  }, [elementRefs]);
}
