import React from "react";
import { Language } from "../../types/language";

export const LanguageContext = React.createContext({
  canLogin: false,
  isKorean: false,
  isNotKorean: false,
  language: Language.KO,
  setLang: (_: Language) => {},
  getLanguageStyle: (_: Record<string, string>): string => "",
  isLanguageLoaded: false,
});
