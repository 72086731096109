import { useUserAgent } from "../../hooks/hooks";

interface Props {
  isMobileBr?: boolean;
}

export function IsMobileBr({ isMobileBr = true }: Props) {
  const { isMobile } = useUserAgent();
  if (isMobile && isMobileBr) return <br />;
  if (!isMobile && !isMobileBr) return <br />;
  return <></>;
}
