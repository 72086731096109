import style from "./eventRegistererModal.module.scss";
import { RefObject } from "react";

interface Props {
  email: string;
  setEmail: (_: string) => void;
  emailRef: RefObject<HTMLInputElement>;
  invalidMessage?: string;
}
export function EventRegistererEmailField({ email, setEmail, emailRef, invalidMessage }: Props) {
  return (
    <div className={style.field}>
      <label className={style.label}>
        부모님 이메일 주소 <span className={style.light}>(선택)</span>
      </label>
      <input
        className={`${style.inputText} ${email ? style.inputted : ""}`}
        required={true}
        minLength={6}
        maxLength={60}
        type={"email"}
        placeholder={"이메일 주소를 입력해 주세요."}
        value={email}
        name={"email"}
        onChange={(e) => setEmail(e.target.value)}
        ref={emailRef}
      />
      {invalidMessage && <span className={style.invalidMessage}>{invalidMessage}</span>}
    </div>
  );
}
