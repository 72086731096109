import { TimeConverter } from "../../util/timeConverter";
import React from "react";

export interface TimeConverterContextType {
  timeConverter: TimeConverter;
}

export const TimeConverterContext = React.createContext<TimeConverterContextType>({
  timeConverter: TimeConverter.getInstance(),
});
