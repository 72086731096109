import React, { useEffect, useRef, useState } from "react";
import style from "./mainArticle1.module.scss";
import faces from "../../../assets/images/main/article1/section2/face_group.png";
import facesMobile from "../../../assets/images/main/article1/section2/face_group_mobile.png";
import children from "../../../assets/images/main/article1/section2/children.png";
import childrenMobile from "../../../assets/images/main/article1/section2/children_mobile.png";
import bgMobile from "../../../assets/images/main/article1/section2/children_background_mobile.png";
import bgTablet from "../../../assets/images/main/article1/section2/children_background_tablet.png";
import bgPC from "../../../assets/images/main/article1/section2/children_background_pc.png";
import { useUserAgent } from "../../../hooks/hooks";
import { Trans, useTranslation } from "react-i18next";
import { IsDesktopBr } from "../../common/IsDesktopBr";
import { IsTabletBr } from "../../common/IsTabletBr";
import { IsMobileBr } from "../../common/IsMobileBr";
import { StrongTextStyle } from "../../common/StyledCommonComponents";

export function Article1Section2() {
  const { t } = useTranslation(["parentsWish"]);
  const { isMobile, isDesktop, isTablet } = useUserAgent();
  const [showChildren, setShowChildren] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowChildren(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <section className={`${style.section2}`}>
      <img
        src={isDesktop ? bgPC : isTablet ? bgTablet : bgMobile}
        className={`${isDesktop ? style.section2Bg : isTablet ? style.section2BgTb : style.section2BgMobile}`}
      />
      <p className={`${style.section2TextArea}`}>
        <Trans
          t={t}
          i18nKey={ "section2.description"}
          components={[<StrongTextStyle></StrongTextStyle>, <IsDesktopBr></IsDesktopBr>, <IsTabletBr></IsTabletBr>, <IsMobileBr></IsMobileBr>]}
        />
      </p>
      <img
        ref={imgRef}
        src={isMobile ? childrenMobile : children}
        className={`${style.childImage} ${showChildren ? style.show : ""}`}
      />
    </section>
  );
}
