import { UserAgentProvider } from "./user_agent/UserAgentProvider";
import { theme } from "../theme/theme";
import React from "react";
import { PageProvider } from "./location/PageProvider";
import { MainScrollingProvider } from "./main_scrolling/MainScrollingProvider";
import { FooterProvider } from "./footer/FooterProvider";
import { ModalProvider } from "./modal/ModalProvider";
import { AuthProvider } from "./auth/AuthProvider";
import { LoaderProvider } from "./loader/LoaderProvider";
import { ScrollingEventProvider } from "./scroll_event/ScrollingEventProvider";
import { ThemeProvider } from "styled-components";
import { LanguageProvider } from "./language/LanguageProvider";
import { ChildrenProp } from "../types/common";
import { TimeConverterProvider } from "./timeconverter/TimeConverterProvider";
import { BannerProvider } from "./banner/BannerProvider";

export function HooksWrapper({ children }: ChildrenProp) {
  return (
    <UserAgentProvider>
      <ThemeProvider theme={theme}>
        <ScrollingEventProvider>
          <LoaderProvider>
            <AuthProvider>
              <ModalProvider>
                <LanguageProvider>
                  <TimeConverterProvider>
                    <FooterProvider>
                      <BannerProvider>
                        <MainScrollingProvider>
                          <PageProvider>{children}</PageProvider>
                        </MainScrollingProvider>
                      </BannerProvider>
                    </FooterProvider>
                  </TimeConverterProvider>
                </LanguageProvider>
              </ModalProvider>
            </AuthProvider>
          </LoaderProvider>
        </ScrollingEventProvider>
      </ThemeProvider>
    </UserAgentProvider>
  );
}
