import React, { useCallback, useState } from "react";

interface Args {
  moveRight?: (_: number) => void;
  moveLeft?: (_: number) => void;
  moveTop?: (_: number) => void;
  moveBottom?: (_: number) => void;
}

const DISTANCE_SENSITIVITY = 50;
export function useTouchEvent({ moveRight, moveLeft, moveTop, moveBottom }: Args) {
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    const { clientX, clientY } = e.targetTouches[0];
    setTouchStartX(clientX);
    setTouchStartY(clientY);
    setTouchEndX(clientX);
    setTouchEndY(clientY);
  }, []);

  const handleTouchMove = useCallback((e: React.TouchEvent) => {
    const { clientX, clientY } = e.targetTouches[0];
    setTouchEndX(clientX);
    setTouchEndY(clientY);
  }, []);

  const handleTouchMoveOnY = useCallback(
    (e: React.TouchEvent) => {
      const endY = e.targetTouches[0].clientY;
      setTouchEndY(endY);
      const distanceY = touchStartY - endY;
      if (distanceY > DISTANCE_SENSITIVITY && moveBottom) {
        moveBottom(distanceY);
        return;
      }
      if (distanceY < DISTANCE_SENSITIVITY * -1 && moveTop) {
        moveTop(distanceY);
        return;
      }
    },
    [setTouchEndY, touchStartY, moveBottom, moveTop]
  );

  const handleTouchEndOnX = useCallback(
    (_: React.TouchEvent) => {
      const distanceX = touchStartX - touchEndX;
      if (distanceX > DISTANCE_SENSITIVITY && moveRight) {
        moveRight(Math.abs(distanceX));
        return;
      }
      if (distanceX < DISTANCE_SENSITIVITY * -1 && moveLeft) {
        moveLeft(Math.abs(distanceX));
        return;
      }
    },
    [touchStartX, touchEndX, moveRight, moveLeft]
  );

  const handleTouchEndOnY = useCallback(() => {
    const distanceY = touchStartY - touchEndY;
    if (distanceY > DISTANCE_SENSITIVITY && moveBottom) {
      moveBottom(distanceY);
      return;
    }
    if (distanceY < DISTANCE_SENSITIVITY * -1 && moveTop) {
      moveTop(distanceY);
      return;
    }
  }, [touchStartY, touchEndY, moveTop, moveBottom]);

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEndOnX,
    handleTouchEndOnY,
    handleTouchMoveOnY,
  };
}
