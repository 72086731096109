import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import {
  descriptionState,
  selectedContentExecutedInfoIdxState,
  selectedProfileIdxState,
  titleState,
} from "../../../../../store/review/reviewModalSlice";
import { useCallback } from "react";
import { createReviewAsync } from "../../../../../store/review/reviewSlice";
import { ReviewPostDTO } from "../../../../../types/review";
import { AddedFile } from "../../../../../types/file";

export function useReviewCreator() {
  const dispatch = useAppDispatch();
  const selectedContentExecutedInfoIdx = useAppSelector(selectedContentExecutedInfoIdxState);
  const selectedProfileIdx = useAppSelector(selectedProfileIdxState);
  const title = useAppSelector(titleState);
  const description = useAppSelector(descriptionState);

  const createReview = useCallback(
    async (detailImages: AddedFile[]) => {
      const reviewPostDTO: ReviewPostDTO = {
        contentExecutedInfoIdx: selectedContentExecutedInfoIdx!,
        userProfileIdx: selectedProfileIdx!,
        oneLineReviewText: title,
        reviewText: description,
        detailImages: detailImages.map((it) => {
          return { file: it };
        }),
      };
      await dispatch(createReviewAsync(reviewPostDTO)).unwrap();
    },
    [selectedContentExecutedInfoIdx, selectedProfileIdx, title, description, dispatch]
  );

  return {
    createReview,
  };
}
