import React from "react";
import style from "./article2/mainArticle2.module.scss";
import { Article2Section1 } from "./article2/Article2Section1";
import { Article2Section2 } from "./article2/Article2Section2";

export function MainArticle2() {
  return (
    <article className={style.article2}>
      <Article2Section1 />
      <Article2Section2 />
    </article>
  );
}
