import styles from "../../../../../assets/scss/common/modal/loginModal.module.scss";
import React, {useEffect, useState} from "react";
import {SignupCellphoneField} from "./component/SignupCellphoneField";
import {SignupNationField} from "./component/SignupNationField";
import {SignupNameField} from "./component/SignupNameField";
import {SignupStatus} from "../../SignupModal";
import {useSignupValidator} from "./hooks/useSignupValidator";
import {useUserAgent} from "../../../../../hooks/hooks";
import {StyleRequestType} from "../../../../../types/common";

interface Props {
    loginStep: SignupStatus;
    changeSignupStep: (_: SignupStatus) => void;
    nationality: string;
    setNationality: (_: string) => void;
    name: string;
    phoneCode: string;
    phoneNumber: string;
    setName: (_: string) => void;
    setCellphone: (_: string) => void;
    setPhoneCode: (_: string) => void;
    setPhoneNumber: (_: string) => void;
}

export function SignupInfoStep1({
   loginStep,
   changeSignupStep,
   nationality,
   setNationality,
   name,
   phoneCode,
   phoneNumber,
   setName,
   setPhoneCode,
   setPhoneNumber,
}: Props){

    const [isHovered, setIsHovered] = useState(false);
    const [showNation, setShowNation] = useState(false);
    const [showCellphone, setShowCellphone] = useState(false);
    const {validate, nationValidate, setNationValidate,
        nameValidate, setNameValidate,
        phoneCodeValidate, setPhoneCodeValidate,
        phoneNumberValidate, setPhoneNumberValidate,
    } = useSignupValidator({
        loginStep ,nationality, name, phoneCode, phoneNumber
    });
    const {isDesktop} = useUserAgent();
    const styleRequestType = StyleRequestType.SIGN_UP

    const onClickNextStep = () =>{
        const result = validate();
        if (result) {
            changeSignupStep(SignupStatus.Sign_UP_INFO_02)
        }
    }


    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const nationField = document.getElementById('nationField');
        const cellphoneField = document.getElementById('cellphoneField');

        if (showNation && nationField && !nationField.contains(target)) {
            setShowNation(false);
        }
        if (showCellphone && cellphoneField && !cellphoneField.contains(target)) {
            setShowCellphone(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, [showNation, showCellphone]);

    return (
        <>
            <div className={styles.inputArea}>

                <div className={styles.desc}>
                    <span className={styles.accent}>추가 정보 입력(1)</span>
                    <br/>회원가입을 위해 아래 정보를 입력해 주세요.
                    <br/>만 14세 미만 회원은 보호자 가입 후 My Page에서 자녀 프로필을 등록해 주세요.
                </div>

                {/* 국적 */}
                <div id="nationField">
                    <SignupNationField nationality={nationality} setNationality={setNationality}
                                       showNation={showNation} setShowNation={setShowNation}
                                       nationValidate={nationValidate} setNationValidate={setNationValidate}
                                       styleRequestType={styleRequestType}
                    />
                </div>

                {/*  이름  */}
                <SignupNameField name={name} setName={setName} styleRequestType={styleRequestType}
                                 nameValidate={nameValidate} setNameValidate={setNameValidate}/>

                {/*  휴대폰 번호  */}
                <div id={"cellphoneField"}>
                    <SignupCellphoneField phoneCode={phoneCode} setPhoneCode={setPhoneCode}
                                          phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                                          showCellphone={showCellphone} setShowCellphone={setShowCellphone}
                                          phoneCodeValidate={phoneCodeValidate} setPhoneCodeValidate={setPhoneCodeValidate}
                                          phoneNumberValidate={phoneNumberValidate} setPhoneNumberValidate={setPhoneNumberValidate}
                                          styleRequestType={styleRequestType}
                    />
                </div>

                <div className={`${styles.buttonsWrapper} ${isDesktop ?styles.withMarginTop : ''}`}>
                    <button type="button"
                            className={`${styles.signUpNextButton} ${isHovered ? styles.signUpNextHoverButton : ''}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={onClickNextStep}
                    >
                        다음
                    </button>
                </div>
            </div>
        </>
    );
}