import { ChildrenProp } from "../../types/common";
import { MainScrollingContext, SlidingPartPassingStatus } from "./MainScrollingContext";
import { useCallback, useRef, useState } from "react";
import { showArea } from "../../util/commonFunctions";
import { MainArticle } from "../../components/common/Header";

export function MainScrollingProvider({ children }: ChildrenProp) {
  const [scrollingSection, setScrollingSection] = useState<MainArticle | null>(null);
  const [selectedSection, setSelectedSection] = useState<MainArticle | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [slidingPartPassingStatus, setSlidingPartPassingStatus] = useState(SlidingPartPassingStatus.IS_BEFORE_PASSING);
  const [article1Section1Height, setArticle1Section1Height] = useState(0);
  const [article1Section1BottomSpace, setArticle1Section1BottomSpace] = useState(0);
  const article1Element = useRef<HTMLElement>(null);
  const article2Element = useRef<HTMLElement>(null);
  const article3Element = useRef<HTMLElement>(null);
  const article4Element = useRef<HTMLElement>(null);

  const showOrHide = useCallback(() => showArea(headerHeight), [headerHeight]);

  const getArticleElement = useCallback(
    (article: MainArticle) => {
      const elements = {
        [MainArticle.ARTICLE1.toString()]: article1Element.current,
        [MainArticle.ARTICLE2.toString()]: article2Element.current,
        [MainArticle.ARTICLE3.toString()]: article3Element.current,
        [MainArticle.ARTICLE4.toString()]: article4Element.current,
      };
      return elements[article.toString()];
    },
    // eslint-disable-next-line
    [article1Element.current, article2Element.current, article3Element.current, article4Element.current]
  );

  const getArticleElementOffsetTop = useCallback(
    (article: MainArticle) => {
      const refinedHeaderHeight = headerHeight - 10;

      const offsetTops = {
        [MainArticle.ARTICLE1.toString()]:
          article1Element.current && article1Section1BottomSpace
            ? article1Element.current.offsetTop + article1Section1BottomSpace - refinedHeaderHeight
            : null,
        [MainArticle.ARTICLE2.toString()]: article2Element.current
          ? article2Element.current.offsetTop - refinedHeaderHeight
          : null,
        [MainArticle.ARTICLE3.toString()]: article3Element.current
          ? article3Element.current.offsetTop - refinedHeaderHeight
          : null,
        [MainArticle.ARTICLE4.toString()]: article4Element.current
          ? article4Element.current.offsetTop - 100 - refinedHeaderHeight
          : null,
      };

      return offsetTops[article.toString()];
    },
    // eslint-disable-next-line
    [
      article1Element.current,
      article2Element.current,
      article3Element.current,
      article4Element.current,
      article1Section1BottomSpace,
      headerHeight,
    ]
  );

  return (
    <MainScrollingContext.Provider
      value={{
        scrollingSection,
        setScrollingSection,
        selectedSection,
        setSelectedSection,
        headerHeight,
        setHeaderHeight,
        slidingPartPassingStatus,
        setSlidingPartPassingStatus,
        article1Section1Height,
        setArticle1Section1Height,
        showOrHide: showOrHide(),
        article1Section1BottomSpace,
        setArticle1Section1BottomSpace,
        getArticleElement,
        getArticleElementOffsetTop,
        article1Element,
        article2Element,
        article3Element,
        article4Element,
      }}
    >
      {children}
    </MainScrollingContext.Provider>
  );
}
