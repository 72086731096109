import { KPASSReviewVO, ReviewImageDTO } from "../../../../types/review";
import { SecuredUserProfile, TesterInfo } from "../../../../types/user";
import { ReviewProfileSection } from "./components/ReviewProfileSection";
import styled from "styled-components";
import backgroundCircle1 from "../../../../assets/images/modal/background/background_circle_1.svg";
import { ReviewContentSection } from "./components/ReviewContentSection";
import { BottomButtonSection } from "./components/BottomButtonSection";
import { breakpoints } from "../../../../theme/theme";
import { useCallback, useRef } from "react";

const ReviewDetailBodyWrapStyle = styled.div`
  position: relative;
  height: 100%;
  
  ${breakpoints.tablet} {
    gap: 20px;
    max-width: 100%;
  }
`;

const ReviewDetailBodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 80px 100px 80px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  gap: 40px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  
  ${breakpoints.tablet} {
    gap: 20px;
    max-width: 100%;
    padding: 0 40px;
  }

  ${breakpoints.mobile} {
    gap: 20px;
    max-width: 100%;
    padding: 0 20px 0 20px;
  }
`;

const BackgroundCircleStyle = styled.div`
  background-image: url(${backgroundCircle1});
  background-size: 309px 329px;
  width: 309px;
  height: 329px;
  position: absolute;
  top: -174px;
  right: 0;
  z-index: ${(props) => props.theme.zIndex.zIndexBgItem};
  
  ${breakpoints.tablet} {
    display: none;
  }

  ${breakpoints.mobile} {
    display: none;
  }
`;

const HrStyle = styled.hr`
  border: none;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  margin: 0;
`;

interface Props {
  review: KPASSReviewVO;
  reviewImages: ReviewImageDTO[];
  profile: SecuredUserProfile;
  testerInfo: TesterInfo;
  isBestReview: boolean;
  reload: (reviewIdx: number) => void;
}

export function ReviewDetail({ review, reviewImages, profile, testerInfo, isBestReview, reload }: Props) {
  const reviewDetailBodyRef = useRef<HTMLDivElement>(null);

  const onReload = useCallback(
    (reviewIdx: number) => {
      reviewDetailBodyRef.current?.scrollTo(0, 0);
      reload(reviewIdx);
    },
    [reviewDetailBodyRef, reload]
  );

  return (
    <ReviewDetailBodyWrapStyle>
      <BackgroundCircleStyle />

      <ReviewDetailBodyStyle ref={reviewDetailBodyRef}>
        <ReviewProfileSection
          reviewIdx={review.idx}
          profile={profile}
          isBestReview={isBestReview}
          testerInfo={testerInfo}
        />
        <HrStyle />
        <ReviewContentSection review={review} reviewImages={reviewImages} />
        <HrStyle />
        <BottomButtonSection reviewIdx={review.idx} isBestReview={isBestReview} reload={onReload} />
      </ReviewDetailBodyStyle>
    </ReviewDetailBodyWrapStyle>
  );
}
