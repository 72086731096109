import style from "./mainArticle2.module.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ArticleTitle } from "../../sub/ArticleTitle";
import earthImage from "../../../assets/images/main/article2/section1/earth.png";
import earthImageMobile from "../../../assets/images/main/article2/section1/earth_mobile.png";
import charactersImage from "../../../assets/images/main/article2/section1/characters.png";
import charactersImageMobile from "../../../assets/images/main/article2/section1/characters_mobile.png";
import starsImage from "../../../assets/images/main/article2/section1/twinkle.png";
import starsImageMobile from "../../../assets/images/main/article2/section1/twinkle_mobile.png";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { useLanguage, useMainScrolling, useScrollingEvent, useUserAgent } from "../../../hooks/hooks";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { Trans, useTranslation } from "react-i18next";
import { StandardizationEvidenceButton } from "../../StandardizationEvidenceButton";

export function Article2Section1() {
  const { t } = useTranslation(["kpass"]);
  const illustrationRef = useRef<HTMLDivElement>(null);
  const earthRef = useRef<HTMLDivElement>(null);
  const { isKorean } = useLanguage();
  const { article2Element, headerHeight } = useMainScrolling();
  const { scrollY } = useScrollingEvent();
  const { isDesktop } = useUserAgent();

  const [showIllustration, setShowIllustration] = useState(false);
  const [characterScaleRatio, setCharacterScaleRatio] = useState(0);

  useShowOrHide({ refCurrent: illustrationRef.current, setShow: setShowIllustration });

  const windowHeight = useMemo(() => window.innerHeight, []);
  const basisPoint = useMemo(() => windowHeight * 0.7, [windowHeight]);
  const basisHeight = useMemo(() => basisPoint - (headerHeight + 100), [basisPoint, headerHeight]);

  useEffect(() => {
    if (earthRef.current && basisHeight) {
      const { top } = earthRef.current.getBoundingClientRect();
      let ratio = (basisPoint - top) / ((windowHeight - headerHeight) * 0.5);

      if (ratio < 0) ratio = 0;
      if (1 < ratio) ratio = 1;
      setCharacterScaleRatio(ratio);
    }
    // eslint-disable-next-line
  }, [scrollY, earthRef.current, setCharacterScaleRatio, basisPoint, basisHeight, headerHeight]);

  return (
    <section className={style.section1} ref={article2Element}>
      <h2 className={"blind"}>{t("section1.heading2")}</h2>
      <div className={style.fullIllustration} ref={illustrationRef}>
        <div className={`${style.twinkleArea} ${showIllustration ? style.show : style.hide}`}>
          <img
            className={style.stars}
            src={isDesktop ? starsImage : starsImageMobile}
            alt={t("section1.starImageAlt")}
          />
        </div>
        <div className={style.earthArea} ref={earthRef}>
          <img
            className={`${style.earth} ${showIllustration ? style.show : style.hide}`}
            src={isDesktop ? earthImage : earthImageMobile}
            alt={t("section1.earthImageAlt")}
          />
          <div className={`${style.charactersWrapper}`} style={{ transform: `scale(${characterScaleRatio})` }}>
            <img
              className={style.characters}
              src={isDesktop ? charactersImage : charactersImageMobile}
              alt={t("section1.kpassCharacterAlt")}
            />
          </div>
        </div>
      </div>
      <div className={style.content}>
        <ArticleTitle title={t("section1.title")} className={style.title} />
        <div className={style.texts}>
          <p className={style.summary}>{t("section1.summary")}</p>
          <p className={style.description}>
            <Trans t={t} i18nKey="section1.description" components={[<StrongTextStyle />]} />
          </p>
        </div>
        {isKorean && (
          <div className={style.standardizationButton}>
            <StandardizationEvidenceButton />
          </div>
        )}
      </div>
    </section>
  );
}
