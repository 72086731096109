import style from "./mainArticle1.module.scss";
import quotationMarkLeft from "../../../assets/images/main/article1/section4/double_quotation_marks_left.png";
import quotationMarkRight from "../../../assets/images/main/article1/section4/double_quotation_marks_right.svg";
import roadCharacter1 from "../../../assets/images/main/article1/section4/road_character1.png";
import roadCharacter2 from "../../../assets/images/main/article1/section4/road_character2.png";
import roadCharacter3 from "../../../assets/images/main/article1/section4/road_character3.png";
import { useRef, useState } from "react";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { Trans, useTranslation } from "react-i18next";
import {useUserAgent} from "../../../hooks/hooks";

export function Article1Section4() {
  const { t } = useTranslation(["parentsWish"]);
  const mainPartRef = useRef<HTMLDivElement>(null);
  const roadItemRef = useRef<HTMLImageElement>(null);
  const [showMainPart, setShowMainPart] = useState(false);
  const [showRoadItem, setShowRoadItem] = useState(false);
  const {isTablet} = useUserAgent();

  useShowOrHide({ refCurrent: mainPartRef.current, setShow: setShowMainPart });
  useShowOrHide({ refCurrent: roadItemRef.current, setShow: setShowRoadItem });

  return (
    <section className={style.section4}>
      <h2 className={"blind"}>{t("section4.heading2")}</h2>
      <div className={style.imageArea} />
      <div className={style.textArea}>
        <div className={`${style.paragraphs}`} ref={mainPartRef}>
          <img
            className={`${style.openingQuotationMark} ${showMainPart ? style.show : style.hide}`}
            src={quotationMarkLeft}
            alt={t("section4.openDoubleQuoteImgAlt")}
          />
          <div className={`${style.innerTextArea} ${showMainPart ? style.show : style.hide}`}>
            <p className={style.backgroundText}>
              {t("section4.text1")}
              <br />
              {t("section4.text2")}
            </p>
            <p className={style.targetText}>
              <Trans t={t} i18nKey={"section4.text4"} components={[<StrongTextStyle />]} />
            </p>
          </div>
          <img
            className={`${style.closingQuotationMark} ${showMainPart ? style.show : style.hide}`}
            src={quotationMarkRight}
            alt={t("section4.closeDoubleQuoteImgAlt")}
          />
        </div>
          {!isTablet ?
                <div className={style.roadPic}>
                  <img
                      className={`${style.character1} ${showRoadItem ? style.show : style.hide}`}
                      src={roadCharacter1}
                      alt={t("section4.planetCharacterImgAlt")}
                      ref={roadItemRef}
                  />
                  <img
                      className={`${style.character2} ${showRoadItem ? style.show : style.hide}`}
                      src={roadCharacter2}
                      alt={t("section4.noteCharacterImgAlt")}
                  />
                  <img
                      className={`${style.character3} ${showRoadItem ? style.show : style.hide}`}
                      src={roadCharacter3}
                      alt={t("section4.pencilCharacterImgAlt")}
                  />
                </div>
              :
              <>
                <div className={style.roadPic}/>
                <div className={style.roadLeft}/>
                <div className={style.roadRight}/>
              </>
          }
      </div>
    </section>
  );
}
