import React from "react";

export interface ScrollingEventContextType {
  prevScrollY: number;
  scrollY: number;
  stopScrollYAndFix: (_: number) => void;
  stopScroll: () => void;
  restartScroll: () => void;
  isStoppedScrolling: boolean;
}

export const ScrollingEventContext = React.createContext<ScrollingEventContextType>(null!);
