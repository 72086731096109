import style from "../../../../assets/scss/common/modal/addConsultationModal.module.scss";
import React, {useEffect} from "react";
import {useConsultationGetter} from "../../../sub/consultation/hooks/useConsultationGetter";
import {ConsultationReservation} from "../../../../types/consultation";
import {ReservationSelectTimeOption} from "./ReservationSelectTimeOption";

interface Args{
    selectedDate: string;
    setSelectedDate: (_: string) => void;
    selectedTime: string;
    setSelectedTime: (_: string) => void;
    availableTimes: ConsultationReservation[];
    setAvailableTimes: (_: ConsultationReservation[]) => void
    setReservationIdx: (_:number) => void;
}
export function ReservationDateInput(
    {selectedDate, setSelectedDate,
        selectedTime, setSelectedTime,
        availableTimes, setAvailableTimes,
        setReservationIdx}:Args) {
    /**
     * 상담 정보 확인
     * 상담 예약 일자 영역
     */
    const {getAvailableReservation, reservationList} = useConsultationGetter();

    useEffect(() => {
        setAvailableTimes(reservationList)
        setSelectedTime("")
        // eslint-disable-next-line
    }, [reservationList]);

    // 날짜 예약
    const handleDateChange = (event: any) => {
        setSelectedDate(event.target.value);
        setSelectedTime("")
        setReservationIdx(0)
        getAvailableReservation({desiredDate:event.target.value}).then();
    };


    // 시간예약 & 예약 idx 저장
    const handleTimeSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const selectedTime = selectedOption.value;
        const selectedIdx = Number(selectedOption.dataset.idx);

        setSelectedTime(selectedTime);
        setReservationIdx(selectedIdx);
    };

    return (
        <>
            <div className={style.innerTextContainer}>
                <div className={style.infoContainer}>
                    <span className={style.infoTitleText}> 상담 정보 정보 확인</span>
                    <span className={style.innerTextDesc}>
                상담을 희망 하시는 날짜와 시간을 선택해 주세요. <br/>
                ※ 상담 희망 날짜는 신청일을 기준으로 3일 이후 부터 선택할 수 있습니다. <br/>
                ※ 상담 희망 시간은 10시부터 20시 사이로 선택할 수 있습니다. <br/>
                ※ 상담 희망 시간의 잔여석이 부족할 경우 날짜를 변경해 주세요. <br/>
                ※ 상담사 배정시 희망 날짜와 시간은 변경할 수 없습니다. 신중히 선택해 주세요.
                <br/>
              </span>

                    <div className={style.consultInputContainer}>
                        <span className={style.consultInputTitle}>상담 희망 날짜</span>
                        <input
                            onChange={handleDateChange}
                            type="date"
                            id="date-picker"
                            className={style.customDateInput}
                            min={new Date().toISOString().split('T')[0]} // 오늘 날짜 기준
                            value={selectedDate}
                        />
                    </div>
                    <div className={style.consultInputContainer}>
                        <span className={style.consultInputTitle}>상담 희망 시간</span>

                        <select
                            className={style.customDateInput}
                            value={selectedTime}
                            onChange={handleTimeSelection}
                        >
                            {selectedTime==="" && (
                                <option value="" disabled hidden>
                                    시간을 선택해 주세요
                                </option>
                            )}
                            <ReservationSelectTimeOption
                                reservations={availableTimes}
                                onTimeSelect={(time, idx) => {
                                    setSelectedTime(time);
                                    setReservationIdx(idx);
                                }}
                            />
                        </select>

                    </div>
                </div>
            </div>
        </>
    )
}