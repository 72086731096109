import styled from "styled-components";
import pictureSampleGood from "../../../../../../assets/images/kpass_review/picture_sample_good.svg";
import pictureSampleGoodMobile from "../../../../../../assets/images/kpass_review/picture_sample_good_mobile.svg";
import pictureSampleBad from "../../../../../../assets/images/kpass_review/picture_sample_bad.svg";
import pictureSampleBadMobile from "../../../../../../assets/images/kpass_review/picture_sample_bad_mobile.svg";
import { breakpoints } from "../../../../../../theme/theme";
import { useUserAgent } from "../../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";

const PictureSampleContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  
  ${breakpoints.tablet} {
    flex-direction: column;
    gap: 20px;
  }

  ${breakpoints.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

const PictureSampleStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 440px;
  height: 100px;

  font-size: 16px;
  line-height: 150%;

  .title {
    font-weight: bold;
  }

  > img {
    width: 130px;
    height: 100px;
  }
  
  ${breakpoints.tablet} {
    width: 100%;
    height: unset;
    font-size: 14px;
    align-items: start;
    gap: 10px;

    > img {
      width: 40px;
      height: 50px;
    }
  }

  ${breakpoints.mobile} {
    width: 100%;
    height: unset;
    font-size: 14px;
    align-items: start;
    gap: 10px;

    > img {
      width: 40px;
      height: 50px;
    }
  }
`;

const DescriptionStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-left: 14px;

  ul {
    margin-left: 14px;

    &,
    li {
      list-style: "- " outside;
    }
  }
  
  ${breakpoints.tablet} {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.21px;
    margin-left: 10px;
  }

  ${breakpoints.mobile} {
    margin-left: 10px;
  }
`;

const GoodPictureSampleStyle = styled(PictureSampleStyle)`
  color: ${(props) => props.theme.color.cFFEB38};
`;

const BadPictureSampleStyle = styled(PictureSampleStyle)`
  color: ${(props) => props.theme.color.white};
`;

export function PictureSample() {
  const { t } = useTranslation(["reviewModal"]);
  const { isDesktop } = useUserAgent();

  return (
    <PictureSampleContainerStyle>
      <GoodPictureSampleStyle>
        <img src={isDesktop ? pictureSampleGood : pictureSampleGoodMobile} alt="good-sample" />
        <DescriptionStyle>
          <p className={"title"}>{t("picturesGoodTitle")}</p>
          <ul>
            <li>
              <span>{t("picturesGoodItem1")}</span>
            </li>
            <li>
              <span>{t("picturesGoodItem2")}</span>
            </li>
          </ul>
        </DescriptionStyle>
      </GoodPictureSampleStyle>
      <BadPictureSampleStyle>
        <img src={isDesktop ? pictureSampleBad : pictureSampleBadMobile} alt="bad-sample" />
        <DescriptionStyle>
          <p className={"title"}>{t("picturesBadTitle")}</p>
          <ul>
            <li>
              <span>{t("picturesBadItem1")}</span>
            </li>
            <li>
              <span>{t("picturesBadItem2")}</span>
            </li>
            <li>
              <span>{t("picturesBadItem3")}</span>
            </li>
          </ul>
        </DescriptionStyle>
      </BadPictureSampleStyle>
    </PictureSampleContainerStyle>
  );
}
