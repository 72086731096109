import { useCallback } from "react";
import { KPASSReviewListDTO, ReviewListVO } from "../review";
import { PageResponse } from "../page";

export function useReviewListVOType() {
  const toReviewListVOFromDto = useCallback(
    (dto: KPASSReviewListDTO): ReviewListVO => ({
      ...dto,
      brainTypeString: "",
    }),
    []
  );

  const toReviewListVOPageableListFromDto = useCallback(
    (dto: PageResponse<KPASSReviewListDTO>) => {
      return {
        ...dto,
        content: dto.content.map((dto) => toReviewListVOFromDto(dto)),
      };
    },
    [toReviewListVOFromDto]
  );

  return {
    toReviewListVOFromDto,
    toReviewListVOPageableListFromDto,
  };
}
