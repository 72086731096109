import React from "react";
import style from "./article1/mainArticle1.module.scss";
import { Article1Section1 } from "./article1/Article1Section1";
import { Article1Section3 } from "./article1/Article1Section3";
import { Article1Section4 } from "./article1/Article1Section4";
import { Article1Section2 } from "./article1/Article1Section2";
import { Article1Press } from "./article1/Article1Press";

export function MainArticle1() {
  return (
    <article className={style.article1}>
      <Article1Section1 />
      <Article1Press />
      <Article1Section2 />
      <Article1Section3 />
      <Article1Section4 />
    </article>
  );
}
