import { useCallback } from "react";
import { useAppSelector } from "../../../../../hooks/hooks";
import { bestReviewsState } from "../../../../../store/review/reviewSlice";
import { ReviewTheme, useBestReviewTheme } from "../../../../main/article4/review/useBestReviewTheme";

export function useGetBestReviewTheme() {
  const bestReviews = useAppSelector(bestReviewsState);
  const theme = useBestReviewTheme();

  return useCallback(
    (reviewIdx: number): ReviewTheme | undefined => {
      if (bestReviews === undefined) return undefined;
      const idx = bestReviews.findIndex((it) => it.idx === reviewIdx);
      if (idx === -1) return undefined;

      return theme[idx];
    },
    [bestReviews, theme]
  );
}
