import style from "./review.module.scss";
import { StrongTextStyle } from "../../../common/StyledCommonComponents";
import { Splitter } from "./Splitter";
import { ReviewTheme } from "./useBestReviewTheme";
import { useEffect, useRef } from "react";
import { BrainType, ReviewListVO } from "../../../../types/review";
import { useTimeConverter, useUserAgent } from "../../../../hooks/hooks";
import { useBrainType } from "../../../../types/hooks/useBrainType";
import { useTranslation } from "react-i18next";
import { useGenderType } from "../../../../types/hooks/useGenderType";

interface props {
  theme: ReviewTheme;
  bestReview: ReviewListVO;
  onClickReview: (reviewIdx: number) => void;
}

const CONTENT_FONT_SIZE = 16;
const CONTENT_LINE_HEIGHT = 1.5;
const CONTENT_LINE_SPACE = CONTENT_FONT_SIZE * CONTENT_LINE_HEIGHT - CONTENT_FONT_SIZE;
const CONTENT_HEIGHT_DESKTOP = 250;
const CONTENT_HEIGHT_MOBILE = 240;
const CONTENT_FLEX_GAP = 10;

export function BestReviewPreview({ theme, bestReview, onClickReview }: props) {
  const { t } = useTranslation("subRealReview");
  const { isDesktop } = useUserAgent();
  const titleRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLParagraphElement>(null);
  const { timeConverter } = useTimeConverter();
  const { toStringFromBrainType } = useBrainType();
  const { toStringFromGender } = useGenderType();

  useEffect(() => {
    if (contentRef.current) {
      const { height: contentHeight } = contentRef.current.getBoundingClientRect();
      contentRef.current.style.webkitLineClamp = `${Math.floor(
        (contentHeight + CONTENT_LINE_SPACE / 2) / (CONTENT_FONT_SIZE * CONTENT_LINE_HEIGHT)
      )}`;
    }
    // eslint-disable-next-line
  }, [isDesktop, contentRef.current]);

  return (
    <div
      className={style.wrapper}
      style={{ backgroundColor: theme.bgColor }}
      onClick={(e) => {
        e.stopPropagation();
        onClickReview(bestReview.idx);
      }}
    >
      <div className={style.writer}>
        <div className={style.character}>
          <img src={theme.character} alt={"character"} />
        </div>
        <div className={style.writerInfo}>
          <div className={style.name}>
            <StrongTextStyle>{bestReview.name}</StrongTextStyle>님
          </div>
          <div className={style.otherInfo}>
            {bestReview.region} <Splitter /> {bestReview.age}
            {t("age")} <Splitter /> {toStringFromGender(bestReview.gender)}{" "}
            {bestReview.brainType !== BrainType.UNKNOWN ? (
              <>
                <Splitter /> {toStringFromBrainType(bestReview.brainType)}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className={style.content}>
        <div
          className={style.texts}
          style={{
            gap: `${CONTENT_FLEX_GAP}px`,
            height: `${isDesktop ? CONTENT_HEIGHT_DESKTOP : CONTENT_HEIGHT_MOBILE}px`,
          }}
        >
          <div className={style.title} ref={titleRef}>
            {bestReview.oneLineReviewText}
          </div>
          <p
            className={style.detail}
            ref={contentRef}
            style={{ fontSize: `${CONTENT_FONT_SIZE}px`, lineHeight: CONTENT_LINE_HEIGHT }}
          >
            {bestReview.reviewText}
          </p>
        </div>
      </div>
      {bestReview.thumbnailUrl ? (
        <div className={style.thumbnail} style={{ backgroundImage: `url("${bestReview.thumbnailUrl}")` }}></div>
      ) : (
        <></>
      )}
      <div className={style.writtenAt}>{timeConverter.convertToLocalDate(bestReview.writtenAt)}</div>
    </div>
  );
}
