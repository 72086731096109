import style from "../../../../assets/scss/common/modal/addChildModal.module.scss";
import React from "react";
import {InitialProfileInfoDTO, ProfileErrorDTO} from "../../../../types/user";
import useValidation from "../../../../hooks/useValidation";

interface Args{
    profileInfo: InitialProfileInfoDTO;
    setProfileInfo: React.Dispatch<React.SetStateAction<InitialProfileInfoDTO>>;
    errors: ProfileErrorDTO;
    setErrors: React.Dispatch<React.SetStateAction<ProfileErrorDTO>>;
}
export function ChildGenderField(
    {profileInfo, setProfileInfo, errors, setErrors}:Args)
{

    const {validateGender} = useValidation();

    const handleGenderSelection = (gender: string) => {
        setProfileInfo((prev: InitialProfileInfoDTO) => ({ ...prev, gender }));
        setErrors((prev: ProfileErrorDTO) => ({
            ...prev,
            gender: validateGender(gender),
        }));
    };

    return (
        <div className={style.infoContainer}>
              <span className={style.infoText}>
                <span className={style.requireText}>[필수]</span> 성별
              </span>
            <div className={style.genderButtons}>
                <button
                    className={`${style.genderBtn} ${profileInfo.gender === "MALE" ? style.selectedGender : ""}`}
                    onClick={() => handleGenderSelection("MALE")}
                >
                    남자
                </button>
                <button
                    className={`${style.genderBtn} ${profileInfo.gender === "FEMALE" ? style.selectedGender : ""}`}
                    onClick={() => handleGenderSelection("FEMALE")}
                >
                    여자
                </button>
            </div>
            {errors.gender && <div className={style.errorText}>{errors.gender}</div>}
        </div>
    )
}