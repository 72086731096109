import {useCallback, useState} from "react";
import {ValidateStatus} from "../modal/login/signup/hooks/useSignupValidator";

interface Args{
    desiredDate: string;
    desiredTime: string;
    inquiry:string
}
export function useConsultationReservationValidator({desiredDate, desiredTime, inquiry}:Args) {
    const [isDateValidate, setIsDateValidate] = useState(ValidateStatus.NONE)
    const [isTimeValidate, setIsTimeValidate] = useState(ValidateStatus.NONE)
    const [isInquiryValidate, setInquiryValidate] = useState(ValidateStatus.NONE)

    const validate = useCallback(() => {
        let validate = true;

        if (desiredDate === '연도-월-일') {
            validate = false;
            setIsDateValidate(ValidateStatus.UNFILLED);
        }

        if (desiredTime === "") {
            validate = false;
            setIsTimeValidate(ValidateStatus.UNFILLED);
        }

        if (inquiry.length > 1000 || inquiry.length < 2) {
            validate = false;
            setInquiryValidate(ValidateStatus.LANGUAGE_MISMATCH)
        }

        return validate;
        // eslint-disable-next-line
    }, [desiredDate, desiredTime, inquiry, isInquiryValidate, isTimeValidate, isDateValidate]);

    return{
        isDateValidate, setIsDateValidate,
        isTimeValidate, setIsTimeValidate,
        isInquiryValidate, setInquiryValidate, validate
    }
}