import { useSignedInUserIdx } from "../../../../hooks/useSignedInUserIdx";
import React, { useCallback, useMemo } from "react";
import { useReviewDeleter } from "../hooks/useReviewDeleter";
import { useModal } from "../../../../hooks/hooks";
import { useReviewsGetter } from "../hooks/useReviewsGetter";
import { useBestReviewsGetter } from "../../../../hooks/review/useBestReviewsGetter";
import { ReviewListVO } from "../../../../types/review";

interface Props {
  wrapperClassName: string;
  editButtonClassName: string;
  deleteButtonClassName: string;
  userIdx: number;
  reviewIdx: number;
}
export function EditableButtons({
  wrapperClassName,
  editButtonClassName,
  deleteButtonClassName,
  userIdx,
  reviewIdx,
}: Props) {
  const signedInUserIdx = useSignedInUserIdx();
  const { deleteReview } = useReviewDeleter();
  const { openConfirmModal, openAlertModal, openReviewEditModal } = useModal();
  const { getReviews } = useReviewsGetter();
  const isSignedInUser = useMemo(() => userIdx && userIdx === signedInUserIdx, [userIdx, signedInUserIdx]);
  const { getBestReviews } = useBestReviewsGetter();
  const isBestReview = useCallback(
    (bestReviews: ReviewListVO[]) => bestReviews?.some((review) => review.idx === reviewIdx) ?? false,
    [reviewIdx]
  );
  const openBestReviewNotice = useCallback(
    () =>
      openAlertModal({
        title: "안내",
        desc:
          "해당 후기는 베스트 후기로 게시되어 있습니다.\n" +
          "베스트 후기 게시 기간 동안 수정/삭제가 불가능합니다.\n" +
          "소중한 후기 감사합니다 :)",
      }),
    [openAlertModal]
  );

  const onClickEditButton = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      const bestReviewVOs = await getBestReviews();
      if (isBestReview(bestReviewVOs)) {
        openBestReviewNotice();
        return;
      }

      openReviewEditModal(reviewIdx);
    },
    [openReviewEditModal, reviewIdx, getBestReviews, isBestReview, openBestReviewNotice]
  );

  const onClickDeleteButton = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      const bestReviewVOs = await getBestReviews();
      if (isBestReview(bestReviewVOs)) {
        openBestReviewNotice();
        return;
      }

      openConfirmModal(
        {
          title: "후기 삭제",
          desc: "작성하신 후기를 정말 삭제하시겠어요?",
        },
        () => {
          deleteReview(reviewIdx)
            .then(() => {
              openAlertModal({
                title: "삭제 완료",
                desc: "후기가 삭제되었습니다.\n후기는 언제든지 재작성 가능합니다.",
              });
              getReviews({}).then();
            })
            .catch(() => openAlertModal(null));
        }
      );
    },
    [
      getReviews,
      getBestReviews,
      openConfirmModal,
      deleteReview,
      reviewIdx,
      openAlertModal,
      isBestReview,
      openBestReviewNotice,
    ]
  );

  return (
    <>
      {isSignedInUser ? (
        <div className={wrapperClassName}>
          <button className={editButtonClassName} onClick={onClickEditButton}>
            수정
          </button>
          <button className={deleteButtonClassName} onClick={onClickDeleteButton}>
            삭제
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
