import {SliderDirection, useSlider} from "../../../common/slider/useSlider";
import React, {useCallback} from "react";
import {useUserAgent} from "../../../../hooks/hooks";
import style from "../../../common/slider/slider.module.scss";
import bannerStyle from "./banner.module.scss";
import {SlideGap} from "../../../common/slider/slider";
import {SliderBullets} from "../../../common/slider/SliderBullets";

interface Props {
  slides: JSX.Element[];
  slideWidth: number;
  slideGap: SlideGap;
}

export function BannerSlider({ slides, slideWidth, slideGap }: Props) {
  const { isMobile } = useUserAgent();
  const { handleTouch, moveSlide, translateXValue, currentSlideIdx, pauseAutoPlay, direction } = useSlider({
    slides,
    slideWidth,
    delay: 0,
    slideGap,
    blockSwipeOnTransitioning: false,
  });

  const moveNext = useCallback(() => {
    pauseAutoPlay();
    moveSlide(SliderDirection.NEXT);
  }, [pauseAutoPlay, moveSlide]);

  const movePrev = useCallback(() => {
    pauseAutoPlay();
    moveSlide(SliderDirection.PREV);
  }, [pauseAutoPlay, moveSlide]);

  /* eslint-disable */
  const ASlideComponent = useCallback(
    ({ Slide }: { Slide: JSX.Element }) => (
      <div
        className={direction === SliderDirection.NEXT ? bannerStyle.fromRight : bannerStyle.fromLeft}
        style={{
          width: `${slideWidth}px`,
          height: "100%",
        }}
      >
        <Slide.type
          {...Slide.props}
          {...{
            movePrev: () => {
              pauseAutoPlay();
              moveSlide(SliderDirection.PREV);
            },
            moveNext: () => {
              pauseAutoPlay();
              moveSlide(SliderDirection.NEXT);
            },
          }}
        />
      </div>
    ),
    [direction, slideWidth, pauseAutoPlay, moveSlide]
  );

  return (
    <div
      style={{ width: !isMobile ? "100%" : slideGap.mainWidth }}
      className={`${style.wrapper} ${style.bulletsWrapper}`}
    >
      {!isMobile && <button className={`${style.directionButton} ${style.prev}`} onClick={movePrev}></button>}
      <div
        className={style.slideContent}
        style={{
          width: `${slideWidth}px`,
        }}
        onTouchStart={handleTouch.start}
        onTouchMove={handleTouch.move}
        onTouchEnd={handleTouch.end}
      >
        <div
          className={style.slides}
          style={{
            height: "max-content",
            transform: `translateX(${translateXValue}px)`,
            gap: `${slideGap.totalGap}px`,
          }}
        >
          {slides.map((aSlide, idx) => (
            <ASlideComponent key={`banner-group0-${idx}`} Slide={aSlide} />
          ))}
          {slides.map((aSlide, idx) => (
            <ASlideComponent key={`banner-group1-${idx}`} Slide={aSlide} />
          ))}
          {slides.map((aSlide, idx) => (
            <ASlideComponent key={`banner-7group2-${idx}`} Slide={aSlide} />
          ))}
        </div>
      </div>
      {!isMobile && <button className={`${style.directionButton} ${style.next}`} onClick={moveNext}></button>}
      {isMobile && <SliderBullets slidersLength={slides.length} currentSlideIdx={currentSlideIdx} />}
    </div>
  );
}
