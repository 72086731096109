import { useAppDispatch, useLanguage } from "../hooks";
import { useCallback } from "react";
import { PageResponse } from "../../types/page";
import { BannerDTO, BannerType, WebsiteType, BannerVO } from "../../types/hooks/banner";
import { getBannersAsync } from "../../store/banner/bannerSlice";

export function useBannerGetter() {
  const dispatch = useAppDispatch();

  const { language } = useLanguage();
  const getBanners = useCallback(async (): Promise<BannerVO[]> => {
    try {
      const result: PageResponse<BannerDTO> = await dispatch(
        getBannersAsync({
          size: 1000,
          page: 0,
          websiteType: WebsiteType.KPASS,
          type: convertLanguageToBannerType(language),
        })
      ).unwrap();
      return result.content.map((dto) => dto as BannerVO);
    } catch (e) {
      console.error(e);
      throw new Error("배너 목록 조회 에러");
    }
  }, [dispatch, language]);

    return {
        getBanners,
    };
    function convertLanguageToBannerType(languages: string) {
        switch (languages) {
            case "ko":
                return BannerType.KOREAN;
            case "en":
                return BannerType.ENGLISH;
            case "ru":
                return BannerType.RUSSIA;
            case "zh":
                return BannerType.TAIWAN;
        }
    }
}
