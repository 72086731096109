import bgItem1Image from "../../assets/images/main_legacy/section2/bgItem1.svg";
import bgItem2Image from "../../assets/images/main_legacy/section2/bgItem2.svg";
import effect1Image from "../../assets/images/main_legacy/section2/effect1.svg";
import effect2Image from "../../assets/images/main_legacy/section2/effect2.svg";
import effect3Image from "../../assets/images/main_legacy/section2/effect3.svg";
import effect4Image from "../../assets/images/main_legacy/section2/effect4.svg";
import effect5Image from "../../assets/images/main_legacy/section2/effect5.svg";
import style from "../../assets/scss/main_legacy/section2.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { MainLegacyScrollContext } from "./hooks/MainLegacyScrollContext";
import { ArticleTitle } from "../sub/ArticleTitle";
import { useTranslation } from "react-i18next";
import { useLanguage, useUserAgent } from "../../hooks/hooks";

export function Section2({ menuName }) {
  const { t } = useTranslation(["mainSection2"]);
  const { isDesktop } = useUserAgent();
  const [isShownEffects, setIsShownEffects] = useState(false);
  const { scrollY, selectedSection, setScrollingSection, showOrHide, isPassingHeaderBottom } =
    useContext(MainLegacyScrollContext);
  const effectsRef = useRef();
  const sectionRef = useRef();
  const { isNotKorean } = useLanguage();

  useEffect(() => {
    showOrHide(effectsRef.current, setIsShownEffects);

    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (sectionRect && isPassingHeaderBottom(sectionRect.top, sectionRect.bottom)) {
      setScrollingSection(menuName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    if (menuName === selectedSection) {
      sectionRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionRef, selectedSection]);

  return (
    <section className={style.section2} ref={sectionRef}>
      <article className={style.expectation}>
        <div className={style.bgItem1}>
          <img src={bgItem1Image} alt={t("bgItem1Alt")} />
        </div>
        <div className={style.bgItem2}>
          <img src={bgItem2Image} alt={t("bgItem2Alt")} />
        </div>
        <div className={style.content}>
          <h3>
            <ArticleTitle title={t("title")} className={`${style.title} ${isNotKorean ? style.ru : ""}`} />
          </h3>
          <p className={style.description}>{isDesktop ? t("desc.line1") : t("desc.line1Mobile")}</p>
          <ul className={`${style.effects} ${isShownEffects ? style.show : style.hide}`} ref={effectsRef}>
            <li className={`${style.item} ${style.wideMarginTop} ${isNotKorean ? style.ru : ""}`}>
              <img src={effect1Image} alt={t("effect1.alt")} />
              <h4 className={`${style.text} ${style.wideMarginTop}`}>{t("effect1.title")}</h4>
            </li>
            <li className={`${style.item} ${style.narrowPadding} ${isNotKorean ? style.ru : ""}`}>
              <img src={effect2Image} alt={t("effect2.alt")} />
              <h4 className={`${style.text} ${style.narrowMarginTop}`}>{t("effect2.title")}</h4>
            </li>
            <li className={`${style.item} ${isNotKorean ? style.ru : ""}`}>
              <img src={effect3Image} alt={t("effect3.alt")} />
              <h4 className={`${style.text} ${style.wideMarginTop}`}>{t("effect3.title")}</h4>
            </li>
            <li className={`${style.item} ${isNotKorean ? style.ru : ""}`}>
              <img src={effect4Image} alt={t("effect4.alt")} />
              <h4 className={`${style.text} ${style.wideMarginTop}`}>{t("effect4.title")}</h4>
            </li>
            <li className={`${style.item} ${isNotKorean ? style.ru : ""}`}>
              <img src={effect5Image} alt={t("effect5.alt")} />
              <h4 className={`${style.text} ${style.narrowMarginTop}`}>{t("effect5.title")}</h4>
            </li>
          </ul>
        </div>
      </article>
    </section>
  );
}
