import {useModal} from "../../../hooks/hooks";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FloatingBtn} from "../../common/button/FloatingBtn";
import style from "./review.module.scss";
import {useReviewsGetter} from "./hooks/useReviewsGetter";
import {Trans, useTranslation} from "react-i18next";
import {useProfileGetter} from "../../../hooks/profile/useProfileGetter";
import {useTesterInfoGetter} from "../../common/modal/review/hooks/useTesterInfoGetter";
import {KPASSReviewVO, ReviewImageDTO} from "../../../types/review";
import {SecuredUserProfile, TesterInfo} from "../../../types/user";
import {LightTextStyle} from "../../common/StyledCommonComponents";
import {useShowOrHide} from "../../../hooks/useShowOrHide";
import {useParams} from "react-router-dom";
import {useBestReviewsGetter} from "../../../hooks/review/useBestReviewsGetter";
import {ReviewWebDetail} from "./detail/ReviewWebDetail";

export function ReviewWebDetailWrapper() {
  const { t } = useTranslation(["reviewModal"]);
  const { closeModal, openAlertModal } = useModal();
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const [showMainTitle, setShowMainTitle] = useState(false);
  const { getReview, getReviewImages } = useReviewsGetter();
  const { getSecuredProfile } = useProfileGetter();
  const { getTesterInfoByReviewIdx } = useTesterInfoGetter();
  const [reviewVO, setReviewVO] = useState<KPASSReviewVO | undefined>();
  const [reviewImages, setReviewImages] = useState<ReviewImageDTO[] | undefined>();
  const [profile, setProfile] = useState<SecuredUserProfile | undefined>();
  const [testerInfo, setTesterInfo] = useState<TesterInfo | undefined>();

  const {bestReviews} = useBestReviewsGetter();
  const [isBestReview, setIsBestReview] = useState(false);
  const { id } = useParams();

  const [reviewIdx, setReviewIdx] = useState(0);

  useShowOrHide({ refCurrent: mainTitleRef.current, setShow: setShowMainTitle });


  //


  useMemo(() =>{
    if (typeof id === "string") {
     setReviewIdx(parseInt(id,10))
    }
    // eslint-disable-next-line
  },[])

  const loadReview = useCallback(
      async (reviewIdx: number) => {
        try {

          const reviewVO = await getReview(reviewIdx);
          const reviewImages = await getReviewImages(reviewIdx);
          const profile = await getSecuredProfile(reviewIdx);
          const testerInfo = await getTesterInfoByReviewIdx(reviewIdx);

          setReviewVO(reviewVO);
          setReviewImages(reviewImages);
          setProfile(profile);
          setTesterInfo(testerInfo);
        } catch (e) {
          console.error(e);
          openAlertModal({
            title: t("errorModalTitle"),
            desc: t("loadReviewError"),
          });
          closeModal();
        }
      },
      [
        getReview,
        getReviewImages,
        setReviewVO,
        setReviewImages,
        openAlertModal,
        getSecuredProfile,
        setProfile,
        closeModal,
        getTesterInfoByReviewIdx,
        t,
      ]
  );



  useEffect(() => {
    const fetchBestReviews = async () => {
      try {
        if (bestReviews !== undefined) {
          const foundBestReview = bestReviews.some(review => review.idx);
          setIsBestReview(foundBestReview);
        }
      } catch (error) {
        console.error('Error fetching best reviews:', error);
      }
    };

    fetchBestReviews().then();
    // eslint-disable-next-line
  }, [setReviewIdx]);


  useEffect(() =>{
    loadReview(reviewIdx).then()
    // eslint-disable-next-line
  },[])

  return (
      <div className={style.mainWrapper}>
        <main className={style.main}>
          <FloatingBtn/>

          <div className={style.titleWrapper}>
            <div className={style.titleEffect}></div>
            <div className={style.title}>
              <h2 className={`${style.text} ${showMainTitle ? style.show : style.hide}`} ref={mainTitleRef}>
                <Trans t={t} i18nKey={"detailModalTitle"} components={[<LightTextStyle></LightTextStyle>]}/>
              </h2>
            </div>
          </div>
          {reviewVO && profile && testerInfo && reviewImages &&
              <ReviewWebDetail
                  review={reviewVO}
                  reviewImages={reviewImages}
                  profile={profile}
                  testerInfo={testerInfo}
                  reload={loadReview}
                  isBestReview={isBestReview}
              />
          }
        </main>
      </div>
  );
}
