export default function useValidateBirthday() {

    const validateBirthday = (e: string) => {

        // 정규식을 통해 값이 8자리 숫자인지 확인
        if (isValidDate(e)) {
            return true;
        } else {
            return false;
        }
    };

    // 유효한 날짜인지 확인하는 함수
    const isValidDate = (dateString:string) => {
        // 연도, 월, 일 추출
        const year = parseInt(dateString.substring(0, 4));
        const month = parseInt(dateString.substring(4, 6));
        const day = parseInt(dateString.substring(6, 8));

        // 월 범위 확인
        if (month < 1 || month > 12) {
            return false;
        }

        // 날짜 유효성 확인 (월별 일 수 체크)
        const daysInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (day < 1 || day > daysInMonth[month - 1]) {
            return false;
        }

        const today = new Date();
        const birthDate = new Date(year, month - 1, day);
        if (birthDate > today) {
            return false;
        }

        return true;
    };

    // 윤년 확인 함수
    const isLeapYear = (year:number) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };
    return{validateBirthday}
};