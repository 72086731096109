import styled from "styled-components";
import { UserProfileWithReviewStatusVO } from "../../../../../../types/review";
import { useCallback, useEffect } from "react";
import {
  setSelectedProfileIdx,
  selectedProfileIdxState,
  reviewModalModeState,
  ReviewModalMode,
} from "../../../../../../store/review/reviewModalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooks";
import { useReviewStateHook } from "../../hooks/useReviewStateHook";
import { breakpoints } from "../../../../../../theme/theme";
import { CheckboxStyle } from "../common/StyledComponents";
import { useTranslation } from "react-i18next";

const ProfileStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: start;

  width: 330px;
  min-width: 330px;
  padding: 20px 30px;

  background-color: ${(props) => props.theme.color.deepMainColor};
  border-radius: 20px;
  cursor: pointer;

  &.disabled {
    cursor: unset;
  }

  &.selected {
    background-color: ${(props) => props.theme.color.kpDeepBlue};
  }
  
  ${breakpoints.tablet} {
    padding: 16px;
    width: 206px;
    min-width: 206px;
    border-radius: 10px;
  }

  ${breakpoints.mobile} {
    padding: 16px;
    width: 206px;
    min-width: 206px;
    border-radius: 10px;
  }
`;

const ProfileTextContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc(100% - 24px - 15px);

  ${breakpoints.mobile} {
    gap: 4px;
  }
`;

const ProfileNameStyle = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-size: 24px;
  line-height: 140%;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  
  &.disabled {
    color ${(props) => props.theme.color.kpBlueLight};
  }
  
  ${breakpoints.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.24px;
  }

  ${breakpoints.mobile} {
    font-size: 16px;
  }
`;

const WritableTextStyle = styled.p`
  color: ${(props) => props.theme.color.kpBlueLight};
  font-size: 18px;
  line-height: 150%;
  b {
    color: ${(props) => props.theme.color.cFFEB38};
  }
  
  ${breakpoints.tablet} {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.21px;
  }

  ${breakpoints.mobile} {
    font-size: 14px;
  }
`;

interface Props {
  userProfileWithReviewStatusVO: UserProfileWithReviewStatusVO;
}

export function Profile({ userProfileWithReviewStatusVO }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const dispatch = useAppDispatch();
  const selectedProfileIdx = useAppSelector(selectedProfileIdxState);
  const reviewModalMode = useAppSelector(reviewModalModeState);
  const { isWritable, hasNoTest, isWrittenAllReview, writableCount } = useReviewStateHook();

  const getWritableText = useCallback(() => {
    if (hasNoTest(userProfileWithReviewStatusVO)) return <span>{t("profileNoTest")}</span>;
    else if (isWrittenAllReview(userProfileWithReviewStatusVO)) return <span>{t("profileWrittenAllReview")}</span>;

    return (
      <span>
        {t("profileWritableReview")}{" "}
        <b>
          {writableCount(userProfileWithReviewStatusVO)}
          {t("count")}
        </b>
      </span>
    );
  }, [hasNoTest, isWrittenAllReview, writableCount, userProfileWithReviewStatusVO, t]);

  const onClickCheckBox = useCallback(() => {
    if (!isWritable(userProfileWithReviewStatusVO)) return;

    dispatch(setSelectedProfileIdx(userProfileWithReviewStatusVO.profileIdx));
  }, [dispatch, userProfileWithReviewStatusVO, isWritable]);

  useEffect(() => {
    if (reviewModalMode === ReviewModalMode.EDIT)
      dispatch(setSelectedProfileIdx(userProfileWithReviewStatusVO.profileIdx));
  }, [reviewModalMode, dispatch, userProfileWithReviewStatusVO.profileIdx]);

  return (
    <ProfileStyle
      onClick={onClickCheckBox}
      className={
        `${isWritable(userProfileWithReviewStatusVO) ? "" : "disabled"} ` +
        `${selectedProfileIdx === userProfileWithReviewStatusVO.profileIdx ? "selected" : ""}`
      }
    >
      <CheckboxStyle
        className={
          `${isWritable(userProfileWithReviewStatusVO) ? "" : "disabled "}` +
          `${selectedProfileIdx === userProfileWithReviewStatusVO.profileIdx ? "checked" : ""}`
        }
      />
      <ProfileTextContainerStyle>
        {reviewModalMode === ReviewModalMode.EDIT ? (
          <ProfileNameStyle>{userProfileWithReviewStatusVO.userProfile.name}</ProfileNameStyle>
        ) : (
          <>
            <ProfileNameStyle className={`${isWritable(userProfileWithReviewStatusVO) ? "" : "disabled"}`}>
              {userProfileWithReviewStatusVO.userProfile.name}
            </ProfileNameStyle>
            <WritableTextStyle>{getWritableText()}</WritableTextStyle>
          </>
        )}
      </ProfileTextContainerStyle>
    </ProfileStyle>
  );
}
