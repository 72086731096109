import loginStyle from "../../../../../../assets/scss/common/modal/loginModal.module.scss";
import memberStyle from "../../../../../../assets/scss/sub/editMember.module.scss";
import React, {RefObject} from "react";
import {Address} from "../../../../../../types/user";
import {StyleRequestType} from "../../../../../../types/common";

interface Args{
    title: string;
    address: Address;
    setValue: (_:Address) => void;
    styleRequestType :StyleRequestType;
    addressRef?: RefObject<HTMLDivElement>;
    foreignAddressRef?: RefObject<HTMLDivElement>;
}

export function SignupForeignAddressField(
    {
        title, setValue, address, addressRef, foreignAddressRef,styleRequestType}:Args){

    const styles = styleRequestType === StyleRequestType.SIGN_UP ? loginStyle :  memberStyle;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setValue({
            ...address,
            [title.toLocaleLowerCase() === 'zip code' ? 'postalCode' : title.toLocaleLowerCase()]: value
        });
    };

    const getValue = () =>{
        switch (title.toLocaleLowerCase()) {
            case 'address1':
                return address.address1;
            case 'address2':
                return address.address2;
            case 'address3':
                return address.address3;
            case 'city':
                return address.city;
            case 'state':
                return address.state;
            case 'country':
                return address.country;
            case 'zip code':
                return address.postalCode;
            default:
                return '';
        }
    }

    return (
        <div className={styles.selectWrapper}>
            <div className={styles.selectTitleWrapper}>
                <span
                    className={styles.unHighLight}>[선택]</span>
                <span className={styles.titleText}>{title}</span>
            </div>
            <div className={styles.selectTextFieldWrapper}>
                <input
                    maxLength={25}
                    type="tel"
                    className={styles.selectText}
                    value={getValue() || ''}
                    onInvalid={(e) => e.preventDefault()}
                    // onChange={(e) => setValue(e.target.value)}
                    onChange={handleChange}
                    placeholder={title}
                />
            </div>
        </div>
    );
}