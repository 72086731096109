import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { descriptionState, titleState } from "../../../../../store/review/reviewModalSlice";
import { useCallback } from "react";
import { updateReviewAsync } from "../../../../../store/review/reviewSlice";
import { ReviewPutDTO } from "../../../../../types/review";
import { AddedFile } from "../../../../../types/file";

export function useReviewUpdater() {
  const dispatch = useAppDispatch();
  const title = useAppSelector(titleState);
  const description = useAppSelector(descriptionState);

  const updateReview = useCallback(
    async (reviewIdx: number, detailImages: AddedFile[]) => {
      const reviewPutDTO: ReviewPutDTO = {
        oneLineReviewText: title,
        reviewText: description,
        detailImages: detailImages.map((it) => {
          return { file: it };
        }),
      };
      await dispatch(updateReviewAsync({ reviewIdx, reviewPutDTO })).unwrap();
    },
    [title, description, dispatch]
  );

  return {
    updateReview,
  };
}
