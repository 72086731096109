export enum CodeStatus {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
  DELETED = "DELETED",
}

export enum DiscountType {
  PERCENT = "PERCENT",
  AMOUNT = "AMOUNT",
}

export enum CommissionType {
  AMOUNT = "AMOUNT",
  PERCENT = "PERCENT",
}

export interface AffiliateCodeDTO {
  discountCode: string;
  percent: number;
  idx: number;
  createAt: string;
  updateAt: string;
  code: string;
  status: CodeStatus;
  discountType: DiscountType;
  discountValue: number;
  commissionType: CommissionType;
  commissionValue: number;
  availableStartAt: string;
  availableEndAt: string;
  organManagerIdx: number;
  vatInclude: boolean;
  isTaxDeduction: boolean;
}

export interface AffiliateCodeVO {
  idx: number;
  code: string;
  status: CodeStatus;
  availableStartAt: string;
  availableEndAt: string;
  discountType: DiscountType;
  discountValue: number;
}
