import style from "./eventRegistererModal.module.scss";
import { Region } from "../../../../types/region";
import { RefObject } from "react";

interface Props {
  region: string;
  setRegion: (_: string) => void;
  regionRef: RefObject<HTMLDivElement>;
  invalidMessage?: string;
}
export function EventRegistererRegionField({ region, setRegion, regionRef, invalidMessage }: Props) {
  return (
    <div className={style.field} ref={regionRef}>
      <label className={style.label}>거주 지역</label>
      <select
        className={`${style.selector} ${region === "" ? style.noOption : style.selected}`}
        value={region}
        onChange={(e) => setRegion(e.target?.value)}
      >
        <option value={""}>지역 선택</option>
        {Region.getOptionList().map((aRegion) => (
          <option key={aRegion.value} value={aRegion.value}>
            {aRegion.label}
          </option>
        ))}
      </select>
      {invalidMessage && <span className={style.invalidMessage}>{invalidMessage}</span>}
    </div>
  );
}
