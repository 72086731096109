import { AddedFile } from "./file";

enum ProductStatus {
  IS_SELLING = "IS_SELLING",
  STOP_SELLING = "STOP_SELLING",
}

enum DisplayPosition {
  USER_WEB = "USER_WEB",
  USER_APP = "USER_APP",
  ORGAN_WEB = "ORGAN_WEB",
  USER_WEB_KPASS = "USER_WEB_KPASS",
  USER_WEB_DCAS = "USER_WEB_DCAS",
  COUNSELOR_VOUCHER = "COUNSELOR_VOUCHER",
}

enum DiscountType {
  AMOUNT = "AMOUNT",
  PERCENT = "PERCENT",
  NONE = "NONE",
}

interface ProductSummary {
  idx: number;
  name: string;
  description: string[];
  specialDescription: string[];
  status: ProductStatus;
  sellingStartDate: string;
  sellingEndDate: string;
  displayPosition: DisplayPosition;
  regularPrice: number;
  discountType: DiscountType;
  discountValue: number;
  sellingPrice: number;
  isCouponUsable: boolean;
  thumbnail: AddedFile;
}

export enum ProductImageType {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export interface ProductDetailImageDTO {
  desktopImages: ProductImage[];
  mobileImages: ProductImage[];
}

interface ProductImage {
  idx: number;
  file: AddedFile;
  productIdx: number;
  orderNum: number;
  type: ProductImageType;
}

interface ProductWOItems {
  idx: number;
  name: string;
  description: string[] | null;
  specialDescription: string[] | null;
  status: ProductStatus;
  sellingStartDate: string;
  sellingEndDate: string | null;
  displayPosition: DisplayPosition;
  regularPrice: number;
  discountType: DiscountType;
  discountValue: number;
  sellingPrice: number;
  isCouponUsable: boolean;
  thumbnail: AddedFile;
  detailImages: ProductImage[];
}

export enum ProductFileType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export namespace ProductFileType {
  const allowedSuffixes = {
    [ProductFileType.IMAGE]: [".jpg", ".jpeg", ".png", ".gif"],
    [ProductFileType.VIDEO]: [".mov", ".mp4"],
  };
  export const getFileTypeFromUrl = (url: string): ProductFileType | null => {
    const refinedUrl = url.trim().toLowerCase();
    if (allowedSuffixes[ProductFileType.IMAGE].some((allowedSuffix) => refinedUrl.endsWith(allowedSuffix))) {
      return ProductFileType.IMAGE;
    }
    if (allowedSuffixes[ProductFileType.VIDEO].some((allowedSuffix) => refinedUrl.endsWith(allowedSuffix))) {
      return ProductFileType.VIDEO;
    }
    return null;
  };
}

export interface ProductFile {
  type: ProductFileType | null;
  url: string;
}

export type { ProductSummary, ProductWOItems, ProductImage };
export { ProductStatus, DisplayPosition, DiscountType };
