import moment from "moment-timezone";

export class TimeConverter {
  private static instance: TimeConverter;
  private timezone: string;

  private constructor(timezone: string, locale: string) {
    this.timezone = timezone;
    this.setLocale(locale);
  }

  public static getInstance(): TimeConverter {
    if (!TimeConverter.instance) {
      moment.updateLocale("ko", {
        longDateFormat: {
          LT: "A h:mm",
          LTS: "A h:mm:ss",
          L: "YYYY.MM.DD",
          LL: "YYYY년 MMMM D일",
          LLL: "YYYY년 MMMM D일 A h:mm",
          LLLL: "YYYY년 MMMM D일 dddd A h:mm",
          l: "YYYY.MM.DD.",
          ll: "YYYY년 MMMM D일",
          lll: "YYYY년 MMMM D일 A h:mm",
          llll: "YYYY년 MMMM D일 dddd A h:mm",
        },
      });

      TimeConverter.instance = new TimeConverter(moment.tz.guess(), "ko");
    }

    return TimeConverter.instance;
  }

  public convertToLocalDate(utcTime: string | undefined | null): string {
    utcTime?.trim();
    if (utcTime === undefined || utcTime == null || utcTime.length === 0) {
      return "";
    }

    return moment.utc(utcTime).tz(this.timezone).format("L HH:mm:ss");
  }

  public isAfterNow(utcTime: string): boolean {
    return moment.utc(utcTime).tz(this.timezone).isAfter();
  }

  public setLocale(locale: string): void {
    moment.locale(locale);
  }

  public convertToFormattedDate(dateTimeString: string): string {
    const dateOnlyString = dateTimeString.split(" ")[0]; // "2024.08.12"
    const formattedDate = dateOnlyString.replace(/\./g, "-"); // "2024-08-12"
    return formattedDate;
  }

  public convertToFormattedHHMMSS(dateTimeString: string): string {
    const timeOnlyString = dateTimeString.split(" ")[1]; // "2024.08.12"
    return timeOnlyString;
  }
}
