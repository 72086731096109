import style from "../../../../assets/scss/common/modal/addChildModal.module.scss";
import React from "react";
interface Args{
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
}
export function AddressInput({label, name, value,onChange,placeholder}:Args) {

    return (
        <>
        <span className={style.infoText}>
            <span className={style.optionalText}>[선택]</span> {label}
        </span>
            <input
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={style.inputField}
            />
        </>
    )
}