import React, {useEffect, useState} from "react";
import style from "../../../assets/scss/common/modal/addConsultationModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import {useModal} from "../../../hooks/hooks";
import {
  ConsultationCancelDTO,
  ConsultationItem,
  ConsultationPutDTO,
  ConsultationReservation
} from "../../../types/consultation";
import {ReservationProfileInfo} from "./consultation/ReservationProfileInfo";
import {ReservationTitle} from "./consultation/ReservationTitle";
import {ReservationDateInput} from "./consultation/ReservationDateInput";
import {ReservationInquiry} from "./consultation/ReservationInquiry";
import {useConsultationReservationValidator} from "../hooks/useConsultationReservationValidator";
import {useConsultationGetter} from "../../sub/consultation/hooks/useConsultationGetter";
import {useConsultationFunctions} from "../../sub/consultation/hooks/useConsultationFunctions";

interface AddChildModalProps {
  isOpen: boolean;
  selectedConsultation: ConsultationItem | null;
  onClose: () => void;
  onRefresh: () => void;
}

const EditConsultationModal: React.FC<AddChildModalProps> = (
    {isOpen, selectedConsultation, onClose, onRefresh}) => {

  const {openConfirmModal, openAlertModal} = useModal();
  const {getConsultationStatus} = useConsultationFunctions();
  const [selectedDate, setSelectedDate] = useState("연도-월-일");
  const [selectedTime, setSelectedTime] = useState("");
  const [reservationIdx, setReservationIdx] = useState(0);
  const [childName, setChildName] = useState("");
  const [testInfo, setTestInfo] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [availableTimes, setAvailableTimes] = useState<ConsultationReservation[]>([]); // 잔여석 데이터

  const {updateCounseling, cancelCounseling} = useConsultationGetter();

  const {getConsultationInfo, consultationData,
    getAvailableReservation, reservationList} = useConsultationGetter();

  useEffect(() => {
    if (isOpen && selectedConsultation && selectedConsultation.userName) {
      getConsultationInfo(selectedConsultation.counselingIdx).then();
      const {userName, testRound, unitVoucherType} = selectedConsultation;
      setChildName(userName)
      setTestInfo(`${unitVoucherType} ${testRound}회차`)
    }
    // eslint-disable-next-line
  }, [selectedConsultation]);

  useEffect(() => {
    if (consultationData) {
      setReservationIdx(consultationData.reservationIdx)
      setSelectedDate(consultationData.desiredDate)
      setSelectedTime(consultationData.desiredTime)
      setInquiry(consultationData.preQuestioning)

      getAvailableReservation({
        desiredDate:consultationData.desiredDate}
      ).then()
    }
    // eslint-disable-next-line
  }, [consultationData]);

  useEffect(() => {
    setAvailableTimes(reservationList)
  }, [reservationList]);

  const {isInquiryValidate, validate} = useConsultationReservationValidator(
      {desiredTime: selectedTime, desiredDate: selectedDate, inquiry: inquiry}
  )
  useEffect(() => {
  }, [getConsultationInfo]);

  const isFormValid = () => {
    return childName && selectedDate !== "연도-월-일" && selectedTime && inquiry.length >= 3;
  };

  if (!isOpen) {
    return null;
  }

  const handleEditConsultation = () =>{
    const validator = validate();
    if(!validator) return alert("상담 작성폼을 다시 확인해주세요.");
    if(selectedConsultation === null) return;
    if(consultationData === undefined) return;

    openConfirmModal({
      title: "상담 신청 안내",
      desc:"상담내용을 정말 수정하시겠습니까?"
    },() =>{
      const body:ConsultationPutDTO = {
        userProfileIdx: selectedConsultation.profileIdx,
        counselingReservationIdx: reservationIdx,
        preQuestioning:inquiry
      }
      updateCounseling({body:body, idx: consultationData.counselingIdx}).then(() =>{
        openAlertModal({
          title: "상담 신청 안내",
          desc: "상담내용이 수정 되었습니다.",
        })
        onClose();
        onRefresh();
      })
    })

  }

  const cancelConsultation = () =>{
    if (!consultationData || !selectedConsultation) {
      alert("일시적인 문제가 발생했습니다.\n다시 시도해주세요.")
      window.location.reload();
      return;
    }

    const body:ConsultationCancelDTO = {
      counselingIdx: consultationData?.counselingIdx,
      counselingReservationIdx: consultationData?.reservationIdx,
      userProfileIdx: selectedConsultation?.profileIdx,
    }

    openConfirmModal(
        {
          title: "상담 신청 안내",
          desc: "상담을 정말 취소 하시겠습니까?\n상담 취소시 상담이용권이 1매 반환됩니다.",
        },
        () => {
          cancelCounseling(body).then(() =>{
            openAlertModal({
              title: "상담 신청 안내",
              desc: "상담이 취소 되었습니다.",
            })
            onClose();
            onRefresh();
          });
        }
    );

  }

  return (
      <div className={style.addChildModalOverlay}>
        <div className={style.addChildInfoModal}>
          <div className={style.closeBtnContainer}>
            <img
                src={CloseBtn}
                alt="closeBtn"
                className={style.closeBtn}
                onClick={() => {
                  openConfirmModal(
                      {
                        title: "상담 신청 안내",
                        desc: "해당 창을 닫을 경우\n입력한 상담 신청 정보가 모두 사라집니다.\n창을 정말 닫으시겠습니까?",
                      },
                      () => {
                        onClose();
                      }
                  );
                }}
            />
          </div>
          <div className={style.innerModalContainer}>
            <ReservationTitle/>
            <ReservationProfileInfo childName={childName} testInfo={testInfo}/>
            <ReservationDateInput
                selectedDate={selectedDate} setSelectedDate={setSelectedDate}
                selectedTime={selectedTime} setSelectedTime={setSelectedTime}
                availableTimes={availableTimes} setAvailableTimes={setAvailableTimes}
                setReservationIdx={setReservationIdx}
            />
            <ReservationInquiry inquiry={inquiry} setInquiry={setInquiry}
                                isInquiryValidate={isInquiryValidate}/>
            <div className={style.innerTextContainer}>
              <div className={style.infoContainer}>
                <span className={style.infoTitleText}>상담신청 상태</span>
                <div className={style.marginDiv}>
                  {getConsultationStatus(consultationData?.status)}
                </div>
              </div>
            </div>

            <div className={style.cancelBtnContainer} onClick={cancelConsultation}>
              <button className={style.cancelBtn}>상담 취소</button>
            </div>
            <button
                className={style.submitBtn}
                onClick={() => handleEditConsultation()}
                disabled={!isFormValid()}
            >
              내용 수정하기
            </button>
          </div>
        </div>
      </div>
  );
};

export default EditConsultationModal;
