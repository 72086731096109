import { RefObject, useEffect } from "react";

interface Args {
  targetRef: RefObject<any>;
  callbackOnIntersecting: () => void;
  callbackOnNotIntersecting: () => void;
}

export function useIntersectionObserver({ targetRef, callbackOnIntersecting, callbackOnNotIntersecting }: Args) {
  useEffect(() => {
    if (targetRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              callbackOnIntersecting();
            } else {
              callbackOnNotIntersecting();
            }
          });
        },
        {
          rootMargin: "0px 0px -20% 0px",
        }
      );

      observer.observe(targetRef.current);
    }
  }, [targetRef, callbackOnIntersecting, callbackOnNotIntersecting]);
}
