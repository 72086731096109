import loginStyle from "../../../../../../assets/scss/common/modal/loginModal.module.scss";
import memberStyle from "../../../../../../assets/scss/sub/editMember.module.scss";
import React, {RefObject} from "react";
import {Gender} from "../../../../../../types/user";
import {ValidateStatus} from "../hooks/useSignupValidator";
import {StyleRequestType} from "../../../../../../types/common";

interface Props{
    gender: Gender;
    setGender: (_: Gender) => void;
    genderValidate: ValidateStatus;
    setGenderValidate: (_: ValidateStatus) => void;
    styleRequestType: StyleRequestType;
    genderRef?: RefObject<HTMLDivElement>;
}
export function SignupGenderField({gender, setGender, genderValidate, setGenderValidate, styleRequestType, genderRef}:Props){
    const styles = styleRequestType === StyleRequestType.SIGN_UP ? loginStyle :  memberStyle;
    const handleGenderClick = (selectedGender: Gender) => {
        setGender(selectedGender);
        setGenderValidate(ValidateStatus.NONE)
    };

    const getGenderBoxClassName = (selectedGender: string) => {
        return `${styles.genderBox} ${gender === selectedGender ? styles.selected : ''}`;
    };

    const getGenderTextClassName = (selectedGender: string) => {
        return `${styles.genderText} ${gender === selectedGender ? styles.selectedText : ''}`;
    };

    return (
        <div className={styles.selectWrapper} ref={genderRef}>
            <div className={styles.selectTitleWrapper}>
                <span className={styles.highLight}>[필수]</span>
                <span className={styles.titleText}>성별</span>
            </div>
            <div className={styles.genderBoxWrapper} >
                <button
                    className={getGenderBoxClassName('MALE')}
                    onClick={() => handleGenderClick(Gender.MALE)}
                >
                    <span className={getGenderTextClassName('MALE')}>남자</span>
                </button>
                <button
                    className={getGenderBoxClassName('FEMALE')}
                    onClick={() => handleGenderClick(Gender.FEMALE)}
                >
                    <span className={getGenderTextClassName('FEMALE')}>여자</span>
                </button>
            </div>
            {genderValidate === ValidateStatus.UNFILLED &&
                (<span className={styles.validateText}>성별을 선택해 주세요.</span>)
            }
        </div>
    )
}