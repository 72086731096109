import { useDragEvent } from "../../../hooks/useDragEvent";
import { PopupLinkOption, PopupVO } from "../../../types/popup";
import style from "./popups.module.scss";
import { useCallback } from "react";

interface Props {
  moveNext?: () => void;
  movePrev?: () => void;
  popupVO: PopupVO;
}

export function APopup({ moveNext, movePrev, popupVO }: Props) {
  const { handleDragStart, handleDragEnd } = useDragEvent({
    moveRight: () => moveNext?.(),
    moveLeft: () => movePrev?.(),
  });

  const onClickPopup = useCallback(() => {
    if (!popupVO.imageLink) return;

    window.open(popupVO.imageLink, popupVO.linkOption === PopupLinkOption.NEW_TAB ? "_blank" : "_self");
  }, [popupVO]);

  return (
    <div
      className={`${style.aPopupSlide} ${popupVO.imageLink ? style.link : ""}`}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onClick={onClickPopup}
      style={{
        backgroundImage: `url(${popupVO.image.uri})`,
      }}
    ></div>
  );
}
