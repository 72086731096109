import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useModal } from "../../hooks/hooks";
import { ErrorType } from "../../types/error";
import { useUserInfoGetter } from "../common/hooks/useUserInfoGetter";

interface Props {
  children: JSX.Element;
}

export function RequireAuth({ children }: Props) {
  const navigate = useNavigate();
  const { signOut, getAccessTokenFromRefreshTokenCookie, loginOnLoad } = useAuth();
  const { getUserInfo } = useUserInfoGetter();
  const { openAlertModal } = useModal();
  const [showChildren, setShowChildren] = useState(false);

  const getUserInfoAndShowChildren = useCallback(() => {
    getUserInfo()
      .then(() => {
        setShowChildren(true);
      })
      .catch((e: any) => {
        console.error(e);
        const errorMessage = (e as { message: string }).message;
        const error: ErrorType = JSON.parse(errorMessage);

        if (error.errorCode.httpCode === 401) {
          getAccessTokenFromRefreshTokenCookie()
            .then(() => {
              getUserInfoAndShowChildren();
            })
            .catch(() => {
              signOut(() => {
                navigate(-1);
              });
            });
        } else {
          signOut(() => {
            openAlertModal(null);
          });
        }
      });
  }, [getAccessTokenFromRefreshTokenCookie, getUserInfo, navigate, openAlertModal, signOut]);

  useEffect(() => {
    loginOnLoad({
      callbackOnLogout: () => {
        signOut(() => {
          navigate("/");
        });
      },
      callbackOnLogin: () => setShowChildren(true),
      callbackOnError: () => {
        signOut(() => {
          openAlertModal(null);
        });
      },
    });
    // eslint-disable-next-line
  }, []);

  return <>{showChildren && children}</>;
}
