import { Gender, SecuredUserProfile, TesterInfo } from "../../../../../types/user";
import { ProfileImage } from "../../ProfileImage";
import styled from "styled-components";
import { breakpoints } from "../../../../../theme/theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ReviewTheme } from "../../../../main/article4/review/useBestReviewTheme";
import { useGetBestReviewTheme } from "../hooks/useGetBestReviewTheme";
import { useBrainType } from "../../../../../types/hooks/useBrainType";
import { BrainType } from "../../../../../types/review";

const ReviewProfileSectionStyle = styled.section`
  display: flex;
  flex-direction: row;
  gap: 30px;
  
  ${breakpoints.tablet} {
    min-width: 628px;
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 16px;
  }
  
  ${breakpoints.mobile} {
    gap: 10px;
  }
`;

const ProfileImageWrapStyle = styled.div`
  width: 130px;
  height: 130px;
  > img {
    width: 100%;
    height: 100%;
  }
  
  ${breakpoints.tablet}{
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }

  ${breakpoints.mobile} {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }
`;

const ProfileTextContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  justify-content: center;

  ${breakpoints.tablet} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: stretch;
    gap: 10px;
    flex: 1 0 0;
  }

  ${breakpoints.mobile} {
    gap: 5px;
  }
`;

const ProfileNameStyle = styled.span`
  font-style: normal;
  font-size: 32px;
  line-height: 140%;
  font-weight: normal;

  b {
    font-weight: bold;
    color: ${(props) => props.theme.color.kpNavy};
  }
  
  ${breakpoints.tablet} {
    min-width:75px;
    font-size: 24px;
    letter-spacing: -0.36px;
  }

  ${breakpoints.mobile} {
    font-size: 24px;
  }
`;

const ProfileExtraInformationStyle = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  flex-wrap: wrap;
  word-break: break-all;
  color: ${(props) => props.theme.color.kpNavy};
  
  ${breakpoints.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.24px;
  }

  ${breakpoints.mobile} {
    font-size: 16px;
    line-height: 100%;
  }
`;

const ProfileExtraInformationSeparatorStyle = styled.span`
  color: #c4c4c4;
  margin: 0 5px;
`;

interface Props {
  reviewIdx: number;
  profile: SecuredUserProfile;
  isBestReview: boolean;
  testerInfo: TesterInfo;
}

export function ReviewProfileSection({ reviewIdx, profile, isBestReview, testerInfo }: Props) {
  const { t } = useTranslation(["reviewModal"]);
  const getBestReviewTheme = useGetBestReviewTheme();
  const [currentTheme, setCurrentTheme] = useState<ReviewTheme>();
  const { toStringFromBrainType } = useBrainType();

  useEffect(() => {
    const theme = getBestReviewTheme(reviewIdx);
    if (theme === undefined) return;
    setCurrentTheme(theme);
  }, [getBestReviewTheme, isBestReview, reviewIdx]);

  return (
    <ReviewProfileSectionStyle>
      <ProfileImageWrapStyle>
        {isBestReview ? (
          currentTheme && <img src={currentTheme!.character} alt={"맥스 캐릭터"} />
        ) : (
          <ProfileImage reviewIdx={reviewIdx} />
        )}
      </ProfileImageWrapStyle>
      <ProfileTextContainerStyle>
        <ProfileNameStyle>
          <b>{profile.name}</b>
          {t("detailModalSir")}
        </ProfileNameStyle>
        <ProfileExtraInformationStyle>
          {testerInfo.region}
          <ProfileExtraInformationSeparatorStyle>|</ProfileExtraInformationSeparatorStyle>
          {testerInfo.age}
          {t("detailModalAge")}
          <ProfileExtraInformationSeparatorStyle>|</ProfileExtraInformationSeparatorStyle>
          {testerInfo.gender === Gender.MALE ? t("detailModalMale") : t("detailModalFemale")}
          {testerInfo.brainType !== BrainType.UNKNOWN ? (
            <>
              <ProfileExtraInformationSeparatorStyle>|</ProfileExtraInformationSeparatorStyle>
              {toStringFromBrainType(testerInfo.brainType)}
            </>
          ) : (
            <></>
          )}
        </ProfileExtraInformationStyle>
      </ProfileTextContainerStyle>
    </ReviewProfileSectionStyle>
  );
}
