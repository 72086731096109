import React from "react";

export const MainLegacyScrollContext = React.createContext({
  scrollY: 0,
  headerHeight: 0,
  selectedSection: undefined,
  scrollingSection: undefined,
  setScrollingSection: (_: string) => {},
  showOrHide: (_: number) => {},
  isPassingHeaderBottom: (a: number, b: number) => {},
  setShowFooter: (_: boolean) => {},
});
