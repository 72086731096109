import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FileClassification, FileResponse } from "../../types/file";
import { addCases, executePromise } from "../../util/sliceUtil";
import { FileApi } from "../../api/FileApi";
import { RootState } from "../index";

export interface FileState extends SliceState {}

const initialState: FileState = {
  status: Status.IDLE,
};

export const uploadFilesAsync = createAsyncThunk(
  "file/uploadFiles",
  ({ files, classification }: { files: File[]; classification: FileClassification }) => {
    return executePromise(() => {
      const formData = new FormData();
      files.forEach((file) => formData.append("images", file));
      return FileApi.uploadImageFiles(formData, classification);
    }).then((result) => {
      const uploadedFiles: FileResponse[] = Object.keys(result).map((k) => ({
        idx: Number(k),
        uri: result[Number(k)],
      }));
      return uploadedFiles;
    });
  }
);

export const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, uploadFilesAsync);
  },
});

export default fileSlice.reducer;

export const fileStatus = (state: RootState) => state.file.status;
