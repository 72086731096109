import { SliceState, Status } from "../../types/common";
import { KPASSReviewVO, ReviewImageDTO, ReviewStatus, UserProfileWithReviewStatusVO } from "../../types/review";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { FileType } from "../../types/file";
import { ReviewValidationError } from "../../components/common/modal/review/hooks/useReviewValidator";

export enum ReviewModalMode {
  CREATE,
  EDIT,
}

export interface ReviewModalSlice extends SliceState {
  selectedProfileIdx?: number;
  selectedContentExecutedInfoIdx?: number;
  userProfileWithReviewStatuses?: UserProfileWithReviewStatusVO[];
  userProfileWithReviewStatusForEdit?: UserProfileWithReviewStatusVO;
  reviewModalMode?: ReviewModalMode;
  reviewForEdit?: KPASSReviewVO;
  title: string;
  description: string;
  agreement: boolean;
  reviewImages: (ReviewImageDTO | FileType | undefined)[];
  validationErrors: ReviewValidationError[];
}

const initialState: ReviewModalSlice = {
  status: Status.IDLE,
  selectedProfileIdx: undefined,
  selectedContentExecutedInfoIdx: undefined,
  reviewForEdit: undefined,
  reviewModalMode: ReviewModalMode.CREATE,
  title: "",
  description: "",
  agreement: false,
  reviewImages: [undefined, undefined, undefined, undefined, undefined],
  validationErrors: [],
};

export const reviewModalSlice = createSlice({
  name: "reviewModal",
  initialState,
  reducers: {
    reset: (state) => {
      state.selectedProfileIdx = undefined;
      state.selectedContentExecutedInfoIdx = undefined;
      state.userProfileWithReviewStatuses = undefined;
      state.userProfileWithReviewStatusForEdit = undefined;
      state.title = "";
      state.description = "";
      state.reviewImages = [undefined, undefined, undefined, undefined, undefined];
      state.agreement = false;
      state.reviewModalMode = ReviewModalMode.CREATE;
      state.validationErrors = [];
    },
    setSelectedProfileIdx: (state, action: PayloadAction<number>) => {
      state.selectedProfileIdx = action.payload;
      const foundProfile = state.userProfileWithReviewStatuses?.find(
        (it) => it.profileIdx === state.selectedProfileIdx
      );
      const foundReviewStatus = foundProfile?.reviewStatuses.find((it) => it.status === ReviewStatus.UNWRITTEN);
      if (foundReviewStatus) state.selectedContentExecutedInfoIdx = foundReviewStatus.contentExecutedInfoIdx;
    },
    setUserProfileWithReviewStatuses: (state, action: PayloadAction<UserProfileWithReviewStatusVO[]>) => {
      state.userProfileWithReviewStatuses = action.payload;
    },
    setUserProfileWithReviewStatusForEdit: (state, action: PayloadAction<UserProfileWithReviewStatusVO>) => {
      state.userProfileWithReviewStatusForEdit = action.payload;
    },
    setReviewForEdit: (state, action: PayloadAction<KPASSReviewVO>) => {
      const kpassReview = action.payload;
      state.reviewForEdit = kpassReview;
      state.title = kpassReview.oneLineReviewText;
      state.description = kpassReview.reviewText;
    },
    setReviewModalMode: (state, action: PayloadAction<ReviewModalMode>) => {
      state.reviewModalMode = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    addAttachedFile: (state, action: PayloadAction<FileType>) => {
      const foundEmptyFileIndex = state.reviewImages.findIndex((it) => it === undefined);
      if (foundEmptyFileIndex === -1) return;
      const newState = [...state.reviewImages];
      newState[foundEmptyFileIndex] = action.payload;
      state.reviewImages = newState;
    },
    removeAttachedFile: (state, action: PayloadAction<number>) => {
      if (action.payload + 1 > state.reviewImages.length) return;
      let newState = [...state.reviewImages];
      newState[action.payload] = undefined;
      newState = newState.filter((it) => it !== undefined);
      let toAddEmptyImageSize = state.reviewImages.length - newState.length;
      state.reviewImages = [...newState, ...new Array<FileType>(toAddEmptyImageSize)];
    },
    setReviewImages(state, action: PayloadAction<ReviewImageDTO[]>) {
      const newReviewImages: (ReviewImageDTO | undefined)[] = [undefined, undefined, undefined, undefined, undefined];
      action.payload.forEach((it, idx) => (newReviewImages[idx] = it));
      state.reviewImages = newReviewImages;
    },
    setAgreement(state, action: PayloadAction<boolean>) {
      state.agreement = action.payload;
    },
    setValidationErrors(state, action: PayloadAction<ReviewValidationError[]>) {
      state.validationErrors = action.payload;
    },
  },
});

export const {
  reset,
  setReviewModalMode,
  setReviewForEdit,
  setUserProfileWithReviewStatuses,
  setUserProfileWithReviewStatusForEdit,
  setSelectedProfileIdx,
  setTitle,
  setDescription,
  addAttachedFile,
  removeAttachedFile,
  setReviewImages,
  setAgreement,
  setValidationErrors,
} = reviewModalSlice.actions;

export const userProfileWithReviewStatusesState = (state: RootState) => state.reviewModal.userProfileWithReviewStatuses;
export const userProfileWithReviewStatusForEditState = (state: RootState) =>
  state.reviewModal.userProfileWithReviewStatusForEdit;
export const selectedProfileIdxState = (state: RootState) => state.reviewModal.selectedProfileIdx;
export const selectedContentExecutedInfoIdxState = (state: RootState) =>
  state.reviewModal.selectedContentExecutedInfoIdx;
export const reviewModalModeState = (state: RootState) => state.reviewModal.reviewModalMode;
export const titleState = (state: RootState) => state.reviewModal.title;
export const descriptionState = (state: RootState) => state.reviewModal.description;
export const reviewImagesState = (state: RootState): (FileType | undefined | ReviewImageDTO)[] =>
  state.reviewModal.reviewImages;
export const agreementState = (state: RootState) => state.reviewModal.agreement;
export const validationErrorsState = (state: RootState): ReviewValidationError[] => state.reviewModal.validationErrors;
export const hasAnyInputState = (state: RootState): boolean => {
  const reviewModal = state.reviewModal;
  return (
    reviewModal.title.length !== 0 ||
    reviewModal.description.length !== 0 ||
    reviewModal.selectedProfileIdx !== undefined ||
    reviewModal.reviewImages.filter((it) => it !== undefined).length !== 0
  );
};

export default reviewModalSlice.reducer;
