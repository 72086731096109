import {MainWrapper} from "./MainWrapper";
import {ProfileInfo} from "./ProfileInfo";
import {CheckupInfo} from "./CheckupInfo";
import {TopBanner} from "./TopBanner";
import {PaymentHistory} from "./PaymentHistory";
import {CouponHistory} from "./CouponHistory";
import {useEffect, useState} from "react";
import {useAuth, useModal} from "../../hooks/hooks"; // 필요한 훅 가져오기
import {useCookie} from "../../hooks/auth/useCookie";
import {useLocation, useNavigate} from "react-router-dom";
import {CookieKeys} from "../../hooks/auth/AuthProvider";
import queryString from "query-string";
import style from "../../assets/scss/sub/myPage.module.scss";
import {MiniGame} from "./MiniGame";
import {ConsultationHistory} from "./consultation/ConsultationHistory";

export function MyPage() {
  const [profileIdx, setProfileIdx] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);
  const [totalVoucherData, setTotalVoucherData] = useState(null);
  const { openAlertModal } = useModal();
  const { getValueFromCookie, setCookie } = useCookie();
  const { onLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // URL에서 토큰 추출
    const { accessToken, refreshToken } = queryString.parse(location.search);

    if (accessToken && refreshToken) {
      // 유효한 accessToken과 refreshToken이 있는 경우 로그인 처리
      onLoggedIn(accessToken, refreshToken);
      setCookie(CookieKeys.ACCESS_TOKEN, accessToken);
      setCookie(CookieKeys.REFRESH_TOKEN, refreshToken);

      // URL에서 토큰 정보 제거
      navigate(location.pathname, { replace: true });

      // 페이지 새로고침
      window.location.reload();
    } else {
      // 쿠키에서 accessToken 가져오기
      const cookieAccessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);

      if (cookieAccessToken === undefined) {
        openAlertModal({
          title: "안내",
          desc: "로그인 후 이용해주세요.",
          callback: () => navigate("/"),
        });
        return;
      }
    }
  }, [navigate, onLoggedIn, setCookie, getValueFromCookie, openAlertModal, location]);

  return (
      <MainWrapper showFooter={true}>
        {/* 보라색 Container */}
        <TopBanner totalVoucherData={totalVoucherData} />
        {/* 관리중인 프로필 & 검사정보 */}
        <MiniGame accessToken={profileInfo?.accessToken} />
        <ProfileInfo
            setProfileIdx={setProfileIdx}
            setProfileInfo={setProfileInfo}
            profileInfo={profileInfo}
            setTotalVoucherData={setTotalVoucherData}
        />
        <div className={style.MyPageContainer}>
          <CheckupInfo profileIdx={profileIdx} profileInfo={profileInfo} />
          <ConsultationHistory profileIdx={profileIdx} profileInfo={profileInfo} />
        </div>

        {/* 결제내역 & 쿠폰내역 */}
        <div className={`${style.MyPageContainer} ${style.marginBottom1}`}>
          {/* 결제내역 */}
          <PaymentHistory />
          {/* 쿠폰내역 */}
          <CouponHistory />
        </div>
      </MainWrapper>
  );
}
