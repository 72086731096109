import React, { useCallback } from "react";
import { useAppDispatch, useAuth, useModal } from "../../../../../hooks/hooks";
import { ModalType } from "../../../../../types/common";
import { getReviewCreateStatusAsync } from "../../../../../store/review/reviewSlice";
import { ReviewCreateStatus } from "../../../../../types/review";

export function useReviewWriterOpener() {
  const { isLoggedIn } = useAuth();
  const { openModal, openAlertModal } = useModal();
  const dispatch = useAppDispatch();

  const checkIsWritable = useCallback(async () => await dispatch(getReviewCreateStatusAsync()).unwrap(), [dispatch]);

  const openReviewWriter = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!isLoggedIn) {
        openModal(ModalType.LOGIN);
        return;
      }

      const status = await checkIsWritable();
      switch (status.kpassReviewCreateStatus) {
        case ReviewCreateStatus.ALL_WRITTEN:
          openAlertModal({
            title: "안내",
            desc: "회원님은 이미 모든 검사 후기를 작성하셨습니다.\n감사합니다 :)",
          });
          break;
        case ReviewCreateStatus.NO_TESTS:
          openAlertModal({
            title: "안내",
            desc: "회원님은 현재 검사내역이 없습니다.\nK-PASS 검사를 진행하고 후기를 작성해보세요!",
          });
          break;
        case ReviewCreateStatus.WRITEABLE:
          openModal(ModalType.REVIEW_WRITE);
          break;
      }
    },
    [isLoggedIn, openModal, openAlertModal, checkIsWritable]
  );

  return {
    openReviewWriter,
  };
}
