import { useEffect, useRef, useState } from "react";
import { isOnScreenAxisX } from "../../../../util/commonFunctions";

interface Args {
  wrapperRefCurrent: HTMLElement | null;
}
export function useBannerRef({ wrapperRefCurrent }: Args) {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (bannerRef.current && wrapperRefCurrent) {
      const { left: wrapperLeft, right: wrapperRight } = wrapperRefCurrent.getBoundingClientRect();
      const { left: bannerLeft, right: bannerRight } = bannerRef.current.getBoundingClientRect();
      setShow(isOnScreenAxisX(wrapperLeft, wrapperRight)(bannerLeft, bannerRight));
    }
  }, [wrapperRefCurrent, bannerRef]);

  return {
    bannerRef,
    show,
  };
}
