import { Gender, UserProfile } from "./user";
import { AddedFile, instanceOfAddedFile } from "./file";

export interface KPASSReviewDTO {
  idx: number;
  userIdx: number;
  profileIdx: number;
  oneLineReviewText: string;
  reviewText: string;
  thumbnailUrl: string | null;
  writtenAt: string;
  brainType: string;
}

export interface KPASSReviewVO {
  idx: number;
  userIdx: number;
  profileIdx: number;
  oneLineReviewText: string;
  reviewText: string;
  thumbnailUrl: string | null;
  writtenAt: string;
  brainType: string;
}

export enum BrainType {
  UNKNOWN = "UNKNOWN",
  SUCCESSIVE = "SUCCESSIVE",
  SIMULTANEOUS = "SIMULTANEOUS",
  BALANCED = "BALANCED",
}

export interface KPASSReviewListDTO {
  idx: number;
  userIdx: number;
  profileIdx: number;
  name: string;
  region: string;
  gender: Gender;
  age: number;
  writtenAt: string;
  oneLineReviewText: string;
  reviewText: string;
  thumbnailUrl: string | null;
  brainType: BrainType;
}

export interface ReviewListVO {
  idx: number;
  age: number;
  writtenAt: string;
  oneLineReviewText: string;
  reviewText: string;
  thumbnailUrl: string | null;
  name: string;
  region: string;
  gender: Gender;
  userIdx: number;
  brainType: BrainType;
  brainTypeString: string;
}

export interface ReviewStatusDTO {
  idx: number;
  contentExecutedInfoIdx: number;
  status: ReviewStatus;
}

export interface UserProfileWithReviewStatusVO {
  profileIdx: number;
  userProfile: UserProfile;
  reviewStatuses: ReviewStatusDTO[];
}

export enum ReviewStatus {
  UNWRITTEN = "UNWRITTEN",
  WRITTEN = "WRITTEN",
}

export interface ReviewImageDTO {
  file: AddedFile;
}

export interface ReviewPostDTO {
  contentExecutedInfoIdx: number;
  userProfileIdx: number;
  oneLineReviewText: string;
  reviewText: string;
  detailImages: ReviewImageDTO[];
}

export interface ReviewPutDTO {
  oneLineReviewText: string;
  reviewText: string;
  detailImages: ReviewImageDTO[];
}

export enum ReviewCreateStatus {
  WRITEABLE = "WRITEABLE",
  ALL_WRITTEN = "ALL_WRITTEN",
  NO_TESTS = "NO_TESTS",
}

export interface ReviewCreateStatusDTO {
  kpassReviewCreateStatus: ReviewCreateStatus;
}

export function instanceOfReviewImageDTO(object: any): object is ReviewImageDTO {
  return object !== undefined && "file" in object && instanceOfAddedFile(object.file);
}
