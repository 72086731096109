import style from "./mainArticle1.module.scss";
import parent1 from "../../../assets/images/main/article1/section3/main_parents1.gif";
import parent2 from "../../../assets/images/main/article1/section3/main_parents2.gif";
import {useLanguage, useMainScrolling, useUserAgent} from "../../../hooks/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { SlidingPartPassingStatus } from "../../../hooks/main_scrolling/MainScrollingContext";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { Trans, useTranslation } from "react-i18next";

export function Article1Section3() {
  const { t } = useTranslation(["parentsWish"]);
  const {isKorean} = useLanguage();

  const { slidingPartPassingStatus } = useMainScrolling();
  const [showFirstText, setShowFirstText] = useState(false);
  const [showPart1, setShowPart1] = useState(false);
  const [showPart1Image, setShowPart1Image] = useState(false);
  const [showPart1Text, setShowPart1Text] = useState(false);
  const [showPart2, setShowPart2] = useState(false);
  const [showPart2Image, setShowPart2Image] = useState(false);
  const [showPart2Text, setShowPart2Text] = useState(false);
  const part1Ref = useRef<HTMLDivElement>(null);
  const part1ImageRef = useRef<HTMLImageElement>(null);
  const part1TextRef = useRef<HTMLDivElement>(null);
  const part2Ref = useRef<HTMLDivElement>(null);
  const part2ImageRef = useRef<HTMLImageElement>(null);
  const part2TextRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet } = useUserAgent();

  useEffect(() => {
    if (slidingPartPassingStatus === SlidingPartPassingStatus.IS_AFTER_PASSING) {
      setTimeout(() => setShowFirstText(true), 0);
    } else {
      setShowFirstText(false);
    }
  }, [slidingPartPassingStatus]);

  useShowOrHide({ refCurrent: part1Ref.current, setShow: setShowPart1 });
  useShowOrHide({ refCurrent: part2Ref.current, setShow: setShowPart2 });
  useShowOrHide({ refCurrent: part1ImageRef.current, setShow: setShowPart1Image });
  useShowOrHide({ refCurrent: part1TextRef.current, setShow: setShowPart1Text });
  useShowOrHide({ refCurrent: part2ImageRef.current, setShow: setShowPart2Image });
  useShowOrHide({ refCurrent: part2TextRef.current, setShow: setShowPart2Text });

  const SpaceComponent = useCallback(() => <>{!isMobile ? <br /> : <> </>}</>, [isMobile]);
  const SpaceComponent2 = useCallback(() => <>{!isMobile ? <> </> : <br />}</>, [isMobile]);
  const TabletSpaceComponent = useCallback(() => <>{!isTablet ? <> </> : <br />}</>, [isTablet]);

  return (
    <section className={style.section3}>
      <div className={style.bgItemTopRight} />
      <div className={style.bgItemLeftBottom} />
      <div className={`${style.desc1} ${showFirstText ? style.show : style.hide}`}>
        <Trans
          t={t}
          i18nKey={"section3.title"}
          components={[
            <div className={style.subText} />,
            <span className={style.primaryText}></span>,
            <span className={style.point}></span>,
            <SpaceComponent />,
            <SpaceComponent2 />,
          ]}
        />
      </div>
      <div className={`${style.imageAndText} ${showPart1 ? style.show : style.hide}`} ref={part1Ref}>
        <img
          className={`${style.image} ${showPart1Image ? style.show : style.hide}`}
          src={parent1}
          alt={"parent"}
          ref={part1ImageRef}
        />
        <div
          className={`${style.textArea} ${style.leftBorder} ${showPart1Text ? style.show : style.hide}`}
          ref={part1TextRef}
        >
          <p className={style.normal}>
            {t("section3.part1.text1")}
            <br />
            <Trans t={t} i18nKey={"section3.part1.text2"} components={[<SpaceComponent />]} />
            <br />
            <Trans t={t} i18nKey={"section3.part1.text4"} components={[<SpaceComponent />]} />
            {!isMobile ? <br /> : <> </>}
          </p>
          <p className={style.point}>
            <Trans t={t} i18nKey={"section3.part1.text6"} components={[<SpaceComponent />]} />
          </p>
        </div>
      </div>
      <div className={`${style.imageAndText} ${style.part2} ${showPart2 ? style.show : style.hide}`} ref={part2Ref}>
        <div
          className={`${style.textArea} ${style.rightBorder} ${showPart2Text ? style.show : style.hide}`}
          ref={part2TextRef}
        >
          <p className={style.normal}>
            <Trans t={t} i18nKey={"section3.part2.text1"} components={[<SpaceComponent />]} />
            <br />
            <Trans t={t} i18nKey={"section3.part2.text4"} components={[<SpaceComponent />]} />
            <br />
            {t("section3.part2.text6")}
            <br />
          </p>
          {isTablet && isKorean
              ? <p className={style.point}>
                <Trans  t={t} i18nKey={"section3.part2.text7tablet"} components={[<TabletSpaceComponent />]} />
              </p>
              : <p className={style.point}>{t("section3.part2.text7")}</p>
          }
        </div>
        <img
          className={`${style.image} ${showPart2Image ? style.show : style.hide}`}
          src={parent2}
          alt={"parent"}
          ref={part2ImageRef}
        />
      </div>
    </section>
  );
}
