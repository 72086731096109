import { ProductSummary } from "./product";
import { UserDetail } from "./user";
import { PaymentWay } from "./payment";

interface OrderedProduct {
  product: ProductSummary;
  quantity: number;
  totalRegularPrice?: number;
  totalDiscountAmount?: number;
  totalActualAmount?: number;
}

interface CreateOrderParam {
  cartIdxes: number[];
  couponHistoryIdx: number | null;
  regularPriceAmount: number;
  productDiscountAmount: number;
  couponDiscountAmount: number;
  payAmount: number;
  paymentWay: PaymentWay;
  affiliateCodeIdx?: number;
  affiliateCodeDiscountAmount?: number;
}

export interface AffiliateCodeCommissionCheckerParam {
  cartIdxes: number[];
  affiliateCodeIdx: number;
}

enum OrderStatus {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  AWAITING_DEPOSIT = "AWAITING_DEPOSIT",
  PAID = "PAID",
  CANCELLED = "CANCELLED",
  FAIL = "FAIL",
}

interface OrderDetail {
  idx: number;
  status: OrderStatus;
  customerUser: UserDetail;
  paymentWay: PaymentWay;
  orderTitle: string;
  orderImage: string;
  priceAmount: number;
  couponDiscountAmount: number;
  productDiscountAmount: number;
  payAmount: number;
  vbankName: string;
  vbankAccountNumber: string;
  failReason: string;
  createAt: string;
  orderQuantity: number;
}

export type { OrderedProduct, CreateOrderParam, OrderDetail };
export { OrderStatus };
