import { ArticleTitle } from "../../sub/ArticleTitle";
import style from "./mainArticle4.module.scss";
import { NavigationButton } from "../../common/button/NavigationButton";
import { useMemo, useRef, useState } from "react";
import { useShowOrHide } from "../../../hooks/useShowOrHide";
import { ReviewSlider } from "../../common/slider/ReviewSlider";
import { useMainScrolling, useUserAgent } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Article4Section1() {
  const { t } = useTranslation(["realReview"]);
  const { isMobile } = useUserAgent();
  const { isTablet } = useUserAgent();
  const navigate = useNavigate();
  const sliderRef = useRef<HTMLDivElement>(null);

  const [showSection, setShowSection] = useState(false);

  const { article4Element } = useMainScrolling();

  useShowOrHide({ refCurrent: article4Element.current, setShow: setShowSection });

  const sliderRect = useMemo(
    () => sliderRef.current?.getBoundingClientRect(),
    // eslint-disable-next-line
    [sliderRef.current]
  );
  const sliderWidth = useMemo(() => sliderRect?.width ?? 0, [sliderRect]);
  const sliderHeight = useMemo(() => sliderRect?.height ?? 0, [sliderRect]);

  return (
    <section className={style.section1} ref={article4Element}>
      <h2 className={"blind"}>{t("heading2")}</h2>
      <div className={style.titleWrapper}>
        <ArticleTitle title={t("title")} className={style.title} />
      </div>
      <div className={`${style.slider} ${showSection ? style.show : style.hide}`} ref={sliderRef}>
        <ReviewSlider
          isArticleSlider={true}
          isSubpageSlider={false}
          hasBulletIndicator={false}
          slideWidth={isMobile ? sliderWidth : isTablet ? 300 : 592}
          slideHeight={isMobile ? sliderHeight : isTablet ? 691 : 890}
          delay={900}
          slideGap={{
            totalGap: isMobile ? 14 : isTablet ? 150 :  280,
            mainWidth: isMobile ? sliderWidth : isTablet ? 444 : 852,
          }}
          slideClassName={{
            wrapper: style.sliderWrapper,
            notCurrentSlide: style.notCurrentSlide,
            currentSlide: style.currentSlide,
            show: style.show,
            hide: style.hide,
          }}
          onClickSlide={(_) => navigate(`/review?page=0`)}
        />
      </div>
      <div className={`${style.readMoreButton} ${showSection ? style.show : style.hide}`}>
        <NavigationButton buttonText={t("goToReviewPage")} onClickEvent={() => navigate(`/review?page=0`)} />
      </div>
    </section>
  );
}
