import style from "./navigationButton.module.scss";
import React from "react";

interface Props {
  buttonText: string;
  onClickEvent: (e: React.MouseEvent) => void;
}
export function NavigationButton({ buttonText, onClickEvent }: Props) {
  return (
    <button className={style.button} onClick={onClickEvent}>
      <span>{buttonText}</span>
    </button>
  );
}
