import React from "react";
import style from "../../../assets/scss/common/modal/organInfoModal.module.scss";

interface OrganInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  organSignInCode: string;
  organName: string;
  organPhoneNumber: string;
  organVoucherCnt: number;
  organKpassVoucherCnt: number;
  organDcasVoucherCnt: number;
}

const OrganInfoModal: React.FC<OrganInfoModalProps> = ({
  isOpen,
  onClose,
  name,
  organSignInCode,
  organName,
  organPhoneNumber,
  organVoucherCnt,
  organKpassVoucherCnt,
  organDcasVoucherCnt,
}) => {
  if (!isOpen) {
    return null;
  }

  // Function to copy the login code to the clipboard
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("로그인코드가 복사되었습니다!");
    } catch (error) {
      console.error("복사 실패:", error);
      alert("복사에 실패했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <div className={style.organInfoModalOverlay}>
      <div className={style.organInfoModal}>
        <div className={style.organInfoModalContent}>
          <h2 className={style.titleText}>
            <span className={style.boldText}>{name}</span>님 소속정보
          </h2>
        </div>
        <div className={style.organInfoTextContainer}>
          <div className={`${style.infoContainer} ${style.marginBottom1}`}>
            <div className={style.infoTitle}>로그인코드</div>
            <div className={style.detailInfoText}>{organSignInCode}</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              style={{ marginLeft: "-50px", cursor: "pointer" }} // Added cursor pointer for clickability
              onClick={() => copyToClipboard(organSignInCode)} // Copy the code on click
              className={style.copyIcon}
            >
              <path
                d="M11.4824 12.167V10.1044C11.4824 9.36058 12.091 8.75195 12.8349 8.75195H19.6988C20.4427 8.75195 21.0513 9.36058 21.0513 10.1044V16.9684C21.0513 17.7122 20.4427 18.3209 19.6988 18.3209H18.3125"
                stroke="#757575"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.9645 12.168H10.1005C9.35358 12.168 8.74805 12.7735 8.74805 13.5205V20.3844C8.74805 21.1313 9.35358 21.7369 10.1005 21.7369H16.9645C17.7114 21.7369 18.317 21.1313 18.317 20.3844V13.5205C18.317 12.7735 17.7114 12.168 16.9645 12.168Z"
                stroke="#757575"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={`${style.infoContainer} ${style.marginBottom1}`}>
            <div className={style.infoTitle}>소속이름</div> <div className={style.detailInfoText}>{organName}</div>
          </div>
          <div className={`${style.infoContainer} ${style.marginBottom1}`}>
            <div className={style.infoTitle}>소속이용권</div>
            <div className={`${style.detailInfoText} ${style.infoIconContainer}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 10C20 4.48112 15.5189 0 10 0C4.48112 0 0 4.48112 0 10C0 15.5189 4.48112 20 10 20H20V10ZM8.30528 12.6293L9.63165 11.0999L12.3561 14.8154H15.0684L11.0418 9.48666L14.8654 5.07812H12.2006L8.30528 9.66639V5.07812H6.13039V14.8154H8.30528V12.6293Z"
                  fill="#583BE2"
                />
              </svg>
              {organKpassVoucherCnt}매
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 10C20 4.48112 15.5189 0 10 0C4.48112 0 0 4.48112 0 10C0 15.5189 4.48112 20 10 20H20V10ZM6.17708 14.7827H9.69659C12.6317 14.7827 14.438 12.9533 14.438 9.91318C14.438 6.87307 12.6317 5.04362 9.69659 5.04362H6.17708V14.7827ZM9.6169 12.8188H8.43488V6.99414H9.59034C11.2638 6.99414 12.1669 7.80125 12.1669 9.91318C12.1669 12.0251 11.2638 12.8188 9.6169 12.8188Z"
                  fill="#583BE2"
                />
              </svg>
              {organDcasVoucherCnt}매
            </div>
          </div>
          <div className={`${style.infoContainer} ${style.marginBottom1}`}>
            <div className={style.infoTitle}>대표연락처</div>
            <div className={style.detailInfoText}>{organPhoneNumber}</div>
          </div>
        </div>
        <button onClick={onClose} className={style.organInfoCloseButton}>
          닫기
        </button>
      </div>
    </div>
  );
};

export default OrganInfoModal;
