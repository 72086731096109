import style from "../../../assets/scss/sub/partnership.module.scss";
import { ArticleTitle } from "../ArticleTitle";
import { StrongTextStyle } from "../../common/StyledCommonComponents";
import { CustomOverlayMap, Map } from "react-kakao-maps-sdk";
import { DomesticPartnerCompanyCards } from "./DomesticPartnerCompanyCards";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PartnerCompanyVO } from "../../../types/partnerCompany";
import { useUserAgent } from "../../../hooks/hooks";
import { useLatitudeLongitudeGetter } from "./useLatitudeLongitudeGetter";
import { useAddress } from "../../../hooks/useAddress";

const FEELGOOD_LATITUDE = 35.1480881;
const FEELGOOD_LONGITUDE = 129.0658794;

interface Props {
  partnerCompanies: PartnerCompanyVO[];
  search: (_: string) => void;
}

interface MapPosition {
  center: {
    lat: number;
    lng: number;
  };
  isPanto: boolean;
}
export function DomesticPartnerList({ partnerCompanies }: Props) {
  const { isMobile } = useUserAgent();

  const [map, setMap] = useState<kakao.maps.Map>();

  const { getAddressLine1 } = useAddress();
  const { getMapPositionFromAddress } = useLatitudeLongitudeGetter({ map });
  const [savedCount, setSavedCount] = useState(0);
  const [centerPosition, setCenterPosition] = useState<MapPosition>({
    center: { lat: FEELGOOD_LATITUDE, lng: FEELGOOD_LONGITUDE },
    isPanto: true,
  });
  const [searchResultPartners, setSearchResultPartners] = useState<PartnerCompanyVO[]>([]);
  const allPartnersWithPosition: PartnerCompanyVO[] = useMemo(
    () =>
      partnerCompanies.map((vo) => {
        const addressLine1 = getAddressLine1(vo.address);
        const copiedVO = { ...vo, addressLine1 };

        getMapPositionFromAddress(addressLine1, (lat, lng) => {
          copiedVO.mapLongitude = lng;
          copiedVO.mapLatitude = lat;
          setSavedCount((prev) => prev + 1);
        });
        return copiedVO;
      }),
    [partnerCompanies, getMapPositionFromAddress, getAddressLine1]
  );

  useEffect(() => {
    setSearchResultPartners(allPartnersWithPosition);
  }, [allPartnersWithPosition]);

  const [selectedPartner, setSelectedPartner] = useState<PartnerCompanyVO | null>(null);
  useEffect(() => {
    if (searchResultPartners.length > 0) {
      setSelectedPartner(searchResultPartners[0]);
    }
  }, [searchResultPartners]);

  const isMarkersLoaded = useMemo(
    () => savedCount % allPartnersWithPosition.length === 0,
    [allPartnersWithPosition.length, savedCount]
  );

  useEffect(() => {
    if (map && selectedPartner && isMarkersLoaded) {
      if (selectedPartner.mapLongitude && selectedPartner.mapLongitude) {
        setCenterPosition({
          center: { lat: Number(selectedPartner.mapLatitude!), lng: Number(selectedPartner.mapLongitude!) },
          isPanto: true,
        });
      }
    }
  }, [map, selectedPartner, isMarkersLoaded]);

  const search = useCallback(
    (searchWord: string) => {
      setSearchResultPartners(
        allPartnersWithPosition.filter(
          (partner) => partner.addressLine1?.includes(searchWord) || partner.name.includes(searchWord)
        )
      );
    },
    [allPartnersWithPosition]
  );

  return (
    <article className={style.article}>
      <div className={style.bgItem1}></div>
      <div className={style.bgItem2}></div>
      <div className={style.bgItem3}></div>
      <section className={style.section}>
        <ArticleTitle title={"국내 협력사"} className={style.domesticSectionTitle} />
        <p className={style.description}>
          필굿은 공공기관, 병원, 학교, 교육센터, 돌봄센터, 종교단체 등{isMobile ?  " " : <br /> }
          국내 아이들의 인재발굴을 위해 다양한 분야의 협력사와 제휴를 맺고 있습니다.
          <br />
          <StrongTextStyle className={style.white}>
            협력사와 제휴 코드를 확인하고 할인된 가격으로 K-PASS 검사를 진행해 보세요.
          </StrongTextStyle>
        </p>
        <div className={style.mapAndCompanyWrapper}>
          <div className={style.mapWrapper}>
            <Map
              className={style.map}
              center={centerPosition.center}
              isPanto={centerPosition.isPanto}
              onCreate={setMap}
            >
              {isMarkersLoaded &&
                allPartnersWithPosition.map((partner, idx) => {
                  return (
                    <CustomOverlayMap
                      key={`map-marker-${idx}`}
                      position={{ lat: Number(partner.mapLatitude), lng: Number(partner.mapLongitude) }}
                    >
                      <div
                        className={`${style.pick} ${selectedPartner?.idx === partner.idx ? style.selected : ""}`}
                        onClick={() => setSelectedPartner(partner)}
                      >
                        <div
                          className={style.logoImage}
                          style={{
                            backgroundImage: `url("${partner.logo}")`,
                          }}
                        />
                      </div>
                    </CustomOverlayMap>
                  );
                })}
            </Map>
          </div>
          <div className={style.companyListWrapper}>
            <DomesticPartnerCompanyCards
              partnerCompanies={searchResultPartners ?? []}
              search={search}
              selectPartnerCompany={(partnerCompany) => setSelectedPartner(partnerCompany)}
              selectedPartner={selectedPartner}
            />
          </div>
        </div>
      </section>
    </article>
  );
}
