import styled from "styled-components";
import attachedImageIcon from "../../../../../../assets/images/kpass_review/attached_image_icon.svg";
import closeButton from "../../../../../../assets/images/kpass_review/close_button.svg";
import React, { useCallback } from "react";
import { FileAcceptType, FileInput } from "../../../../../../util/file/FileInput";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooks";
import {
  addAttachedFile,
  removeAttachedFile,
  reviewImagesState,
} from "../../../../../../store/review/reviewModalSlice";
import { instanceOfFileType } from "../../../../../../types/file";
import { breakpoints } from "../../../../../../theme/theme";

const AttachedPicturesContainerStyle = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  overflow-y: hidden;
  
  ${breakpoints.tablet} {
    flex-wrap: wrap;
    justify-content: unset;
    gap: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }

  ${breakpoints.mobile} {
    justify-content: unset;
    gap: 10px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }
`;

const PictureStyle = styled.li`
  width: 190px;
  height: 140px;
  border: 2px solid ${(props) => props.theme.color.kpBlueLight};
  border-radius: 10px;
  box-sizing: border-box;
  
  ${breakpoints.tablet} {
    width: 206px;
    height: 120px;
    flex-shrink: 0;
  }

  ${breakpoints.mobile} {
    width: 160px;
    height: 120px;
    flex-shrink: 0;
  }
`;

const EmptyPictureStyle = styled(PictureStyle)`
  background-image: url(${attachedImageIcon});
  background-size: 72px 52px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`;

const AttachedPictureStyle = styled(PictureStyle)`
  position: relative;

  > img {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-top: -1px;
    margin-left: -1px;
    object-fit: cover;
    border-radius: 10px;
  }

  .clear_button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-image: url(${closeButton});
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

export function ReviewImages() {
  const dispatch = useAppDispatch();
  const reviewImages = useAppSelector(reviewImagesState);

  const onClickSelectImage = useCallback(() => {
    const fileInput = new FileInput(
      (files) => {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.onload = () => {
            dispatch(
              addAttachedFile({
                src: reader.result?.toString() ?? "",
                file: files[i],
              })
            );
          };
          reader.readAsDataURL(files[i]);
        }
      },
      true,
      FileAcceptType.JPG_PNG_GIF
    );
    fileInput.click();
  }, [dispatch]);

  const onClickClearImage = useCallback(
    (e: React.MouseEvent, idx: number) => {
      dispatch(removeAttachedFile(idx));
      e.stopPropagation();
    },
    [dispatch]
  );

  return (
    <AttachedPicturesContainerStyle>
      {reviewImages.map((file, idx) =>
        file ? (
          <AttachedPictureStyle key={idx}>
            <img src={instanceOfFileType(file) ? file.src : file.file.uri} alt="review" />
            <span className={"clear_button"} onClick={(e) => onClickClearImage(e, idx)} />
          </AttachedPictureStyle>
        ) : (
          <EmptyPictureStyle key={idx} onClick={() => onClickSelectImage()} />
        )
      )}
    </AttachedPicturesContainerStyle>
  );
}
