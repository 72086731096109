import style from "../koreanKpassStandardization.module.scss";
import kpassSecondaryLogo from "../../../assets/images/logo/kpass_secondary.svg";

export function CoverHeaderKO() {
  return (
    <section className={style.articleHeader}>
      <span>kpass.feel-good.io</span>
      <img className={style.logo} src={kpassSecondaryLogo} alt={"K-PASS Secondary Logo"} />
    </section>
  );
}
