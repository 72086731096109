import modalStyle from "../../../assets/scss/common/modal/modal.module.scss";
import styles from "../../../assets/scss/common/modal/loginModal.module.scss";
import {useCallback, useEffect, useState} from "react";
import {useAppSelector, useLoader, useModal} from "../../../hooks/hooks";
import {loginStatus} from "../../../store/login/loginSlice";
import {SignupStep} from "./login/signup/SignupStep";
import {SignupInfoStep1} from "./login/signup/SignupInfoStep1";
import {ModalType} from "../../../types/common";
import {Modal} from "./Modal";
import {SignupInfoStep2} from "./login/signup/SignupInfoStep2";
import {Address, Gender} from "../../../types/user";
import {SignupInfoStep3} from "./login/signup/SignupInfoStep3";
import {SignupInfoStep4} from "./login/signup/SignupInfoStep4";

export enum SignupStatus {
  SIGN_UP = "SIGN_UP",
  Sign_UP_INFO_01 = "Sign_UP_INFO_01",
  Sign_UP_INFO_02 = "Sign_UP_INFO_02",
  Sign_UP_INFO_03 = "Sign_UP_INFO_03",
  Sign_UP_INFO_04 = "Sign_UP_INFO_04",
}

export function SignupModal() {
  const status = useAppSelector(loginStatus);
  const { showModal, closeModal, openConfirmModal } = useModal();
  const { setLoaderStatus } = useLoader();
  const [isPathChanged, setIsPathChanged] = useState(0);

  const [loginStep, setLoginStep] = useState<SignupStatus>(SignupStatus.SIGN_UP);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("로그인 & 회원가입")

  const [name, setName] = useState("");
  const [nationality, setNationality] = useState("KR");
  const [phoneCode, setPhoneCode] = useState("+82");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthday, setBirthday] = useState('');
  const [isAgreementEmailMarketingReceipt, setIsAgreementEmailMarketingReceipt] = useState(false);
  const [isAgreementSMSMarketingReceipt, setIsAgreementSMSMarketingReceipt] = useState(false);
  const [isAgreementPersonalInfoCollectionAndUsage, setIsAgreementPersonalInfoCollectionAndUsage] = useState(false);
  const [gender, setGender] = useState(Gender.NONE);
  const [address, setAddress] = useState<Address>({
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',

  });

  useEffect(() => {
    const localEmail = localStorage.getItem('email')
    if (localEmail !== '' && localEmail !=null) {
      setEmail(localEmail)
      localStorage.setItem('email','')
    }
  }, []);

  const onClickCloseBtn = () => {
    if (name === '' &&  phoneNumber === '' ) {
      closeModal();
    } else {
      openConfirmModal(
          {
            title: "안내",
            desc: "해당 창을 닫을 경우\n입력한 회원 정보가 모두 사라집니다.\n창을 정말 닫으시겠습니까?",
          },
          () => {
            closeModal();
          }
      );
    }
    if (loginStep === SignupStatus.Sign_UP_INFO_04) {
      closeModal();
      window.location.reload();
    }
  };

  const changeSignupStep = useCallback((step: SignupStatus) => setLoginStep(step), []);

  useEffect(() =>{
    setTitle("로그인 & 회원가입")
  },[])

  useEffect(() => {
    setLoaderStatus([status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);


  const modalOtherStyle = loginStep === SignupStatus.SIGN_UP
      ? `${modalStyle.normal} ${styles.wrapper}`
      : `${modalStyle.signupModal} ${styles.wrapper}`;

  // 스텝 바뀔때마다 모달 최상단으로 이동
  useEffect(() => {
    setIsPathChanged((prev) => isPathChanged+1)
    const modal = document.querySelector(`.${styles.content}`);
    if (modal) {
      modal.scrollTop = 0;
    }

    if (loginStep === SignupStatus.Sign_UP_INFO_04) {
      setName('')
      setPhoneNumber('')
    }
    // eslint-disable-next-line
  }, [loginStep]);

  return (
    <Modal
      showModal={showModal.valueOf() === ModalType.SIGN_UP.valueOf()}
      title={title}
      closeModalFunction={onClickCloseBtn}
      modalBgStyle={modalStyle.mainColorBg}
      modalCloseButtonStyle={modalStyle.mainColorBg}
      modalOtherStyle={modalOtherStyle}
      isPathChanged={isPathChanged}
    >
      <>
        {loginStep === SignupStatus.SIGN_UP && (
            <SignupStep
                changeSignupStep={changeSignupStep}
                email={email}
                setTitle={setTitle}
                setCode={setCode}
            />
        )}
      <div className={loginStep === SignupStatus.SIGN_UP ? '' : styles.content}>
        {loginStep === SignupStatus.Sign_UP_INFO_01 && (
            <SignupInfoStep1
                loginStep={loginStep}
                changeSignupStep={changeSignupStep}
                name={name}
                phoneCode={phoneCode}
                phoneNumber={phoneNumber}
                nationality={nationality}
                setNationality={setNationality}
                setName={setName}
                setCellphone={setPhoneCode}
                setPhoneCode={setPhoneCode}
                setPhoneNumber={setPhoneNumber}
            />
        )}
        {loginStep === SignupStatus.Sign_UP_INFO_02 && (
            <SignupInfoStep2
                loginStep={loginStep}
                changeSignupStep={changeSignupStep}
                birthday={birthday}
                setBirthday={setBirthday}
                gender={gender}
                setGender={setGender}
                address={address}
                setAddress={setAddress}
            />
        )}
        {loginStep === SignupStatus.Sign_UP_INFO_03 && (
            <SignupInfoStep3
                loginStep={loginStep}
                changeSignupStep={changeSignupStep}
                isAgreementEmailMarketingReceipt={isAgreementEmailMarketingReceipt}
                setIsAgreementEmailMarketingReceipt={setIsAgreementEmailMarketingReceipt}
                isAgreementPersonalInfoCollectionAndUsage={isAgreementPersonalInfoCollectionAndUsage}
                setIsAgreementPersonalInfoCollectionAndUsage={setIsAgreementPersonalInfoCollectionAndUsage}
                isAgreementSMSMarketingReceipt={isAgreementSMSMarketingReceipt}
                setIsAgreementSMSMarketingReceipt={setIsAgreementSMSMarketingReceipt}

                email={email}
                phoneCode={phoneCode}
                phoneNumber={phoneNumber}
                name={name}
                birthday={birthday}
                gender={gender}
                address={address}
                code={code}
                nationality={nationality}
            />
        )}
        {loginStep === SignupStatus.Sign_UP_INFO_04 && (
            <SignupInfoStep4
                changeSignupStep={changeSignupStep}
            />
        )}
      </div>
      </>
    </Modal>
  );
}
