import style from "../../assets/scss/sub/couponhistory.module.scss";
import LeftBtnIcon from "../../assets/images/mypage/left_button_icon.svg";
import RightBtnIcon from "../../assets/images/mypage/right_button_icon.svg";
import { getAllCouponAsync, getAllCouponCountAsync } from "../../store/coupon/couponSlice";
import { useAppDispatch, useTimeConverter } from "../../hooks/hooks";
import { useCallback, useEffect, useState } from "react";
import { MyCoupon, ApiResponse } from "../../types/coupon";
export function CouponHistory() {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [coupons, setCoupons] = useState<MyCoupon[]>([]);
  const [totalCoupons, setTotalCoupons] = useState(0);
  const { timeConverter } = useTimeConverter();

  const getAllCouponList = useCallback(
    async (page: number) => {
      try {
        const response = (await dispatch(getAllCouponAsync(page)).unwrap()) as ApiResponse;
        const response2 = (await dispatch(getAllCouponCountAsync()).unwrap()) as ApiResponse;
        setCoupons(response.content);
        setTotalPages(response.totalPages || 1); // totalPages가 0이면 1로 설정
        setTotalCoupons(response2.totalElements);
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getAllCouponList(currentPage).then();
  }, [currentPage]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // 페이지네이션 범위를 계산하는 부분
  const paginationRange = 5;
  const startPage = Math.max(0, Math.min(currentPage - Math.floor(paginationRange / 2), totalPages - paginationRange));
  const endPage = Math.min(totalPages, startPage + paginationRange);

  return (
    <div className={style.container}>
      <div className={style.containerContentBox}>
        <h2 className={style.containerTitle}>
          쿠폰내역 <span className={style.profileCount}>({totalCoupons}건)</span>
        </h2>
      </div>
      <div>
        {/* 지급일 쿠폰명 혜택 사용 기한 */}
        <div className={style.labelContainer}>
          <div className={style.firstLabel}>지급일</div>
          <div className={style.secondLabel}>쿠폰명</div>
          <div className={style.thirdLabel}>혜택</div>
          <div className={style.fourthLabel}>사용 기한</div>
        </div>
        {/* 데이터 */}
        <div className={style.resultInfoContainer}>
          {coupons.length > 0 ? (
            coupons.map((coupon) => (
              <div key={coupon.idx} className={style.resultInfoData}>
                <div className={style.firstInfoData}>
                  {timeConverter.convertToFormattedDate(timeConverter.convertToLocalDate(coupon.availableStartAt))}
                </div>
                <div className={style.secondInfoData}>{coupon.coupon.name}</div>
                <div className={style.thirdInfoData}>
                  {coupon.coupon.point
                    ? `${coupon.coupon.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원 할인`
                    : `${coupon.coupon.percent}% 할인`}
                </div>
                <div className={style.fourthInfoData}>
                  {timeConverter.convertToFormattedDate(timeConverter.convertToLocalDate(coupon.availableEndAt))}
                </div>
              </div>
            ))
          ) : (
            <div className={style.noPaymentInfo}>쿠폰 내역이 없습니다.</div>
          )}
        </div>
        {/* 페이지네이션 */}
        <div className={style.paginationContainer}>
          <div className={style.paginationDiv}>
            <img
              src={LeftBtnIcon}
              className={style.leftButtonImg}
              onClick={() => handlePageChange(Math.max(currentPage - 1, 0))}
            />
            {Array.from({ length: endPage - startPage }, (_, i) => startPage + i).map((page) => (
              <button
                key={page}
                className={`${style.paginationBtn} ${currentPage === page ? style.active : ""}`}
                onClick={() => handlePageChange(page)}
              >
                {page + 1}
              </button>
            ))}
            <img
              src={RightBtnIcon}
              className={style.rightButtonImg}
              onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages - 1))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
