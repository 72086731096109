import {ArticleTitle} from "./ArticleTitle";
import style from "../../assets/scss/sub/understandingKpass.module.scss";
import simPic from "../../assets/images/sub/understanding/kpass/sim_pic.png";
import simPicKo from "../../assets/images/sub/understanding/kpass/sim_pic_ko.png";
import liPic from "../../assets/images/sub/understanding/kpass/li_pic.png";
import liPicKo from "../../assets/images/sub/understanding/kpass/li_pic_ko.png";
import childrenPic from "../../assets/images/sub/understanding/kpass/children.png";
import childrenPicMobile from "../../assets/images/sub/understanding/kpass/children_mobile.png";
import reason1Pic from "../../assets/images/sub/understanding/kpass/reason1.svg";
import reason2Pic from "../../assets/images/sub/understanding/kpass/reason2.svg";
import reason3Pic from "../../assets/images/sub/understanding/kpass/reason3.svg";
import reason4Pic from "../../assets/images/sub/understanding/kpass/reason4.svg";
import bottomPic from "../../assets/images/sub/understanding/kpass/bottom_pic.png";
import {CircleTitle} from "./CircleTitle";
import {Trans, useTranslation} from "react-i18next";
import {useLanguage, useUserAgent} from "../../hooks/hooks";

export function UnderstandingKpass() {
  const { t } = useTranslation(["subUnderstandingKpass"]);
  const { isKorean, isNotKorean } = useLanguage();
  const { isDesktop } = useUserAgent();
  const { isMobile } = useUserAgent();
  const { isTablet } = useUserAgent();

  return (
    <section className={style.understandingKpass}>
      <h3 className="blind">{t("title")}</h3>
      <article className={style.descriptionArticle}>
        <div className={style.phrase1}>
          <h4>
            <ArticleTitle title={t("articleTitle")} className={style.title} />
          </h4>
          <p className={style.desc}>
            <span className={style.accent}>{t("description1")}</span>
            <span className={style.normal}>{t("description2")}</span>
          </p>
        </div>
        <div className={style.phrase2}>
          <div className={style.descArea}>
            <div className={style.image}>
              <img src={isKorean ? simPicKo : simPic} alt={t("simImage")} />
            </div>
            <div className={style.text}>
              {isMobile ? (
                <div className={`${style.titleArea} ${isNotKorean ? style.ru : ""}`}>
                  <h5 className={style.title}>{t("simTitle")}</h5>
                  <span className={style.titleDesc}>{t("simSubtitle")}</span>
                </div>
              ) : (
                <>
                  <h5 className={style.title}>{t("simTitle")}</h5>
                  <span className={style.titleDesc}>Psychological Tests</span>
                </>
              )}
              <ul className={style.descList}>
                <li>{t("simDesc1")}</li>
                <li>{t("simDesc2")}</li>
              </ul>
              <div className={style.conclusion}>
                <div className={`${style.arrowArea} ${style.simArrow}`}></div>
                <p className={style.text}>{t("simLimitDesc1")}</p>
              </div>
            </div>
          </div>
          <div className={style.descArea}>
            <div className={style.image}>
              <img src={isKorean ? liPicKo : liPic} alt={t("liImage")} />
            </div>
            <div className={style.text}>
              {isMobile ? (
                <div className={`${style.titleArea} ${isNotKorean ? style.ru : ""}`}>
                  <h5 className={style.title}>{t("liTitle")}</h5>
                  <span className={style.titleDesc}>{t("liSubtitle")}</span>
                </div>
              ) : (
                <>
                  <h5 className={style.title}>{t("liTitle")}</h5>
                  <span className={style.titleDesc}>{t("liSubtitle")}</span>
                </>
              )}
              <ul className={style.descList}>
                <li>{t("liDesc1")}</li>
                <li>{t("liDesc2")}</li>
              </ul>
              <div className={style.conclusion}>
                <div className={`${style.arrowArea} ${style.liArrow}`}></div>
                <p className={style.text}>{t("liBenefit")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.phrase3}>
          <div className={`${style.area1} ${isNotKorean ? style.ru : ""}`}>
            <div className={style.content}>
              <p className={style.text}>
                {isDesktop ? t("phrase3Area1Text1") : t("phrase3Area1Text1Mobile")}
                <Trans t={t} i18nKey={"phrase3Area1Text3"} components={[<span className={style.accent} />]} />
                {isDesktop ? t("phrase3Area1Text5") : t("phrase3Area1Text5Mobile")}
              </p>
              {isDesktop ? (
                <div className={style.image}>
                  <img src={childrenPic} alt={t("phrase3Area1ImageAlt")} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={`${style.area2} ${isNotKorean ? style.ru : ""}`}>
            {isMobile ?  <div className={style.backgroundArea}></div> : <></>}
            <div className={style.content}>
              {isTablet && (
                  <>
                    <p className={style.text}>
                      <Trans t={t} i18nKey={"phrase3Area2Text1"} components={[<span className={style.accent} />]} />
                    </p>
                    <div className={style.image}>
                      <img src={childrenPicMobile} alt={t("phrase3Area1ImageAlt")}/>
                    </div>
                  </>
              )}

              {isDesktop && (
                  <p className={style.text}>
                    <Trans t={t} i18nKey={"phrase3Area2Text1"} components={[<span className={style.accent} />]} />
                  </p>
              )}

              { isMobile && (
                  <>
                    <div className={style.image}>
                      <img src={childrenPicMobile} alt={t("phrase3Area1ImageAlt")}/>
                    </div>
                    <p className={style.text}>
                      <Trans t={t} i18nKey={"phrase3Area2Text1"} components={[<span className={style.accent}/>]}/>
                    </p>
                  </>
              )}
            </div>
          </div>
        </div>
        <div className={style.phrase4}>
          {isMobile ? (
            <>
              <div className={style.bgItem1}></div>
              <div className={style.bgItem2}></div>
              <div className={style.bgItem3}></div>
              <div className={style.bgItem4}></div>
            </>
          ) : (
            <>
              <div className={style.bgItem1}></div>
              <div className={style.bgItem2}></div>
              <div className={style.bgItemsGroup1}>
                <div className={style.item1}></div>
                <div className={style.item2}></div>
              </div>
              <div className={style.bgItemsGroup2}>
                <div className={style.item1}></div>
                <div className={style.item2}></div>
              </div>
              <div className={style.bgItemsGroup3}>
                <div className={style.item1}></div>
                <div className={style.item2}></div>
              </div>
            </>
          )}
          <div className={style.contentWrapper}>
            <div className={style.content}>
              <CircleTitle title={t("phrase4Section1Title")} />
              <p className={style.desc}>{t("phrase4Section1Desc1")}</p>
            </div>
            <div className={style.content}>
              <CircleTitle title={t("phrase4Section2Title")} />
              <p className={style.desc}>{t("phrase4Section2Desc1")}</p>
            </div>
          </div>
        </div>
      </article>
      <article className={style.whyNeedArticle}>
        <div className={style.content}>
          <h4>
            <ArticleTitle title={t("whyNeedTitle")} className={`${style.title} ${isNotKorean ? style.ru : ""}`} />
          </h4>
          <p className={style.summary}>{t("whyNeedSummary1")}</p>
          <p className={style.desc}>{t("whyNeedDesc1")}</p>
          <div className={style.descImages}>
            <div className={style.reason}>
              <img src={reason1Pic} alt="" />
              <span className={style.caption}>{t("whyNeedDescImage1Caption")}</span>
            </div>
            <div className={style.reason}>
              <img src={reason2Pic} alt="" />
              <span className={style.caption}>{t("whyNeedDescImage2Caption")}</span>
            </div>
            <div className={style.reason}>
              <img src={reason3Pic} alt="" />
              <span className={style.caption}>{t("whyNeedDescImage3Caption")}</span>
            </div>
            <div className={style.reason}>
              <img src={reason4Pic} alt="" />
              <span className={style.caption}>{t("whyNeedDescImage4Caption")}</span>
            </div>
          </div>
        </div>
      </article>
      <article className={style.resultArticle}>
        <div className={style.text}>
          <p className={style.summary}>
            <Trans
              t={t}
              i18nKey={"resultArticleSummary1"}
              components={[<span className={`${style.accent} `} />]}
            />
          </p>
          <p className={`${style.desc} ${isNotKorean ? style.ru : ""}`}>{t("resultArticleDesc1")}</p>
        </div>
        <div className={`${style.image} ${isKorean ?  "" : style.ru}`}>
          {isMobile ? (
            <>
              <img src={bottomPic} alt={""} />
            </>
          ) : (
            <></>
          )}
        </div>
      </article>
    </section>
  );
}
