import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import loginReducer from "./login/loginSlice";
import userReducer from "./user/userSlice";
import profileReducer from "./profile/profileSlice";
import voucherReducer from "./voucher/voucherSlice";
import couponReducer from "./coupon/couponSlice";
import termsReducer from "./terms/termsSlice";
import productReducer from "./product/productSlice";
import countryReducer from "./country/countrySlice";
import orderReducer from "./order/orderSlice";
import cartReducer from "./cart/cartSlice";
import reviewReducer from "./review/reviewSlice";
import reviewModalReducer from "./review/reviewModalSlice";
import fileReducer from "./file/fileSlice";
import eventReducer from "./event/eventSlice";
import partnerCompanyReducer from "./partner_company/partnerCompanySlice";
import popupReducer from "./popup/popupSlice";
import testHistoryReducer from "./test_history/testHistorySlice";
import signupReducer from "./singup/signupSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    profile: profileReducer,
    voucher: voucherReducer,
    coupon: couponReducer,
    terms: termsReducer,
    product: productReducer,
    country: countryReducer,
    order: orderReducer,
    cart: cartReducer,
    review: reviewReducer,
    reviewModal: reviewModalReducer,
    file: fileReducer,
    event: eventReducer,
    partnerCompany: partnerCompanyReducer,
    popup: popupReducer,
    testHistory: testHistoryReducer,
    signup:signupReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
